import { createSlice } from "@reduxjs/toolkit";
import api from '../../store/services'

export const supplierSlice = createSlice({
    name: "supplier",
    initialState: {
      suppliers: [],
    },
    reducers: {
      setSuppliers: (state, { payload }) => {
          state.suppliers = payload;
      },
    }
  });

export const getSuppliers = (payload) => async (dispatch) => {
  const { data } = await api.get(`/v1/organizations/${payload}/network?category_tag=EXCHANGE`)
  dispatch(setSuppliers(data.content));
};


export const { setSuppliers } = supplierSlice.actions;
export default supplierSlice.reducer;