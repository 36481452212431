import React, { useEffect, useState, useCallback, useRef, useContext } from "react";

import { useSelector, useDispatch } from "react-redux";
import uuid from "react-uuid";

import Button from "../../Elements/button/button";
import DropDown from "../../../components/McDropdown";
import DateView from "../../../components/Elements/DateView";

import { OrganisationContext } from "../../../context/organisationContext";
import { addToast } from "../../../store/features/toastSlice";

import { updateCampaign, createCampaign } from '../../../store/features/campaignSlice';
import { getCollections, getCollectionIntegrations, getPaymentIntegrations } from "../../../store/features/collectionSlice";
import { getAccounts } from "../../../store/features/accountSlice";


const CampaignForm = ({c, cancel}) => {
    const campaignTypeOptions = [{id: 0, name: 'Event', text: 'EVENT'}, {id: 1, name: 'Leaderboard', text: 'LEADERBOARD'}, {id: 2, name: 'Giveaway', text: 'GIVEAWAY'}, {id: 3, name: 'Transactional', text: 'TRANSACTIONAL'}, {id: 4, name: 'Merchandise', text: 'MERCHANDISE'}, {id: 5, name: 'Measurement', text: 'MEASUREMENT'}]
    const { checkPermission, hasPermission } = useContext(OrganisationContext);
    const { user, organisations } = useSelector(data => data.user);
    const dispatch = useDispatch();

    const pageRef = useRef(null);
    const pageRefBeneficiary = useRef(null);
    const headlineRef = useRef(null);

    const { collections, integrations, paymentIntegrations } = useSelector(data => data.collection)
    const { accounts } = useSelector(data => data.account);
    const [campaign, setCampaign] = useState(c)
    const [beneficiaryWallet, setBeneficiaryWallet] = useState(campaign?.beneficiary?.wallet?.wallet_address)

    useEffect(() => {
        if(headlineRef.current) {
            headlineRef.current.style.height = '0px';
            const scrollHeightHeadline = headlineRef.current.scrollHeight;
            headlineRef.current.style.height = scrollHeightHeadline + 5 + 'px';
        }
    }, [campaign?.description]);

    const fetchCollections  = useCallback((page, merge, integration)  => {
        pageRef.current = page;
        dispatch(getCollections({page: page, size: 10, merge, integration: integration?.id}));
    }, [dispatch]);

    useEffect(() => {
            fetchCollections(0, false, campaign.integration);
    }, [campaign.integration, fetchCollections])

    useEffect(() => {
        dispatch(getCollectionIntegrations());
      }, []);

      useEffect(() => {
        dispatch(getPaymentIntegrations());
      }, []);

    const fetchAccounts  = useCallback((page, merge, user)  => {
        pageRefBeneficiary.current = page;
        dispatch(getAccounts({organisationId: user.organisations[0].external_id, page: page, merge: merge, type: '', search: ''}));
    }, [dispatch]);

    useEffect(() => {
        fetchAccounts(0, false, user);
    }, [user, fetchAccounts]);

    const saveCampaign = async () => {
        const campaignPayload = {
            name: campaign.name,
            description: campaign.description,
            effective: campaign.effective,
            campaign_type: campaign?.campaign_type || null,
            integration: campaign?.integration ? {
              id: campaign?.integration?.id
            } : null,
            payment_integration: campaign?.payment_integration ? {
                id: campaign?.payment_integration?.id
              } : null,
            account: campaign?.account,
            collection: campaign.collection ? {
              id: campaign?.collection?.id
            } : null,
            beneficiary: {id: campaign.beneficiary?.id, 
                 wallet: {
                    wallet_address: beneficiaryWallet
                 }
             }
          }
        if(!campaignPayload.beneficiary?.id) {
            dispatch(
                addToast({
                  error: true,
                  text: `Please select beneficiary`,
                  id: uuid(),
                }),
              );
    
            } else if(!campaignPayload.name) {
                dispatch(
                    addToast({
                      error: true,
                      text: `Please enter name`,
                      id: uuid(),
                    }),
                  );
        } else if(!campaignPayload.effective?.start) {
            dispatch(
                addToast({
                  error: true,
                  text: `Please select start date`,
                  id: uuid(),
                }),
              );
            } else if(!campaignPayload.effective?.end) {
                dispatch(
                    addToast({
                      error: true,
                      text: `Please select end date`,
                      id: uuid(),
                    }),
                  );
            
        
        } else  if(!campaignPayload.beneficiary.wallet || !campaignPayload.beneficiary.wallet.wallet_address) {
            dispatch(
                addToast({
                  error: true,
                  text: `Please enter beneficiary wallet`,
                  id: uuid(),
                }),
              ); 
        } else {
            if (!campaign.id) {
                const data = await dispatch(createCampaign({campaign: {...campaignPayload}}))
                cancel(data);
            } else {
                dispatch(updateCampaign({id: campaign.id, campaign: {...campaignPayload}}))
                 cancel();
            }
        }
    }

    return (
        <div className="flex-column">
        <div className="flex">
            <div className="flex-column flex-1 mr-10">
                <label className="input-label">Name</label>
                <input className="input" value={campaign?.name || ''} placeholder="Enter name" onChange={e => setCampaign({...campaign, name: e.target.value})} />
                <label className="input-label mt-6">Description</label>
                <textarea className="textarea" value={campaign?.description || ''} placeholder="Enter description" ref={headlineRef} onChange={e => setCampaign({...campaign, description: e.target.value})} />
                <div className="flex mt-6">
                    <div className="flex-column flex-1">
                        <label className="input-label">Start</label>
                        <DateView size="medium" value={campaign.effective?.start} setValue={o =>  setCampaign({...campaign, effective: {...campaign.effective, start: o}})}/>
                    </div>
                    <div className="flex-column flex-1 ml-6 end-date-selector">
                        <label className="input-label">End</label>
                        <DateView size="medium" value={campaign.effective?.end} setValue={o =>  setCampaign({...campaign, effective: {...campaign.effective, end: o}})}/>
                    </div>
                </div>
                <label className="input-label mt-6">Campaign Type</label>
                <DropDown 
                    size="large" 
                    placeholder="Select" 
                    selected={campaignTypeOptions.find(c => c.text === campaign.campaign_type)}
                    setSelected={c => setCampaign({...campaign, campaign_type: c.text})}
                    options={campaignTypeOptions}
                />
                <label className="input-label mt-6">Payment integration</label>
                <DropDown 
                    size="large" 
                    placeholder="Select" 
                    selected={campaign.payment_integration}
                    setSelected={c => setCampaign({...campaign, payment_integration: {...c, icon: c.connector?.icon ? {...c.connector?.icon} : {}}, collection: null })}
                    options={paymentIntegrations}
                    getIcon={(option) => { return option.icon?.active || option?.connector?.icon?.active || ''}}
                    icon={true}
                />  
            </div>
            <div className="flex-column flex-1">
                <label className="input-label">Blockchain</label>
                <DropDown 
                    size="large" 
                    placeholder="Select" 
                    selected={campaign.integration}
                    setSelected={c => setCampaign({...campaign, integration: {...c, icon: c.connector?.icon ? {...c.connector?.icon} : {}}, collection: null })}
                    options={integrations}
                    getIcon={(option) => { return option.icon?.active || option?.connector?.icon?.active || ''}}
                    icon={true}
                />                        
                <label className="input-label mt-6">Blockchain receiver</label>
                <input className="input" placeholder="Enter receiver" value={campaign?.account || ''} onChange={e => setCampaign({...campaign, account: e.target.value})} />
                <label className="input-label mt-6">Collection</label>
                <DropDown 
                    size="large" 
                    placeholder="Select" 
                    selected={campaign?.collection}
                    setSelected={c => setCampaign({...campaign, collection: c})}
                    options={collections.content}
                    isPaged={true}
                    hasMore={!collections.last}    
                    fetchData={() => fetchCollections(pageRef.current + 1, true, campaign.integration)}   
                />
                <label className="input-label mt-6">Beneficiary</label>
                <DropDown 
                    size="large" 
                    placeholder="Select" 
                    selected={campaign.beneficiary}
                    setSelected={c => setCampaign({...campaign, beneficiary: c})}
                    options={[...organisations.map(o => ({...o, id: o.external_id})), ...accounts.content]}
                    isPaged={true}
                    hasMore={!accounts.last}    
                    fetchData={() => fetchAccounts(pageRefBeneficiary.current + 1, true, user)}   
                />
                <label className="input-label mt-6">Beneficiary Wallet address</label>
                <input className="input" placeholder="Enter beneficiary wallet address" value={beneficiaryWallet || ''} onChange={e => setBeneficiaryWallet(e.target.value)} />
            </div>
        </div>
        <div className="flex items-center justify-center mt-10">
            <div className="flex">
                <Button
                    className="secondary-hover"
                    label="Cancel"
                    borderRadius="12px"
                    onClick={cancel}
                    secondary={true}
                    size="average"
                    style={{ flex: 1 }}
                    bgColor='#FFFFFF'
                    borderColor="#24CBB1"
                    color="#24CBB1"
                    borderWidth="1px"
                    width="112px"
                />
                <Button
                    label='Save'
                    onClick={() => checkPermission(() => saveCampaign(), 'CAMPAIGNS_MANAGE')}
                    size="average"
                    style={{ flex: 1, marginLeft: 24 }}
                    bgColor='#24CBB1'
                    borderColor="#24CBB1"
                    color='#FFFFFF'
                    borderRadius="12px"
                    borderWidth="1px"
                    disabled={!hasPermission('CAMPAIGNS_MANAGE')}
                    width="112px"
                />
            </div>
        </div>
</div>
    )
}

export default CampaignForm;