import React, { useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTemplate, setTemplate } from "../../store/features/templateSlice";
import TemplateDetailsComponent from "../../components/templates/TemplateDetails";
import NavTop from "../../components/common/NavTop";
import TemplateAttributes from "../../components/templates/TemplateAttributes";
import TemplateMedia from "../../components/templates/TemplateMedia";
import TemplateCollections from "../../components/templates/TemplateCollections";
import TemplateMenu from "../../components/DefaultMenu";
import { Route, Routes, useParams, useNavigate, useLocation } from 'react-router-dom';

import './style.scss';

const TemplateDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { template } = useSelector(data => data.template);
    const menuItems = [{id: 0, name: 'Attributes', text: 'Attributes', path: '/attributes'},
                       {id: 1, name: 'Media', text: 'Media', path: '/media'},
                       {id: 2, name: 'Collections', text: 'Collections', path: '/collections'}];

    const {id} = useParams();
    const isNew = id === 'new';
    
    const collection = location.state?.collection;

    useEffect(() => {
        if (isNew) {
            dispatch(setTemplate({}))
        } else {
            dispatch(getTemplate(id));
        }
    }, [id, isNew]);

    const onMenuItemClick = item => {
        navigate(`/meta/templates/${id}${item.path}`)
    }

    return (
        <div className="flex-column schema-details flex-1">
            <div className="mb-6 mt-8 ml-6">
                <NavTop names={collection ? [{text: "Collections", path: "/meta/collections"}, {text: collection?.name, path: `/meta/collections/${collection.id}/templates`} , {text: "Templates", path: "/meta/templates"}, {text: template?.name}] : [{text: "Templates", path: "/meta/templates"}, {text: template?.name}]} savePermission="NFT_MANAGE" edit={false} done={true} onDone={()=> navigate(`/meta/templates`)} />
            </div>
            <div className="flex">
                <TemplateDetailsComponent template={template} isNew={isNew} />
                <div className="flex-column flex-1 mr-6">
                    <Routes>
                        <Route element={<TemplateAttributes template={template} menu={<TemplateMenu menuItems={menuItems} onMenuItemClick={onMenuItemClick}  />} />} path={`/attributes`} />
                        <Route element={<TemplateMedia template={template}  menu={<TemplateMenu menuItems={menuItems} onMenuItemClick={onMenuItemClick}  />}/>} path={`/media`} />
                        <Route element={<TemplateCollections template={template} menu={<TemplateMenu menuItems={menuItems} onMenuItemClick={onMenuItemClick}  />} isNew={isNew} />} path={`/collections`} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default TemplateDetails;
