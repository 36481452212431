import React, { useState, useEffect, useRef } from "react";

import api, { cloudinaryApi } from '../../store/services'

import uuid from 'react-uuid'

import ImagePlaceholder from '../../assets/images/image-placeholder.svg'

import './photos.scss';


const Photos = () => {
    const inputFile = useRef(null)
    const usageTypes = ['LOGO',
        'BACKGROUND',
        'AVATAR',
        'PHOTO',
        'VIDEO',
        'DOCUMENT'];

    const [selectedView, setSelectedView] = useState("add")
    const [creatives, setCreatives] = useState([]);
    const [mediaGroupId, setMediaGroupId] = useState('');
    const [mediaGroupName, setMediaGroupName] = useState('');
    const [description, setDescription] = useState('');
    const [selected, setSelected] = useState();
    const [file, setFile] = useState();
    const [selectedUsageType, setSelectedUsageType] = useState(usageTypes[0]);
    const [showOptions, setShowOptions] = useState(false);
    const [addedGroup, setAddedGroup] = useState();

    const getCreatives = async () => {
        const { data } = await api.get(`/v3/api/asset/media/${mediaGroupId}`)
        if (data.length > 0)
            setSelected(data[0])
        setCreatives(data)
    }

    const createMediaGroup = async () => {
        if (mediaGroupName && mediaGroupName.length > 2) {
            const { data } = await api.post(`/v3/api/mediagroup`, { name: mediaGroupName, description: description })
            setMediaGroupName('');
            setAddedGroup(data.id);
        }
    }

    const getSignature = async (mediaGroupId) => {
        const trackingKey = uuid()
        const { data } = await api.get(
            `/v3/api/upload/sign?mediaUsageType=${selectedUsageType}&folder=crm/${'c74cd62c-4b99-4291-b083-d0ad7aa69c7d'}&trackingKey=${trackingKey}&organisationId=${'c74cd62c-4b99-4291-b083-d0ad7aa69c7d'}&mediaGroupExternalId=${mediaGroupId}`,
        );
        return data;
    }

    const upload = async () => {
        if (file ) {
            const signature = await getSignature(mediaGroupId);

            const formData = new FormData()
            formData.append('file', file)
            formData.append('api_key', signature['api_key'])
            formData.append('type', 'upload')
            formData.append('folder', signature['upload_options']['folder'])
            formData.append('context', signature['context'])
            formData.append('timestamp', signature['timestamp'].toString())
            formData.append('signature', signature['signature'])

            const { data } = await cloudinaryApi.post(`/${signature.cloud_name}/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            setFile(null);
        }
    }

    return (<div className="photos">
        <div className="photos-left">
            {selected?.resource_type === 'video' ?
                <video controls autoPlay>
                    <source src={selected?.url} type="video/mp4" />
                </video> :

                <img src={selected?.url} />}
        </div>
        <div className="photos-right">
            <div className="view-selectors">
                <div className="view-selector" style={{ background: selectedView === 'add' ? '#d0d2d6' : '#ffffff' }} onClick={() => setSelectedView("add")}>
                    <label>Add</label>
                </div>
                <div className="view-selector" style={{ background: selectedView === 'get' ? '#d0d2d6' : '#ffffff' }} onClick={() => setSelectedView("get")}>
                    <label>Get</label>
                </div>
                <div className="view-selector" style={{ background: selectedView === 'upload' ? '#d0d2d6' : '#ffffff' }} onClick={() => setSelectedView("upload")}>
                    <label>Upload</label>
                </div>
            </div>
            {selectedView === 'add' ?
                <>
                    <input placeholder="Media group name" value={mediaGroupName} onChange={e => setMediaGroupName(e.target.value)} />
                    <input placeholder="Media group description" value={description} onChange={e => setDescription(e.target.value)} />
                    <div className="actions">
                        <button onClick={createMediaGroup}>ADD</button>
                    </div>
                    {addedGroup &&
                        <div className="new-group">
                            <label>Added media group id:</label>
                            <label>{addedGroup}</label>
                        </div>}
                </>
                :
                selectedView === 'get' ?
                    <>
                        <input placeholder="Media group id" value={mediaGroupId} onChange={e => setMediaGroupId(e.target.value)} />
                        <div className="actions">
                            <button onClick={getCreatives}>GET</button>
                        </div>
                        <div className="creatives">
                            <div className="creative header">
                                <label>ID</label>
                                <label>Width</label>
                                <label>Height</label>
                                <label>Type</label>
                            </div>
                            {creatives.map(creative =>
                                <div key={creative.id} className={`creative ${selected.id === creative.id && 'selected-creative'}`} onClick={() => setSelected(creative)}>
                                    <label>{creative.media_external_id}</label>
                                    <label>{creative.width}</label>
                                    <label>{creative.height}</label>
                                    <label>{creative.resource_type}</label>
                                </div>
                            )}
                        </div>
                    </>
                    :
                    <>
                        <input placeholder="Media group id" value={mediaGroupId} onChange={e => setMediaGroupId(e.target.value)} />
                        <div className='bv-image-placeholder'>
                            <input className='file-select-input' type='file' ref={inputFile} onChange={(e) => setFile(e.target.files[0])} />
                        </div>
                        <div className="usages">
                            <div className="selected-usage" onClick={() => setShowOptions(!showOptions)}>
                                <label>{selectedUsageType}</label>
                            </div>
                            {showOptions &&
                                <div className="su-options">
                                    {usageTypes.map((ut, i) =>
                                        <div key={i} className="su-option" onClick={() => {
                                            setSelectedUsageType(ut);
                                            setShowOptions(false);
                                        }}>
                                            <label>{ut}</label>
                                        </div>)}
                                </div>
                            }
                        </div>

                        <div className="actions">
                            <button onClick={upload}>UPLOAD</button>
                        </div>
                    </>
            }
        </div>
    </div>)
}


export default Photos;