import React from "react";

import Checkbox from '../../../assets/images/small-checkbox.svg';
import CheckboxSelected from '../../../assets/images/small-checkbox-selected.svg';


const SmallCheckbox = ({selected}) => {
    return ( 
        <div className="flex items-center justify-center">
            <img src={selected ? CheckboxSelected : Checkbox } alt="check"/>
        </div>
    )
}

export default SmallCheckbox;