import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSchemas } from "../../store/features/schemaSlice";
import './projects.scss';
import Header from "../../components/header";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useNavigate } from "react-router-dom";
import { ReactComponent as DotsIcon } from '../../assets/images/menu/dots.svg';

import Loading from '../../assets/images/loading.svg';

const Schemas = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { schemas, loading } = useSelector(data => data.schema);
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatch(getSchemas({page: currentPage, search: search}))
    }, [currentPage, search, dispatch]);

    const onAddSchema = () => {
        navigate(`/meta/schemas/new`);
    }

    return (
        <div className="flex-column mb-6 mt-2 flex-1">
            <Header title={"Schemas"}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    search={search}
                    setSearch={setSearch}
                    isAddBtn={true}
                    totalPages={schemas.total_pages}
                    onAdd={onAddSchema}
                    addPermission="COLLECTIONS_MANAGE"
                    />

            <div>
                {loading ? 
                <div className="flex flex-1 items-center justify-center mt-4">
                    <img src={Loading} />
                    <label className="bold-text font-16 ml-2 light-purple-text">Loading</label>
                </div>
                :
                <TransitionGroup  className="schemas-cards card mx-6 mt-4">
                    {schemas.content?.map(schema =>
                        <CSSTransition key={schema.id} appear classNames="fade" timeout={500}>
                            <div className="schema-grid border-bottom" onClick={() => navigate(`/meta/schemas/${schema.id}/attributes`)}>
                                <label className="regular-text px-6 py-4 my-2 border-right">{schema.name}</label>
                                <label className="regular-text light-purple-text px-6 py-4 my-2 border-right">{schema.description}</label>
                                <div className="flex items-center justify-between px-6 py-4 my-2">
                                    <label className="regular-text">{schema.attributes?.length} attributes</label>
                                    <DotsIcon className="cursor" width={20}></DotsIcon>
                                </div>
                            </div>
                        </CSSTransition>
                    )}
                </TransitionGroup>  
                }
            </div>
        </div>
    )
}

export default Schemas;
