import React, { useEffect, useState, useRef } from "react";

import { useParams } from "react-router-dom";
import { getOrganisations } from "../../../store/features/organisationSlice";
import { addInventoryConsignment } from "../../../store/features/inventorySlice";
import { useDispatch, useSelector } from "react-redux";

import DropDown from "../../McDropdown";

import "./style.scss";

const AddConsignmentPopup = ({onCancel, carbon}) => {
    const dispatch = useDispatch();
    const {organisations, hasMore} = useSelector(data => data.organisation)
    const { user } = useSelector(data => data.user);

    const pageRef = useRef(null);

    const {id} = useParams();

    const [organization, setOrganization] = useState();
    const [amount, setAmount] = useState();
    const [name, setName] = useState();
    const [price, setPrice] = useState();
    const [priceCurrency, setPriceCurrency] = useState({id: 0, name: 'USD'});
    const [rrp, setRrp] = useState();
    const [rrpCurrency, setRrpCurrency] = useState({id: 0, name: 'USD'});

    const [search, setSearch] = useState();

    const fetchOrganisations = async (page = 0, merge = false) => {
        pageRef.current = page;
        dispatch(getOrganisations({organisationId: user.organisations[0].external_id, page: page, search: search, merge: merge, size: 20, type: user.organisations[0].org_type === 'SERVICEOPERATOR' ? 'ACCOUNT' : 'PARTNER'}));
      };
  
      useEffect(() => {
        fetchOrganisations(0, false);
      }, [search])
 
    const addCarbon = async () => {
        const request = {
           organization: {
            id: organization.id
           },
           amount: amount * 1000,
           price: {
            price: price,
            currency: priceCurrency.name
           },
           rrp: {
            price: rrp,
            currency: rrpCurrency.name
           },
           name: name
        }

        if(organization && amount && price && priceCurrency && rrp && rrpCurrency) {
            dispatch(addInventoryConsignment({id, request}));
        }
        onCancel()
    }

    return (
        <div className='add-consignment-popup'>
            <div className='add-consignment-popup-content'>
                <label className='popup-title mt-8 mb-6 ml-4'>Add consignment</label>
                <div className="flex items-center mt-4 ml-4">
                    <label className="regular-text font-16">Organization</label>
                    <div className="ml-4" style={{width: '300px'}}>
                    <DropDown 
                        size="large" 
                        placeholder="Select organisation" 
                        options={organisations} 
                        selected={organization}
                        setSelected={p => setOrganization(p)}
                        isSearchable={true}
                        search={search}
                        setSearch={setSearch}
                        isPaged={true}
                        hasMore={hasMore}    
                        fetchData={() => fetchOrganisations(pageRef.current + 1, true)}   
                    /> 
                        </div>
                </div>
                <div className="flex items-center mt-4 ml-4">
                    <label className="regular-text font-16">Name</label>
                    <input className="input " value={name} style={{marginLeft: '65px'}}
                                           name={'name'} placeholder={'Name'} onChange={e => setName(e.target.value)}/>
                </div>
                <div className="flex items-center mt-4 ml-4">
                    <label className="regular-text font-16">Amount</label>
                    <input className="input " value={amount} type={"number"} style={{marginLeft: '50px'}}
                                           name={'purchase_price'} placeholder={0} onChange={e => setAmount(e.target.value)}/>
                </div>
                <div className="flex items-center mt-4 ml-4">
                    <label className="regular-text font-16">Sell price</label>
                    <input className="input  mr-4 " value={price} type={"number"} style={{marginLeft: '44px'}}
                                           name={'purchase_price'} placeholder={0} onChange={e => setPrice(e.target.value)}/>
                    <div className="">
                        <DropDown
                            size="large" 
                            setSelected={option => setPriceCurrency(option)}
                            options={[{id: 0, name: 'USD'}, { id: 1, name: 'GBP'}, {id: 2, name: 'EUR'}]}
                            scrollEnabled
                            selected={priceCurrency}
                            withIcon={false}
                            borderColor='rgba(181, 185, 193, 0.3)'
                        />
                    </div>
                </div>
                <div className="flex items-center mt-4 ml-4">
                    <label className="regular-text font-16">RRP</label>
                    <input className="input mr-4 " value={rrp} type={"number"} style={{marginLeft: '78px'}}
                                           name={'purchase_price'} placeholder={0} onChange={e => setRrp(e.target.value)}/>
                    <div>
                        <DropDown
                            size="large" 
                            setSelected={option => setRrpCurrency(option)}
                            options={[{id: 0, name: 'USD'}, { id: 1, name: 'GBP'}, {id: 2, name: 'EUR'}]}
                            scrollEnabled
                            selected={rrpCurrency}
                            withIcon={false}
                            borderColor='rgba(181, 185, 193, 0.3)'
                        />
                    </div>
                </div>
                <div className="flex justify-center mt-auto mb-6 mt-10">
                    <div className="flex items-center justify-center cancel-btn cursor mr-8"
                         onClick={() => onCancel(false)}>
                        <label className="medium-text color-green">Cancel</label>
                    </div>

                    <div
                        onClick={addCarbon}
                        className={`${(organization && amount && price && priceCurrency && rrp && rrpCurrency) ? 'done-btn' : 'disable-btn'} flex items-center justify-center cursor mr-8`}>
                        <label className="medium-text color-white">Save</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddConsignmentPopup;
