import React, { useEffect, useState } from 'react';
import api from '../../store/services'
import SelectedIntegrationAutomation from './selected-integration-automation';
import Loading from '../../assets/images/loading.svg';
import { uppercase } from '../../helpers/utils';

function SelectedIntegration({
  selectedIngrationObj,
  automationObj,
  actionObj,
  triggerData,
  updateAction,
  removeIntegrationFromAction,
}) {
  const [integrationData, setIntegrationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(
    actionObj?.integration?.id ? { ...actionObj.integration, params: { ...actionObj.params } } : false,
  );

  const fetchIngerationData = async () => {
    setLoading(true);
    const { data } = await api.get(`/v3/api/actions/${actionObj.action_type}/integrations`);
    setIntegrationData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (!actionObj?.integration?.id) {
      setSelectedIntegration(false);
      fetchIngerationData();
    } else {
      setSelectedIntegration({ ...actionObj.integration, params: { ...actionObj.params } });
    }
  }, [actionObj]);
  return (
    <div>
      {loading ? (
        <div className="flex items-center justify-center">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (
        <>
          {!selectedIntegration ? (
            <div className="label-container">
              <div className="mb-6">
                <label className="medium-text  grey-text font-12">{uppercase('Select Integration')}</label>
              </div>
              <div className="leads-container">
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  {integrationData.map((value, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          className="integration-boxes"
                          onClick={() => {
                            setSelectedIntegration(value);
                          }}
                          style={{
                            margin: '0 24px 16px 0',
                            padding: '12px 16px',
                            cursor: 'pointer',
                          }}>
                          <div className="flex">
                            <div className="leads-icon" style={{ margin: '0' }}>
                              <div
                                className="pxy-1 flex items-center justify-center"
                                style={{ margin: '0 8px 0 0', background: 'white', borderRadius: '4px' }}>
                                <img
                                  alt="icon"
                                  src={`${value?.connector?.icon?.active}`}
                                  style={{ width: 24, height: 24 }}
                                />
                              </div>
                            </div>
                            <div>
                              <div className="medium-text">{value?.name}</div>
                              <div className="regular-text grey-text mt-1 font-12">{value?.connector?.name}</div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <SelectedIntegrationAutomation
              actionObj={actionObj}
              automationObj={automationObj}
              removeIntegrationFromAction={removeIntegrationFromAction}
              selectedIntegrationObj={selectedIntegration}
              setSelectedIntegration={setSelectedIntegration}
              triggerData={triggerData}
              updateAction={updateAction}
            />
          )}
        </>
      )}
    </div>
  );
}

export default SelectedIntegration;
