import React, { useState, useEffect } from 'react'

import { uploadFile } from '../../store/features/accountSlice';
import { useDispatch } from 'react-redux';
import "./style.scss";
import uuid from 'react-uuid';
import moment from 'moment';


const Upload = () => {
    
    const imageExtensions = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/svg', 'image/svg+xml'];
const maxFileSize = 100 * 1024;

const FileUploadContainer = ({ file, setFile }) => {
  const onChange = value => {
  };
  const [onFile, setOnFile] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [progress, setProgress] = useState(0);  
  const dispatch = useDispatch();

  const onAddFile = addedFile => {
    console.log(addedFile);

    if (!imageExtensions.includes(addedFile.type)) {
      return setFile(null);
    }
console.log('upload')
    if (addedFile.size > maxFileSize) {
      return setFile(null);
    }
    const newFile = {
      file_data: addedFile,
      name: addedFile.name,
      size: addedFile.size,
      type: addedFile.type,
      url: URL.createObjectURL(addedFile),
      id: uuid(),
    };

    setFileUploading(true);

    dispatch(
      uploadFile({
        file: newFile?.file_data,
        onSuccess: data => {
          setFileUploading(false);
          setFile({ ...newFile, media_id: data?.media_id, media_url: data?.url, created_on: moment().unix() });
        },
        onError: error => {
          setFileUploading(false);
        },
        setProgress,
      }),
    );
  };

  const onFileDrop = e => {
    console.log(e);
    e.preventDefault();
    onAddFile(e.dataTransfer?.files[0]);
    setOnFile(true);
  };

  const onFileAdd = e => {
    e.preventDefault();
    onAddFile(e.target.files[0]);
    e.target.value = null;
    setOnFile(true);
  };

  const removeFile = () => {
    setFile(null);
  };

  return (
    <>
      <div className="flex col-gap-4 items-center">
        <div
          className={
            'border-dotted radius-1_5 flex-column items-center justify-center image-upload-box ' +
            onFile ? 'on-droparea' : 'off-droparea'
          }
          onDragLeave={e => {
            e.preventDefault();
            setOnFile(false);
          }}
          onDragOver={e => {
            e.preventDefault();
            setOnFile(true);
          }}
          onDrop={onFileDrop}>
          {fileUploading ? (
            <>
              loading
            </>
          ) : file ? (
            <div className="relative h-full w-full">
              <img src={file.url} alt="Uploaded" className="radius-1_5" style={{ width: '100%', height: '100%' }} />
              <div className="absolute top-0 right-0 cross-icon">
                cross
              </div>
            </div>
          ) : (
            <div>
              <div>
                <input type="file" id="file-upload-input" style={{ display: 'none' }} onChange={onFileAdd} />
                <label
                  htmlFor="file-upload-input"
                  className="primary-text font-12 inter-500-text line-height-20 cursor">
                  browse
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="flex-column">
          <label className="font-14 inter-500-text natural-900-text line-height-20 ">upload</label>
          <label className="font-12 inter-400-text natural-400-text line-height-20">
          </label>
          <div className="flex items-center col-gap-2 pt-2">
            <label className="font-12 inter-500-text natural-900-text line-height-20">alignment</label>
     
          </div>
        </div>
      </div>
    </>
  );
};
   
const [file, setFile] = useState(null);


    return (
        <div className="flex-column items-center w-full" >
            <FileUploadContainer  file={file} setFile={setFile} />
        </div>
    )
}

export default Upload;