import React, { useContext } from "react";
import uuid from "react-uuid";
import { ReactComponent as DragIcon } from "../../../assets/images/drag.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/images/remove-image.svg";
import { ReactComponent as AddIcon } from "../../../assets/images/add.svg";

import "./style.scss";
import { DndContainer } from "../../dnd";
import { DndCard } from "../../dnd/card";
import DropDown from "../../McDropdown";
import { OrganisationContext } from "../../../context/organisationContext";

const SchemaAttributeEdit = ({ schemaAttributes, setSchemaAttributes }) => {
  const { setModal } = useContext(OrganisationContext);

  const dataTypeOptions = [
    { id: 0, name: "String", value: "STRING" },
    { id: 1, name: "Numeric", value: "NUMERIC" },
    { id: 2, name: "Uri", value: "URI" },
    { id: 3, name: "Ipfs", value: "IPFS" },
  ];

  const updateCard = (attribute, name, value) => {
    setSchemaAttributes([
      ...schemaAttributes.map((c) =>
        c.id === attribute.id ? { ...c, [name]: value } : c
      ),
    ]);
  };

  const onAddAttribute = () => {
    setSchemaAttributes([{ id: uuid(), isNew: true }, ...schemaAttributes]);
  };

  const removeAttribute = (attribute) => {
    setSchemaAttributes([
      ...schemaAttributes.filter((c) => c.id !== attribute.id),
    ]);
  };

  const onRemoveAttribute = (attribute) => {
    setModal({
      type: "remove-schema-attribute",
      content: {
        attribute: attribute,
        onSuccess: () => removeAttribute(attribute),
        top: true,
      },
    });
  };

  return (
    <div className="flex-column edit-schema-attribute">
      <div
        className="add-new-attribute flex items-center justify-center mb-4 cursor"
        onClick={() => onAddAttribute()}
      >
        <AddIcon />
        <label className="regular-text font-12 ml-2">Add new attribute</label>
      </div>
      <DndContainer cards={schemaAttributes} setCards={setSchemaAttributes}>
        <div>
          {schemaAttributes.map((card, i) => (
            <DndCard
              key={card.id}
              index={i}
              id={card.id}
              setCards={setSchemaAttributes}
            >
              <div className="attribute-item">
                <div className="flex justify-center">
                  <DragIcon className="move-cursor" height={24} />
                </div>
                <div className="flex-column mr-4">
                  <label className="regular-text light-purple-text mb-1">
                    Name
                  </label>
                  <input
                    placeholder="Enter"
                    className="input"
                    value={card.attribute_name}
                    onChange={(e) =>
                      updateCard(card, "attribute_name", e.target.value)
                    }
                  />
                </div>
                <div className="flex-column mr-4">
                  <label className="regular-text light-purple-text mb-1">
                    Data type:
                  </label>
                  <DropDown
                    options={dataTypeOptions}
                    selected={dataTypeOptions.find(
                      (o) => o.value === card.attribute_type
                    )}
                    setSelected={(option) =>
                      updateCard(card, "attribute_type", option.value)
                    }
                    placeholder="Select"
                    hideOptions={true}
                  />
                </div>
                <div className="flex-column mr-4">
                  <label className="regular-text light-purple-text mb-1">
                    Lexicon:
                  </label>
                  <input
                    placeholder="Enter"
                    className="input"
                    value={card.lexicon}
                    onChange={(e) =>
                      updateCard(card, "lexicon", e.target.value)
                    }
                  />
                </div>
                <div className="flex justify-center items-end mb-2">
                  <RemoveIcon
                    className="cursor remove-icon"
                    height={16}
                    onClick={() => onRemoveAttribute(card)}
                  />
                </div>
              </div>
            </DndCard>
          ))}
        </div>
      </DndContainer>
    </div>
  );
};

export default SchemaAttributeEdit;
