import InviteIcon from '../../../assets/images/invite-icon.svg';
import api from '../../../store/services'
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SearchIcon } from '../../../assets/images/search-icon.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import useDebounce from '../../../helpers/useDebounceHook';
import Profile from '../../../pages/profile/profile';
import Pagination from "../../pagination/pagination";
import DeleteUserPopup from '../delete-user-popup';
import UserDeletedPopup from '../user-deleted-popup';
import UserItem from './userItem';
import Loading from '../../../assets/images/loading.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-image.svg';
import { UsersContainerWrapper, UsersWrapper } from './users.styled';
import { setUsers, setSelectedUser } from '../../../store/features/settingsSlice';

const Users = () => {
  const { user } = useSelector(state => state.user);

  const organisationId = user?.organisations[0].external_id;
  const { setModal, modal, checkPermission, hasPermission } = useContext(OrganisationContext);
  const { users, selectedUser } = useSelector(state => state.settings);

  const dispatch = useDispatch();

  const [deletedUser, setDeletedUser] = useState();
  const [deletedUserName, setDeletedUserName] = useState();
  const [selectedPage, setSelectedPage] = useState(
    Number(localStorage.getItem('init-page')) || Number(localStorage.getItem('users-page')) || 0,
  );
  const [totalPage, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);

  const debouncedSearchText = useDebounce(searchText, 500);
  const previousSearchTextRef = useRef('');
  const [initPage, setInitPage] = useState(selectedPage);

  const fetchData = async loading => {
    const size = 11;
    setLoading(loading);
    const { data } = await api.get(
      `/v1/users?page=${selectedPage}&size=${size}&name=${searchText}`,
    );
    dispatch(setUsers(data.content));
    setTotalPages(data.total_pages || 1);
    setLoading(false);
  };

  useEffect(() => {
    if (totalPage && selectedPage > totalPage - 1) {
      setSelectedPage(totalPage ? totalPage - 1 : totalPage);
    }
  }, [totalPage]);

  useEffect(() => {
    
    const call = async() => {
      if (previousSearchTextRef.current !== debouncedSearchText) {
        const currentSelectedPage = selectedPage;
        if (currentSelectedPage === 0 && debouncedSearchText !== '') {
          await fetchData(true);
        }
        if (initPage === 0 && debouncedSearchText === '') {
          await fetchData(true);
        }
        if (debouncedSearchText === '') {
          setSelectedPage(initPage);
          localStorage.setItem('users-page', initPage);
        } else {
          setSelectedPage(0);
        }
        previousSearchTextRef.current = debouncedSearchText;
      } else {
        if (previousSearchTextRef.current === '' && debouncedSearchText === '') {
          setInitPage(selectedPage);
          localStorage.setItem('init-page', selectedPage);
        }
        await fetchData(true);
      }
  
      return () => {
        selectUser({});
      };
    }
 
    call()
  }, [organisationId, selectedPage, debouncedSearchText]);

  useEffect(() => {
    if (
      modal.type === 'invite-user-success' ||
      modal.type === 'delete-user-success' ||
      modal.type === 're-invite-user-success'
    ) {
      fetchData(false);
    }
  }, [modal.type]);

  useEffect(() => {
    const selectedUser = JSON.parse(localStorage.getItem('selected-user'));
    selectUser(selectedUser);
    return () => {
      dispatch(setUsers([]));
    };
  }, []);

  const deleteUser = async () => {
    await api.delete(`/v1/organisations/${organisationId}/users/${deletedUser.id}`);
    dispatch(setUsers(users.filter(user => user.id !== deletedUser.id)));
    setDeletedUserName(`${deletedUser.name}`);
    setDeletedUser(null);
  };

  const changeDeleteUser = user => {
    setModal({
      type: 'delete-user',
      content: {
        user: user,
      },
    });
  };

  const onEditUser = user => {
    setModal({
      type: 'invite-user',
      content: {
        venues: [],
        merchant: user.organisations?.[0],
        user: user,
        organisationId: organisationId,
      },
    });
  };

  const onAvailibilityUser = user => {
    setModal({
      type: 'user-availibility',
      content: {
        user: user,
      },
    });
  };

  const changeActive = async user => {
    const names = user.name?.split(' ') || [];
    const request = { is_active: !user.is_active, forename: names[0] || '', surname: names[1] || '' };
    await api.put(`/v1/users/${user.id}`, request);
    dispatch(setUsers(users.map(u => (u.id === user.id ? { ...u, is_active: !user.is_active } : u))));
  };

  const selectUser = user => {
    localStorage.setItem('selected-user', JSON.stringify(user));
    dispatch(setSelectedUser(user));
  };

  const updateUserData = updatedSelectedUser => {
    selectUser(updatedSelectedUser);
    fetchData();
  };

  const onInviteUser = user => {
    setModal({
      type: 're-invite-user',
      content: {
        user: user,
        organisationId: organisationId,
        top: true,
      },
    });
  };

  const handleBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFocused(false);
    }
  };

  return (
    <UsersContainerWrapper>
      {selectedUser?.id ? (
        <div>
          <Profile
            profileClassName="selected-user-profile"
            selectedUser={selectedUser}
            updateUserData={updateUserData}
          />
        </div>
      ) : (
        <>
          <div className="pagination-container">
            <div
              className="user-action"
              onClick={() =>
                checkPermission(
                  () =>
                    setModal({
                      type: 'invite-user',
                      content: {
                        venues: [],
                        merchant: user.organisations[0],
                        organisationId: organisationId,
                      },
                    }),
                  'ADMINUSERS_MANAGE',
                )
              }
              style={{ opacity: hasPermission('ADMINUSERS_MANAGE') ? 1 : 0.5 }}>
              <img alt="icon" className="user-action-icon" src={InviteIcon} />
              <p className="medium-text">Add user</p>
            </div>
            <div
              className={`properties-search ${focused ? 'search-focused' : ''}`}
              onBlur={e => handleBlur(e)}
              onFocus={() => setFocused(true)}>
              <SearchIcon className="search-icon" />
              <input
                className="regular-text"
                onChange={e => {
                  setSearchText(e.target.value);
                }}
                placeholder="Search"
                value={searchText || ''}
              />
              {focused && (
                <div
                  className="close"
                  onClick={e => {
                    setSearchText('');
                  }}
                  tabIndex="0">
                  <CloseIcon className="close-icon" />
                </div>
              )}
            </div>
            <Pagination
              className="user-pagination"
              selectedPage={selectedPage}
              setSelectedPage={page => {
                setSelectedPage(page);
                localStorage.setItem('users-page', page);
              }}
              totalPages={totalPage}
            />
          </div>
          {loading ? (
            <div className="flex items-center justify-center content-loader">
              <img alt="loading" height="64px" src={Loading} />
            </div>
          ) : (
            <UsersWrapper className="scroller">
              {users?.map((user, key) => (
                <UserItem
                  key={key}
                  changeActive={changeActive}
                  changeDeleteUser={changeDeleteUser}
                  checkPermission={checkPermission}
                  hasPermission={hasPermission}
                  onAvailibilityUser={onAvailibilityUser}
                  onEditUser={onEditUser}
                  onInviteUser={onInviteUser}
                  setSelectedUser={selectUser}
                  testKey={key}
                  user={user}
                />
              ))}
              {deletedUser && (
                <DeleteUserPopup
                  close={() => setDeletedUser(null)}
                  deleteUser={deleteUser}
                  name={`${deletedUser.name}`}
                />
              )}
              {deletedUserName && <UserDeletedPopup close={() => setDeletedUserName(false)} name={deletedUserName} />}
            </UsersWrapper>
          )}
        </>
      )}
    </UsersContainerWrapper>
  );
};

export default Users;
