import styled from 'styled-components';
import { HOVER_COLOR } from '../../../constant/colorTheme';

const DropdownWrapper = styled.div`
  position: relative;
  * {
    cursor: pointer;
  }
  .selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    width: 100%;
    min-height: 40px;
    border: 1px solid #d2e5ed;
    margin-right: 35px;
    cursor: pointer;
    user-select: none;
    outline: 0;
    border: 1.5px solid rgba(181, 185, 193, 0.3);
    border-radius: 16px;\
    .dropdown-icon {
      transition: 0.3s;
    }
    .open {
      transform: rotate(-90deg);
    }
  }
  .options {
    transition: 0.3s;
    position: absolute;
    top: 50px;
    overflow-y: hidden;
    width: 100%;
    z-index: 1;
    .option-item {
      display: flex;
      align-items: center;
      padding: 10px 16px;
    }
    .option-item-selected {
      display: flex;
      align-items: center;
      background: ${HOVER_COLOR};
      padding: 10px 16px;
    }
    .option-item:hover {
      background: ${HOVER_COLOR};
    }
    .option-item-icon path {
      stroke: #053149;
    }
  }
  .mark-icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    margin-right: 16px;
  }
  .tag {
    display: flex;
    height: 24px;
    align-items: center;
    background: #d2e5ed4c;
    border-radius: 4px;
    margin-right: 8px;
    margin-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .is-optional::after {
    content: '(optional)';
    margin-left: auto;
    margin-right: 12px;
  }

  .arrow-padding {
    padding-right: 36px;
  }

  .input-arrow {
    height: 100%;
    position: absolute;
    right: 0;
    padding: 0 17px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    .dropdown-icon {
      transition: 0.3s;
    }
    .open {
      transform: rotate(-90deg);
    }
  }
`;

export default DropdownWrapper;
