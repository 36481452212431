import api from "../store/services";
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import Button from '../components/Elements/button/button';
import Dropdown from '../components/Elements/dropdown/dropdown';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { addToast } from "../store/features/toastSlice";
import { InviteUserPopupWrapper } from './invite-user-popup.styled';

const ReInviteUserModal = () => {
  const { user: loggedInUser } = useSelector(state => state.user);

  const accountOrganisationId = loggedInUser?.organisations.find(o => o.org_type === 'ACCOUNT')?.external_id;

  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState();

  const { modal, setModal } = useContext(OrganisationContext);
  const { toasts } = useSelector(state => state.toast);

  const { organisationId, user } = modal.content;

  const userEmail = user.email || '';
  const userMobile =
    (user && user?.contact_info?.find(contact => contact.contact_type === 'MOBILE')?.contact_value) || '';

  useEffect(() => {
    const fetchData = async () => {
      const rolesResponse = await api.get(`/v1/organizations/${loggedInUser.organisations[0].external_id}/roles`);
      setRoles(rolesResponse.data);
    };
    fetchData();
  }, [organisationId]);

  const save = async () => {
    if (selectedRole) {
      if (!userEmail) {
        dispatch(
          addToast({
            error: true,
            text: 'User does not have any email.',
            id: 'user-email',
          }),
        );
        return;
      }
      try {
        setLoading(true);
        await api.post(`/v1/organizations/${loggedInUser.organisations[0].external_id}/users/${user.id}/invite`);
        setLoading(false);
        dispatch(
          addToast({
            error: false,
            text: 'Invite email has been sent to the user',
            id: 'role',
          }),
        );
      setModal({ type: 're-invite-user-success', content: {} });
      } catch(e) {
        setLoading(false);
        dispatch(
        addToast({
          error: true,
          text: e.response?.data?.error_description,
          id: 'role',
        }))
      }
   
    } else {
      setError(true);
      dispatch(
        addToast({
          error: true,
          text: 'Please select the users role',
          id: 'role',
        }),
      );
    }
  };

  const getUserInviteStatus = () => {
    if (user.invitation_status === 'NOT_INVITED') {
      return 'Send';
    } else if (user.invitation_status === 'INVITED') {
      return 'Re-send';
    } else if (user.invitation_status === 'INVITATION_EXPIRED') {
      return 'Re-send';
    }
    return 'Send';
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <InviteUserPopupWrapper>
        <div className="iu-popup">
          <label className="popup-title">{`${getUserInviteStatus()} Invite`}</label>
          <div className="flex-column mb-6">
            <label className="input-label">Role</label>
            <Dropdown
              borderColor={error && !selectedRole ? '#FF5B5B' : '#d2e5ed'}
              className="dropdown-hover options-select-container"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              onSelect={options => {
                setSelectedRole(options);
              }}
              options={roles}
              placeholder="Select role"
              selected={selectedRole}
              withIcon={false}
            />
          </div>
          <div className="iu-actions">
            <Button
              className={`${!loading && 'secondary-hover'}`}
              disabled={loading}
              label="Cancel"
              onClick={() => setModal(initModal)}
              secondary={true}
              size="large"
              width="168px"
            />
            <Button
              className={`${!loading && 'primary-hover'}`}
              disabled={loading}
              label={getUserInviteStatus()}
              onClick={save}
              primary={true}
              size="large"
              style={{ marginLeft: 24 }}
              width="168px"
            />
          </div>
        </div>
      </InviteUserPopupWrapper>
    </CSSTransition>
  );
};

export default ReInviteUserModal;
