import React, { useState, useEffect, useRef } from "react";

import uuid from "react-uuid";

import { useSelector, useDispatch } from "react-redux";
import { getCollections, getCollectionIntegrations } from "../../store/features/collectionSlice";
import { getProjects } from "../../store/features/projectSlice";
import { getBrands, getFamilies } from "../../store/features/productSlice";
import { getCampaigns } from "../../store/features/campaignSlice";
import { getAccounts, getCategoryTags } from "../../store/features/accountSlice";

import DropDown from "../McDropdown";
import DateView from "../Elements/DateView";

import TrashIcon from "../../assets/images/trash-black.svg";

import './style.scss';


const Item = ({ filter, setFilter, removeFilter, nr }) => {
    const dispatch = useDispatch();
    const pageRef = useRef(null);
    const { user } = useSelector(data => data.user);

    const { accounts, categoryTags } = useSelector(data => data.account);
    const { collections, integrations } = useSelector(data => data.collection);
    const { projects } = useSelector(data => data.project);
    const { campaigns } = useSelector(data => data.campaign);
    const { brands, families } = useSelector(data => data.product);
    const nftTypeFilters = [{id: 'MERCHANDISE', name: 'Merchandise'}, {id: 'RETIREMENT', name: 'Retirement'}];
    const [search, setSearch] = useState('');

    const getFilterProps = object => {
        if(object === 'COLLECTION') return {id: uuid(), name: 'Collection', param: 'collection_id', type: object};
        else if(object === 'NFT_TYPE') return {id: uuid(), name: 'NFT type', param: 'nft_type', type: object};
        else if(object === 'INTEGRATION') return {id: uuid(), name: 'Integration', param: 'integration_id', type: object};
        else if(object === 'PROJECT') return {id: uuid(), name: 'Project', param: 'project_id', type: object, isSearchable: true, isPaged: true};
        else if(object === 'BRAND') return {id: uuid(), name: 'Brand', param: 'brand_id', type: object};
        else if(object === 'FAMILY') return {id: uuid(), name: 'Family', param: 'family_id', type: object};
        else if(object === 'PURCHASE_DATES') return {id: uuid(), name: 'Purchase dates', startParam: 'purchase_date.start', endParam: 'purchase_date.end', type: object, view: 'DATE_RANGE'};
        else if(object === 'CREATED_DATES') return {id: uuid(), name: 'Created dates', startParam: 'created_date.start', endParam: 'created_date.end', type: object, view: 'DATE_RANGE'};
        else if(object === 'MINTED_DATES') return {id: uuid(), name: 'Minted dates', startParam: 'created_date.start', endParam: 'created_date.end', type: object, view: 'DATE_RANGE'};
        else if(object === 'TRANSACTION_DATES') return {id: uuid(), name: 'Transaction dates', startParam: 'transaction_date.start', endParam: 'transaction_date.end', type: object, view: 'DATE_RANGE'};
        else if(object === 'CAMPAIGN') return {id: uuid(), name: 'Campaign', param: 'campaign_id', type: object, isSearchable: true, isPaged: true};
        else if(object === 'OWNER') return {id: uuid(), name: 'Owner', param: 'owner_id', type: object, isSearchable: true, isPaged: true};
        else if(object === 'ORGANISATION_CATEGORY') return {id: uuid(), name: 'Category', param: 'category_tag', paramValue: 'tag', type: object};
        else return {};
    }

    const fetchAccounts = (page, merge) => {
        pageRef.current = page;
        dispatch(getAccounts({organisationId: user.organisations[0].external_id, page: page, merge, type: '', search}));
    };
    
    const fetchProjects = (page, merge) => {
        pageRef.current = page;
        dispatch(getProjects({page: page, size: 10, search, merge}));
    };

        
    const fetchCampaigns = (page, merge) => {
        pageRef.current = page;
        dispatch(getCampaigns({page: page, size: 10, search, merge, active: true}));
    };

    useEffect(() => {
        if(filter.object) {
            if(filter.object.type === 'COLLECTION')
                dispatch(getCollections({page: 0, size: 20}));
            if(filter.object.type === 'OWNER')
                fetchAccounts(0, false);
            if(filter.object.type === 'INTEGRATION')
                dispatch(getCollectionIntegrations());
            else if(filter.object.type === 'PROJECT')
                fetchProjects(0, false)
            else if(filter.object.type === 'CAMPAIGN')
                fetchCampaigns(0, false)
            else if(filter.object.type === 'BRAND')
                dispatch(getBrands());
            else if(filter.object.type === 'FAMILY')
                dispatch(getFamilies());
            else if(filter.object.type === 'ORGANISATION_CATEGORY')
                dispatch(getCategoryTags());
        }
    }, [filter.object, search, dispatch ])

    const getValues = () => {
        if(filter.object.type === 'COLLECTION')
            return collections.content;
        if(filter.object.type === 'OWNER')
            return [{...user.organisations[0], id: user.organisations[0].external_id}, ...accounts.content];
        else if(filter.object.type === 'INTEGRATION')
            return integrations;
        else if(filter.object.type === 'PROJECT')
            return projects.content;
        else if(filter.object.type === 'CAMPAIGN')
            return campaigns.content;
        else if(filter.object.type === 'BRAND')
            return brands;
        else if(filter.object.type === 'FAMILY')
            return families;
        else if(filter.object.type === 'NFT_TYPE')
            return nftTypeFilters;
        else if(filter.object.type === 'ORGANISATION_CATEGORY')
            return categoryTags;
    }

    const getHasMore = () => {
        if(filter.object.type === 'COLLECTION')
            return !collections.last;
        if(filter.object.type === 'OWNER')
            return !accounts.last;
        else if(filter.object.type === 'PROJECT')
            return !projects.last
        else
            return !campaigns.last;
    }

    const fetchMore = () => {
        if(filter.object.type === 'OWNER')
            fetchAccounts(pageRef.current + 1, true)
        else if(filter.object.type === 'PROJECT')
            fetchProjects(pageRef.current + 1, true)
        else
            fetchCampaigns(pageRef.current + 1, true)
    }

    return (
        <div className="flex-column pxy-6 border-bottom">
            <div className="flex items-center justify-between">
                <label className="bold-text font-12">FILTER {nr}</label>
                <div className="flex items-center justify-center remove-filter-icon cursor" onClick={() => removeFilter(filter)}>
                    <img src={TrashIcon} alt='trash' />
                </div>
            </div>
            <label className="input-label font-12 mt-3">Object</label>
            <DropDown
                options={filter.objects?.map(o => getFilterProps(o))}
                selected={filter.objects?.map(o => getFilterProps(o)).find(o => o.type === filter.object?.type)}
                setSelected={o => setFilter({...filter, object: o, value: null})}
                placeholder="Select object"
            />
            {filter.object && 
            <div className="flex-column  mt-4">
            {filter.object.view === 'DATE_RANGE' ?
                <div className="flex">
                    <div className="flex-column flex-1 mr-4">
                        <label className="input-label font-12">Start</label>
                        <DateView value={filter.start} setValue={o => !filter.end || o < filter.end ? setFilter({...filter, start: o, value: 'range'}) : {}}/>
                    </div>
                    <div className="flex-column flex-1">
                        <label className="input-label font-12">End</label>
                        <DateView value={filter.end} setValue={o => !filter.start || o > filter.start ? setFilter({...filter, end: o, value: 'range'}) : {}} />
                    </div>
                </div>
                :
                <div className="flex-column">
                    <label className="input-label font-12">{filter.object.name}</label>
                    <DropDown
                        options={getValues()}
                        selected={filter.value}
                        setSelected={o => setFilter({...filter, value: o})}
                        placeholder={`Select ${filter.object.name.toLowerCase()}`}
                        isSearchable={filter.object.isSearchable}
                        search={search}
                        setSearch={setSearch}
                        isPaged={filter.object.isPaged}
                        hasMore={getHasMore()}    
                        fetchData={() => fetchMore()}       
                    />
                </div>
            }
            </div>
        }
        </div> 
    )
}

export default Item;