import React, { useState, useEffect, useRef } from "react";

const Success = () => {
    return (
        <div className="flex-1 items-center mt-6 ml-6">
            Payment success
        </div>
    )
}

export default Success;