import styled from 'styled-components';

export const UsersContainerWrapper = styled.div`
  .content-loader {
    position: absolute;
    inset: 0;
    margin: 0;
    opacity: 0.8;
    z-index: 10;
  }

  .pagination-container,
  .button-container {
    display: flex;
    position: fixed;
    right: 40px;
  }
  .user-action {
    display: flex;
    align-items: center;
    height: 40px;
    background: #31ddca4d;
    border-radius: 10px;
    cursor: pointer;
    padding-left: 8px;
    padding-right: 16px;

    &:hover {
      background: #2fedd84d;
    }
  }
  .user-action-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    path {
      fill: #053149;
    }
  }

  .add-team {
    display: flex;
    align-items: center;
    height: 32px;
    width: 104px;
    background: rgba(64, 241, 222, 0.3);
    border-radius: 10px;
    cursor: pointer;
    padding-left: 9px;
  }

  .user-pagination {
    height: unset;
  }

  .properties-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 230px;
    border: 1px solid #e3eef3;
    border-radius: 12px;
    background: #ffffff;
    padding: 0 16px;
    padding-right: 6px;
    user-select: none;
    margin-left: 20px;
    border: 1px solid #d2e5ed;
    margin-right: 20px;

    &:hover {
      background: #f4f7f9;

      input {
        background: #f4f7f9;
      }
    }

    input {
      width: 100%;
      border: none;
      outline: none;
      padding-left: 8px;

      &::-webkit-input-placeholder {
        opacity: 0.5;
      }
    }

    .search-icon {
      height: 16px;
      width: 16px;
      opacity: 0.5;
    }

    .close {
      background: #f4f7f9;
      border-radius: 100px;
      width: 30px;
      height: 24px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    .close-icon {
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }
  }

  .selected-user-profile {
    margin-left: 16px;
    margin-top: 60px;
  }

  .search-focused {
    border: 1px solid #40f1de;
  }
`;

export const UsersWrapper = styled.div`
  ${
    '' /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media only screen and (max-width: 2000px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (max-width: 1450px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  } */
  }

  row-gap: 24px;
  column-gap: 24px;
  margin-top: 60px;
  overflow-y: auto;
  height: calc(100vh - 90px);
  overflow: auto;
  padding: 5px 20px 20px 20px;

  .user-invite-btn {
    min-height: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #f4f7f9;
    border: 2px dashed #d2e5ed;
    border-radius: 8px;
    max-height: 200px;

    label {
      font-size: 16px;
    }
  }

  .mh {
    min-height: 80px;
  }

  .ur-user {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 5px 30px rgba(9, 19, 53, 0.06);
    border-radius: 8px;
    background: #ffffff;
    min-height: 80;
    display: grid;
    grid-template-columns: minmax(200px, 1.5fr) 2px 2fr 2px 2fr 2px 0.5fr 2px;
    flex: 170;

    .flex-wrap {
      flex-wrap: wrap;
    }

    .uru-top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      ${'' /* border-bottom: 1px solid #d2e5ed; */}
      padding: 24px;

      .urut-left {
        display: flex;
        flex-direction: column;

        .uru-name {
          font-family: Roboto Bold;
          font-size: 20px;
          color: #092c40;
        }
      }

      .urut-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        outline: none;

        .uru-state {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: #9ed086;
          border-radius: 4px;
          font-family: Roboto Medium;
          font-size: 12px;
          color: #ffffff;
          padding: 5px 8px;
        }

        .menu-btn {
          margin-left: 4px;
          height: 24px;
          width: 16px;
          cursor: pointer;
        }

        .urut-menu {
          position: absolute;
          right: 20px;
          top: -5px;
          display: flex;
          flex-direction: column;
          background: #ffffff;
          box-shadow: 0px 10px 16px rgba(9, 19, 53, 0.1);
          border-radius: 4px;
          overflow: hidden;

          .urut-menu-item * {
            cursor: pointer;
          }

          .urut-menu-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 20px 24px;
            cursor: pointer;

            label {
              font-family: Roboto Medium;
              font-size: 16px;
              color: #092c40;
              margin-left: 5px;
            }
          }

          .urut-menu-item:hover {
            background: #f8f8f8;
          }

          .urut-state {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid rgb(231, 234, 236);
            padding: 15px;

            label {
              font-family: Roboto Medium;
              font-size: 16px;
              color: #092c40;
              margin-left: 5px;
            }

            .slider {
              cursor: pointer;
            }
          }

          .active-selector {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #e5e5e5;
            padding: 24px;

            label {
              font-family: Roboto Medium;
              font-size: 16px;
              color: #092c40;
              margin-left: 5px;
            }
          }
        }
      }
    }

    .uru-roles {
      font-family: Roboto Regular;
      font-size: 14px;
      line-height: 16px;
      color: #73868c;
      opacity: 0.5;
      text-transform: lowercase;
      max-height: 16px;
    }

    .uru-middle {
      padding: 0px 20px;
      height: 100%;
      background: #ffffff;
      font-size: 14px;
      min-height: 80px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .uru-role {
        font-family: Roboto Regular;
        font-size: 14px;
        line-height: 16px;
        color: #73868c;
        opacity: 0.5;
        text-transform: lowercase;
        max-height: 16px;
      }

      .urub-email {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          min-width: 12px;
          min-height: 10px;
        }

        label {
          font-family: Roboto Regular;
          font-size: 14px;
          line-height: 16px;
          color: #092c40;
          margin-left: 10px;
        }

        .not-provided-label {
          color: rgba(5, 49, 73, 0.3);
        }
      }

      .remaining-roles {
        padding: 4px 6px;
        background: #f4f7f9;
        border-radius: 4px;
        font-size: 12px;
      }
    }

    .uru-icons {
      background: rgba(210, 229, 237, 0.3);
      border: 1px solid rgba(9, 44, 64, 0.06);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 1px;
    }
    .uru-bottom {
      display: flex;
      flex-direction: row;
      ${'' /* background: rgba(244, 247, 249, 0.5); */}
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 6px 16px;

      .uru-organisations {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;

        label {
          background: #f4f7f9;
          color: '#053149';
          box-sizing: border-box;
          border-radius: 4px;
          font-family: Roboto Medium;
          font-size: 12px;
          padding: 4px 10px;
          margin-right: 8px;
          margin-bottom: 4px;
          margin-top: 4px;
        }
      }

      .show-organisations {
        max-height: unset !important;
      }

      .organisation-empty-container {
        min-height: 64px;
        display: flex;
        align-items: center;

        .organisation-empty {
          color: rgba(115, 134, 140, 0.5);
          margin: 4px 0px;
          display: block;
          padding: 4px 10px;
          margin-right: 8px;
          margin-bottom: 4px;
          margin-top: 4px;
        }
      }

      .remaining-organisations {
        padding: 4px 6px;
        background: white;
        font-size: 12px;
      }
    }
  }

  .menu-wrapper {
    position: absolute;
    top: -5px;
    width: 200px;
    right: 25px;
    padding: 4px;
    z-index: 12;

    hr {
      margin: 8px -12px;
      width: 200px;
    }

    .regular-text {
      padding: 8px;
      display: flex;
      align-items: center;
      color: #053149;
      white-space: nowrap;
    }

    .regular-text:hover {
      background: #f4f9f8;
    }

    .border-bottom {
      border-bottom: 1px solid #d2e5ed;
    }
  }

  .display-none-class {
    height: 0;
    margin: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    color: transparent !important;
    border: unset !important;
  }

  .invite-btn {
    height: 32px;
  }


  .switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 48px;
    height: 24px;
    background: #d2e5ed;
    box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);
    border-radius: 20px;
    cursor: pointer;

    &:hover {
      background: #e5ebef;
    }

    .selector {
      min-width: 20px;
      min-height: 20px;
      background: #ffffff;
      box-shadow: 1px 0px 5px rgba(5, 49, 73, 0.2);
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
      transition: transform 400ms;
    }
  }

  .switch-selected {
    background: #40f1de;
    box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);

    &:hover {
      background: #40f1de;
    }

    .selector {
      transform: translate(22px);
    }
  }
`;
