import React, { useState, useRef, useEffect } from 'react';

import { useSelector, useDispatch } from "react-redux";
import { getCampaigns } from '../../../store/features/campaignSlice';

import ArrowDownIcon from '../../../assets/images/menu/arrow-down-black.svg';

import UncheckedIcon from '../../../assets/images/check-unchecked.svg';
import checkedIcon from '../../../assets/images/check-checked.svg';
import XIcon from '../../../assets/images/x.svg';
import Loading from '../../../assets/images/loading.svg';
import ClearIcon from '../../../assets/images/clear.svg';


import './style.scss'

const CampaignFilter = ({ selected, onSelect, close, onClear }) => {
    const ref = useRef();
    const loader = useRef(null);
    const pageRef = useRef(null);
    const dispatch = useDispatch();

    const { campaigns, hasMore } = useSelector(data => data.campaign);
    const { user } = useSelector(data => data.user);

    const values = campaigns.content?.map(a => ({...a, selected: selected.find(so => so.id === a.id)}))

    const [show, setShow] = useState(false);
    const [height, setHeight] = useState(0);
    const [edit, setEdit] = useState(false);
    const [search, setSearch] = useState();
    const [all, setAll] = useState(true);

    const fetchCamaigns= async (page = 0, merge = false) => {
        pageRef.current = page;
        dispatch(getCampaigns({ size: 20, active: true}));
      };
  
      useEffect(() => {
        fetchCamaigns(0, false);
      }, [search])

    const onBlur = e => {
        e.preventDefault();
        if (!e.currentTarget.contains(e.relatedTarget)) {
          close();  
        }
      };

    const onRemove = (e, org) => {
        setShow(false);
        e.stopPropagation();
        onSelect(org);
    };

    const select = org => {
        setEdit(false);
        onSelect(org);
        setSearch(null);
    }

    useEffect(() => {
        setHeight(ref?.current?.clientHeight + 30);
    }, [selected, edit])


    const handleObserver = entities => {
        const target = entities[0];
        if (target.isIntersecting) {
          if (hasMore)fetchCamaigns(pageRef.current + 1, true);
        }
      };

      useEffect(() => {
        // if (!infiniteSCroll) return;
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0.5,
        };
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
          observer.observe(loader.current);
        }
        return () => {
          if (loader.current) {
            observer.unobserve(loader.current);
          }
        };
      }, [loader.current]);

      useEffect(() => {
        document.getElementById('of-id').focus();
      }, []);

      const clear = () => {
        setAll(true);
        onClear();
      }

      const changeAll = () => {
        setAll(!all)
        onClear();
      }

    return (
        <div className="flex card pxy-6 absolute organisation-filter no-select relative" id="of-id" onBlur={onBlur}  tabIndex={0}>
            <div className="flex justify-between organisations-border flex-1 cursor" ref={ref} style={{borderColor: show ? '#96F1E3' : 'rgba(181, 185, 193, 0.3)'}} >
                { edit ?
                    <div className="flex items-center pl-4 py-2" onClick={() => setShow(true)}>
                        <input className="org-name-input" autoFocus value={search} onChange={e => setSearch(e.target.value)} />
                    </div>
                :
                selected?.length > 0 ? 
                    <div className="flex selected-values flex-1 mxy-05" onClick={() => {setEdit(true); setShow(true);}} >
                        {selected.map(s => 
                            <div key={s.id} className="flex items-center selected-value pl-2 pr-1 mxy-05 py-1 pl-2 pr-1">
                                <label className="regular-text font-16 mr-2">{s.name}</label>
                                <div className="flex items-center justify-center remove-btn cursor" onClick={e => onRemove(e, s)}>
                                    <img src={XIcon} alt="remove" />
                                </div>
                            </div>
                            )}
                    </div>
                    :
                    <div className='flex items-center flex-1 pl-4 py-2' onClick={() => {setEdit(true); setShow(true);}}>
                        <label className="regular-text font-16 o03">Select campaings</label>
                    </div>
                }
                <div className="input-arrow" onClick={() => {setEdit(false); setShow(!show);}}>
                    <img alt="icon" className={`dropdown-icon ${show && 'open'} cursor`} src={ArrowDownIcon} />
                </div>            
            </div>
            <div className="of-options absolute flex-column card px-4" style={{height: show ? values.length * 34 + 60 : 0, top: height}}>
                <div className="flex items-center justify-between py-4 all-border">
                    <div className="flex items-center cursor" onClick={changeAll}>
                        <img src={all && selected?.length === 0 ? checkedIcon : UncheckedIcon} alt="icon" />
                        <label className="regular-text font-16 ml-2">All</label>
                    </div>
                    <div className="flex items-center cursor" onClick={clear}>
                        <img src={ClearIcon} alt="clear" />
                        <label className="bold-text clear-text ml-1">Clear</label>
                    </div> 
                </div>
                {values?.map(value => 
                <div key={value.id} className="flex items-center py-2 cursor" onClick={() => select(value)}>
                    <img src={value.selected ? checkedIcon : UncheckedIcon} alt="icon" />
                    <label className="regular-text font-16 ml-2">{value.name}</label>
                </div>
                )}
                 {hasMore && (
                <div ref={loader} className="item flex items-center justify-center my-2">
                    <img src={Loading}/>
                </div>
            )}
            </div>
        </div>
    )
}

export default CampaignFilter;