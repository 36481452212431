import React from 'react';
import TabWrapper from './tab.styled.js';

const Tab = ({
  tabs = [],
  selected = '',
  onTabChange = () => {}
}) => {
  
  return (
    <TabWrapper>
      {tabs.map(tab => (
        <div onClick={() => onTabChange(tab)} className={`bold-text tab-item cursor ${selected === tab.value ? 'selected-item' : ''}`}>{tab.name}</div>
      ))}
    </TabWrapper>
  );
};

export default Tab;
