import React from "react";

import EditIcon from '../../assets/images/edit.svg'
import DeleteIcon from '../../assets/images/delete.svg'
import AddIcon from '../../assets/images/menu-add.svg'

import '../../assets/css/pages/business.scss'

const MerchantMenu = () => {
    return (
        <div className="card flex-column crm-menu">
            <div className="flex-column pxy-2">
                <div className="flex items-center pxy-2 cursor crm-menu-item" onClick={() => console.log('')}>
                    <img src={EditIcon} alt="edit" />
                    <label className="semibold-text ml-2">Edit merchant</label>
                </div>
                <div className="flex items-center pxy-2 cursor crm-menu-item">
                    <img src={DeleteIcon} alt="delete" />
                    <label className="semibold-text ml-2">Delete merchant</label>
                </div>
            </div>
            <div className="flex items-center px-4 py-6 cursor border-top-grey">
                <img src={AddIcon} alt="delete" />
                <label className="semibold-text ml-2 violet-light-text">Add new location</label>
            </div>
        </div>
    )
}

export default MerchantMenu;