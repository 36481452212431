import React, { useState, useRef, useEffect } from "react";

import EditDeleteMenu from "../../Elements/EditDeleteMenu";
import Button from "../../Elements/button/button";

import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCreateSchemaStatus } from "../../../store/features/schemaSlice";

const SchemaDetailsComponent = ({schema, saveSchema, isNew = false}) => {
    const descriptionRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [ edit, setEdit ] = useState(isNew)
    const [updateSchema, setUpdateSchema] = useState();
    const { createSchemaStatus } = useSelector(data => data.schema);


    useEffect(() => {
        if(descriptionRef.current) {
            descriptionRef.current.style.height = '0px';
            const scrollHeightHeadline = descriptionRef.current.scrollHeight;
            descriptionRef.current.style.height = scrollHeightHeadline + 5 + 'px';
        }
    }, [descriptionRef?.description, edit]);

    const save = async () => {
        await saveSchema(updateSchema, isNew);
        if (!isNew) {
            setEdit(false);
        }
    }

    useEffect(() => {
        if (isNew && createSchemaStatus === 'CREATED') {
         setEdit(false);
         navigate(`/meta/schemas/${schema.id}`)
        }
    }, [createSchemaStatus, isNew]);

    useEffect(() => {
        return () => {
            dispatch(setCreateSchemaStatus(null))
        }
    }, []);

    const onCancel = () => {
        if (isNew) {
            navigate(`/meta/schemas`);
        } else {
            setEdit(false);
        }
    }
    
    return (
       <div className="flex-column card mx-6 schema-details-l fit-content">
             <div className="flex items-center justify-between pxy-6 border-bottom">
                <label className="bold-text font-20">Schema details</label>
                <EditDeleteMenu onEdit={() => {setEdit(true); setUpdateSchema(schema);}} onDelete={() =>{} } />
            </div>
            {edit ? 
            <div className="flex-column pxy-6">
                <label className="input-label">Name</label>
                <input className="input" value={updateSchema?.name || ''} placeholder="Enter name" onChange={e => setUpdateSchema({...updateSchema, name: e.target.value})} />
                <label className="input-label mt-6">Description</label>
                <textarea className="textarea" value={updateSchema?.description || ''} placeholder="Enter description" ref={descriptionRef} onChange={e => setUpdateSchema({...updateSchema, description: e.target.value})} />
                <div className="flex justify-center mt-10">
                    <Button
                        className="secondary-hover"
                        label="Cancel"
                        borderRadius="12px"
                        onClick={() =>  onCancel()}
                        secondary={true}
                        size="average"
                        style={{ flex: 1 }}
                        bgColor='#FFFFFF'
                        borderColor="#24CBB1"
                        color="#24CBB1"
                        borderWidth="1px"
                    />
                    <Button
                        label='Save'
                        onClick={save}
                        size="average"
                        style={{ flex: 1, marginLeft: 24 }}
                        bgColor='#24CBB1'
                        borderColor="#24CBB1"
                        color='#FFFFFF'
                        borderRadius="12px"
                        borderWidth="1px"
                    />
                </div>
            </div>
           :
            <div className="flex-column pxy-6">
                <label className="bold-text font-16">{schema.name}</label>
                <label className="regular-text light-purple-text mt-2">{schema.description}</label>
            </div>
            }
        </div>
    )
}

export default SchemaDetailsComponent;
