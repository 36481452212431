import React, { useState, useEffect, useRef, useContext } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getRankings, getSalePeriod, getSalesPerformance } from '../../store/features/analyticsSlice';
import moment from 'moment'

import Analytics from '../../components/dashboard/analytics'
import Chart from '../../components/dashboard/chart'
import TopAccounts from '../../components/dashboard/topAccounts'
import DateSelectors from '../../components/widget/date-selectors/date-selectors'
import OrganisationFilter from '../../components/dashboard/OrganisationFilter';
import CampaignFilter from '../../components/dashboard/CampaignFilter';
import { OrganisationContext } from "../../context/organisationContext";

import { ReactComponent as UserIcon } from '../../assets/images/account.svg';
import { ReactComponent as GreenUserIcon } from '../../assets/images/account-green.svg';
import { ReactComponent as ProjectIcon } from '../../assets/images/project.svg';
import { ReactComponent as ProjectGreenIcon } from '../../assets/images/project-green.svg';
import { ReactComponent as CalendarIcon } from '../../assets/images/sales-calendar-icon.svg';

import TeamsIcon from '../../assets/images/organisationIcon.svg';

import './style.scss'

const Dashboard = () => {
    const pageRef = useRef(null);
    const dispatch = useDispatch();
    const { salePeriod, salesPerformance, rankings } = useSelector(data => data.analytics);
    const { checkPermission, hasPermission } = useContext(OrganisationContext);

    const selectors = [
        { id: 0, name: 'DAY', add: 0 },
        { id: 1, name: 'WEEK', add: 1 },
        { id: 2, name: 'MONTH', add: 0 },
        { id: 3, name: 'YEAR', add: 0 },
      ];

    const [salesDashboardUnit, setSalesDashboardUnit] = useState('MONTH');
    const [saleDates, setSaleDates] = useState({ startDate: moment().utc().subtract(1, 'month').add(1, 'day').startOf('day'), endDate: moment().utc().add(1, 'day').endOf('day'), active: false });
    const [showDateSelectors, setShowDateSelectors] = useState(false);
    const [salesPerformanceUnit, setSalesPerformanceUnit] = useState('USER')
    const [selectedOrganisations, setSelectedOrganisations] = useState([]);
    const [showOrganisationFilter, setShowOrganisationFilter] = useState(false);
    const [selectedCampaigns, setSelectedCampaigns] = useState([]);
    const [showCampaignFilter, setShowCampaignFilter] = useState(false);

    useEffect(() => {
        dispatch(getSalePeriod());
    }, [dispatch])

    useEffect(() => {
        dispatch(getSalesPerformance({startDate: saleDates.startDate.unix(), endDate: saleDates.endDate.unix(), unit:salesPerformanceUnit === 'USER' ? 'TOTAL' : salesPerformanceUnit, organisations: selectedOrganisations.map(so => so.id), campaigns: selectedCampaigns.map(so => so.id)  }));
    }, [saleDates, salesPerformanceUnit, dispatch, selectedOrganisations, selectedCampaigns])

    useEffect(() => {
        dispatch(getRankings({startDate: saleDates.startDate.unix(), endDate: saleDates.endDate.unix(), unit: salesPerformanceUnit, organisations: selectedOrganisations.map(so => so.id), campaigns: selectedCampaigns.map(so => so.id)   }));
    }, [salesPerformanceUnit, saleDates, dispatch, selectedOrganisations, selectedCampaigns])

    useEffect(() => {
        if (salesPerformance && salesPerformance.length > 0 && saleDates.active) {
          const unit = getUnit(salesPerformance[0].period);
          if (unit !== salesDashboardUnit) {
            setSalesDashboardUnit(unit);
          }
        }
      }, [salesPerformance, saleDates]);

      const getUnit = period => {
        if (period === 'HOUR') return 'DAY';
        else if (period === 'DAY_OF_WEEK') return 'WEEK';
        else if (period === 'DAY_OF_MONTH') return 'MONTH';
        else if (period === 'MONTH') return 'YEAR';
        else if (period === 'YEAR') return 'YEAR';
      };


    const onSelect = selector => {
        setSalesDashboardUnit(selector.name);
        setSaleDates(selector.name === 'DAY' ? {
            startDate: moment().utc().subtract(1, selector.name.toLowerCase()),
            endDate: moment().utc(),
            active: false,
          } : selector.name === 'YEAR' ? {
            startDate: moment().utc().subtract(1, selector.name.toLowerCase()).add(1, 'month'),
            endDate: moment().utc(),
            active: false,
          } : {
            startDate: moment().utc().startOf('day').subtract(1, selector.name.toLowerCase()).add(1, 'day'),
            endDate: moment().utc().endOf('day').add(1, 'day'),
            active: false,
          })
      };

      const removeDates = () => {
        setSalesDashboardUnit('MONTH');
       setSaleDates({
            startDate: moment().utc().startOf('month'),
            endDate: moment().utc().endOf('month'),
            active: false,
          });
      };

      const setFrom = from => {
        if (from >= moment(saleDates.endDate).unix()) {
          return;
        }
        setSaleDates({
            ...saleDates,
            startDate: moment.unix(from).startOf('day'),
            active: true,
          });
      };
    
      const setTo = to => {
        if (to <= moment(saleDates.startDate).unix()) {
      
          return;
        }
        setSaleDates({
            ...saleDates,
            endDate: moment.unix(to).endOf('day'),
            active: true,
          })
      };

      return (
        <div className='dashboard my-3 mr-3'>
            <div className='dashboard-analytics'>
                <Analytics title='THIS YEAR' value={salePeriod.year}/>
                <Analytics title='THIS MONTH' value={salePeriod.month}/>
                <Analytics title='THIS WEEK' value={salePeriod.week}/>
                <Analytics title='TODAY'value={salePeriod.day} />
            </div>
        <div className="sale-selectors flex items-center mxy-3">
          <label className='bold-text font-16 light-purple-text mr-4'>Display data by:</label>
            <div className="flex items-center pxy-1 unit-selectors">
                {selectors.map(selector => (
                <div
                    key={selector.id}
                    className={`flex items-center justify-center unit-selector cursor ${
                    selector.name === salesDashboardUnit && !saleDates.active && 'card'
                    }`}
                    onClick={() => onSelect(selector)}>
                    <label className="bold-text font-12">{selector.name}</label>
                </div>
                ))}
            </div>
            <div className="flex relative">
                <div
                className="flex items-center justify-center cursor nav-btn date-selector-btn"
                onClick={() => setShowDateSelectors(!showDateSelectors)}
                style={{ background: saleDates.active || showDateSelectors ? '#053149' : '#F4F7F9' }}>
                <CalendarIcon fill={saleDates.active || showDateSelectors ? '#40F1DE' : '#053149'} />
                </div>
                {showDateSelectors && (
                <DateSelectors
                    close={() => setShowDateSelectors(false)}
                    from={saleDates.startDate.unix()}
                    removeDates={removeDates}
                    setFrom={from => {
                    setFrom(from);
                    }}
                    setTo={to => {
                    setTo(to);
                    }}
                    to={saleDates.endDate.unix()}
                />
                )}
            </div>
            <div className='flex relative ml-6'>
              <div
                  className="flex items-center justify-center cursor relative nav-btn px-2"
                  onClick={() =>  checkPermission(() => setShowOrganisationFilter(!showOrganisationFilter), 'ORGANIZATIONS_VIEW')}
                  style={{ borderColor: showOrganisationFilter ? '#24CBB1' : '#F6F6F6', opacity: hasPermission('ORGANIZATIONS_VIEW') ? 1 : 0.5 }}>
                    <img src={TeamsIcon} alt="organisations"/>
                    <label className="regular-text ml-2">Organisations</label>
                    <label className="regular-text ight-green-text ml-2">{selectedOrganisations.length}</label>
              </div>
              {showOrganisationFilter &&
                <OrganisationFilter 
                  selected={selectedOrganisations} 
                  onSelect={org => selectedOrganisations.find(so => so.id === org.id) ? setSelectedOrganisations(selectedOrganisations.filter(so => so.id !== org.id)) : setSelectedOrganisations([...selectedOrganisations, org])}
                  close={() => setShowOrganisationFilter(false)}
                  onClear={() => setSelectedOrganisations([])}
                />
              }
            </div>
            <div className='flex relative'>
              <div
                  className="flex items-center justify-center cursor relative nav-btn px-2"
                  onClick={() =>  checkPermission(() => setShowCampaignFilter(!showCampaignFilter), 'ORGANIZATIONS_VIEW')}
                  style={{ borderColor: showCampaignFilter ? '#24CBB1' : '#F6F6F6', opacity: hasPermission('ORGANIZATIONS_VIEW') ? 1 : 0.5 }}>
                    <label className="regular-text ml-2">Campaigns</label>
                    <label className="regular-text ight-green-text ml-2">{selectedCampaigns.length}</label>
              </div>
              {showCampaignFilter &&
                <CampaignFilter 
                  selected={selectedCampaigns} 
                  onSelect={org => selectedCampaigns.find(so => so.id === org.id) ? setSelectedCampaigns(selectedCampaigns.filter(so => so.id !== org.id)) : setSelectedCampaigns([...selectedCampaigns, org])}
                  close={() => setShowCampaignFilter(false)}
                  onClear={() => setSelectedCampaigns([])}
                />
              }
            </div>
            <div
                className="flex items-center justify-center cursor relative nav-btn ml-6 px-2"
                onClick={() => {
                localStorage.setItem('timeline-chart-filter-type', 'USER');
                if (salesPerformanceUnit !== 'USER') localStorage.removeItem('timeline-chart-filter');
                setSalesPerformanceUnit('USER');
                }}
                style={{ background: salesPerformanceUnit === 'USER' ? '#053149' : '#F4F7F9' }}>
                {salesPerformanceUnit === 'USER'  ? <GreenUserIcon /> : <UserIcon /> }
                <label className="regular-text ml-2" style={{color: salesPerformanceUnit === 'USER' ? '#40F1DE' : '#053149'}}>Accounts</label>
            </div>
            <div
                className="flex items-center justify-center cursor ml-4 relative nav-btn px-2"
                onClick={() => {
                localStorage.setItem('timeline-chart-filter-type', 'TEAM');
                if (salesPerformanceUnit !== 'TEAM') localStorage.removeItem('timeline-chart-filter');
                setSalesPerformanceUnit('TEAM');
                }}
                style={{ background: salesPerformanceUnit === 'TEAM' ? '#053149' : '#F4F7F9' }}>
                {salesPerformanceUnit === 'TEAM'  ? <ProjectGreenIcon /> : <ProjectIcon /> }
                <label className="regular-text ml-2" style={{color:  salesPerformanceUnit === 'TEAM'  ? '#40F1DE' : '#053149'}}>Projects</label>
            </div>
            </div>
            <div className='dashboard-content'>
                <Chart salesPerformance={salesPerformance} salesDashboardUnit={salesDashboardUnit} saleDates={saleDates} salesPerformanceUnit={salesPerformanceUnit} />
                <TopAccounts unit={salesPerformanceUnit} rankings={rankings.content}/>
            </div>
        </div>
    )
}

export default Dashboard;