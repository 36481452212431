import React, {useState, useEffect, useCallback, useRef} from 'react'

import { Route, Routes, useLocation } from "react-router-dom";

import DropDown from '../../components/McDropdown';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { getAccounts } from '../../store/features/accountSlice';

import NavTop from '../../components/common/NavTop'
import { capitalize } from '../../helpers/utils';
import DatePicker from "react-datepicker";
import { exportReport } from '../../store/features/reportsSlice';

import CalendarIcon from '../../assets/images/small-calendar.svg';

import ReportInventory from '../../components/report/inventory';
import ReportSales from '../../components/report/sales';
import ReportCombined from '../../components/report/combined';

import "react-datepicker/dist/react-datepicker.css";

import './style.scss'

const ReportDetails = () => {
    const location = useLocation();
    const name = location.pathname.split("/")[2]
    const report = {id: 1, name: capitalize(name)}
    const exportType = name === 'retirements' ? 'RETIREMENT' : name === 'sales' ? 'SALE' : 'COMBINED';

    const [selected, setSelected] = useState( {id: 1, name: "Last Month", startDate: moment().subtract(1, 'month').startOf('month').unix(), endDate: moment().subtract(1, 'month').endOf('month').unix(), totalText: 'Month Total'})

    const dateOptions = [
        {id: 1, name: "This Month", startDate: moment().startOf('month').unix(), endDate: moment().endOf('month').unix(), totalText: 'Month Total'}, 
        {id: 2, name: "Last Month", startDate: moment().subtract(1, 'month').startOf('month').unix(), endDate: moment().subtract(1, 'month').endOf('month').unix(), totalText: 'Month Total'}, 
        {id: 3, name: "Last Quarter", startDate: moment().subtract(1, 'quarter').startOf('quarter').unix(), endDate: moment().subtract(1, 'quarter').endOf('quarter').unix(), totalText: 'Quarter Total'},
        {id: 4, name: "Last Year", startDate: moment().subtract(1, 'year').startOf('year').unix(), endDate: moment().subtract(1, 'year').endOf('year').unix(), totalText: 'Year Total'},
        {id: 5, name: "This Year", startDate: moment().startOf('year').unix(), endDate: moment().unix(), totalText: 'Year Total'},
        {id: 6, name: "Custom", startDate: selected.startDate, endDate: selected.endDate, totalText: 'Total'}]

    const [selectedOrganization, setSelectedOrganization] = useState({"id": "all", "name": "All"})

    const dispatch = useDispatch();
    const pageRefBeneficiary = useRef(null);

    const { user, organisations } = useSelector(data => data.user);
    const { accounts } = useSelector(data => data.account);

    const fetchAccounts  = useCallback((page, merge, user)  => {
        pageRefBeneficiary.current = page;
        dispatch(getAccounts({organisationId: user.organisations[0].external_id, page: page, merge: merge, type: 'PROJECT_OWNER', search: ''}));
    }, [dispatch]);

    useEffect(() => {
        fetchAccounts(0, false, user);
    }, [user, fetchAccounts]);


    const onExportReport = () => {
        dispatch(exportReport({type: exportType, startDate: selected.startDate, endDate: selected.endDate, organizationId: selectedOrganization?.id === 'all' ? null : selectedOrganization?.id}))
    }

    return(
        <div className='flex-column pxy-6 flex-1'>
            <NavTop names={[{text: "Reports", path: "/reports"}, {text: capitalize(name)}]} edit={false} done={false}/>
            <div className="card flex-column card flex-1 mt-6">
                <div className='flex items-center justify-between pxy-6'>
                    <label className='bold-text font-20'>{report.name}</label>
                    <div className='flex items-center'>
                        {organisations[0].org_type === 'SAAS_OPERATOR' && <div className='flex mr-6'>
                            <DropDown 
                                className="report-org-dd"
                                size="medium" 
                                placeholder="Select organization" 
                                selected={selectedOrganization}
                                setSelected={c => setSelectedOrganization(c)}
                                options={[{"id": "all", "name": "All"}, ...organisations.map(o => ({...o, id: o.external_id})), ...accounts.content]}
                                isPaged={true}
                                hasMore={!accounts.last}    
                                fetchData={() => fetchAccounts(pageRefBeneficiary.current + 1, true, user)}   
                            />
                        </div>
                        }
                        {selected?.name === 'Custom' &&
                        <div className='flex items-center mr-4'>
                             <DatePicker
                                    className="small-input mc-date-input mr-2"
                                    selected={moment.unix(selected.startDate || moment().unix()).toDate()}
                                    onChange={(date) => setSelected({...selected,  startDate: moment(date).unix()})}
                                    dropdownMode="select"
                                    showMonthDropdown
                                    showYearDropdown
                                    adjustDateOnChange
                                    placeholderText='Start Date'
                                />
                                -
                                <DatePicker
                                    className="small-input mc-date-input ml-2"
                                    selected={moment.unix(selected.endDate || moment().unix()).toDate()}
                                    onChange={(date) => setSelected({...selected,  endDate: moment(date).unix()})}
                                    dropdownMode="select"
                                    showMonthDropdown
                                    showYearDropdown
                                    adjustDateOnChange
                                    placeholderText='Start Date'
                                />
                        </div>
                        }
                        <DropDown 
                            size="medium"
                            options={dateOptions} 
                            selected={selected} 
                            setSelected={p => setSelected(p)} 
                            placeholder="Select"
                            selectedIcon={true}
                            getIcon={(option) => { return CalendarIcon}}
                        />
                       <div className="flex items-center justify-center add-btn cursor ml-4" style={{height: '32px', width: '112px'}}  onClick={onExportReport} >
                            <label className="bold-text font-14 violet-light-text ml-2">Export</label>
                        </div>
                    </div>
                </div>
                <Routes>
                    <Route element={<ReportInventory organisation={selectedOrganization} selected={selected} />} path={`/retirements`}   />
                    <Route element={<ReportSales organisation={selectedOrganization} selected={selected} />} path={`/sales`}   />
                    <Route element={<ReportCombined organisation={selectedOrganization} selected={selected} />} path={`/combination`}   />
                </Routes>
            </div>
        </div>
    )
}

export default ReportDetails;