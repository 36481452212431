import { createSlice } from "@reduxjs/toolkit";
import api from '../../store/services'
import { uploadMedia } from "../services/media";
import { addToast } from "./toastSlice";
import uuid from "react-uuid";

export const projectSlice = createSlice({
    name: "project",
    initialState: {
      projects: {},
      loading: false,
      project: null,
      mediaGroups: [],
      media: [],
      supporters: {},
      sdgs: [],
      carbon: [],
    },
    reducers: {
      setProjects: (state, { payload }) => {
          state.projects = payload;
      },
      addProjects: (state, { payload }) => {
        state.projects = {...payload, content: [...state.projects.content, ...payload.content]}
      },
      setProject: (state, { payload }) => {
        state.project = payload;
      },
      setMediagroups: (state, { payload }) => {
        state.mediaGroups = payload;
      },
      addMediaGroup: (state, { payload}) => {
        state.mediaGroups = [...state.mediaGroups, payload]
      },
      setMedia: (state, { payload}) => {
        state.media = payload;
      },
      removeMedia : (state, { payload}) => {
        state.media = state.media.filter(m => m.media_external_id !== payload);
      },
      addMedia: (state, { payload}) => {
        state.media = [...state.media, payload];
      },
      setSupporters: (state, { payload}) => {
        state.supporters = payload;
      },
      setSdgs: (state, { payload}) => {
        state.sdgs = payload;
      },
      setCarbon: (state, { payload}) => {
        state.carbon = payload;
      },
      addCarbon: (state, { payload}) => {
        state.carbon = [...state.carbon, payload];
      },
      setLoading: (state, { payload}) => {
        state.loading = payload;
      },
      setImage: (state, { payload}) => {
        state.project = {...state.project, image: payload };
      },
    }
  });

export const getProjects = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await api.get(`/v3/api/backoffice/projects?page=${payload.page}&size=${payload.size || 8}&search=${payload.search}&is_published=${payload.isPublished || true}`)
  if(payload.merge)
    dispatch(addProjects(data));
  else
    dispatch(setProjects(data));
  dispatch(setLoading(false));
};

export const getProject = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await api.get(`/v3/api/backoffice/projects/${payload}`);
  dispatch(setProject(data));
  dispatch(setLoading(false));
};

export const getMediaGroups = payload => async dispatch => {
  const { data } = await api.get(`/v3/api/backoffice/projects/${payload}/mediagroups`)
  dispatch(setMediagroups(data.map((mg, i) => ({...mg, selected: i === 0}))));
};

export const createMediaGroup = payload => async dispatch => {
  const { data } = await api.post(`/v3/api/mediagroup`, { name: payload.name })
  await api.post(`/v3/api/backoffice/projects/${payload.projectId}/mediagroups`, {id: data.id});
  dispatch(addMediaGroup({id: data.id, name: payload.name}))
};

export const getMedia = payload => async dispatch => {
  const { data } = await api.get(`/v3/api/mediagroups/${payload.id}/media`)
  dispatch(setMedia(data));
};

export const deleteMedia = payload => async dispatch => {
  api.delete(`/v3/api/mediagroup/${payload.mediaGroupId}/media/${payload.mediaId}`);
  dispatch(removeMedia(payload.mediaId));
};

export const createMedia = payload => async dispatch => {
  const media = await uploadMedia(payload.file, payload.selectedMediaGroup);
  dispatch(addMedia({...media, media: [{url: URL.createObjectURL(payload.file)}]}));
};

export const getSupporters = (payload) => async (dispatch) => {
  const {data} = await api.get(`/v3/api/backoffice/projects/${payload.projectId}/rankings?effective.start=${payload.start}&effective.end=${payload.end}`)
  dispatch(setSupporters(data));
};

export const getSdgs = (payload) => async (dispatch) => {
  const {data} = await api.get(`/v3/api/backoffice/projects/${payload}/sdg`)
  dispatch(setSdgs(data));
};

export const getCarbon = (payload) => async (dispatch) => {
  const { data } = await api.get(`/v3/api/backoffice/projects/${payload}/carbon`)
  dispatch(setCarbon(data));
};

export const createCarbon = payload => async dispatch => {
  const { data } = await api.post(`/v3/api/backoffice/carbon_inventory`, payload)
  dispatch(addCarbon(data))
};

export const uploadImage = payload => async dispatch => {
  const media = await uploadMedia(payload.file, payload.selectedMediaGroup);
  dispatch(setImage({...media, media: [{url: URL.createObjectURL(payload.file)}]}));
};

export const saveProject = (payload) => async (dispatch) => {
  try {
    await api.put(`/v3/api/backoffice/projects/${payload.id}`, payload)
    dispatch(
      addToast({
        error: false,
        title: 'Project updated',
        text: `${payload.name} has been updated`,
        id: uuid(),
      }),
    );
  } catch (e) {
    dispatch(
      addToast({
        error: true,
        text: 'Error while updating project',
        id: uuid(),
      }),
    );
  }
};

export const addProject = (payload) => async (dispatch) => {
  try {
    const { data } = await api.post(`/v3/api/backoffice/projects`, payload)
    dispatch(
      addToast({
        error: false,
        title: 'Project created',
        text: `${payload.name} has been created`,
        id: uuid(),
      }),
    );
    return data;
  } catch (e) {
    dispatch(
      addToast({
        error: true,
        text: 'Error while creating project',
        id: uuid(),
      }),
    );
  }
};

export const deleteProject = (payload) => async (dispatch) => {
  await api.delete(`/v3/api/backoffice/projects/${payload}`)
};

export const { setProjects, addProjects, setProject, setMediagroups, addMediaGroup, setMedia, removeMedia, addMedia, setSupporters, setSdgs, setCarbon, addCarbon, setLoading, setImage } = projectSlice.actions;
export default projectSlice.reducer;