import React, { useState, useEffect } from 'react'

import Axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';

import Logo from '../../assets/images/logo.svg'
import RefreshIcon from '../../assets/images/refresh-icon.svg'
import ErrorIcon from '../../assets/images/error.svg'
import ShowPassword from '../../assets/images/show-password.svg'
import HidePassword from '../../assets/images/hide-password.svg'

import "./style.scss";


const Activate = () => {
    const navigate = useNavigate();

    const [passwordActive, setPasswordActive] = useState();
    const [password, setPassword] = useState();
    const [confimPassword, setConfirmPassword] = useState();
    const [passwordError, setPasswordError] = useState();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const token = location.search.replace('?token=', '');

    // const isTokenExpired = moment().unix() > tokenData?.exp;

    useEffect(() => {
        setPasswordActive(password && confimPassword && password.length > 2 && confimPassword.length > 2)
    }, [password, confimPassword])


    const activate = async () => {
        if (passwordActive) {
            if (password !== confimPassword) setPasswordError('Passwords do not match');
            else {
                Axios.post(
                    `${process.env.REACT_APP_API_URL}/v1/users/activate`,
                    {
                        password: password,
                    },
                    { headers: { authorization: `Bearer ${token}` } },
                )
                    .then(() => {
                        navigate("/login")
                    })
                    .catch(() => {
                        setPasswordError('Something happened');
                    });
            }
        }
    };

    return (
        <div className="activate-page flex-column items-center w-full" >
            <div className="flex-column items-center fp-content">
                <img className="logo mt-20" src={Logo} />
                <label className='bold-text font-24 white-text mt-12'>Activate your account</label>
                {passwordError &&
                    <div className="flex password-error pxy-1 mt-6">
                        <img src={ErrorIcon} alt="error" />
                        <label className="medium-text white-text ml-2">{passwordError}</label>
                    </div>
                }
                <div className="flex-column relative mt-6">
                    <label className='regular-text white-text font-12 mb-2'>New password</label>
                    <div className='input-container'>
                        <input
                            placeholder="Enter new password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={showPassword ? 'text' : 'password'}
                        />
                        <img className="show-password" src={showPassword ? HidePassword : ShowPassword} alt="show password" onClick={() => setShowPassword(!showPassword)} />
                    </div>
                </div>
                <div className="flex-column mt-6 relative">
                    <label className='regular-text white-text font-12 mb-2'>Confirm new password</label>
                    <div className='input-container'>
                        <input
                            placeholder="Confirm new password"
                            value={confimPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type={showPassword ? 'text' : 'password'}
                        />
                        <img className="show-password" src={showPassword ?  HidePassword : ShowPassword} alt="show password" onClick={() => setShowPassword(!showPassword)} />
                    </div>
                </div>
                <button style={{ backgroundColor: passwordActive ? '#10F3CF' : '#217E70', color: passwordActive ? '#ffffff' : 'rgba(255, 255, 255, 0.3)' }} onClick={activate}>Activate</button>
            </div>
        </div>
    )
}

export default Activate;