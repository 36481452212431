import styled from 'styled-components';

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-family: Roboto Bold;
  font-style: normal;
  width: ${props => props.width};
  cursor: pointer;
  padding: 8px;
  font-weight: 600;
  color: ${props => props.color};
  background: ${props => props.bgColor};
  height: ${props => props.height};
  font-size: ${props => props.fontSize};
  border: ${props => props.borderWidth} solid ${props => props.borderColor};
  border-radius: ${props => props.borderRadius};

  * {
    cursor: pointer;
  }
  &:active {
    opacity: 0.8;
  }
  svg {
    margin-right: 4px;
    width: 12px;
    height: 12px;
    * {
      fill: ${props => props.color};
    }
  }
  label {
    margin: 0px auto;
    font-weight: 400;
    color: ${props => props.color};
  }
`;

export default ButtonWrapper;
