import Loading from '../../assets/images/loading.svg';
import { ReactComponent as EmailIcon } from '../../assets/images/email.svg';
import React, { useContext, useEffect, useState } from 'react';
import api from '../../store/services'
import AutomationLogViewWrapper from './automation-log-view.styled';
import moment from 'moment';
import { capitalize, formatText } from '../../helpers/utils';
import { ReactComponent as TimeIcon } from '../../assets/images/time.svg';
import { useNavigate, useParams } from 'react-router-dom';
import AutomationLogsWrapper from './automation-logs.styled';
import ArrowRightIcon from '../../assets/images/menu/arrow-right-black.svg';
import VectorIcon from '../../assets/images/vector.svg';
import { ReactComponent as DotsIcon } from '../../assets/images/menu/dots.svg';
import { MenuWrapper } from './menu.styled';
import { OrganisationContext } from '../../context/organisationContext';

const AutomationLogView = ({ automationLog }) => {
  const navigator = useNavigate();
  const { id } = useParams();
  const [automationLogViewData, setAutomationLogViewData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const { setModal } = useContext(OrganisationContext);

  const fetchAutomationLogViewData = async () => {
    setLoading(true);
    const { data } = await api.get(`/v3/api/automation_logs/${id}`);
    setAutomationLogViewData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      fetchAutomationLogViewData();
    }
  }, [id]);

  const getStatus = al_status => {
    if (!al_status) return 'No status';
    if (al_status === 'Unmatched (verified)') return 'Unmatched';
    else return capitalize(formatText(al_status, ' '));
  };

  const getStatusColor = al_status => {
    switch (al_status) {
      case 'SUCCESS':
        return '#7BD77A';
      case 'FAILED':
      case 'PARTIAL_FAIL':
      case 'FAILURE':
        return '#FF5B5B';
      case 'PENDING':
        return '#F6BB23';
      case 'CANCELLED':
        return '#73868C';
      default:
        return '#73868C';
    }
  };

  const onSuccess = action => {
    setAutomationLogViewData({
      ...automationLogViewData,
      actions: automationLogViewData.actions.map(a => (a.id === action.id ? { ...a, status: 'CANCELLED' } : { ...a })),
    });
  };

  const clickHandler = (action, index) => {
    setModal({
      type: action.status === 'PENDING' ? 'cancelled-automation-log' : 'retry-automation-log',
      content: { action: action, top: true },
      index: index + 1,
      onSuccess: () => onSuccess(action),
    });
  };

  return (
    <AutomationLogsWrapper className="flex flex-1 flex-column mx-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center" style={{ height: '72px' }}>
          <label className="regular-text grey-text hover-underline">Platform</label>
          <img className="mx-2" src={ArrowRightIcon} />
          <label
            className={id ? 'regular-text grey-text hover-underline cursor' : 'bold-text'}
            onClick={() => navigator('/platform/automation/log')}>
            Automation Log
          </label>
          <img className="mx-2" src={ArrowRightIcon} />
          <label className="bold-text">{id}</label>
        </div>
      </div>
      <AutomationLogViewWrapper className="flex flex-1 flex-column">
        {loading ? (
          <div className="flex justify-center items-center flex-1 mb-20 content-loader">
            <img alt="loading" height="64px" src={Loading} width="64px" />
          </div>
        ) : (
          <div className="automation-log-view">
            <div className="left-panel">
              <div className="log-details">
                <div className="header-text">
                  <label className="bold-text">AUTOMATION LOG</label>
                </div>
                <hr />
                <div className="details-text">
                  <div className="info">
                    <label className="regular-text grey-text font-16">Name:</label>
                    <label className="medium-text font-16">{automationLogViewData.automation?.name}</label>
                  </div>
                  <div className="info">
                    <label className="regular-text grey-text font-16">ID:</label>
                    <label className="medium-text font-16">{automationLogViewData.id}</label>
                  </div>
                  <div className="info">
                    <label className="regular-text grey-text font-16">Date:</label>
                    <label className="medium-text font-16">
                      {!automationLogViewData.created_on
                        ? '-'
                        : `${moment(automationLogViewData.created_on * 1000).format('DD/MM/YY - hh:mm A')}`}
                    </label>
                  </div>
                  <div className="info">
                    <label className="regular-text grey-text font-16">Status:</label>
                    <div className="">
                      <label
                        className="medium-text font-16 status"
                        style={{ background: getStatusColor(automationLogViewData.status) }}>
                        {getStatus(automationLogViewData.status) || '-'}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="event-details">
                <div className="header-text">
                  <label className="bold-text">EVENT</label>
                </div>
                <hr />
                <div className="details-text">
                  <div className="info">
                    <label className="regular-text grey-text font-16">Name:</label>
                    <label className="medium-text font-16">
                      {getStatus(automationLogViewData.event?.event_type) || '-'}
                    </label>
                  </div>
                  <div className="info">
                    <label className="regular-text grey-text font-16">ID:</label>
                    <label className="medium-text font-16">{automationLogViewData.event?.id || '-'}</label>
                  </div>
                  <div className="info">
                    <label className="regular-text grey-text font-16">Date:</label>
                    <label className="medium-text font-16">
                      {!automationLogViewData.event?.created_on
                        ? '-'
                        : `${moment(automationLogViewData.event?.created_on * 1000).format('DD/MM/YY - hh:mm A')}`}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-panel flex-1">
              {automationLogViewData.actions?.length > 0 ? (
                automationLogViewData.actions.map((action, index) => (
                  <>
                    <div className={`action-container ${index > 0 && 'show-line'}`}>
                      {index > 0 && <div className="line" />}
                      <div className="header-text">
                        <label className="bold-text">ACTION {index + 1}</label>
                        <div
                          className="data-match-item flex items-center flex-row menu-container"
                          onBlur={() => setShowMenu(false)}
                          tabIndex={0}>
                          {['PENDING', 'FAILED', 'FAILURE'].includes(action.status) && (
                            <DotsIcon className="menu-btn" onClick={() => setShowMenu(action.id)} />
                          )}
                          {['PENDING', 'FAILED', 'FAILURE'].includes(action.status) && action.id === showMenu && (
                            <MenuWrapper className="menu">
                              <label className="regular-text" onClick={() => clickHandler(action, index)}>
                                {action.status == 'PENDING' ? 'Cancel' : 'Retry'}
                              </label>
                            </MenuWrapper>
                          )}
                        </div>
                      </div>
                      <hr />
                      <div className="action-details">
                        <div className="flex-column">
                          <div className="flex">
                            <div className="mr-1">
                              <EmailIcon height={24} width={24} />
                            </div>
                            <div className="flex-column">
                              <label className="medium-text font-16">{action?.action?.action_type?.name}</label>
                              {action?.action?.action_type?.description && (
                                <label className="regular-text grey-text font-12">
                                  {action?.action?.action_type?.description}
                                </label>
                              )}
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                              <div className="status" style={{ background: getStatusColor(action.status) }}>
                                <label>{getStatus(action.status)}</label>
                              </div>
                            </div>
                          </div>
                          {(action.status_message || action.status === 'FAILURE') && (
                            <div className="status_message">
                              <div className="flex">
                                <img className="mx-2 message-icon" src={VectorIcon} />
                                <div className="regular-text message-text">
                                  {action.status_message || 'No error message'}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <hr />
                      <div className="integration-details">
                        <div className="flex-column">
                          <div className="flex">
                            <img
                              className="mr-2"
                              src={`${action?.action?.integration?.connector.icon?.active}`}
                              style={{ width: 25, height: 25 }}
                            />
                            <div className="integration-text bold-text regular-text">
                              {action?.action?.integration?.connector.name}
                            </div>
                          </div>
                          <div className="mt-4 flex-column">
                            {action?.action?.params?.length > 0 &&
                              action?.action?.params.map(data => {
                                return (
                                  <div key={data.id} className="flex mb-2">
                                    <label className="regular-text grey-text">
                                      {capitalize(formatText(data.param_type, ' '))}:
                                    </label>
                                    <label className=" mx-2 medium-text" style={{ wordBreak: 'break-all' }}>
                                      {data.param_value}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <hr />
                      {action?.action?.schedule?.offset !== null &&
                        action?.action?.schedule?.unit &&
                        action?.action?.schedule?.field && (
                          <>
                            <div className="pxy-6 flex">
                              <TimeIcon />
                              <label className="regular-text ml-1">{`${Math.abs(action?.action?.schedule?.offset)}
                              ${formatText(action?.action?.schedule?.unit, ' ')}
                              ${action?.action?.schedule?.offset < 0 ? 'before' : 'after'}
                              ${action?.action?.schedule?.field.toLowerCase().replaceAll('.', ' ')}`}</label>
                            </div>
                            <hr />
                          </>
                        )}
                      {action?.action?.conditions.length > 0 &&
                        action?.action?.conditions.map((condition, index) => {
                          return (
                            <div className="pxy-6 flex">
                              <label className="regular-text">
                                {index == 0 ? 'When' : 'And'} {condition?.field} =
                              </label>
                              <label className="medium-text ml-1">{condition?.value}</label>
                            </div>
                          );
                        })}
                    </div>
                  </>
                ))
              ) : (
                <div className="flex items-center justify-center no-action-container">
                  <label className="regular-text grey-text font-20">No action available</label>
                </div>
              )}
            </div>
          </div>
        )}
      </AutomationLogViewWrapper>
    </AutomationLogsWrapper>
  );
};

export default AutomationLogView;
