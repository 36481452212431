import React, { useEffect, useState } from 'react';
import Checkbox from "../checkbox";

const SDGDataList = [
    {
        value: 1,
        icon: '/project-detail/SDG-1.svg',
        name: 'No poverty'
    },
    {
        value: 2,
        icon: '/project-detail/SDG-2.svg',
        name: 'Zero hunger'
    },
    {
        value: 3,
        icon: '/project-detail/SDG-3.svg',
        name: 'Good health and well-being'
    },
    {
        value: 4,
        icon: '/project-detail/SDG-4.svg',
        name: 'Quality education'
    },
    {
        value: 5,
        icon: '/project-detail/SDG-5.svg',
        name: 'Gender equality'
    },
    {
        value: 6,
        icon: '/project-detail/SDG-6.svg',
        name: 'Clean water and sanitation'
    },
    {
        value: 7,
        icon: '/project-detail/SDG-7.svg',
        name: 'Affordable and clean energy'
    },
    {
        value: 8,
        icon: '/project-detail/SDG-8.svg',
        name: 'Decent work and economic growth'
    },
    {
        value: 9,
        icon: '/project-detail/SDG-9.svg',
        name: 'Industry, innovation and infrastructure'
    },
    {
        value: 10,
        icon: '/project-detail/SDG-10.svg',
        name: 'Reduced inequalities'
    },
    {
        value: 11,
        icon: '/project-detail/SDG-11.svg',
        name: 'Sustainable cities and communities'
    },
    {
        value: 12,
        icon: '/project-detail/SDG-12.svg',
        name: 'Responsible consumption and production'
    },
    {
        value: 13,
        icon: '/project-detail/SDG-13.svg',
        name: 'Climate action'
    },
    {
        value: 14,
        icon: '/project-detail/SDG-14.svg',
        name: 'Life below water'
    },
    {
        value: 15,
        icon: '/project-detail/SDG-15.svg',
        name: 'Peace, justice and strong institutions'
    },
    {
        value: 16,
        icon: '/project-detail/SDG-16.svg',
        name: 'Life on land'
    },
    {
        value: 17,
        icon: '/project-detail/SDG-17.svg',
        name: 'Partnerships for the goals'
    },
]

const SDGData = ({ sdgs }) => {
    return (
        <div className="px-6 pb-6">
            <div className="sdg-grid">
                {
                    SDGDataList.map(sdg => (
                        <div className="flex ai-c col-gap-4 dg-card" key={Math.random()}>
                            <img className="sdg-icon" src={sdg.icon} alt={sdg.name}/>
                            <div className={"sdg-name"}>{sdg.name}</div>
                            <Checkbox value={sdg.value} checked={sdgs?.find(item => item.sdg_goal === sdg.value)}/>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default SDGData;