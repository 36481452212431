import React, { useEffect, useState, useCallback, useRef } from "react";

import moment from "moment";

import { getInventories } from "../../../store/features/inventorySlice";
import { getRetirementReasons } from "../../../store/features/nftSlice";
import { getRegistries } from "../../../store/features/registrySlice";
import { createRetirement } from "../../../store/features/nftSlice";
import { updateCampaignProject } from "../../../store/features/campaignSlice";
import { getAccounts } from "../../../store/features/accountSlice";

import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import Image from "../../image";
import CarbonDropDown from "../../CarbonDropdown";
import Switch from "../../common/switch";

import DropDown from "../../McDropdown";

import CopyIcon from '../../../assets/images/copy-green.svg'

import "./style.scss";
import { updateRetirement } from "../../../store/features/retirementSlice";

const UpdateRetirementPopup = ({onCancel, ret}) => {
    const dispatch = useDispatch();
    const statuses = [{id: 0, name: 'Pending'}, { id: 1, name: 'Retired'}];
    const pageRefBeneficiary = useRef(null);

    const { inventories } = useSelector(data => data.inventory);
    const { retirementReasons } = useSelector(data => data.nft);
    const { user } = useSelector(data => data.user);

    const [def, setDef] = useState(true)

    const [retirement, setRetirement] = useState(ret)

    const addRetirement = async () => {
        await dispatch(updateRetirement({id: retirement.id, retirement: {
            id: retirement.id,
            retirement_date: ret.retirement_date,
            serial_number: retirement.serial_number,
            project: {id: retirement.project.id},
            beneficiary_account: {id: retirement?.beneficiary_account?.id},
            beneficiary: retirement.beneficiary,
            reason: retirement.reason ? {id: retirement.reason?.id} : null,
            registry: {id: retirement?.registry?.id},
            units: retirement.blocks.units * 1000,
            offset_url: retirement.offset_url,
            campaign: {id: retirement.campaign.id},
            is_pending: retirement.is_pending,
            transparency_url: retirement.transparency_url,
        }}))
        onCancel(true)
    }

    useEffect(() => {
        dispatch(getInventories({page: 0, projectId: retirement.project.id}))
    }, [])

    useEffect(() => {
        dispatch(getRetirementReasons())
        dispatch(getRegistries())
    }, [])

    const fetchAccounts  = useCallback((page, merge, user)  => {
        pageRefBeneficiary.current = page;
        dispatch(getAccounts({organisationId: user.organisations[0].external_id, page: page, merge: merge, type: '', search: ''}));
    }, [dispatch]);

    useEffect(() => {
        fetchAccounts(0, false, user);
    }, [user, fetchAccounts]);

    const changeTransparencyUrl = () => {
        setDef(!def)
    }

    return (
        <div className='add-retirement-popup'>
            <div className='add-retirement-popup-content'>
                <label className='popup-title mb-6 ml-4'>Update retirement</label>
                <div className="flex flex-1 mx-6">
                    <div className="flex items-center flex-1 mr-6">
                        <div className="flex items-center flex-1">
                            <div className="flex-column flex-1" >
                                <label className="input-label font-12">Amount</label>
                                <div className="flex items-center inactive-input">
                                    <label className="regular-text o05">{retirement.blocks?.units|| '-'}</label>
                                </div>
                            </div> 
                        </div>
                        <div className="flex-column ml-6 flex-2">
                            <label className="input-label font-12">Status</label>
                            <DropDown 
                                size="large" 
                                placeholder="Select" 
                                selected={retirement.is_pending ? statuses[0] : statuses[1]}
                                setSelected={c => setRetirement({...retirement, is_pending: c.id === 0})}
                                options={statuses}
                            />
                        </div>
                    </div>
                    <div className="flex-column flex-1 ml-6">
                        <label className="regular-text font-12 light-purple-text mb-2">Retirement reason</label>
                        <DropDown 
                            size="large"
                            options={retirementReasons} 
                            selected={retirement.reason} 
                            setSelected={e => setRetirement({...retirement, reason: e})} 
                            placeholder="Select" 
                        />
                    </div>
                </div>
             
            <div className="flex items-center flex-1 mx-6 mt-4">
                <div className="flex-column flex-1" >
                    <label className="input-label font-12">Carbon</label>
                    <div className="flex items-center inactive-input">
                        <label className="regular-text o05">{retirement.inventory?.serial_number || '-'}</label>
                    </div>
                </div> 
            </div>
            <div className="flex-column mx-6 mt-4">
                <label className="regular-text font-12 light-purple-text mb-2">Serial number</label>
                <input className="edit-retirement-input"  value={retirement.serial_number || ''} onChange={e => setRetirement({...retirement, serial_number: e.target.value})} />
            </div>
            <div className="flex-column mt-4 mx-6">
                <label className="regular-text font-12 light-purple-text mb-2">Offset url</label>
                <input className="edit-retirement-input"  value={retirement.offset_url || ''} onChange={e => setRetirement({...retirement, offset_url: e.target.value})} />
            </div>
            <div className="flex items-center px-6 py-4">
                <div className="flex-column flex-1">
                    <div className="flex items-center justify-between mb-2">
                        <label className="regular-text font-12 light-purple-text">Transparency link</label>
                        {/* <div className="flex items-center">
                            <div className="flex items-center mr-6">
                                <label className="light-purple-text font-12 regular-text mr-2">Set default</label>
                                <Switch
                                    variant="small"
                                    enabled={def}
                                    onClick={() => changeTransparencyUrl()}
                                    />
                            </div>
                            <div className="flex items-center cursor" onClick={() => {navigator.clipboard.writeText(retirement.transparency_url)}} >
                                <label className="light-purple-text font-12 regular-text mr-2">Copy</label>
                                <img src={CopyIcon} alt="copy" className="cursor" />
                            </div>
                        </div> */}
                    </div>
                    {false ?
                        <div className="flex flex-center justify-between items-center inactive-input">
                            <label className="regular-text mr-2 one-line">{retirement.transparency_url}</label>
                        </div>
                        :
                        <input className="edit-retirement-input"  value={retirement.transparency_url || ''} onChange={e => setRetirement({...retirement, transparency_url: e.target.value})} />
                    }
                </div>
            </div>
            <div className="flex items-center px-6">
                <div className="flex items-center flex-1">
                        <div className="flex-column flex-1 flex-1" >
                            <label className="input-label font-12">Beneficiary Account</label>
                            <div className="flex items-center inactive-input">
                                <label className="regular-text o05">{retirement.beneficiary_account?.name}</label>
                            </div>
                        </div> 
                    </div>
                    <div className="flex items-center flex-1 ml-6">
                        <div className="flex-column flex-1" >
                            <label className="input-label font-12">Customer</label>
                            <div className="flex items-center inactive-input">
                                <label className="regular-text o05">{retirement.contact?.name || '-'}</label>
                            </div>
                        </div> 
                    </div>
                    <div className="flex-column ml-6 flex-1">
                        <label className="regular-text font-12 light-purple-text mb-2">Carbon Beneficiary</label>
                        <input className="edit-retirement-input"  value={retirement.beneficiary || ''} onChange={e => setRetirement({...retirement, beneficiary: e.target.value})} />
                    </div>
            </div>
            
            <div className="flex justify-center mt-auto mb-2 mt-6">
                <div className="flex items-center justify-center cancel-btn cursor mr-3"
                        onClick={() => onCancel(false)}>
                    <label className="medium-text color-green">Cancel</label>
                </div>

                <div
                    onClick={addRetirement}
                    className={`${(true) ? 'done-btn' : 'disable-btn'} flex items-center justify-center cursor ml-3`}>
                    <label className="medium-text color-white">Save</label>
                </div>
            </div>
            </div>
        </div>
    )
}

export default UpdateRetirementPopup;
