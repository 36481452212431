import React from 'react';
import DeleteIcon from "../../assets/images/delete-black.svg";
import AddIcon from "../../assets/images/add.svg";
import TooltipModal from "../modals/TooltipModal";

function NftFilters({value, setValue}) {
    const filterClearAll = () => {
    }

    return (
        <TooltipModal title={"Filter"} action={"Clear All"} actionClick={filterClearAll}>
            <div className={"pxy-6"}>
                <div className={"flex items-center justify-between"}>
                    <div className="bold-text">FILTER 1</div>

                    <div className="flex items-center delete-icon justify-center cursor" onClick={() => setValue(null)}>
                        <img src={DeleteIcon} alt="delete"/>
                    </div>
                </div>
                <div className={"flex-column w-full mt-6"}>
                    <label className="input-label">Lifecycle</label>
                    <select className="input mt-1 select" value={value} required onChange={e => setValue(e.target.value)}>
                        <option disabled selected hidden>Select lifecycle</option>
                        <option value={"PENDING"}>Pending</option>
                        <option value={"MINTED"}>Minted</option>
                        <option value={"RANKED"}>Ranked</option>
                    </select>
                </div>
            </div>

            <div className="border-bottom"/>

            <div className="flex items-center justify-center add-filter-btn cursor mxy-6">
                <img src={AddIcon} alt="add" className="ml-6"/>
                <label className="bold-text font-16 violet-light-text ml-2">Add new filter</label>
            </div>

        </TooltipModal>
    );
}

export default NftFilters;
