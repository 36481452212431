import React, { useEffect, useState } from "react";
import './nfts.scss';
import api from "../../store/services";
import Header from "../../components/header";
import NftFilters from "../../components/nftDetails/NftFilters";
import NftMintCard from "../../components/nft/NFTMintCard";
import uuid from "react-uuid";
import { addToast} from '../../store/features/toastSlice'
import { useDispatch } from "react-redux";

const Projects = () => {
  const dispatch = useDispatch();

    const [ranks, setRanks] = useState([]);
    const [currentPage, setCurrentPage] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [search, setSearch] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await api.get(`/v3/api/backoffice/monthly_rank?page=${currentPage}&size=8`)
            setTotalPages(data?.total_pages)
            setRanks(data.content);
        };
        fetchData();
    }, [currentPage]);

    const onRankClick = rank => {
        setRanks(ranks.map(r => r.id === rank.id ? ({...r, selected: !r.selected}) : r))
    }

    const onMint = async () => {
        const { data } = api.post(`/backoffice/mint`, ranks.filter(r => r.selected).map(rank => (
            {
                "mc_api_id":  uuid(),
                "wax_schema_name": "creaturesv11",
                "new_asset_owner": rank.wallet_id,
                "inmutable_data": [
                    {
                        "name": "Creature Rank",
                        "value": rank.ranking
                    },
                    {
                        "name": "Creature More Info",
                        "value": rank.more_info
                    },
                    {
                        "name": "CO2 Contribution (kg)",
                        "value": rank.kilos
                    },
                    {
                        "name": "CO2 Contributor Account",
                        "value": rank.wallet_id
                    },
                    {
                        "name": "CO2 Offset Asset ID",
                        "value": rank.offset_id
                    },
                    {
                        "name": "CO2 Offset Beneficiary",
                        "value": rank.beneficiary
                    },
                    {
                        "name": "CO2 Contribution Period",
                        "value": rank.period
                    }
                ]
            }
        )));

        if(data.accepted?.length > 0)
            dispatch(
                addToast({
                id: uuid(),
                error: true,
                text: `Minted ${data.accepted.length}`,
                }),
            );
        else if(data.rejected?.length > 0 )
            dispatch(
                addToast({
                id: uuid(),
                error: true,
                text: `Error ${data.rejected.error}`,
                }),
            );
    }

    return (
        <div className="flex-column mr-6 mb-6 ml-3 flex-1">
            <Header title={"NFT Minting"}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    search={search}
                    setSearch={setSearch}
                    totalPages={totalPages}
                    isAddBtn={false}
                    isMinthBtn={true}
                    onMint={onMint}
                    />

            <div className="nft-cards">
                {ranks.map(rank => <NftMintCard key={rank.id} nft={rank} onClick={() => onRankClick(rank)} />)}
            </div>
        </div>
    )
}

export default Projects;
