import { createSlice } from "@reduxjs/toolkit";
import api from '../../store/services'

export const walletSlice = createSlice({
    name: "wallet",
    initialState: {
      wallets: {},
    },
    reducers: {
      setWallets: (state, { payload }) => {
          state.wallets = payload;
      },
      addWallets: (state, { payload }) => {
        state.wallets = {...payload, content: [...state.wallets.content, ...payload.content]}
      },
    }
  });

export const getWallets = (payload) => async (dispatch) => {
  let url = `/v3/api/backoffice/organisations/${payload.organisationId}/wallets?page=${payload.page}&size=10`
  if(payload.search) url += `&search=${payload.search}`
  const { data } = await api.get(url)
  if(payload.merge)
    dispatch(addWallets(data));
  else
    dispatch(setWallets(data));};


export const { setWallets, addWallets } = walletSlice.actions;
export default walletSlice.reducer;