import React from 'react';

import './user-deleted-popup.scss';

const UserDeleteModal = ({ close, name }) => {
  const onClick = e => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      close();
    }
  };

  return (
    <div className="user-deleted-popup" onClick={onClick}>
      <div className="user-deleted-popup-content">
        <label className="ud-title">{name} deleted</label>
        <button onClick={close}>OK</button>
      </div>
    </div>
  );
};

export default UserDeleteModal;
