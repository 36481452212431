import React, { useState, useEffect, useContext } from 'react'

import moment from 'moment';

import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, Area, AreaChart, Tooltip, BarChart, Bar } from 'recharts';

import { ReactComponent as AreaIcon } from '../../assets/images/area-chart.svg';
import { ReactComponent as BarIcon } from '../../assets/images/bar-chart.svg';

import '../../assets/css/components/dashboard/chart.scss'
import { start } from '@popperjs/core';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload != null && payload[0] != null) {
      return (
        <div className="custom-tooltip">
          {payload &&
            payload
              .slice(0, 15)
              .sort((a, b) => b.value - a.value)
              .map((p, i) => (
                <div key={i} className="tooltip-content">
                  <label className="regular-text font-16">{p.dataKey}</label>
                  <label className="medium-text font-16">{p.value.toLocaleString()}</label>
                </div>
              ))}
        </div>
      );
    }
    return null;
  };

const Chart = ({ salesPerformance, salesDashboardUnit, saleDates, salesPerformanceUnit }) => {

    const colors = [
        '#F8DD81',
        '#8AB2FF',
        '#D06BFF',
        '#FF7BDA',
        '#FF5B5B',
        '#8AB2FF',
        '#7BD77A',
        '#F6BB23',
        '#40F1DE',
        '#44A9E2',
      ];
    
      const [chartData, setChartData] = useState([]);
      const [chartDataForName, setChartDataForName] = useState([]);
      const [names, setNames] = useState([]);
      const [sales, setSales] = useState([]);
      const [chartType, setChartType] = useState('AREA')
      
      useEffect(() => {
        const salesFilter = JSON.parse(localStorage.getItem('timeline-chart-filter'))
          ? JSON.parse(localStorage.getItem('timeline-chart-filter'))
          : [];
        if (salesFilter.length > 1) {
          setSales(salesFilter);
        } else {
          setSales(salesPerformance);
        }
      }, [salesPerformance]);

    const formatLabel = (label, unit, range) => {
        if (unit === 'day') return label.format('HH:00');
        else if (unit === 'week') return label.format('dddd');
        else if (unit === 'month') return range ? label.format('DD MMM') : label.format('DD');
        else if (unit === 'year') return label.format('MMM');
        else return '';
      };
      
    const getLabels = (unit, start, end) => {
        if (unit === 'day') return getDayLabels(start, end);
        else if (unit === 'week') return getWeekLabels(start, end, unit);
        else if (unit === 'month') return getMonthLabels(start, end);
        else if (unit === 'year') return getYearLabels(start, end);
      };
      
    const formatTs = (ts, unit) => {
        if (unit === 'day') return ts;
        else {
          const date = new Date(ts * 1000);
          date.setHours(0, 0, 0, 0);
          return date.getTime() / 1000;
        }
      };
      
    const formatToUnit = (ts, unit, range) => {
        if (unit === 'day') return ts.hour();
        else if (unit === 'week') return ts.weekday() + 2;
        else if (unit === 'month') return `${ts.date()}.${ts.month() + 1}`;
        else if (unit === 'year') return ts.month() + 1;
      };

      const getDayLabels = (start, end) => {
        return Array.from(Array(24).keys()).map(h => saleDates.startDate.clone().add(h + 1, 'hours'));
      };
      
      const getWeekLabels = (start, end) => {
        return Array.from(Array(7).keys()).map(d => saleDates.startDate.clone().add(d + 1, 'days'));
      };
      
      const getMonthLabels = (start, end) => {
        if (start && end) {
          const daysInMonth =
            start && end ? end.diff(start, 'days') + 2 : moment().diff(moment().subtract(1, 'month'), 'days') + 1;
          return Array.from(Array(daysInMonth).keys()).map(d =>
            (end ? moment.unix(end.unix()) : moment()).subtract(daysInMonth - d, 'day').add(1, 'day'),
          );
        } else {
          return Array.from(Array(saleDates.endDate.diff(saleDates.startDate, 'days') + 1).keys()).map(d =>
            saleDates.startDate.clone().add(d, 'days'))
        }
      };
      
      const getYearLabels = (start, end) => {
        if (start && end) {
          const months = start && end ? end.diff(start, 'months') + 1 : 12;
          return Array.from(Array(months).keys()).map(m =>
            (end ? moment.unix(end.unix()) : moment()).subtract(months - m, 'months').add(1, 'month'),
          );
        } else {
          return Array.from(Array(12).keys()).map(m => saleDates.startDate.clone().add(m, 'months'));
        }
      };
      
    
      useEffect(() => {
        const unit = salesDashboardUnit?.toLowerCase();
        const range = saleDates.active;
        const labels = getLabels(
          unit,
          saleDates.active ? saleDates.startDate : null,
          saleDates.active ? saleDates.endDate : null,
        );
        const data = labels?.map(l => ({ name: formatLabel(l, unit, range) })) || [];
        const dataForName = labels?.map(l => ({ name: formatLabel(l, unit, range) })) || [];
        if (sales) {
          labels?.forEach((label, i) => {
            sales
              .filter(a => !a.hide)
              .forEach(stats => {
                const name = stats?.entity?.name;
                const id = stats?.entity?.id;
                const value = stats.series.find(v => formatToUnit(label, unit, range).toString() === v.timestamp);
                data[i][name] = value ? value.number : 0;
                dataForName[i][id] = { id: id, name: name, value: value ? value : 0 };
              });
          });
        }
        setChartDataForName(dataForName);
        setChartData(data);
      }, [sales]);

      useEffect(() => {
        if (chartDataForName && chartDataForName.length > 0 && sales?.length > 0)
          setNames(
            Object.entries(chartDataForName[0])
              .slice(1)
              .map((name, i) => {
                const selectedItemIndex = sales.findIndex(r => r.entity.id === name[1].id);
                return {
                  value: name[1]?.name,
                  color: colors[selectedItemIndex !== -1 ? selectedItemIndex : i % 10],
                  selected: true,
                };
              }),
          );
      }, [chartData]);

      const updateSales = sale => {
        const updatedSales = sales.map(s => (s.entity.id === sale.entity.id ? { ...s, hide: !s.hide } : s));
        localStorage.setItem('timeline-chart-filter', JSON.stringify(updatedSales));
        setSales(updatedSales);
      };

      return (
        <div className="flex-column flex-2 card mxy-3 py-6 pl-6">
          <div className="flex items-center justify-between mr-6">
            <label className='bold-text font-14'>CARBON</label>
            <div className="flex items-center">
              <label className='bold-text font-20'>{salesPerformance.map(s => s.series).flat().map(s => s.number).reduce((a, c) => a + c, 0).toLocaleString()}kilo</label>
              <div
                  className="flex items-center justify-center cursor relative nav-btn ml-6"
                  onClick={() => {
                  setChartType('AREA');
                  }}
                  style={{ background: chartType === 'AREA' ? '#053149' : '#F4F7F9' }}>
                  <AreaIcon
                  fill={chartType === 'AREA' ? '#40F1DE' : '#053149'}
                  stroke={chartType === 'AREA' ? '#40F1DE' : '#053149'}
                  />
              </div>
              <div
                  className="flex items-center justify-center cursor ml-4 relative nav-btn"
                  onClick={() => {
                  setChartType('BAR');
                  }}
                  style={{ background: chartType === 'BAR' ? '#053149' : '#F4F7F9' }}>
                  <BarIcon
                   fill={chartType === 'BAR' ? '#40F1DE' : '#053149'}
                   stroke={chartType === 'BAR' ? '#40F1DE' : '#053149'}
                    />
              </div>
            </div>
          </div>
          {salesDashboardUnit === 'DAY' ? <label className='regular-text font-14'>{saleDates.startDate.format("hh:00")} - {saleDates.endDate.format('hh:00')}</label>  : <label className='regular-text font-14'>{saleDates.startDate.format("D MMM yyyy")} - {saleDates.endDate.format("D MMM yyyy")}</label>}
            <div className="flex-column flex-1 sales-chart">
              {salesPerformanceUnit !== 'USER' &&
                <div className="flex items-center wrap mt-4 sale-selectors">
                    {sales.slice(0, 15).map((sale, i) => (
                    <div
                        key={i}
                        className="flex items-center pxy-1-2 mr-2 mt-2 sale-selector cursor no-select"
                        onClick={() => updateSales(sale)}
                        style={{ background: sale.hide ? 'rgba(36, 203, 177, 0.1)' : '#F6F6F6', border: sale.hide ? '1px solid rgba(36, 203, 177, 0.5)' : '1px solid #F6F6F6' }}>
                        <div className="sale-selector-dot" style={{ background: sale.hide ? '#FFFFFF' : colors[i % 10] }} />
                        <label className="medium-text font-12 ml-2">{sale.entity.name}</label>
                    </div>
                    ))}
                </div>
            }
            <div className="flex flex-1 mt-6">
                <ResponsiveContainer height="99%" minWidth="0" width="99%">
                  {chartType === 'AREA' ? 
                <AreaChart data={chartData} margin={{ top: 10, left: -20, right: 24, bottom: -12 }}>
                    {names &&
                    names.length !== 0 &&
                    names.map((l, idx) => (
                        <defs key={idx}>
                        <linearGradient id={idx} x1="0" x2="0" y1="0" y2="1">
                            <stop offset="0%" stopColor={l.color} stopOpacity={0.2} />
                            <stop offset="100%" stopColor={l.color} stopOpacity={0} />
                        </linearGradient>
                        </defs>
                    ))}
                    <Tooltip animationDuration={0} content={<CustomTooltip />} cursor={false} />
                    <CartesianGrid stroke="#D2E5ED" strokeDasharray="8" vertical={false} />
                    <YAxis
                    allowDecimals={false}
                    axisLine={{ stroke: 'transparent' }}
                    tick={{ fontSize: 10 }}
                    tickLine={false}
                    type="number"
                    />
                    <XAxis axisLine={{ stroke: '#E3EEF3' }} dataKey="name" tick={{ fontSize: 10 }} tickLine={false} />
                    {names.map((name, i) => (
                    <Area
                        key={i}
                        dataKey={name.value}
                        fill={`url('#${i}')`}
                        stroke={name.color}
                        strokeWidth={2}
                        type="monotone"
                    />
                    ))}
                </AreaChart>
                :
                  <BarChart data={chartData} margin={{ top: 10, left: -27, right: 24, bottom: -12 }}>
                    <CartesianGrid stroke="#D2E5ED" strokeDasharray="8" vertical={false} />
                    <XAxis axisLine={{ stroke: '#E3EEF3' }} dataKey="name" tick={{ fontSize: 10 }} tickLine={false} />
                    <YAxis
                      allowDecimals={false}
                      axisLine={{ stroke: 'transparent' }}
                      tick={{ fontSize: 10 }}
                      tickLine={false}
                      type="number"
                      />                  
                    <Tooltip animationDuration={0} content={<CustomTooltip />} cursor={false} />
                    {names.map((name, i) => (
                      <Bar
                          key={i}
                          dataKey={name.value}
                          fill={name.color}
                          stroke={name.color}
                          strokeWidth={2}
                          type="monotone"
                      />
                      ))}
                  </BarChart>
                }
                </ResponsiveContainer>
            </div>
            </div>
        </div>
      );
      
    };

export default Chart;