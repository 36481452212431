import styled from 'styled-components';

export const AutomationFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: margin 400ms;
  user-select: none;
  * {
    cursor: pointer;
  }
  .menu-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -168px;
    top: 44px;
    background: #ffffff;
    z-index: 11;
    cursor: pointer;
    width: 375px;

    &::before {
      content: '';
      background: white;
      width: 10px;
      height: 10px;
      transform: translate(-50%, -50%) rotate(45deg);
      position: absolute;
      left: 50%;
      top: 0px;
    }
  }
  .pagination-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    background: #ffffff;
    border-radius: 8px;
    margin-right: 20px;
    margin-left: 8px;

    .nav-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      user-select: none;
      border-radius: 8px;
      background: #ffffff;
      box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
    }

    .selected {
      background-color: #053149;
    }

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .options-select-container {
    width: 100%;

    .value-logo {
      margin-right: 8px;
      padding: 8px;
      background: #d2e5ed;
      border-radius: 50%;

      label {
        color: #73868c;
      }
    }

    .select__placeholder,
    .select__menu-notice--no-options {
      color: #053149;
      opacity: 0.3;
    }

    .select__value-container {
      padding-left: 16px;
      padding-top: 6px;
      padding-bottom: 6px;
      min-height: 46px;
    }

    .select__input-container {
      display: flex !important;
    }
    .select__control {
      border-radius: 10px;
      border: 1px solid #d2e5ed;

      &:hover {
        background: #f4f7f9;
      }
    }

    .select__control--is-focused {
      border: 1px solid #31ddca;
      box-shadow: unset;
    }

    .select__control--menu-is-open {
      .select__single-value {
        display: none;
      }
    }

    .select__menu-list {
      max-height: 200px;
    }

    .select__multi-value {
      background: #d2e5ed;
      border-radius: 4px;
    }

    .select__multi-value__label {
      color: #053149;
      font-size: 16px;
    }

    .select__clear-indicator,
    .select__indicator-separator {
      display: none;
    }

    .select__menu {
      margin-top: 4px;
    }
  }
`;

export const MenuWrapper = styled.div`
  * {
    cursor: pointer;
  }

  .filter-dropdowns {
    column-gap: 20px;

    .dropdown-container {
      width: 100%;
    }

    .selector {
      width: 100%;
      margin-right: unset;
    }
  }

  .header-container {
    margin-left: 10px;
    margin-bottom: 16px;

    .clear-all-btn {
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 4px 8px;
      border: 2px solid #d2e5ed;
      border-radius: 4px;
    }

    .clear-btn {
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 4px;
      box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
      border-radius: 8px;
      background: #f4f7f9;
    }
  }
`;
