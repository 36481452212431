import { ReactComponent as UsersIcon } from '../assets/images/users.svg';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import Button from '../components/Elements/button/button';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import api from "../store/services";
import { addToast } from '../store/features/toastSlice';
import { DeleteTeamWrapper } from './modal.styled.js';
import { CSSTransition } from 'react-transition-group';
import { setTeams } from '../store/features/settingsSlice';

const DeleteTeam = () => {
  const { modal, setModal } = useContext(OrganisationContext);
  const { teams } = useSelector(state => state.settings);
  const { user } = useSelector(state => state.user);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const team = modal.content.team;
  const services = team?.services || [];

  const getBackgroundForService = key => {
    switch (key) {
      case 'Electrician':
        return '#8AB2FF';
      case 'Scaffolding':
        return '#F6BB23';
      case 'LEAD_GENERATOR':
      case 'Roofer':
        return '#44A9E2';
      default:
        return '#44A9E2';
    }
  };

  const onDelete = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await api.delete(`/v1/organizations/${user.organisations[0].external_id}/teams/${team.id}`);
      dispatch(setTeams(teams.filter(t => t.id !== team.id)));
      dispatch(
        addToast({
          error: false,
          text: 'Team has been deleted successfully',
          id: uuid(),
        }),
      );
      setModal({ type: 'delete-team-success', content: {} });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: 'Failed to delete team',
          id: uuid(),
        }),
      );
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <DeleteTeamWrapper>
        <p className="bold-text font-24 text-center">Are you sure you want to delete this team?</p>
        <div className="mt-6 team-details-container">
          <div className="flex team-name-container">
            <label className="team-name">{team.name}</label>
            <div className="flex">
              <UsersIcon />
              <label className="ml-1">{team.adminuser_count}</label>
            </div>
          </div>
          <div className="flex team-description">
            <label>{team.description}</label>
          </div>
          <div className="flex team-organization-container">
            <div className="team-organisations">
              {services?.length > 0 ? (
                <>
                  {services.map((service, index) => (
                    <label key={`${service.id}`} style={{ background: getBackgroundForService(service.name) }}>
                      {service.name}
                    </label>
                  ))}
                </>
              ) : (
                <div>
                  <span className="regular-text services-empty">No service available</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-12">
          <Button
            className="secondary-hover"
            label="Cancel"
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            style={{ flex: 1 }}
          />
          <Button label="Delete" onClick={onDelete} red={true} size="large" style={{ flex: 1, marginLeft: 24 }} />
        </div>
      </DeleteTeamWrapper>
    </CSSTransition>
  );
};

export default DeleteTeam;
