import React, { useEffect, useRef, useState} from "react";

import { useLocation } from "react-router-dom";


import './style.scss';

const TabMenu = ({menuItems, onMenuItemClick}) => {
    const location = useLocation();
    const divRef = useRef(null);

    const [selectedTabMenu, setSelectedTabMenu] = useState();

    useEffect(() => {
        if(location) {
            let tmp = location.pathname?.slice(location.pathname.lastIndexOf("/"), location.pathname.length) ;
            setSelectedTabMenu(tmp);
        }
    }, [location])


    return(
        <div className="tab-menu flex items-center pr-4 pl-4 pt-4 pb-4 border-bottom cursor">
            <div className="flex items-center tab-menu-items" ref={divRef}>
                {menuItems?.map((item, i) => 
                    <div
                    key={item.id} 
                    onClick={() => onMenuItemClick(item)} 
                    className={`pxy-2 mxy-1 ${selectedTabMenu === item.path && 'selected-tab-menu'}`}>
                        <label className="medium-text">{item.name}</label>
                    </div>
                    )}
            </div>
        </div>
    )
}

export default TabMenu;