import React, { useRef, useState, useContext, useEffect } from "react";

import SearchIcon from "../../assets/images/search.svg";
import Pagination from "../../components/pagination/pagination";
import AddIcon from "../../assets/images/add.svg";
import ArrowLeft from '../../assets/images/menu/arrow-left.svg';
import ArrowLeftIcon from "../../assets/images/arrow-left.svg";

import FilterIcon from "../../assets/images/filter.svg";
import FilterActiveIcon from "../../assets/images/filter-green.svg";
import TimeToggle from "../timeToggle";
import NavTop from "../common/NavTop";
import TabMenu from "../TabMenu";
import useOnclickOutside from "react-cool-onclickoutside";
import { ReactComponent as DotsIcon } from '../../assets/images/horizontal-dots.svg';
import { OrganisationContext } from "../../context/organisationContext";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh-green.svg";
import { ReactComponent as RefreshBlackIcon } from "../../assets/images/refresh-icon.svg";


import Button from "../Elements/button/button";

import './style.scss';
import Tab from "../Elements/tab/tab";

const Header = (
    {
        nav,
        search,
        setSearch,
        currentPage,
        setCurrentPage,
        totalPages,
        title,
        isPaged = true,
        isFilter = false,
        isAddBtn = true,
        addPermission,
        onAdd,
        isTimeToggle = false,
        addBtnText,
        isSearch = true,
        onRetire,
        isRetire,
        filterChildren,
        size="medium",
        tabMenu,
        menuItems,
        onMenuItemClick,
        children,
        filter,
        selectedTime,
        setSelectedTime,
        back,
        isBack,
        totalOffsetAmount,
        totalRetirementAmount,
        isLeaderboard = false,
        onCreateNft = () => {},
        tabs,
        selectedTab = '',
        onTabChange = () => {},
        showBackBtn = false,
        onBackClick = () => {},
        isEditBtn = false,
        onSave = () => {},
        onCancelEdit = () => {},
        onEdit = () => {},
        edit = false,
        onRefresh,
        createNftBtn,
        onRefreshData,
        onExport
    }) => {
    const viewRef = useRef();
    const [filterPopup, setFilterPopup] = useState(false)
    const [showMenu, setShowMenu] = useState(false);
    const { checkPermission, hasPermission } = useContext(OrganisationContext);
    const [width, setWidth] = useState();

    const ref = useOnclickOutside(() => {
        setFilterPopup(false);
    }, {
        ignoreClass: 'ignore-click'
    });
    const updateSelectedPage = page => {
        setCurrentPage(page)
    };

    const handleResize = () => {
        setWidth(viewRef.current?.offsetWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize, false);
    }, []);


    useEffect(() => {
        handleResize();
    }, [viewRef]);


    return (
        <div className="flex items-center justify-between mx-6 action-header" ref={viewRef}>
            <div className="flex items-center">
                {showBackBtn && <div onClick={onBackClick} className="flex items-center justify-center cursor mr-4"><img src={ArrowLeftIcon} alt="ArrowLeftIcon" /></div>}
                {!tabMenu && title &&
                <div className="flex items-center cursor" onClick={back}>
                    {isBack && <img width={24} src={ArrowLeft} alt="back" className="mr-2" /> }
                    <label className={`bold-text mr-6 ${size==='medium' ? 'font-24' : 'font-20'}`}>{title}</label>
                </div>
                }
                {tabs && <Tab tabs={tabs} selected={selectedTab} onTabChange={onTabChange} />}
                {nav && <NavTop names={nav.names} edit={nav.edit}/>}
            </div>
            <div className="flex items-center">
                <div className="flex-column ml-4">{children}</div>
                {isTimeToggle && <TimeToggle isWhiteBg={true} selected={selectedTime} setSelected={setSelectedTime}/>}
                {isFilter && 
                <div className={"relative"} >
                    <div className="flex items-center filter-icon justify-center cursor ignore-click"
                                        style={{height: size === 'medium' ? '40px' : '32px', width: size === 'medium' ? '40px' : '32px', borderRadius: size === 'medium' ? 12 : 8}}
                                    onClick={() => setFilterPopup(!filterPopup)}>
                        <img src={filterPopup || (filter && filter?.value !== '') ? FilterActiveIcon : FilterIcon} alt="filter"/>
                    </div>
                    {filterPopup && <div ref={ref}>{filterChildren}</div> }
                </div>
                }
                {onRefreshData && 
                <div className={"relative"} >
                    <div className="flex items-center filter-icon justify-center cursor ignore-click ml-4"
                                        style={{height: size === 'medium' ? '40px' : '32px', width: size === 'medium' ? '40px' : '32px', borderRadius: size === 'medium' ? 12 : 8}}
                                    onClick={onRefreshData}>
                        <RefreshIcon />
                    </div>
                </div>
                }
                {onRefresh &&
                    <div className="flex items-center justify-center cursor action-btn ml-4" style={{opacity: hasPermission(addPermission) ? 1 : 0.5, height: '32px' }} onClick={onRefresh}>
                        <RefreshIcon />
                    </div>
                }
                {isPaged && 
                <div className="ml-4 flex items-center">
                <Pagination
                    selectedPage={currentPage}
                    setSelectedPage={updateSelectedPage} 
                    totalPages={totalPages}
                    height={size === 'medium' ? '40px' : '32px'}/>    
                    </div>
                  }
                {isSearch && <div className="flex items-center search-input nft-search-input px-2 ml-4" style={{height: size === 'medium' ? '40px' : '32px'}}>
                    <img src={SearchIcon} alt="search" className="ml-1"/>
                    <input placeholder="Search" className="ml-1 font-16" value={search}
                        onChange={e => setSearch(e.target.value)}/>
                </div>}
                {isRetire && <div className="flex items-center justify-center add-btn cursor" style={{opacity: hasPermission(addPermission) ? 1 : 0.5, height: size === 'medium' ? '40px' : '32px', width: size === 'medium' ? '136px' : '112px'}} onClick={() => checkPermission(() => onRetire(), addPermission)} >
                    <label className="bold-text font-14 violet-light-text ml-2">Retire</label>
                </div>}
                {(isLeaderboard || createNftBtn) &&
                    <div className="flex items-center justify-center cursor action-btn ml-4" style={{opacity: hasPermission(addPermission) ? 1 : 0.5 }} onClick={() => checkPermission(() => onCreateNft(), addPermission)}>
                        <img src={AddIcon} alt="add" />
                        <label className="bold-text font-14 violet-light-text ml-2">Create NFTs</label>
                    </div>
                }
                {isAddBtn && <div className="flex items-center justify-center add-btn cursor ml-4" style={{opacity: hasPermission(addPermission) ? 1 : 0.5, height: size === 'medium' ? '40px' : '32px', width: size === 'medium' ? '136px' : '112px'}} onClick={() => checkPermission(() => onAdd(), addPermission)} >
                    {addBtnText ? 
                    <label className="bold-text font-14 violet-light-text ml-2">{addBtnText}</label>
                    :
                    <>
                        <img src={AddIcon} alt="add" />
                        <label className="bold-text font-14 violet-light-text ml-2">Add new</label>
                    </>
                    }
                </div>}
                {onExport && <div className="flex items-center justify-center add-btn cursor ml-4"  onClick={onExport} >
                    <label className="bold-text font-14 violet-light-text ml-2">Export</label>
                </div> }
                {/* style={{marginRight: width > 900 ? (width - 900) / 3 - 5  : ''}} */}
                {(totalOffsetAmount != null || totalRetirementAmount != null) &&
                    <div className="flex items-center" >
                        {totalOffsetAmount != null &&
                            <div className="flex items-center px-2 total-amount">
                                <label className="regular-text one-line">Total offset amount: {totalOffsetAmount / 1000}</label>
                            </div>}
                        {totalRetirementAmount != null &&
                            <div className="flex items-center px-2 total-amount ml-6">
                                <label className="regular-text one-line">Total retirement amount: {totalRetirementAmount / 1000}</label>
                            </div>}
                    </div>
                }
                {isEditBtn && (
                    edit ? (
                        <div className="flex justify-center">
                        <Button
                            className="secondary-hover"
                            label="Cancel"
                            borderRadius="12px"
                            onClick={onCancelEdit}
                            secondary={true}
                            size="medium"
                            style={{ flex: 1 }}
                            bgColor="#FFFFFF"
                            borderColor="#24CBB1"
                            color="#24CBB1"
                            borderWidth="1px"
                            width="110px"
                        />
                        <Button
                            label="Save"
                            onClick={onSave}
                            size="medium"
                            style={{ flex: 1, marginLeft: 24 }}
                            bgColor="#24CBB1"
                            borderColor="#24CBB1"
                            color="#FFFFFF"
                            borderRadius="12px"
                            borderWidth="1px"
                            width="110px"
                        />
                        </div>
                    ) : (
                            <div
                            className="flex items-center justify-center cursor no-select schema-edit-btn"
                            onClick={onEdit}
                            >
                            <EditIcon />
                            <label className="semibold-text font-12 mid-green-text ml-1">
                                Edit
                            </label>
                            </div>
                    )
                )}
                {!tabMenu && menuItems &&  
                <div className="flex items-center justify-center header-menu relative cursor ml-4" style={{background: showMenu  ? '#F6F6F6' : '', border: showMenu ? '1px solid rgba(181, 185, 193, 0.5)' : ''}} onClick={() => setShowMenu(!showMenu)} tabIndex={0} onBlur={() => setShowMenu(false)}>
                    <DotsIcon className="cursor" width={20} /> 
                    {showMenu && menuItems.length > 0 && <div className="header-menu-items flex-column card pxy-2 absolute">
                        {menuItems.map((item, i) => 
                            <div key={i} className="header-menu-item flex items-center pxy-2 cursor" onClick={() => onMenuItemClick(item)}>
                                <label className="regular-text">{item.name}</label>
                            </div>
                            )}
                    </div>
                    }
                </div>
                }
                </div>
        </div>
    )
}

export default Header;
