import React from 'react'
import PlaceholderImage from '../../assets/images/nft-placeholder.svg'
import { useNavigate } from "react-router-dom";
import Image from "../image";
import Checkbox from '../checkbox';

const NftMintCard = ({nft, onClick}) => {
    return (
        nft ? <div className={"card nft-card flex items-center justify-between cursor"} onClick={onClick}>
            <div className={"card-left flex justify-start gap-4"}>
                <Image className={"nft-image"} size={85} media={nft?.image?.media} />
                <div className={"w-full"}>
                    <div className="flex justify-between items-center">
                        <h3 className={"my-2 mr-2"}>{nft.name}</h3>
                        <span>{nft.ranking}</span>
                    </div>
                    <span className="regular-text o05 nft-desc">{nft.project}</span>
                </div>
            </div>

            <div className={"card-middle flex-column items-start justify-between gap-2"}>
                <div><span className="regular-text o05 mr-1">Beneficiary:</span>{nft.beneficiary}</div>
                <div><span className="regular-text o05 mr-1">Period:</span> {nft.period}</div>
                <div><span className="regular-text o05 mr-1">Template:</span> {nft.template}</div>
            </div>

            <div className={"card-right flex-column items-start justify-between gap-2"}>
                <div><span className="regular-text o05 mr-1">Beneficiary:</span>{nft.beneficiary}</div>
                <div><span
                    className="regular-text o05 mr-1">Wallet:</span>{nft.wallet_address}
                </div>
                <div><span className="regular-text o05 mr-1">Kilos:</span>{nft.kilos}</div>
            </div>
            <div className="mr-4">
                <Checkbox checked={nft.selected}/>
            </div>
        </div> : <></>
    )
}

export default NftMintCard;
