import React, { useContext, useState } from 'react';
import uuid from 'react-uuid';
import Button from '../components/Elements/button/button';
import { initModal } from '../constant/InitialData';
import { addToast} from '../store/features/toastSlice'
import { CancelAutomationLogWrapper } from './modal.styled.js';
import { CSSTransition } from 'react-transition-group';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { OrganisationContext } from "../context/organisationContext";
import api from "../store/services";

const CancelAutomationLog = () => {
  const { modal, setModal } = useContext(OrganisationContext);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { automationLog } = modal.content;

  const onPerformAction = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await api.put(`/v3/api/automation_logs/${automationLog.id}/cancel`);
      dispatch(
        addToast({
          error: false,
          text: `Automation log has been cancelled successfully`,
          id: uuid(),
        }),
      );
      setModal({
        type: 'automation-log-success',
        content: {
          isCancel: true,
          automationLog: automationLog,
        },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: 'Failed to cancel automation',
          id: uuid(),
        }),
      );
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <CancelAutomationLogWrapper>
        <p className="bold-text font-24 text-center">{'Are you sure you want to cancel this automation?'}</p>
        <p className="regular-text grey-text text-center mt-2">{'You will not be able to un-do this action'}</p>
        <div className="mt-8 integration-details-container">
          <div className="flex justify-between items-center">
            <div className="flex integration-name-container">
              <label className="medium-text font-16">{automationLog.automation.name}</label>
            </div>
          </div>
          <div className="flex-column mt-4">
            <label className="regular-text grey-text font-12">ID:</label>
            <label className="regular-text mt-1">{automationLog.id}</label>
          </div>
          <div className="flex-column mt-4">
            <label className="regular-text grey-text font-12">Date:</label>
            <label className="regular-text mt-1">
              {!automationLog.created_on
                ? '-'
                : `${moment(automationLog.created_on * 1000).format('DD/MM/YY - hh:mm A')}`}
            </label>
          </div>
        </div>
        <div className="flex justify-center mt-12">
          <Button
            bgColor="white"
            borderColor="#32E5C5"
            label="Keep"
            onClick={() => setModal(initModal)}
            size="large"
            style={{ flex: 1 }}
          />
          <Button
            bgColor={'#FF5B5B'}
            borderColor={'#FF5B5B'}
            color="white"
            disabled={loading}
            label={'Cancel'}
            onClick={onPerformAction}
            size="large"
            style={{ flex: 1, marginLeft: 24 }}
          />
        </div>
      </CancelAutomationLogWrapper>
    </CSSTransition>
  );
};

export default CancelAutomationLog;
