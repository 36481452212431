import React, { useState } from 'react'

import moment from 'moment';

import DateSelector from '../../widget/date-selector/date-selector';

import './style.scss';

const DateView = ({value, setValue, size="small"}) => {
    const [show, setShow] = useState(false)

    const onBlur = e => {
        e.preventDefault();
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setShow(false);
        }
      };

    return (
    <div
      className="flex items-center jusitfy-center organisations-border cursor relative date-view pl-4 no-select" 
      style={{
        borderColor: show ? '#24CBB1' : 'rgba(181, 185, 193, 0.3)',
        height: size === 'small' ? '32px' : '48px',
        borderRadius: size === 'small' ? '12px' : '18px'
      }} 
      onClick={() => setShow(!show)}
      onBlur={onBlur}
      tabIndex={0}
    >
        {value ? <label className="medium-text" style={{fontSize: size === 'small' ? '14px' : '16px'}}>{moment.unix(value).format("DD/MM/YY")}</label> : <label className="regular-text o03">Select date</label>}
        {show && <DateSelector 
            setEndDate={() => {}}
            setStartDate={setValue}
            startDate={value}
            top={size === 'small' ? '36px' : '48px'}
        />}
    </div>
    )
}

export default DateView;