import { createSlice } from "@reduxjs/toolkit";
import api from '../../store/services'
import { addToast } from "./toastSlice";
import uuid from "react-uuid";

export const nftSlice = createSlice({
    name: "nft",
    initialState: {
      nfts: {},
      nft: {},
      retirement: null,
      retirementReasons: [],
      loading: false,
      retirementLoading: true,
      attributes: []
    },
    reducers: {
      setNfts: (state, { payload }) => {
          state.nfts = payload;
      },
      setNft: (state, { payload }) => {
        state.nft = payload;
      },
      setLoading: (state, { payload }) => {
        state.loading = payload;
      },
      setRetirementLoading: (state, { payload }) => {
        state.retirementLoading = payload;
      },
      setRetirement: (state, { payload }) => {
        state.retirement = payload;
      },
      setRetirementReasons: (state, { payload }) => {
        state.retirementReasons = payload;
      },
      setAttributes: (state, { payload }) => {
        state.attributes = payload;
      },
    }
  });

  export const getNfts = (payload) => async (dispatch) => {
    dispatch(setLoading(true));
    var url = `/v3/api/backoffice/nfts?page=${payload.page}&size=8`
    payload.lifecycles.forEach(l => url += `&lifecycle=${l}`)
    url += `&${payload.filter}`
    const { data } = await api.get(url)
    dispatch(setNfts(data));
    dispatch(setLoading(false));
};

export const getNft = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await api.get(`/v3/api/backoffice/nfts/${payload}`);
  dispatch(setNft(data));
  dispatch(setLoading(false));
};

export const getNftRetirement = (payload) => async (dispatch) => {
  dispatch(setRetirementLoading(true));
  try {
    const { data } = await api.get(`/v3/api/backoffice/nfts/${payload}/retirements`);
    dispatch(setRetirement(data));
  } catch(e) {
    dispatch(setRetirement(null));
  }

  dispatch(setRetirementLoading(false));
};

export const getAttributes = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await api.get(`/v3/api/backoffice/nfts/${payload}/attributes`);
    dispatch(setAttributes(data));
  } catch(e) {
  }

  dispatch(setLoading(false));
};

export const getRetirementReasons = (payload) => async (dispatch) => {
  const { data } = await api.get(`/v3/api/backoffice/retirement_reasons`);
  dispatch(setRetirementReasons(data));
};

export const createRetirement = (payload) => async (dispatch) => {
  try {
    const { data } = await api.post(`/v3/api/backoffice/retirements`, payload);
    dispatch(setRetirement(data));
    
    dispatch( addToast({
      error: false,
      title: 'Retirement created',
      text: `Retirement has been created`,
      id: uuid(),
    }))
  } catch(e) {
    dispatch(
      addToast({
        error: true,
        text: 'Error while creating Retirement',
        id: uuid(),
      }),
    );
  }
};

export const updateRetirement = (payload) => async (dispatch) => {
  try {
    const { data } = await api.post(`/v3/api/backoffice/retirements/${payload.id}`, payload);
    dispatch(setRetirement(data));
    
    dispatch( addToast({
      error: false,
      title: 'Retirement updated',
      text: `Retirement has been updated`,
      id: uuid(),
    }))
  } catch(e) {
    dispatch(
      addToast({
        error: true,
        text: 'Error while updating Retirement',
        id: uuid(),
      }),
    );
  }
};

export const { setNfts, setNft, setLoading, setRetirement, setRetirementReason, setRetirementLoading, setRetirementReasons, setAttributes } = nftSlice.actions;
export default nftSlice.reducer;