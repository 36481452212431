import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignLeaderboard } from "../../../store/features/campaignSlice";
import CampaignLeaderboardItem from "./CampaignLeaderboardItem";
import Header from "../../header";
import "./style.scss";
import Loading from "../../../assets/images/loading.svg";
import InfiniteScroll from "react-infinite-scroll-component";

const CampaignLeaderboards = ({ campaignId, isNewCampaign, menuItems, onMenuItemClick, onCreateNft }) => {
  const dispatch = useDispatch();
  const { campainLeaderboard, loading } = useSelector((data) => data.campaign);

  useEffect(() => {
    if (campaignId)
      dispatch(
        getCampaignLeaderboard({
          campaignId: campaignId,
          page: 0,
          merge: false,
        })
      );
  }, [campaignId]);

  const fetchMoreData = () => {
    dispatch(
      getCampaignLeaderboard({
        campaignId: campaignId,
        page: campainLeaderboard?.number + 1,
        merge: true,
      })
    );
  };

  return (
    <div className="flex-column campaign-leaderboards">
      <div className="border-bottom">
         <Header 
            title='Leaderboards'
            isSearch={false}
            isPaged={false}
            isAddBtn={false}
            size='small'
            addPermission="NFT_MANAGE"
            menuItems={menuItems}
            onMenuItemClick={onMenuItemClick}
            onCreateNft={() => onCreateNft()}
            isLeaderboard={true}
            tabMenu={true}
        />
      </div>
      {loading ? (
        <div className="flex flex-1 items-center justify-center mt-4">
          <img src={Loading} alt="loading" />
          <label className="bold-text font-16 ml-2 light-purple-text">
            Loading
          </label>
        </div>
      ) : campainLeaderboard?.content?.length > 0 && !isNewCampaign ? (
        <div className="scroll-wrapper" id="scrollableDiv">
          <InfiniteScroll
            dataLength={campainLeaderboard?.content?.length}
            hasMore={!campainLeaderboard?.last}
            loader={
              <div className="item flex items-center justify-center">
                <img alt="loading" height="24px" src={Loading} />
              </div>
            }
            next={fetchMoreData}
            scrollableTarget="scrollableDiv"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "unset",
            }}
          >
            {campainLeaderboard?.content?.map((campaignNftLeaderItem) => (
              <>
                <CampaignLeaderboardItem
                  campaignNftLeaderItem={campaignNftLeaderItem}
                  campaignId={campaignId}
                  key={campaignNftLeaderItem.id}
                />
              </>
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        <div className="flex items-center justify-center flex-1">
          <label className="bold-text font-16 light-purple-text">
            There are no leaderboards
          </label>
        </div>
      )}
    </div>
  );
};

export default CampaignLeaderboards;
