import { ReactComponent as DotsIcon } from '../../assets/images/menu/dots.svg';
import React, { useContext, useState } from 'react';
import { OrganisationContext } from '../../context/organisationContext';
import Menu from './menu';

const AutomationItem = ({ automation, checkPermission, hasPermission, index, setSelectedAutomation }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { setModal } = useContext(OrganisationContext);

  const ownerSymbolColors = ['#E6EEFF', '#FFF0F0', '#FAF4E7', '#E6FAF3', '#E0F4FF'];

  const menuClick = action => {
    setShowMenu(false);
    switch (action) {
      case 'EDIT':
        setModal({
          type: 'add-automation',
          content: { automation: automation, isEdit: true },
        });
        break;
      case 'DELETE':
        setModal({
          type: 'automation-action',
          content: {
            automation: automation,
            top: true,
            isDelete: true,
            ownerSymbolColor: ownerSymbolColors[index % 5 || 0],
          },
        });
        break;
      default:
        break;
    }
  };

  const onChangeStatus = async () => {
    const { is_enabled } = automation;
    setModal({
      type: 'automation-action',
      content: {
        automation: automation,
        top: true,
        isDelete: false,
        isEnable: !is_enabled,
        isDisable: is_enabled,
        ownerSymbolColor: ownerSymbolColors[index % 5 || 0],
      },
    });
  };

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowMenu(false);
    }
  };

  const getOwnerLogo = () => {
    const { owner } = automation;
    if (!owner.name) return '';
    const nameArray = owner.name?.split(' ');
    return (
      <div
        className="flex justify-center items-center owner-logo"
        style={{ backgroundColor: ownerSymbolColors[index % 5 || 0] }}>
        <label className="bold-text">
          {nameArray?.[0]?.charAt(0)?.toUpperCase()}
          {nameArray?.[1]?.charAt(0)?.toUpperCase()}
        </label>
      </div>
    );
  };

  return (
    <div className="px-3">
      <div
        className={`card automation-item-container ${
          hasPermission(automation.is_default ? 'GLOBAL_AUTOMATIONS_MANAGE' : 'AUTOMATIONS_MANAGE')
            ? 'cursor'
            : 'not-allowed'
        }`}
        onClick={() =>
          checkPermission(
            () => setSelectedAutomation(automation),
            automation.is_default ? 'GLOBAL_AUTOMATIONS_MANAGE' : 'AUTOMATIONS_MANAGE',
          )
        }>
        <div className="automation-name flex items-center justify-start">
          <label className="automation-text-name regular-text font-16">{automation?.name}</label>
        </div>
        <div className="border-right" />
        <div className="automation-description">
          {automation?.description ? (
            <label className="double-line regular-text grey-text font-14">{automation?.description}</label>
          ) : (
            <label className="regular-text font-14 no-description">{'No description'}</label>
          )}
        </div>
        <div className="border-right" />
        <div className="enable-switch-container">
          <label className="regular-text font-16">{automation?.is_enabled ? 'Enabled' : 'Disabled'}</label>
          <div
            className={`switch ml-1 ${automation?.is_enabled && 'switch-selected'} ${
              hasPermission(automation?.is_default ? 'GLOBAL_AUTOMATIONS_MANAGE' : 'AUTOMATIONS_MANAGE')
                ? 'cursor'
                : 'not-allowed'
            }`}
            onClick={e => {
              e.stopPropagation();
              checkPermission(
                () => onChangeStatus(),
                automation.is_default ? 'GLOBAL_AUTOMATIONS_MANAGE' : 'AUTOMATIONS_MANAGE',
              );
            }}>
            <div className="selector" />
          </div>
        </div>
        <div className="border-right" />
        <div className="owner-container" tabIndex={0}>
          <div> {getOwnerLogo()}</div>
          <div className="relative flex items-center" onBlur={onBlur} tabIndex={0}>
            <div
              className="menu-btn cursor flex items-center relative py-1 px-2 menu-hover"
              onClick={e => {
                e.stopPropagation();
                checkPermission(
                  () => setShowMenu(!showMenu),
                  automation.is_default ? 'GLOBAL_AUTOMATIONS_MANAGE' : 'AUTOMATIONS_MANAGE',
                );
              }}>
              <DotsIcon style={{ margin: 'auto' }} />
            </div>
            {showMenu && (
              <div>
                <Menu
                  checkPermission={checkPermission}
                  clickItem={action => menuClick(action)}
                  hasPermission={hasPermission}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomationItem;
