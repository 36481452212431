import CavitiesIcon from '../assets/images/cavities.svg';
import LoftIcon from '../assets/images/loft.svg';
import BoilerIcon from '../assets/images/boiler.svg';
import ElectricalStorageIcon from '../assets/images/elec-storage-white.svg';
import { getUser } from './localstorage';

export const emailRegEx = /^[A-Za-z0-9][-A-Z-a-z0-9.!#$%&'*+-=?^_`{|}~\/]+@([-A-Z-a-z0-9]+\.)+[A-Za-z]{2,5}$/;

export const capitalize = str => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatText = (text, replaceWith = '-') => {
  if (!text) return '';
  return text.toLowerCase().replaceAll('_', replaceWith);
};

export const uppercase = str => {
  if (!str) return '';
  return str.toUpperCase();
};

export const getSurveyIcon = surveyName => {
  switch (surveyName) {
    case 'CAVITY':
      return CavitiesIcon;
    case 'LOFT_INSULATION':
    case 'LOFT':
      return LoftIcon;
    case 'BOILER':
      return BoilerIcon;
    case 'ELEC_STORAGE':
      return ElectricalStorageIcon;
    default:
      return LoftIcon;
  }
};

export const getNftLifecycleColor = lifecycle => {
  if(lifecycle === 'MINTED')
      return '#57DBD3';
  else if(lifecycle === 'PENDING')
     return '#FBBF66';
  else if(lifecycle === 'RANKED')
      return '#7976F4';
  else if(lifecycle === 'QUEUED')
      return '#7976F4';
  else if(lifecycle === 'FAILED')
    return '#7976F4';
  else if(lifecycle === 'CREATE')
    return '#24CBB1';
  return '#7976F4';
}

export const getErrorDescription = (error, defaultErrorText = '') => {
  if (!error) return '';
  return error?.response?.data?.error_description || defaultErrorText;
};