import { createSlice } from "@reduxjs/toolkit";
import uuid from "react-uuid";
import api from '../../store/services'
import { addToast } from "./toastSlice";

export const campaignSlice = createSlice({
  name: "campaign",
  initialState: {
    campaigns: {},
    campaign: {},
    tiers: {},
    achievements: {},
    tier: {},
    rewards: [],
    campaignProjects: [],
    campaignNfts: {},
    loading: false,
    totalOffsetAmount: 0,
    totalRetireAmount: 0,
    retirementAmounts: {},
    retirementNfts: {},
    retirementNftsLoading: false,
    merchandiseNfts: {},
    merchandiseNftsLoading: false,
    fractionalNfts: {},
    retirements: {},
    fractionalNftsLoading: false,
    retirementLoading: false
  },
  reducers: {
    setCampaigns: (state, { payload }) => {
      state.campaigns = payload;
    },
    setCampaign: (state, { payload }) => {
      state.campaign = payload;
    },
    setTiers: (state, { payload }) => {
      state.tiers = payload;
    },
    setAchievements: (state, { payload }) => {
      state.achievements = payload;
    },
    setMergeAchievements: (state, { payload }) => {
      state.achievements = {...payload, content: [...state.achievements.content, ...payload.content]};
    },
    setNfts: (state, { payload }) => {
      state.campaignNfts = payload;
    },
    setMergeNfts: (state, { payload }) => {
      state.campaignNfts = {...payload, content: [...state.campaignNfts.content, ...payload.content]};
    },
    setRetirementNfts: (state, { payload }) => {
      state.retirementNfts = payload;
    },
    setMergeRetirementNfts: (state, { payload }) => {
      state.retirementNfts = {...payload, content: [...state.retirementNfts.content, ...payload.content]};
    },
    setMerchandiseNfts: (state, { payload }) => {
      state.merchandiseNfts = payload;
    },
    setMergeMechandiseNfts: (state, { payload }) => {
      state.merchandiseNfts = {...payload, content: [...state.merchandiseNfts.content, ...payload.content]};
    },
    setFractionalNfts: (state, { payload }) => {
      state.fractionalNfts = payload;
    },
    setMergeFractionalNfts: (state, { payload }) => {
      state.fractionalNfts = {...payload, content: [...state.fractionalNfts.content, ...payload.content]};
    },
    setTier: (state, { payload }) => {
      state.tier = payload;
    },
    setRewards: (state, { payload }) => {
      state.rewards = payload;
    }, 
    setCampaignProjects: (state, { payload }) => {
      state.campaignProjects = payload;
      state.totalOffsetAmount =  payload.map(p => p.total_co2_kilo_amount).reduce((sum, p) => sum + p, 0)
      state.totalRetireAmount = payload.map(p => p.retired_co2_kilo_amount).reduce((sum, p) => sum + p, 0)
      state.retirementAmounts = payload.reduce((sum, p) => ({...sum, [p.id] : p.retired_co2_kilo_amount/1000}), {})
    }, 
    addCampaignProjects: (state, { payload }) => {
      state.campaignProjects = [payload, ...state.campaignProjects];
    }, 
    updateCampaignProject: (state, { payload }) => {
      state.campaignProjects = state.campaignProjects.map(cp => cp.id === payload.id ? payload.campaignProject : cp)
    }, 
    removeCampaignProject: (state, { payload }) => {
      state.campaignProjects = state.campaignProjects.filter(cp => cp.id !== payload)
    }, 
    removeCampaignTier: (state, { payload }) => {
      state.tiers.content = state.tiers.content.filter(cp => cp.id !== payload)
    },  
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setRetirementNftsLoading: (state, { payload }) => {
      state.retirementNftsLoading = payload;
    },
    setMerchandiseNftsLoading: (state, { payload }) => {
      state.merchandiseNftsLoading = payload;
    },
    setRetirementLoading: (state, { payload }) => {
      state.retirementLoading = payload;
    },
    setFractionalNftsLoading: (state, { payload }) => {
      state.fractionalNftsLoading = payload;
    },
    setCampainLeaderboard: (state, { payload }) => {
      state.campainLeaderboard = payload;
    },
    setMergeCampainLeaderboard: (state, { payload }) => {
      state.campainLeaderboard = {...payload, content: [...state.campainLeaderboard.content, ...payload.content]};
    },
    setCampaignMintingProgress: (state, { payload }) => {
      state.campaignMintingProgress = payload;
    },
    setMergeCampaignMintingProgress: (state, { payload }) => {
      state.campaignMintingProgress = {...payload, content: [...state.campaignMintingProgress.content, ...payload.content]};
    },
    setCampaignCreateStatus: (state, { payload }) => {
      state.campaignCreateStatus = payload;
    },
    setRetirementAmounts: (state, { payload }) => {
      state.retirementAmounts = payload;
    },
    setRetirements: (state, {payload}) => {
      state.retirements = payload;
    },
    setMergeRetirements: (state, { payload }) => {
      state.retirements = {...payload, content: [...state.retirements.content, ...payload.content]};
    },
  }
});

export const getCampaigns = (payload) => async (dispatch) => {
  let apiURL = `/v3/api/backoffice/campaigns?page=${payload.page}&size=${payload.size || 10}`;
  if (payload.search) apiURL += `&search=${payload.search}`;
  apiURL += `&active=${payload.active}`;
  const { data } = await api.get(apiURL);
  dispatch(setCampaigns(data));
};

export const getCampaign = (payload) => async (dispatch) => {
  const { data } = await api.get(`/v3/api/backoffice/campaigns/${payload}`)
  dispatch(setCampaign(data));
};

export const updateCampaign = (payload) => async (dispatch) => {
  try {
    const { data } = await api.put(`/v3/api/backoffice/campaigns/${payload.id}`, payload.campaign)
    dispatch(setCampaign(data));
    dispatch(
      addToast({
        error: false,
        title: 'Campaign updated',
        text: `${payload.campaign.name} has been updated`,
        id: uuid(),
      }),
    );
  } catch (e) {
    dispatch(
      addToast({
        error: true,
        text: 'Error while updating campaign',
        id: uuid(),
      }),
    );
  }
};

export const createCampaign = (payload) => async (dispatch) => {
  try {
    dispatch(setCampaignCreateStatus('LOADING'));
    const { data } = await api.post(`/v3/api/backoffice/campaigns`, payload.campaign)
    dispatch(
      addToast({
        error: false,
        title: 'Campaign created',
        text: `${payload.campaign.name} has been created`,
        id: uuid(),
      }),
    );
    dispatch(setCampaign(data));
    dispatch(setCampaignCreateStatus('CREATED'));
    return data;
  } catch (e) {
    dispatch(
      addToast({
        error: true,
        text: 'Error while creating campaign',
        id: uuid(),
      }),
    );
    dispatch(setCampaignCreateStatus('FAILED'));
  }
};

export const getCampaignTiers = (payload) => async (dispatch) => {
    dispatch(setLoading(true));
    const { data } = await api.get(`/v3/api/backoffice/campaigns/${payload.campaignId}/tiers?page=${payload.page}`)
    dispatch(setTiers(data));
    dispatch(setLoading(false));
};

export const getCampaignAchievements = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await api.get(`/v3/api/backoffice/campaigns/${payload.campaignId}/achievements?page=${payload.page}&active=${payload.active}`)
  if (payload.merge) {
    dispatch(setMergeAchievements(data));
  } else {
    dispatch(setAchievements(data));
  }
  dispatch(setLoading(false));
};

export const deleteCampaignAchievement = (payload) => async (dispatch) => {
  try {
    await api.delete(`/v3/api/backoffice/campaigns/${payload.campaignId}/achievements/${payload.achievement.id}`)
    dispatch(
      addToast({
        error: false,
        title: 'Campaign achievement deleted',
        text: `${payload.achievement.name} has been deleted`,
        id: uuid(),
      }),
    );
  } catch (e) {
    dispatch(
      addToast({
        error: true,
        text: 'Error while deleting achievement',
        id: uuid(),
      }),
    );
  }
};

export const getCampaignNfts = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  var url = `/v3/api/backoffice/campaigns/${payload.campaignId}/nfts?page=${payload.page}&size=20&nft_type=${payload.type}`
  const { data } = await api.get(url)
  if (payload.merge) {
    dispatch(setMergeNfts(data));
  } else {
    dispatch(setNfts(data));
  }
  dispatch(setLoading(false));
  return data;
};

export const getCampaignRetirements = (payload) => async (dispatch) => {
  dispatch(setRetirementLoading(true));
  var url = `/v3/api/backoffice/campaigns/${payload.campaignId}/retirements?page=${payload.page}&size=${payload.size || 10}`
  payload.nftTypes?.forEach(t => url += `&nft_lifecycle=${t}`)
  const { data } = await api.get(url)
  if (payload.merge) {
    dispatch(setMergeRetirements(data));
  } else {
    dispatch(setRetirements(data));
  }  
  dispatch(setRetirementLoading(false));
  return data;
};

export const getCampaignRetirementNfts = (payload) => async (dispatch) => {
  dispatch(setRetirementNftsLoading(true));
  var url = `/v3/api/backoffice/campaigns/${payload.campaignId}/nfts?page=${payload.page}&size=20&nft_type=${payload.type}`
  const { data } = await api.get(url)
  if (payload.merge) {
    dispatch(setMergeRetirementNfts(data));
  } else {
    dispatch(setRetirementNfts(data));
  }
  dispatch(setRetirementNftsLoading(false));
  return data;
};

export const getCampaignMerchandiseNfts = (payload) => async (dispatch) => {
  dispatch(setMerchandiseNftsLoading(true));
  var url = `/v3/api/backoffice/campaigns/${payload.campaignId}/nfts?page=${payload.page}&size=20&nft_type=MERCHANDISE`
  const { data } = await api.get(url)
  if (payload.merge) {
    dispatch(setMergeMechandiseNfts(data));
  } else {
    dispatch(setMerchandiseNfts(data));
  }
  return data;
};

export const getCampaignFractionalNfts = (payload) => async (dispatch) => {
  dispatch(setFractionalNftsLoading(true));
  var url = `/v3/api/backoffice/campaigns/${payload.campaignId}/nfts?page=${payload.page}&size=20&nft_type=FRACTIONAL`
  const { data } = await api.get(url)
  if (payload.merge) {
    dispatch(setMergeFractionalNfts(data));
  } else {
    dispatch(setFractionalNfts(data));
  }
  return data;
};

export const getCampaignLeaderboard = (payload) => async (dispatch) => {
  if (!payload.merge) {
    dispatch(setLoading(true));
  }
  var url = `/v3/api/backoffice/campaigns/${payload.campaignId}/rankings?page=${payload.page}&size=50`
  const { data } = await api.get(url)
  if (payload.merge) {
    dispatch(setMergeCampainLeaderboard(data));
  } else {
      dispatch(setCampainLeaderboard(data));
  }
  dispatch(setLoading(false));
};

export const getCampaignTier = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await api.get(`/v3/api/backoffice/campaigns/${payload.campaignId}/tiers/${payload.tierId}`)
  dispatch(setTier(data));
  dispatch(setLoading(false));
};

export const getCampaignProjects = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await api.get(`/v3/api/backoffice/campaigns/${payload.campaignId}/projects`)
  dispatch(setCampaignProjects(data));
  dispatch(setLoading(false));
};


export const saveCampaignProjects = (payload) => async (dispatch) => {
  const { data } = await api.post(`/v3/api/backoffice/campaigns/${payload.campaignId}/projects`,  payload.campaignProject )
  dispatch(addCampaignProjects(data));
};

export const updateCampaignProjects = (payload) => async (dispatch) => {
  const { data } = await api.put(`/v3/api/backoffice/campaign_projects/${payload.project_id}`,  payload.campaignProject )
  dispatch(updateCampaignProject({id: data.id, campaignProject: {...data}}));
};

export const deleteCampaignProjects = (payload) => async (dispatch) => {
  await api.delete(`/v3/api/backoffice/campaigns/${payload.campaignId}/projects/${payload.campaignProject.project.id}`)
  dispatch(removeCampaignProject(payload.campaignProject.id));
};

export const deleteCampaignTier = (payload) => async (dispatch) => {
  await api.delete(`/v3/api/backoffice/campaigns/${payload.campaignId}/tiers/${payload.tierId}`)
  dispatch(removeCampaignTier(payload.tierId));
};

export const updateTier = (payload) => async (dispatch) => {
  api.put(`/v3/api/backoffice/campaigns/${payload.campaignId}/tiers/${payload.tier.id}`, payload.tier)
};

export const createTier = (payload) => async (dispatch) => {
  return await api.post(`/v3/api/backoffice/campaigns/${payload.campaignId}/tiers`, payload.tier)
};

export const createAchievement = (payload) => async (dispatch) => {
  return await api.post(`/v3/api/backoffice/campaigns/${payload.campaignId}/achievements`, payload.achievement)
};

export const changeCampaignProjects = (payload) => async (dispatch) => {
  await api.delete(`/v3/api/backoffice/campaigns/${payload.campaignId}/projects/${payload.oldProject}`)
  const { data } = await api.post(`/v3/api/backoffice/campaigns/${payload.campaignId}/projects`, { project: {id: payload.campaignProject.project.id }, template: { id: payload.campaignProject.template.id} })
  dispatch(updateCampaignProject({id: payload.campaignProject.id, campaignProject: data}));
};

export const getRewards = (payload) => async (dispatch) => {
  // const {data} = await api.get(`/v3/api/backoffice/campaigns/${payload.campaignId}/tiers/${payload.tierId}`)
  // dispatch(setTier(data));
};

export const createCampaignNfts = (payload) => async (dispatch) => {
  const { data } = await api.post(`/v3/api/backoffice/campaigns/${payload.campaignId}/merchandise`, { project: {id: payload.campaignProject.project.id }, template: { id: payload.campaignProject.template.id} })
  // dispatch(updateCampaignProject({id: payload.campaignProject.id, campaignProject: data}));
};

export const getCampaignMintingProgress = (payload) => async (dispatch) => {
  if (!payload.merge) {
    dispatch(setLoading(true));
  }
  var url = `/v3/api/backoffice/nfts?page=${payload.page}&size=20`
  url += `&${payload.filter}`
  const { data } = await api.get(url)
  if (payload.merge) {
    dispatch(setMergeCampaignMintingProgress(data));
  } else {
    dispatch(setCampaignMintingProgress(data));
  }
  dispatch(setLoading(false));
};

export const { setRetirementLoading, setCampaigns, setCampaign, setTiers, setTier, setRewards, setCampaignProjects, addCampaignProjects, updateCampaignProject, removeCampaignProject, removeCampaignTier, setNfts, setLoading, setCampainLeaderboard, setMergeCampainLeaderboard, setCampaignMintingProgress, setMergeCampaignMintingProgress, setCampaignCreateStatus, setRetirementAmounts, setMergeNfts, setRetirementNfts, setMergeRetirementNfts, setMerchandiseNfts, setMergeMechandiseNfts, setRetirementNftsLoading, setMerchandiseNftsLoading, setRetirements, setMergeRetirements, setFractionalNfts, setMergeFractionalNfts, setFractionalNftsLoading, setAchievements, setMergeAchievements} = campaignSlice.actions;
export default campaignSlice.reducer;
