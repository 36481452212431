import { createSlice } from "@reduxjs/toolkit";
import api from '../../store/services'
import { uploadMedia } from "../services/media";

export const productSlice = createSlice({
    name: "product",
    initialState: {
      products: {},
      product: null,
      mediaGroups: [],
      media: [],
      brands: [],
      families: []
    },
    reducers: {
      setProducts: (state, { payload }) => {
          state.products = payload;
      },
      setProduct: (state, { payload }) => {
        state.product = payload;
      },
      setMediagroups: (state, { payload }) => {
        state.mediaGroups = payload;
      },
      addMediaGroup: (state, { payload}) => {
        state.mediaGroups = [...state.mediaGroups, payload]
      },
      setMedia: (state, { payload}) => {
        state.media = payload;
      },
      removeMedia : (state, { payload}) => {
        state.media = state.media.filter(m => m.media_external_id !== payload);
      },
      addMedia: (state, { payload}) => {
        state.media = [...state.media, payload];
      },
      setBrands: (state, { payload}) => {
        state.brands = payload;
      },
      setFamilies: (state, { payload}) => {
        state.families = payload;
      },
    }
  });

export const getProducts = (payload) => async (dispatch) => {
  let url = `/v3/api/products?page=${payload.page}&size=8&search=${payload.search}`
  url += `&${payload.filter}`
  const { data } = await api.get(url)
  dispatch(setProducts(data));
};

export const getProduct = (payload) => async (dispatch) => {
  const { data } = await api.get(`/v3/api/products/${payload}`);
  dispatch(setProduct(data));
};

export const getMediaGroups = payload => async dispatch => {
  const { data } = await api.get(`/v3/api/backoffice/products/${payload}/mediagroups`)
  dispatch(setMediagroups(data.map((mg, i) => ({...mg, selected: i === 0}))));
};

export const createMediaGroup = payload => async dispatch => {
  const { data } = await api.post(`/v3/api/mediagroup`, { name: payload.name })
  await api.post(`/v3/api/products/${payload.productId}/mediagroups`, {id: data.id});
  dispatch(addMediaGroup({id: data.id, name: payload.name}))
};

export const getMedia = payload => async dispatch => {
  const { data } = await api.get(`/v3/api/mediagroups/${payload.id}/media`)
  dispatch(setMedia(data));
};

export const deleteMedia = payload => async dispatch => {
  api.delete(`/v3/api/mediagroup/${payload.mediaGroupId}/media/${payload.mediaId}`);
  dispatch(removeMedia(payload.mediaId));
};

export const createMedia = payload => async dispatch => {
  const media = await uploadMedia(payload.file, payload.selectedMediaGroup);
  dispatch(addMedia(media));
};

export const getBrands = payload => async dispatch => {
  const { data } = await api.get(`/backoffice/v3/api/brand`)
  dispatch(setBrands(data));
};

export const getFamilies = payload => async dispatch => {
  const { data } = await api.get(`/backoffice/v3/api/family`)
  dispatch(setFamilies(data));
};

export const { setProducts, setProduct, setMediagroups, addMediaGroup, setMedia, removeMedia, addMedia, setBrands, setFamilies } = productSlice.actions;
export default productSlice.reducer;