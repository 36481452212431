import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/features/userSlice";
import Logo from '../../assets/images/logo.svg'
import LoginErrorIcon from '../../assets/images/login-error.svg'
import ShowPassword from '../../assets/images/show-password.svg'
import HidePassword from '../../assets/images/hide-password.svg'

import "./style.scss";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showError, setShowError] = useState(false);
  const [active, setActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onLogin = async (event) => {
    if (active)
      try {
        event.preventDefault();
        const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/v1/authentication`, { identity: email, password: password });
        const user = {
          access_token: data.api_tokens.access_token,
          refresh_token: data.api_tokens.refresh_token,
          exp:  data.api_tokens.exp,
          organisations: data.organizations.map(org => 
            ({
                external_id: org.id,
                name:  org.name, 
                org_type: org.organization_type,
                roles: [
                    {
                        id: org.role.id,
                        name: org.role.name,
                        permissions: data.api_tokens.permissions
                    }
                ]
            }
          )),
          forename: data.user.forename,
          surname: data.user.surname,
        }
        localStorage.setItem("user", JSON.stringify(user));
        dispatch(setUser(user));
        navigate('/dashboard')
      } catch (e) {
        console.log(e)
        setShowError(true);
      }
  };

  const changePassword = (e) => {
    setPassword(e.target.value);
    setShowError(false);
  };

  const changeEmail = (email) => {
    setEmail(email);
    setShowError(false);
  };

  useEffect(() => {
    setActive(email && password && email.length > 2 && password.length > 2)
  }, [email, password])

  return (
    <div className="login-page">
      <div className='login'>
        <div className="login-form">
          <img className="logo mt-20" src={Logo} />
          <label className='subtitle mt-6'>Login to your Dashboard</label>
          <form onSubmit={onLogin}>
            <label className='input-label'>Email</label>
            <div className='input-container'>
              <input
                placeholder="Email address"
                onChange={(e) => changeEmail(e.target.value)}
                className={`${showError && 'error-border'}`}
              />
              {showError &&
                <div className='error-msg'>
                  <img className='error-icon' src={LoginErrorIcon} />
                  <label>wrong email, try again</label>
                </div>
              }
            </div>
            <label className='input-label'>Password</label>
            <div className='input-container'>
              <input
                className={`password-input ${showError && 'error-border'}`}
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                onChange={(e) => changePassword(e)}
              />
              <img className="show-password" src={showPassword ? HidePassword : ShowPassword} alt="show password" onClick={() => setShowPassword(!showPassword)} />
              {showError &&
                <div className='error-msg'>
                  <img className='error-icon' src={LoginErrorIcon} />
                  <label>wrong password, try again</label>
                </div>
              }
            </div>
            <input type="submit" value="Login" style={{ backgroundColor: active ? '#10F3CF' : '#217E70', color: active ? '#ffffff' : 'rgba(255, 255, 255, 0.3)' }} />
            <a href="#/user/password/forgot">Forgot password</a>
          </form>
        </div>
      </div>
    </div>
  )
};

export default Login;