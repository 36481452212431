import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        teams: [],
        user: [],
        selectedTeam: {},
        selectedUser: {},
        setAutomations: {},
        setViewMore: {},
    },
    reducers: {
        setTeams: (state, { payload }) => {
            state.teams = payload;
        },
        setUsers: (state, { payload }) => {
            state.users = payload;
        },
        setSelectedTeam: (state, { payload }) => {
            state.selectedTeam = { ...payload };
        }, 
        setSelectedUser: (state, { payload }) => {
            state.selectedUser = { ...payload };
        },
        setAutomations: (state, { payload }) => {
            state.automationsData = { ...payload };
        },
        setViewMore: (state, { payload }) => {
            state.viewMore = { ...payload };
        },
    }
  });


export const { setSelectedTeam, setSelectedUser, setAutomations, setViewMore, setTeams, setUsers } = settingsSlice.actions;
export default settingsSlice.reducer;