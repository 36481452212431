import React, { useState } from 'react';

import { ReactComponent as ArrowDownIcon } from '../../../../../assets/images/menu/arrow-down-black.svg';

import DropdownWrapper from './dropdown.styled';

const DropDown = ({ options, setOptions, placeholder, actions = [] }) => {
  const [showOptions, setShowOptions] = useState(false);

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowOptions(false);
    }
  };

  const selectOption = option => {
    setOptions(options.map(o => ({ ...o, selected: o.id === option.id })));
    setShowOptions(false);
  };

  const onAction = action => {
    action.action();
    setShowOptions(false);
  };

  return (
    <DropdownWrapper onBlur={onBlur} tabIndex={0}>
      <div
        className="dropdown-selected pl-4"
        onClick={e => {
          e.stopPropagation();
          setShowOptions(!showOptions);
        }}>
        {options.find(option => option.selected) ? (
          <label className="regular-text">{options.find(option => option.selected).name}</label>
        ) : (
          <label className="regular-text placeholder-text">{placeholder}</label>
        )}
        <ArrowDownIcon className={`arrow-down mx-4 ${showOptions && 'arrow-up'}`} />
      </div>
      <div
        className="dd-options"
        style={{
          height: showOptions
            ? `${options.length * 40 + 16 + actions.length * 40 + (actions.length > 0 ? 24 : 0)}px`
            : 0,
        }}>
        {options.map(option => (
          <div
            key={option.id}
            className={`dd-option ${(options.find(o => o.selected) || {}).id === option.id && 'dd-option-selected'}`}
            onClick={e => {
              e.stopPropagation();
              selectOption(option);
            }}>
            <label className="regular-text">{option.name}</label>
          </div>
        ))}
        {actions &&
          actions.map(action => (
            <div className="dd-action" onClick={() => onAction(action)}>
              <label className="regular-text">{action.name}</label>
            </div>
          ))}
      </div>
    </DropdownWrapper>
  );
};

export default DropDown;
