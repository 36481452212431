import React from 'react'

import IncreaseIcon from '../../assets/images/increase.svg';
import DecreaseIcon from '../../assets/images/decrease.svg';

import '../../assets/css/components/dashboard/analytics.scss'

const Analytics = ({ title, value }) => {

    const percentage = ((value?.current / (value?.previous || 1)) * 100).toFixed(0);
    const increased = 100 - percentage;
      
    return (
        <div className='analytics card pxy-6 mxy-3'>
            <label className='bold-text font-14'>{title}</label>
            <div className="flex items-baseline justify-between mt-7">
                <label className="bold-text font-32">{value?.current?.toLocaleString() || 0}</label>
                <div className="flex items-center">
                    <label className="bold-text font-14 ml-1" style={{ color: increased ? '#40F1DE' : '#FF5B5B' }}>{value?.previous?.toLocaleString() || 0}</label>
                    <label className="bold-text font-14 ml-1" style={{ color: increased ? '#40F1DE' : '#FF5B5B' }}>( <img src={increased ? IncreaseIcon : DecreaseIcon} /> {percentage || 0}%)</label>
                </div>
            </div>
        </div>
    )
}

export default Analytics;