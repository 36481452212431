import React, { useEffect, useRef, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { createTier, getCampaign, getCampaignTier, setTier, updateTier } from '../../store/features/campaignSlice';
import { getCollectionTemplates } from "../../store/features/collectionSlice";

import NavTop from "../../components/common/NavTop";
import Header from "../../components/header";
import Button from "../../components/Elements/button/button";
import DropDown from "../../components/McDropdown";
import ColorPicker from "../../components/Elements/ColorPicker";

import { OrganisationContext } from "../../context/organisationContext";

import { ReactComponent as DotsIcon } from '../../assets/images/horizontal-dots.svg';

import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../assets/images/loading.svg";


import './style.scss';

const TierDetails = () => {
    const { setModal, checkPermission, hasPermission } = useContext(OrganisationContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { campaign, tier, loading } = useSelector(data => data.campaign);
    const { collectionTemplates } = useSelector(data => data.collection);
    const {campaignId, tierId} = useParams();
    const headlineRef = useRef(null);

    const menuItems = [{id: 0, name: 'Edit'}, {id: 1, name: 'Delete'}];
    const [showMenu, setShowMenu] = useState();
    const [edit, setEdit] = useState(tierId === 'new')
    const [showColorPicker, setShowColorPicker] = useState(false);

 

    useEffect(() => {
        if(headlineRef.current) {
            headlineRef.current.style.height = '0px';
            const scrollHeightHeadline = headlineRef.current.scrollHeight;
            headlineRef.current.style.height = scrollHeightHeadline + 5 + 'px';
        }
    }, [tier?.description]);

    useEffect(() => {
        dispatch(getCampaign(campaignId));
    }, [campaignId, dispatch]);

    useEffect(() => {
        if(tierId !== 'new')
            dispatch(getCampaignTier({campaignId: campaignId, tierId: tierId}));
        else
            dispatch(setTier({color_code: '#F5D45E'}))
    }, [campaignId, tierId, dispatch]);

    useEffect(() => {
        if(edit)
            dispatch(getCollectionTemplates({collectionId: campaign.collection?.id, search: ''}))
    }, [campaign, edit, dispatch])

    const onMenuItemClick = item => {
        if(item.name === 'Delete') {
            setModal({ type: 'delete-campaign-tier', content: { campaignId: campaignId, tier: tier, top: true } }); 
        } else if(item.name === 'Edit') {
            setEdit(true)
        }
    }


    const saveTier = async () => {
        if(tierId === 'new') {
            const { data } = await dispatch(createTier({campaignId: campaign.id, tier: {...tier, color: null, template: tier.template ? {id: tier.template?.id }  : null}}))
            navigate(`/commerce/campaigns/${campaignId}/tiers/${data.id}`)
        } else
        dispatch(updateTier({campaignId: campaign.id, tier: {...tier, color: null, template: tier.template ? {id: tier.template?.id }  : null}}))
        setEdit(false)
    }
    return (
        <div className="flex-column flex-1 tier-details no-select">
            <div className="mb-6 mt-8 ml-6">
                <NavTop edit={false} done={true} names={[{text: "Campaigns", path: "/commerce/campaigns"}, {text: campaign?.name, path: `/commerce/campaigns/${campaign.id}/tier`},
                {text: "Tiers", path: `/commerce/campaigns/${campaign.id}/tier`}, {text: tier?.name || 'new', path: tierId === 'new' ? '' : `/commerce/campaigns/${campaign.id}/tiers/${tier.id}`}]}/>
            </div>
            <div className="flex mx-6">
                <div className="flex-column tier-details-l card fit-content">
                    <div className="flex items-center justify-between pxy-6 border-bottom">
                        <label className="bold-text font-20">Tier details</label>
                        <div className="flex items-center justify-center header-menu relative cursor" style={{background: showMenu  ? '#F6F6F6' : ''}} onClick={() => setShowMenu(!showMenu)} tabIndex={0} onBlur={() => setShowMenu(false)}>
                            <DotsIcon className="cursor" width={20} /> 
                            {showMenu && menuItems.length > 0 && <div className="header-menu-items flex-column card pxy-2 absolute">
                                {menuItems.map((item, i) => 
                                    <div key={i} className="header-menu-item flex items-center pxy-2 cursor" onClick={() => onMenuItemClick(item)}>
                                        <label className="regular-text">{item.name}</label>
                                    </div>
                                    )}
                            </div>
                            }
                        </div>
                    </div>
                    {loading ? 
                    <div className="flex flex-1 items-center justify-center mt-4">
                        <img src={Loading} alt="loading" />
                        <label className="bold-text font-16 ml-2 light-purple-text">
                            Loading
                        </label>
                    </div>
                    :
                    
                    edit ?
                    <div className="flex-column pxy-6">
                        <label className="input-label">Name</label>
                        <input className="input" value={tier?.name} onChange={e => dispatch(setTier({...tier, name: e.target.value}))} />
                        <label className="input-label mt-6">Description</label>
                        <textarea className="textarea" value={tier?.description} ref={headlineRef} onChange={e => dispatch(setTier({...tier, description: e.target.value}))} />
                        <div className="flex mt-6">
                            <div className="flex-column flex-1">
                                <label className="input-label">Rank min</label>
                                <input className="input w-full" value={tier?.rank?.min} onChange={e => dispatch(setTier({...tier, rank: {...tier.rank, min: e.target.value }}))} />
                            </div>
                            <div className="flex-column flex-1 ml-6">
                                <label className="input-label">Rank max</label>
                                <input className="input w-full" value={tier?.rank?.max} onChange={e => dispatch(setTier({...tier, rank: {...tier.rank, max: e.target.value }}))} />
                            </div>
                        </div>
                        <div className="flex mt-6">
                            <div className="flex-column flex-1">
                                <label className="input-label">Priority</label>
                                <input className="input w-full" value={tier?.priority} onChange={e => dispatch(setTier({...tier, priority: e.target.value }))} />
                            </div>
                            <div className="flex-column flex-1 ml-6 relative">
                                <label className="input-label">Tier color</label>
                                <div className="edit-tier-color" style={{background: tier.color_code}} onClick={() => setShowColorPicker(!showColorPicker)} />
                                {showColorPicker && <ColorPicker color={tier.color || tier.color_code} onChange={color => dispatch(setTier({...tier, color: color, color_code: color.hex}))} setColor={color => dispatch(setTier({...tier, color_code: color}))} close={() => setShowColorPicker(false)} /> }
                            </div>
                        </div>
                        <label className="input-label mt-6">Template name</label>
                        <DropDown 
                            size="large" 
                            placeholder="Select" 
                            selected={tier.template}
                            setSelected={c => dispatch(setTier({...tier, template: c }))}
                            options={collectionTemplates}
                        />   
                        <div className="flex justify-center mt-10">
                            <Button
                                className="secondary-hover"
                                label="Cancel"
                                borderRadius="12px"
                                onClick={() => setEdit(false)}
                                secondary={true}
                                size="average"
                                style={{ flex: 1 }}
                                bgColor='#FFFFFF'
                                borderColor="#24CBB1"
                                color="#24CBB1"
                                borderWidth="1px"
                            />
                            <Button
                                label='Done'
                                onClick={() => checkPermission(() => saveTier(), 'CAMPAIGNS_MANAGE')}
                                size="average"
                                style={{ flex: 1, marginLeft: 24 }}
                                bgColor='#24CBB1'
                                borderColor="#24CBB1"
                                color='#FFFFFF'
                                borderRadius="12px"
                                borderWidth="1px"
                                disabled={!hasPermission('CAMPAIGNS_MANAGE')}
                            />
                        </div>
                    </div>
                    :               
                    <div className="flex-column pxy-6">
                        <div className="flex items-center">
                            <div className="tier-details-color" style={{ background: tier.color_code }} />
                            <label className="semibold-text font-16 ml-2">{tier.name}</label>
                        </div>
                        <label className="regular-text light-purple-text mt-4">{tier.description}</label>
                        <label className="regular-text font-12 light-purple-text mt-6">Template name:</label>
                        <label className="regular-text mt-2">{tier.template?.name}</label>
                        <div className="flex mt-6">
                            <div className="flex-column flex-1">
                                <label className="regular-text font-12 light-purple-text">Rank</label>
                                <div className="flex items-center justify-center py-3 mt-1 grey-box">
                                    <label className="regular-text font-16">{tier.rank?.min}-{tier.rank?.max}</label>
                                </div>
                            </div>
                            <div className="flex-column flex-1 ml-6">
                                <label className="regular-text font-12 light-purple-text">Priority</label>
                                <div className="flex items-center justify-center py-3 mt-1 grey-box">
                                    <label className="regular-text font-16">{tier.priority}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                }                    
                </div>
                <div className="card flex-column flex-1 ml-6">
                    <Header title="Leaderboard"
                        size="small"
                        isPaged={false}
                        isSearch={false}
                        isFilter={false}
                        isAddBtn={false}
                        menuItems={[]}/>
                    <div className="flex-column border-top tier-details-content mt-3">
                        <div className="flex items-center justify-center flex-1">
                            <label className="bold-text font-16 light-purple-text">There is no leaderboard</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TierDetails;
