import HidePassword from '../../assets/images/hide-password.svg';
import ArrowRightIcon from '../../assets/images/menu/arrow-right-black.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { capitalize, formatText, getIntegrationTypeIcon } from '../../helpers/utils';
import api from '../../store/services'
import { addToast} from '../../store/features/toastSlice'
import Button from '../../components/Elements/button/button';
import Dropdown from '../../components/Elements/dropdown/dropdown';
import Loading from '../../assets/images/loading.gif';
import { OrganisationContext } from '../../context/organisationContext';

const SelectedIntegration = ({ selectedIntegration, setSelectedIntegration, updateIntegration, hasPermission }) => {
  const [integration, setIntegration] = useState({ ...selectedIntegration });
  const [showPassword, setShowPassword] = useState({});
  const [errorFields, setErrorFields] = useState({});
  const [configData, setConfigData] = useState([]);
  const [loadingConfigData, setLoadingConfigData] = useState(false);
  const textareaRef = useRef();

  const dispatch = useDispatch();
  const { setModal, modal } = useContext(OrganisationContext);

  const fetchData = async () => {
    const { data } = await api.get(`/v3/api/integrations/${selectedIntegration.id}`);
    setIntegration(data);
  };

  const fetchConfigData = async () => {
    setLoadingConfigData(true);
    const { data } = await api.get(`/v3/api/connector/${selectedIntegration.connector.id}/config`);
    setConfigData(data);
    setLoadingConfigData(false);
  };

  useEffect(() => {
    fetchData();
    fetchConfigData();
  }, [selectedIntegration.id]);

  const checkErrors = () => {
    let errors = {};
    configData.forEach(config => {
      if (config.conditional) {
        const isAvailable = getIntegrationParamValue(config.conditional.param_name) === config.conditional.param_value;
        if (isAvailable) {
          const value = getIntegrationParamValue(config.id);
          errors = { ...errors, [config.id]: !value };
        }
      } else {
        const value = getIntegrationParamValue(config.id);
        errors = { ...errors, [config.id]: !value };
      }
    });
    setErrorFields(errors);
    return Object.values(errors).some(error => error);
  };

  const getSelectedConfigs = () => {
    const selectedConfigs = configData.reduce((acc, curr) => {
      if (curr.conditional) {
        const isAvailable = getIntegrationParamValue(curr.conditional.param_name) === curr.conditional.param_value;
        if (isAvailable) {
          acc.push(curr);
        }
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
    return selectedConfigs;
  };

  const onSave = async () => {
    if (checkErrors()) {
      dispatch(
        addToast({
          error: true,
          text: 'Please enter all required fields before enabling this integration',
          id: 'error-required-field',
        }),
      );
      return;
    }
    try {
      const { integration_params } = integration;
      const selectedConfigs = getSelectedConfigs();
      const request = {
        ...integration,
        integration_params:
          integration_params
            .filter(p => selectedConfigs.find(config => config.id === p.param_name))
            .map(param => ({ ...param, param_value: param.param_value || null })) || [],
      };
      const { data } = await api.put(`/v3/api/integrations/${integration.id}`, request);

      dispatch(
        addToast({
          error: false,
          text: 'Integration updated successfully',
          id: uuid(),
        }),
      );
      updateIntegration(data);
      setSelectedIntegration({});
    } catch (e) {
      dispatch(
        addToast({
          error: true,
          text: 'Error while updating integration',
          id: uuid(),
        }),
      );
    }
  };

  const updateIntegrationParams = (name, value) => {
    const isAvailable = integration.integration_params?.find(param => param.param_name === name);
    setIntegration({
      ...integration,
      integration_params: isAvailable
        ? [
            ...integration.integration_params.map(param =>
              param.param_name === name ? { ...param, param_value: value } : param,
            ),
          ]
        : [...integration.integration_params, { param_name: name, param_value: value }],
    });
    setErrorFields({ ...errorFields, [name]: false });
  };

  const getIntegrationParamValue = name => {
    return integration.integration_params?.find(param => param.param_name === name)?.param_value || '';
  };

  useEffect(() => {
    if (modal.type === 'integration-success') {
      if (modal.content.isDisable) {
        updateIntegration({ ...selectedIntegration, is_enabled: false });
        setSelectedIntegration({ ...selectedIntegration, is_enabled: false });
        setIntegration({ ...integration, is_enabled: false });
      }
    }
  }, [modal.type]);

  const onChangeStatus = async is_enabled => {
    if (is_enabled) {
      setModal({ type: 'integration-actions', content: { integration: integration, top: true, isDisable: true } });
    } else {
      try {
        const request = {
          enabled: !is_enabled,
        };
        updateIntegration({ ...selectedIntegration, is_enabled: !is_enabled });
        setSelectedIntegration({ ...selectedIntegration, is_enabled: !is_enabled });
        setIntegration({ ...integration, is_enabled: !is_enabled });
        await api.put(`/v3/api/integrations/${integration.id}/state`, request);
      } catch (error) {
        const errorData = error?.response?.data;
        updateIntegration({ ...selectedIntegration, is_enabled: is_enabled });
        setSelectedIntegration({ ...selectedIntegration, is_enabled: is_enabled });
        setIntegration({ ...integration, is_enabled: is_enabled });
        dispatch(
          addToast({
            error: true,
            text:
              errorData?.error_code === 'BAD_REQUEST'
                ? 'Please add all required fields in integration'
                : 'Error while updating status',
            id: uuid(),
          }),
        );
      }
    }
  };

  return (
    <>
      <div className="selected-integration-container">
        <div className="flex items-center">
          <label className="regular-text grey-text hover-underline">Platform</label>
          <img className="mx-2" src={ArrowRightIcon} />
          <label className="regular-text grey-text hover-underline cursor" onClick={() => setSelectedIntegration({})}>
            Integrations
          </label>
          <img className="mx-2" src={ArrowRightIcon} />
          <label className="bold-text">{capitalize(formatText(integration.connector?.name))}</label>
        </div>
        <div className="flex">
          <Button
            className={`mr-4 selected-integration-action secondary-hover`}
            label="Cancel"
            onClick={() => setSelectedIntegration({})}
            secondary={true}
            size="medium"
          />
          <Button
            className={`selected-integration-action ${hasPermission('INTEGRATIONS_MANAGE') && 'primary-hover'}`}
            disabled={hasPermission('INTEGRATIONS_MANAGE') ? false : true}
            label="Save"
            onClick={onSave}
            primary={true}
            size="medium"
          />
        </div>
      </div>
      <div className="selected-integration">
        <div className="selected-integration-left">
          <div className="flex-column items-center">
            <label className="medium-text font-24 mb-1">{capitalize(formatText(integration.connector.name))}</label>
            <img alt="icon" src={`${integration.connector.icon?.active}`} style={{ width: 200, height: 200 }} />
          </div>
          <div className="flex-column mt-6">
            <label className="regular-text field-label mb-2">Name</label>
            <input
              autoComplete="new-password"
              className={`popup-input form-input ${errorFields.name && 'error-info'}`}
              onChange={e => setIntegration({ ...integration, name: e.target.value })}
              placeholder="Enter integration name"
              value={integration.name || ''}
            />
          </div>
          <div className="w-full flex-column mb-4 mt-4">
            <label className="regular-text field-label mb-2">Description</label>
            <textarea
              ref={textareaRef}
              className="popup-input form-input description-input"
              onChange={e => setIntegration({ ...integration, description: e.target.value })}
              placeholder="Enter description here"
              rows={4}
              value={integration.description || ''}
            />
          </div>
          <div className="flex justify-between mt-6">
            <span className={`regular-text font-16`}>Enabled</span>
            <div
              className={`switch ml-1 ${integration.is_enabled && 'switch-selected'}`}
              onClick={() => onChangeStatus(integration.is_enabled)}>
              <div className="selector" />
            </div>
          </div>
        </div>
        <div className="selected-integration-right w-full">
          <div className="mr-10 ml-10 my-4">
            <label className="bold-text">SETTINGS</label>
          </div>
          <hr />
          <div className="mr-10 ml-10 mt-6 mb-8">
            {loadingConfigData ? (
              <div className={`flex items-center justify-center loading-progress`}>
                <img alt="loading" height="48px" src={Loading} width="48px" />
              </div>
            ) : (
              <>
                {configData.map(config => (
                  <>
                    {config.conditional ? (
                      <>
                        {config.conditional.param_value === getIntegrationParamValue(config.conditional.param_name) && (
                          <div className="flex-column mt-6">
                            <label className="regular-text field-label mb-2">{config.name}</label>
                            <div className="integration-input">
                              <input
                                autoComplete="turnoff"
                                className={`popup-input form-input api-key-input ${
                                  errorFields[config.id] && 'error-info'
                                } ${config.param_type === 'PASSWORD' && !showPassword[config.id] && 'password-mask'}`}
                                onChange={e => updateIntegrationParams(config.id, e.target.value)}
                                placeholder={config.name}
                                type="text"
                                value={getIntegrationParamValue(config.id) || ''}
                              />
                              {config.param_type === 'PASSWORD' && (
                                <div className="input-show-img">
                                  <img
                                    alt="icon"
                                    onClick={() => setShowPassword({ [config.id]: !showPassword[config.id] })}
                                    src={showPassword[config.id] ? ShowPassword : HidePassword}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : config.param_type === 'LIST' ? (
                      <div className="flex-column mt-6">
                        <label className="regular-text field-label mb-2">{config.name}</label>
                        <Dropdown
                          className={`dropdown-hover ${errorFields[config.id] ? 'error-info' : ''}`}
                          onSelect={option => updateIntegrationParams(config.id, option.id)}
                          options={config.choices}
                          placeholder={config.name}
                          selected={config.choices?.find(type => type.id === getIntegrationParamValue(config.id))}
                          withIcon={false}
                        />
                      </div>
                    ) : (
                      <div className="flex-column mt-6">
                        <label className="regular-text field-label mb-2">{config.name}</label>
                        <div className="integration-input">
                          <input
                            autoComplete="turnoff"
                            className={`popup-input form-input api-key-input ${
                              errorFields[config.id] && 'error-info'
                            } ${config.param_type === 'PASSWORD' && !showPassword[config.id] && 'password-mask'}`}
                            onChange={e => updateIntegrationParams(config.id, e.target.value)}
                            placeholder={config.name}
                            type="text"
                            value={getIntegrationParamValue(config.id) || ''}
                          />
                          {config.param_type === 'PASSWORD' && (
                            <div className="input-show-img">
                              <img
                                alt="icon"
                                onClick={() => setShowPassword({ [config.id]: !showPassword[config.id] })}
                                src={showPassword[config.id] ? ShowPassword : HidePassword}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectedIntegration;
