import styled from 'styled-components';

const AutomationWrapper = styled.div`
  width: 100%;
  margin: 14px;

  .add-action {
    display: flex;
    align-items: center;
    height: 40px;
    background: #31ddca4d;
    border-radius: 8px;
    cursor: pointer;
    padding: 16px;
    margin-right: 16px;

    &:hover {
      background: #2fedd84d;
    }
  }

  .add-action-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    path {
      fill: #053149;
    }
  }

  .automation-wrapper {
    display: flex;
    flex-direction: column;
  }
  .automation-item-wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    overflow: auto;
    margin: 8px 0px;
  }
  .automation-item-container {
    display: flex;
    background: #fff;
    margin: 8px 0px;
    border-radius: 8px;
    box-shadow: 2px 2px 15px rgb(5 49 73 / 5%);

    .automation-name {
      display: flex;
      padding: 16px 24px;
      width: 297px;

      .automation-text-name {
        width: 297px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: auto;
      }
    }
    .automation-description {
      display: flex;
      align-item: center;
      justify-centent: flex-start;
      flex: 1;
      padding: 16px 24px;

      .double-line {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          display: block;
        }
      }

      .no-description {
        color: rgba(115, 134, 140, 0.5);
      }
    }

    .enable-switch-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;
      width: 200px;
    }

    .owner-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      width: 135px;

      .owner-logo {
        height: 32px;
        width: 32px;
        margin-right: 12px;
        padding: 8px;
        background: #e6eeff;
        border-radius: 50%;

        label {
          color: #73868c;
        }
      }
      .card {
        background: #ffffff;
        box-shadow: 2px 2px 15px rgb(5 49 73 / 5%);
        border-radius: 8px;
      }
      .menu-wrapper {
        position: absolute;
        top: -25px;
        width: 200px;
        right: 25px;
        padding: 4px;
        z-index: 1;

        .regular-text {
          padding: 8px;
          display: flex;
          align-items: center;
          color: #053149;
          white-space: nowrap;
        }

        .regular-text:hover {
          background: #f4f9f8;
        }

        .border-bottom {
          border-bottom: 1px solid #d2e5ed;
        }
      }
    }
  }

  .content-loader {
    position: absolute;
    inset: 0;
    margin: 0;
    opacity: 0.8;
    z-index: 10;
  }
  .conditional-options-select-container {
    max-height: 40px;
    width: 100%;

    .select__placeholder {
      color: #053149;
      opacity: 0.5;
      font-family: Roboto Regular;
      font-size: 14px;
    }

    .select__value-container {
      padding: 2px 0px 2px 6px;
    }

    .select__control {
      border-radius: 8px;
      border: 1px solid #d2e5ed;
      flex-wrap: nowrap;

      &:hover {
        background: #f4f7f9;
      }
    }

    .select__control--is-focused {
      border: 1px solid #d2e5ed;
      box-shadow: unset;
      flex-wrap: nowrap;
    }

    .select__single-value {
      color: #053149;
      font-size: 16px;
      font-family: Roboto Regular;
    }

    .select__clear-indicator,
    .select__indicator-separator {
      display: none;
    }

    .select__indicators {
      .select__indicator {
        padding: 8px 2px 8px 0px;

        svg {
          height: 18px;
          width: 18px;
        }
      }
    }

    .select__menu {
      top: unset;
      bottom: 100%;

      .select__menu-list {
        max-height: 140px;
      }
    }
  }
  .integration-container {
    display: flex;
    justify-content: space-between;
    margin: 8px 14px;

    .integration-pagination {
      height: unset;
    }

    .integration-search {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      width: 230px;
      border: 1px solid #e3eef3;
      border-radius: 12px;
      background: #ffffff;
      padding: 0 16px;
      user-select: none;
      border: 1px solid #d2e5ed;

      &:hover {
        background: #f4f7f9;

        input {
          background: #f4f7f9;
        }

        .close {
          background: #f4f7f9;
        }
      }

      input {
        width: 100%;
        border: none;
        outline: none;
        padding-left: 8px;

        &::-webkit-input-placeholder {
          opacity: 0.5;
        }
      }

      .search-icon {
        height: 16px;
        width: 16px;
        opacity: 0.5;
      }

      .close {
        background: #f4f7f9;
        border-radius: 100px;
        width: 30px;
        height: 24px;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
      }

      .close-icon {
        width: 14px;
        height: 14px;
        margin-left: 5px;
      }
    }
    .search-focused {
      border: 1px solid #40f1de;
    }
  }

  .loading-automation {
    position: absolute;
    inset: 0;
  }

  .selected-automation-container {
    display: flex;
    justify-content: space-between;
    margin: 8px 14px;

    .selected-automation-action {
      padding: 8px 40px;
    }
  }
  .integrations {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media only screen and (max-width: 1600px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media only screen and (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 800px) {
      grid-template-columns: 1fr;
    }

    row-gap: 20px;
    column-gap: 20px;
    overflow-y: scroll;
    max-height: calc(100vh - 90px);
    overflow: scroll;
    padding: 12px 10px;

    .integration-add-btn {
      min-height: 152px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: #f4f7f9;
      border: 2px dashed #d2e5ed;
      border-radius: 8px;

      label {
        font-size: 16px;
      }
    }

    .integration-item-container {
      display: flex;
      flex-direction: column;
      box-shadow: 0px 5px 30px rgba(9, 19, 53, 0.04);
      border-radius: 8px;
      background: white;
      min-height: 152px;
      height: 100%;

      hr {
        margin: 0;
      }

      .integration-header {
        padding: 24px;
        height: 100%;

        .automation-desc {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #73868c;

          &:hover {
            display: block;
          }
        }
      }

      .integration-item-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #ffffff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .integration-item-left {
          display: flex;
          flex-direction: column;
          margin-right: 4px;

          .integration-item-name {
            font-family: Roboto Bold;
            font-size: 18px;
            color: #092c40;
          }

          .hide-name {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              display: block;
            }
          }
        }

        .integration-item-right {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          outline: none;

          .owner-logo {
            margin-right: 12px;
            padding: 8px;
            background: #e6eeff;
            border-radius: 50%;

            label {
              color: #73868c;
            }
          }
        }
      }

      .integration-item-bottom {
        display: flex;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 16px 24px;
        justify-content: space-between;
        align-items: center;

        .integration-type {
          span {
            font-size: 20px;
          }
        }

        .integration-status {
        }

        .enable-color {
          color: #40f1de;
        }

        .disable-color {
          color: #d2e5ed;
        }

        .switch {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 48px;
          height: 24px;
          background: #d2e5ed;
          box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);
          border-radius: 20px;

          &:hover {
            background: #e5ebef;
          }

          .selector {
            min-width: 20px;
            min-height: 20px;
            background: #ffffff;
            box-shadow: 1px 0px 5px rgba(5, 49, 73, 0.2);
            border-radius: 50%;
            margin: 0 2px;
            transition: transform 400ms;
          }
        }

        .switch-selected {
          background: #40f1de;
          box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);

          &:hover {
            background: #40f1de;
          }

          .selector {
            transform: translate(24px);
          }
        }
      }
    }

    .card {
      background: #ffffff;
      box-shadow: 2px 2px 15px rgb(5 49 73 / 5%);
      border-radius: 8px;
    }
    .menu-wrapper {
      position: absolute;
      top: -5px;
      width: 200px;
      right: 25px;
      padding: 4px;
      z-index: 1;

      .regular-text {
        padding: 8px;
        display: flex;
        align-items: center;
        color: #053149;
        white-space: nowrap;
      }

      .regular-text:hover {
        background: #f4f9f8;
      }

      .border-bottom {
        border-bottom: 1px solid #d2e5ed;
      }
    }
  }

  .selected-integration-container {
    display: flex;
    justify-content: space-between;
    margin: 8px 14px;

    .selected-integration-action {
      padding: 8px 40px;
    }
  }

  .selected-automation {
    display: flex;
    margin: 20px 16px;

    .field-label {
      color: #73868c;
      font-size: 14px;
    }

    .popup-input {
      margin-bottom: unset;
      padding: 12px 15px;

      &:focus {
        border: 1px solid #31ddca;
      }
    }

    .error-info {
      border-color: red;

      .selector,
      .select__control,
      .select__control--is-focused {
        border-color: red !important;
      }
    }

    .left-scroll {
      max-height: calc(100vh - 150px);
      overflow: auto;
      min-width: 440px;
      max-width: 440px;
    }

    .selected-automation-left {
      background: #ffffff;
      min-width: 424px;
      max-width: 424px;
      // box-shadow: 2px 8px 30px rgb(9 19 53 / 6%);
      border-radius: 8px;
      // margin: 24px 0px;
      height: auto;
      padding: 10px;
      border-left: 4px solid #8ab2ff;

      .action-icons {
        .action-menu {
          right: 20px;
        }
      }
    }

    .current-selected-left {
      box-shadow: 2px 8px 30px rgb(9 19 53 / 6%);
    }

    .selected-automation-right {
      background: #ffffff;
      box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
      border-radius: 8px;
      height: fit-content;

      .api-key-input {
        font-size: 16px;
      }

      .automation-input {
        display: flex;
        width: 100%;
        position: relative;

        .api-key-input {
          width: 100%;
          padding: 12px 30px 12px 15px;
        }

        .input-show-img {
          position: absolute;
          right: 0;
          height: 100%;
          display: flex;
          align-items: center;
          margin-right: 8px;
          cursor: pointer;
        }
      }
    }

    .password-mask {
      text-security: disc; /* IE/Safari */
      -moz-text-security: disc; /* FireFox */
      -webkit-text-security: disc; /* Chrome/Safari  */
    }
  }
  .selected-automation-name-container {
    margin: 26px 13px;
    padding: 1px;
  }
  .automation-selected-name {
    font-size: 24px;
    color: #053149;
    height: 28px;
    width: auto;
  }
  .add-new-action-button {
    width: 424px;
    height: 48px;
    border-radius: 8px;
    background: #ffffff;
    border: 2px solid #d2e5ed;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    label {
      width: auto;
      height: 19px;
      font-size: 16px;

      display: flex;
      align-items: center;

      color: #053149;

      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }
  .dot {
    min-width: 8px;
    min-height: 8px;
    background: #ff5b5b;
    border-radius: 50%;
    margin: 10px 10px;
    margin-right: 16px;
  }
  .line {
    display: flex;
    flex: 1;
    background-color: #d2e5ed;
    width: 1px;
    min-height: 32px;
    margin-left: 26px;
  }

  // right side css
  .selected-integration-right {
    background: #ffffff;
    box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
    border-radius: 8px;
    height: fit-content;
    max-height: calc(100vh - 150px);
    // margin-top: 30px;
    margin-left: 10px;
    // width: auto;
    display: flex;
    flex-direction: column;

    .menu-btn-header {
      margin-right: 16px;
    }
  }
  .trigger-container {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;

    .detail-log-menu {
      padding: 4px 8px;
      .menu {
        right: calc(100% + 10px);
      }
    }
    hr {
      margin-bottom: 0px !important;
    }
  }
  .search-container {
    display: flex;
    padding: 24px 24px 16px 24px;

    .logs-search {
      .close-icon {
        background: #f4f7f9;
        border-radius: 50%;
        padding: 4px;
        margin-left: 4px;
      }
    }
    .search-focused {
      border: 1px solid #31ddca;
    }
  }

  .integration-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 312px;
    border: 1px solid #e3eef3;
    border-radius: 12px;
    background: #ffffff;
    padding: 0 16px;
    user-select: none;
    border: 1px solid #d2e5ed;
    margin-right: 20px;

    &:hover {
      background: #f4f7f9;

      input {
        background: #f4f7f9;
      }

      .close {
        background: #f4f7f9;
      }
    }

    input {
      width: 100%;
      border: none;
      outline: none;
      padding-left: 8px;

      &::-webkit-input-placeholder {
        opacity: 0.5;
      }
    }

    .search-icon {
      height: 16px;
      width: 16px;
      opacity: 0.5;
    }
  }
  .text-label {
    width: 238px;
    height: 28px;
    left: 1104px;
    top: 209px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;

    /* Grey */

    color: #73868c;
  }
  .leads-container {
  }

  .left-boxes {
    background: #f4f7f9;
    border-radius: 4px;
  }

  .leads-boxes {
    background: #f4f7f9;
    border-radius: 4px;

    &:hover {
      background: #053149;
      color: white !important;

      .medium-text,
      .regular-text {
        color: white !important;
      }
    }
  }

  .leads-icon {
    margin: 0px 4px;
    // background: red;
  }
  .main-container {
    width: auto;
    overflow: scroll;
    padding: 16px 24px;
    flex: 1 1;
    height: calc(100vh - 280px);
  }
  .automations-logs-row {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(0, 3fr) minmax(0, 3fr) 2fr 2fr 0.5fr;
    height: 40px;
  }
  .automations-logs-header {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(0, 3fr) minmax(0, 3fr) 2fr 2fr 0.5fr;
    background: #f4f7f9;
    border-radius: 4px;
    height: 40px;
  }
  .automations-logs-item {
    border-right: 1px solid #fff;
    line-height: 20px;

    .dmi-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      // padding: 0 16px;
    }

    .status {
      border-radius: 4px;
      color: #ffffff;
      padding: 0 4px;
    }
  }

  .automations-logs-header {
    .automations-logs-item {
      border-right: 1px solid #e3eef3;
    }
  } // automation logs css
  .data-match-row {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(0, 3fr) minmax(0, 3fr) 2fr 2fr 0.5fr;
    background: #ffffff;
    box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
    border-radius: 8px;
    height: 40px;
  }
  .data-match-item {
    border-right: 1px solid #e3eef3;
    line-height: 20px;

    .dmi-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 0 16px;
    }

    .status {
      border-radius: 4px;
      color: #ffffff;
      padding: 0 4px;
    }
  }

  .data-match-item:last-child {
    border: none;
    justify-content: space-between;
    padding-left: 16px;
  }
  .data-matches {
    // overflow-y: scroll;
    padding: 0 4px;
    .main-container {
      overflow: unset;

      .scroller {
        overflow: auto;

        hr {
          margin-bottom: 10px;
          background-color: #f4f7f9;
        }
      }
    }
  }

  // for react-table
  .rt-thead {
    &.-header {
    }
    .rt-th {
      box-shadow: none;

      // background-color: #e5e5e5;
      // left: 31.18%;
      // right: 67.99%;
      // top: 16%;
      // bottom: 82.44%;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */

      text-align: center;
      text-transform: uppercase;

      /* Grey */

      color: #73868c;
    }
  }

  .rt-tbody {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    /* Dark blue */

    color: #053149;

    // background-color: green;
  }
  // }
  .success-badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    gap: 8px;
    width: auto;

    background: #7bd77a;
    border-radius: 4px;
    height: auto;
    left: 85.86%;
    right: 9.48%;
    top: 27.5%;
    bottom: 27.5%;
    width: 50%;
    margin-top: 3px;
  }

  // details page css
  .container {
    display: flex;
    width: 100%;
    // margin: 0px 24px;
    padding: 24px;

    div > input {
      background: #ffffff;
      /* Light grey 2 */

      border: 1px solid #d2e5ed;
      border-radius: 8px;
      width: 90%;
      height: 48px;
      margin: 15px 0px;
    }
  }
  .for-placeholder {
    margin-bottom: unset;
    padding: 12px 15px;
  }

  // Integration css
  .label-container {
  }
  .integration-boxes {
    // width: 312px;
    height: 56px;
    background: #f4f7f9;
    border-radius: 4px;

    &:hover {
      background: #053149;
      color: white !important;

      .medium-text,
      .regular-text {
        color: white !important;
      }
    }
  }
  .integration-list {
    .options {
      top: 48px;
      // background: green;
      // width: auto;
      overflow: scroll;
      height: 150px;
    }
  }
  .details-menu-wrapper {
    position: absolute;
    width: 180px;
    right: 63px;
    padding: 4px;
    z-index: 1;
  }

  .border-bottom {
    border-bottom: 1px solid #d2e5ed;
  }

  .view-more-btn {
    width: 30%;
    margin: 1% 25%;
    text-align: center;
    padding: 5px;
    background-color: #f2f6f7;
    border-radius: 16px;
    cursor: pointer;
  }
  .tpus-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin: 0 8px;
    cursor: pointer;

    label {
      width: 100%;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .tpus-user:hover {
    background: rgba(210, 229, 237, 0.3);
    border-radius: 4px;
    cursor: pointer;
  }
  .options-select-container {
    width: 100%;

    .value-logo {
      margin-right: 8px;
      padding: 8px;
      background: #d2e5ed;
      border-radius: 50%;

      label {
        color: #73868c;
      }
    }

    .select__placeholder,
    .select__menu-notice--no-options {
      color: #053149;
      opacity: 0.3;
    }

    .select__value-container {
      padding-left: 16px;
      padding-top: 6px;
      padding-bottom: 6px;
      min-height: 46px;

      .select__input-container {
        display: none;
      }
    }

    .select__control {
      border-radius: 10px;
      border: 1px solid #d2e5ed;

      &:hover {
        background: #f4f7f9;
      }
    }

    .select__control--is-focused {
      border: 1px solid #31ddca;
      box-shadow: unset;
    }

    .select__control--menu-is-open {
      // border: 1px solid #31ddca;
      .select__single-value {
        display: none;
      }
    }

    .select__menu-list {
      max-height: 200px;
    }

    .select__multi-value {
      background: #d2e5ed;
      border-radius: 4px;
    }

    .select__multi-value__label {
      color: red;
      font-size: 16px;
    }

    .select__clear-indicator,
    .select__indicator-separator {
      display: none;
    }

    .select__menu {
      margin-top: 4px;
    }
  }

  .opened {
    .select__control {
      border-radius: 8px;
      border: 1px solid #31ddca;
    }
    .select__value-container {
      .select__input-container {
        display: flex;

        input {
          height: 26px;
        }
      }
    }
    .hide-single-value {
      display: none;
    }
  }

  .attachment-options {
    .select__value-container {
      .select__input-container {
        display: flex;

        input {
          height: 26px;
        }
      }
    }

    .select__indicators {
      svg {
        fill: #053149;
      }
    }
  }
`;

export default AutomationWrapper;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -10px;
  right: 100%;
  background: #ffffff;
  padding: 6px;
  z-index: 2;
  cursor: pointer;
  * {
    cursor: pointer;
  }

  .regular-text {
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;

    &:hover {
      background: #f4f9f8;
      border-radius: 4px;
    }
  }


  .switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 48px;
    height: 24px;
    background: #d2e5ed;
    box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);
    border-radius: 20px;

    &:hover {
      background: #e5ebef;
    }

    .selector {
      min-width: 20px;
      min-height: 20px;
      background: #ffffff;
      box-shadow: 1px 0px 5px rgba(5, 49, 73, 0.2);
      border-radius: 50%;
      margin: 0 2px;
      transition: transform 400ms;
    }
  }

  .switch-selected {
    background: #40f1de;
    box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);

    &:hover {
      background: #40f1de;
    }

    .selector {
      transform: translate(24px);
    }
  }
`;
