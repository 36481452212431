import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import Image from '../components/image';
import Button from '../components/Elements/button/button';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { addToast } from '../store/features/toastSlice';
import { MintNftWrapper } from './modal.styled.js';
import { CSSTransition } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import api from '../store/services'
import { setNft } from '../store/features/nftSlice';

const MintRetirementNft = () => {
  const navigate = useNavigate();
  const { modal, setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const nft = modal.content.nft;

  const onMint = async () => {
    if (loading) return;
    setLoading(true);
    try {
    await api.post(`/v3/api/backoffice/mint`, [{id: nft.id}])
      dispatch(
        addToast({
          error: false,
          text: `NFT has been minted`,
          id: uuid(),
        }),
      );
      dispatch(setNft({...nft, lifecycle: "QUEUED"}))
    } catch (error) {
      dispatch(
        addToast({
          error: true,
          text: `Failed to mint NFT`,
          id: uuid(),
        }),
      );
    }
  
    setLoading(false);
    setModal(initModal);
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <MintNftWrapper>
        <p className="bold-text font-24 text-center">
          Are you sure you want to mint this NFT?
        </p>
        <label className='regular-text light-purple-text mt-2'>Once minted this action can not be undone</label>
        <div className="flex-column mn-details-container pxy-2 relative" style={{ marginTop: 32 }}>
          <Image className={"mn-image"} size='313' media={nft?.image?.media} />
          <label className='bold-text font-16 mt-4 px-2'>{nft?.name}</label>
          <label className='regular-text font-12 o05 mt-2 px-2 pb-2'>{nft?.description}</label>
        </div>
        <div className="flex justify-center mt-12">
          <Button
            className="secondary-hover"
            label="Cancel"
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            style={{ flex: 1, borderRadius: '16px' }}
            bgColor="#FFFFFF"
            borderColor='#24CBB1'
            color='#24CBB1'
            width='152px'
          />
          <Button
            label='Mint'
            onClick={onMint}
            size="large"
            style={{ flex: 1, marginLeft: 24, borderRadius: '16px' }}
            bgColor="#24CBB1"
            borderColor='#24CBB1'
            color='#FFFFFF'
            width='152px'
          />
        </div>
      </MintNftWrapper>
    </CSSTransition>
  );
};

export default MintRetirementNft;
