import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getCollectionTemplates } from '../../../store/features/collectionSlice';
import { useParams, useSearchParams } from 'react-router-dom';

import Header from "../../header";
import AddTemplatePopup from "../addTemplatePopup";
import Template from "../../templates/Template";

import './style.scss';

const CollectionTemplates = ({ collection, menuItems, onMenuItemClick}) => {
    const { collectionTemplates } = useSelector(data => data.collection);

    const dispatch = useDispatch();

    const {id} = useParams();

    const [searchParams] = useSearchParams();
    const integrationId = searchParams.get('integration');

    const [search, setSearch] = useState('')
    const [add, setAdd] = useState(false)

    useEffect(() => {
            dispatch(getCollectionTemplates({collectionId: id, search: search, integration: integrationId }));
    }, [id, search, integrationId, dispatch]);

    return (
        <div className="flex-column flex-1">
            <Header title={"Templates"}
                    search={search}
                    setSearch={setSearch}
                    isFilter={false}
                    isPaged={false}
                    size='small'
                    menuItems={menuItems}
                    onMenuItemClick={onMenuItemClick}
                    isAddBtn={false}
                    addPermission="CAMPAIGNS_MANAGE"
                    onAdd={() => setAdd(true)}
                    />

            <div className="templates-list pb-6 mt-4 border-top">
                {collectionTemplates.map(template => <Template key={template.id} collection={collection} template={template} />)}
            </div>
            {add && <AddTemplatePopup onCancel={() => setAdd(false)} collectionId={id} />}
        </div>
    )
}

export default CollectionTemplates;
