import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getCollectionNfts } from '../../../store/features/collectionSlice';

import { useParams } from 'react-router-dom';

import NftList from "../../nfts/NftList";

import './style.scss';

const CollectionNfts = ({menuItems, onMenuItemClick}) => {
    const dispatch = useDispatch();

    const {id} = useParams();

    const { collectionNfts } = useSelector(data => data.collection);

    const [filter, setFilter] = useState({value: ''});
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')
    const [lifecycle, setLifecycle] = useState()

    useEffect(() => {
        dispatch(getCollectionNfts({collectionId: id, page: currentPage, search: search, filter: filter?.value}));
    }, [id, currentPage, search, filter?.value, dispatch]);

    return (
        <div className="flex-column flex-1 pb-12">
              <NftList 
                nfts={collectionNfts.content}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={collectionNfts.total_pages}
                search={search}
                setSearch={setSearch}
                lifecycle={lifecycle}
                setLifecycle={setLifecycle}
                isAddBtn={false}
                headerSize='small'
                menuItems={menuItems}
                onMenuItemClick={onMenuItemClick} 
                filter={filter}
                setFilter={setFilter}
                filterObjects={["NFT_TYPE"]}
            />
        </div>
    )
}

export default CollectionNfts;
