import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { update } from './store/features/reloadSlice'
import { injectStore } from './store/services';

import store from './store/store';

injectStore(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
const configuration = {
    onUpdate: registration => {
        if (registration && registration.waiting) {
            store.dispatch(update(registration));
        }
    },
};

serviceWorkerRegistration.register(configuration);
