import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import DateSelectorElement from '../date-selector-element/date-selector-element';

import DeleteIcon from '../../../assets/images/trash-chart.svg';

import './date-selectors.scss';

const DateSelectors = ({ close, from, setFrom, to, setTo, removeDates }) => {
  const ref = useRef();

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      close(false);
    }
  };

  useEffect(() => {
    if (ref.current) ref.current.focus();
  }, [ref]);

  return (
    <div ref={ref} className="flex flex-column pxy-6 card absolute date-selectors" onBlur={onBlur} tabIndex={0}>
      <div className="flex items-center justify-between">
        <label className="bold-text font-12 mt-4 mb-6">DATE RANGE</label>
        <div className="flex items-center justify-center cursor delete-date-btn" onClick={removeDates}>
          <img src={DeleteIcon} />
        </div>
      </div>
      <div className="flex items-center">
        <label className="regular-text font-16 selector-label">From</label>
        <DateSelectorElement placeholder="From" selectedDate={from} setSelectedDate={setFrom} />
      </div>
      <div className="flex items-center mt-4">
        <label className="regular-text font-16 selector-label">To</label>
        <DateSelectorElement placeholder="To" selectedDate={to} setSelectedDate={setTo} />
      </div>
    </div>
  );
};

export default DateSelectors;
