import React, { useEffect, useRef, useState } from 'react';

import '../../pages/projectDetails/details.scss';

const MainDetails = ({ project, changeProject }) => {

    const descriptionRef = useRef(null);
    const longDescriptionRef = useRef(null);


    useEffect(() => {
        descriptionRef.current.style.height = '0px';
        const scrollHeightHeadline = descriptionRef.current.scrollHeight;
        descriptionRef.current.style.height = scrollHeightHeadline + 5 + 'px';
      }, [project?.description]);

      useEffect(() => {
        longDescriptionRef.current.style.height = '0px';
        const scrollHeightHeadline = longDescriptionRef.current.scrollHeight;
        longDescriptionRef.current.style.height = scrollHeightHeadline + 5 + 'px';
      }, [project?.long_description]);


    return (
        <div className='flex-column px-6 pt-6 pb-6 border-top'>
            <div className={"flex"}>
                <div className="flex-column flex-1">
                    <label className="input-label">Short description</label>
                    <textarea className="textarea mt-1" ref={descriptionRef} value={project?.description || ''} placeholder={'Add short description'} onChange={e => changeProject({...project, description: e.target.value})}/>
                    <label className="input-label mt-6">Long description</label>
                    <textarea className="textarea mt-1" ref={longDescriptionRef} value={project?.long_description || ''} placeholder={'Add long description'} onChange={e => changeProject({...project, long_description: e.target.value})}/>
                </div>
            </div>

        </div>
    )
}

export default MainDetails;
