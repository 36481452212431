import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import uuid from 'react-uuid';
import Button from '../components/Elements/button/button';
import Dropdown from '../components/Elements/dropdown/dropdown';
import { initModal } from '../constant/InitialData';
import { integrationProviderOptions } from '../constant/optionData';
import { addToast} from '../store/features/toastSlice'
import { AddIntegrationWrapper } from './modal.styled.js';
import { OrganisationContext } from "../context/organisationContext";
import api from "../store/services";

const AddIntegration = () => {
  const { modal, setModal } = useContext(OrganisationContext);

  const dispatch = useDispatch();

  const [integrations, setIntegrations] = useState({});
  const [connectorOptions, setConnectorOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const isEdit = modal.content?.isEdit;

  useEffect(() => {
    const fetchConnectors = async () => {
      const { data } = await api.get('/v3/api/connectors');
      setConnectorOptions(data);
    };

    fetchConnectors();
  }, []);

  useEffect(() => {
    if (modal.content?.integration) {
      const connector = modal.content.integration.connector;
      const provider =
        integrationProviderOptions.find(p => (p.subProviders.find(sp => sp.value === connector) ? true : false)) || {};
      setIntegrations({ ...modal.content.integration, provider });
    }
  }, [modal.content?.integration]);

  const onDone = async () => {
    try {
      setLoading(true);
      const request = {
        name: integrations.name,
        connector: { id: integrations.connector.id },
        is_enabled: false,
        is_default: false,
      };
      const { data } = isEdit
        ? await api.put(`/v3/api/integrations/${integrations.id}`, request)
        : await api.post(`/v3/api/integrations`, request);

      dispatch(
        addToast({
          error: false,
          text: isEdit ? 'Integration updated successfully' : 'New integration added successfully',
          id: uuid(),
        }),
      );
      setLoading(false);

      setModal({ type: 'integration-success', content: { isEdit, integration: data } });
    } catch (e) {
      setLoading(false);

      dispatch(
        addToast({
          error: true,
          text: isEdit ? 'Error while updating integration' : 'Error while creating integration',
          id: uuid(),
        }),
      );
    }
  };

  const onSelectSubProvider = subProvider => {
    setIntegrations({
      ...integrations,
      connector: subProvider,
    });
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AddIntegrationWrapper>
        <p className="bold-text font-24 text-center mb-2">{isEdit ? 'Edit integration' : 'New integrations'}</p>
        <div className="w-full flex-column mb-6 mt-6">
          <label className="regular-text field-label mb-2">Name</label>
          <input
            className="popup-input"
            onChange={e => setIntegrations({ ...integrations, name: e.target.value })}
            placeholder="Enter integration name"
            value={integrations.name || ''}
          />
        </div>
        <div className="w-full mb-6 flex-column mb-6 mt-2">
          <label className="regular-text field-label mb-2">Providers</label>
          <Dropdown
            className="provider-selector"
            onSelect={option => setIntegrations({ ...integrations, provider: option, connector: '' })}
            options={connectorOptions}
            placeholder="Select relation"
            selected={connectorOptions?.find(relation => relation.id === integrations.provider?.id)}
            withIcon={false}
          />
        </div>
        {integrations.provider && (
          <div className="sub-provider">
            {integrations.provider?.connectors?.map(connector => (
              <div
                className={`sub-provider-item cursor ${
                  integrations.connector?.id === connector.id ? 'selected-sub-provider' : ''
                }`}
                onClick={() => onSelectSubProvider(connector)}>
                <img
                  alt="icon"
                  src={`${connector.icon.active}`}
                  style={{ width: 24, height: 24, marginRight: 8 }}
                />
                <span className="medium-text">{connector.name}</span>
              </div>
            ))}
          </div>
        )}
        <div className="flex justify-center mt-10 w-full">
          <Button
            bgColor="white"
            borderColor="#32E5C5"
            label={'Cancel'}
            onClick={() => setModal(initModal)}
            size="large"
            style={{ flex: 1 }}
            width="170px"
          />
          <Button
            bgColor="#40F1DE"
            borderColor="#40F1DE"
            color="white"
            disabled={!(integrations.name && integrations.connector?.id) || loading}
            label={`${isEdit ? 'Update' : 'Done'}`}
            onClick={onDone}
            size="large"
            style={{ flex: 1, marginLeft: 24 }}
            width="170px"
          />
        </div>
      </AddIntegrationWrapper>
    </CSSTransition>
  );
};

export default AddIntegration;
