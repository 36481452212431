import React, { useState, useEffect, useContext } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getMedia, createMedia, saveCollection } from "../../../store/features/collectionSlice";
import TemplateMediaList from "../../templates/TemplateMediaList";

import { OrganisationContext } from "../../../context/organisationContext";


import './style.scss';

const CollectionMedia = ({collection, menu}) => {
    const dispatch = useDispatch();
    const { mediaGroups, media } = useSelector(data => data.collection);
    const { checkPermission, hasPermission } = useContext(OrganisationContext);

    const [deletedMedia, setDeletedMedia] = useState();


    useEffect(() => {
        if(collection.id)
            dispatch(getMedia(collection.id));
    }, [collection, dispatch])

    const uploadMedia = async file => {
        if(file) {
            dispatch(createMedia({file: file, collectionId: collection.id}))
        }
    }


  const getMediaId = (media) => {
    return media
      ? {
          id:
            media.format === "IPFS"
              ? media?.public_id
              : media?.media_external_id,
        }
      : null;
  };

    const updateCollection = c => {
        dispatch(
          saveCollection({
            payload: {
              ...c,
              image: getMediaId(c.image),
              background_image: getMediaId(c.background_image),
              video: getMediaId(c.video),
              background_video: getMediaId(c.background_video),
            }
          })
        );
      };

    const changeMedia = (type, creative) => {
        if(type === 'image')
            updateCollection({...collection, image: creative })
        else if(type === 'backImage')
            updateCollection({...collection, background_image: creative })
        else if(type === 'video')
            updateCollection({...collection, video: creative })
        else if(type === 'backVideo')
            updateCollection({...collection, background_video: creative })
    }

    return (
       <div className="flex-column flex-1">
             <div className="flex items-center justify-between px-6 pt-5">
                <label className="bold-text font-20">Media</label>
                <div className="flex items-center">
                    {menu}
                </div>
            </div>
            <div className="flex-column collection-attributes">
                <TemplateMediaList 
                    primary={collection?.image?.media_external_id} 
                     media={media} 
                     setFile={uploadMedia} 
                     deleteMedia={setDeletedMedia} 
                     addPermission="COLLECTIONS_MANAGE" hasPermission={hasPermission} 
                     checkPermission={checkPermission} 
                     changeMedia={changeMedia}
                     frontImage={collection.image?.media_external_id}
                     backImage={collection.background_image?.media_external_id}
                     frontVideo={collection.video?.media_external_id}
                     backVideo={collection.background_video?.media_external_id}
                />
            </div>
        </div>
    )
}

export default CollectionMedia;
