import React, { useState, useEffect } from 'react'
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getConsignements } from '../../store/features/inventorySlice';
import { useNavigate } from 'react-router-dom';

import EditDeleteMenu from '../Elements/EditDeleteMenu';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/menu/arrow-down-black.svg';
import Loading from "../../assets/images/loading.svg";

import './style.scss'

const InventoryCard = ({ inventory, editCarbon }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { consignments, consignmendtLoading } = useSelector(data => data.inventory);

    const [show, setShow] = useState(false)

    useEffect(() => {
        if(show && inventory.consignments.consigned_count && inventory.consignments.consigned_count > 0)
            dispatch(getConsignements({id: inventory.id, page: 0}));
    }, [inventory.id, show]);

    const changeShow = e => {
        e.stopPropagation(); 
        if(inventory.consignments.consigned_count && inventory.consignments.consigned_count > 0)
            setShow(!show)
    }

    return (
        inventory ? 
        <div  onClick={() => navigate(`/carbon/inventories/${inventory.id}/details`)}>
            <div className={"ci-grid ci-card cursor border-top cursor"}>
                <label className='medium-text px-6 py-3'>{inventory?.name}</label>
                <label className='medium-text px-6 py-3' onClick={e => { e.stopPropagation(); navigate(`/projects/${inventory.project.id}`)}}>{inventory.project?.name}</label>
                <label className='medium-text px-6 py-3'>{inventory.project?.country}</label>
                <label className='medium-text px-6 py-3'>{inventory.registry?.name}</label>
                <label className='medium-text px-6 py-3'>{inventory?.primary_account?.name}</label>
                <label className='medium-text px-6 py-3'>{moment.unix(inventory.vintage.vintage_from).format('DD/MM/YYYY')} - {moment.unix(inventory.vintage.vintage_to).format('DD/MM/YYYY')}</label>
                <label className='medium-text px-6 py-3'>{inventory.owner?.name}</label>
                {/* <label className='medium-text px-6 py-3'>{inventory.carbon.open_balance / 1000}</label> */}
                {/* <label className='medium-text px-6 py-3'>{(inventory.carbon.open_balance / 1000).toLocaleString('en-US')}</label> */}
                <label className='medium-text px-6 py-3'>{(inventory.consignments?.current_co2_balance / 1000).toLocaleString('en-US')}</label>
                <label className='medium-text px-6 py-3'>{(inventory.carbon.current_balance / 1000).toLocaleString('en-US')}</label>
                {!inventory.consigned_to?.id && 
                <div className='flex-column items-center justify-center mr-4'>
                    <EditDeleteMenu onEdit={() => editCarbon(inventory)} onDelete={() => {} } />
                    {/* {inventory.consignments.consigned_count > 0 &&
                     <ArrowDownIcon className={`arrow-down mt-1 ${show && 'arrow-up'}`}  onClick={changeShow} /> } */}
                </div>
                }
        </div>
        {/* {consignments.content && consignments.content.length > 0 && 
                <div className='ci-consigneds' style={{background: '#FBFBFB', height: show ? consignments.content.length * 43  : 0}}>
                     {consignmendtLoading ?
                    <div className="flex flex-1 items-center justify-center mt-4">
                        <img src={Loading} alt="loading" />
                        <label className="bold-text font-16 ml-2 light-purple-text">
                            Loading
                        </label>
                    </div>
                    :
                    <>
                    {consignments.content.map(ci => 
                        <div key={ci.id} className='flex border-bottom py-1'>
                            <div className='flex items-center border-right pl-6' style={{height: '34px', width: '240px'}}>
                                <label className='regular-text'>{ci.consigned_to?.name}</label>
                            </div>
                            <div className="flex-column flex-1 border-right justify-center" style={{height: '34px'}}>
                                <label className="regular-text light-purple-text ml-6">Consigned: <span className="medium-text">{ci.consigned_to?.date && moment.unix(ci.consigned_to?.date).format("DD/MM/YYYY")}</span></label>
                            </div>
                            <div className="flex-column flex-1 border-right justify-center" style={{height: '34px'}}>
                                <label className="regular-text light-purple-text ml-6">Purchase price: <span className="medium-text">{ci.costs.purchase_price || '-'} {ci.costs.currency}</span></label>
                            </div>
                            <div className="flex-column flex-0-6 border-right  justify-center" style={{height: '34px'}}>
                                <label className="regular-text light-purple-text ml-6">Tonnes: <span className="medium-text">{ci.carbon.open_balance / 1000}</span></label>
                            </div>
                            <div className="flex-column flex-1 justify-center" style={{height: '34px'}}>
                                <label className="regular-text light-purple-text ml-6">Current balance: <span className="medium-text">{ci.carbon.current_balance / 1000}</span></label>
                            </div>
                        </div>
                        )}
                        </>
                    }
                </div>
                } */}
        </div>
         : <></>
    )
}

export default InventoryCard;
