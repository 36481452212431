import React from "react";
import Image from "../../image";

import CopyIcon from '../../../assets/images/copy-green.svg'
import OpenIcon from '../../../assets/images/open-green.svg'

import './style.scss'

const CampaignNftRetirementDetails = ({retirement}) => {
    return(
        <div className="flex-column">
            <div className="flex-column border-bottom mb-1 px-6 py-4">
                <label className="regular-text font-12 light-purple-text">Carbon</label>
                <div className="flex items-center justify-between mt-2 one-line hidden-action-container">
                    <label className="regular-text font-16 one-line">{retirement.carbon_inventory?.serial_number}</label>
                    <img src={CopyIcon} alt="copy" className="hidden-action cursor" onClick={() => {navigator.clipboard.writeText(retirement.inventory?.serial_number)}} />
                </div>
            </div>
            <div className="flex items-center">
                <div className="flex-column flex-1 px-6 py-3 border-right">
                    <label className="regular-text font-12 light-purple-text">Amount</label>
                    <label className="regular-text font-16 mt-2">{retirement.units ? retirement.units / 1000 : 0}</label>
                </div>
                <div className="flex-column flex-1 px-6 py-3 border-right">
                    <label className="regular-text font-12 light-purple-text">Units</label>
                    <label className="regular-text font-16 mt-2">{retirement.unit_type}</label>
                </div>  
                <div className="flex-column flex-1 px-6 py-3">
                    <label className="regular-text font-12 light-purple-text">Registry</label>
                    <label className="regular-text font-16 mt-2">{retirement.registry?.name}</label>
                </div>
            </div>
            <div className="flex items-center border-top mt-1 px-6 py-3">
                <Image className={"retirement-project-image"} size={48} media={retirement?.project?.image?.media} />
                <div className="flex-column ml-2">
                    <label className="regular-text font-16">{retirement.project?.name}</label>
                    <label className="regular-text font-12 light-purple-text one-line retirement-project-description mt-1">{retirement.project?.description}</label>
                </div>
            </div>
            <div className="flex-column border-top mb-1 px-6 py-4">
                <label className="regular-text font-12 light-purple-text">Serial number</label>
                <div className="flex items-center justify-between mt-2 hidden-action-container one-line">
                    <label className="regular-text font-16 one-line">{retirement?.serial_number}</label>
                    <img src={CopyIcon} alt="copy" className="hidden-action cursor" onClick={() => {navigator.clipboard.writeText(retirement?.serial_number)}} />
                </div>
            </div>
            <div className="flex-column border-top border-bottom mb-1 px-6 py-4">
                <label className="regular-text font-12 light-purple-text">Offset url</label>
                <div className="flex items-center mt-2 one-line hidden-action-container">
                    <label className="regular-text font-16 one-line mr-2">{retirement?.offset_url}</label>
                    <img src={OpenIcon} alt="copy" className="hidden-action cursor mr-4" onClick={() => { window.open(retirement.offset_url, "_blank")}} />
                    <img src={CopyIcon} alt="copy" className="hidden-action cursor" onClick={() => {navigator.clipboard.writeText(retirement?.offset_url)}} />
                </div>
            </div>
            <div className="flex items-center">
                <div className="flex-column flex-1 px-6 py-3  border-right">
                    <label className="regular-text font-12 light-purple-text">Retirement reason</label>
                    <label className="regular-text font-16 mt-2">{retirement.reason?.name || '-'}</label>
                </div>
                <div className="flex-column flex-1 px-6 py-3  border-right">
                    <label className="regular-text font-12 light-purple-text">Account</label>
                    <label className="regular-text font-16 mt-2">{retirement.account?.name || '-'}</label>
                </div>  
                <div className="flex-column flex-1 px-6 py-3 ">
                    <label className="regular-text font-12 light-purple-text">Beneficiary</label>
                    <label className="regular-text font-16 mt-2">{retirement.beneficiary_account?.name || '-'}</label>
                </div>
            </div>
            <div className="border-top mt-1" />
        </div>
    )
}

export default CampaignNftRetirementDetails