import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getCampaignMintingProgress } from "../../../store/features/campaignSlice";
import Loading from "../../../assets/images/loading.svg";
import Header from "../../header";
import { useNavigate } from "react-router-dom";

import "./style.scss";
import CampaignMintingProgressItem from "./CampaignMintingProgressItem";
import InfiniteScroll from "react-infinite-scroll-component";

const CampaignMintingProgress = ({ campaignId, isNewCampaign, menuItems, onMenuItemClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { campaignMintingProgress, loading } = useSelector(
    (data) => data.campaign
  );

  useEffect(() => {
    if (campaignId)
      dispatch(
        getCampaignMintingProgress({
          campaignId: campaignId,
          filter: `campaign=${campaignId}&lifecycle=QUEUED,MINTED`,
          page: 0,
          merge: false,
        })
      );
  }, [campaignId]);

  const fetchMoreData = () => {
    dispatch(
        getCampaignMintingProgress({
        campaignId: campaignId,
        filter: `campaign=${campaignId}&lifecycle=QUEUED,MINTED`,
        page: campaignMintingProgress?.number + 1,
        merge: true,
      })
    );
  };

  return (
    <div className="flex-column campaign-nfts">
      <div className="border-bottom"> 
      <Header title='Minting progress'
          isSearch={false}
          isPaged={false}
          size='small'
          menuItems={menuItems}
          onMenuItemClick={onMenuItemClick}
          isAddBtn={false}
          showBackBtn={true}
          onBackClick={() => navigate(-1)}
          tabMenu={true}
      />
      </div>
      {loading ? (
        <div className="flex flex-1 items-center justify-center mt-4">
          <img src={Loading} />
          <label className="bold-text font-16 ml-2 light-purple-text">
            Loading
          </label>
        </div>
      ) : campaignMintingProgress?.content?.length > 0 && !isNewCampaign ? (
        <div className="scroll-wrapper" id="scrollableDiv">
          <InfiniteScroll
            dataLength={campaignMintingProgress?.content?.length}
            hasMore={!campaignMintingProgress?.last}
            loader={
              <div className="item flex items-center justify-center">
                <img alt="loading" height="24px" src={Loading} />
              </div>
            }
            next={fetchMoreData}
            scrollableTarget="scrollableDiv"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "unset",
            }}
          >
            {campaignMintingProgress?.content?.map((mintProgressItem) => (
              <CampaignMintingProgressItem
                campaignNft={mintProgressItem}
                campaignId={campaignId}
                key={mintProgressItem.id}
              />
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        <div className="flex items-center justify-center flex-1">
          <label className="bold-text font-16 light-purple-text">
            There are no NFTs
          </label>
        </div>
      )}
    </div>
  );
};

export default CampaignMintingProgress;
