import { setTeams } from '../store/features/settingsSlice';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import Button from '../components/Elements/button/button';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import api from "../store/services";
import { addToast } from '../store/features/toastSlice';
import { AddTeamWrapper } from './modal.styled.js';
import { CSSTransition } from 'react-transition-group';
import Selected from '../assets/images/selected.svg';
import AsyncSelect from 'react-select/async';

const MultiValueOption = props => {
  const onSelect = () => {
    const currentSelected = props.getValue();
    const newSelected = props.isSelected
      ? currentSelected.filter(opt => opt.value !== props.value)
      : [...currentSelected, { ...props.data }];
    props.setValue(newSelected);
  };
  return (
    <div className={`tpus-user ${props.isSelected && 'tpus-user-selected'}`} onClick={() => onSelect()}>
      <label className={`regular-text ${props.isSelected && 'bold-text'}`}>{props.label}</label>
      {props.isSelected && <img alt="icon" src={Selected} />}
    </div>
  );
};

const AddTeam = () => {
  const textareaRef = useRef(null);
  const { user } = useSelector(state => state.user);

  const organisationId = user?.organisations[0].external_id;

  const { modal, setModal } = useContext(OrganisationContext);
  const { teams } = useSelector(state => state.settings);

  const dispatch = useDispatch();

  const [team, setTeam] = useState({});
  const [services, setServices] = useState([]);
  const [inputState, setInputState] = useState('');
  const [selectedValue, setSelectedValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const isEdit = modal.content?.isEdit;
  const [error, setError] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);

  useEffect(() => {
    textareaRef.current.style.height = '0px';
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + 5 + 'px';
  }, [team.description]);

  useEffect(() => {
    if (modal.content?.team) {
      const editTeam = modal.content?.team;
      setTeam({ ...editTeam });
      setSelectedValue(editTeam.services?.map(s => ({ ...s, label: s.name, value: s.id })) || []);
    }
  }, [modal.content?.team]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get(`/v1/organizations/${organisationId}/services`);
      setServices(data.map(d => ({ ...d, label: d.name, value: d.id })));
    };
    fetchData();
  }, []);

  const getOptions = async inputValue => {
    return services.filter(o => o.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const loadOptions = async inputValue => {
    const options = await getOptions(inputValue);
    return options;
  };

  const handleInputChange = newValue => {
    setInputState(newValue);
    return newValue;
  };

  const handleChange = value => {
    setSelectedValue(value);
  };

  const onDone = async () => {
    try {
      if (!team?.name?.trim()) {
        setError(true);
        dispatch(addToast({ error: true, text: 'Please enter team name' }));
        return;
      }
      const request = {
        name: team.name,
        description: team.description,
      };
      setLoading(true);
      const serviceRequest = selectedValue.map(s => ({ service: { id: s.value }, daily_capacity: 0, slots: 0 }));
      const { data } = isEdit
        ? await api.put(`/v1/organizations/${organisationId}/teams/${team.id}`, request)
        : await api.post(`/v1/organizations/${organisationId}/teams`, request);

      // const { data: serviceData } = await api.put(
      //   `/v1/organizations/${isEdit ? team.id : data.id}/services`,
      //   serviceRequest,
      // );
      // const updatedTeamServices = serviceData.map(s => ({ ...s.service }));

      // const newTeamsData = teams
      //   ? isEdit
      //     ? teams.map(t => (t.id === data[0].id ? { ...data[0], services: [...updatedTeamServices] } : { ...t }))
      //     : teams
      //   : [{ ...data, services: [...updatedTeamServices] }];
      // dispatch(setTeams(newTeamsData));

      dispatch(
        addToast({
          error: false,
          text: isEdit ? 'Team updated successfully' : 'New team added successfully',
          id: uuid(),
        }),
      );
      setLoading(false);
      setModal(isEdit ? initModal : { type: 'add-team-success', content: {} });
    } catch (e) {
      dispatch(
        addToast({
          error: true,
          text: isEdit ? 'Error while updating team' : 'Error while creating team',
          id: uuid(),
        }),
      );
      setLoading(false);
      setModal(initModal);
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AddTeamWrapper>
        <p className="bold-text font-24 text-center mb-2">{isEdit ? 'Edit Team' : 'Create Team'}</p>
        <div className="w-full flex-column mb-6">
          <label className="regular-text field-label mb-2">Name</label>
          <input
            className={`popup-input form-input ${error && 'error-input'}`}
            onChange={e => {
              setTeam({ ...team, name: e.target.value });
              setError(false);
            }}
            placeholder="Enter team name"
            value={team.name || ''}
          />
        </div>
        <div className="w-full note-input flex-column mb-6">
          <label className="regular-text field-label mb-2">Description</label>
          <textarea
            ref={textareaRef}
            className="medium-text grey-text popup-input form-input"
            onChange={e => setTeam({ ...team, description: e.target.value })}
            placeholder="Enter team description"
            value={team.description || ''}
          />
        </div>
        <div className="w-full mb-6 flex-column mb-6">
          <label className="regular-text field-label mb-2">Service</label>
          <AsyncSelect
            className={`options-select-container ${dropdownOpened ? 'opened' : ''}`}
            classNamePrefix="select"
            closeMenuOnSelect={false}
            components={{ Option: MultiValueOption }}
            defaultOptions={services}
            inputValue={inputState}
            isMulti
            loadOptions={loadOptions}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onMenuClose={() => setDropdownOpened(false)}
            onMenuOpen={() => setDropdownOpened(true)}
            options={services}
            placeholder="Select services"
            value={selectedValue}
          />
        </div>

        <div className="flex justify-center mt-8">
          <Button
            className={`${!loading && 'secondary-hover'}`}
            disabled={loading}
            label={'Cancel'}
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            style={{ flex: 1 }}
            width="170px"
          />
          <Button
            className={`${!loading && 'primary-hover'}`}
            disabled={loading}
            label={`${isEdit ? 'Update' : 'Create'}`}
            onClick={onDone}
            primary={true}
            size="large"
            style={{ flex: 1, marginLeft: 24 }}
            width="170px"
          />
        </div>
      </AddTeamWrapper>
    </CSSTransition>
  );
};

export default AddTeam;
