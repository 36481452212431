import React from 'react';
import { uppercase } from '../../helpers/utils';

function selectedAutomationBox({ data, mainTitle, setShowTrigger, setSelected, type, setShowAction }) {
  return (
    <div>
      <div className="leads-container mb-10">
        <div className="mb-4">
          <label className="medium-text grey-text font-12">{uppercase(mainTitle)}</label>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          {data.map((value, index) => {
            return (
              <div
                key={index}
                className="leads-boxes"
                onClick={() => {
                  if (type === 'action') {
                    setSelected(value);
                    setShowAction(true);
                  } else {
                    setSelected(value);
                    setShowTrigger(false);
                  }
                }}
                style={{
                  justifyContent: 'space-between',
                  margin: '0 24px 16px 0',
                  padding: '12px 16px',
                  cursor: 'pointer',
                }}>
                <div className="flex">
                  <div className="leads-icon">
                    {value?.icon && (
                      <div
                        className="pxy-1 flex items-center justify-center"
                        style={{ margin: '0 8px 0 0', background: 'white', borderRadius: '4px' }}>
                        <img
                          // alt="icon"
                          src={`${value?.icon?.active}`}
                          style={{ width: 24, height: 24 }}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="medium-text">{value?.name}</div>
                    <div className="regular-text grey-text mt-1 font-12">{value?.description}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default selectedAutomationBox;
