import React from "react";

import { HashRouter, Route, Routes, Navigate } from "react-router-dom";

import { PrivateRoute } from "./privateRoute";
import Login from "../pages/login";
import Main from '../pages/main'
import ForgotPassword from "../pages/forgot-password";
import history from './history';

import Photos from "../pages/photos/photos";
import Files from "../pages/files";
import Toast from "../popup/toast/toast";
import Payment from "../pages/payment/payment";
import Success from "../pages/payment/success";
import Activate from "../pages/activate/index.js";
import Upload from "../pages/upload/index.js.js";

export const Router = () => {
  return (
    <HashRouter history={history}>
      <Routes>
        <Route element={<Login />} path="/login" />
        <Route element={<Activate />} path="/activate" />
        <Route element={<Upload />} path="/upload" />

        <Route element={<ForgotPassword />} path="/user/password/forgot"/>
        <Route element={<Photos />} path="/photos"/>
        <Route element={<Files />} path="/files"/>
        <Route element={<Payment />} path="/payment"/>
        <Route element={<Success />} path="/success"/>
        <Route element={<PrivateRoute />}>
          <Route element={<Main />} path="/*" />
        </Route>
        <Route render={() => <Navigate to="/dashboard" />} />
      </Routes>
      <Toast />
    </HashRouter>
  );
};
