import React, { useEffect, useState } from 'react';
import Button from '../../components/Elements/button/button';
import Dropdown from '../../components/Elements/dropdown/dropdown';
import TimeIcon from '../../assets/images/time.svg';
import { useDispatch } from 'react-redux';
import api from '../../store/services'
import { addToast} from '../../store/features/toastSlice'
import { formatText } from '../../helpers/utils';
import Loading from '../../assets/images/loading.gif';
import { ReactComponent as EditIcon } from '../../assets/images/edit-image.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/trash.svg';

function ScheduleComponent({
  automationObj,
  actionResponse,
  setShowScheduleComponent,
  showScheduleComponent,
  actionObj,
  triggerData,
  integration,
  setActionResponse,
  setLoading,
}) {
  const dispatch = useDispatch();
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const hourOptions = [
    { id: 'MINUTE', name: 'Minutes' },
    { id: 'HOUR', name: 'Hours' },
    { id: 'DAY', name: 'Days' },
    { id: 'WEEK', name: 'Weeks' },
  ];
  const [offsetTypeOptions, setOffsetTypeOptions] = useState(['before', 'after']);
  const dateOptions = triggerData?.event_type?.includes('APPOINTMENT')
    ? [
        { id: 'appointment.start_date', name: 'Appointment date' },
        { id: 'event.created_on', name: 'Trigger date' },
      ]
    : [{ id: 'event.created_on', name: 'Trigger date' }];

  const [hour, setHour] = useState(
    actionResponse?.schedule?.unit ? hourOptions.find(h => h.id === actionResponse?.schedule?.unit) || {} : {},
  );

  const [offset, setOffset] = useState(
    actionResponse?.schedule?.offset !== null ? actionResponse?.schedule?.offset.toString().replace('-', '') : '',
  );
  const [offsetType, setOffsetType] = useState(
    actionResponse?.schedule?.offset < 0 ? 'before' : actionResponse?.schedule?.offset > 0 ? 'after' : '',
  );
  const [showDataInLabel, setShowDataInLabel] = useState(
    actionResponse?.schedule?.field || actionResponse?.schedule?.offset !== null || actionResponse?.schedule?.unit
      ? true
      : false,
  );
  const [date, setDate] = useState(
    actionResponse?.schedule?.field ? dateOptions.find(o => o.id === actionResponse?.schedule?.field) || {} : {},
  );

  const [errors, setError] = useState({});
  const [showIntegrationAction, setShowIntegrationAction] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (actionResponse?.id) {
      const showLabel =
        actionResponse?.schedule &&
        (actionResponse?.schedule?.field ||
          actionResponse?.schedule?.offset !== null ||
          actionResponse?.schedule?.unit);
      setShowDataInLabel(showLabel);
      setHour(
        actionResponse?.schedule?.unit ? hourOptions.find(h => h.id === actionResponse?.schedule?.unit) || {} : {},
      );
      setOffsetType(
        actionResponse?.schedule?.offset < 0 ? 'before' : actionResponse?.schedule?.offset > 0 ? 'after' : '',
      );
      setOffset(
        actionResponse?.schedule?.offset !== null ? actionResponse?.schedule?.offset.toString().replace('-', '') : '',
      );
      setDate(
        actionResponse?.schedule?.field ? dateOptions.find(o => o.id === actionResponse?.schedule?.field) || {} : {},
      );
      setError({});
      setIsEdit(false);
    }
  }, [actionResponse]);

  const checkErrors = () => {
    const error = !date?.id || !hour?.id || !offset || !offsetType;
    setError({ date: !date?.id, hour: !hour?.id, offset: !offset, offsetType: !offsetType });
    return error;
  };

  const handleScheduleAPI = async (isDeleteSchedule = false) => {
    if (!isDeleteSchedule && checkErrors()) {
      dispatch(addToast({ error: true, text: 'Please add all required fields', id: 'error-fields' }));
      return;
    }
    let request = {
      ...actionResponse,
      integration: {
        id: actionResponse.integration.id,
      },
      schedule: {
        offset: offsetType === 'before' ? '-' + offset : offset,
        unit: hour.id,
        field: date.id,
      },
    };
    if (isDeleteSchedule) {
      delete request.schedule;
    }

    try {
      setScheduleLoading(true);
      const { data } = await api.put(`/v3/api/automations/${automationObj.id}/actions/${actionResponse.id}`, request);
      setActionResponse(data);
      setShowDataInLabel(isDeleteSchedule ? false : true);
      setScheduleLoading(false);
    } catch (error) {
      setScheduleLoading(false);
    }
  };

  const scheduleDisplay = () => {
    return `${offset} ${
      offset === '1' ? hour.name.substring(0, hour.name.length - 1) : hour.name
    } ${offsetType} ${formatText(date.name)}`;
  };

  const onEditSelectedAction = action => {
    setIsEdit(true);
    setShowDataInLabel(false);
  };

  const onDeleteSelectedAction = () => {
    handleScheduleAPI(true);
  };

  const onCancel = () => {
    if (isEdit) {
      if (actionResponse?.id) {
        const showLabel =
          actionResponse?.schedule &&
          (actionResponse?.schedule?.field ||
            actionResponse?.schedule?.offset !== null ||
            actionResponse?.schedule?.unit);
        setShowDataInLabel(showLabel);
        setHour(
          actionResponse?.schedule?.unit ? hourOptions.find(h => h.id === actionResponse?.schedule?.unit) || {} : {},
        );
        setOffsetType(
          actionResponse?.schedule?.offset < 0 ? 'before' : actionResponse?.schedule?.offset > 0 ? 'after' : '',
        );
        setOffset(
          actionResponse?.schedule?.offset !== null ? actionResponse?.schedule?.offset.toString().replace('-', '') : '',
        );
        setDate(
          actionResponse?.schedule?.field ? dateOptions.find(o => o.id === actionResponse?.schedule?.field) || {} : {},
        );
        setError({});
        setIsEdit(false);
      }
    } else {
      setShowScheduleComponent(false);
    }
  };

  return (
    <>
      {!showDataInLabel && showScheduleComponent && (
        <div className="schedule" style={{ height: 'auto' }}>
          <div className="schedule-label">
            <label className="medium-text font-16">Schedule</label>
          </div>
          {scheduleLoading ? (
            <div className="flex items-center justify-center mb-4">
              <img alt="loading" height="40px" src={Loading} />
            </div>
          ) : (
            <>
              <div className="flex mt-2">
                <input
                  className={`popup-input form-input ${errors.offset && 'error-info'}`}
                  min={1}
                  onChange={e => {
                    setOffset(e.target.value);
                  }}
                  placeholder="offset"
                  type="number"
                  value={offset}
                />
                <Dropdown
                  className={`schedule-hour dropdown-hover ${errors.hour ? 'error-info' : ''}`}
                  dropdownHeight={'120px'}
                  onSelect={option => setHour(option)}
                  options={hourOptions}
                  placeholder="hours"
                  scrollEnabled
                  selected={hour}
                  withIcon={false}
                />
                <Dropdown
                  className={`schedule-hour dropdown-hover ${errors.offsetType ? 'error-info' : ''}`}
                  onSelect={option => {
                    setOffsetType(option);
                  }}
                  options={offsetTypeOptions}
                  placeholder={date.id === 'event.created_on' ? 'after' : 'before'}
                  selected={{ name: offsetType ? offsetType : null }}
                  withIcon={false}
                />
                <Dropdown
                  className={`schedule-hour dropdown-hover last-dropdown ${errors.date ? 'error-info' : ''}`}
                  onSelect={option => {
                    if (option.id === 'event.created_on') {
                      setOffsetTypeOptions(['after']);
                      if (offsetType === 'before') {
                        setOffsetType('');
                      }
                    } else {
                      setOffsetTypeOptions(['before', 'after']);
                    }
                    setDate(option);
                  }}
                  options={dateOptions}
                  placeholder="date"
                  selected={date}
                  withIcon={false}
                />
              </div>
              <div className="button-container">
                <Button
                  className={`cancel-save-button secondary-hover`}
                  label="Cancel"
                  onClick={() => onCancel()}
                  secondary={true}
                  size="large"
                />
                <Button
                  className={`cancel-save-button primary-hover`}
                  label="Save"
                  onClick={() => {
                    handleScheduleAPI();
                  }}
                  primary={true}
                  size="large"
                  style={{ marginLeft: 12 }}
                />
              </div>
            </>
          )}
        </div>
      )}
      {showDataInLabel && (
        <>
          <div
            className="flex"
            onMouseLeave={() => setShowIntegrationAction(false)}
            onMouseMove={() => setShowIntegrationAction(true)}
            style={{ width: '100%', margin: '5px 0px 20px 0px' }}>
            <div className="flex items-center flex-1">
              <div className="flex items-center mr-2">
                <img alt="icon" src={TimeIcon} style={{ height: 19 }} />
              </div>

              <span className="regular-text">{scheduleDisplay()}</span>
            </div>
            {showIntegrationAction && (
              <div className="flex mx-1">
                <div className="mx-1 cursor" onClick={() => onEditSelectedAction()}>
                  <EditIcon />
                </div>
                <div className="mx-1 cursor" onClick={() => onDeleteSelectedAction()}>
                  <DeleteIcon />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ScheduleComponent;
