import React, { useEffect, useState } from 'react';
import InviteIcon from '../../assets/images/invite-icon.svg';
import api from '../../store/services'
import Button from '../../components/Elements/button/button';
import { useDispatch } from 'react-redux';
import { addToast} from '../../store/features/toastSlice'
import Loading from '../../assets/images/loading.svg';
import { ReactComponent as EditIcon } from '../../assets/images/edit-image.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/trash.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import Select from 'react-select';

const SelectDropdownOption = props => {
  const onSelect = () => {
    props.setValue({ ...props.data });
  };
  return (
    <div className={`tpus-user ${props.isSelected && 'tpus-user-selected'}`} onClick={() => onSelect()}>
      <label className={`${props.isSelected ? 'bold-text' : 'regular-text'}`}>{props.label}</label>
    </div>
  );
};

function ConditionComponent({
  automationObj,
  actionResponse,
  setShowConditionComponent,
  setActionResponse,
  triggerData,
  integration,
  setLoading,
}) {
  const projectOption =
    triggerData.lexicons.map(lax => {
      return { ...lax, label: lax.name, value: lax.id };
    }) || [];
  const [conditions, setConditions] = useState([{}]);
  const [optionData, setOptionData] = useState({});
  const [showDataInLabel, setShowDataInLabel] = useState(false);
  const [conditionLoading, setConditionLoading] = useState(false);
  const [showConditionActions, setShowConditionAction] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (actionResponse.id) {
      setShowDataInLabel(actionResponse?.conditions?.length > 0 ? true : false);
      setConditions(actionResponse?.conditions?.length > 0 ? actionResponse.conditions : conditions);
    }
  }, [actionResponse]);

  const handleAddClick = () => {
    setConditions([...conditions, { projectFilter: '', name: '' }]);
  };

  const fetchProjectData = async lexicon => {
    const { data } = await api.get('/v3/api/projects');
    return data;
  };

  const fetchProjectLifecycleData = async lexicon => {
    const data = [
      { id: 'COMPLETE', name: 'Complete' },
      { id: 'ACTIVE', name: 'Active' },
      { id: 'CANCELLED', name: 'Cancelled' },
    ];
    return data;
  };

  const fetchLeadLifecycleData = async lexicon => {
    const { data } = await api.get('/v3/api/lifecycles');
    return data;
  };

  const fetchAppointmentStatusData = async lexicon => {
    const data = [
      { id: 'PENDING', name: 'Pending' },
      { id: 'SUCCESS', name: 'Success' },
      { id: 'FAILED', name: 'Failed' },
      { id: 'CANCELLED', name: 'Cancelled' },
    ];
    return data;
  };

  const fetchSurveyData = async lexicon => {
    const { data } = await api.get('/v3/api/surveys');
    const filteredData = data.filter((d, idx, self) => idx === self.findIndex(t => t.id === d.id));
    return filteredData;
  };

  const fetchImprovementData = async lexicon => {
    const { data } = await api.get('/v3/api/improvements');
    return data;
  };

  const fetchProcessData = async lexicon => {
    const { data } = await api.get('/v3/api/processes');
    return data;
  };

  const fetchStagesData = async lexicon => {
    const { data } = await api.get('/v3/api/stages');
    return data;
  };

  const fetchServicesData = async lexicon => {
    // const { data } = await api.get(`/v1/organizaions/${organisationId}/services`);
    // return data;
  };

  const fetchWorkflowCancelledData = async lexicon => {
    const data = [
      { id: 'true', name: 'True' },
      { id: 'false', name: 'False' },
    ];
    return data;
  };

  const fetchWorkflowCancelReasonData = async lexicon => {
    const { data } = await api.get('/v3/api/cancel_reasons?lifecycle=WORKFLOW');
    return data;
  };

  const fetchAutomationEvidenceRules = async lexicon => {
    const { data } = await api.get('/v3/api/evidence_rules?source=AUTOMATION');
    return data;
  };

  const fetchAppointmentFollowupData = async lexicon => {
    const data = [
      { id: 'true', name: 'True' },
      { id: 'false', name: 'False' },
    ];
    return data;
  };

  const fetchExternalReferenceIntegrationData = async lexicon => {
    const { data } = await api.get('/v3/api/integrations?page=0&size=200');
    return data.content;
  };

  const getNewOption = async lexicon => {
    switch (lexicon) {
      case 'property_project.project.id':
        return await fetchProjectData(lexicon);
      case 'property_project.lifecycle':
        return await fetchProjectLifecycleData(lexicon);
      case 'lead.last_lifecycle.lifecycle.id':
        return await fetchLeadLifecycleData(lexicon);
      case 'appointment.status':
        return await fetchAppointmentStatusData(lexicon);
      case 'appointment.service.id':
        return await fetchServicesData(lexicon);
      case 'appointment.surveys.id':
        return await fetchSurveyData(lexicon);
      case 'property_project.improvements.id':
        return await fetchImprovementData(lexicon);
      case 'workflow.stage.id':
        return await fetchStagesData(lexicon);
      case 'workflow.process.id':
        return await fetchProcessData(lexicon);
      case 'workflow.is_cancelled':
        return await fetchWorkflowCancelledData(lexicon);
      case 'workflow.cancel_reason.id':
        return await fetchWorkflowCancelReasonData(lexicon);
      case 'evidence_generation.evidence.evidence_rule.id':
        return await fetchAutomationEvidenceRules(lexicon);
      case 'appointment.followup':
        return await fetchAppointmentFollowupData(lexicon);
      case 'external_reference.integration_id':
        return await fetchExternalReferenceIntegrationData(lexicon);
      default:
        break;
    }
  };

  const onChangeFirstCondition = async (option, index) => {
    setConditions(conditions.map((c, idx) => (idx === index ? { ...c, field: option, value: null } : { ...c })));
    if (!optionData[option.lexicon]) {
      const data = await getNewOption(option.lexicon);
      setOptionData({ ...optionData, [option.lexicon]: data });
    }
  };

  const handleConditionAPI = async (isDeleteConditions = false) => {
    if (!isDeleteConditions) {
      let flag = true;
      conditions.map(data => {
        if (data?.field && data?.value) {
          flag = false;
        }
      });
      if (flag) {
        dispatch(
          addToast({
            error: true,
            text: 'Please add atleast one condition',
            id: 'condition-error',
          }),
        );
        return;
      }
    }
    if (!actionResponse.schedule?.field || actionResponse.schedule?.offset === null || !actionResponse.schedule?.unit) {
      delete actionResponse.schedule;
    }
    let request = {
      ...actionResponse,
      conditions: isDeleteConditions
        ? []
        : conditions
            .filter(c => c.field?.lexicon && c.value?.id)
            .map(c => ({
              value: c.value.id,
              field: c.field.lexicon,
              display_value: c.value.name,
            })),
    };

    try {
      setConditionLoading(true);
      const { data } = await api.put(`/v3/api/automations/${automationObj.id}/actions/${actionResponse.id}`, request);
      setActionResponse(data);
      setConditions(data.conditions);
      setShowDataInLabel(isDeleteConditions ? false : true);
      setConditionLoading(false);
    } catch (error) {
      console.log(error);
      setConditionLoading(false);
    }
  };

  const onRemoveCondition = index => {
    const newConditions = conditions.filter((c, idx) => idx !== index);
    setConditions(newConditions.length === 0 ? [{}] : conditions.filter((c, idx) => idx !== index));
  };

  const getOptionForConditonalSelect = data => {
    let options = data.field?.lexicon ? optionData[data.field?.lexicon] || [] : [];
    options = options.map(item => {
      return { ...item, label: item?.name ? item?.name : '', value: item?.id ? item?.id : '' };
    });
    return options;
  };

  const addConditionFunction = (data, index, labelValue, showAdd = false) => {
    return (
      <div className="mb-2 condition-render">
        <label className="regular-text font-16 flex items-center">{labelValue ? labelValue : 'When'}</label>
        <Select
          className={`conditional-options-select-container`}
          classNamePrefix={'select'}
          closeMenuOnSelect
          components={{ Option: SelectDropdownOption }}
          hideSelectedOptions={false}
          onChange={value => {
            onChangeFirstCondition(value, index);
          }}
          options={projectOption}
          value={data.field}
        />
        <span className="flex items-center">=</span>
        <Select
          className={`conditional-options-select-container`}
          classNamePrefix={'select'}
          closeMenuOnSelect
          components={{ Option: SelectDropdownOption }}
          onChange={value =>
            setConditions(conditions.map((c, idx) => (idx === index ? { ...c, value: value } : { ...c })))
          }
          options={getOptionForConditonalSelect(data)}
          value={{
            ...data.value,
            label: data?.value?.name ? data?.value?.name : '',
            value: data?.value?.id ? data.value.id : '',
          }}
        />
        {showAdd ? (
          <div className="flex">
            {data.field && (
              <div className="flex items-center mr-2">
                <CloseIcon
                  className="close-conditions cursor"
                  height={24}
                  onClick={() => onRemoveCondition(index)}
                  width={24}
                />
              </div>
            )}
            <div
              className="add-condition flex-1"
              onClick={() => {
                if (data.value) {
                  handleAddClick();
                } else {
                  dispatch(
                    addToast({
                      error: true,
                      text: 'Please add current conditions',
                      id: 'condition-add-changes',
                    }),
                  );
                }
              }}>
              <img alt="icon" height="16" src={InviteIcon} />

              <label className="medium-text">Add Condition</label>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <CloseIcon
              className="close-conditions cursor"
              height={24}
              onClick={() => onRemoveCondition(index)}
              width={24}
            />
          </div>
        )}
      </div>
    );
  };
  const onEditConditionAction = async action => {
    const newConditions = conditions.map(c => ({
      ...c,
      field: projectOption.find(po => po.lexicon === c.field) || {},
      value: { id: c.value || c.display_value, name: c.display_value || c.value },
    }));
    setConditions(newConditions);
    setIsEdit(true);
    setShowDataInLabel(false);
    const uniqueLexicons = newConditions.reduce((acc, curr) => {
      if (!acc[curr.field.lexicon] && !optionData[curr.field.lexicon]) {
        acc.push(curr.field.lexicon);
      }
      return acc;
    }, []);
    const promiseResult = await Promise.all(
      uniqueLexicons.map(async lexicon => {
        const data = await getNewOption(lexicon);
        return { id: lexicon, data };
      }),
    );
    let filteredOptionData = {};
    promiseResult.forEach(pr => {
      filteredOptionData[pr.id] = pr.data;
    });
    setOptionData({ ...optionData, ...filteredOptionData });
  };

  const onDeleteConditionAction = () => {
    handleConditionAPI(true);
  };

  const onCancel = () => {
    if (isEdit) {
      if (actionResponse?.id) {
        setShowDataInLabel(actionResponse?.conditions?.length > 0 ? true : false);
        setConditions(actionResponse?.conditions?.length > 0 ? actionResponse.conditions : conditions);
        setIsEdit(false);
      }
    } else {
      setShowConditionComponent(false);
    }
  };

  return (
    <div className="condition" style={{ width: '100%' }}>
      <hr />
      {showDataInLabel ? (
        <div
          className="flex"
          onMouseLeave={() => setShowConditionAction(false)}
          onMouseMove={() => setShowConditionAction(true)}>
          <div className="condition-container flex-1">
            {conditions.map((data, index) => (
              <div className="flex mt-2">
                <label className="regular-text">
                  {index == 0 ? 'When' : 'And'} {data?.field} =
                </label>
                <label className="medium-text ml-1">{data?.display_value || data?.value}</label>
              </div>
            ))}
          </div>
          {showConditionActions && (
            <div className="flex mx-1 mt-2">
              <div className="mx-1 cursor" onClick={() => onEditConditionAction()}>
                <EditIcon />
              </div>
              <div className="mx-1 cursor" onClick={() => onDeleteConditionAction()}>
                <DeleteIcon />
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="condition-container">
            <label className="medium-text font-16">Condition</label>
          </div>
          {conditionLoading ? (
            <div className="flex items-center justify-center">
              <img alt="loading" height="40px" src={Loading} />
            </div>
          ) : (
            <>
              <div className="mt-2">
                {conditions.map((data, idx) => {
                  return (
                    <>
                      <div className="flex" style={{ margin: '5px 0px' }}>
                        {addConditionFunction(data, idx, idx !== 0 && 'And', conditions.length - 1 === idx)}
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="button-container conditions-button">
                <Button
                  className={`cancel-save-button secondary-hover`}
                  label="Cancel"
                  onClick={() => onCancel()}
                  secondary={true}
                  size="large"
                />
                <Button
                  className={`cancel-save-button primary-hover`}
                  label="Save"
                  onClick={() => handleConditionAPI()}
                  primary={true}
                  size="large"
                  style={{ marginLeft: 12 }}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ConditionComponent;
