import React, { useEffect, useState, useCallback, useRef } from "react";

import moment from "moment";

import { getInventories } from "../../../store/features/inventorySlice";
import { getRetirementReasons } from "../../../store/features/nftSlice";
import { getRegistries } from "../../../store/features/registrySlice";
import { createRetirement } from "../../../store/features/nftSlice";
import { updateCampaignProject } from "../../../store/features/campaignSlice";
import { getAccounts } from "../../../store/features/accountSlice";

import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import Image from "../../image";
import CarbonDropDown from "../../CarbonDropdown";
import Switch from "../../common/switch";

import DropDown from "../../McDropdown";

import CopyIcon from '../../../assets/images/copy-green.svg'

import "./style.scss";

const AddRetirementPopup = ({onCancel, campaignProject, campaign, amount, transactions}) => {
    const dispatch = useDispatch();
    const statuses = [{id: 0, name: 'Pending'}, { id: 1, name: 'Retired'}];
    const pageRefBeneficiary = useRef(null);

    const { inventories } = useSelector(data => data.inventory);
    const { retirementReasons } = useSelector(data => data.nft);
    const { organisation } = useSelector(data => data.user) 
    const { user, organisations } = useSelector(data => data.user);
    const { accounts } = useSelector(data => data.account);

    const [id, setId] = useState(uuid());
    const [def, setDef] = useState(true)
    const contact = transactions && transactions.length === 1 ? transactions[0].contact : null
    const actualCo2Offset = transactions && transactions.length > 0 ? transactions.map(t => t.co2_kg_units).map(c => c/1000).reduce((a, c) => a + c, 0)  : null;
    const [retirement, setRetirement] = useState({co2_offset_amount: actualCo2Offset || amount/1000, is_pending: true, beneficiary: contact ? contact.business_name ? contact.business_name : contact?.forename + " " + contact.surname : campaign?.beneficiary?.name })

    const addRetirement = async () => {
        await dispatch(createRetirement({
            id,
            retirement_date: moment().unix(),
            serial_number: retirement.serial_number,
            carbon_inventory: {id: retirement.carbon_inventory?.id},
            project: {id: campaignProject.project.id},
            beneficiary_account: {id: campaign?.beneficiary?.id},
            beneficiary: retirement?.beneficiary,
            reason: retirement.reason ? {id: retirement.reason?.id} : null,
            registry: {id: retirement.carbon_inventory?.registry?.id},
            units: retirement.co2_offset_amount * 1000,
            offset_url: retirement.offset_url,
            unit_type: 'VCU',
            campaign: {id: campaign.id},
            is_pending: retirement.is_pending,
            transactions: transactions ? transactions.map(t => ({id: t.id})) : [],
            contact: contact ? {id: contact.id} : null,
            transparency_url: retirement.transparency_url,
            co2_inventory_balance: retirement.carbon_inventory?.carbon?.current_balance,
            co2_kilos: retirement.co2_offset_amount * 1000,
            actual_co2_offset: actualCo2Offset * 1000
        }))
        dispatch(updateCampaignProject({id: campaignProject.id, campaignProject: {...campaignProject, retired_count: campaignProject.retired_count + 1, retired_co2_kilo_amount: campaignProject.retired_co2_kilo_amount + (retirement.co2_offset_amount*1000)}}))
        onCancel(true)
    }

    useEffect(() => {
        dispatch(getInventories({page: 0, projectId: campaignProject.project.id}))
    }, [])

    useEffect(() => {
        dispatch(getRetirementReasons())
        dispatch(getRegistries())
    }, [])

    const fetchAccounts  = useCallback((page, merge, user)  => {
        pageRefBeneficiary.current = page;
        dispatch(getAccounts({organisationId: user.organisations[0].external_id, page: page, merge: merge, type: '', search: ''}));
    }, [dispatch]);

    useEffect(() => {
        fetchAccounts(0, false, user);
    }, [user, fetchAccounts]);

    const changeTransparencyUrl = () => {
        setDef(!def)
    }

    useEffect(() => {
        if(def)
            setRetirement({...retirement, transparency_url: `https://transparency.standfortrees.org/retirement/${id}`})
        else
        setRetirement({...retirement, transparency_url: null})
    }, [def])

    return (
        <div className='add-retirement-popup'>
            <div className='add-retirement-popup-content'>
                <label className='popup-title mt-8 mb-6 ml-4'>Add retirement for {campaignProject.project?.name}</label>
                <div className="flex flex-1 mx-6">
                    <div className="flex items-center flex-1 mr-6">
                        <div className="flex-column flex-1">
                            <label className="regular-text font-12 light-purple-text mb-2">Amount</label>
                            <div className="edit-retirement-input-container flex items-center">
                                <input className="edit-retirement-input-text" type='number' min="0" step="1" style={{width: "200px"}}  value={retirement.co2_offset_amount} onChange={e => e.target.validity.valid ? setRetirement({...retirement, co2_offset_amount: e.target.value}) : {}} />
                                <label className="regulat-text ml-2">tonnes</label>
                            </div>
                        </div>
                        <div className="flex-column ml-6 flex-2">
                            <label className="input-label font-12">Status</label>
                            <DropDown 
                                size="large" 
                                placeholder="Select" 
                                selected={retirement.is_pending ? statuses[0] : statuses[1]}
                                setSelected={c => setRetirement({...retirement, is_pending: c.id === 0})}
                                options={statuses}
                            />
                        </div>
                    </div>
                    <div className="flex-column flex-1 ml-6">
                        <label className="regular-text font-12 light-purple-text mb-2">Retirement reason</label>
                        <DropDown 
                            size="large"
                            options={retirementReasons} 
                            selected={retirement.reason} 
                            setSelected={e => setRetirement({...retirement, reason: e})} 
                            placeholder="Select" 
                        />
                    </div>
                </div>
             
                <div className="flex-column flex-1 px-6 pt-6">
                    <label className="regular-text font-12 light-purple-text mb-2">Carbon</label>
                    <CarbonDropDown
                        size="large" 
                        options={inventories.content} 
                        selected={retirement.carbon_inventory} 
                        setSelected={e => setRetirement({...retirement, carbon_inventory: e})} 
                        placeholder="Select" 
                    />
                </div>
                {/* {retirement.carbon_inventory && 
                <div className="px-6 pt-6 carbon-details-grid">
                    <div className="flex items-center one-line">
                        <label className="regular-text font-12 light-purple-text mr-2">Name:</label>
                        <label className="regular-text mr-2 one-line">{retirement.carbon_inventory?.name || '-'}</label>
                    </div>
                    <div className="flex items-center one-line">
                        <label className="regular-text font-12 light-purple-text mr-2">Owner:</label>
                        <label className="regular-text mr-2 one-line">{retirement.carbon_inventory?.owner?.name || '-'}</label>
                    </div>
                    <div className="flex items-center one-line">
                        <label className="regular-text font-12 light-purple-text mr-2">Account:</label>
                        <label className="regular-text mr-2 one-line">{retirement.carbon_inventory?.primary_account?.name || '-'}</label>
                    </div>
                    <div className="flex items-center one-line">
                        <label className="regular-text font-12 light-purple-text mr-2">Sub Account:</label>
                        <label className="regular-text mr-2 one-line">{retirement.carbon_inventory?.sub_account?.name || '-'}</label>
                    </div>
                </div>
                }
                {retirement.carbon_inventory && 
                <div className="px-6 pt-4 carbon-details-grid">
                    <div className="flex items-center one-line">
                        <label className="regular-text font-12 light-purple-text mr-2">Registry:</label>
                        <label className="regular-text mr-2 one-line">{retirement.carbon_inventory?.registry?.name || '-'}</label>
                    </div>
                    <div className="flex items-center one-line">
                        <label className="regular-text font-12 light-purple-text mr-2">Vintage:</label>
                        <label className="regular-text mr-2 one-line">{moment.unix(retirement.carbon_inventory?.vintage?.vintage_from).format('DD/MM/YYYY')}-{retirement.carbon_inventory?.vintage?.vintage_to}</label>
                    </div>
                    <div className="flex items-center one-line">
                        <label className="regular-text font-12 light-purple-text mr-2">Cost:</label>
                        <label className="regular-text mr-2 one-line">{retirement.carbon_inventory?.costs?.purchase_price}{retirement.carbon_inventory?.costs?.currency}</label>
                    </div>
                    <div className="flex items-center one-line">
                        <label className="regular-text font-12 light-purple-text mr-2">Available:</label>
                        <label className="regular-text mr-2 one-line">{retirement.carbon_inventory?.carbon?.current_balance / 1000}</label>
                    </div>
                </div>
                } */}
            <div className="flex-column mx-6 mt-6">
                <label className="regular-text font-12 light-purple-text mb-2">Serial number</label>
                <input className="edit-retirement-input"  value={retirement.serial_number || ''} onChange={e => setRetirement({...retirement, serial_number: e.target.value})} />
            </div>
            <div className="flex-column mt-6 mx-6">
                <label className="regular-text font-12 light-purple-text mb-2">Offset url</label>
                <input className="edit-retirement-input"  value={retirement.offset_url || ''} onChange={e => setRetirement({...retirement, offset_url: e.target.value})} />
            </div>
            <div className="flex items-center pxy-6">
                <div className="flex-column flex-1">
                    <div className="flex items-center justify-between mb-2">
                        <label className="regular-text font-12 light-purple-text">Transparency link</label>
                        <div className="flex items-center">
                            <div className="flex items-center mr-6">
                                <label className="light-purple-text font-12 regular-text mr-2">Set default</label>
                                <Switch
                                    variant="small"
                                    enabled={def}
                                    onClick={() => changeTransparencyUrl()}
                                    />
                            </div>
                            <div className="flex items-center cursor" onClick={() => {navigator.clipboard.writeText(retirement.transparency_url)}} >
                                <label className="light-purple-text font-12 regular-text mr-2">Copy</label>
                                <img src={CopyIcon} alt="copy" className="cursor" />
                            </div>
                        </div>
                    </div>
                    {def ?
                        <div className="flex flex-center justify-between items-center inactive-input">
                            <label className="regular-text mr-2 one-line">{retirement.transparency_url}</label>
                        </div>
                        :
                        <input className="edit-retirement-input"  value={retirement.transparency_url || ''} onChange={e => setRetirement({...retirement, transparency_url: e.target.value})} />
                    }
                </div>
            </div>
            <div className="flex items-center px-6">
                <div className="flex items-center flex-1">
                        <div className="flex-column flex-1 flex-1" >
                            <label className="input-label font-12">Beneficiary Account</label>
                            <div className="flex items-center inactive-input">
                                <label className="regular-text o05">{campaign.beneficiary?.name}</label>
                            </div>
                        </div> 
                    </div>
                    <div className="flex items-center flex-1 ml-6">
                        <div className="flex-column flex-1" >
                            <label className="input-label font-12">Customer Name</label>
                            <div className="flex items-center inactive-input">
                                <label className="regular-text o05">{contact ? contact.business_name ? contact.business_name : contact?.forename + " " + contact.surname  : '-'}</label>
                            </div>
                        </div> 
                    </div>
                        <div className="flex-column flex-1 ml-6" >
                            <label className="input-label font-12">Carbon Beneficiary</label>
                            <input className="edit-retirement-input"  value={retirement.beneficiary || ''} onChange={e => setRetirement({...retirement, beneficiary: e.target.value})} />
                        </div> 
                    </div>
            
                <div className="flex justify-center mt-auto mb-6 mt-10">
                    <div className="flex items-center justify-center cancel-btn cursor mr-3"
                            onClick={() => onCancel(false)}>
                        <label className="medium-text color-green">Cancel</label>
                    </div>

                    <div
                        onClick={addRetirement}
                        className={`${(true) ? 'done-btn' : 'disable-btn'} flex items-center justify-center cursor ml-3`}>
                        <label className="medium-text color-white">Save</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddRetirementPopup;
