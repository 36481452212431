import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import DateSelectorElement from '../date-selector-element/date-selector-element';

import DeleteIcon from '../../../assets/images/trash-chart.svg';

import './style.scss';

const TxDateSelectors = ({ close, from, setFrom, to, setTo, removeDates }) => {
  const ref = useRef();

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      close(false);
    }
  };

  useEffect(() => {
    if (ref.current) ref.current.focus();
  }, [ref]);

  return (
    <div ref={ref} className="flex flex-column card absolute tx-date-selectors" onBlur={onBlur} tabIndex={0}>
      <div className="flex items-center justify-between border-bottom px-6 py-4">
        <label className="bold-text">Select dates</label>
        <div className="flex items-center justify-center cursor tx-delete-date-btn no-select" onClick={removeDates}>
          <label className='font-12 bold-text'>Clear all</label>
        </div>
      </div>
      <div className='flex-column px-6 py-4'>
        <div className="flex items-center">
          <label className="regular-text selector-label">Start date</label>
          <DateSelectorElement
             placeholder="DD/MM/YY" 
             selectedDate={from} 
             setSelectedDate={setFrom} 
             format='DD/MM/YY' 
             showDropdownArrow={false}
             borderRadius='12px'
          />
        </div>
        <div className="flex items-center mt-4">
          <label className="regular-text selector-label">End date</label>
          <DateSelectorElement
             placeholder="DD/MM/YY" 
             selectedDate={to}
             setSelectedDate={setTo} 
             format='DD/MM/YY'
             showDropdownArrow={false}
             borderRadius='12px'
          />
        </div>
      </div>
    </div>
  );
};

export default TxDateSelectors;
