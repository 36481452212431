import styled from 'styled-components';

const AutomationLogsWrapper = styled.div`
  .search {
    height: 40px;
    padding: 0 16px;
    background: #ffffff;
    padding-right: 6px;

    &:hover {
      background: #f4f7f9;

      input {
        background: #f4f7f9;
      }

      .close {
        background: #f4f7f9;
      }
    }

    input {
      border: none;
      outline: none;
    }
    .close {
      background: #fff;
      border-radius: 100px;
      width: 24px;
      height: 24px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }
    .close-focused {
      background: #f4f7f9;
    }

    .close-icon {
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }
  }

  .search-focused {
    border: 1px solid #40f1de;
  }

  .hide-pagination {
    display: none;
  }

  .data-match-row {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(0, 3fr) minmax(0, 3fr) 2fr 2fr 0.5fr;
    background: #ffffff;
    box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
    border-radius: 8px;
    height: 40px;
  }

  hr {
    background: rgba(210, 229, 237, 0.5);
  }

  .data-matches {
    overflow-y: scroll;
    max-height: calc(100vh - 160px);
    padding: 0 4px;
  }

  .content-loader {
    position: absolute;
    inset: 0;
    margin: 0;
    opacity: 0.8;
    z-index: 10;
  }
`;

export default AutomationLogsWrapper;
