import React, { useContext } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { CSSTransition } from 'react-transition-group';
import AddIntegration from './add-integration';
import AddAutomation from './add-automation';
import AutomationAction from './automation-action';
import CancelAutomationLog from './cancel-automation';
import IntegrationActions from './integration-actions';
import DeleteAutomationAction from './delete-automation-action';
import AutomationSaveAction from './automation-save-action';
import AddTeam from './add-team-popup';
import AddTeamMember from './add-team-member-popup';
import InviteUser from './invite-user-popup';
import DeleteUser from './delete-user-popup';
import DeleteTeam from './delete-team-popup';
import ReInviteUserModal from './reinvite-user-popup';
import DeleteCampaignProject from './delete-campaign-project';
import DeleteCampaignTier from './delete-campaign-tier';
import DeleteProject from './delete-project-popup';
import DeleteMedia from './delete-media-popup';
import MintRetirementNft from './mint-retirement-nft-popup';
import CreateRetirementNft from './create-retirement-nft';
import MintNft from './mint-nft';
import RetryLog from './retry-log';
import CancelLog from './cancel-log';
import AddAccount from './add-account';
import TransferTransaction from './transfer-transaction';
import DeletePopup from './delete-popup';

import { OrganisationContext } from "../context/organisationContext";
import { ModalWrapper } from './modal.styled';
import CreateNft from './create-nft';
import RemoveSchemaAttribute from './remove-schema-attribute';
import RemoveTemplateAttribute from './remove-template-attribute';

const initModal = { type: null, content: {} };

const Modal = () => {
  const { setModal, modal } = useContext(OrganisationContext);

  const onClick = e => {
    if (e.target === e.currentTarget) {
      setModal(initModal);
    }
  };

  const ref = useOnclickOutside(
    () => {
      setModal(initModal);
    },
    {
      ignoreClass: `ignore-click`,
    },
  );

  const setPopupComponent = () => {
    switch (modal.type) {
      case 'add-integration':
        return <AddIntegration />;
      case 'integration-actions':
        return <IntegrationActions />;
      case 'add-automation':
        return <AddAutomation />;
      case 'automation-action':
        return <AutomationAction />;
      case 'automation-save-action':
        return <AutomationSaveAction />;
      case 'cancel-automation-log':
        return <CancelAutomationLog />;
      case 'delete-automation-action':
        return <DeleteAutomationAction />;
      case 'add-team':
        return <AddTeam />;
      case 'add-team-member':
        return <AddTeamMember />;
      case 'delete-user':
        return <DeleteUser />;
      case 're-invite-user':
        return <ReInviteUserModal />;
      case 'delete-team':
        return <DeleteTeam />;
      case 'delete-campaign-project':
        return <DeleteCampaignProject />;
      case 'delete-campaign-tier':
        return <DeleteCampaignTier />;
      case 'delete-popup':
        return <DeletePopup />;
      case 'delete-project':
        return <DeleteProject />;
      case 'delete-media':
        return <DeleteMedia />;
      case 'mint-retirement-nft':
        return <MintRetirementNft />;
      case 'create-nft':
        return <CreateNft />;
      case 'create-retirement-nft':
        return <CreateRetirementNft />;
      case 'mint-nft':
        return <MintNft />;
      case 'remove-schema-attribute':
        return <RemoveSchemaAttribute />;
      case 'retry-automation-log':
          return <RetryLog />;
      case 'cancelled-automation-log':
        return <CancelLog />;
      case 'remove-template-attribute':
        return <RemoveTemplateAttribute />;
      case 'add-account':
        return <AddAccount />;
      case 'transfer-transaction':
        return <TransferTransaction />;
      case 'invite-user':
        return (
          <InviteUser
            merchant={modal.content.merchant}
            organisationId={modal.content.organisationId}
            user={modal.content.user}
            venues={modal.content.venues}
          />
        );
      default:
        return <></>;
    }
  };

  if (
    !modal.type ||
    modal.type === 'edit-lead-success' ||
    modal.type === 'delete-lead-success' ||
    modal.type === 'cancel-lead-success' ||
    modal.type === 'new-appointment-success' ||
    modal.type === 'edit-appointment-success' ||
    modal.type === 'delete-appointment-success' ||
    modal.type === 'delete-note-success' ||
    modal.type === 'delete-contact-success' ||
    modal.type === 'archive-property-success' ||
    modal.type === 'invite-user-success' ||
    modal.type === 'delete-user-success' ||
    modal.type === 'add-team-member-success' ||
    modal.type === 'install-state-success' ||
    modal.type === 'book-install-success' ||
    modal.type === 'integration-success' ||
    modal.type === 'survey-update-success' ||
    modal.type === 'add-team-success' ||
    modal.type === 'delete-team-success' ||
    modal.type === 'service-remove-success' ||
    modal.type === 're-invite-user-success' ||
    modal.type === 'automation-success' ||
    modal.type === 'dashboard-success' ||
    modal.type === 'automation-log-success' ||
    modal.type === 'automation-log-cancelled'
  )
    return null;

  return (
    <CSSTransition appear classNames="modal-transition" in timeout={100}>
      <ModalWrapper
        id="modal-wrapper-id"
        onclick={onClick}
        style={{ justifyContent: modal.content?.top ? 'start' : 'center' }}>
        <CSSTransition key="modal-content-id" appear classNames="popup-in" in timeout={200}>
          <div
            key="modal-content-id"
            ref={ref}
            className="modal-content"
            id="modal-content-id"
            style={{
              borderRadius: modal.content?.noRadius ? '0' : '16px',
              marginTop: modal.content?.top ? '80px' : '0',
            }}>
            {setPopupComponent()}
          </div>
        </CSSTransition>
      </ModalWrapper>
    </CSSTransition>
  );
};

export default Modal;
