import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSchemas } from "../../../store/features/schemaSlice";
import {
  saveNewTemplate,
  setCreateTemplateStatus,
  updateTemplate,
} from "../../../store/features/templateSlice";
import { addToast } from "../../../store/features/toastSlice";
import Button from "../../Elements/button/button";

import EditDeleteMenu from "../../Elements/EditDeleteMenu";
import Image from "../../image";
import DropDown from "../../McDropdown";

import "./style.scss";

const TemplateDetailsComponent = ({ template, isNew }) => {
  const booleanTypeOptions = [
    { id: 0, name: "Yes", text: true },
    { id: 1, name: "No", text: false },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pageRef = useRef(null);
  const headlineRef = useRef(null);

  const [edit, setEdit] = useState(isNew);
  const [showError, setShowError] = useState(false);
  const [templateState, setTemplateState] = useState({ ...template });
  const [search, setSearch] = useState("");
  const { schemas } = useSelector((data) => data.schema);
  const { createTemplateStatus } = useSelector((data) => data.template);

  const fetchSchemas = (page, merge) => {
    pageRef.current = page;
    dispatch(getSchemas({ page: page, size: 10, search: search, merge }));
  };

  useEffect(() => {
    fetchSchemas(0, false);
  }, [search]);

  useEffect(() => {
    setTemplateState({ ...template });
    setSearch("");
  }, [template]);

  const getMediaId = (media) => {
    return media
      ? {
          id:
            media.format === "IPFS"
              ? media?.public_id
              : media?.media_external_id,
        }
      : null;
  };

  const saveTemplate = () => {
    const {
      back_image,
      back_video,
      description,
      name,
      image,
      is_burnable,
      is_transferable,
      is_ipfs,
      schema,
      supply,
      video,
      default_co2_offset,
      nft_name_template
    } = templateState;
    if (!schema?.id || !name) {
      dispatch(
        addToast({
          error: true,
          title: "Template creation error",
          text: `Please enter required fields`,
          id: "template-creation-error",
        })
      );
      setShowError(true);
      return;
    }
    const request = {
      name,
      description,
      is_transferable,
      is_burnable,
      is_ipfs,
      supply,
      schema: {
        id: schema?.id,
      },
      image: getMediaId(image),
      video: getMediaId(video),
      back_video: getMediaId(back_video),
      back_image: getMediaId(back_image),
      default_co2_offset,
      nft_name_template
    };
    if (isNew) {
      dispatch(saveNewTemplate({ request }));
    } else {
      dispatch(updateTemplate({ id: templateState.id, request }));
      setEdit(false);
    }
  };

  const onCancelEdit = () => {
    if (isNew) {
      navigate(`/meta/templates`);
    } else {
      setEdit(false);
    }
  };

  useEffect(() => {
    if (isNew && createTemplateStatus === "CREATED") {
      navigate(`/meta/templates/${template.id}/media`);
      setEdit(false);
    }
  }, [createTemplateStatus, isNew]);

  useEffect(() => {
    return () => {
      dispatch(setCreateTemplateStatus(null));
    };
  }, []);

  useEffect(() => {
    if (headlineRef.current) {
      headlineRef.current.style.height = "0px";
      const scrollHeightHeadline = headlineRef.current.scrollHeight;
      headlineRef.current.style.height = scrollHeightHeadline + 5 + "px";
    }
  }, [templateState?.description, edit]);

  return (
    <div className="flex-column card mx-6 template-details-l fit-content">
      <div className="flex items-center justify-between pxy-6 border-bottom">
        <label className="bold-text font-20">Template details</label>
        {!isNew && (
          <EditDeleteMenu onEdit={() => setEdit(true)} onDelete={() => {}} />
        )}
      </div>
      {edit ? (
        <div className="flex-column pxy-6">
          <div className="flex justify-center mb-6">
            <Button
              className="secondary-hover"
              label="Cancel"
              borderRadius="12px"
              onClick={() => onCancelEdit()}
              secondary={true}
              size="average"
              style={{ flex: 1 }}
              bgColor="#FFFFFF"
              borderColor="#24CBB1"
              color="#24CBB1"
              borderWidth="1px"
            />
            <Button
              label="Save"
              onClick={() => saveTemplate()}
              size="average"
              style={{ flex: 1, marginLeft: 24 }}
              bgColor="#24CBB1"
              borderColor="#24CBB1"
              color="#FFFFFF"
              borderRadius="12px"
              borderWidth="1px"
            />
          </div>
          <div className="flex-column template-details-edit">
            <Image
              className={"template-image"}
              size={160}
              media={template?.image?.media}
            />
            <label className="input-label mt-6">Name</label>
            <input
              className={`input ${showError && !templateState.name && "error"}`}
              value={templateState?.name || ""}
              placeholder="Enter name"
              onChange={(e) =>
                setTemplateState({ ...templateState, name: e.target.value })
              }
            />
            <label className="input-label mt-6">Description</label>
            <textarea
              className="textarea"
              value={templateState?.description || ""}
              placeholder="Enter description"
              ref={headlineRef}
              onChange={(e) =>
                setTemplateState({
                  ...templateState,
                  description: e.target.value,
                })
              }
            />
            <label className="input-label mt-6">Max supply</label>
            <input
              className="input"
              value={templateState?.supply?.max || ""}
              placeholder="Enter max supply"
              onChange={(e) =>
                setTemplateState({
                  ...templateState,
                  supply: {
                    ...templateState.supply,
                    max: e.target.value,
                  },
                })
              }
            />
            <div className="mt-6 flex w-full col-gap-4">
              <div className="flex-column w-full">
                <label className="input-label">Transfereable</label>
                <DropDown
                  size="large"
                  placeholder="Select"
                  selected={booleanTypeOptions.find(
                    (c) => c.text === templateState.is_transferable
                  )}
                  setSelected={(c) =>
                    setTemplateState({
                      ...templateState,
                      is_transferable: c.text,
                    })
                  }
                  options={booleanTypeOptions}
                />
              </div>
              <div className="flex-column w-full">
                <label className="input-label">Burnable</label>
                <DropDown
                  size="large"
                  placeholder="Select"
                  selected={booleanTypeOptions.find(
                    (c) => c.text === templateState.is_burnable
                  )}
                  setSelected={(c) =>
                    setTemplateState({ ...templateState, is_burnable: c.text })
                  }
                  options={booleanTypeOptions}
                />
              </div>
            </div>
            <label className="input-label mt-6">Schema</label>
            <div
              className={`${showError && !templateState.schema && "error"}`}
            >
              <DropDown
                size="large"
                options={schemas.content}
                selected={templateState?.schema}
                setSelected={(s) =>
                  setTemplateState({ ...templateState, schema: s })
                }
                placeholder="Select schema"
                isSearchable={true}
                search={search}
                setSearch={setSearch}
                isPaged={true}
                hasMore={!schemas.last}
                fetchData={() => fetchSchemas(pageRef.current + 1, true)}
              />
            </div>
            <label className="input-label mt-6">Default CO2</label>
            <input
              className="input"
              value={templateState?.default_co2_offset || ""}
              placeholder="Enter default CO2"
              onChange={(e) =>
                setTemplateState({
                  ...templateState,
                  default_co2_offset: e.target.value,
                })
              }
            />
            <label className="input-label mt-6">Name Template</label>
            <input
              className="input"
              value={templateState?.nft_name_template || ""}
              placeholder="Enter name template"
              onChange={(e) =>
                setTemplateState({
                  ...templateState,
                  nft_name_template: e.target.value,
                })
              }
            />
          </div>
        </div>
      ) : (
        <div className="flex-column pxy-6 template-details-info">
          <Image
            className={"template-image"}
            size={160}
            media={template?.image?.media}
          />
          <label className="bold-text font-16 mt-6">{template.name}</label>
          <label className="regular-text light-purple-text mt-2">
            {template.description}
          </label>
          <div className="flex items-center justify-between mt-6">
            <label className="regular-text light-purple-text">
              Max supply:
            </label>
            <label className="regular-text">{template?.supply?.max}</label>
          </div>
          <div className="flex items-center justify-between mt-4">
            <label className="regular-text light-purple-text">
              Transferable:
            </label>
            <label className="regular-text">
              {template.is_transferable ? "Yes" : "No"}
            </label>
          </div>
          <div className="flex items-center justify-between mt-4">
            <label className="regular-text light-purple-text">Burnable:</label>
            <label className="regular-text">
              {template.is_burnable ? "Yes" : "No"}
            </label>
          </div>
          <div className="flex items-center justify-between mt-4">
            <label className="regular-text light-purple-text">Schema:</label>
            <label className="regular-text">{template.schema?.name}</label>
          </div>
          <div className="flex items-center justify-between mt-4">
            <label className="regular-text light-purple-text">Default CO2:</label>
            <label className="regular-text">{template.default_co2_offset}</label>
          </div>
          <div className="flex items-center justify-between mt-4">
            <label className="regular-text light-purple-text">Name Template:</label>
            <label className="regular-text">{template.nft_name_template}</label>
          </div>
        </div>
      )}
    </div>
  );
};

export default TemplateDetailsComponent;
