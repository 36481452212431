import React, { useEffect, useState, useCallback, useRef, useContext } from "react";

import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import { getTransactions, setTransactions } from '../../../store/features/transactionSlice';
import { getCampaignProjects, setCampaignProjects } from "../../../store/features/campaignSlice";
import { addToast } from "../../../store/features/toastSlice";
import uuid from "react-uuid";
import { OrganisationContext } from "../../../context/organisationContext";

import ArrowTabLeft from "../../../assets/images/arrow-left-tab.svg";
import ArrowTabRight from "../../../assets/images/arrow-right-tab.svg";
import McCheckbox from "../../McCheckbox";
import AddRetirementPopup from "../../campaignNft/addRetirementPopup";
import AddTransactionPopup from "../addTransactionPopup";

import InfiniteScroll from "react-infinite-scroll-component";
import Loading from '../../../assets/images/loading.svg';
import AddIcon from "../../../assets/images/add.svg";

    
import './style.scss'

const CampaignTransactions = ({ campaign }) => {
    const ref = useRef();

    const { setModal } = useContext(OrganisationContext);

    const { transactions, loading } = useSelector(data => data.transaction);
    const { campaignProjects } = useSelector(data => data.campaign);

    const [selectedProject, setSelectedProject] = useState();
    const [selectAll, setSelectAll] = useState(false);
    const [showRetirement, setShowRetirement] = useState();
    const [showTransactions, setShowTransactions] = useState();
    const [n, setN] = useState(0);
    const dispatch = useDispatch();

    const loadTransactions  = useCallback(async (page, merge)  => {
            dispatch(getTransactions(
                {
                    page: page, 
                    search: '',
                    size: 30,
                    filter:  `campaign_id=${campaign.id}&project_id=${selectedProject.project.id}&retired=false`,
                    merge: merge
                }));
      }, [campaign, selectedProject, dispatch]);

      useEffect(() => {
        if (campaign && campaign.id && selectedProject)
            loadTransactions(0, false);
    }, [campaign, selectedProject, loadTransactions]);

    const fetchMoreTransactions = () => {
        loadTransactions(transactions?.number + 1, true);
    };

    useEffect(() => {
        if(campaign)
            dispatch(getCampaignProjects({campaignId: campaign.id}))
    }, [campaign, n, dispatch])
    

    useEffect(() => {
        if(campaignProjects && campaignProjects.length > 0)
            setSelectedProject(campaignProjects[0]);
    }, [campaignProjects])

    const onSelectAll = () => {
        setSelectAll(!selectAll);
        dispatch(setTransactions({...transactions, content: transactions.content.map(t =>  ({...t, selected: false }))}))
    }

    const onSelectTransaction = tx => {
        setSelectAll(false);
        dispatch(setTransactions({...transactions, content: transactions.content.map(t => t.id === tx.id ? ({...t, selected: !t.selected}) : t)}))
    }

    const changeSelectedProject = p => {
        setSelectedProject(p);
        setSelectAll(false);
    }

    const handleHorizantalScroll = (element, speed, distance, step) => {
        let scrollAmount = 0;
        const slideTimer = setInterval(() => {
            element.scrollLeft += step;
            scrollAmount += Math.abs(step);
            if (scrollAmount >= distance) {
            clearInterval(slideTimer);
            }
        }, speed);
    };

    const onScroll = v => {
        handleHorizantalScroll(ref.current, 25, 430, v)
    }

    const onRetire = () => {
        if(!selectAll && transactions.content.filter(t => t.selected).length === 0)
            dispatch(addToast({
                error: true,
                title: "Error",
                text: `Please select transactions`,
                id: uuid(),
            }))
        else
            setShowRetirement(true)
    }

    const onTransfer = () => {
        if(!selectAll && transactions.content.filter(t => t.selected).length === 0)
        dispatch(addToast({
            error: true,
            title: "Error",
            text: `Please select transactions`,
            id: uuid(),
        }))
    else
    setModal({
        type: 'transfer-transaction',
        content: {
          transactions: transactions.content.filter(t => t.selected),
          campaignProjects: campaignProjects.filter(cp => cp.id !== selectedProject.id),
          onSuccess: () => setN(n+1),
      }});
    }
    return(
        <div className="flex-column campaign-list-tx">
            <div className="flex items-center py-4 mx-6 cursor">
                {/* <div className="mr-2" onClick={() => onScroll(-380)}>
                    <img src={ArrowTabLeft} alt="left" width="24px" height="24px"/>
                </div> */}
                <div className="flex items-center flex-1 tx-projects" ref={ref}>
                    {campaignProjects.map(cp =>
                        <div key={cp.id} className={`flex-column tx-project pxy-6 mxy-2 ${selectedProject?.id === cp.id && 'selected-tx-project'}`} style={{borderColor: selectedProject?.id === cp.id ? '#24CBB1' : ''}} onClick={() => changeSelectedProject(cp)}>
                            <label className="bold-text font-12 light-purple-text mb-2 one-line">{cp.project.name.toUpperCase()}</label>
                            <label className="bold-text font-20 tx-total-kg">{cp.total_co2_kilo_amount ? cp.total_co2_kilo_amount.toLocaleString() : 0 } <span className="font-16">kilo</span></label>
                        </div>
                    )}
                </div>
                  {/* <div className="ml-2" onClick={() => onScroll(380)}>
                    <img src={ArrowTabRight} alt="left" width="24px" height="24px"/>
                </div> */}
            </div>
            <div className="flex items-center justify-between mx-6 mb-4 no-select">
                <div className="flex items-center">
                    <div className="flex items-center cursor" onClick={onSelectAll}>
                        <McCheckbox checked={selectAll} />
                        <label className="regular-text ml-2">Select all</label>
                    </div>
                    <div className="flex items-center justify-center add-btn cursor ml-10" style={{ height: '32px', width: '112px'}} onClick={onRetire} >
                        <label className="bold-text font-14 violet-light-text ml-2">Retire</label>
                    </div>
                    <div className="flex items-center justify-center add-btn cursor ml-4" style={{ height: '32px', width: '112px'}} onClick={onTransfer} >
                        <label className="bold-text font-14 violet-light-text ml-2">Transfer</label>
                    </div>
                </div>
                <div className="flex items-center justify-center add-btn cursor ml-4" style={{height: '32px', width: '112px'}}  onClick={() => setShowTransactions(true)} >
                    <img src={AddIcon} alt="add" />
                    <label className="bold-text font-14 violet-light-text ml-2">Add new</label>
                </div>
            </div>
            <div className="tx-campaign-grid tx-campaign-header border-bottom border-top">
                    <label className="font-12 semibold-text px-6 py-3 ml-12 o05">Date & Time</label>
                    <label className="font-12 semibold-text px-6 py-3 o05">Amount of CO2</label>
                    <label className="font-12 semibold-text px-6 py-3 o05">Wallet</label>
                    <label className="font-12 semibold-text px-6 py-3 o05">Contact</label>
                    <label className="font-12 semibold-text px-6 py-3 o05">Reference</label>
                </div>
              
            <div className="flex-column campaign-list-tx flex-1">
            {             
                transactions.content?.length > 0 ?
                    <div className="tx-campaign-list" id="scrollableDiv">
                        <InfiniteScroll
                            dataLength={transactions?.content?.length}
                            hasMore={!transactions?.last}
                            loader={
                            <div className="item flex items-center justify-center my-2">
                                <img alt="loading" height="24px" src={Loading} />
                            </div>
                            }
                            next={fetchMoreTransactions}
                            scrollableTarget="scrollableDiv"
                            style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            overflow: "unset",
                            }}
                        >
                        {transactions.content?.map(t => 
                        <div className={"tx-campaign-grid tx-card cursor border-bottom cursor"}  key={t.id} onClick={() => onSelectTransaction(t)}>
                            <div className="flex items-center ml-6">
                                <McCheckbox checked={t.selected || selectAll} />
                                <label className='medium-text px-6 py-3'>{moment.unix(t.transaction_date).format("DD/MM/YYYY HH:mm")}</label>
                            </div>
                            <label className='medium-text px-6 py-3'>{t.co2_kg_units} kilos</label>
                            <label className='medium-text px-6 py-3'>{t.wallet?.alias || t.wallet?.wallet_address}</label>
                            <label className='medium-text px-6 py-3'>{t.contact ? t.contact?.contact_type === 'BUSINESS' ? t.contact?.business_name  : t.contact?.forename + " " + t.contact?.surname : '-' }</label>
                            <label className='medium-text px-6 py-3'>{t.reference}</label>
                         </div>
                        )}
                        </InfiniteScroll>   
                    </div>
                :
                loading ? 
                <div className="flex flex-1 items-center justify-center my-6">
                    <img alt="loading" height="24px" src={Loading} />
                </div>
                :
                <div className="flex items-center justify-center flex-1">
                    <label className="bold-text font-16 light-purple-text">There are no Transactions</label>
                </div>
            }
        </div>
        {showRetirement && <AddRetirementPopup campaignProject={selectedProject} amount={selectedProject.total_co2_kilo_amount || 0} campaign={campaign} onCancel={refresh => {setShowRetirement(null); if(refresh) setN(n+1)}} transactions={transactions.content.filter(t => t.selected)} />}
        {showTransactions &&<AddTransactionPopup onCancel={refresh => {setShowTransactions(null); if(refresh) setN(n+1)}} campaignProject={{...selectedProject, campaign: campaign}} />}
        </div>
    )
}

export default CampaignTransactions;