import React, { useState, useRef } from "react";

import AddIcon from '../../assets/images/plus-white.svg';
import ViewIcon from '../../assets/images/view.svg';
import DeleteIcon from '../../assets/images/trash.svg';
import PrimaryImageIcon from '../../assets/images/primary-image.svg';


import "../../pages/projectDetails/details.scss";

const MediaList = ({media, setFile, deleteMedia, setPrimary, addPermission, hasPermission, checkPermission, primary}) => {
    
    const inputFile = useRef(null)

    const [showActions, setShowActions] = useState(false);

    const onClick = () => {
       checkPermission(() => inputFile.current.click(), addPermission)
    }

    return (
        <div className="media-grid px-6 pb-6 pt-4">
            <div className="flex items-center justify-center cursor add-media" onClick={onClick}  style={{opacity: hasPermission(addPermission) ? 1 : 0.5}}>
                <div className="flex items-center justify-center add-media-btn">
                    <img src={AddIcon} alt="add" />
                    <input className='file-select-input' type='file' ref={inputFile} onChange={(e) => setFile(e.target.files[0])} />
                </div>
            </div>
            {media?.map(creative => 
                <div key={creative.id} className="project-media relative" onMouseEnter={() => setShowActions(creative.id)} onMouseLeave={() => setShowActions(null)}>
                    {creative.resource_type === 'video' ?
                    <video controls="controls" preload="metadata">
                        <source src={`${creative.url}#t=0.5`} type="video/mp4"/>
                    </video>                  
                    :
                    <img src={creative.url} alt="" style={{border: primary === creative.media_external_id ? '4px solid #24CBB1' : ''}} />
                }
                    {showActions === creative.id && creative.resource_type !== 'video' &&
                        <div className="flex edit-image">
                            <div className="flex-column edit-image-actions mr-3 mt-3">
                                <div className="flex items-center justify-center edit-image-action cursor">
                                    <img src={ViewIcon} alt="view" />
                                </div>
                                <div className="flex items-center justify-center edit-image-action mt-2 cursor"  style={{opacity: hasPermission(addPermission) ? 1 : 0.5}} onClick={() => checkPermission(() => deleteMedia(creative), addPermission)}>
                                    <img src={DeleteIcon} alt="delete" />
                                </div>
                                <div className="flex items-center justify-center edit-image-action mt-2 cursor"  style={{opacity: hasPermission(addPermission) ? 1 : 0.5}} onClick={() => checkPermission(() => setPrimary(creative), addPermission)}>
                                    <img src={PrimaryImageIcon} alt="primary" />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                )}
        </div>
    )
}

export default MediaList;
