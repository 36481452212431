import React from 'react';
import ButtonWrapper from './button.styled.js';

const Button = ({
  bgColor = '#BEF5EC',
  borderColor = '#BEF5EC',
  color = '#053149',
  disabled,
  icon = null,
  label = '',
  onClick = () => {},
  size = 'small',
  width = 'fit-content',
  borderRadius = '8px',
  borderWidth='1px',
  ...buttonProps
}) => {
  let height = '24px';
  let fontSize = '12px';

  switch (size) {
    case 'large':
      height = '48px';
      fontSize = '16px';
      break;
    case 'medium':
      height = '32px';
      fontSize = '14px';
      break;
    case 'tiny':
      height = '15px';
      break;
    case 'average':
      height = '40px';
      fontSize = '16px';
      break;
    default:
      break;
  }

  return (
    <ButtonWrapper
      bgColor={bgColor}
      borderColor={borderColor}
      borderRadius={borderRadius}
      color={color}
      fontSize={fontSize}
      height={height}
      onClick={onClick}
      width={width}
      borderWidth={borderWidth}
      {...(disabled && {
        bgColor: '#73868C33',
        borderColor: 'transparent',
        color: 'white',
        onClick: null,
      })}
      {...buttonProps}>
      {icon && icon}
      {label && (
        <label className="medium-text" style={{ fontSize }}>
          {label}
        </label>
      )}
    </ButtonWrapper>
  );
};

export default Button;
