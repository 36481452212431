import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { getOwnerPerformance } from "../../../store/features/reportsSlice";
import ReportSale from "../sale";

import "./style.scss";

const ReportSales = ({ organisation, selected }) => {

  const dispatch = useDispatch();

  const { ownerPerformance } = useSelector((data) => data.reports);

  const [sales, setSales] = useState([]);

  useEffect(() => {
    dispatch(
      getOwnerPerformance({
        startDate: selected.startDate,
        endDate: selected.endDate,
        organizationId: organisation?.id === "all" ? null : organisation?.id,
      })
    );
  }, [dispatch, selected, organisation]);

  useEffect(() => {
    setSales(
      ownerPerformance
        .filter((e) => e.project?.id)
        .map(sale => ({...sale, paymentFees: sale.amount_usd * (sale.payment_fees || 0), ownerCommission: sale.amount_usd * (sale.owner_commission || 0), platformCommission: sale.amount_usd * (sale.platform_commission || 0)}))
        .reduce((r, e) => {
          var key = (e.project?.id || "-") + "::" + e.currency;
          if (!r[key]) r[key] = [e];
          else {
            r[key].push(e);
          }
          return r;
        }, [])
    );
  }, [ownerPerformance]);


  return (
    <div className="flex-column">
      <div className="report-details-grid-sales report-details-header border-top">
        {/* <label className="font-12 regular-text px-4 py-3">INVENTORY</label> */}
        <label className="font-12 regular-text px-4 py-3 text-center">
          PROJECT
        </label>
        <label className="font-12 regular-text px-4 py-3 text-center">OWNER</label>
        <label className="font-12 regular-text px-4 py-3 text-center">
          SERIAL
        </label>
        <label className="font-12 regular-text px-4 py-3 text-center">MONTH</label>
        {/* <label className="font-12 regular-text px-4 py-3">WHOLESALE PRICE</label> */}
        <label className="font-12 regular-text px-4 py-3 text-center">
          RETAIL SALE
        </label>
        <label className="font-12 regular-text px-4 py-3 text-center">
          RETAIL SALE USD
        </label>
        <label className="font-12 regular-text px-4 py-3 text-center">
          PAYMENT PROCESSING
        </label>
        <label className="font-12 regular-text px-4 py-3 text-center">
          OWNER COMMISSION
        </label>
        <label className="font-12 regular-text px-4 py-3 text-center">
          PLATFORM COMMISSION
        </label>
        <label className="font-12 regular-text px-4 py-3 text-center">
          AMOUNT DUE SUPPLIER
        </label>
        {/* <label className="font-12 regular-text px-4 py-3">
          AMOUNT USD
        </label> */}
        <label className="font-12 regular-text px-4 py-3 text-center">SALES TONNES</label>
      </div>
      <div className="flex-column report-sales-list">
        {Object.entries(sales).map(([k, v]) => (
          <div>
            {v.map((op, i) => (
              <ReportSale sale={op} key={i}/>
            ))}
            <div className={"report-details-grid-sales ci-card cursor border-top cursor border-bottom mb-10"}>
                <label className='bold-text px-4 py-3'></label>
                <label className='bold-text px-4 py-3'></label>
                <label className='bold-text px-4 py-3'></label>
                <label className='bold-text px-4 py-3'>Total:</label>
                <label className='bold-text px-4 py-3'>{v.map(p => p.amount).reduce((sum, p) => sum + p, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} {k.split("::")[1]}</label>
                <label className='bold-text px-4 py-3'>${v.map(p => p.amount_usd).reduce((sum, p) => sum + p, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</label>
                <label className='bold-text px-4 py-3'>${v.map(p => p.paymentFees).reduce((sum, p) => sum + p, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</label>
                <label className='bold-text px-4 py-3'>${v.map(p => p.ownerCommission).reduce((sum, p) => sum + p, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</label>
                <label className='bold-text px-4 py-3'>${v.map(p => p.platformCommission).reduce((sum, p) => sum + p, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</label>
                <label className='bold-text px-4 py-3'>${(v.map(p => p.amount_usd).reduce((sum, p) => sum + p, 0) - v.map(p => p.paymentFees).reduce((sum, p) => sum + p, 0) - v.map(p => p.ownerCommission).reduce((sum, p) => sum + p, 0) - v.map(p => p.platformCommission).reduce((sum, p) => sum + p, 0)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</label>
                <label className='bold-text px-4 py-3'>{((v.map(p => p.sales).reduce((sum, p) => sum + p, 0)/1000)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})}</label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportSales;
