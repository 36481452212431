import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/images/search-icon.svg';
import useDebounce from '../../helpers/useDebounceHook';
import api from '../../store/services'
import AutomationLogItem from './automation-logs-item';
import Loading from '../../assets/images/loading.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import { AutomationContentWrapper } from './content.styled';
import EmptyNotesIcon from '../../assets/images/empty-notes.svg';

const SelectedAutomationLogs = ({ selectedAutomation }) => {
  const header = ['ID', 'AUTOMATION NAME', 'DATE', 'STATUS'];
  const [searchText, setSearchText] = useState('');
  const [selectedPage, setSelectedPage] = useState(0);
  const [automationLogs, setAutomationLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [selectedAutomationLog, setSelectedAutomationLog] = useState({});
  const [hasMoreData, setHasMoreData] = useState(false);
  const [focusedInput, setFocusedInput] = useState(false);

  const debouncedSearchText = useDebounce(searchText, 500);
  const currentSearchText = useRef(debouncedSearchText);

  const fetchAutomationLogs = async (page = null, merge = false) => {
    if (!merge) {
      setLoading(true);
    }
    const { data } = await api.get(
      `/v3/api/automation_logs?automation_id=${selectedAutomation.id}&page=${page}&size=${pageSize}${
        searchText ? `&automation_log_id=${searchText}` : ''
      }`,
    );
    setSelectedPage(page);
    if (merge) {
      setAutomationLogs([...automationLogs, ...data.content]);
    } else {
      setAutomationLogs([...data.content]);
    }
    setTotalPages(data.total_pages);
    setHasMoreData(!data.last);
    setLoading(false);
  };

  const fetchMoreData = () => {
    fetchAutomationLogs(selectedPage + 1, true);
  };

  useEffect(() => {
    if (currentSearchText.current !== debouncedSearchText) {
      fetchAutomationLogs(0, false);
      currentSearchText.current = debouncedSearchText;
    }
  }, [debouncedSearchText]);

  useEffect(() => {
    fetchAutomationLogs(0, false);
  }, []);

  const handleBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFocusedInput(false);
    }
  };

  return (
    <div className="data-matches">
      <div className="search-container" style={{ paddingBottom: '0px' }}>
        <div
          className={`integration-search logs-search ${focusedInput ? 'search-focused' : ''}`}
          onBlur={e => handleBlur(e)}
          onFocus={() => setFocusedInput(true)}>
          <SearchIcon className="search-icon" />
          <input
            className="regular-text"
            onChange={e => setSearchText(e.target.value)}
            placeholder="Search"
            value={searchText || ''}
          />
          {searchText && (
            <div
              className="flex items-center close-icon cursor"
              onClick={() => {
                setSearchText('');
              }}>
              <CloseIcon style={{ width: 16, height: 16 }} />
            </div>
          )}
        </div>
      </div>

      <div className="main-container ">
        <>
          <div className="automations-logs-header mt-3">
            {header.map((text, i) => (
              <div key={i} className="flex automations-logs-item items-center justify-center">
                <label className="medium-text grey-text font-12">{text}</label>
              </div>
            ))}
          </div>
          {loading ? (
            <div className="flex items-center justify-center my-8">
              <img alt="loading" height="40px" src={Loading} />
            </div>
          ) : (
            <>
              {automationLogs?.length > 0 ? (
                <div className="scroller" id="scrollableDiv">
                  <InfiniteScroll
                    dataLength={automationLogs.length}
                    hasMore={hasMoreData}
                    loader={
                      <div className="item flex items-center justify-center">
                        <img alt="loading" height="32px" src={Loading} />
                      </div>
                    }
                    next={fetchMoreData}
                    scrollableTarget="scrollableDiv"
                    style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    {automationLogs?.map((automationLog, i) => (
                      <>
                        <AutomationLogItem
                          key={automationLog.id}
                          automationLog={automationLog}
                          setSelectedAutomationLog={setSelectedAutomationLog}
                        />
                        <hr />
                      </>
                    ))}
                  </InfiniteScroll>
                </div>
              ) : (
                <AutomationContentWrapper>
                  <div className="automation-logs">
                    <img alt="automation-logs image" src={EmptyNotesIcon} />
                    <label className="medium-text font-18 mt-4">There are no logs</label>
                  </div>
                </AutomationContentWrapper>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
}

export default SelectedAutomationLogs;
