import { createSlice } from "@reduxjs/toolkit";
import api from '../../store/services'
import uuid from "react-uuid";
import { addToast } from "./toastSlice";

export const retirementSlice = createSlice({
    name: "retirement",
    initialState: {
      retirements: {},
      retirement: {},
      loading: true,
      retirementTransactions: {},
      loadingTransactions: true,
      showRetirementTransactions: ''
    },
    reducers: {
      setRetirements: (state, { payload }) => {
        state.retirements = payload;
      },
      setRetirement: (state, { payload }) => {
        state.retirement = payload;
      },
      setLoading: (state, { payload }) => {
        state.loading = payload;
      },
      setLoadingTransactions: (state, { payload }) => {
        state.loadingTransactions = payload;
      },
      setRetirementTransactions: (state, { payload }) => {
        state.retirementTransactions = payload;
      },
      setMergeRetirementTransactions: (state, { payload }) => {
        state.retirementTransactions = {...payload, content: [...state.retirementTransactions.content, ...payload.content]};
      },
      setShowRetirementTransactions: (state, { payload }) => {
        state.showRetirementTransactions = payload;
      },
    }
  });

  export const getRetirements = (payload) => async (dispatch) => {
    var url = `/v3/api/backoffice/retirements?page=${payload.page}&size=${payload.size || 8}`
    url += `&${payload.filter}`
    if(payload.pending)
      url += `&pending=${payload.pending}`
    const { data } = await api.get(url)
    dispatch(setRetirements(data));
  };

  export const getRetirementTransactions = (payload) => async (dispatch) => {
    dispatch(setLoadingTransactions(true))
    var url = `/v3/api/backoffice/retirements/${payload.id}/transactions?page=${payload.page}&size=${payload.size || 8}`
    const { data } = await api.get(url)
    if (payload.merge) {
      dispatch(setMergeRetirementTransactions(data));
    } else {
      dispatch(setRetirementTransactions(data));
    }
    dispatch(setLoadingTransactions(false))
  };

export const getRetirement = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await api.get(`/v3/api/backoffice/retirements/${payload}`)
  dispatch(setRetirement(data));
  dispatch(setLoading(false));
};

export const updateRetirement = (payload) => async (dispatch) => {
  try {
    const { data } = await api.put(`/v3/api/backoffice/retirements/${payload.id}`, payload.retirement)
    dispatch(setRetirement(data));
    dispatch(
      addToast({
        error: false,
        title: 'Retirement updated',
        text: `Retirement has been updated`,
        id: uuid(),
      }),
    );
  } catch (e) {
    dispatch(
      addToast({
        error: true,
        text: 'Error while updating retirement',
        id: uuid(),
      }),
    );
  }
};


export const { setRetirements, setRetirement, setLoading, setRetirementTransactions, setLoadingTransactions, setMergeRetirementTransactions, setShowRetirementTransactions } = retirementSlice.actions;
export default retirementSlice.reducer;