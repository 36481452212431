import React, { useEffect, useState } from "react";

import moment from "moment";
import Header from "../../header";

import './style.scss'



const InventoryDetailsComp = ({inventory, menuItems, onMenuSelect,}) => {
    return(
        <div className='flex-column' >
            <div className="border-bottom pb-3">
                <Header title="Details"
                        isSearch={false}
                        isPaged={false}
                        size='small'
                        isAddBtn={false}
                        isEditBtn={false}
                        edit={false}
                        menuItems={menuItems}
                        onMenuItemClick={onMenuSelect}
                    />
            </div>
            <div className="flex-column  mxy-6">
                <div className="flex items-center">
                    <label className="regular-text light-purple-text">Serial number:</label>
                    <label className="regular-text font-16" style={{marginLeft: '20px'}}>{inventory?.serial_number}</label>
                </div>
                <div className="flex items-center mt-4">
                    <label className="regular-text light-purple-text">Supplier:</label>
                    <label className="regular-text font-16" style={{marginLeft: '55px'}}>{inventory?.supplier?.name}</label>
                </div>
                <div className="flex items-center mt-4">
                    <div className="flex items-center flex-1">
                        <label className="regular-text light-purple-text">Purchased:</label>
                        <label className="regular-text font-16" style={{marginLeft: '42px'}}>{moment.unix(inventory.purchase_date).format("DD/MM/YYYY")}</label>
                    </div>
                    <div className="flex items-center flex-1">
                        <label className="regular-text light-purple-text ml-4">Cost:</label>
                        <label className="regular-text font-16" style={{marginLeft: '20px'}}>{inventory?.costs?.purchase_price} {inventory?.costs?.currency}</label>
                    </div>
                </div>
                <div className="flex items-center mt-4">
                    <div className="flex items-center flex-1">
                        <label className="regular-text light-purple-text">Sell price:</label>
                        <label className="regular-text font-16" style={{marginLeft: '50px'}}>{inventory.sale?.amount} {inventory.sale?.currency}</label>
                    </div>
                    <div className="flex items-center flex-1">
                        <label className="regular-text light-purple-text ml-4">RRP:</label>
                        <label className="regular-text font-16" style={{marginLeft: '20px'}}>{inventory?.costs?.purchase_price} {inventory?.costs?.currency}</label>
                    </div>
                </div>
                <div className="flex items-center mt-4">
                    <div className="flex items-center flex-1">
                        <label className="regular-text light-purple-text">Consigned:</label>
                        <label className="regular-text  font-16" style={{marginLeft: '42px'}}>{inventory?.consignments?.open_co2_balance/1000} Tons</label>
                    </div>
                    <div className="flex items-center flex-1">
                        <label className="regular-text light-purple-text ml-4">Consigned balance:</label>
                        <label className="regular-text font-16" style={{marginLeft: '20px'}}>{inventory?.consignments?.current_co2_balance/1000} Tons</label>
                    </div>
                </div>
                {/* <div className="flex items-center mt-4">
                    <div className="flex items-center flex-1">
                        <label className="regular-text light-purple-text">Primary account:</label>
                        <label className="regular-text  font-16" style={{marginLeft: '8px'}}>{inventory?.primary_account?.name}</label>
                    </div>
                    <div className="flex items-center flex-1">
                        <label className="regular-text light-purple-text ml-4">Sub account:</label>
                        <label className="regular-text font-16" style={{marginLeft: '20px'}}>{inventory?.sub_account?.name}</label>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default InventoryDetailsComp;