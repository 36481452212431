import ArrowRightIcon from '../../assets/images/menu/arrow-right-black.svg';
import SecuritySettingsIcon from '../../assets/images/security-settings.svg';
import Users from '../../components/settings/users/users';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Teams from '../../components/settings/teams/teams';
import { OrganisationContext } from '../../context/organisationContext';
import { setSelectedTeam, setSelectedUser } from '../../store/features/settingsSlice';
import SettingsWrapper from './settings.styled';

const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedTeam, selectedUser } = useSelector(state => state.settings);
  const { checkPermission, hasPermission, checkMultiplePermission, hasMultiplePermission } = useContext(OrganisationContext);

  const types = [
    {
      id: 0,
      name: 'Security',
      description: 'Add new users, change their info and roles',
      icon: SecuritySettingsIcon,
      path: `/settings/security`,
    },
  ];

  const securityTypes = [
    {
      id: 0,
      name: 'Users',
      description: 'Manage your users',
      path: `/settings/security/users`,
      permission: 'USERS_VIEW',
    },
    {
      id: 1,
      name: 'Teams',
      description: 'Manage your teams',
      path: `/settings/security/teams`,
      permission: 'TEAMS_VIEW',
    },
  ];

  return (
    <SettingsWrapper>
      <div className="settings">
        <div className="settings-top">
          <div className="settings-navigation">
            <label
              className={location.pathname !== '/settings' && 'hover-underline'}
              onClick={() => navigate('/settings')}
              style={{ opacity: location.pathname === '/settings' ? '1' : '0.3' }}>
              Settings
            </label>
            {location.pathname.includes(`/security`) && <img alt="icon" src={ArrowRightIcon} />}
            {location.pathname.includes(`/security`) && (
              <>
                <label
                  className={location.pathname !== '/settings/security' && 'hover-underline'}
                  onClick={() => navigate('/settings/security')}
                  style={{ opacity: location.pathname === '/settings/security' ? '1' : '0.3' }}>
                  Security
                </label>
              </>
            )}
            {(location.pathname.includes(`/security/users`) || location.pathname.includes(`/security/teams`)) && (
              <img alt="icon" src={ArrowRightIcon} />
            )}
            {location.pathname.includes(`/security/users`) && (
              <>
                <label
                  className={selectedUser.id && 'hover-underline'}
                  onClick={() => {
                    dispatch(setSelectedUser({}));
                    localStorage.setItem('selected-user', JSON.stringify({}));
                  }}
                  style={{ opacity: !selectedUser.id ? '1' : '0.3' }}>
                  Users
                </label>
                {selectedUser.id && (
                  <>
                    <img alt="icon" src={ArrowRightIcon} />
                    <label>{selectedUser.name}</label>
                  </>
                )}
              </>
            )}
            {location.pathname.includes(`/security/teams`) && (
              <>
                <label
                  className={selectedTeam.id && 'hover-underline'}
                  onClick={() => {
                    dispatch(setSelectedUser({}));
                    dispatch(setSelectedTeam({}));
                    localStorage.setItem('selected-team', JSON.stringify({}));
                    localStorage.setItem('selected-user', JSON.stringify({}));
                  }}
                  style={{ opacity: !selectedTeam.id ? '1' : '0.3' }}>
                  Teams
                </label>
                {selectedTeam.id && (
                  <>
                    <>
                      <img alt="icon" src={ArrowRightIcon} />
                      <label
                        className={selectedUser.id && 'hover-underline'}
                        onClick={() => {
                          dispatch(setSelectedUser({}));
                          localStorage.setItem('selected-user', JSON.stringify({}));
                        }}
                        style={{ opacity: !selectedUser.id ? '1' : '0.3' }}>
                        {selectedTeam.name}
                      </label>
                    </>
                    <>
                      {selectedUser.id && (
                        <>
                          <img alt="icon" src={ArrowRightIcon} />
                          <label>{selectedUser.name}</label>
                        </>
                      )}
                    </>
                  </>
                )}
              </>
            )}
            {location.pathname.includes(`/datamanagement`) && <img alt="icon" src={ArrowRightIcon} />}
            {location.pathname.includes(`/datamanagement`) && (
              <label
                className={location.pathname !== '/settings/datamanagement' && 'hover-underline'}
                onClick={() => navigate('/settings/datamanagement')}
                style={{ opacity: location.pathname === '/settings/datamanagement' ? '1' : '0.3' }}>
                Data management
              </label>
            )}
            {location.pathname.includes(`/datamanagement/datacapture`) && <img alt="icon" src={ArrowRightIcon} />}
            {location.pathname.includes(`/datamanagement/datacapture`) && (
              <label
                className={location.pathname !== '/settings/datamanagement/datacapture' && 'hover-underline'}
                onClick={() => navigate('/settings/datamanagement')}
                style={{ opacity: location.pathname === '/settings/datamanagement/datacapture' ? '1' : '0.3' }}>
                Data capture
              </label>
            )}
            {location.pathname.includes(`/datamanagement/datacapture/surveys`) && (
              <img alt="icon" src={ArrowRightIcon} />
            )}
            {location.pathname.includes(`/datamanagement/datacapture/surveys`) && (
              <label
                className={location.pathname.includes(`/datamanagement/datacapture/surveys/`) && 'hover-underline'}
                onClick={() => navigate('/settings/datamanagement/datacapture/surveys')}
                style={{
                  opacity: location.pathname.includes(`/datamanagement/datacapture/surveys/`) ? '0.3' : '1',
                }}>
                Surveys
              </label>
            )}
          </div>
        </div>
        <Routes>
          <Route element={<Users />} path={`/security/users`} />
          <Route element={<Teams />} path={`/security/teams`} />
          <Route path={`/financials`}></Route>
          <Route
            element={
              <div className="security-settings">
                <div className="st-content">
                  <img alt="icon" className="st-icon" src={SecuritySettingsIcon} />
                  <div className="st-details">
                    <label className="std-name">Users and Teams</label>
                    <label className="std-description">Manage your ogranisations users and teams</label>
                  </div>
                </div>
                <div className="ss-types">
                  {securityTypes.map(type => (
                    <div
                      key={type.id}
                      className="ss-type"
                      onClick={() => checkPermission(() => navigate(type.path), type.permission)}
                      style={{ opacity: hasPermission(type.permission) ? 1 : 0.5 }}>
                      <div className="st-content">
                        <div className="st-details">
                          <label className="std-name">{type.name}</label>
                          <label className="std-description">{type.description}</label>
                        </div>
                      </div>
                      <img alt="icon" className="st-arrow" src={ArrowRightIcon} />
                    </div>
                  ))}
                </div>
              </div>
            }
            path={`/security`}
          />
          <Route
            element={
              <div className="settings-types">
                {types.map(type => (
                  <div key={type.id} className="settings-type">
                    <div
                      className="settings-item"
                      onClick={() => checkMultiplePermission(() => navigate(type.path), type.permission)}
                      style={{ opacity: hasMultiplePermission(type.permission) ? 1 : 0.5 }}>
                      <div className="st-content">
                        <img alt="icon" className="st-icon" src={type.icon} />
                        <div className="st-details">
                          <label className="std-name">{type.name}</label>
                          <label className="std-description">{type.description}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }
            path={``}
          />
        </Routes>
      </div>
    </SettingsWrapper>
  );
};

export default Settings;
