import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import Button from '../../components/Elements/button/button';

const defaultProps = {
  modalClassName: '',
  onConfirm: () => {},
  onCancel: () => {},
  disabled: false,
  cancelButtonProps: {
    label: 'Cancel',
    size: 'medium',
    borderRadius: '16px',
    width: '100%',
    className: 'secondary',
    bgColor: '#FFFFFF',
    borderColor: '#24CBB1',
    color: '#24CBB1',
  },
  confirmButtonProps: {
    label: 'Confirm',
    size: 'medium',
    borderRadius: '16px',
    width: '100%',
    className: 'primary',
    bgColor: '#24CBB1',
    borderColor: '#24CBB1',
    color: '#FFFFFF'
  },
  popupTitle: '',
  popupSubTitle: '',
};

const CommonPopup = ({
  modalClassName = defaultProps.modalClassName,
  children,
  disabled = defaultProps.disabled,
  confirmDisabled = false,
  onConfirm = defaultProps.onConfirm,
  onCancel = defaultProps.onCancel,
  cancelButtonProps = defaultProps.cancelButtonProps,
  confirmButtonProps = defaultProps.confirmButtonProps,
  popupTitle = defaultProps.popupTitle,
  popupSubTitle = defaultProps.popupSubTitle,
}) => {
  const cancelButtonStyleProps = { ...defaultProps.cancelButtonProps, ...cancelButtonProps };
  const confirmButtonStyleProps = { ...defaultProps.confirmButtonProps, ...confirmButtonProps };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <CommonPopupWrapper className={modalClassName}>
        <label className="bold-text font-24 text-center mb-2">{popupTitle}</label>
        {popupSubTitle && <label className="regular-text font-14 text-center my-2 grey-text">{popupSubTitle}</label>}
        {children}
        <div className="flex justify-center w-full mt-10 col-gap-6">
          <Button {...cancelButtonStyleProps} size='large' disabled={disabled} onClick={onCancel} />
          <Button {...confirmButtonStyleProps} size='large' disabled={disabled || confirmDisabled} onClick={onConfirm} />
        </div>
      </CommonPopupWrapper>
    </CSSTransition>
  );
};

const CommonPopupWrapper = styled.div`
  width: 464px;
  padding: 40px 48px 48px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default CommonPopup;
