import React, { useState, useRef, useEffect } from "react";

import ArrowDownIcon from '../../assets/images/menu/arrow-down-black.svg';
import Loading from '../../assets/images/loading.svg';
import moment from "moment";

import './style.scss';

const CarbonDropDown = ({options, selected, setSelected, placeholder, isSearchable, search, setSearch, isPaged, hasMore, fetchData, size='medium', description }) => {
    const ref = useRef();
    const loader = useRef(null);

    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState(false);

    const itemHeight = 120;

    const onBlur = e => {
        e.preventDefault();
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setShow(false);  
          setEdit(false);
        }
    };

    const onClick = (edit, show) => {
        setEdit(edit);
        setShow(show);
    }; 

    const handleObserver = entities => {
        const target = entities[0];
        if (target.isIntersecting) {
          if (hasMore) fetchData();
        }
      };

      useEffect(() => {
        if (!isPaged) return;
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0.5,
        };
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
          observer.observe(loader.current);
        }
        return () => {
          if (loader.current) {
            observer.unobserve(loader.current);
          }
        };
      }, [loader.current, isPaged]);

      const select = s => {
        setEdit(false);
        setShow(false);
        setSelected(s);
        if(isSearchable)
            setSearch('');
    }

    return(
        <div className="flex carbon-mc-select mc-select relative no-select mc-select" id="of-id" onBlur={onBlur} tabIndex={0} style={{}}>
            <div className="flex items-center justify-between organisations-border mc-border flex-1 cursor pl-4" 
                    ref={ref} 
                    style={{height: size === 'large' ? '48px' : '32px', borderColor: show ? '#96F1E3' : 'rgba(181, 185, 193, 0.3)', borderWidth: size === 'large' ? '1.5px' : '1px', borderRadius: size === 'large' ? '16px' : '12px'}} 
                    onClick={() => isSearchable ? onClick(true, true) : setShow(!show)} >
                { isSearchable && edit ?
                    <div className="flex items-center py-2" onClick={() => setShow(true)}>
                        <input className="org-name-input" autoFocus value={search} onChange={e => setSearch(e.target.value)} />
                    </div>
                :
                selected ? <label className={`regular-text carbon-selected-name flex-1 ${size === 'large' && 'font-16'}`}>{selected.name || selected.serial_number}</label> : <label className={`regular-text o03  ${size === 'large' && 'font-16'}`}>{placeholder}</label> }
                <div className="input-arrow"  onClick={() => onClick(false, !show)}>
                    <img alt="icon" className={`dropdown-icon ${show && 'open'} cursor`} src={ArrowDownIcon} />
                </div>            
            </div>
            <div className="mc-options absolute flex-column card px-2" style={{height: show ? options?.length * itemHeight + 16 : 0, top: size === 'large' ? '54px' : '38px'}}>
                <div className="flex-column py-2">  
                    {options?.map((option, i) => 
                        <div 
                            key={option.id} 
                            className="flex items-center justify-between px-2 cursor carbon-option-item" 
                            onClick={() => select(option)}
                            style={{height: `${itemHeight}px`}}
                            >
                              <div className="flex-column flex-1">
                                <label className="regular-text light-purple-text">Name:<span className="ml-2 medium-text">{option?.name || '-'}</span></label>
                                <label className="regular-text light-purple-text mt-1">Serial:<span className="ml-2 medium-text">{option?.serial_number || '-'}</span></label>
                                <label className="regular-text light-purple-text mt-1">Vintage:<span className="ml-2 medium-text">{moment.unix(option.vintage?.vintage_from).format('DD/MM/YYYY')}-{moment.unix(option.vintage?.vintage_to).format('DD/MM/YYYY')}</span></label>
                                <div className="flex items-center mt-1">
                                  <label className="flex-1 regular-text light-purple-text">Owner:<span className="ml-2 medium-text">{option?.owner?.name || '-'}</span></label>
                                  {option.consigned_to?.name && <label className="flex-1 regular-text light-purple-text">Consigned:<span className="ml-2 medium-text">{option?.consigned_to?.name || '-'}</span></label> }
                                </div>
                                <div className="flex items-center mt-1">
                                  <label className="flex-1 regular-text light-purple-text">Account:<span className="ml-2 medium-text">{option?.primary_account?.name || '-'}</span></label>
                                  <label className="flex-1 regular-text light-purple-text">Sub Account:<span className="ml-2 medium-text">{option?.sub_account?.name || '-'}</span></label>
                                </div>
                              </div>
                              <div className="flex-column flex-1 items-end">
                                  <div className="flex-column items-center justify-center carbon-dd-blocks">
                                    <label className="bold-text">{option.carbon?.current_balance / 1000}</label>
                                    <label className="regular-text font-12 mt-1">tonnes</label>
                                  </div>
                              </div>
                        </div>
                    )}
                    {isPaged && hasMore && (
                        <div ref={loader} className="item flex items-center justify-center my-2">
                            <img src={Loading}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CarbonDropDown;