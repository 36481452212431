import styled from 'styled-components';

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  position: relative;

  .dropdown-selected * {
    cursor: pointer;
  }

  .dropdown-selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1.5px solid rgba(181, 185, 193, 0.3);
    border-radius: 12px;
    min-width: 196px;
    height: 40px;
    cursor: pointer;

    .placeholder-text {
      color: rgba(5, 49, 73, 0.5);
    }

    label {
      margin-left: 7px;
    }

    .arrow-down {
      transition: transform 400ms;
    }

    .arrow-up {
      transform: rotate(180deg);
    }
  }

  .dd-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 40px;
    background: #ffffff;
    box-shadow: 2px 10px 30px rgba(5, 49, 73, 0.16);
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    z-index: 11;

    .dd-option * {
      cursor: pointer;
    }

    .dd-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 40px;
      cursor: pointer;
      margin: 0 8px;

      label {
        margin: 0 8px;
      }
    }

    .dd-option:hover {
      background: #f4f9fb;
      border-radius: 4px;
    }

    .dd-option-selected {
      label {
        font-family: Roboto Bold;
      }
    }

    .dd-action * {
      cursor: pointer;
    }

    .dd-action {
      border-top: 1px solid #e5e5e5;
      padding-left: 16px;
      padding-top: 16px;
      margin-top: 8px;
      cursor: pointer;
    }
  }
`;

export default DropdownWrapper;
