import { createSlice } from "@reduxjs/toolkit";
import api from '../../store/services'

export const registrySlice = createSlice({
    name: "registry",
    initialState: {
      registries: [],
    },
    reducers: {
      setRegistries: (state, { payload }) => {
          state.registries = payload;
      },
      addRegistry: (state, { payload }) => {
        state.registries = [...state.registries, payload];
    },
    }
  });

export const getRegistries = (payload) => async (dispatch) => {
  const { data } = await api.get(`/v3/api/backoffice/registry`)
  dispatch(setRegistries(data));
};

export const createRegistry = (payload) => async (dispatch) => {
  const { data } = await api.post(`/v3/api/backoffice/registry`, payload)
  dispatch(addRegistry(data));
};

export const { setRegistries, addRegistry } = registrySlice.actions;
export default registrySlice.reducer;