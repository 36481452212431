import React from 'react'
import moment from "moment";
import { useNavigate } from 'react-router-dom';

import CopyIcon from '../../assets/images/copy-green.svg'
import OpenIcon from '../../assets/images/open-green.svg'


import './style.scss'

const RetirementCard = ({ retirement }) => {
    const navigate = useNavigate();

    return (
        <div  onClick={() => navigate(`/carbon/retirements/${retirement.id}`)}>
            <div className={"ret-grid ret-card cursor border-top cursor"}>
                <label className='medium-text px-6 py-3'>{moment.unix(retirement.retirement_date).format('DD/MM/YYYY HH:mm')}</label>
                <label className='medium-text px-6 py-3'>{retirement.blocks?.units / 1000}</label>
                <label className='medium-text px-6 py-3'>{retirement?.project.name}</label>
                <label className='medium-text px-6 py-3'>{retirement.registry.name}</label>
                <label className='medium-text px-6 py-3'>{retirement.beneficiary ? retirement.beneficiary : retirement.contact?.id ? retirement.contact.business_name ? retirement.contact.business_name  : retirement.contact.name  : retirement.beneficiary_account.name}</label>
                <label className='medium-text px-6 py-3'>
                    <div className='flex items-center justify-center retirement-state px-2' style={{background: retirement.is_pending ? '#FBBF66' : '#5CCFF3'}}>
                        <label className='medium-text white-text font-10'>{retirement.is_pending ? 'PENDING' : 'RETIRED'}</label>
                    </div>
                </label>
            </div>
        </div>
    )
}

export default RetirementCard;
