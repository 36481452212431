import instance from './index'

import axios from 'axios'

import { setUser } from '../features/userSlice'

const setUpInterceptor = store => {
    instance.interceptors.request.use(async function (config) {
        try {
            config.headers.Authorization = 'Bearer ' + await getAccessToken(store)
        } catch (e) {
            localStorage.removeItem('user')
            window.location.reload()
        }
        return config;
    }, function (error) {
        return Promise.reject(error)
    })
}

const getAccessToken = async store => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user.exp - 10000 < new Date().getTime()) {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v1/authentication/refresh?refresh_token=${user.refresh_token}`, {})
        const newUser = {...user, access_token: data.access_token, refresh_token: data.refresh_token, exp: data.exp}
        localStorage.setItem("user", JSON.stringify(newUser));
        store.dispatch(setUser(newUser))
        return data.access_token
    } else
        return user.access_token
}

export default setUpInterceptor

