import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getProjects } from "../../../store/features/projectSlice";
import { getCollectionTemplates } from "../../../store/features/collectionSlice";
import { addToast } from "../../../store/features/toastSlice";
import Image from "../../image";

import uuid from "react-uuid";

import { saveCampaignProjects, updateCampaignProjects } from "../../../store/features/campaignSlice";

import DropDown from "../../McDropdown";


import './style.scss'

const AddCampaignProject = ({campaign, onCancel, editProject = null }) => {
    const dispatch = useDispatch();
    const pageRef = useRef(null);

    const { projects } = useSelector(data => data.project);
    const { campaignProjects } = useSelector(data => data.campaign);
    const { collectionTemplates } = useSelector(data => data.collection);

    const [search, setSearch] = useState('');
    const [campaignProject, setCampaignProject] = useState(editProject ? {...editProject} : null)

    const fetchProjects = (page, merge) => {
        pageRef.current = page;
        dispatch(getProjects({page: page, size: 10, search, merge}));
    };

    useEffect(() => {
        fetchProjects(0, false);
    }, [search])


    useEffect(() => {
        dispatch(getCollectionTemplates({collectionId: campaign.collection?.id, search: ''}))
    }, [campaign])

    useEffect(() => {
        setCampaignProject(editProject ? {...editProject} : null)
    }, [editProject])

    const onSave = () => {
        if(!campaignProject.project) {
            dispatch(
                addToast({
                  error: true,
                  text: `Please select a project`,
                  id: uuid(),
                }),
              );
        } else  if(!campaignProject.template) {
            dispatch(
                addToast({
                  error: true,
                  text: `Please select a template`,
                  id: uuid(),
                }),
              ); 
        } else {
            if (editProject) {
                dispatch(updateCampaignProjects({project_id: campaignProject.id, campaignProject: {project: {id: campaignProject.project.id}, template: {id: campaignProject?.template.id}, nft: campaignProject?.nft ? {id: campaignProject?.nft?.id} : null}}))
                dispatch(
                   addToast({
                    error: false,
                    title: 'Project updated',
                    text: `${campaignProject.project.name} has been updated`,
                    id: uuid(),
                    }),
                );
            } else {
                dispatch(saveCampaignProjects({campaignId: campaign.id, campaignProject: {project: {id: campaignProject.project.id}, template: {id: campaignProject?.template.id}}}))
                dispatch(
                    addToast({
                    error: false,
                    title: 'Project created',
                    text: `${campaignProject.project.name} has been created`,
                    id: uuid(),
                    }),
                );  
            }
            onCancel();
        }
    }

    return (
        <div className="flex-column pxy-6 border-bottom">
            <div className="flex items-center">
                <DropDown 
                    options={projects.content?.filter(p => campaignProject?.project?.id === p.id || !campaignProjects.find(cp => cp.project?.id === p?.id))} 
                    selected={campaignProject?.project} 
                    setSelected={p => setCampaignProject({ project: p })} 
                    description={true}
                    placeholder="Select project"
                    isSearchable={true}
                    search={search}
                    setSearch={setSearch}
                    isPaged={true}
                    hasMore={!projects.last}    
                    fetchData={() => fetchProjects(pageRef.current + 1, true)}   
                />
                <div className='acp-actions ml-8'>
                    <button onClick={onCancel}>Cancel</button>
                    <button className='save-action' onClick={onSave}>Save</button>
                </div>
            </div>
            {campaignProject && 
            <div className="flex mt-6">
                <Image className={"add-campaign-project-image my-2"} size={64} media={campaignProject?.project?.image?.media} />
                <div className="flex-column ml-4 flex-1 mt-3">
                    <div className="flex items-center justify-between ">
                        <label className="bold-text">{campaignProject?.project?.name}</label>
                        <div className="flex items-center">
                            <div className="flex items-center justify-center px-2 cp-country ml-4">
                                <label className="bold-text font-12 one-line" style={{color: campaignProject.added ? '#AABAB7' : ''}}>{campaignProject?.project?.location?.country}</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label className="regular-text font-12 light-purple-text mt-2 two-lines campaign-project-description mb-2">{campaignProject?.project?.description}</label>
                        <div className="campaign-template-dd">
                            <DropDown 
                                options={collectionTemplates} 
                                selected={campaignProject.template} 
                                setSelected={t => setCampaignProject({...campaignProject, template: t })} 
                                placeholder="Select template"
                            />
                        </div>
                    </div>
               
                </div>
            </div>
            }
        </div>
    )
}

export default AddCampaignProject;