import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getTransactions,
  setTransactions,
} from "../../store/features/transactionSlice";
import { getProjects } from "../../store/features/projectSlice";
import { getCampaigns } from "../../store/features/campaignSlice";
import { useNavigate } from "react-router-dom";

import InfiniteScroll from "react-infinite-scroll-component";
import Header from "../../components/header";
import Filter from "../../components/filter";
import Loading from "../../assets/images/loading.svg";
import moment from "moment";
import TxDateSelectors from "../../components/widget/tx-date-selectors";
import TxFilter from "../../components/widget/tx-filter";
import SmallCheckbox from "../../components/widget/small-checkbox";
import AddTransactionPopup from "../../components/campaign/addTransactionPopup";

import SearchIcon from "../../assets/images/search.svg";
import { ReactComponent as RefreshIcon } from "../../assets/images/refresh-green.svg";
import CalendarIcon from "../../assets/images/tx-calendar-icon.svg";
import CalendarGreenIcon from "../../assets/images/tx-calendar-green-icon.svg";
import AddIcon from "../../assets/images/add.svg";
import OpenIcon from "../../assets/images/open-green.svg";

import "./styles.scss";

const Transactions = () => {
  const dispatch = useDispatch();
  const { transactions, loading } = useSelector((data) => data.transaction);
  const { projects } = useSelector((data) => data.project);
  const { campaigns } = useSelector((data) => data.campaign);

  const [search, setSearch] = useState("");
  const [projectSearch, setProjectSearch] = useState("");
  const [campaignSearch, setCampaignSearch] = useState("");

  const [showDateSelectors, setShowDateSelectors] = useState(false);
  const [dates, setDates] = useState({ startDate: null, endDate: null });

  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedCamapigns, setSelectedCampaigns] = useState([]);
  const [isPublished, setIsPublished] = useState(true);
  const [showTransactions, setShowTransactions] = useState();
  const [n, setN] = useState(0);
  const navigate = useNavigate();

  const [lifecycles, setLifecycles] = useState([
    { id: 1, name: "ACTIVE", color: "#24CBB1", selected: true },
    { id: 2, name: "NOT ACTIVE", color: "#FB3535", selected: false },
    { id: 0, name: "ALL", color: "#020819", selected: false },
  ]);

  const loadProjects = useCallback(
    async (page, merge) => {
      dispatch(
        getProjects({
          page: page,
          size: 20,
          search: projectSearch,
          merge: merge,
          isPublished,
        })
      );
    },
    [projectSearch, isPublished, dispatch]
  );

  useEffect(() => {
    loadProjects(0, false);
  }, [loadProjects]);

  const fetchMoreProjects = () => {
    loadProjects(projects?.number + 1, true);
  };

  const loadCampaigns = useCallback(
    async (page, merge) => {
      const active = lifecycles.find((l) => l.selected)?.name;
      dispatch(
        getCampaigns({
          page: page,
          size: 20,
          search: campaignSearch,
          merge: merge,
          active: active === "ALL" ? "" : active === "ACTIVE",
        })
      );
    },
    [campaignSearch, lifecycles, dispatch]
  );

  useEffect(() => {
    loadCampaigns(0, false);
  }, [loadCampaigns]);

  const fetchMoreCampaigns = () => {
    loadCampaigns(campaigns?.number + 1, true);
  };

  const loadTransactions = useCallback(
    async (page, merge) => {
      var filters = `transaction_date.start=${
        dates.startDate?.unix() || ""
      }&transaction_date.end=${dates.endDate?.unix() || ""}`;
      selectedProjects.forEach((p) => (filters += `&project_id=${p}`));
      selectedCamapigns.forEach((c) => (filters += `&campaign_id=${c}`));

      dispatch(
        getTransactions({
          page: page,
          size: 30,
          search,
          filter: filters,
          merge: merge,
        })
      );
    },
    [dates, search, selectedProjects, selectedCamapigns, dispatch]
  );

  useEffect(() => {
    loadTransactions(0, false);
  }, [loadTransactions, n]);

  const fetchMoreTransactions = () => {
    loadTransactions(transactions?.number + 1, true);
  };

  const setFrom = (from) => {
    if (from >= moment(dates.endDate).unix()) {
      return;
    }
    setDates({
      ...dates,
      startDate: moment.unix(from).startOf("day"),
      active: true,
    });
  };

  const setTo = (to) => {
    if (to <= moment(dates.startDate).unix()) {
      return;
    }
    setDates({
      ...dates,
      endDate: moment.unix(to).endOf("day"),
      active: true,
    });
  };

  const removeDates = () => {
    setDates({
      startDate: null,
      endDate: null,
      active: false,
    });
  };

  const reload = () => {
    dispatch(setTransactions({ content: [] }));
    loadTransactions(0, false);
  };

  return (
    <div className="flex-column mb-6 mt-2 flex-1">
      <div className="flex items-center justify-between mx-6 action-header no-select">
        <div className="flex items-center">
          <div className="flex items-center">
            <label className="bold-text mr-6 font-24">Transactions</label>
          </div>
        </div>
        <div className="flex items-center">
          <div
            className="flex items-center mr-8 cursor"
            onClick={() => {
              setSelectedCampaigns([]);
              setSelectedProjects([]);
            }}
          >
            <SmallCheckbox
              selected={
                selectedProjects.length === 0 && selectedCamapigns.length === 0
              }
            />
            <label className="medium-text ml-2">All</label>
          </div>
          <div className="mr-8">
            <TxFilter
              name="Projects"
              search={projectSearch}
              setSearch={setProjectSearch}
              fetchMore={fetchMoreProjects}
              options={projects}
              selected={selectedProjects}
              setSelected={setSelectedProjects}
              children={
                <div className="flex items-center mt-6 ml-2">
                  <div
                    className="flex items-center justify-center lifecycle-selector ml-2 cursor"
                    onClick={() => setIsPublished(!isPublished)}
                    style={{
                      background: isPublished
                        ? "#57DBD3"
                        : "rgb(170, 186, 183, 0.1)",
                    }}
                  >
                    <label
                      className="bold-text font-12"
                      style={{ color: isPublished ? "#FFFFFF" : "#020819" }}
                    >
                      PUBLISHED
                    </label>
                  </div>
                </div>
              }
            />
          </div>
          <div className="mr-6">
            <TxFilter
              name="Campaigns"
              search={campaignSearch}
              setSearch={setCampaignSearch}
              fetchMore={fetchMoreCampaigns}
              options={campaigns}
              selected={selectedCamapigns}
              setSelected={setSelectedCampaigns}
              children={
                <div className="flex items-center no-select mt-6 ml-2 mr-4">
                  {lifecycles?.map((lifecycle) => (
                    <div
                      key={lifecycle.id}
                      className="flex flex-1 items-center justify-center tx-lifecycle-selector ml-2 cursor"
                      onClick={() =>
                        setLifecycles(
                          lifecycles.map((l) =>
                            l.id === lifecycle.id
                              ? { ...l, selected: true }
                              : { ...l, selected: false }
                          )
                        )
                      }
                      style={{
                        background: lifecycle.selected
                          ? lifecycle.color
                          : "rgb(170, 186, 183, 0.1)",
                      }}
                    >
                      <label
                        className="bold-text font-10"
                        style={{
                          color: lifecycle.selected ? "#FFFFFF" : "#020819",
                        }}
                      >
                        {lifecycle.name}
                      </label>
                    </div>
                  ))}
                </div>
              }
            />
          </div>
          <div className="flex relative">
            <div
              className="flex items-center justify-center cursor tx-dates-btn"
              onClick={() => setShowDateSelectors(!showDateSelectors)}
            >
              <img
                src={
                  dates.active || showDateSelectors
                    ? CalendarGreenIcon
                    : CalendarIcon
                }
                alt=""
              />
            </div>
            {showDateSelectors && (
              <TxDateSelectors
                close={() => setShowDateSelectors(false)}
                from={dates.startDate?.unix()}
                removeDates={removeDates}
                setFrom={(from) => {
                  setFrom(from);
                }}
                setTo={(to) => {
                  setTo(to);
                }}
                to={dates.endDate?.unix()}
              />
            )}
          </div>
          <div
            className="flex items-center justify-center cursor tx-dates-btn ml-4"
            onClick={reload}
          >
            <RefreshIcon />
          </div>
          <div className="flex items-center search-input txs-search-input px-2 ml-4">
            <img src={SearchIcon} alt="search" className="ml-1" />
            <input
              placeholder="Search"
              className="ml-1 font-16"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div
            className="flex items-center justify-center add-btn cursor ml-4"
            style={{ height: "32px", width: "112px" }}
            onClick={() => setShowTransactions(true)}
          >
            <img src={AddIcon} alt="add" />
            <label className="bold-text font-14 violet-light-text ml-2">
              Add new
            </label>
          </div>
        </div>
      </div>
      <div className="flex-column card mx-6 mt-2">
        <div className="tx-grid tx-header border-top">
          <label className="font-12 semibold-text pr-2 pl-6 py-4 one-line">
            DATE & TIME
          </label>
          <label className="font-12 semibold-text px-2 py-4 one-line">
            AMOUNT
          </label>
          <label className="font-12 semibold-text px-2 py-4 one-line">
            VALUE
          </label>
          <label className="font-12 semibold-text px-2 py-4 one-line">
            REFERENCE
          </label>
          <label className="font-12 semibold-text px-2 py-4 one-line">
            CONTACT
          </label>
          <label className="font-12 semibold-text px-2 py-4 one-line">
            WALLET ALIAS
          </label>
          <label className="font-12 semibold-text px-2 py-4 one-line">
            PROJECT
          </label>
          <label className="font-12 semibold-text pr-6 pl-2 py-4 one-line">
            CAMPAIGN
          </label>
        </div>

        {transactions.content?.length > 0 ? (
          <div className="tx-list mb-2" id="scrollableDiv">
            <InfiniteScroll
              dataLength={transactions?.content?.length}
              hasMore={!transactions?.last}
              loader={
                <div className="item flex items-center justify-center my-2">
                  <img alt="loading" height="24px" src={Loading} />
                </div>
              }
              next={fetchMoreTransactions}
              scrollableTarget="scrollableDiv"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                overflow: "unset",
              }}
            >
              {transactions.content?.map((t) => (
                <div
                  className={"tx-grid tx-card cursor border-top cursor"}
                  key={t.id}
                >
                  <label className="medium-text pr-2 pl-6 py-3 one-line">
                    {moment.unix(t.transaction_date).format("DD/MM/YYYY HH:mm")}
                  </label>
                  <label className="medium-text px-2 py-3 one-line">
                    {t.co2_kg_units} kilos
                  </label>
                  <label className="medium-text px-2 py-3 one-line">
                    {t.base_currency?.amount} {t.base_currency?.currency}
                  </label>
                  <label className="medium-text px-2 py-3 one-line">
                    {t.reference}
                  </label>
                  <label className="medium-text px-2 py-3 one-line">
                    {t.contact
                      ? t.contact?.contact_type === "BUSINESS"
                        ? t.contact?.business_name
                        : t.contact?.forename + " " + t.contact?.surname
                      : "-"}
                  </label>
                  <label className="medium-text px-2 py-3 one-line">
                    {t.wallet?.alias}
                  </label>
                  <label className="medium-text px-2 py-3 one-line">
                    {t.project?.name}
                  </label>
                  <div className="flex ites-center justify-between">
                    <label className="medium-text pr-6 pl-2 py-3 one-line">
                      {t.campaign?.name}
                    </label>
                    {t.retirement && (
                      <img
                        src={OpenIcon}
                        alt="copy"
                        className="cursor mr-2"
                        onClick={() => {
                          window.open(t.retirement?.offset_url, "_blank");
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        ) : loading ? (
          <div className="item flex items-center justify-center my-2">
            <img alt="loading" height="24px" src={Loading} />
          </div>
        ) : (
          <div className="flex items-center justify-center flex-1 py-4">
            <label className="bold-text font-16 light-purple-text">
              There are no Transactions
            </label>
          </div>
        )}
      </div>
      {showTransactions && (
        <AddTransactionPopup
          onCancel={(refresh) => {
            setShowTransactions(null);
            if (refresh) setN(n + 1);
          }}
        />
      )}
    </div>
  );
};

export default Transactions;
