import React, { useState } from "react";
import './index.scss'

const TimeToggle = ({ isWhiteBg, selected = 'day', setSelected }) => {
    const times = ['day', 'month', 'year']

    return (
        <div className={`time-toggle no-select ${isWhiteBg && 'toggle-white'}`}>
            {times.map(item =>
                <span className={`time bold-text font-12 ${selected === item && 'selected'}`}
                      onClick={() => setSelected(item)}>{item}</span>)}
        </div>
    )
}

export default TimeToggle;
