import React, { useState } from "react";

import { ReactComponent as DotsIcon } from '../../assets/images/dots.svg'

import './style.scss'

const Menu = ({menuItems, onMenuItemClick, background}) => {
    const [showMenu, setShowMenu] = useState(false);

    return(
        <div className="flex items-center justify-center header-menu relative cursor" style={{background: showMenu  ? '#F6F6F6' : '', border: showMenu ? '1px solid rgba(181, 185, 193, 0.5)' : ''}} onClick={e => {e.stopPropagation(); setShowMenu(!showMenu);}} tabIndex={0} onBlur={() => setShowMenu(false)}>
            <DotsIcon className="cursor" width={20} /> 
            {  showMenu && <div className="header-menu-items flex-column card pxy-2 absolute">
                {menuItems.map((item, i) => 
                    <div key={i} className="header-menu-item flex items-center pxy-2 cursor" onClick={() => onMenuItemClick(item)}>
                        <label className="regular-text">{item.name}</label>
                    </div>
                    )}
            </div>
            }
    </div> 
    )
}

export default Menu;