import React, { useState, useEffect } from 'react'

import api from '../../store/services'

import CloseIcon from '../../assets/images/close.svg'
import { ReactComponent as ArrowRight } from '../../assets/images/menu/arrow-down.svg'

import '../../assets/css/components/popup/invite-user-popup.scss'

export default ({ organisationId, merchant, venues, close }) => {

    const [complete, setComplete] = useState(false)
    const [showRoles, setShowRoles] = useState(false)
    const [showVenues, setShowVenues] = useState(false)
    const [roles, setRoles] = useState([])
    const [invitedUser, setInvitedUser] = useState({})
    const [selectedRole, setSelectedRole] = useState({})
    const [selectedOrganisation, setSelectedOrganisation] = useState({})
    const [organisations, setOrganisations] = useState([])

    useEffect(() => {
        setOrganisations([merchant, ...venues])
        setSelectedOrganisation(merchant)
    }, [merchant, venues])

    useEffect(() => {
        setComplete(invitedUser.forename && invitedUser.forename.length > 2 &&
            invitedUser.surname && invitedUser.surname.length > 2 &&
            invitedUser.email && invitedUser.email.length > 2 &&
            invitedUser.password && invitedUser.password.length > 2)
    }, [invitedUser])

    useEffect(() => {
        const fetchData = async () => {
            const rolesResponse = await api.get(`/v3/api/roles`)
            setRoles(rolesResponse.data)
            const role = rolesResponse.data[0]
            setSelectedRole(role)
        }
        fetchData()
    }, [organisationId])

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    const save = async () => {
        invitedUser.role_external_id = selectedRole.external_id
        invitedUser.organisation_external_id = selectedOrganisation.external_id
        await api.post('/v3/api/user/invite', invitedUser)
        close()
    }

    return (
        <div className='invite-user-popup' onClick={onClick}>
            <div className='invite-user-popup-content'>
                <img className='popup-close-btn' src={CloseIcon} onClick={close} />
                <label className='popup-title'>Invite new user</label>
                <label className='input-label'>First Name</label>
                <input className='popup-input' placeholder='Enter first name' value={invitedUser.forename || ''} onChange={e => setInvitedUser({ ...invitedUser, forename: e.target.value })} />
                <label className='input-label'>Last Name</label>
                <input className='popup-input' placeholder='Enter last name' value={invitedUser.surname || ''} onChange={e => setInvitedUser({ ...invitedUser, surname: e.target.value })} />
                <label className='input-label'>Email</label>
                <input className='popup-input' placeholder='Type your email' value={invitedUser.email || ''} onChange={e => setInvitedUser({ ...invitedUser, email: e.target.value })} />
                <label className='input-label'>User's role</label>
                <div className='iu-dropdown' onClick={() => setShowRoles(!showRoles)} tabIndex='0' onBlur={() => setShowRoles(false)}>
                    <label>{selectedRole.name}</label>
                    <ArrowRight className={`arrow ${showRoles && 'arrow-up'}`} />
                    <div className='iu-options' style={{ height: showRoles && roles.length > 0 ? '120px' : 0 }}>
                        {
                            roles.map(role => <label key={role.external_id} onClick={() => setSelectedRole(role)}>{role.name}</label>)
                        }
                    </div>
                </div>
                <label className='input-label'>Venue</label>
                <div className='iu-dropdown' onClick={() => setShowVenues(!showVenues)} tabIndex='0' onBlur={() => setShowVenues(false)}>
                    <label>{selectedOrganisation.name}</label>
                    <ArrowRight className={`arrow ${showVenues && 'arrow-up'}`} />
                    <div className='iu-options' style={{ height: showVenues && organisations.length > 0 ? '120px' : 0 }}>
                        {
                            organisations.map(organisation => <label key={organisation.external_id} onClick={() => setSelectedOrganisation(organisation)}>{organisation.name}</label>)
                        }
                    </div>
                </div>
                <label className='input-label'>Invitation password</label>
                <input className='popup-input' type='password' placeholder='Create an invitation password' value={invitedUser.password || ''} onChange={e => setInvitedUser({ ...invitedUser, password: e.target.value })}  />
                <button className='popup-btn' style={{ background: complete ? '#169D8F' : 'rgba(9, 44, 64, 0.1)' }} onClick={save}>SEND INVITE</button>
            </div>
        </div>
    )
}