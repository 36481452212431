import styled from 'styled-components';

const AutomationLogItemWrapper = styled.div`
    .data-match-item {
      border-right: 1px solid #E3EEF3;
      line-height: 20px;

      .dmi-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 0 16px;
      }

      .status {
        border-radius: 4px;
        color: #FFFFFF;
        padding: 0 4px;
      }
    }

    .data-match-item:last-child {
      border: none;
      justify-content: center;
      padding-left: 16px
    }

    .menu-container {
      position: relative;
      outline: none;
      user-select: none;

      .menu {
        right: 45px;
      }
    }

    .menu-btn {
      height: 16px;
      width: 16px;
      cursor: pointer;
    }
  }
`;

export default AutomationLogItemWrapper;
