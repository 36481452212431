import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import Image from "../../image";
import { getNftLifecycleColor } from "../../../helpers/utils"; 

import CopyIcon from '../../../assets/images/copy-green.svg'
import Loading from "../../../assets/images/loading.svg";

import './style.scss'

const CampaignNftDetails = ({nft, loading}) => {

    return(
    <div className="flex-column pxy-6 campaign-nft-details">
        {loading ? 
        <div className="flex-column loading-nft-details">
            <div className="flex flex-1 items-center justify-center mt-4">
                <img src={Loading} alt="loading" />
                <label className="bold-text font-16 ml-2 light-purple-text">
                    Loading
                </label>
            </div>
        </div>
        :
        <div className="flex-column">
            <Image className={"campaign-nft-details-image"} size={306} media={nft?.image?.media} />
            <div className="flex items-center justify-between mt-6 hidden-action-container">
                <label className="regular-text font-14 one-line mr-6">{nft.id}</label>
                <img src={CopyIcon} alt="copy" className="cursor hidden-action" onClick={() => {navigator.clipboard.writeText(`https://carbon-creatures.com/offset/${nft.id}`)}} />
            </div>
            <label className="semibold-text font-16 mt-6">{nft.name}</label>
            <label className="regular-text light-purple-text mt-2">{nft.description}</label>
            <div className="flex items-center justify-between mt-6">
                <label className="regular-text light-purple-text">Nft type:</label>
                <label className="regular-text">{nft.nft_type?.toLowerCase().charAt(0).toUpperCase() + nft.nft_type?.toLowerCase().slice(1)}</label>
            </div>
            <div className="flex items-center justify-between mt-4">
                <label className="regular-text light-purple-text">Lifecycle:</label>
                <div className='nft-lifecycle flex items-center justify-center' style={{background: getNftLifecycleColor(nft.lifecycle)}}>
                    <label className='medium-text font-10 white-text'>{nft.lifecycle}</label>
                </div>                        </div>
            <div className="flex items-center justify-between mt-4">
                <label className="regular-text light-purple-text">Created:</label>
                <label className="regular-text">{moment.unix(nft.created_date).format('DD/MM/YYYY')} {moment.unix(nft.created_date).format('HH:mm:ss')}</label>
            </div>
            {nft.nft_type !== 'RETIREMENT' &&
            <div className="flex items-center justify-between mt-4">
                <label className="regular-text light-purple-text">Tier:</label>
                {nft.tier && <div className="flex items-center">
                    <div className="tier-color" style={{background: nft.tier?.color_code}} />
                    <label className="regular-text ml-2">{nft.tier?.name}</label>
                </div>}                     
            </div>}
            <div className="flex items-center justify-between mt-4">
                <label className="regular-text light-purple-text">Template:</label>
                <label className="regular-text">{nft.template?.name}</label>
            </div>
        </div>  
        }
    </div>
    )
}

export default CampaignNftDetails