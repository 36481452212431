import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../store/features/toastSlice';
import uuid from 'react-uuid';
import { ReactComponent as EmailIcon } from '../../../assets/images/email.svg';
import { ReactComponent as DotsIcon } from '../../../assets/images/menu/dots.svg';
import { ReactComponent as MobileIcon } from '../../../assets/images/mobile.svg';
import { useWindowSize } from '../../../hooks/useWindowSize';
import Menu from './menu';
import { useRef } from 'react';

const UserItem = ({
  user,
  changeActive,
  testKey,
  onEditUser,
  changeDeleteUser,
  isTeamUsers,
  setSelectedUser,
  selectedTeam,
  checkPermission,
  hasPermission,
  changeUserRole,
  onInviteUser,
  onAvailibilityUser,
}) => {
  const dispatch = useDispatch();

  const [showMenu, setShowMenu] = useState(false);

  const organisations =
    user.organisations
      ?.filter(org => org.organisation_type === 'TEAM')
      ?.filter(o => (isTeamUsers ? o.id === selectedTeam.id : true)) || [];

  const menuClick = action => {
    setShowMenu(false);
    switch (action) {
      case 'DELETE':
        changeDeleteUser(user);
        break;
      case 'EDIT':
        onEditUser(user);
        break;
      case 'ACTIVE':
        changeActive(user);
        break;
      case 'CHANGE_USER_LEAD':
        changeUserRole(user, 'LEADER');
        dispatch(
          addToast({
            error: false,
            text: 'User is now a team lead',
            id: uuid(),
          }),
        );
        break;
      case 'CHANGE_USER_MEMBER':
        changeUserRole(user, 'MEMBER');
        dispatch(
          addToast({
            error: false,
            text: 'User is now a member',
            id: uuid(),
          }),
        );
        break;
      case 'INVITE_USER':
        onInviteUser(user);
        break;
      case 'AVAILIBILITY':
        onAvailibilityUser(user);
        break;
      default:
        break;
    }
  };

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowMenu(false);
    }
  };

  const getUserInviteStatus = () => {
    if (user.status === 'ACTIVE') {
      return user.is_active
        ? { status: 'active', background: '#A1D9A0' }
        : { status: 'inactive', background: '#D2E5ED' };
    } else if (user.status === 'INACTIVE') {
      return { status: 'inactive ', background: 'rgb(115, 134, 140, 0.3)' };
    } else if (user.status === 'INVITED') {
      return { status: 'invited', background: '#F6BB23' };
    } else if (user.status === 'EXPIRED') {
      return { status: 'expired', background: '#FF5B5B' };
    }
    return { status: 'expired', background: '#FF5B5B' };
  };

  return (
    <div className="ur-user cursor flex card items-center my-3 mx-2" onClick={() => setSelectedUser(user)}>
      <div className="uru-top">
        <div className="urut-left">
          <label className="regular-text font-16">{user.name}</label>
        </div>

        {!isTeamUsers && (
          <div className={`mt-1 flex items-center`}>
            <div className="uru-roles w-full flex flex-wrap">
             <label className="mr-1 role-label">{user.role?.name}</label>
            </div>
          </div>
        )}
      </div>
      <div className="border-right mh" />
      <div
        className="flex-column cursor"
        onClick={() => setSelectedUser(user)}
        style={{ flex: 150, minWidth: '200px' }}>
        <div className="uru-middle">
          <div className={`urub-email mb-2 uru-organisations`}>
            <div className="uru-icons">
              <EmailIcon />
            </div>
            <label
              className={`ml-2 ${
                !user?.contact_info?.find(contact => contact.contact_type === 'EMAIL')?.contact_value &&
                'not-provided-label'
              }`}
              style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {user?.contact_info?.find(contact => contact.contact_type === 'EMAIL')?.contact_value || 'Not provided'}
            </label>
          </div>
          <div className="urub-email">
            <div className="uru-icons">
              <MobileIcon />
            </div>
            <label
              className={`ml-2 ${
                !user?.contact_info?.find(contact => contact.contact_type === 'MOBILE')?.contact_value &&
                'not-provided-label'
              }`}
              style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {user?.contact_info?.find(contact => contact.contact_type === 'MOBILE')?.contact_value || 'Not provided'}
            </label>
          </div>
        </div>
      </div>
      <div className="border-right mh h-full h-full" />
      <div
        onClick={() => setSelectedUser(user)}
        style={{
          flex: 150,
          minHeight: 64,
          borderRadius: 8,
          minWidth: '200px',
          zIndex: '10',
        }}>
        {!isTeamUsers && (
          <div className="uru-bottom" id={`bottom-${testKey}`}>
            <div className="uru-organisations">
              {organisations?.length > 0 ? (
                <>
                  {organisations.map((organisation, index) => (
                    <label>{organisation.name}</label>
                  ))}
                </>
              ) : (
                <div className="organisation-empty-container">
                  <span className="regular-text organisation-empty">User is not in a team</span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="border-right items-center mh h-full" />
      <div className="items-center justify-center  cursor" onClick={() => setSelectedUser(user)} style={{ flex: 175 }}>
        <div
          className="uru-top"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            justifyContent: 'left',
            columnGap: '10px',
          }}>
          <div className="urut-right" style={{ width: '90px' }} tabIndex={0}>
            <label className="uru-state" style={{ background: getUserInviteStatus().background }}>
              {getUserInviteStatus().status}
            </label>
          </div>
          <div className="urut-right" tabIndex={0}>
            <div className="relative pl-2 pr-2" onBlur={onBlur} tabIndex={0}>
              <div
                className="menu-btn cursor flex items-center relative cursor menu-hover"
                onClick={e => {
                  e.stopPropagation();
                  setShowMenu(!showMenu);
                }}>
                <DotsIcon style={{ margin: 'auto' }} />
              </div>
              {showMenu && (
                <div>
                  <Menu
                    checkPermission={checkPermission}
                    clickItem={action => menuClick(action)}
                    hasPermission={hasPermission}
                    isTeamUsers={isTeamUsers}
                    organisations={organisations}
                    user={user}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserItem;
