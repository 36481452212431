import React, { useState, useContext } from "react";

import Image from "../../image";
import { setNft, setRetirement } from "../../../store/features/nftSlice";
import { useDispatch } from "react-redux";
import api from '../../../store/services'
import { addToast } from "../../../store/features/toastSlice";
import uuid from "react-uuid";

import { OrganisationContext } from '../../../context/organisationContext';

import { ReactComponent as DotsIcon } from '../../../assets/images/menu/dots.svg';
import AddRetirementPopup from "../../campaignNft/addRetirementPopup";

import './style.scss'

const CampaignProject = ({campaign, campaignProject, onEditProject, setViewNft }) => {
    const { setModal, hasPermission, checkPermission } = useContext(OrganisationContext);
    const dispatch = useDispatch();

    const [showMenu, setShowMenu] = useState();
    const [showRetirement, setShowRetirement] = useState();

    const project = campaignProject?.project;
    const template = campaignProject?.template;
    const nft = campaignProject?.nft;
    const menuItems = [{id: 0, name: 'Edit'}, {id: 1, name: 'Remove'}, {id: 2, name: 'Add Retirement'}];

    const onMenuItemClick = item => {
        if(item.name === 'Remove') {
            setModal({ type: 'delete-campaign-project', content: { campaignProject: campaignProject, campaignId: campaign.id, top: true } });
        } else if(item.name === 'Edit') {
            onEditProject(campaignProject)
        } else if(item.name === 'Add Retirement') {
            setShowRetirement(true);
        }
    }

    const createNft = async () => {
        try {
            const { data } = await api.post(`/v3/api/backoffice/nfts`, {
                template: {id: template.id}, 
                collection: {id: campaign.collection.id},
                campaign: {id: campaign.id},
                owner: { wallet_id: campaign.beneficiary?.wallet?.wallet_address, organisation: { id: campaign.beneficiary.id}, ownership_start: 1, percentage: 100 }, 
                name: template.name, 
                description: template.description,
                nft_type: 'RETIREMENT',
                is_fractional: template.is_burnable,
                is_transferable: template.is_transferable,
                series_number: template.issued_supply,
                total_in_series: template.max_supply,
                image_id: template.image ? template.image.format === 'IPFS' ? template.image?.public_id : template.image?.media_external_id : null,
                is_ipfs: template.image?.format === 'IPFS'
            });
            await api.put(`/v3/api/backoffice/campaign_projects/${campaignProject.id}`, {
                template: {id: template.id}, 
                project: {id: project.id}, 
                nft: {id: data.id}, 
            });
            dispatch( addToast({
                error: false,
                title: 'NFT created',
                text: `NFT has been created`,
                id: uuid(),
              }))
              dispatch(setNft({}));
              dispatch(setRetirement(null))
              setViewNft({campaignProject: campaignProject, nft: data})
        } catch(e) {
            console.log(e)
            dispatch(
                addToast({
                  error: true,
                  text: 'Error while creating NFT',
                  id: uuid(),
                }),
              );
        }
    }

    return (
        <div className="flex-column">
            <div className="flex-column px-6 border-bottom relative">
                <div className="campaign-project-grid">
                    <div className="flex items-center border-right pr-6 my-1">
                        <Image className={"campaign-project-image my-3"} size={64} media={project?.image?.media} />
                        <div className="flex-column ml-2 ">
                            <div className="flex items-center justify-between">
                                <label className="bold-text font-16 mt-1">{project.name}</label>
                                <label className="regular-text font-12">{project.allocation_percentage || 0}%</label>                            
                            </div>
                            <label className="regular-text font-12 light-purple-text mt-1 two-lines">{project.description}</label>
                        </div>
                    </div>
                    <div className="flex items-center ml-6">
                        <div className="flex-column justify-center flex-1">
                            <label className="regular-text font-12 light-purple-text mt-1 two-lines">Retirement No.</label>
                            <label className="regular-text mt-1">{campaignProject.retired_count || 0}</label>
                        </div>
                        <div className="flex-column justify-center flex-1">
                            <label className="regular-text font-12 light-purple-text mt-1 two-lines">Total retired</label>
                            <label className="regular-text mt-1">{campaignProject.retired_co2_kilo_amount / 1000 || 0} t</label>
                        </div>
                        <div className="flex-column justify-center flex-1">
                            <label className="regular-text font-12 light-purple-text mt-1 two-lines">Un-Retired</label>
                            <label className="regular-text mt-1">{campaignProject.total_co2_kilo_amount / 1000 || 0} t</label>
                        </div>
                    </div>
                </div>
                <div className="flex-column absolute campaign-project-menu ml-8">
                    <div className="flex items-center justify-center header-menu relative cursor" style={{background: showMenu  ? '#F6F6F6' : '', border: showMenu ? '1px solid rgba(181, 185, 193, 0.5)' : '', visibility: (campaignProject.added || campaignProject.updated) ? "hidden" : "visible"}} onClick={() => setShowMenu(!showMenu)} tabIndex={0} onBlur={() => setShowMenu(false)}>
                            <DotsIcon className="cursor" height={12} /> 
                            {showMenu && <div className="header-menu-items flex-column card pxy-2 absolute">
                                {menuItems.map((item, i) => 
                                    <div key={i} className="header-menu-item flex items-center pxy-2 cursor" onClick={() => checkPermission(() => onMenuItemClick(item), 'CAMPAIGNS_MANAGE')} style={{ opacity: hasPermission('CAMPAIGNS_MANAGE') ? 1 : 0.5 }}>
                                        <label className="regular-text">{item.name}</label>
                                    </div>
                                )}
                            </div>
                            }
                    </div>
                </div>
            </div>
            {showRetirement && <AddRetirementPopup campaignProject={campaignProject} amount={campaignProject.total_co2_kilo_amount || 0} campaign={campaign} onCancel={() => setShowRetirement(null)} />}
        </div>
    )
}

export default CampaignProject;