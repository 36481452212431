import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  getRetirement,
  setRetirement,
  updateRetirement,
} from "../../store/features/retirementSlice";

import NavTop from "../../components/common/NavTop";

import { useParams, useNavigate, Routes, Route } from "react-router-dom";

import Header from "../../components/header";
import Image from "../../components/image";

import { ReactComponent as DotsIcon } from "../../assets/images/horizontal-dots.svg";

import "./style.scss";
import Loading from "../../assets/images/loading.svg";

import RetirementDetailsComp from "../../components/retirements/retirement-details";
import RetirementTransactions from "../../components/retirements/retirement-transactions";

const RetirementDetails = () => {
  const { id } = useParams();
  const isNewCampaign = id === "new";
  const menuItems = [
    { id: 1, name: "Details", text: "Details", path: "" },
    {
      id: 2,
      name: "Transactions",
      text: "Transactions",
      path: "/transactions",
    },
  ];

  const [showMenu, setShowMenu] = useState();
  const [edit, setEdit] = useState(isNewCampaign ? true : false);
  const [name, setName] = useState("Details");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { retirement, loading } = useSelector((data) => data.retirement);

  const onMenuSelect = (item) => {
    setName(item.name)
    navigate(`/carbon/retirements/${id}${item.path}`);
  };

  useEffect(() => {
    dispatch(getRetirement(id));
  }, [id, dispatch]);

  const saveRetirement = () => {
    const retirementPayload = {
      units: retirement.blocks?.units,
      is_pending: retirement.is_pending,
      requested_date: retirement.requested_date,
      retirement_date: retirement?.retirement_date,
      registry: retirement.registry ? { id: retirement.registry.id } : null,
      serial_number: retirement?.serial_number,
      offset_url: retirement?.offset_url,
      beneficiary_account: retirement.beneficiary_account
        ? { id: retirement.beneficiary_account.id }
        : null,
      beneficiary: retirement.beneficiary,
      reason: retirement.reason ? { id: retirement.reason.id } : null,
      transparency_url: retirement.transparency_url,
    };

    dispatch(
      updateRetirement({
        id: retirement.id,
        retirement: { ...retirementPayload },
      })
    );
    setEdit(false);
  };

  return (
    <div className="flex flex-1">
      <div className="flex-column retirement-details flex-1">
        <div className="mb-6 mt-8 ml-6">
          <NavTop
            names={[
              { text: "Retirements", path: "/carbon/retirements" },
              { text: "Retirement details" },
            ]}
            savePermission="RETIREMENT_MANAGE"
            edit={false}
            done={true}
            onDone={() => navigate("/carbon/retirements")}
          />
        </div>
        <div className="flex mx-6">
          <div className="retirement-details-l card fit-content">
            <div className="flex items-center justify-between pxy-6 border-bottom">
              <label className="bold-text font-20">Retirement</label>
              <div
                className="flex items-center justify-center header-menu relative cursor"
                style={{ background: showMenu ? "#F6F6F6" : "" }}
                onClick={() => setShowMenu(!showMenu)}
                tabIndex={0}
                onBlur={() => setShowMenu(false)}
              >
                <DotsIcon className="cursor" width={20} />
              </div>
            </div>
            <div className="flex-column pxy-6">
              <div className="flex items-center justify-between">
                <label className="regular-text light-purple-text">
                  Project:
                </label>
                <label className="regular-text ">
                  {retirement.inventory?.project?.name}
                </label>
              </div>
              <Image
                className={"ret-project-image mt-4"}
                size={160}
                media={retirement?.inventory?.project?.image?.media}
              />
              <div className="flex items-center justify-between mt-4">
                <label className="regular-text light-purple-text">
                  Retired date:
                </label>
                <label className="regular-text ">
                  {moment.unix(retirement.retirement_date).format("DD/MM/YYYY")}
                </label>
              </div>
              <div className="flex items-center justify-between mt-4">
                <label className="regular-text light-purple-text">
                  Requested date:
                </label>
                <label className="regular-text ">
                  {retirement.requested_date
                    ? moment
                        .unix(retirement.requested_date)
                        .format("DD/MM/YYYY")
                    : "-"}
                </label>
              </div>
              <div className="flex items-center justify-between mt-4">
                <label className="regular-text light-purple-text">
                  Retired amount:
                </label>
                <label className="regular-text ">
                  {retirement.blocks?.units / 1000}
                </label>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="flex flex-1 items-center justify-center mt-4">
              <img src={Loading} />
              <label className="bold-text  ml-2 light-purple-text">
                Loading
              </label>
            </div>
          ) : (
            <div className="card flex-column flex-1 ml-6 carbon-retirement">
              <div className="border-bottom pb-3">
                <Header
                  title={name}
                  isSearch={false}
                  isPaged={false}
                  size="small"
                  isAddBtn={false}
                  isEditBtn={retirement.is_pending || edit}
                  onEdit={() => setEdit(true)}
                  onSave={saveRetirement}
                  onCancelEdit={() => setEdit(false)}
                  edit={edit}
                  menuItems={menuItems}
                  onMenuItemClick={onMenuSelect}
                />
              </div>
              <Routes>
                <Route
                  element={
                    <RetirementDetailsComp
                      retirement={retirement}
                      edit={edit}
                    />
                  }
                  path={``}
                />
                <Route
                  element={
                    <RetirementTransactions retirement={retirement} />
                  }
                  path={`/transactions`}
                />
              </Routes>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RetirementDetails;
