import { createSlice } from "@reduxjs/toolkit";
import api from '../services'
import { addToast } from "./toastSlice";
import uuid from "react-uuid";

export const transactionSlice = createSlice({
    name: "transaction",
    initialState: {
      transactions: {},
      loading: false,
    },
    reducers: {
      setTransactions: (state, { payload }) => {
        state.transactions = payload;
      },
      setMergeTransactions: (state, { payload }) => {
        state.transactions = {...payload, content: [...state.transactions.content, ...payload.content]};
      },
      setLoading: (state, { payload }) => {
        state.loading = payload;
      },
    }
  });

  export const getTransactions = (payload) => async (dispatch) => {
    dispatch(setLoading(true))
    let url = `/v3/api/backoffice/transactions?page=${payload.page}&size=${payload.size || 8}&search=${payload.search}`;
    url += `&${payload.filter}`
    const { data } = await api.get(url);
    if (payload.merge) {
      dispatch(setMergeTransactions(data));
    } else {
      dispatch(setTransactions(data));
    }
    dispatch(setLoading(false))
  };

  export const createTransaction = (payload) => async (dispatch) => {
      const { data } = await api.post(`/v3/api/backoffice/transactions`, payload);
 
  };

  export const transferTransaction = (payload) => async (dispatch) => {
    const { data } = await api.post(`/v3/api/backoffice/transactions/transfer`, payload);

};
export const { setTransactions, setLoading, setMergeTransactions } = transactionSlice.actions;
export default transactionSlice.reducer;