import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { createCampaign, getCampaign, setCampaign, setCampaignCreateStatus, updateCampaign } from '../../store/features/campaignSlice';
import { getCollections, getCollectionIntegrations } from "../../store/features/collectionSlice";
import { getAccounts } from "../../store/features/accountSlice";
import { getWallets } from "../../store/features/walletSlice";


import NavTop from "../../components/common/NavTop";

import { Route, Routes, useParams, useNavigate } from 'react-router-dom';
import DateView from "../../components/Elements/DateView";
import CampaignProjects from "../../components/campaign/CampaignProjects";
import CampaignTiers from "../../components/campaign/CampaignTiers";
import CampaignNfts from "../../components/campaign/CampaignNfts";
import CampaignTransactions from "../../components/campaign/CampaignTransactions";
import CampaignRetirements from "../../components/campaign/CampaignRetirments";
import CampaignLeaderboards from "../../components/campaign/CampaignLeaderboards";
import CampaignNft from "../campaignNft";
import CampaignSocials from "../../components/campaign/CampaignSocials";
import CampaignDashboard from "../../components/campaign/CampaigDashboard";
import CampaignDetailsComp from "../../components/campaign/CampaignDetailsComp";
import DropDown from "../../components/McDropdown";
import Button from "../../components/Elements/button/button";
import TabMenu from "../../components/TabMenu";
import CampaignAchievements from "../../components/campaign/CampaignAchievements";

import { ReactComponent as DotsIcon } from '../../assets/images/horizontal-dots.svg';
import { OrganisationContext } from "../../context/organisationContext";

import './style.scss';
import CampaignRetire from "../../components/campaign/CampaignRetire";
import CampaignMintingProgress from "../../components/campaign/CampaignMintingProgress";
import { capitalize, formatText } from "../../helpers/utils";

import CampaignCalendarIcon from  "../../assets/images/campaign-calendar.svg";

const CampaignDetails = () => {
    const {id} = useParams();
    const isNewCampaign = id === 'new';
    const pageRef = useRef(null);
    const pageRefBeneficiary = useRef(null);


    const { checkPermission, hasPermission, setModal } = useContext(OrganisationContext);
    const { campaign, totalOffsetAmount, totalRetireAmount, campaignCreateStatus } = useSelector(data => data.campaign);
    const menuItems = [ {id: 112, name: 'Details', text: 'Details', path: '/details'},  {id: 6, name: 'Transactions', text: 'Transactions', path: '/transactions'}, {id: 1, name: 'Leaderboards', text: 'Leaderboards', path: '/leaderboards'},  {id: 4, name: 'Retirements', text: 'Retirements', path: '/retirements'}, {id: 2, name: 'NFTs', text: 'NFTs', path: '/nfts'}, {id: 3, name: 'Projects', text: 'Projects', path: '/projects'}, {id: 5, name: 'Achievements', text: 'Achievements', path: '/achievements'}];
    const nftTabOptions = [{name: 'Retirement', value: 'RETIREMENT'}, {name: campaign.campaign_type === 'LEADERBOARD' ? 'Ranking' : 'Fractional', value: campaign.campaign_type === 'LEADERBOARD' ? 'MERCHANDISE' : 'FRACTIONAL'}, {name: 'Measurement', value: 'MEASUREMENT'}];
    const detailsMenuItems = [{id: 0, name: 'Edit'}, {id: 1, name: 'Delete'}];

    const [showMenu, setShowMenu] = useState();
    const [edit, setEdit] = useState(isNewCampaign ? true : false)
    const [addProject, setAddProject] = useState(false)
    const [selectedNftTab, setSelectedNftTab] = useState(nftTabOptions[0])
    const [viewNft, setViewNft] = useState();
    const { user, organisations } = useSelector(data => data.user);
    const { wallets } = useSelector(data => data.wallet);

    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [beneficiaryWallet, setBeneficiaryWallet] = useState(campaign?.beneficiary?.wallet?.wallet_address)

    useEffect(() => {
        if(campaign)
            setBeneficiaryWallet(campaign?.beneficiary?.wallet?.wallet_address)
    }, [campaign])


    useEffect(() => {
        if (isNewCampaign) {
            dispatch(setCampaign({}));
        } else {
            dispatch(getCampaign(id));
        }
    }, [id, dispatch, isNewCampaign]);

    useEffect(() => {
        if (isNewCampaign && campaignCreateStatus === 'CREATED') {
            navigate(`/commerce/campaigns/${campaign.id}/projects`);
            setEdit(false);
        }
    }, [campaignCreateStatus]);  
    
    useEffect(() => {
        return () => {
            dispatch(setCampaignCreateStatus(null));
        }
    }, []);

 
    const onCancelEdit = () => {
        if (isNewCampaign && !campaign.id) {
            navigate('/commerce/campaigns');
        } else {
            setEdit(false);
        }
    }

    const onCreateNft = () => {
        setModal({
            type: 'create-nft',
            content: {
                top: true,
                campaign: campaign,
                onSuccess: onCreateNftSuccess
            }
        })
    }

    const onCreateNftSuccess = queued => {
        const merchandiseTab =nftTabOptions.find(tab => tab.value === 'MERCHANDISE');
        onNftTabChange({ ...merchandiseTab, fromCreateNftSuccess: true });
        navigate(`/commerce/campaigns/${id}/nfts`, { state: { queued: queued }}) 
    }

    const onMintNftSuccess = () => {
        navigate(`/commerce/campaigns/${id}/minting`)
    }

    const onMintNfts = () => {
        setModal({
            type: 'mint-nft',
            content: {
                top: true,
                campaign: campaign,
            }
        })
    }

    const onMenuItemClick = item => {
        if(item.name === 'Delete') {
        } else if(item.name === 'Edit') {
            setEdit(true)
        }
    }

    const onNftTabChange = tab => {
        setSelectedNftTab(tab);
    }

    useEffect(() => {
        if(edit)
            dispatch(getCollectionIntegrations());
    }, [edit, dispatch])


    // const fetchWallets  = useCallback((page, merge, organisationId)  => {
    //     pageRefWallets.current = page;
    //     dispatch(getWallets({organisationId: organisationId, page: page, merge: merge}));
    // }, [dispatch]);

    // useEffect(() => {
    //     if(campaign.beneficiary && campaign.beneficiary.id)
    //         fetchWallets(0, false, campaign.beneficiary?.id);
    // }, [campaign.beneficiary, fetchWallets])

    const onMenuSelect = item => {
        navigate(`/commerce/campaigns/${id}${item.path}`)
    }

    return (
        <div className="flex flex-1">
        {viewNft ? <CampaignNft campaign={campaign} campaignProject={viewNft.campaignProject} nftId={viewNft.nft.id} close={() => setViewNft(false)} /> :
        <div className="flex-column campaign-details flex-1">
            <div className="mb-6 mt-8 ml-6">
                <NavTop names={[{text: "Campaigns", path: "/commerce/campaigns"}, {text: campaign?.name}]} savePermission="CAMPAIGNS_MANAGE" edit={false} done={false} onDone={() => navigate('/commerce/campaigns')} />
            </div>
            <div className="flex-column mx-6">
                <div className="flex items-center justify-between card px-6 py-4 mb-6">
                    <label className="bold-text font-20">{campaign?.name}</label>
                    <div className="flex items-center mr-2">
                        <div className="flex items-center">
                            <label className="regular-text font-16 light-purple-text ml-2">Type:</label>
                            <label className="regular-text font-16 ml-2">{capitalize(formatText(campaign?.campaign_type || ''))}</label>
                        </div>
                        <div className="flex items-center ml-10">
                            <img src={CampaignCalendarIcon} alt="calendar" />
                            <label className="regular-text font-16 light-purple-text ml-2">Start:</label>
                            <label className="regular-text font-16 ml-2">{moment.unix(campaign.effective?.start).format('Do MMMM yyyy')}</label>
                        </div>
                        <div className="flex items-center ml-10">
                            <img src={CampaignCalendarIcon} alt="calendar" />
                            <label className="regular-text font-16 light-purple-text ml-2">End:</label>
                            <label className="regular-text font-16 ml-2">{moment.unix(campaign.effective?.end).format('Do MMMM yyyy')}</label>
                        </div>
                    </div>
                </div>
                {/* <div className="campaign-details-l card fit-content">
                    <div className="flex items-center justify-between pxy-6 border-bottom">
                        <label className="bold-text font-20">Campaign details</label>
                        <div className="flex items-center justify-center header-menu relative cursor" style={{background: showMenu  ? '#F6F6F6' : ''}} onClick={() => setShowMenu(!showMenu)} tabIndex={0} onBlur={() => setShowMenu(false)}>
                            <DotsIcon className="cursor" width={20} /> 
                            {showMenu && detailsMenuItems.length > 0 && <div className="header-menu-items flex-column card pxy-2 absolute">
                                {detailsMenuItems.map((item, i) => 
                                    <div key={i} className="header-menu-item flex items-center pxy-2 cursor" onClick={() => onMenuItemClick(item)}>
                                        <label className="regular-text">{item.name}</label>
                                    </div>
                                    )}
                            </div>
                            }
                        </div>
                    </div>
                    {edit ?
                    <div className="flex-column pxy-6">
                      
                        <div className="flex-column campaign-details-edit">
                        <label className="input-label">Name</label>
                        <input className="input" value={campaign?.name || ''} placeholder="Enter name" onChange={e => dispatch(setCampaign({...campaign, name: e.target.value}))} />
                        <label className="input-label mt-6">Description</label>
                        <textarea className="textarea" value={campaign?.description || ''} placeholder="Enter description" ref={headlineRef} onChange={e => dispatch(setCampaign({...campaign, description: e.target.value}))} />
                         <label className="input-label mt-6">Campaign Type</label>
                        <DropDown 
                            size="large" 
                            placeholder="Select" 
                            selected={campaignTypeOptions.find(c => c.text === campaign.campaign_type)}
                            setSelected={c => dispatch(setCampaign({...campaign, campaign_type: c.text}))}
                            options={campaignTypeOptions}
                        />
                        <div className="flex mt-6">
                            <div className="flex-column flex-1">
                                <label className="input-label">Start</label>
                                <DateView size="medium" value={campaign.effective?.start} setValue={o =>  dispatch(setCampaign({...campaign, effective: {...campaign.effective, start: o}}))}/>
                            </div>
                            <div className="flex-column flex-1 ml-6 end-date-selector">
                                <label className="input-label">End</label>
                                <DateView size="medium" value={campaign.effective?.end} setValue={o =>  dispatch(setCampaign({...campaign, effective: {...campaign.effective, end: o}}))}/>
                            </div>
                        </div>
                        <label className="input-label mt-6">Blockchain</label>
                        <DropDown 
                            size="large" 
                            placeholder="Select" 
                            selected={campaign.integration}
                            setSelected={c => dispatch(setCampaign({...campaign, integration: {...c, icon: c.connector?.icon ? {...c.connector?.icon} : {}}, collection: null }))}
                            options={integrations}
                            getIcon={(option) => { return option.icon?.active || option?.connector?.icon?.active || ''}}
                            icon={true}
                        />                        
                        <label className="input-label mt-6">Blockchain receiver</label>
                        <input className="input" placeholder="Enter receiver" value={campaign?.account || ''} onChange={e => dispatch(setCampaign({...campaign, account: e.target.value}))} />
                        <label className="input-label mt-6">Collection</label>
                        <DropDown 
                            size="large" 
                            placeholder="Select" 
                            selected={campaign.collection}
                            setSelected={c => dispatch(setCampaign({...campaign, collection: c}))}
                            options={collections.content}
                            isPaged={true}
                            hasMore={!collections.last}    
                            fetchData={() => fetchCollections(pageRef.current + 1, true, campaign.integration)}   
                        />
                        <label className="input-label mt-6">Beneficiary</label>
                        <DropDown 
                            size="large" 
                            placeholder="Select" 
                            selected={campaign.beneficiary}
                            setSelected={c => dispatch(setCampaign({...campaign, beneficiary: c}))}
                            options={[...organisations.map(o => ({...o, id: o.external_id})), ...accounts.content]}
                            isPaged={true}
                            hasMore={!accounts.last}    
                            fetchData={() => fetchAccounts(pageRefBeneficiary.current + 1, true, user)}   
                        />
                        <label className="input-label mt-6">Beneficiary Wallet address</label>
                        <input className="input" placeholder="Enter beneficiary wallet address" value={beneficiaryWallet || ''} onChange={e => setBeneficiaryWallet(e.target.value)} />
                        </div>
                    </div>
                    :
                    <div className="flex-column pxy-6">
                        <label className="semibold-text font-16">{campaign.name}</label>
                        <label className="regular-text light-purple-text mt-2">{campaign.description}</label>
                        <div className="flex items-center justify-between mt-6">
                            <label className="regular-text light-purple-text">Campaign Type:</label>
                            <label className="regular-text">{capitalize(formatText(campaign?.campaign_type || ''))}</label>
                        </div>
                        <div className="flex items-center justify-between mt-4">
                            <label className="regular-text light-purple-text">Start:</label>
                            <label className="regular-text">{moment.unix(campaign.effective?.start).format('DD/MM/yyyy')}</label>
                        </div>
                        <div className="flex items-center justify-between mt-4">
                            <label className="regular-text light-purple-text">End:</label>
                            <label className="regular-text">{moment.unix(campaign.effective?.end).format('DD/MM/yyyy')}</label>
                        </div>
                        <div className="flex items-center justify-between mt-4">
                            <label className="regular-text light-purple-text">Blockchain:</label>
                            {campaign.integration && <div className="flex">
                                <img className="ci-icon" alt="icon" src={campaign.integration?.icon?.active} />
                                <label className="regular-text ml-2">{campaign.integration?.name}</label>
                            </div>}
                        </div>
                        <div className="flex items-center justify-between mt-4">
                            <label className="regular-text light-purple-text">Blockchain receiver:</label>
                            <label className="regular-text">{campaign.account}</label>
                        </div>
                        <div className="flex items-center justify-between mt-4">
                            <label className="regular-text light-purple-text">Collection:</label>
                            <label className="regular-text">{campaign.collection?.name}</label>
                        </div>
                        <div className="flex items-center justify-between mt-4">
                            <label className="regular-text light-purple-text">Beneficiary:</label>
                            <label className="regular-text">{campaign.beneficiary?.name}</label>
                        </div>
                        <div className="flex items-center justify-between mt-4 one-line">
                            <label className="regular-text light-purple-text">Beneficiary Wallet address:</label>
                            <label className="regular-text one-line ml-2">{campaign.beneficiary?.wallet?.alias || campaign.beneficiary?.wallet?.wallet_address}</label>
                        </div>
                    </div>
                    }
                </div> */}
                <div className="card flex-column">
                    <TabMenu menuItems={menuItems} onMenuItemClick={onMenuSelect} />
                    <Routes>
                        <Route element={<CampaignProjects campaign={campaign} add={addProject} cancelAdd={() => setAddProject(false)} isNewCampaign={isNewCampaign} menuItems={menuItems} onMenuItemClick={onMenuSelect} onAdd={() => setAddProject(!addProject)} setViewNft={setViewNft}  />} path={`/projects`}   />
                        <Route element={ <CampaignRetire campaign={campaign}  isNewCampaign={isNewCampaign}  menuItems={menuItems} onMenuItemClick={onMenuSelect} totalOffsetAmount={totalOffsetAmount} totalRetireAmount={totalRetireAmount} setViewNft={setViewNft}  />} path={`/projects/retire`} />
                        {/* <Route element={<CampaignTiers campaignId={campaign.id} isNewCampaign={isNewCampaign}  menuItems={menuItems} onMenuItemClick={onMenuSelect}  />} path={`/tier`} /> */}
                        <Route element={<CampaignAchievements campaignId={campaign.id} isNewCampaign={isNewCampaign}  menuItems={menuItems} onMenuItemClick={onMenuSelect}  />} path={`/achievements`} />

                        <Route element={<CampaignNfts campaignId={campaign.id} selectedNftTab={selectedNftTab} isNewCampaign={isNewCampaign}  menuItems={menuItems} onMenuItemClick={onMenuSelect} onAdd={onMintNfts} onCreateNft={onCreateNft} nftTabOptions={nftTabOptions} onNftTabChange={onNftTabChange}  />} path={`/nfts`} />
                        <Route element={<CampaignRetirements campaignId={campaign.id} campaign={campaign}  menuItems={menuItems} onMenuItemClick={onMenuSelect}  />} path={`/retirements`} />
                        <Route element={<CampaignLeaderboards campaignId={campaign.id} isNewCampaign={isNewCampaign}  menuItems={menuItems} onMenuItemClick={onMenuSelect} onCreateNft={onCreateNft} />} path={`/leaderboards`} />
                        <Route element={<CampaignMintingProgress campaignId={campaign.id} selectedNftTab={selectedNftTab} isNewCampaign={isNewCampaign}  menuItems={menuItems} onMenuItemClick={onMenuSelect}  />} path={`/minting`} />
                        {/* <Route element={<CampaignSocials campaign={campaign} menuItems={menuItems} onMenuItemClick={onMenuSelect} />} path={`/details`} /> */}
                        <Route element={<CampaignTransactions  menuItems={menuItems} onMenuItemClick={onMenuSelect} campaign={campaign} />} path={`/transactions`} />
                        <Route element={<CampaignDetailsComp campaign={campaign}/>} path={`/details`}/>
                        <Route element={<CampaignDashboard />} path={`/dashboard`}/>
                    </Routes>
                </div>
            </div>
        </div>
        }
    </div>
    )
}

export default CampaignDetails;
