import React, { useContext, useEffect, useState } from 'react';
import uuid from 'react-uuid';
import Button from '../components/Elements/button/button';
import { initModal } from '../constant/InitialData';
import { capitalize, formatText } from '../helpers/utils';
import { addToast} from '../store/features/toastSlice'
import { DeleteIntegrationWrapper } from './modal.styled.js';
import { CSSTransition } from 'react-transition-group';
import { useDispatch } from 'react-redux';
import { OrganisationContext } from "../context/organisationContext";
import api from "../store/services";

const IntegrationActions = () => {
  const ownerSymbolColors = ['#E6EEFF', '#FFF0F0', '#FAF4E7', '#E6FAF3', '#E0F4FF'];
  const { modal, setModal } = useContext(OrganisationContext);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [integrationAutomations, setIntegrationAutomations] = useState([]);

  const { integration, isDelete, isDisable } = modal.content;

  const fetchIntegrationAutomations = async () => {
    const { data } = await api.get(`/v3/api/integrations/${integration.id}/automations`);
    setIntegrationAutomations(data);
  };

  useEffect(() => {
    if (integration.id) {
      fetchIntegrationAutomations();
    }
  }, [integration.id]);

  const onDelete = async () => {
    if (loading) return;
    setLoading(true);
    try {
      isDelete
        ? await api.delete(`/v3/api/integrations/${integration.id}`)
        : await api.put(`/v3/api/integrations/${integration.id}/state`, {
            enabled: false,
          });
      isDelete &&
        dispatch(
          addToast({
            error: false,
            text: 'Integration has been deleted successfully',
            id: uuid(),
          }),
        );
      setModal({
        type: 'integration-success',
        content: { integration_id: integration.id, isDelete: isDelete, isDisable: isDisable },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: `Failed to ${isDelete ? 'delete' : 'disable'} Integration`,
          id: uuid(),
        }),
      );
    }
  };

  const getOwnerLogo = (automation, index) => {
    const { owner } = automation;
    if (!owner.name) return '';
    const nameArray = owner.name?.split(' ');
    return (
      <div className="owner-logo" style={{ backgroundColor: ownerSymbolColors[index % 5 || 0] }}>
        <label className="bold-text">
          {nameArray?.[0]?.charAt(0)?.toUpperCase()}
          {nameArray?.[1]?.charAt(0)?.toUpperCase()}
        </label>
      </div>
    );
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <DeleteIntegrationWrapper>
        <p className="bold-text font-24 text-center">
          Are you sure you want to {isDelete ? 'delete' : isDisable ? 'disable' : ''} this Integration?
        </p>
        <div className="mt-8 integration-details-container">
          <div className="flex integration-name-container">
            <label className="medium-text font-18">{integration.name}</label>
          </div>
          <div className="flex items-center integration-type-container">
            <div className="integration-icon">
              <img
                alt="image"
                src={`data:image/png;base64,${integration.connector.icon}`}
                style={{ width: 24, height: 24 }}
              />
            </div>
            <label className="medium-text ml-2">{capitalize(formatText(integration.connector?.name))}</label>
          </div>
        </div>
        {integrationAutomations?.length > 0 && (
          <div className="mt-8">
            <div className="flex items-center justify-center text-center">
              <label className="regular-text grey-text" style={{ width: '90%' }}>
                This will affect these automations and they will be disabled as a result:
              </label>
            </div>
            <div className="automation-detail">
              {integrationAutomations.map((automation, index) => (
                <div className="automation-detail-container">
                  <div className="integration-item-top">
                    <div className="integration-item-left">
                      <label className="integration-item-name hide-name">{automation.name}</label>
                    </div>
                    <div className="integration-item-right" tabIndex={0}>
                      {getOwnerLogo(automation, index)}
                    </div>
                  </div>
                  <div className={`flex items-center mt-1 automation-desc`}>
                    <span className="regular-text grey-text">{automation.description}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="flex justify-center mt-12">
          <Button
            bgColor="white"
            borderColor="#32E5C5"
            label="Cancel"
            onClick={() => setModal(initModal)}
            size="large"
            style={{ flex: 1 }}
          />
          <Button
            bgColor={isDelete ? '#FF5B5B' : '#40F1DE'}
            borderColor={isDelete ? '#FF5B5B' : '#40F1DE'}
            color="white"
            disabled={loading}
            label={isDelete ? 'Delete' : isDisable ? 'Disable' : ''}
            onClick={onDelete}
            size="large"
            style={{ flex: 1, marginLeft: 24 }}
          />
        </div>
      </DeleteIntegrationWrapper>
    </CSSTransition>
  );
};

export default IntegrationActions;
