import styled from "styled-components";

const TabWrapper = styled.div`
  background: #aabab71a;
  border-radius: 12px;
  padding: 4px 6px;
  display: flex;
  column-gap: 4px;

  .tab-item {
    padding: 5px 8px;
  }

  .selected-item {
    background: #020819;
    border-radius: 8px;
    color: #ffffff;
  }
`;

export default TabWrapper;
