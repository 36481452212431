import React, { useContext } from "react";
import Button from "../components/Elements/button/button";
import { initModal } from "../constant/InitialData";
import { OrganisationContext } from "../context/organisationContext";
import { RemoveSchemaAttributeWrapper } from "./modal.styled.js";
import { CSSTransition } from "react-transition-group";

const RemoveSchemaAttribute = () => {
  const { modal, setModal } = useContext(OrganisationContext);

  const { attribute, onSuccess } = modal.content;

  const onRemove = async () => {
    onSuccess();
    setModal(initModal);
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <RemoveSchemaAttributeWrapper>
        <p className="bold-text font-24 text-center">
          Are you sure you want to remove this attribute?
        </p>
        <div className="flex-column pxy-4 name-wrapper mt-8">
          <label className="regular-text light-purple-text mb-1 font-12">
            Name
          </label>
          <label className="regular-text">
            {attribute.attribute_name || "-"}
          </label>
        </div>
        <div className="flex justify-center mt-12">
          <Button
            className="secondary-hover"
            label="Cancel"
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            style={{ flex: 1, borderRadius: "16px" }}
            bgColor="#FFFFFF"
            borderColor="#24CBB1"
            color="#24CBB1"
          />
          <Button
            label="Remove"
            onClick={() => onRemove()}
            size="large"
            style={{ flex: 1, marginLeft: 24, borderRadius: "16px" }}
            bgColor="#FB3535"
            borderColor="#FB3535"
            color="#FFFFFF"
          />
        </div>
      </RemoveSchemaAttributeWrapper>
    </CSSTransition>
  );
};

export default RemoveSchemaAttribute;
