import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as ArrowRightIcon } from '../assets/images/arrows/right.svg';
import Address from '../components/common/address/address';
import Dropdown from '../components/common/dropdown';
import PhoneInputLandline from '../components/common/phone-number-landline';
import SearchableDropdown from '../components/common/searchable-dropdown';
import Switch from '../components/common/switch';
import PhoneInput from '../components/Elements/phone-input';

import { initModal } from '../constant/InitialData';
import { OrganisationContext } from "../context/organisationContext";
import { emailRegEx, getErrorDescription } from '../helpers/utils';
import {
  createAccount,
  getAccountCategoryTagInfo,
  getOrganizationCategories,
  getCalculators,
  addFormOrganization
} from '../store/features/accountSlice';
import { getIntegrationsList } from '../store/features/integrationsSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';

const addAccountSteps = [
  { key: 'COMPANY_INFO', name: 'COMPANY INFO', category_tag_list: ['ACCOUNT', 'BRAND', 'VENUE'] },
  { key: 'ADDRESS', name: 'ADDRESS', category_tag_list: ['ACCOUNT', 'BRAND', 'VENUE'] },
  { key: 'OWNER_INFO', name: 'OWNER INFO', category_tag_list: ['ACCOUNT', 'BRAND', 'VENUE'] },
  { key: 'NETWORK', name: 'NETWORK', category_tag_list: ['BRAND', 'VENUE'] },
];

const CompanyInfo = ({ organizationId, companyInfo, setCompanyInfo, error = {}, setError = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { name, email, phone, landline, country_code, type, category } = companyInfo;

  const fetchCategories=  async (page) => {
    try {
      return await dispatch(getOrganizationCategories({ params: { page: page} }))
    } catch(e) {
      dispatch(
        addToast({
          text: getErrorDescription(error, 'Error while fetching categories'),
          error: true,
          id: nanoid(),
        }),
      );
    }
  };


  const fetchCategoryOptions = async (search, _prevOptions, { page, merge }) => {

    const categories = await fetchCategories(page);
    const { content, ...restResponse } = categories || {};
    const categoryContent = categories ? content : [];
    const changedOptions = categoryContent.map(option => ({ ...option, label: option.name, value: option.id }));

    return {
      options: changedOptions,
      hasMore: !restResponse.last,
      additional: {
        page: page + 1,
        merge,
        hasMore: !restResponse.last,
      },
    };
  };


  return (
    <div className="flex-column row-gap-6 w-full">
      <div className="w-full flex-column">
        <label className="regular-text main-grey-text mb-1 font-12">Company Name</label>
        <input
          autoComplete="off"
          className={classNames('input radius-3 h-40px', error?.name && 'error-border')}
          onChange={({ target: { value } }) => {
            setCompanyInfo({ ...companyInfo, name: value });
            setError({ ...error, name: false });
          }}
          placeholder={'Enter name'}
          value={name || ''}
        />
      </div>
      {/* <div className="w-full flex-column">
        <label className="regular-text main-grey-text mb-2 font-12">Mobile (optional)</label>
        <PhoneInput
            selectedCountry={country_code}
            setSelectedCountry={country => setCompanyInfo({ ...companyInfo, country_code: country, phone: '' })}
            phone={phone}
            setPhone={phone => setCompanyInfo({ ...companyInfo, phone: phone })}
            error={error?.phone}
          />
      </div> */}
      <div className="w-full flex-column">
        <label className="regular-text main-grey-text mb-2 font-12">Company Phone Number (optional)</label>
        <PhoneInputLandline
          phone={landline}
          setPhone={updatedPhone => {
            setCompanyInfo({ ...companyInfo, landline: updatedPhone });
            setError({ ...error, landline: false });
          }}
          phoneInputClassname={classNames('h-40px radius-3', error.landline && 'error-border')}
          className={classNames('h-40px radius-3 items-center user-phone-input')}
        />
      </div>
      <div className="w-full flex-column">
        <label className="regular-text main-grey-text mb-2 font-12">Company Email Address (optional)</label>
        <input
          className={classNames('input h-40px radius-3', error.email && !emailRegEx.test(email) && 'error-border')}
          onChange={({ target: { value } }) => {
            setCompanyInfo({ ...companyInfo, email: value });
            setError({ ...error, email: false });
          }}
          placeholder={'Enter email'}
          value={email || ''}
        />
      </div>
      <div className="flex-column">
      <label className="regular-text main-grey-text mb-2 font-12">Category</label>
      <SearchableDropdown
          loadOptions={fetchCategoryOptions}
          className="w-full"
          placeholder="Select category"
          value={category}
          onChange={option => {
            setCompanyInfo({ ...companyInfo, category: option });
            setError({ ...error, category: !option });
          }}
          defaultAdditional={{ page: 0 }}
          error={error.category}
        />
      </div>
    </div>
  );
};


const AddressInfo = ({ address, setAddress, error = {}, setError = () => {} }) => {
  const countries = [{subLabel: 1, label: 'United States', value: 'US'}, {subLabel: 2, label: 'United Kingdom', value:  'UK'}]

  const { addressStep, postal_address, billing_address, country_code } = address || {};

  const handlePostalAddressChange = updatedAddress => {
    setAddress(prevState => ({
      ...prevState,
      postal_address: updatedAddress,
    }));
  };

  const handleBillingAddressChange = updatedAddress => {
    setAddress(prevState => ({
      ...prevState,
      billing_address: updatedAddress,
    }));
  };

  const handleAddressChange = (field, value, type) => {
    let updatedData = type === 'postal_address' ? { ...(postal_address || {}) } : { ...(billing_address || {}) };
    let updatedError = error;

    if (field === 'address') {
      updatedData = { ...value };
      updatedError = { line1: false, city: false, state: false, postcode: false };
    } else if (field === 'postcode') {
        updatedData = { ...updatedData, [field]: value };
        updatedError = { ...updatedError, [field]: false };
    } else {
      updatedData = { ...updatedData, [field]: value };
      updatedError = { ...updatedError, [field]: false };
    }
    if (type === 'postal_address') {
      handlePostalAddressChange(updatedData);
    } else {
      handleBillingAddressChange(updatedData);
    }
    setError(updatedError);
  };

  return (
    <div className="flex-column row-gap-6 w-full">
      {addressStep === 'ADDRESS' && (
        <Fragment>
          <label className="regular-text font-16">Company address</label>
          <div className="flex-column">
          <label className="regular-text main-grey-text mb-2 font-12">Country</label>
            <Dropdown
              options={countries}
              className="w-full"
              placeholder="Select country"
              value={countries.find(c => c.value === (postal_address?.country_code || 'US'))}
              variant='primary'
              onChange={option =>  handleAddressChange('country_code', option.value, 'postal_address')}
            />
          </div>
          <Address
            address={{ ...(address?.postal_address || {}) }}
            handleChange={(key, value) => handleAddressChange(key, value, 'postal_address')}
            wrapperClassName="row-gap-4 address-fields"
            error={{ ...(error || {}) }}
            labelClassName="grey-text"
          />
          <div className="flex items-center justify-between">
            <label className="regular-text font-16">Different billing address</label>
            <Switch
              variant="large"
              enabled={address.show_billing_address}
              onClick={() => setAddress({ ...address, show_billing_address: !address.show_billing_address })}
            />
          </div>
        </Fragment>
      )}
      {addressStep === 'BILLING_ADDRESS' && address.show_billing_address && (
        <Fragment>
          <label className="regular-text font-16">Billing address</label>
          <Address
            address={{ ...(address?.billing_address || {}) }}
            handleChange={(key, value) => handleAddressChange(key, value, 'billing_address')}
            wrapperClassName="row-gap-4 address-fields"
            error={{ ...(error || {}) }}
            labelClassName="grey-text"
          />
        </Fragment>
      )}
    </div>
  );
};

const OwnerInfo = ({ ownerInfo, setOwnerInfo, error = {}, setError = () => {} }) => {
  const { t } = useTranslation();
  const { name, email, phone, country_code } = ownerInfo;
  const { name: errorName, email: errorEmail } = error || {};

  const onUpdateValue = (field, value) => {
    setOwnerInfo(prevState => ({
      ...prevState,
      [field]: value,
    }));
    setError({ ...error, [field]: false });
  };
  return (
    <div className="flex-column row-gap-6 w-full">
      <div className="w-full flex-column">
        <label className="regular-text main-grey-text mb-2 font-12">{t('NAME')}</label>
        <input
          className={classNames('input h-40px radius-3', errorName && 'error-border')}
          onChange={({ target: { value } }) => onUpdateValue('name', value)}
          placeholder={'Enter name'}
          value={name}
        />
      </div>
      <div className="w-full flex-column">
        <label className="regular-text main-grey-text mb-2 font-12">{t('EMAIL')}</label>
        <input
          className={classNames('input h-40px radius-3', errorEmail && !emailRegEx.test(email) && 'error-border')}
          onChange={({ target: { value } }) => onUpdateValue('email', value)}
          placeholder={'Enter email'}
          value={email}
        />
      </div>
      <div className="w-full flex-column">
        <label className="regular-text main-grey-text mb-2 font-12">Phone number (optional)</label>
        <PhoneInput
            selectedCountry={country_code}
            setSelectedCountry={country => onUpdateValue('country_code', country)}
            phone={phone}
            setPhone={phone => onUpdateValue('phone', phone)}
          />
      </div>
    </div>
  );
};

const FinanceOption = props => {
  const { innerProps, data, isSelected } = props;
  return (
    <div
      className={`flex-column w-full pxy-2 radius-1 cursor option-wrapper ${isSelected ? 'selected' : ''}`}
      {...innerProps}>
      <span
        className={`flex-1 regular-text text-uppercase font-12 pb-2 option-text select-option-label main-grey-text ${
          isSelected && 'semibold-text'
        }`}>
        {data.subLabel}
      </span>
      <span className={`flex-1 regular-text font-16 option-text ${isSelected && 'semibold-text'}`}>{data.label}</span>
    </div>
  );
};
const Finance = ({
  companyInfo,
  finance,
  setFinance,
  error = {},
  setError = () => {},
  category_tag = '',
  organizationId,
  organizationName = '',
}) => {
  const dispatch = useDispatch();
  const { add_to_brand, brand_venue, brand_invoice, brand_invoice_pay } = finance;

  const [searchedBrand, setSearchedBrand] = useState(null);

  const fetchBrandList = async (page, search = '') => {
    try {
      const brandList = await dispatch(
        getAccountCategoryTagInfo({
          organizationId: organizationId,
          params: { page: page, search: search },
        }),
      );
      return brandList;
    } catch (error) {
      console.log('error', error);
      return null;
    }
  };

  const fetchBrandOptions = async (search, _prevOptions, { page, merge }) => {
    const brandOptions = await fetchBrandList(page, search);
    const { content, ...restResponse } = brandOptions || {};
    const brandContent = brandOptions ? content : [];
    const changedOptions = brandContent.map(option => ({ ...option, label: option.name, value: option.id }));

    return {
      options: changedOptions,
      hasMore: !restResponse.last,
      additional: {
        page: page + 1,
        merge,
        hasMore: !restResponse.last,
      },
    };
  };

  const changedOptions =
    category_tag === 'BRAND'
      ? [
          { subLabel: 'Account', label: organizationName, value: organizationId },
          { subLabel: 'Brand', label: companyInfo?.name, value: null },
        ]
      : [
          { subLabel: 'Account', label: organizationName, value: organizationId },
          brand_venue ? { subLabel: 'Brand', label: brand_venue?.name, value: brand_venue?.id } : null,
          { subLabel: 'Venue', label: companyInfo?.name, value: null },
        ].filter(item => item);

  return (
    <div className="flex-column row-gap-6 w-full">
      {category_tag === 'VENUE' && (
        <Fragment>
          <div className="flex items-center justify-between">
            <label className="regular-text font-16">Add to brand</label>
            <Switch
              variant="large"
              enabled={add_to_brand}
              onClick={() => setFinance({ ...finance, add_to_brand: !add_to_brand })}
            />
          </div>
          {add_to_brand && (
            <Fragment>
              <SearchableDropdown
                isClearable
                inputValue={searchedBrand}
                onInputChange={setSearchedBrand}
                loadOptions={fetchBrandOptions}
                className="w-full"
                placeholder="Select brand"
                name="Select a brand for this venue"
                value={brand_venue}
                onChange={option => {
                  setFinance({ ...finance, brand_venue: option });
                  setError({ ...error, brand_venue: !option });
                }}
                isSearchable
                defaultAdditional={{ page: 0 }}
                error={error.brand_venue}
              />
            </Fragment>
          )}
        </Fragment>
      )}
      <div className="flex-column">
        <label className="medium-text font-16 mb-1">Who do you want to invoice?</label>
        <Dropdown
          isClearable
          options={changedOptions}
          className="w-full"
          placeholder="Select brand"
          name="Choose who makes payments for the subscriptions and charges"
          value={brand_invoice}
          onChange={option => {
            setFinance({ ...finance, brand_invoice: option });
            setError({ ...error, brand_invoice: !option });
          }}
          error={error.brand_invoice}
          customComponents={{ Option: FinanceOption }}
        />
      </div>
      <div className="flex-column">
        <label className="medium-text font-16 mb-1">Who pays the invoice?</label>
        <Dropdown
          isClearable
          options={changedOptions}
          className="w-full"
          placeholder="Select brand"
          name="Choose who makes payments for the subscriptions and charges"
          value={brand_invoice_pay}
          onChange={option => {
            setFinance({ ...finance, brand_invoice_pay: option });
            setError({ ...error, brand_invoice_pay: !option });
          }}
          error={error.brand_invoice_pay}
          customComponents={{ Option: FinanceOption }}
        />
      </div>
    </div>
  );
};

const AddAccount = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { modal, setModal } = useContext(OrganisationContext);

  const { organizationId, organizationName, category_tag, onSuccess } = modal?.content || {};

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState('COMPANY_INFO');
  const [companyInfo, setCompanyInfo] = useState({});
  const [address, setAddress] = useState({ addressStep: 'ADDRESS', show_billing_address: false, country_code: 'US' });

  const [ownerInfo, setOwnerInfo] = useState({});
  const [finance, setFinance] = useState({ add_to_brand: category_tag === 'VENUE' });
  const [error, setError] = useState({});
  // const [integration, setIntegration] = useState({});
  const filteredAddAccountSteps = addAccountSteps.filter(({ category_tag_list }) =>
    category_tag_list.includes(category_tag),
  );

  const checkCompanyInfo = () => {
    const { name, email, phone, landline } = companyInfo;

    const errors = {
      name: !name,
      landline: landline && landline.replaceAll(' ').length < 11,
    };
    setError(errors);
    return Object.values(errors).some(err => err === true);
  };

  const checkAddress = () => {
    const { addressStep, postal_address, billing_address } = address || {};
    if (addressStep === 'ADDRESS') {
      const { line1, city, state, postcode } = postal_address || {};
      const errors = {
        line1: !line1,
        city: !city,
        state: !state,
        postcode: !postcode,
      };
      setError(errors);
      return Object.values(errors).some(err => err === true);
    }
    if (addressStep === 'BILLING_ADDRESS') {
      const { line1, city, state, postcode } = billing_address || {};
      const errors = {
        line1: !line1,
        city: !city,
        state: !state,
        postcode: !postcode,
      };
      setError(errors);
      return Object.values(errors).some(err => err === true);
    }
  };

  const checkOwnerInfo = () => {
    const { name, email, phone } = ownerInfo;
    const errors = {
      name: !name || (name && name.trim().split(' ').length < 2),
      email: !emailRegEx.test(email),
    };
    setError(errors);
    return Object.values(errors).some(err => err === true);
  };

  const checkFinance = () => {
    const { add_to_brand, brand_venue } = finance;
    const errors = {
      brand_venue: add_to_brand && !brand_venue,
    };
    setError(errors);
    return Object.values(errors).some(err => err === true);
  };

  const getFormattedAddress = address => {
    const { line1, line2, city, state, postcode, country_code } = address || {};
    return [line1, line2, city, state, postcode, country_code].filter(item => item).join(', ') || '';
  };

  const getAddressRequest = (address, is_postal, is_billing) => {
    const { line1, line2, city, state, postcode, country_code, location } = address || {};
    return {
      formatted_address: getFormattedAddress(address),
      line1: line1 || '',
      line2: line2 || '',
      line3: null,
      city: city || '',
      state: state || '',
      country_code: country_code || '',
      postcode: postcode || '',
      location: location,
      is_primary: is_postal,
      is_billing: is_billing,
      is_postal: is_postal,
    };
  };

  const onAddAccount = () => {
    const { name, email: companyEmail, phone: companyPhone, landline: companyLandline, country_code: companyCountryCode, category } = companyInfo;

    const { postal_address, billing_address, show_billing_address } = address || {};
    const { name: ownerName, email, phone, country_code } = ownerInfo;
    const { add_to_brand, brand_venue, brand_invoice, brand_invoice_pay } = finance;
    const splittedName = ownerName.split(' ');
    const request = {
      name: name,
      parent: [
        organizationId && {
          id: organizationId,
          relationship_type: 'CHILD'
        },
        add_to_brand && brand_venue && brand_venue.id
          ? {
              id: brand_venue.id,
            }
          : null,
      ].filter(item => item),
      category: { id: category?.id },
      code: null,
      unique_tax_reference: null,
      company_registration_number: null,
      location: [
        { ...getAddressRequest(postal_address, true, !show_billing_address) },
        show_billing_address ? { ...getAddressRequest(billing_address, false, true) } : null,
      ].filter(item => item),
      contact_details: [companyEmail ? 
        {
          contact_type: 'EMAIL',
          subtype: null,
          contact_value: companyEmail,
          is_primary: true,
          country_code: null,
        } : null,
        companyPhone
          ? {
              contact_type: 'MOBILE',
              subtype: null,
              contact_value: companyPhone ? `${companyPhone.replaceAll(' ', '')}` : null,
              is_primary: true,
              country_code: companyCountryCode || '+1',
            }
          : null,
        companyLandline
          ? {
              contact_type: 'LANDLINE',
              subtype: null,
              contact_value: companyLandline ? `${companyLandline.replaceAll(' ', '')}` : null,
              is_primary: true,
              country_code: '+1',
            }
          : null,
      ].filter(item => item),
      account_owner: {
        forename: splittedName[0].trim(),
        surname: splittedName[1].trim(),
        title: '',
        email: email,
        phone: phone ? `${phone.replaceAll(' ', '')}` : null,
        country_code: phone ? country_code || '+1' : null,
        employee_number: null,
        is_owner: true,
        badge_number: null,
        role: null,
      },
      invoice_organization:
        brand_invoice && brand_invoice.value
          ? {
              id: brand_invoice.value,
            }
          : null,
      payment_organization:
        brand_invoice_pay && brand_invoice_pay.value
          ? {
              id: brand_invoice_pay.value,
            }
          : null,
      // integration: integration?.id ? { id: integration.id } : null,
      send_invite: true,
    };

    setLoading(true);
    dispatch(createAccount({ request }))
      .then(response => {
        if(category?.tag?.tag === 'SME_CALCULATOR')
        dispatch(getCalculators({ tag: 'SME_CALCULATOR' }))
        .then(c => {
          c.forEach(calc => {
            dispatch(addFormOrganization({formId: calc.form.id, organizationId: response.id}));
          })
        });
        setModal(initModal);
        dispatch(addToast({ text: 'Account added successfully', id: nanoid() }));
        onSuccess && onSuccess(nanoid());
      })
      .catch(error => {
        dispatch(
          addToast({ text: getErrorDescription(error, 'Error while creating account'), error: true, id: nanoid() }),
        );
      })
      .finally(() => setLoading(false));
  };

  const onNextOrAdd = () => {
    if (currentStep === 'COMPANY_INFO') {
      if (checkCompanyInfo()) {
        dispatch(addToast({ text: 'Please fill all the required fields', error: true, id: nanoid() }));
        return;
      }
      setCurrentStep(
        filteredAddAccountSteps[filteredAddAccountSteps.findIndex(({ key }) => key === currentStep) + 1].key,
      );
    }
    if (currentStep === 'ADDRESS') {
      if (checkAddress()) {
        dispatch(addToast({ text: 'Please fill all the required fields', error: true, id: nanoid() }));
        return;
      }
      const { addressStep, show_billing_address } = address || {};
      if (addressStep === 'ADDRESS') {
        if (show_billing_address) {
          setAddress({ ...address, addressStep: 'BILLING_ADDRESS' });
        } else {
          setCurrentStep(
            filteredAddAccountSteps[filteredAddAccountSteps.findIndex(({ key }) => key === currentStep) + 1].key,
          );
        }
      } else {
        setCurrentStep(
          filteredAddAccountSteps[filteredAddAccountSteps.findIndex(({ key }) => key === currentStep) + 1].key,
        );
      }
    }
    if (currentStep === 'OWNER_INFO') {
      if (checkOwnerInfo()) {
        if(error?.name) 
        dispatch(addToast({ text: 'Please enter full name', error: true, id: nanoid() }));
          else
        dispatch(addToast({ text: 'Please fill all the required fields', error: true, id: nanoid() }));
        return;
      }
      if (category_tag === 'ACCOUNT') {
        onAddAccount();
      } else {
        setCurrentStep(
          filteredAddAccountSteps[filteredAddAccountSteps.findIndex(({ key }) => key === currentStep) + 1].key,
        );
      }
    }
    if (currentStep === 'NETWORK') {
      if (checkFinance()) {
        dispatch(addToast({ text: 'Please fill all the required fields', error: true, id: nanoid() }));
        return;
      }
      onAddAccount();
    }
  };

  const onPreviousOrCancel = () => {
    if (currentStep === 'ADDRESS') {
      const { addressStep } = address || {};
      if (addressStep === 'BILLING_ADDRESS') {
        setAddress({ ...address, addressStep: 'ADDRESS' });
      } else {
        setCurrentStep(
          filteredAddAccountSteps[filteredAddAccountSteps.findIndex(({ key }) => key === currentStep) - 1].key,
        );
      }
    } else if (currentStep === 'COMPANY_INFO') {
      setModal(initModal);
    } else {
      setCurrentStep(
        filteredAddAccountSteps[filteredAddAccountSteps.findIndex(({ key }) => key === currentStep) - 1].key,
      );
    }
  };

  return (
    <AddAccountCommonWrapper>
      <CommonPopup
        modalClassName="common-popup"
        popupTitle={'New Organisation'}
        cancelButtonProps={{ label: currentStep === 'COMPANY_INFO' ? t('CANCEL') : t('PREVIOUS') }}
        confirmButtonProps={{ label: currentStep === 'OWNER_INFO' ? t('ADD') : t('NEXT') }}
        onCancel={onPreviousOrCancel}
        onConfirm={onNextOrAdd}
        disabled={loading}>
        <AddAccountWrapper className="flex-column row-gap-6 mt-6 items-center w-full flex-1">
          <div className="flex items-center justify-center col-gap-2">
            {filteredAddAccountSteps.map(({ key, name }, index) => (
              <div key={key} className="flex flex-1 col-gap-2">
                {index !== 0 && <ArrowRightIcon />}
                <label
                  className={classNames(
                    'regular-text font-12 one-line',
                    currentStep === key ? 'semibold-text' : 'main-grey-text',
                  )}>
                  {name}
                </label>
              </div>
            ))}
          </div>
          {currentStep === 'COMPANY_INFO' && (
            <CompanyInfo organizationId={organizationId} companyInfo={companyInfo} setCompanyInfo={setCompanyInfo} error={error} setError={setError} />
          )}
          {currentStep === 'ADDRESS' && (
            <AddressInfo address={address} setAddress={setAddress} error={error} setError={setError} />
          )}
          {currentStep === 'OWNER_INFO' && (
            <OwnerInfo ownerInfo={ownerInfo} setOwnerInfo={setOwnerInfo} error={error} setError={setError} />
          )}
          {currentStep === 'NETWORK' && (
            <Finance
              finance={finance}
              setFinance={setFinance}
              error={error}
              setError={setError}
              category_tag={category_tag}
              organizationId={organizationId}
              companyInfo={companyInfo}
              ownerInfo={ownerInfo}
              organizationName={organizationName}
            />
          )}
        </AddAccountWrapper>
      </CommonPopup>
    </AddAccountCommonWrapper>
  );
};

const AddAccountCommonWrapper = styled.div`
  .common-popup {
    min-height: 680px;
  }
`;

const AddAccountWrapper = styled.div``;

export default AddAccount;
