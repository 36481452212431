import uuid from 'react-uuid'
import api, { cloudinaryApi } from '../../store/services';


const getSignature = async (mediaGroupId) => {
    const trackingKey = uuid()
    const { data } = await api.get(
        `/v3/api/upload/sign?mediaUsageType=PHOTO&folder=crm/${'c74cd62c-4b99-4291-b083-d0ad7aa69c7d'}&trackingKey=${trackingKey}&organisationId=${'c74cd62c-4b99-4291-b083-d0ad7aa69c7d'}&mediaGroupExternalId=${mediaGroupId}`,
    );
    return data;
}

export const uploadMedia = async (file, selectedMediaGroup, addMedia, onUploadProgress) => {
    const signature = await getSignature(selectedMediaGroup?.id);
    if(addMedia)
        addMedia({id: signature.media_external_id, uploading: true, progress: 0})
    const formData = new FormData()
    formData.append('file', file)
    formData.append('api_key', signature['api_key'])
    formData.append('type', 'upload')
    formData.append('folder', signature['upload_options']['folder'])
    formData.append('context', signature['context'])
    formData.append('timestamp', signature['timestamp'].toString())
    formData.append('signature', signature['signature'])

    const { data } = await cloudinaryApi.post(`/${signature.cloud_name}/upload`, formData, { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress: e => onUploadProgress({e, id: signature.media_external_id}) })
    return  {...data, id: data.asset_id, media_external_id: signature.media_external_id};
}