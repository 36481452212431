import React from 'react';
import { ServiceSelectorMenuWrapper } from './profile.styled';

const ServiceSelectorMenu = ({ service, onEdit, onRemove }) => {
  const items = [
    {
      id: 0,
      name: 'Edit',
      action: () => onEdit(service),
    },
    {
      id: 1,
      name: 'Remove',
      action: () => onRemove(service),
    },
  ];

  return (
    <ServiceSelectorMenuWrapper>
      {items.map(item => (
        <div key={item.id} className={`${item.border ? 'border-top-grey' : ''} flex items-center flex-1`}>
          <div
            className="flex items-center flex-1 service-menu-item"
            onClick={e => {
              e.stopPropagation();
              item.action();
            }}>
            <label className="regular-text ssm-name">{item.name}</label>
          </div>
        </div>
      ))}
    </ServiceSelectorMenuWrapper>
  );
};

export default ServiceSelectorMenu;
