import React, { useState, useRef } from "react";

import Menu from "../../DefaultMenu";
import CircularProgressBar from "../../widget/circular-progress-bar/circular-progress-bar";


import AddIcon from '../../../assets/images/plus-white.svg';
import FrontIcon from '../../../assets/images/front-icon.svg';
import BackIcon from '../../../assets/images/back-icon.svg';
import Loading from '../../../assets/images/loading.svg';

import "./style.scss";

const TemplateMediaList = ({media, setFile, changeMedia, addPermission, hasPermission, checkPermission, frontImage, backImage, frontVideo, backVideo}) => {
    const inputFile = useRef(null)
    const imageMenuItems = [{id: 0, name: "Set as Front Image", type: 'image'}, {id: 1, name: "Set as Back Image", type: 'backImage'}, {id: 2, name: "Delete", type: 'delete'}];
    const videoMenuItems = [{id: 0, name: "Set as Front Video", type: 'video'}, {id: 1, name: "Set as Back Video", type: 'backVideo'}, {id: 2, name: "Delete", type: 'delete'}];

    const [showActions, setShowActions] = useState(true);

    const onClick = () => {
       checkPermission(() => inputFile.current.click(), addPermission)
    }

    return (
        <div className="media-grid px-6 pb-6 pt-4">
            <div className="flex items-center justify-center cursor add-media" onClick={onClick}  style={{opacity: hasPermission(addPermission) ? 1 : 0.5}}>
                <div className="flex items-center justify-center add-media-btn">
                    <img src={AddIcon} alt="add" />
                    <input className='file-select-input' type='file' ref={inputFile} onChange={(e) => setFile(e.target.files[0])} />
                </div>
            </div>
            {media?.map(creative => 
            <div key={creative.id}>
                {creative.uploading ?
                    <div className="template-uploading flex items-center justify-center">
                        {creative.uploaded ?
                            <img alt="loading" height="40px" src={Loading} />
                            :
                            <CircularProgressBar percentage={creative.progress * 100} size="40" strokeWidth="4" />
                        }
                    </div>
                    :
                    <div className="template-media relative" onMouseEnter={() => setShowActions(creative.id)} onMouseLeave={() => setShowActions(null)}>
                        {creative.resource_type === 'video' ?
                            <video controls="controls" preload="metadata">
                                <source src={`${creative.url}#t=0.5`} type="video/mp4"/>
                            </video>                  
                            :
                            <img 
                                alt="" 
                                className="template_image"
                                sizes="200px"
                                srcSet={creative.media?.map(m => `${m.url} ${m.width}w`).join(', ')}
                            />
                        }
                        {
                            showActions === creative.id  &&
                            <div className="template-image-menu absolute">
                                <Menu menuItems={creative.resource_type === 'video' ? videoMenuItems : imageMenuItems} background={true} onMenuItemClick={item => changeMedia(item.type, creative)} />
                            </div>
                        }
                        {
                            (creative.media_external_id === frontImage || creative.media_external_id === frontVideo) && 
                            <img src={FrontIcon} alt="" className="creative_type absolute" /> 
                        }
                        {
                            (creative.media_external_id === backImage || creative.media_external_id === backVideo) && 
                            <img src={BackIcon} alt="" className="creative_type absolute" /> 
                        }
                    </div>
                }
                </div>
                )}
        </div>
    )
}

export default TemplateMediaList;
