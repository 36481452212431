import ArrowRightIcon from '../../assets/images/menu/arrow-right-black.svg';
import React, { useContext, useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/images/search-icon.svg';
import { OrganisationContext } from '../../context/organisationContext';
import Pagination from '../../components/pagination/pagination';
import AutomationItem from './automationItem';
import { useEffect } from 'react';
import api from '../../store/services'
import SelectedAutomation from './selected-automation';
import Loading from '../../assets/images/loading.gif';
import useDebounce from '../../helpers/useDebounceHook';
import AutomationWrapper from './automations.styled';
import AutomationFilter from './automation-filter/automation-filter';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import { ReactComponent as AdddIcon } from '../../assets/images/add-icon.svg';
import { getItemFromLocalStorage, setItemInLocalStorage } from '../../helpers/localstorage';

const Automations = () => {
  const { setModal, modal, hasPermission, checkPermission } = useContext(OrganisationContext);

  const [selectedPage, setSelectedPage] = useState(getItemFromLocalStorage('automation-page') || 0);
  const [totalPage, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [automations, setAutomations] = useState([]);
  const [selectedAutomation, setSelectedAutomation] = useState(getItemFromLocalStorage('selected-automation') || {});
  const [loading, setLoading] = useState(false);
  const [automationMenuFilters, setAutomationMenuFilters] = useState(
    getItemFromLocalStorage('automationMenuFilters') ? getItemFromLocalStorage('automationMenuFilters') : {},
  );
  const [focused, setFocused] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 500);
  const previousSearchTextRef = useRef('');
  const [initPage, setInitPage] = useState(selectedPage);

  const createAutomationFilterUrl = automationMenuFilters => {
    if (!automationMenuFilters) {
      return '';
    }
    let url = ``;
    url = automationMenuFilters.owner ? url.concat(`&owner=${automationMenuFilters.owner.id}`) : url;
    url = automationMenuFilters.trigger ? url.concat(`&event_type=${automationMenuFilters.trigger.value}`) : url;
    return url;
  };

  const fetchData = async () => {
    setLoading(true);
    const { data } = await api.get(
      `/v3/api/automations?page=${selectedPage}&size=10&search=${searchText}${createAutomationFilterUrl(
        automationMenuFilters,
      )}`,
    );
    setAutomations(data.content);
    setTotalPages(data.total_pages);
    setLoading(false);
  };

  useEffect(() => {
    if (previousSearchTextRef.current !== debouncedSearchText) {
      if (debouncedSearchText === '') {
        setSelectedPage(initPage);
      } else {
        setSelectedPage(0);
      }
      previousSearchTextRef.current = debouncedSearchText;
    } else {
      if (previousSearchTextRef.current === '' && debouncedSearchText === '') {
        setInitPage(selectedPage);
      }
    }
    fetchData();
  }, [selectedPage, debouncedSearchText, automationMenuFilters.owner, automationMenuFilters.trigger]);

  useEffect(() => {
    if (modal.type === 'automation-success') {
      if (modal.content.isEdit || modal.content.updateAutomationStatus) {
        setAutomations([
          ...automations.map(item =>
            item.id === modal.content.automation.id ? { ...modal.content.automation } : item,
          ),
        ]);
      } else if (modal.content.isDelete) {
        if (automations.length === 1 && selectedPage > 0) {
          setItemInLocalStorage('automation-page', selectedPage - 1);
          setSelectedPage(selectedPage - 1);
        } else {
          fetchData();
        }
      } else {
        onAutomationSelect(modal.content.automation);
        fetchData();
      }
    }
  }, [modal.type]);

  const updateAutomation = (updatedAutomation, updateSelectedAutomation = true) => {
    setAutomations([...automations.map(intg => (intg.id === updatedAutomation.id ? updatedAutomation : intg))]);
    if (updateSelectedAutomation) {
      onAutomationSelect({});
    }
  };

  const handleBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFocused(false);
    }
  };

  const onAutomationSelect = automation => {
    setItemInLocalStorage('selected-automation', automation);
    setSelectedAutomation(automation);
  };

  return (
    <AutomationWrapper>
      {selectedAutomation.id ? (
        <SelectedAutomation
          checkPermission={checkPermission}
          hasPermission={hasPermission}
          selectedAutomation={selectedAutomation}
          setSelectedAutomation={onAutomationSelect}
          updateAutomation={updateAutomation}
        />
      ) : (
        <>
          <div className="flex items-center integration-container">
            <div className="flex items-center">
              <label className="regular-text grey-text hover-underline">Platform</label>
              <img className="mx-2" src={ArrowRightIcon} />
              <label className="bold-text">Automations</label>
            </div>
            <div className="flex items-center">
              <div
                className="add-action"
                onClick={() =>
                  checkPermission(
                    () =>
                      setModal({
                        type: 'add-automation',
                      }),
                    'AUTOMATIONS_MANAGE',
                  )
                }
                style={{ opacity: hasPermission('AUTOMATIONS_MANAGE') ? 1 : 0.5 }}>
                <AdddIcon className="add-action-icon" />
                <p className="medium-text font-14">Add new</p>
              </div>
              <AutomationFilter
                automationMenuFilters={automationMenuFilters}
                setAutomationMenuFilters={setAutomationMenuFilters}
              />
              <div
                className={`integration-search ${focused ? 'search-focused' : ''}`}
                onBlur={e => handleBlur(e)}
                onFocus={() => setFocused(true)}>
                <SearchIcon className="search-icon" />
                <input
                  className="regular-text"
                  onChange={e => setSearchText(e.target.value)}
                  placeholder="Search"
                  value={searchText || ''}
                />
                {focused && (
                  <div
                    className="close"
                    onClick={e => {
                      setSearchText('');
                    }}
                    tabIndex="0">
                    <CloseIcon className="close-icon" />
                  </div>
                )}
              </div>
              <Pagination
                className="integration-pagination"
                selectedPage={selectedPage}
                setSelectedPage={page => {
                  setItemInLocalStorage('automation-page', page);
                  setSelectedPage(page);
                }}
                totalPages={totalPage}
              />
            </div>
          </div>
          {loading ? (
            <div className="flex items-center justify-center content-loader">
              <img alt="loading" height="64px" src={Loading} />
            </div>
          ) : (
            <div className="automation-item-wrapper scroller">
              {automations.map((automation, index) => (
                <AutomationItem
                  automation={automation}
                  checkPermission={checkPermission}
                  hasPermission={hasPermission}
                  index={index}
                  setSelectedAutomation={onAutomationSelect}
                  updateAutomation={updateAutomation}
                />
              ))}
            </div>
          )}
        </>
      )}
    </AutomationWrapper>
  );
};

export default Automations;
