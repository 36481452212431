import React from 'react';

import { CircularProgressBarWrapper } from './circular-progress-bar.styled';

const CircularProgressBar = ({ size, strokeWidth, percentage, strokeColor = ['#24CBB1'] }) => {
  const radius = (size - strokeWidth) / 2;
  const viewBox = `0 0 ${size} ${size}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <CircularProgressBarWrapper>
      <svg height={size} viewBox={viewBox} width={size}>
        <circle className="circle-background" cx={size / 2} cy={size / 2} r={radius} strokeWidth={`${strokeWidth}px`} />
        <circle
          className="circle-progress"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={
            strokeColor.length > 1
              ? percentage >= 100
                ? strokeColor[0]
                : percentage < 50
                ? strokeColor[1]
                : strokeColor[2]
              : strokeColor[0]
          }
          strokeWidth={`${strokeWidth}px`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
    </CircularProgressBarWrapper>
  );
};

export default CircularProgressBar;
