import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import moment from "moment";

import Loading from '../../../assets/images/loading.svg';

import { getConsignements } from "../../../store/features/inventorySlice";
import Header from "../../header";
import AddConsignmentPopup from "../addConsignmentPopup";


import './style.scss'

import { useSelector, useDispatch } from "react-redux";


const InventoryConsignements = ({menuItems, onMenuSelect}) => {
    const dispatch = useDispatch();
    const { consignments, consignmendtLoading } = useSelector(data => data.inventory);
    const {id} = useParams();
    const [add, setAdd] = useState(false)

    useEffect(() => {
        dispatch(getConsignements({id: id, page: 0}));
    }, [id]);

    
    return(
        <div className=''>
               <div className="pb-3">
                    <Header title="Consignments"
                            isSearch={false}
                            isPaged={false}
                            size='small'
                            isAddBtn={true}
                            isEditBtn={false}
                            edit={false}
                            menuItems={menuItems}
                            onMenuItemClick={onMenuSelect}
                            addPermission="INVENTORY_MANAGE"
                            onAdd={() => setAdd(true)}
                        />
                </div>
                <div className="ci-cons-grid ci-cons-header border-top border-right border-left border-bottom">
                    <label className="font-12 semibold-text px-6 py-4">CONSIGNED TO</label>
                    <label className="font-12 semibold-text px-6 py-4">DATE</label>
                    <label className="font-12 semibold-text px-6 py-4">PURCHASED PRICE</label>
                    <label className="font-12 semibold-text px-6 py-4">OPEN BALANCE</label>
                    <label className="font-12 semibold-text px-6 py-4">CURRENT BALANCE</label>
                </div>
                     {consignmendtLoading ?
                    <div className="flex flex-1 items-center justify-center mt-4">
                        <img src={Loading} alt="loading" />
                        <label className="bold-text font-16 ml-2 py-4 light-purple-text">
                            Loading
                        </label>
                    </div>
                    :
                    <div className="ci-consigneds">
                    {consignments.content?.map(ci => 
                        <div key={ci.id} className='ci-cons-grid ci-card cursor border-bottom cursor border-right border-left'>
                            <label className='medium-text px-6 py-3'>{ci.consigned_to?.name}</label>
                            <label className="medium-text px-6 py-3">{ci.consigned_to?.date && moment.unix(ci.consigned_to?.date).format("DD/MM/YYYY")}</label>
                            <label className="medium-text px-6 py-3">{ci.costs.purchase_price || '-'} {ci.costs.currency}</label>
                            <label className="medium-text px-6 py-3">{ci.carbon.open_balance / 1000}</label>
                            <label className="medium-text px-6 py-3">{ci.carbon.current_balance / 1000}</label>
                        </div>
                        )}
                    </div>
                    }
                    {add && <AddConsignmentPopup onCancel={() => setAdd(false)} />}
                </div>
    )
}

export default InventoryConsignements;