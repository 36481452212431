import React, { useEffect} from "react";

import { useSelector, useDispatch } from "react-redux";
import { getSchemaCollections } from "../../../store/features/schemaSlice";
import { ReactComponent as DotsIcon } from '../../../assets/images/dots.svg'
import { useNavigate } from "react-router-dom";

import Image from "../../image";

import './style.scss';

const SchemaCollections = ({schema, menu}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { schemaCollections } = useSelector(data => data.schema);

    useEffect(() => {
        if(schema)
            dispatch(getSchemaCollections(schema.id));
    }, [schema, dispatch]);

    return (
       <div className="flex-column card">
             <div className="flex items-center justify-between px-6 py-5 border-bottom">
                <label className="bold-text font-20">Collections</label>
                {menu}
            </div>
            <div className="flex-column schema-collections">
                {schemaCollections.content?.map(collection => 
                <div key={collection.id} className="flex items-center border-bottom schema-collections-grid cursor" onClick={() => navigate(`/meta/collections/${collection.id}`)}>
                    <div className="flex items-center px-6 py-1 my-1 border-right">
                        <Image className={"tc-image"} size={58} media={collection?.image?.media} />
                        <label className="regular-text ml-2">{collection.name}</label>
                    </div>
                    <div className="border-right px-6 py-5 my-1">
                        <label className="regular-text light-purple-text two-lines">{collection.description}</label>
                    </div>
                    <div className="flex items-center justify-between px-6 py-5 my-1">
                        <div className="flex items-center">
                            <img src={collection.integration?.icon?.active} alt="" className="tc-integration-img" />
                            <label className="regular-text ml-2">{collection.integration?.name}</label>
                        </div>
                        <DotsIcon className="cursor" width={20} /> 
                    </div>
                </div>)}
            </div>
        </div>
    )
}

export default SchemaCollections;
