import React, { useEffect, useState } from "react";

import moment from "moment";
import { useParams } from 'react-router-dom';

import Loading from '../../../assets/images/loading.svg';
import Header from "../../header";

import { getRetirements } from "../../../store/features/inventorySlice";


import './style.scss'

import { useSelector, useDispatch } from "react-redux";


const InventoryRetirements = ({menuItems, onMenuSelect}) => {
    const dispatch = useDispatch();
    const { retirements, retirementsLoading } = useSelector(data => data.inventory);
    const {id} = useParams();
    const [currentPage, setCurrentPage] = useState(0)


    useEffect(() => {
            dispatch(getRetirements({id: id, page: currentPage}));
    }, [id, currentPage]);

    
    return(
        <div className=''>
            <div className="pb-3">
                <Header title="Retirements"
                        isSearch={false}
                        isPaged={true}
                        size='small'
                        isAddBtn={false}
                        isEditBtn={false}
                        edit={false}
                        menuItems={menuItems}
                        onMenuItemClick={onMenuSelect}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={retirements.total_pages}
                    />
            </div>
            <div className="ci-ret-grid ci-ret-header border-top border-right border-left border-bottom">
                    <label className="font-12 semibold-text px-6 py-4">RETIREMENT DATE</label>
                    <label className="font-12 semibold-text px-6 py-4">REGISTRY</label>
                    <label className="font-12 semibold-text px-6 py-4">BENEFICIARY</label>
                    <label className="font-12 semibold-text px-6 py-4">AMOUNT</label>
            </div>
            {retirementsLoading ?
                <div className="flex flex-1 items-center justify-center mt-4">
                    <img src={Loading} alt="loading" />
                    <label className="bold-text font-16 ml-2 py-4 light-purple-text">
                        Loading
                    </label>
                </div>
                :
                <div className="ci-retirements">
                    {retirements.content?.map(ci => 
                        <div key={ci.id} className='ci-ret-grid ret-card cursor border-bottom cursor'>
                            <label className="medium-text px-6 py-3">{moment.unix(ci.retirement_date).format("DD/MM/YYYY")}</label>
                            <label className="medium-text px-6 py-3">{ci.registry?.name || '-'}</label>
                            <label className="medium-text px-6 py-3">{ci.beneficiary?.name || ''}</label>
                            <label className="medium-text px-6 py-3">{ci.blocks?.units/1000}</label>
                        </div>
                        )}
                    </div>
                }
        </div>
    )
}

export default InventoryRetirements;