import { createSlice } from "@reduxjs/toolkit";

export const toastSlice = createSlice({
    name: "toast",
    initialState: {
      toasts: [],
      removedToast: []
    },
    reducers: {
      newToast: (state, { payload }) => {
        state.toasts = [...state.toasts, { ...payload }]
      },
      removeToast: (state, { payload }) => {
        state.toasts = state.toasts.filter(t => t.id !== payload.id)
      }
    }
  });

  export const addToast = (data) => dispatch => {
    dispatch(newToast(data));
    setTimeout(() => {
      dispatch(removeToast(data));
    }, 5000);
  };


export const { newToast, removeToast } = toastSlice.actions;
export default toastSlice.reducer;