import api from '../../store/services'
import React, { useContext, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { OrganisationContext } from '../../context/organisationContext';
import { components } from 'react-select';
import Selected from '../../assets/images/selected.svg';
import { useSelector } from 'react-redux';
import './style.scss'

const MultiValueOption = props => {
  const onSelect = () => {
    props.setValue({ ...props.data });
  };
  return (
    <div className={`tpus-user ${props.isSelected && 'tpus-user-selected'}`} onClick={() => onSelect()}>
      <label className={`regular-text ${props.isSelected && 'bold-text'}`}>{props.label}</label>
      {props.isSelected && <img alt="icon" src={Selected} />}
    </div>
  );
};

const SingleValue = props => {
  const { data, setDropdownOpened, dropdownOpened } = props;
  const getValueLogo = name => {
    if (!name) return '';
    const nameArray = name?.split(' ');
    return (
      <div className="value-logo">
        <label className="bold-text">
          {nameArray?.[0]?.charAt(0)?.toUpperCase()}
          {nameArray?.[1]?.charAt(0)?.toUpperCase()}
        </label>
      </div>
    );
  };
  return (
    <div
      className={`flex items-center h-full ignore-click ${dropdownOpened && 'hide-single-value'}`}
      onClick={() => setDropdownOpened(!dropdownOpened)}>
      {getValueLogo(data?.label)}
      <label style={{ color: 'black' }}>{data?.label}</label>
    </div>
  );
};

function SelectedAtomationDetails({ selectedAutomation, setSelectedAutomation, updateIntegration }) {
  const { modal, setModal, hasPermission, checkPermission } = useContext(OrganisationContext);
  const { user } = useSelector(state => state.user);

  const [selectedAutomationFilter, setSelectedAutomationFilter] = useState({
    ...selectedAutomation,
    owner: {
      ...selectedAutomation.owner,
      label: selectedAutomation.owner?.name || '',
      value: selectedAutomation.owner?.id || '',
    },
  });

  const [inputState, setInputState] = useState('');
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const organisationId = user?.organisations[0]?.external_id;

  const [name, setName] = useState();
  const [description, setDescription] = useState();

  const onChangeStatus = async () => {
    const { is_enabled } = selectedAutomation;
    setModal({
      type: 'automation-action',
      content: {
        automation: selectedAutomation,
        top: true,
        isDelete: false,
        isEnable: !is_enabled,
        isDisable: is_enabled,
      },
    });
  };
  const fetchOwnerOptions = async () => {
    setLoadingOptions(true);
    const defaultOptions = await getOptions('');
    setOptions(defaultOptions);
    setLoadingOptions(false);
  };
  useEffect(() => {
    fetchOwnerOptions();
  }, []);

  useEffect(() => {
    if (modal.type === 'automation-success') {
      if (modal.content.isEdit || modal.content.updateAutomationStatus) {
        setSelectedAutomation(modal.content.automation);
      } else if (modal.content.isDelete) {
        setSelectedAutomation({});
      }
    }
  }, [modal.type]);

  useEffect(() => {
    setSelectedAutomation({
      ...selectedAutomation,
      name: selectedAutomationFilter.name,
      description: selectedAutomationFilter.description,
      owner: selectedAutomationFilter.owner,
    });
  }, [selectedAutomationFilter]);

  const handleChange = value => {
    const newFilter = { ...selectedAutomationFilter, owner: value };
    setSelectedAutomationFilter(newFilter);
    // updateMainFilter(newFilter);
  };
  const handleInputChange = newValue => {
    setInputState(newValue);
    return newValue;
  };

  const getOptions = async inputValue => {
    const { data } = await api.get(`/v1/users?organisation_id=${organisationId}&name=${inputValue}&size=100`);
    return data.content.map(o => ({ ...o, value: o.id, label: o.name }));
  };

  const loadOptions = async inputValue => {
    const options = await getOptions(inputValue);
    return options;
  };
  return (
    <div className="flex container ">
      <div style={{ width: '50%' }}>
        <div>
          <label className="regular-text field-label">Name</label>
          <div>
            <input
              className="popup-input form-input"
              defaultValue={selectedAutomationFilter.name}
              onChange={e => setSelectedAutomationFilter({ ...selectedAutomationFilter, name: e.target.value })}
              placeholder="Automation name"
              type={'text'}
            />
          </div>
        </div>
        <div style={{ width: '90%' }}>
          <div>
            <div className="w-full mb-6 flex-column mb-6 mt-6">
              <label className="regular-text field-label mb-2">Owner</label>
              <AsyncSelect
                className={`options-select-container ${dropdownOpened ? 'opened' : ''}`}
                classNamePrefix="select"
                components={{
                  Option: MultiValueOption,
                  SingleValue: props => (
                    <SingleValue {...props} dropdownOpened={dropdownOpened} setDropdownOpened={setDropdownOpened} />
                  ),
                  Input: props => <components.Input {...props} autoFocus={dropdownOpened} />,
                }}
                defaultOptions={options}
                inputValue={inputState}
                isLoading={loadingOptions}
                loadOptions={loadOptions}
                onBlur={() => {}}
                onChange={handleChange}
                onInputChange={handleInputChange}
                onMenuClose={() => setDropdownOpened(false)}
                onMenuOpen={() => setDropdownOpened(true)}
                placeholder="Select owner"
                value={selectedAutomationFilter.owner ? selectedAutomationFilter.owner : null}
              />
            </div>
          </div>
        </div>
        <div className="flex-1 items-center justify-between mt-8" style={{ marginRight: '5%' }}>
          <span className={`regular-text font-16 mt-4`}>{selectedAutomation.is_enabled ? 'Enabled' : 'Disabled'}</span>
          <div
            className={`switch mr-6 ${selectedAutomation.is_enabled && 'switch-selected'} ${
              hasPermission(selectedAutomation.is_default ? 'GLOBAL_AUTOMATIONS_MANAGE' : 'AUTOMATIONS_MANAGE')
                ? 'cursor'
                : 'not-allowed'
            }`}
            onClick={e => {
              e.stopPropagation();
              checkPermission(
                () => onChangeStatus(),
                selectedAutomation.is_default ? 'GLOBAL_AUTOMATIONS_MANAGE' : 'AUTOMATIONS_MANAGE',
              );
            }}>
            <div className="selector" />
          </div>
        </div>
      </div>
      <div style={{ width: '50%' }}>
        {/* description */}
        <label className="regular-text field-label">Description</label>
        <div>
          <textarea
            className="popup-input form-input description-input"
            defaultValue={selectedAutomationFilter.description}
            onChange={e => setSelectedAutomationFilter({ ...selectedAutomationFilter, description: e.target.value })}
            placeholder="Description"
            rows={7}
            style={{ margin: '15px 0px', width: '100%', resize: 'vertical' }}
          />
        </div>
      </div>
    </div>
  );
}

export default SelectedAtomationDetails;
