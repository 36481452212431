import axios from 'axios';
import { setUser } from "../../store/features/userSlice";

let isRefreshing = false;
let refreshSubscribers = [];

let store;

export const injectStore = _store => {
  store = _store;
};

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 10000,
  params: {},
});

function onRrefreshed(token) {
  refreshSubscribers.map(cb => cb(token));
  refreshSubscribers = [];
}

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data?.errorDescription === 'User is inactive') {
      localStorage.removeItem('user');
      localStorage.removeItem('current-organisation');
      localStorage.removeItem('sidebar-locked');
      localStorage.removeItem('selected-organisation');
      localStorage.removeItem('l-filter');
      localStorage.removeItem('r-filter');
      localStorage.removeItem('current-customers-page');
      window.location.reload();
    }
    if (
      error?.response?.data?.errorDescription === 'Expired jwt token' ||
      error?.response?.data?.errorDescription === 'User permissions expired'
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        getUserData(true)
          .then(newUser => {
            isRefreshing = false;
            onRrefreshed(newUser.access_token);
          })
          .catch(e => {
            localStorage.removeItem('user');
            localStorage.removeItem('current-organisation');
            localStorage.removeItem('sidebar-locked');
            localStorage.removeItem('selected-organisation');
            localStorage.removeItem('l-filter');
            localStorage.removeItem('r-filter');
            localStorage.removeItem('current-customers-page');
            window.location.reload();
          });
      }
      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh(token => {
          error.config.headers.Authorization = 'Bearer ' + token;
          resolve(instance.request(error.config));
        });
      });
      return retryOrigReq;
    }
    return Promise.reject(error);
  },
);

instance.interceptors.request.use(
  async function (config) {
    try {
      config.headers.Authorization = 'Bearer ' + (await getAccessToken());
    } catch (e) {
      localStorage.removeItem('user');
      localStorage.removeItem('current-organisation');
      localStorage.removeItem('sidebar-locked');
      localStorage.removeItem('selected-organisation');
      localStorage.removeItem('l-filter');
      localStorage.removeItem('r-filter');
      localStorage.removeItem('current-customers-page');
      window.location.reload();
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

const getUserData = async (ignore = false) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user.exp - 10000 < new Date().getTime() || ignore) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/authentication/refresh?refresh_token=${user.refresh_token}`,
      {},
    );
    const { dispatch } = store;

    const newUser = {...user, access_token: data.access_token, refresh_token: data.refresh_token, exp: data.exp, 
      organisations: user.organisations.map(o => ({...o, roles : o.roles.map(r => ({...r, permissions: data.permissions}))}))}
    dispatch(setUser(newUser));
    localStorage.setItem("user", JSON.stringify(newUser));
    return newUser;
  } else return user;
};

const getAccessToken = async (ignore = false) => {
  const user = await getUserData(ignore);
  return user.access_token;
};

export const cloudinaryApi = axios.create({
  baseURL: `https://api.cloudinary.com/v1_1`,
  timeout: 20000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'multipart/form-data',
  },
});

export default instance;
