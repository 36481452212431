import React, { useState, useEffect, useContext } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getTemplateAttributes, getMedia, createMedia, saveTemplate, uploadToIPFS } from "../../../store/features/templateSlice";
import TemplateMediaList from "../TemplateMediaList";

import { OrganisationContext } from "../../../context/organisationContext";

import './style.scss';

const TemplateMedia = ({template, menu}) => {
    const dispatch = useDispatch();
    const { media } = useSelector(data => data.template);
    const { checkPermission, hasPermission, setModal } = useContext(OrganisationContext);

    const [deletedMedia, setDeletedMedia] = useState();

    useEffect(() => {
        dispatch(getTemplateAttributes(template.id));
    }, [template]);

    useEffect(() => {
        if(template.id)
            dispatch(getMedia(template.id));
    }, [template, dispatch])


    const uploadMedia = async file => {
        if(file) {
            dispatch(createMedia({templateId: template.id, file: file}))
        }
    }
    const updateTemplate = t => {
        dispatch(saveTemplate({...t, 
            image: getMediaId(t.image),
            back_image: getMediaId(t.back_image),
            video: getMediaId(t.video),
            back_video: getMediaId(t.back_video)
        }))
    }

    const getMediaId = (media) => {
        return media
          ? {
              id:
                media.format === "IPFS"
                  ? media?.public_id
                  : media?.media_external_id,
            }
          : null;
      };

    const changeMedia = async (type, creative) => {
        const c = await dispatch(uploadToIPFS(creative.media[creative.media.length - 1].id))
        if(type === 'image')
            updateTemplate({...template, is_ipfs: true, image: {...c, format: "IPFS"} })
        else if(type === 'backImage')
            updateTemplate({...template, is_ipfs: true, back_image: {...c, format: "IPFS"} })
        else if(type === 'video')
            updateTemplate({...template, is_ipfs: true, video: {...c, format: "IPFS"} })
        else if(type === 'backVideo')
            updateTemplate({...template, is_ipfs: true, back_video: {...c, format: "IPFS"} })
        else if(type === 'delete') {
            setModal({ type: 'delete-media', content: { media: {...creative, templateId: template.id}, top: true } })
        }
    }

    return (
       <div className="flex-column card">
             <div className="flex items-center justify-between px-6 py-5 border-bottom">
                <label className="bold-text font-20">Media</label>
                <div className="flex items-center">
                    {menu}
                </div>
            </div>
            <div className="flex-column template-attributes">
                <TemplateMediaList 
                    primary={template?.image?.media_external_id} 
                     media={media} 
                     setFile={uploadMedia} 
                     deleteMedia={setDeletedMedia} 
                     addPermission="NFT_MANAGE" hasPermission={hasPermission} 
                     checkPermission={checkPermission} 
                     changeMedia={changeMedia}
                     frontImage={template.image?.media_external_id}
                     backImage={template.back_image?.media_external_id}
                     frontVideo={template.video?.media_external_id}
                     backVideo={template.back_video?.media_external_id}
                />
            </div>
        </div>
    )
}

export default TemplateMedia;
