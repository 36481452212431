import React from 'react';
import { MenuWrapper } from './automations.styled';

const Menu = props => {
  const { clickItem, hasPermission, checkPermission } = props;
  return (
    <MenuWrapper className="menu">
      <label className="regular-text" onClick={() => clickItem('DETAILS')}>
        Details
      </label>
      <label className="regular-text" onClick={() => clickItem('LOGS')}>
        Logs
      </label>
    </MenuWrapper>
  );
};

export default Menu;
