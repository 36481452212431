import { ReactComponent as DotsIcon } from '../../assets/images/menu/dots.svg';
import moment from 'moment';
import React, { useState } from 'react';
import { capitalize, formatText } from '../../helpers/utils';
import AutomationLogItemWrapper from './automation-log-item.styled';
import Menu from './log-menu';

const AutomationLogItem = ({ automationLog, setSelectedAutomationLog }) => {
  const [showMenu, setShowMenu] = useState(false);
  const getStatus = al_status => {
    if (!al_status) return 'No status';
    if (al_status === 'Unmatched (verified)') return 'Unmatched';
    else return capitalize(formatText(al_status, ' '));
  };

  const getStatusColor = al_status => {
    switch (al_status) {
      case 'SUCCESS':
        return '#A1D9A0';
      case 'FAILED':
      case 'PARTIAL_FAIL':
      case 'FAILURE':
        return '#FF8888';
      case 'PENDING':
        return '#F3D074';
      case 'CANCELLED':
        return '#73868C';
      case 'SKIPPED':
        return '#91B4C4';
      default:
        return '#73868C';
    }
  };

  return (
    <AutomationLogItemWrapper>
      <div className="automations-logs-row items-center justify-center mt-1">
        <div className="automations-logs-item flex justify-center">
          <label className="regular-text dmi-text">{automationLog.id}</label>
        </div>
        <div className="automations-logs-item flex justify-center">
          <label className="regular-text dmi-text">{automationLog.automation?.name}</label>
        </div>
        <div className="automations-logs-item flex justify-center">
          <label className="regular-text dmi-text">
            {!automationLog.created_on
              ? '-'
              : `${moment(automationLog.created_on * 1000).format('DD/MM/YY - hh:mm A')}`}
          </label>
        </div>
        <div className="automations-logs-item flex justify-center">
          <label
            className="medium-text dmi-text status font-12"
            style={{ background: getStatusColor(automationLog.status) }}>
            {getStatus(automationLog.status) || '-'}
          </label>
        </div>
        <div
          className="automations-logs-item flex items-center flex-row pr-4t menu-container"
          onBlur={() => setShowMenu(true)}
          tabIndex={0}>
          <div className="menu-wrapper flex items-center">
            <div className="flex mr-2 pxy-1 menu-hover">
              <DotsIcon className="menu-btn menu-hover" onClick={() => setShowMenu(!showMenu)} />
            </div>
            {showMenu && <Menu automationLog={automationLog} setSelectedAutomationLog={setSelectedAutomationLog} />}
          </div>
        </div>
      </div>
    </AutomationLogItemWrapper>
  );
};

export default AutomationLogItem;
