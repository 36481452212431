import { createSlice } from "@reduxjs/toolkit";
import api from '../../store/services'

export const automationSlice = createSlice({
    name: "automation",
    initialState: {
      setViewMore: {},
      automationsData: {}
    },
    reducers: {
      setViewMore: (state, { payload }) => {
        state.viewMore = payload;
      }, 
      setAutomationData: (state, { payload }) => {
        state.automationsData = payload;
      }, 
    }
  });


export const { setViewMore, setAutomationData } = automationSlice.actions;
export default automationSlice.reducer;