import { createSlice } from "@reduxjs/toolkit";
import api from "../../store/services";
import { uploadMedia } from "../services/media";
import { addToast } from "./toastSlice";
import uuid from "react-uuid";

export const collectionSlice = createSlice({
  name: "collection",
  initialState: {
    collections: {},
    collection: {},
    collectionNfts: {},
    collectionTemplates: [],
    collectionSchemas: [],
    integrations: [],
    paymentIntegrations: [],
    integration: {},
    mediaGroups: [],
    media: [],
  },
  reducers: {
    addCollections: (state, { payload }) => {
      state.collections = {
        ...payload,
        content: [...state.collections.content, ...payload.content],
      };
    },
    setCollections: (state, { payload }) => {
      state.collections = payload;
    },
    setCollection: (state, { payload }) => {
      state.collection = payload;
    },
    setCollectionNfts: (state, { payload }) => {
      state.collectionNfts = payload;
    },
    setCollectionTemplates: (state, { payload }) => {
      state.collectionTemplates = payload;
    },
    addCollectionTemplates: (state, { payload }) => {
      state.collectionTemplates = [...state.collectionTemplates, ...payload];
    },
    setCollectionSchemas: (state, { payload }) => {
      state.collectionSchemas = payload;
    },
    setIntegrations: (state, { payload }) => {
      state.integrations = payload;
    },
    setPaymentIntegrations: (state, { payload }) => {
      state.paymentIntegrations = payload;
    },
    setIntegration: (state, { payload }) => {
      state.integration = payload;
    },
    setWallets: (state, { payload }) => {
      state.wallets = payload;
    },
    addMediaGroup: (state, { payload }) => {
      state.mediaGroups = [...state.mediaGroups, payload];
    },
    setMediagroups: (state, { payload }) => {
      state.mediaGroups = payload;
    },
    setMedia: (state, { payload }) => {
      state.media = payload;
    },
    addMedia: (state, { payload }) => {
      state.media = [...state.media, payload];
    },
    updateMedia: (state, { payload }) => {
      state.media = state.media.map((m) => (m.id === payload.id ? payload : m));
    },
  },
});

export const getCollections = (payload) => async (dispatch) => {
  let url = `/v3/api/backoffice/collections?page=${payload.page}&size=${
    payload.size || 8
  }`;
  if (payload.integration) url += `&integration_id=${payload.integration}`;
  url += `&${payload.filter}`;
  const { data } = await api.get(url);
  if (payload.merge) dispatch(addCollections(data));
  else dispatch(setCollections(data));
};

export const getCollection = (payload) => async (dispatch) => {
  let url = `/v3/api/backoffice/collections/${payload.id}`;
  if (payload.integration) url += `?integration_id=${payload.integration}`;
  const { data } = await api.get(url);
  dispatch(setCollection(data));
};

export const getCollectionNfts = (payload) => async (dispatch) => {
  let url = `/v3/api/backoffice/collections/${payload.collectionId}/nfts?page=${payload.page}&search=${payload.search}`;
  if (payload.filter) url += `&${payload.filter}`;
  const { data } = await api.get(url);
  dispatch(setCollectionNfts(data));
};

export const getCollectionTemplates = (payload) => async (dispatch) => {
  let url = `/v3/api/backoffice/collections/${payload.collectionId}/templates?search=${payload.search}`;
  if (payload.integration) url += `&integration_id=${payload.integration}`;
  const { data } = await api.get(url);
  dispatch(setCollectionTemplates(data));
};

export const getCollectionSchemas = (payload) => async (dispatch) => {
  const { data } = await api.get(
    `/v3/api/backoffice/collections/${payload.collectionId}/schemas?search=${payload.search}`
  );
  dispatch(setCollectionSchemas(data));
};

export const getCollectionIntegrations = (payload) => async (dispatch) => {
  const { data } = await api.get(`/v3/api/integrations?action=GET_BLOCKCHAIN`);
  dispatch(setIntegrations(data));
};

export const getPaymentIntegrations = (payload) => async (dispatch) => {
  const { data } = await api.get(`/v3/api/integrations?connector_class=12ac1c05-e2f5-4db6-98e1-77b9b79a0014`);
  dispatch(setPaymentIntegrations(data));
};

export const getCollectionWallets = (payload) => async (dispatch) => {
  const { data } = await api.get(
    `/v3/api/organisations/${payload.organisationId}/wallet`
  );
  dispatch(setWallets(data));
};

export const getIntegration = (payload) => async (dispatch) => {
  const { data } = await api.get(`/v3/api/integrations/${payload}`);
  dispatch(setIntegration(data));
};

export const createMediaGroup = (payload) => async (dispatch) => {
  const { data } = await api.post(`/v3/api/mediagroup`, { name: payload.name });
  await api.post(
    `/v3/api/backoffice/collections/${payload.collection}/mediagroups`,
    {
      id: data.id,
    }
  );
  dispatch(addMediaGroup({ id: data.id, name: payload.name }));
};

export const getMediaGroups = (payload) => async (dispatch) => {
  const { data } = await api.get(
    `/v3/api/backoffice/collections/${payload}/mediagroups`
  );
  dispatch(setMediagroups(data.map((mg, i) => ({ ...mg, selected: i === 0 }))));
};

export const getMedia = (payload) => async (dispatch) => {
  const { data } = await api.get(
    `/v3/api/backoffice/collections/${payload}/media`
  );
  dispatch(setMedia(data));
};

export const cteateCollectionTemplates = (payload) => async (dispatch) => {
  try {
    const { data } = await api.post(
      `/v3/api/backoffice/collections/${payload.id}/templates`,
      payload.templates
    );
    dispatch(addCollectionTemplates(data));
    dispatch(
      addToast({
        error: false,
        title: "Collection templates added",
        text: `Collection templates added`,
        id: uuid(),
      })
    );
  } catch (e) {
    dispatch(
      addToast({
        error: true,
        text: "Error while adding collection templates",
        id: uuid(),
      })
    );
  }
};

export const createMedia = (payload) => async (dispatch) => {
  const media = await uploadMedia(
    payload.file,
    payload.selectedMediaGroup,
    (media) => dispatch(addMedia(media)),
    async ({ e, id }) => {
      dispatch(updateMedia({ id, uploading: true, progress: e.progress }));

      if (e.progress === 1) {
        var counter = 0;
        dispatch(updateMedia({ id, uploading: true, uploaded: true }));

        var timer = setInterval(async () => {
          const { data } = await api.get(
            `/v3/api/asset/medias/${media.media_external_id}`
          );

          if (data.length > 0) {
            dispatch(updateMedia({ ...data[0], id: media.media_external_id }));
          }

          if (counter >= 100 || data.length > 0) {
            clearInterval(timer);
          }

          counter++;
        }, 3000);
      } else {
      }
    }
  );
  await api.post(
    `/v3/api/backoffice/collections/${payload.collectionId}/media`,
    { id: media.media_external_id }
  );
};

export const saveCollection =
  ({ payload, fallbackPayload }) =>
  async (dispatch) => {
    try {
      await api.put(`/v3/api/backoffice/collections/${payload.id}`, payload);
      dispatch(
        addToast({
          error: false,
          title: "Collection updated",
          text: `${payload.name} has been updated`,
          id: uuid(),
        })
      );
    } catch (e) {
      dispatch(
        addToast({
          error: true,
          text: "Error while updating collection",
          id: uuid(),
        })
      );
      dispatch(setCollection(fallbackPayload));
    }
  };

export const {
  setCollections,
  setCollection,
  setCollectionNfts,
  setPaymentIntegrations,
  setCollectionTemplates,
  setCollectionSchemas,
  setIntegrations,
  addCollections,
  setIntegration,
  setWallets,
  setMedia,
  setMediagroups,
  addMedia,
  addMediaGroup,
  updateMedia,
  addCollectionTemplates,
} = collectionSlice.actions;
export default collectionSlice.reducer;
