import styled from 'styled-components';

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: margin 400ms;
  user-select: none;
  * {
    cursor: pointer;
  }

  .pagination-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    background: #ffffff;
    border-radius: 8px;

    .nav-btn {
      margin: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      user-select: none;
      background: #FBFBFB;
    }

    .nav-btn-left {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .nav-btn-right {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .left-shadow {
      right: 23px;
      width: 21px;
      height: 40px;
      background:  linear-gradient(90deg, rgba(243, 243, 243, 0.7) 7.14%, rgba(255, 255, 255, 0) 52.38%);
    }

    .right-shadow {
      left: 42px;
      width: 21px;
      height: 40px;
      background:  linear-gradient(90deg, rgba(243, 243, 243, 0.7) 7.14%, rgba(255, 255, 255, 0) 52.38%);
    }

    .pages {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow-x: scroll;
      scrollbar-width: none;
      transition: width 400ms;
      padding: 0 1px;
      background: #FBFBFB;

      ::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
      }

      .page {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 50px;

        label {
          color: #d3dfe5;
          width: 32px;
          height: 32px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        label:hover {
          background: rgba(211, 223, 229, 0.2);
        }
      }

      .selected-page {
        label {
          color: #053149;
        }
        label:hover {
          background: #ffffff;
        }
      }
    }
  }
`;
export default PaginationWrapper;
