import React, { useEffect, useCallback, useRef } from "react";

import DateView from "../../Elements/DateView";
import DropDown from "../../McDropdown";

import { useDispatch, useSelector } from "react-redux";
import { setRetirement } from "../../../store/features/retirementSlice";

import { getRetirementReasons } from "../../../store/features/nftSlice";
import { getRegistries } from "../../../store/features/registrySlice";
import { getAccounts } from "../../../store/features/accountSlice";

import CopyIcon from '../../../assets/images/copy-green.svg'
import OpenIcon from '../../../assets/images/open-green.svg'

const RetirementDetailsComp = ({ retirement, edit }) => {
    const statuses = [{id: 0, name: 'Pending'}, { id: 1, name: 'Retired'}];

    const { retirementReasons } = useSelector(data => data.nft);
    
    const { registries } = useSelector(data => data.registry);
    const pageRefBeneficiary = useRef(null);
    const { accounts } = useSelector(data => data.account);
    const { user, organisations } = useSelector(data => data.user);
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRetirementReasons())
        dispatch(getRegistries())
    }, [])

    const fetchAccounts  = useCallback((page, merge, user)  => {
        pageRefBeneficiary.current = page;
        dispatch(getAccounts({organisationId: user.organisations[0].external_id, page: page, merge: merge, type: '', search: ''}));
    }, [dispatch]);

    useEffect(() => {
        fetchAccounts(0, false, user);
    }, [user, fetchAccounts]);

  return (
    <div>
      {edit ? (
        <div className="flex-column">
          <div className="retirement-grid1 px-6 mt-4">
            <div className="flex-column">
              <label className="input-label font-12">Registry</label>
              <DropDown
                size="large"
                placeholder="Select"
                selected={retirement.registry}
                setSelected={(c) =>
                  dispatch(setRetirement({ ...retirement, registry: c }))
                }
                options={registries}
              />
            </div>
            <div className="flex-column ml-6" style={{ width: "224px" }}>
              <label className="input-label font-12">Status</label>
              <DropDown
                size="large"
                placeholder="Select"
                selected={retirement.is_pending ? statuses[0] : statuses[1]}
                setSelected={(c) =>
                  dispatch(
                    setRetirement({ ...retirement, is_pending: c.id === 0 })
                  )
                }
                options={statuses}
              />
            </div>
          </div>
          <div className="flex-column mx-6 mt-6">
            <label className="input-label font-12">Serial number</label>
            <input
              className="input"
              value={retirement?.serial_number || ""}
              placeholder="Enter serial number"
              onChange={(e) =>
                dispatch(
                  setRetirement({
                    ...retirement,
                    serial_number: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="flex-column mx-6 mt-6">
            <label className="input-label font-12">Certificate url</label>
            <input
              className="input"
              value={retirement?.offset_url || ""}
              placeholder="Enter url"
              onChange={(e) =>
                dispatch(
                  setRetirement({ ...retirement, offset_url: e.target.value })
                )
              }
            />
          </div>
          <div className="flex-column mx-6 mt-6">
            <label className="input-label font-12">Transparency url</label>
            <input
              className="input"
              value={retirement?.transparency_url || ""}
              placeholder="Enter url"
              onChange={(e) =>
                dispatch(
                  setRetirement({
                    ...retirement,
                    transparency_url: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="flex-column mx-6 mt-6">
            <label className="input-label font-12">Retirement reason</label>
            <DropDown
              size="large"
              placeholder="Select"
              selected={retirement.reason}
              setSelected={(c) =>
                dispatch(setRetirement({ ...retirement, reason: c }))
              }
              options={retirementReasons}
            />
          </div>
          <div className="flex-column mx-6 mt-6">
            <label className="input-label font-12">Beneficiary Account</label>
            <DropDown
              size="large"
              placeholder="Select"
              selected={retirement.beneficiary_account}
              setSelected={(c) =>
                dispatch(
                  setRetirement({ ...retirement, beneficiary_account: c })
                )
              }
              options={[
                ...organisations.map((o) => ({ ...o, id: o.external_id })),
                ...accounts.content,
              ]}
              isPaged={true}
              hasMore={!accounts.last}
              fetchData={() =>
                fetchAccounts(pageRefBeneficiary.current + 1, true, user)
              }
            />
          </div>
          <div className="flex-column mx-6 mt-6 mb-6">
            <label className="input-label font-12">Carbon Beneficiary</label>
            <input
              className="input"
              value={retirement?.beneficiary || ""}
              placeholder="Enter url"
              onChange={(e) =>
                dispatch(
                  setRetirement({ ...retirement, beneficiary: e.target.value })
                )
              }
            />
          </div>
        </div>
      ) : (
        <div className="flex-column">
          <div className="retirement-grid1">
            <div className="flex items-center px-6 py-3 my-1">
              <label className="regular-text light-purple-text font-12">
                Registry:
              </label>
              <label className="regular-text ml-2">
                {retirement.registry?.name}
              </label>
            </div>
            <div className="flex items-center  px-6 py-3 my-1">
              <label className="regular-text light-purple-text font-12">
                Status:
              </label>
              <div
                className="flex items-center justify-center retirement-state px-2 ml-2"
                style={{
                  background: retirement.is_pending ? "#FBBF66" : "#5CCFF3",
                }}
              >
                <label className="medium-text white-text font-10">
                  {retirement?.is_pending ? "PENDING" : "RETIRED"}
                </label>
              </div>
            </div>
          </div>

          <div className="flex items-center px-6 py-3 my-1 border-top">
            <label className="regular-text light-purple-text font-12">
              Serial number:
            </label>
            <label className="regular-text ml-2 ">
              {retirement.serial_number}
            </label>
          </div>
          <div className="flex items-center px-6 py-3 my-1 border-top relative retirement-url">
            <label className="regular-text light-purple-text font-12">
              Certificate url:{" "}
            </label>
            <div className="flex items-center ml-2">
              <label
                className="regular-text cursor"
                onClick={() => {
                  window.open(retirement.offset_url, "_blank");
                }}
              >
                {retirement.offset_url || "-"}
              </label>
              <div className="retirement-details-actions absolute">
                <img
                  src={OpenIcon}
                  alt="copy"
                  className="cursor mr-4"
                  onClick={() => {
                    window.open(retirement.offset_url, "_blank");
                  }}
                />
                <img
                  src={CopyIcon}
                  alt="copy"
                  className="cursor"
                  onClick={() => {
                    navigator.clipboard.writeText(retirement.offset_url);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center px-6 py-3 my-1 border-top relative retirement-url">
            <label className="regular-text light-purple-text font-12 one-line">
              Transparency url:{" "}
            </label>
            <div className="flex items-center ml-2 one-line">
              <label
                className="regular-text one-line mr-12 cursor"
                onClick={() => {
                  window.open(retirement.transparency_url, "_blank");
                }}
              >
                {retirement.transparency_url || "-"}
              </label>
              <div className="retirement-details-actions absolute">
                <img
                  src={OpenIcon}
                  alt="copy"
                  className="cursor mr-4"
                  onClick={() => {
                    window.open(retirement.transparency_url, "_blank");
                  }}
                />
                <img
                  src={CopyIcon}
                  alt="copy"
                  className="cursor"
                  onClick={() => {
                    navigator.clipboard.writeText(retirement.transparency_url);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center border-top">
            <div className="flex items-center px-6 py-3 my-1 flex-1">
              <label className="regular-text light-purple-text font-12">
                Campaign:
              </label>
              <label className="regular-text ml-2 ">
                {retirement.campaign?.name || "-"}
              </label>
            </div>
            <div className="flex items-center px-6 py-3 my-1 flex-1">
              <label className="regular-text light-purple-text font-12">
                Retirement reason:{" "}
              </label>
              <label className="regular-text ml-2 ">
                {retirement.reason?.name || "-"}
              </label>
            </div>
          </div>
          <div className="flex items-center border-top">
            <div className="flex items-center px-6 py-3 my-1 flex-1">
              <label className="regular-text light-purple-text font-12">
                Beneficiary Account:
              </label>
              <label className="regular-text ml-2 ">
                {retirement.beneficiary_account?.name}
              </label>
            </div>
            <div className="flex items-center px-6 py-3 my-1 flex-1">
              <label className="regular-text light-purple-text font-12">
                Contact:{" "}
              </label>
              <label className="regular-text ml-2 ">
                {retirement.contact?.business_name}
                {retirement.contact?.business_name ? " - " : ""}
                {retirement.contact?.name || "-"}
              </label>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex items-center px-6 my-1 flex-1">
              <label className="regular-text light-purple-text font-12">
                Carbon Beneficiary:
              </label>
              <label className="regular-text ml-2 ">
                {retirement.beneficiary || "-"}
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RetirementDetailsComp;