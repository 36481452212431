import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getInventories } from "../../../store/features/inventorySlice";
import { getRetirementReasons } from "../../../store/features/nftSlice";
import { getRegistries } from "../../../store/features/registrySlice";

import Image from "../../image";
import DropDown from "../../McDropdown";
import CarbonDropDown from "../../CarbonDropdown";
import Button from "../../Elements/button/button";

import CopyIcon from '../../../assets/images/copy-green.svg'
import OpenIcon from '../../../assets/images/open-green.svg'

import './style.scss'

const EditCampaignNftRetirementDetails = ({ campaignProject, retirement, setRetirement }) => {
    const dispatch = useDispatch();

    const { inventories } = useSelector(data => data.inventory);
    const { retirementReasons } = useSelector(data => data.nft);
    const { registries } = useSelector(data => data.registry);
    const project = campaignProject.project;

    useEffect(() => {
        dispatch(getInventories({page: 0, projectId: campaignProject.project.id}))
    }, [])

    useEffect(() => {
        dispatch(getRetirementReasons())
        dispatch(getRegistries())
    }, [])

    return(
        <div className="flex-column">
            <div className="flex items-center pxy-6">
                <div className="flex-column flex-1 mr-6">
                    <label className="regular-text font-12 light-purple-text mb-2">Carbon</label>
                    <CarbonDropDown
                        size="large" 
                        options={inventories.content} 
                        selected={retirement.carbon_inventory} 
                        setSelected={e => setRetirement({...retirement, carbon_inventory: e})} 
                        placeholder="Select" 
                    />
                </div>
                <div className="flex items-center flex-1">
                    <div className="flex-column flex-1">
                        <label className="regular-text font-12 light-purple-text mb-2">Amount</label>
                        <input className="edit-retirement-input" type='number' min="0" step="1"  value={retirement.co2_offset_amount || ''} onChange={e => e.target.validity.valid ? setRetirement({...retirement, co2_offset_amount: e.target.value}) : {}} />
                    </div>
                    <div className="flex-column flex-1 ml-6">
                        <label className="regular-text font-12 light-purple-text mb-2">Units</label>
                        <DropDown 
                            size="large" options={[{id: 0, name: "VCU"}]}
                            selected={retirement.unit_type} 
                            setSelected={e => setRetirement({...retirement, unit_type: e})} 
                            placeholder="Select" 
                        />
                    </div>
                </div>
            </div>
            <div className="flex items-center px-6">
                <div className="flex-column flex-1">
                    <label className="regular-text font-12 light-purple-text mb-2">Project</label>
                    <div className="flex items-center">
                        <Image className={"retirement-project-image"} size={48} media={project?.image?.media} />
                        <div className="flex-column ml-2">
                            <label className="regular-text font-16">{project?.name}</label>
                            <label className="regular-text font-12 light-purple-text one-line edit-retirement-project-description mt-1">{project?.description}</label>
                        </div>
                    </div>
                </div>
                <div className="flex-column flex-1 ml-6">
                    <label className="regular-text font-12 light-purple-text mb-2">Registry</label>
                    <DropDown 
                        size="large" 
                        options={registries}
                        selected={retirement.registry} 
                        setSelected={e => setRetirement({...retirement, registry: e})} 
                        placeholder="Select"     
                    />
                </div>
            </div>
            <div className="flex-column mt-6 mx-6">
                <label className="regular-text font-12 light-purple-text mb-2">Serial number</label>
                <input className="edit-retirement-input"  value={retirement.serial_number || ''} onChange={e => setRetirement({...retirement, serial_number: e.target.value})} />
            </div>
            <div className="flex-column mt-6 mx-6">
                <label className="regular-text font-12 light-purple-text mb-2">Offset url</label>
                <input className="edit-retirement-input"  value={retirement.offset_url || ''} onChange={e => setRetirement({...retirement, offset_url: e.target.value})} />
            </div>
            <div className="flex items-center pxy-6">
                <div className="flex-column flex-1 mr-6">
                    <label className="regular-text font-12 light-purple-text mb-2">Retirement reason</label>
                    <DropDown 
                        size="large"
                        options={retirementReasons} 
                        selected={retirement.reason} 
                        setSelected={e => setRetirement({...retirement, reason: e})} 
                        placeholder="Select" 
                    />
                </div>
                <div className="flex-column flex-1" />
            </div>
        </div>
    )
}

export default EditCampaignNftRetirementDetails