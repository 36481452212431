import React, { useState} from "react";
import moment from "moment";

import CampaignForm from "../CampaignForm";

import { capitalize, formatText } from "../../../helpers/utils";

import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";

    
import './style.scss'

const CampaignDetailsComp = ({campaign }) => {

    const [edit, setEdit] = useState(false)


    return(
        <div className="flex-column pxy-6 campaign-details-comp">
            {!edit ?
            <div className="flex-column">
                <div className="flex justify-between">
                    <div className="flex-column">
                        <label className="semibold-text font-20 mt-2">{campaign.name}</label>
                        <label className="regular-text light-purple-text mt-2 mb-2 font-16">{campaign.description}</label>
                    </div>
                    <div className="flex items-center justify-center cursor no-select capaign-edit-btn" onClick={() => setEdit(!edit)}>
                        <EditIcon />
                        <label className="semibold-text font-12 mid-green-text ml-1">
                            Edit
                        </label>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex flex-1">
                        <div className="flex-column">
                        <label className="regular-text light-purple-text font-16 mt-6">Start:</label>
                        <label className="regular-text light-purple-text font-16 mt-6">End:</label>
                        <label className="regular-text light-purple-text font-16 mt-6">Blockchain:</label>
                        <label className="regular-text light-purple-text font-16 mt-6">Blockchain receiver:</label>
                        <label className="regular-text light-purple-text font-16 mt-6">Payment integration:</label>
                        </div>
                        <div className="flex-column ml-8">
                            <label className="medium-text font-16 mt-6">{moment.unix(campaign.effective?.start).format('DD/MM/yyyy') || '-'}</label>
                            <label className="medium-text font-16 mt-6">{moment.unix(campaign.effective?.end).format('DD/MM/yyyy') || '-'}</label>
                            <div className="flex items-center justify-between  mt-6">
                            {campaign.integration?.id ?
                             <div className="flex">
                                <img className="ci-icon" alt="icon" src={campaign.integration?.icon?.active} />
                                <label className="medium-text font-16 ml-2">{campaign.integration?.name}</label>
                            </div> :
                            <label className="medium-text font-16 ml-2">-</label>
                            }
                            </div>
                            <label className="medium-text  mt-6">{campaign.account || '-'}</label>
                            <div className="flex items-center justify-between  mt-6">
                            {campaign.payment_integration?.id ?
                             <div className="flex">
                                <img className="ci-icon" alt="icon" src={campaign.payment_integration?.icon?.active} />
                                <label className="medium-text font-16 ml-2">{campaign.payment_integration?.name}</label>
                            </div> :
                            <label className="medium-text font-16 ml-2">-</label>
                            }
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-1 ml-6">
                        <div className="flex">
                            <div className="flex-column">
                                <label className="regular-text light-purple-text font-16 mt-6">Campaign Type:</label>
                                <label className="regular-text light-purple-text font-16 mt-6">Collection:</label>
                                <label className="regular-text light-purple-text font-16 mt-6">Beneficiary:</label>
                                <label className="regular-text light-purple-text font-16 mt-6">Beneficiary Wallet address:</label>
                            </div>
                            <div className="flex-column ml-8">
                                <label className="medium-text font-16 mt-6">{capitalize(formatText(campaign?.campaign_type)) || '-'}</label>
                                <label className="medium-text font-16 mt-6">{campaign.collection?.name || '-'}</label>
                                <label className="medium-text font-16 mt-6">{campaign.beneficiary?.name || '-'}</label>
                                <label className="medium-text font-16 one-line mt-6">{campaign.beneficiary?.wallet?.alias || campaign.beneficiary?.wallet?.wallet_address || '-'}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <CampaignForm c={campaign} cancel={() => setEdit(!edit)} />
        }
        </div>
    )
}

export default CampaignDetailsComp;