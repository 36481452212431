import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { OrganisationContext } from '../../context/organisationContext';
import { setViewMore } from '../../store/features/automationSlice';
import { MenuWrapper } from './automations.styled';

const Menu = ({ automationLog }) => {
  const { setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();

  const onCancel = () => {
    setModal({
      type: 'cancel-automation-log',
      content: {
        automationLog,
        top: true,
      },
    });
  };
  return (
    <MenuWrapper className="menu">
      <label
        className="regular-text"
        onClick={() => {
          dispatch(setViewMore({ status: true, data: automationLog }));
        }}
        style={{ cursor: 'pointer' }}>
        View more
      </label>
    </MenuWrapper>
  );
};

export default Menu;
