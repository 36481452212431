import React from 'react'

import '../../assets/css/components/dashboard/top-merchants.scss'

const TopMerchants = ({rankings, unit}) => {
    return (
        <div className='top-merchants card flex-column'>
            <label className='bold-text ml-6'>TOP ACCOUNTS</label>
            <div className='merchants'>
                {rankings?.map((ranking, i) =>
                    <div key={i} className='merchant'>
                        <div className='m-left'>
                            <div className='m-number'>
                                <label>{i + 1}</label>
                            </div>
                            <div className='m-names items-center'>
                                <label className='m-name'>{ranking.sale && ranking.sale.name ? ranking.sale.name : unit === 'TEAM' ? "No project" : 'No wallet'}</label>
                            </div>
                        </div>
                        <div className='m-right'>
                            <label className='m-value'>{ranking.number?.toLocaleString()}</label>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default TopMerchants;