import styled from 'styled-components';

export const TeamDetailsWrapper = styled.div`
  margin-top: 60px;
  padding: 5px 20px 20px 20px;

  .font-20{
    font-size: 20px;
  }

  .add-team-member {
    display: flex;
    align-items: center;
    height: 32px;
    width: 104px;
    background: #31ddca4d;
    border-radius: 10px;
    cursor: pointer;
    padding-left: 9px;

    &:hover {
      background: #2fedd84d;
    }
  }

  .team-details-left {
    background: #ffffff;
    box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
    border-radius: 16px;
    height: fit-content !important;
    min-width: 360px;
    max-width: 360px;

    .t-users {
      padding: 4px;
      background: rgba(210, 229, 237, 0.3);
      border-radius: 4px;
    }
    .title-text {
      font-size: 20px;
      color: #053149;
    }
    .title-m {
      margin: 24px 24px 16px 24px;
    }
    .input-m {
      margin: 24px;
    }

    .note-input {
      textarea {
        width: 100%;
        border: 1px solid #d2e5ed;
        border-radius: 8px;
        padding: 10px 15px;
        resize: none;
        outline: none;
        font-family: Roboto regular;
        font-size: 16px;
        color: #053149;
        min-height: 100px;

        &::-webkit-input-placeholder {
          opacity: 0.5;
          color: #053149;
        }
      }
    }

    .action-container {
      column-gap: 24px;
    }

    .options-select-container {
      max-width: 420px;

      .select__placeholder {
        color: #053149;
        opacity: 0.5;
        font-family: Roboto Regular;
      }

      .select__value-container {
        padding-left: 15px;
      }

      .select__control {
        border-radius: 10px;
        border: 1px solid #d2e5ed;

        &:hover {
          background: #F4F7F9;
        }
      }

      .select__control--is-focused {
        border: 1px solid #31DDCA;
        box-shadow: unset;
      }

      .select__multi-value {
        background: rgba(210, 229, 237, 0.3)
        border-radius: 4px;
      }
      .select__multi-value__label {
        color: #053149;
        font-size: 16px;
        font-family: Roboto Regular;
      }

      .select__clear-indicator,
      .select__indicator-separator {
        display: none;
      }
    }

    .opened {
        .select__control {
          border-radius: 8px;
          border: 1px solid #31ddca;
        }
        .select__value-container {
      .select__input-container {
        display: flex;

        input {
          height: 26px;
        }
      }
    }
    .hide-single-value {
      display: none;
    }
      }

    .tpus-user {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      margin: 0 8px;
      cursor: pointer;

      label {
        width: 100%;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .tpus-user:hover {
      background: rgba(210, 229, 237, 0.3);
      border-radius: 4px;
      cursor: pointer;
    }

    .field-label {
      color: #73868c;
      font-size: 14px;
    }

    .popup-input {
      margin-bottom: unset;
      padding: 10px 15px;
      border-radius: 12px;
      width: 312px;

      &:focus{
        border:1px solid #31ddca;
      }
    }

    .input-w{
      width: 312px !important;
    }

    .error-input {
      border-color: red;
    }

    .error-border {
      .select__control {
        border-color: red;
      }
    }

    .service{
      background: #F4F7F9;
      border-radius: 6px;
      align-items: center;
      padding: 4px;
    }

    .shared-tooltip {
      .shared-tooltip-details {
        position: relative;
      }
      .tooltip-content {
        height: fit-content;
        width: fit-content;
        max-width: fit-content;
        z-index: 10;
        margin: 4px 0;
        box-shadow: 2px 8px 30px rgb(5 49 73 / 10%);
        padding: 12px 16px;

        &::before {
          width: 0px;
          height: 0px;
        }

        #arrow,
        #arrow::before {
          position: absolute;
          width: 16px;
          height: 16px;
          background: inherit;
          background: #ffffff;
        }

        #arrow {
          visibility: hidden;
        }

        #arrow::before {
          visibility: visible;
          content: '';
          transform: rotate(45deg);
        }
      }

      .tooltip-content[data-placement^='top'] > #arrow {
        bottom: -6px;
      }

      .tooltip-content[data-placement^='bottom'] > #arrow {
        top: -6px;
      }

      .tooltip-content[data-placement^='left'] > #arrow {
        right: -6px;
        top: 50%;
      }

      .tooltip-content[data-placement^='right'] > #arrow {
        left: -6px;
      }

      .appointment-tooltip-directions[data-placement^='left'] > #arrow {
        right: -6px;
        top: -40px !important;
      }
    }
  }

  .data-match-row {
    display: grid;
    align-items: center;
    grid-template-columns: 5fr 0.1fr 5fr;
    border-bottom: 1px solid #d2e5ed;
  }

  .border-right {
    border-right: 1px solid #d2e5ed;
    min-height: 78px;
    height: 100%;
  }

  .hr-line {
    margin: 0px;
    height: 1px;
  }

 .scroll-wrapper {
    height: calc(100vh - 170px);
    overflow: auto;

    .infinite-scroll-component__outerdiv {
      height: calc(100% - 10px);
    }
 }

  .team-user {
    background: #ffffff;
    box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
    border-radius: 16px;
    height: fit-content !important;

    .name-column {
      min-width: 220px;
    }

    .contact-column {
      min-width: 340px;
    }

    .contact-email {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .contact-mobile {
      overflow: 'hidden';
      text-overflow: 'ellipsis';
      white-space: 'nowrap';
    }

    .flex-wrap {
      flex-wrap: wrap;
    }

    .urut-right {
      display: flex;
      flex-direction: row;
      flex: 1 1;
      justify-content: end;
      align-items: center;
      position: relative;
      outline: none;

      .uru-state {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #9ed086;
        border-radius: 4px;
        font-family: Roboto Medium;
        font-size: 12px;
        color: #ffffff;
        padding: 5px 8px;
      }

      .menu-btn {
        cursor: pointer;
        padding: 0 8px;
      }

      .urut-menu {
        position: absolute;
        right: 20px;
        top: -5px;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        box-shadow: 0px 10px 16px rgba(9, 19, 53, 0.1);
        border-radius: 4px;
        overflow: hidden;

        .urut-menu-item * {
          cursor: pointer;
        }

        .urut-menu-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 20px 24px;
          cursor: pointer;

          label {
            font-family: Roboto Medium;
            font-size: 16px;
            color: #092c40;
            margin-left: 5px;
          }
        }

        .urut-menu-item:hover {
          background: #f8f8f8;
        }

        .urut-state {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid rgb(231, 234, 236);
          padding: 15px;

          label {
            font-family: Roboto Medium;
            font-size: 16px;
            color: #092c40;
            margin-left: 5px;
          }

          .slider {
            cursor: pointer;
          }
        }

        .active-selector {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid #e5e5e5;
          padding: 24px;

          label {
            font-family: Roboto Medium;
            font-size: 16px;
            color: #092c40;
            margin-left: 5px;
          }

          .switch {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 48px;
            height: 24px;
            background: #d2e5ed;
            box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);
            border-radius: 20px;
            cursor: pointer;

            &:hover {
              background: #e5ebef;
            }

            .selector {
              min-width: 20px;
              min-height: 20px;
              background: #ffffff;
              box-shadow: 1px 0px 5px rgba(5, 49, 73, 0.2);
              border-radius: 50%;
              margin: 0 5px;
              cursor: pointer;
              transition: transform 400ms;
            }
          }

          .switch-selected {
            background: #40f1de;
            box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);

            &:hover {
              background: #40f1de;
            }

            .selector {
              transform: translate(22px);
            }
          }
        }
      }
    }

    .menu-container {
      min-width: 24px;
    }

    .uru-roles {
      font-family: Roboto Regular;
      font-size: 14px;
      line-height: 16px;
      color: #73868c;
      opacity: 0.5;
      max-height: 16px;
    }

    .uru-middle {
      padding: 0px 20px;
      height: 100%;
      background: #ffffff;
      font-size: 14px;
      min-height: 80px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 1;
      max-width: calc(100% - 40px);

      .uru-role {
        font-family: Roboto Regular;
        font-size: 14px;
        line-height: 16px;
        color: #73868c;
        opacity: 0.5;
        text-transform: lowercase;
        max-height: 16px;
      }

      .urub-email {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          min-width: 12px;
          min-height: 10px;
        }

        label {
          font-family: Roboto Regular;
          font-size: 14px;
          line-height: 16px;
          color: #092c40;
          margin-left: 10px;
        }

        .not-provided-label {
          color: rgba(5, 49, 73, 0.3);
        }
      }

      .remaining-roles {
        padding: 4px 6px;
        background: #f4f7f9;
        border-radius: 4px;
        font-size: 12px;
      }
    }

    .uru-icons {
      background: rgba(210, 229, 237, 0.3);
      border: 1px solid rgba(9, 44, 64, 0.06);
      box-sizing: border-box;
      border-radius: 4px;
      padding: 1px;
    }
  }

  .menu-wrapper {
    position: absolute;
    top: -5px;
    width: 200px;
    right: 35px;
    padding: 4px;
    z-index: 1;

    hr {
      margin: 8px -12px;
      width: 200px;
    }

    .regular-text {
      padding: 8px;
      display: flex;
      align-items: center;
      color: #053149;
      white-space: nowrap;
    }

    .regular-text:hover {
      background: #f4f9f8;
    }

    .border-bottom {
      border-bottom: 1px solid #d2e5ed;
    }
  }

  .menu-position-top {
    bottom: unset;
    top: 0;
  }

  .menu-position-bottom {
    bottom: 0;
    top: unset;
  }

  .properties-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    width: 230px;
    border: 1px solid #e3eef3;
    border-radius: 10px;
    background: #ffffff;
    padding: 0 16px;
    padding-right: 6px;
    user-select: none;
    margin-left: 20px;
    border: 1px solid #d2e5ed;
    margin-right: 24px;

    &:hover {
      background: #f4f7f9;

      input {
        background: #f4f7f9;
      }
    }

      input {
      width: 100%;
      border: none;
      outline: none;
      padding-left: 8px;

      &::-webkit-input-placeholder {
        opacity: 0.5;
      }
    }

    .search-icon {
      height: 16px;
      width: 16px;
      opacity: 0.5;
    }

    .close {
      background: #f4f7f9;
      border-radius: 100px;
      width: 30px;
      height: 24px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    .close-icon {
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }
  }

  .search-focused {
    border: 1px solid #40f1de;
  }
`;
