import React, { useEffect, useState, useRef } from 'react';
import PlaceholderImage from "../../assets/images/nft-placeholder.svg";
import WhitePlaceholderImage from "../../assets/images/white-placeholder.svg";
import UploadImage from "../../assets/images/upload.svg";
import TrashImage from "../../assets/images/trash-white.svg";


import './style.scss'

const Image = ({media, size, className, whitePlaceholder = false, placeholder, uploadable, editable, onUpload, onRemove}) => {
    const inputFile = useRef(null)
    return (
        <div className='relative image-container'>
            {media && media.length > 0 ?
            <img 
                className={className}
                sizes={size}
                srcSet={media?.map(m => `${m.url} ${m.width || 50}w`).join(', ')}
                alt=""
                placeholder={ whitePlaceholder ?  WhitePlaceholderImage : PlaceholderImage }
            />
            :
            <img 
                className={className}
                alt=""
                src={whitePlaceholder ?  WhitePlaceholderImage : PlaceholderImage }
        />
            }
            {(!media || media?.length === 0) && uploadable &&
                <div className='flex-column items-center cursor absolute upload-btn' onClick={() => inputFile.current.click()}>
                    <img src={UploadImage} alt="upload" />
                    <label className='bold-text font-12 mt-2'>Upload image</label>
                    <input className='file-select-input' type='file' ref={inputFile} onChange={(e) => onUpload(e.target.files[0])} />
                </div>
            }
            {editable && media && media.length > 0 &&
            <div className='image-actions flex-column absolute'>
                <div className='flex items-center justify-center cursor image-action' onClick={onRemove}>
                    <img src={TrashImage} alt="delete" />
                </div>
            </div>
            }
        </div>
        
    );
};

export default Image;
