import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaigns } from "../../store/features/campaignSlice";
import Header from "../../components/header";
import CampaignCard from "../../components/campaign/CampaignCard";
import { useNavigate } from "react-router-dom";
import { OrganisationContext } from "../../context/organisationContext";
import AddCampaignPopup from "../../components/campaign/AddCampaignPopup";

import './style.scss';

const Campaigns = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { campaigns } = useSelector(data => data.campaign);
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('');
    const { checkPermission, hasPermission } = useContext(OrganisationContext);
    const [showAdd, setShowAdd] = useState(false) 

    const [lifecycles, setLifecycles] = useState([{ id: 1, name: 'ACTIVE', color: '#24CBB1', selected: true},
    { id: 1, name: 'NOT ACTIVE', color: '#FB3535', selected: false}])

    useEffect(() => {
        checkPermission(() => {
            dispatch(getCampaigns({ page: currentPage, search: search, active: lifecycles.find(l => l.selected && l.id === 1)?.name === 'ACTIVE' }));
        }, 'CAMPAIGNS_VIEW');

        setTimeout(() => { if (!hasPermission('CAMPAIGNS_VIEW')) navigate(-1) }, 1000);

    }, [currentPage, dispatch, search, lifecycles]);

    const onCancelAdd = d => {
        // if(d)
        //     navigate(`/commerce/campaigns/${d.id}/details`);
        setShowAdd(false);
    }

    return (
        <div className="flex-column mb-6 mt-2 flex-1">
            <Header
                title={"Campaigns"}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                search={search}
                setSearch={setSearch}
                totalPages={campaigns.total_pages}
                isAddBtn={true}
                addPermission='CAMPAIGNS_MANAGE'
                onAdd={() => setShowAdd(true)}
                children={
                    <div className="flex items-center no-select">
                            {lifecycles?.map(lifecycle => 
                            <div 
                                key={lifecycle.id}
                                className="flex items-center justify-center lifecycle-selector ml-2 cursor" 
                                onClick={() => setLifecycles(lifecycles.map(l => l.id === lifecycle.id ? ({...l, selected: !l.selected}) : l))}
                                style={{background: lifecycle.selected ? lifecycle.color : 'rgb(170, 186, 183, 0.1)'}}
                                >
                                <label className="bold-text font-12" style={{color: lifecycle.selected ? '#FFFFFF' : '#020819'}}>{lifecycle.name}</label>
                            </div>)}
                    </div>}
            />
            <div className="flex-column mx-4 my-1 card">
                <div className="campaigns-ret-grid campaigns-ret-header border-top border-bottom">
                    <label className="font-12 semibold-text px-6 py-4">NAME</label>
                    <label className="font-12 semibold-text px-6 py-4">DESCRIPTION</label>
                    <label className="font-12 semibold-text px-6 py-4">START</label>
                    <label className="font-12 semibold-text px-6 py-4">END</label>
                    <label className="font-12 semibold-text px-6 py-4">TYPE</label>
                    <label className="font-12 semibold-text px-6 py-4">STATUS</label>
            </div>
                <div className="campaigns pb-6">
                    {campaigns.content?.map((campaign, index) => <CampaignCard key={index} campaign={campaign} />)}
                </div>
            </div>
            {showAdd && <AddCampaignPopup cancel={onCancelAdd} />}
        </div>
    )
}

export default Campaigns;
