import React from 'react';
import Button from '../components/Elements/button/button';
import { CancelAutomationLogWrapper } from './modal.styled';
import { CSSTransition } from 'react-transition-group';
import TimeIcon from '../assets/images/time.svg';
import { useContext, useState } from 'react';
import { initModal } from '../constant/InitialData';
import { useDispatch } from 'react-redux';
import { addToast} from '../store/features/toastSlice'
import uuid from 'react-uuid';
import { capitalize, formatText } from '../helpers/utils';
import { OrganisationContext } from "../context/organisationContext";
import api from "../store/services";

const DeleteAutomationAction = () => {
  const { modal, setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { content } = modal;
  const { action, automation, onSuccess, index } = content;
  const onPerformAction = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await api.delete(`/v3/api/automations/${automation.id}/actions/${action.id}`);
      dispatch(
        addToast({
          error: false,
          text: `Action has been deleted successfully`,
          id: uuid(),
        }),
      );
      onSuccess();
      setLoading(false);
      setModal(initModal);
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: 'Failed to delete action',
          id: uuid(),
        }),
      );
      setModal(initModal);
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <CancelAutomationLogWrapper>
        <p className="bold-text font-24 text-center">{'Are you sure you want to delete this action?'}</p>
        {action ? (
          <>
            <div className="mt-6 integration-details-container">
              <div className="flex justify-between items-center action-name">
                <div className="flex integration-name-container">
                  <label className="bold-text font-14">ACTION {index}</label>
                </div>
              </div>
              <hr />
              <div className="content-padding">
                <div className="mb-4 flex">
                  <div className="flex mr-2">
                    {action?.action_type?.icon && (
                      <img
                        // alt="icon"
                        src={`data:image/png;base64,${action?.action_type?.icon}`}
                        style={{ width: 24, height: 24 }}
                      />
                    )}
                  </div>
                  <div className="flex-column">
                    <label className="medium-text font-14 mt-1">{action?.action_type?.name}</label>
                    <label className="flex regular-text grey-text font-12">{action?.action_type?.description}</label>
                  </div>
                </div>

                {action?.integration && (
                  <>
                    <hr />
                    <div className="mb-4 mt-2">
                      <div className="flex items-center mb-1">
                        <img
                          className="mr-2"
                          src={`data:image/png;base64,${action?.integration?.connector.icon}`}
                          style={{ width: 25, height: 25 }}
                        />
                        <div className="medium-text font-14">{action?.integration?.connector.name}</div>
                      </div>
                      <div className="mt-4">
                        {action?.params?.length > 0 &&
                          action?.params.map(data => {
                            return (
                              <div className="flex mb-2">
                                <div key={data.id} className="flex">
                                  <label className="regular-text grey-text">
                                    {capitalize(formatText(data.param_type, ' '))}: &nbsp;
                                  </label>
                                  <label className="flex regular-text" style={{ wordBreak: 'break-all' }}>
                                    {data.param_value || data.param_value}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}
                {action?.schedule?.offset !== null && action?.schedule?.unit && action?.schedule?.field && (
                  <>
                    <hr />
                    <div className="flex mt-4 mb-2">
                      <img className="mx-2" src={TimeIcon} />
                      <label className="regular-text">{`${Math.abs(action?.schedule?.offset)}
                      ${formatText(action?.schedule?.unit, ' ')}
                      ${action?.schedule?.offset < 0 ? 'before' : 'after'}
                      ${action?.schedule?.field.toLowerCase().replaceAll('.', ' ')}`}</label>
                    </div>
                  </>
                )}
                {action?.conditions?.length > 0 && (
                  <>
                    <hr />
                    {action?.conditions.map((condition, index) => {
                      return (
                        <div className="mt-2 mb-2">
                          <label className="flex regular-text">
                            {index == 0 ? 'When' : 'And'} {condition?.field} =
                          </label>
                          <label className="flex medium-text">{condition?.value}</label>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center no-action-container mt-4">
            <label className="regular-text grey-text font-20">No action available</label>
          </div>
        )}
        <div className="flex justify-center mt-12">
          <Button
            bgColor="white"
            borderColor="#40F1DE"
            disabled={loading}
            label="Cancel"
            onClick={() => setModal(initModal)}
            size="large"
            style={{ flex: 1 }}
          />
          <Button
            bgColor={'#FF8888'}
            borderColor={'#FF8888'}
            color="white"
            disabled={loading}
            label="Delete"
            onClick={onPerformAction}
            size="large"
            style={{ flex: 1, marginLeft: 24 }}
          />
        </div>
      </CancelAutomationLogWrapper>
    </CSSTransition>
  );
};

export default DeleteAutomationAction;
