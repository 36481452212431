import { createSlice } from "@reduxjs/toolkit";
import api from '../../store/services'

export const analyticsSlice = createSlice({
    name: "analytics",
    initialState: {
      salePeriod: {}, 
      salesPerformance: [],
      rankings: [],
    },
    reducers: {
      setSalePeriod: (state, { payload }) => {
        state.salePeriod = payload;
      },
      setSalesPerformance: (state, { payload }) => {
        state.salesPerformance = payload;
      },
      setRankings: (state, { payload }) => {
        state.rankings = payload;
      },
    }
  });

export const getSalePeriod = (payload) => async (dispatch) => {
  const { data } = await api.get(`/v3/api/backoffice/analytics/sales_period`)
  dispatch(setSalePeriod(data));
};

export const getSalesPerformance = (payload) => async (dispatch) => {
  let url = `/v3/api/backoffice/analytics/sales_performance?start_date=${payload.startDate}&end_date=${payload.endDate}&unit=${payload.unit}`;
  payload.organisations.forEach(o => url += `&tenant_id=${o}`);
  payload.campaigns.forEach(o => url += `&campaign_id=${o}`);
  const { data } = await api.get(url)
  dispatch(setSalesPerformance(data));
};

export const getRankings = (payload) => async (dispatch) => {
  let url = `/v3/api/backoffice/analytics/sales_rankings?page=0&size=10&start_date=${payload.startDate}&end_date=${payload.endDate}&unit=${payload.unit}`;
  payload.organisations.forEach(o => url += `&tenant_id=${o}`);
  payload.campaigns.forEach(o => url += `&campaign_id=${o}`);
  const { data } = await api.get(url)
  dispatch(setRankings(data));
};

export const { setSalePeriod, setSalesPerformance, setRankings } = analyticsSlice.actions;
export default analyticsSlice.reducer;