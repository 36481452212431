import React, { useEffect, useRef } from 'react';

import "../../pages/projectDetails/details.scss";

import Image from "../image";

const NFTInfo = ({nft}) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        textareaRef.current.style.height = '0px';
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + 5 + 'px';
    }, [nft?.description]);

    return (
        <div className="flex-column card pxy-6">
            <Image className={"nft-details-image"} size={306} media={nft?.image?.media} />
            <label className="input-label mt-6">Name</label>
            <input className="input" value={nft?.name}/>
            <label className="input-label mt-6">Description</label>
            <textarea className="textarea" value={nft?.description} ref={textareaRef}/>
        </div>
    )
}

export default NFTInfo;
