import { createSlice } from "@reduxjs/toolkit";
import api from '../../store/services'

export const supporterSlice = createSlice({
    name: "supporter",
    initialState: {
      supporters: {}
    },
    reducers: {
      setSupporters: (state, { payload }) => {
        state.supporters = payload;
      },
    }
  });

  export const getSupporters = (payload) => async (dispatch) => {
    let url = `/v3/api/backoffice/rankings?page=${payload.page}&size=8`;
    if(payload.startDate) url += `&effective.start=${payload.startDate}`
    if(payload.endDate) url += `&effective.end=${payload.endDate}`
    const {data} = await api.get(url)
    dispatch(setSupporters(data));
};


export const { setSupporters } = supporterSlice.actions;
export default supporterSlice.reducer;