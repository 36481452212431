import React, { useEffect, useState, useContext, useCallback, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { deleteCampaignAchievement, getCampaignAchievements } from "../../../store/features/campaignSlice";
import Loading from '../../../assets/images/loading.svg';
import { OrganisationContext } from "../../../context/organisationContext";
import InfiniteScroll from "react-infinite-scroll-component";
import AddIcon from "../../../assets/images/add.svg";
import AddAchievementPopup from "../AddAchievementPopup";
import CampaignAchievement from "../CampaignAchievement";

import './style.scss'

const CampaignAchievements = ({campaignId}) => {
    const dispatch = useDispatch();
    const { setModal } = useContext(OrganisationContext);
    const menuItems = [ {id: 1, name: 'Delete'}];

    const { achievements, loading } = useSelector(data => data.campaign);
    const [c, setC] = useState(0)
    const [lifecycles, setLifecycles] = useState([{ id: 1, name: 'ACTIVE', color: '#24CBB1', selected: true},
    { id: 1, name: 'NOT ACTIVE', color: '#FB3535', selected: false}])
    const [showAdd, setShowAdd] = useState(false) 

    const loadAchievements  = useCallback(async (page, merge)  => {
        dispatch(getCampaignAchievements({campaignId: campaignId, page, merge, active: lifecycles.find(l => l.selected && l.id === 1)?.name === 'ACTIVE'  }));
    }, [campaignId, dispatch, lifecycles]);

    useEffect(() => {
        loadAchievements(0, false);
    }, [loadAchievements]);

    const fetchMoreAchievements = () => {
        loadAchievements(achievements?.number + 1, true);
    };

    const onCancel = data => {
        setShowAdd(false)
        if(data)
        loadAchievements(0, false)
    }

    const onDelete = async achievement => {
        await dispatch(deleteCampaignAchievement({campaignId: campaignId, achievement: achievement }));
        loadAchievements(0, false)
    }


    const onMenuItemClick = (item, achievement) => {
        if(item.name === 'Delete') {
            setModal({ type: 'delete-popup', content: { data: achievement, onDelete} });
        } 
    }

    return(
        <div className="flex-column campaign-achievements">
            <div className="flex items-center justify-between mx-6 my-4 no-select">
                <div>
                <div className="flex items-center no-select">
                        {lifecycles?.map(lifecycle => 
                        <div 
                            key={lifecycle.id}
                            className="flex items-center justify-center lifecycle-selector mr-4 cursor" 
                            onClick={() => setLifecycles(lifecycles.map(l => l.id === lifecycle.id ? ({...l, selected: !l.selected}) : l))}
                            style={{background: lifecycle.selected ? lifecycle.color : 'rgb(170, 186, 183, 0.1)'}}
                            >
                            <label className="bold-text font-12" style={{color: lifecycle.selected ? '#FFFFFF' : '#020819'}}>{lifecycle.name}</label>
                        </div>)}
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex items-center justify-center cursor action-btn ml-6" onClick={() => setShowAdd(true)}>
                        <img src={AddIcon} alt="add" />
                        <label className="bold-text font-14 violet-light-text ml-2">Add new</label>
                    </div>
                </div>
            </div>
            <div className="camp-ach-grid camp-ach-header border-top border-bottom">
                    <label className="font-12 semibold-text px-6 py-3">NAME</label>
                    <label className="font-12 semibold-text px-6 py-3">TYPE</label>
                    <label className="font-12 semibold-text px-6 py-3">MIN</label>
                    <label className="font-12 semibold-text px-6 py-3">MAX</label>
                    <label className="font-12 semibold-text px-6 py-3">ACTIVE DATES</label>
                    <label className="font-12 semibold-text px-6 py-3">USE HISTORY</label>
                    <label className="font-12 semibold-text px-6 py-3">TEMPLATE</label>
            </div>
            <div className="flex-column campaign-list-camp flex-1">
            {             
                achievements.content?.length > 0 ?
                    <div className="camp-campaign-list" id="scrollableDiv">
                        <InfiniteScroll
                            dataLength={achievements?.content?.length}
                            hasMore={!achievements?.last}
                            loader={
                            <div className="item flex items-center justify-center my-2">
                                <img alt="loading" height="24px" src={Loading} />
                            </div>
                            }
                            next={fetchMoreAchievements}
                            scrollableTarget="scrollableDiv"
                            style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            overflow: "unset",
                            }}
                        >
                    {achievements.content?.map(ach => 
                        <CampaignAchievement key={ach.id} ach={ach} menuItems={menuItems} onMenuItemClick={onMenuItemClick} />
                    )}
                        </InfiniteScroll>   
                    </div>
                :
                <div className="flex items-center justify-center flex-1">
                    <label className="bold-text font-16 light-purple-text">There are no achievements</label>
                </div>
            }
        </div>
        {showAdd && <AddAchievementPopup campaignId={campaignId} cancel={onCancel}  />}
        </div>
    )
}

export default CampaignAchievements;



