import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddressInput from './address-input';

const Address = ({
  address = {},
  handleChange = () => {},
  error = {},
  customLabels = { line1: 'ADDRESS_LINE_1', line2: 'ADDRESS_LINE_2' },
  wrapperClassName = '',
  labelClassName = '',
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('flex-column items-center justify-center w-full row-gap-6', wrapperClassName)}>
      <div className="flex-column w-full row-gap-2 line-1">
        <label className={classNames('regular-text main-grey-text font-12', labelClassName)}>
          Line1
        </label>
        <input
          autoComplete="turnoff"
          className={classNames('input radius-3 h-40px', error?.line2 && 'error-border')}
          onChange={e => handleChange('line1', e.target.value)}
          placeholder={'Enter line1'}
          value={address?.line1 || ''}
        />
      </div>
      <div className="flex-column w-full row-gap-2 line-2">
        <label className={classNames('regular-text main-grey-text font-12', labelClassName)}>
          Line2
        </label>
        <input
          autoComplete="turnoff"
          className={classNames('input radius-3 h-40px', error?.line2 && 'error-border')}
          onChange={e => handleChange('line2', e.target.value)}
          placeholder={'Enter line2'}
          value={address?.line2 || ''}
        />
      </div>
      <div className="flex-column w-full row-gap-2 city">
        <label className={classNames('regular-text main-grey-text font-12', labelClassName)}>{'Town/City'}</label>
        <input
          autoComplete="turnoff"
          className={classNames('input radius-3 h-40px', error?.city && 'error-border')}
          onChange={e => handleChange('city', e.target.value)}
          placeholder={'Enter city'}
          value={address?.city || ''}
        />
      </div>
      <div className="flex items-center col-gap-6 w-full">
        <div className="flex-column w-full row-gap-2 state">
          <label className={classNames('regular-text main-grey-text font-12', labelClassName)}>{address.country_code === 'US' ? 'State' : 'County'}</label>
          <input
            autoComplete="turnoff"
            className={classNames('input radius-3 h-40px w-full', error?.state && 'error-border')}
            onChange={e => handleChange('state', e.target.value)}
            placeholder={'Enter state'}
            value={address?.state || ''}
          />
        </div>
        <div className="flex-column w-full row-gap-2 zip-code">
          <label className={classNames('regular-text main-grey-text font-12', labelClassName)}>{address.country_code === 'US' ? 'Zip code' : 'Postcode'}</label>
          <input
            autoComplete="turnoff"
            className={classNames('input radius-3 h-40px w-full', error?.postcode && 'error-border')}
            onChange={e => handleChange('postcode', e.target.value)}
            placeholder={'Enter zipcode'}
            value={address?.postcode || ''}
          />
        </div>
      </div>
    </div>
  );
};

export default Address;
