import React from "react";

import Image from "../../image";

import moment from "moment";

import { getNftLifecycleColor } from "../../../helpers/utils";
import OpenIcon from "../../../assets/images/open-green.svg";

import "./style.scss";

const CampaignNft = ({ nft }) => {
  return (
    <div
      key={nft.id}
      className="campaign-nft-nft border-bottom"
      id={JSON.stringify(nft.tier)}
    >
      <div className="flex px-6 py-4">
        <Image
          className="campaign-nft-s-image"
          size={56}
          media={nft?.image?.media}
        />
        <div className="flex-column ml-4 one-line">
          <label className="bold-text one-line mt-1">{nft.name}</label>
          <label className="regular-text font-12 light-purple-text mt-1 one-line">
            {moment.unix(nft.created_date).format("DD/MM/YYYY")}
          </label>
        </div>
      </div>
      <label className="regular-text px-6 py-4">
        {nft?.carbon?.[0]?.project?.name}
      </label>
      <label className="regular-text px-6 py-4">
        {nft.nft_type === "MEASUREMENT"
          ? nft.co2_offset_amount
          : nft.nft_type === "MERCHANDISE"
          ? nft.carbon?.[0]?.merchandise?.units
          : nft.carbon?.[0]?.units}{" "}
        kCO2
      </label>
      <div className="flex-column px-6 py-4 one-line">
        {nft.owner?.contact && (
          <label className="regular-text mb-2 one-line">
            {nft.owner?.contact?.name}
          </label>
        )}
        <label className="regular-text one-line">
          {nft.owner?.wallet?.wallet_address}
        </label>
      </div>
      <label className="regular-text px-6 py-4 one-line">
        {nft.blockchains?.[0]?.token.id}
      </label>
      <label className="regular-text px-6 py-4">
        {nft.blockchains?.[0]?.minted_date
          ? moment
              .unix(nft.blockchains?.[0]?.minted_date)
              .format("DD/MM/YYYY HH:MM")
          : "-"}
      </label>
      <div className="flex px-6 py-4">
        <div
          className="nft-lifecycle flex items-center justify-center"
          style={{ background: getNftLifecycleColor(nft.lifecycle) }}
        >
          <label className="medium-text font-10 white-text">
            {nft.lifecycle}
          </label>
        </div>
        {nft.blockchains?.[0] && (
          <div className="open-icon ml-2 mr-2">
            <img
              src={OpenIcon}
              alt="copy"
              className="cursor mr-4"
              onClick={() => {
                window.open(
                  `${
                    nft.blockchains?.[0]?.token?.token_type === "BEP20"
                      ? `https://${JSON.stringify(process.env.REACT_APP_ENVIRONMENT) === JSON.stringify('dev')? 'test.' : ''}bithomp.com/en/nft/`
                      : "https://wax.atomichub.io/explorer/asset/wax-mainnet/"
                  }${nft.blockchains?.[0]?.token?.id}`,
                  "_blank"
                );
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignNft;
