import React from 'react';

export const initialThemeState = {
  theme: 'light',
  setTheme: () => {},
};
const ThemePreferenceContext = React.createContext(initialThemeState);

export const ThemePreferenceContextProvider = ThemePreferenceContext.Provider;
export default ThemePreferenceContext;
  