import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Route, Routes, useParams, useNavigate } from 'react-router-dom';

import { getSuppliers } from "../../store/features/supplierSlice";
import { getInventory, updateOneInventory, getRegistryAccounts } from "../../store/features/inventorySlice";

import { getRetirementReasons } from "../../store/features/nftSlice";
import { getRegistries, createRegistry } from "../../store/features/registrySlice";

import NavTop from "../../components/common/NavTop";
import Dropdown from "../../components/Elements/dropdown/dropdown";

import Header from "../../components/header";
import EditDeleteMenu from "../../components/Elements/EditDeleteMenu";
import Button from "../../components/Elements/button/button";
import DateView from "../../components/Elements/DateView";
import InventoryConsignements from "../../components/inventory/inventoryConsignments";
import InventoryRetirements from "../../components/inventory/inventoryRetirements";
import InventoryDetailsComp from "../../components/inventory/inventoryDetailsComp";
import Image from "../../components/image";
import AddCarbonPopup from "../../components/projectDetails/AddCarbonPopup";

import AddIcon from "../../assets/images/add.svg";

import './style.scss';
import Loading from '../../assets/images/loading.svg';

const InventoryDetails = () => {
    const menuItems = [{id: 0, name: 'Details', path: '/details'},{id: 1, name: 'Retirements', path: '/retirements'}, {id: 2, name: 'Consignments', path: '/consignments'}];

    const {id, type} = useParams();
    const isNewCampaign = id === 'new';

    const [edit, setEdit] = useState(isNewCampaign ? true : false)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { inventory, loading, consignments, consignmendtLoading, registryAccounts } = useSelector(data => data.inventory);
    const { registries } = useSelector(data => data.registry);
    const { suppliers } = useSelector(data => data.supplier);
    const { organisation } = useSelector(data => data.user);
    const [page, setPage] = useState("Details")

    const [showAddRegistry, setShowAddRegistry] = useState(false);
    const [newRegistry, setNewRegistry] = useState({});

    useEffect(() => {
        dispatch(getRetirementReasons())
        dispatch(getRegistries())
    }, [])


    useEffect(() => {
        dispatch(getInventory(id));
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(getRegistryAccounts());
    }, [dispatch]);

    const [formData, setFormData] = useState({});

    const {name, serialNumber, blocks, supplier, vintage_to, vintage_from, purchase_price, currency, registry,
        current_balance, open_balance, sale_amount, sale_currency, purchase_date, primary_account, sub_account } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});


    useEffect(() => {
        dispatch(getRegistries());
        dispatch(getSuppliers(organisation));
    }, []);

    const addRegistry = () => {
        dispatch(createRegistry(newRegistry));
        setShowAddRegistry(false);
    };

    const saveInventory = () => {
        const request = {
            "name": name,
            "project": {
                "id":  inventory.project?.id
            },
            "vintage_to": vintage_to,
            "vintage_from": vintage_from,
            "blocks": blocks,
            "serial_number": serialNumber,
            "purchase_price": purchase_price,
            "purchase_currency": currency.name,
            "registry": {
                "id": registry
            },
            "primary_account": {
                "id": primary_account
            },
            "sub_account": {
                "id": sub_account
            },
            "supplier": {
                "id": supplier
            },
            "open_co2_balance": open_balance,
            "current_co2_balance": current_balance,
            "sale": {
                amount: sale_amount,
                currency: sale_currency.name
            },
            "purchase_date": purchase_date
        }
        dispatch(updateOneInventory({...request, id: inventory.id}));
        setEdit(false)
    }

    const onEdit = () => {
        setFormData({
            serialNumber: inventory.serial_number ,
            name: inventory.name ,
            blocks: inventory.blocks ,
            supplier: inventory.supplier?.id ,
            registry: inventory.registry?.id ,
            primary_account: inventory.primary_account?.id ,
            sub_account: inventory.sub_account?.id ,
            vintage_to: inventory.vintage?.vintage_to ,
            vintage_from: inventory.vintage?.vintage_from ,
            purchase_price: inventory.costs?.purchase_price ,
            currency:  {name: inventory.costs?.currency},
            current_balance: inventory.carbon?.current_balance ,
            open_balance: inventory.carbon?.open_balance ,
            sale_amount: inventory.sale?.amount ,
            sale_currency:  {name: inventory.sale?.currency},
            purchase_date: inventory.purchase_date
        })
        setEdit(true)
    }

    const onMenuSelect = item => {
        setPage(item.name)
        navigate(`/carbon/inventories/${id}${item.path}`, {replace: true})
    }

    return (
        <div className="flex flex-1">
    
            <div className="flex-column inventory-details flex-1">
                <div className="mb-6 mt-8 ml-6">
                    <NavTop names={[{text: "Inventories", path: "/carbon/inventory"}, {text: 'Inventory details'}]} savePermission="INVENTORY_MANAGE" edit={false} done={true} onDone={() => navigate('/carbon/inventories')} />
                </div>
                <div className="flex mx-6">
                    <div className="inventory-details-l card fit-content">
                        <div className="flex items-center justify-between pxy-6 border-bottom">
                            <label className="bold-text font-20">Carbon inventory</label>
                            <EditDeleteMenu onEdit={onEdit} onDelete={() => {}} />
                        </div>
                        <div className="inventory-details-l-content">
                            {false ?
                            <div className="flex-column pxy-6">
                                <div className="flex justify-center mb-6">
                                    <Button
                                    className="secondary-hover"
                                    label="Cancel"
                                    borderRadius="12px"
                                    onClick={() => setEdit(false)}
                                    secondary={true}
                                    size="average"
                                    style={{ flex: 1 }}
                                    bgColor="#FFFFFF"
                                    borderColor="#24CBB1"
                                    color="#24CBB1"
                                    borderWidth="1px"
                                    />
                                    <Button
                                    label="Save"
                                    onClick={() => saveInventory()}
                                    size="average"
                                    style={{ flex: 1, marginLeft: 24 }}
                                    bgColor="#24CBB1"
                                    borderColor="#24CBB1"
                                    color="#FFFFFF"
                                    borderRadius="12px"
                                    borderWidth="1px"
                                    />
                                </div>
                                <div className="flex-column">
                                    <div className={"flex-column flex-1"}>
                                        <label className="input-label">Name</label>
                                        <input className="input  w-full" value={name} 
                                            name={'name'} placeholder={"Name"} onChange={onChange}/>
                                    </div>
                                    <div className="flex items-center flex-1 mt-6">
                                        <div className={"flex-column flex-1"} >
                                            <label className="input-label">Vintage to</label>
                                            <DateView size="medium" value={vintage_to} setValue={o => onChange({target: {value: o, name: 'vintage_to'}})}/>
                                        </div>
                                        <div className={"flex-column flex-1 ml-6"} > 
                                            <label className="input-label">Vintage from</label>
                                            <DateView size="medium" value={vintage_from} setValue={o => onChange({target: {value: o, name: 'vintage_from'}})}/>
                                        </div>
                                    </div>
                                    <label className="input-label mt-6">Registry</label>
                                    <Dropdown
                                        onSelect={option => setFormData({...formData, registry: option.id})}
                                        options={registries}
                                        scrollEnabled
                                        selected={registries.find(r => r.id === registry)}
                                        withIcon={false}
                                        borderColor='rgba(181, 185, 193, 0.3)'
                                        placeholder="Select registry"
                                        height="50px"
                                        components={
                                        <div className="flex items-center justify-center border-top">
                                            <div className="flex items-center justify-center add-registry-btn cursor" onClick={() => setShowAddRegistry(!showAddRegistry)}>
                                                <img src={AddIcon} alt="add" className="ml-6"/>
                                                <label className="bold-text font-14 violet-light-text ml-2">Add new registry</label>
                                            </div>
                                        </div>}
                                        component={showAddRegistry ? 
                                            <div className="card add-registry flex-column absolute pxy-4">
                                                <label className="input-label">Name</label>
                                                <input className="input" value={newRegistry.name} onChange={(e=> setNewRegistry({...newRegistry, name: e.target.value}))} />
                                                <label className="input-label mt-4">Description</label>
                                                <textarea className="textarea"  value={newRegistry.description} onChange={(e=> setNewRegistry({...newRegistry, description: e.target.value}))} />
                                                <div className="flex items-center mt-6">
                                                    <div className="flex flex-1 items-center justify-center cancel-btn medium-btn cursor mr-4"
                                                        onClick={() => setShowAddRegistry(false)}>
                                                        <label className="medium-text color-green">Cancel</label>
                                                    </div>

                                                    <div
                                                        onClick={addRegistry}
                                                        className='flex-1 done-btn medium-btn flex items-center justify-center cursor'>
                                                        <label className="medium-text color-white">Add</label>
                                                    </div> 
                                                </div>
                                            </div>
                                            :
                                            null}
                                        dropdownHeight='250px'
                                        />
                                    <label className="input-label mt-6">Primary account</label>
                                    <Dropdown
                                        onSelect={option => setFormData({...formData, primary_account: option.id})}
                                        options={registryAccounts}
                                        scrollEnabled
                                        selected={registryAccounts.find(r => r.id === primary_account)}
                                        withIcon={false}
                                        borderColor='rgba(181, 185, 193, 0.3)'
                                        placeholder="Select primary account"
                                        height="50px"
                                        />
                                    <label className="input-label mt-6">Sub account</label>
                                    <Dropdown
                                        onSelect={option => setFormData({...formData, sub_account: option.id})}
                                        options={registryAccounts}
                                        scrollEnabled
                                        selected={registryAccounts.find(r => r.id === sub_account)}
                                        withIcon={false}
                                        borderColor='rgba(181, 185, 193, 0.3)'
                                        placeholder="Select sub account"
                                        height="50px"
                                        />
                                </div>
                                <div className="flex-column flex-1 mt-6">
                                    <label className="input-label">Purchase Date</label>
                                    <DateView size="medium" value={purchase_date} setValue={o => onChange({target: {value: o, name: 'purchase_date'}})}/>
                                </div>
                                <div className={"flex mt-6"}>
                                    <div className={"flex-column flex-1 mr-6"}>
                                        <label className="input-label">Purchase price</label>
                                        <input className="input  w-full" value={purchase_price} 
                                            name={'purchase_price'} placeholder={0} onChange={onChange}/>
                                    </div>
                                    <div className={"flex-column flex-1"}>
                                        <label className="input-label">Currency</label>
                                        <Dropdown
                                            onSelect={option => setFormData({...formData, currency: option})}
                                            options={[{id: 0, name: 'USD'}, { id: 1, name: 'GBP'}, {id: 2, name: 'EUR'}]}
                                            scrollEnabled
                                            selected={currency}
                                            withIcon={false}
                                            borderColor='rgba(181, 185, 193, 0.3)'
                                            height="50px"
                                        />
                                    </div>
                                </div>
                                <div className={"flex mt-6"}>
                                    <div className={"flex-column flex-1 mr-6"}>
                                        <label className="input-label">Sale price</label>
                                        <input className="input  w-full" value={sale_amount} 
                                            name={'sale_amount'} placeholder={0} onChange={onChange}/>
                                    </div>
                                    <div className={"flex-column flex-1"}>
                                        <label className="input-label">Currency</label>
                                        <Dropdown
                                            onSelect={option => setFormData({...formData, sale_currency: option})}
                                            options={[{id: 0, name: 'USD'}, { id: 1, name: 'GBP'}, {id: 2, name: 'EUR'}]}
                                            scrollEnabled
                                            selected={sale_currency}
                                            withIcon={false}
                                            borderColor='rgba(181, 185, 193, 0.3)'
                                            height="50px"
                                        />
                                    </div>
                                </div>
                                <div className="flex-column flex-1  mt-6">
                                    <label className="input-label">Purchased amount</label>
                                    <input className="input  w-full" value={open_balance / 1000} 
                                                name={'open_balance'} placeholder={0} onChange={e => onChange({target: {value: e.target.value * 1000, name: 'open_balance'}})}/>
                                </div>
                                <div className="flex-column flex-1  mt-6">
                                    <label className="input-label">Current balance</label>
                                    <input className="input  w-full" value={current_balance / 1000} 
                                                name={'current_balance'} placeholder={0} onChange={e => onChange({target: {value: e.target.value * 1000, name: 'current_balance'}})}/>
                                </div>
                            </div>
                            :
                            <div className="flex-column pxy-6">
                                <div className="flex items-center justify-between">
                                    <label className="regular-text light-purple-text">Project:</label>
                                    <label className="regular-text">{inventory?.project?.name}</label>
                                </div>
                                <Image
                                    className={"ci-project-image mt-4"}
                                    size={160}
                                    media={inventory?.project?.image?.media}
                                    />
                                <div className="flex items-center justify-between mt-4">
                                    <label className="regular-text light-purple-text">Name:</label>
                                    <label className="regular-text">{inventory?.name}</label>
                                </div>
                                <div className="flex items-center justify-between mt-4">
                                    <label className="regular-text light-purple-text">Owner:</label>
                                    <label className="regular-text">{inventory?.owner?.name}</label>
                                </div>
                                <div className="flex items-center justify-between mt-4">
                                    <label className="regular-text light-purple-text">Vintage:</label>
                                    <label className="regular-text">{moment.unix(inventory?.vintage?.vintage_from).format('DD/MM/YYYY')} - {moment.unix(inventory?.vintage?.vintage_to).format('DD/MM/YYYY')}</label>
                                </div>
                                <div className="flex items-center justify-between mt-4">
                                    <label className="regular-text light-purple-text">Registry:</label>
                                    <label className="regular-text">{inventory?.registry?.name}</label>
                                </div>
                                <div className="flex items-center justify-between mt-4">
                                    <label className="regular-text light-purple-text">Purchase amount:</label>
                                    <label className="regular-text">{inventory?.carbon?.open_balance/1000} Tons</label>
                                </div>
                                <div className="flex items-center justify-between mt-4">
                                    <label className="regular-text light-purple-text">Current balance:</label>
                                    <label className="regular-text">{inventory?.carbon?.current_balance/1000} Tons</label>
                                </div>
                                <div className="flex items-center justify-between mt-4">
                                    <label className="regular-text light-purple-text">Primary account:</label>
                                    <label className="regular-text  font-16">{inventory?.primary_account?.name}</label>
                                </div>
                                <div className="flex items-center justify-between mt-4">
                                    <label className="regular-text light-purple-text">Sub account:</label>
                                    <label className="regular-text font-16">{inventory?.sub_account?.name}</label>
                                </div>
                                {/* <div className="flex items-center justify-between mt-4">
                                    <label className="regular-text light-purple-text">Purchased date:</label>
                                    <label className="regular-text">{moment.unix(inventory.purchase_date).format("DD/MM/YYYY")}</label>
                                </div>
                                <div className="flex items-center justify-between mt-4">
                                    <label className="regular-text light-purple-text">Purchased price:</label>
                                    <label className="regular-text">{inventory?.costs?.purchase_price} {inventory?.costs?.currency}</label>
                                </div>
                                <div className="flex items-center justify-between mt-4">
                                    <label className="regular-text light-purple-text">RRP:</label>
                                    <label className="regular-text">{inventory?.sale?.amount} {inventory?.sale?.currency}</label>
                                </div>
                            
                        
                                <div className="flex items-center justify-between mt-4">
                                    <label className="regular-text light-purple-text">Consigned amount:</label>
                                    <label className="regular-text">{inventory?.consignments?.open_co2_balance/1000} Tons</label>
                                </div>
                                <div className="flex items-center justify-between mt-4">
                                    <label className="regular-text light-purple-text">Consigned balance:</label>
                                    <label className="regular-text">{inventory?.consignments?.current_co2_balance/1000} Tons</label>
                                </div> */}
                            </div>
                            }
                        </div>
                    </div>
                    {loading ? 
                    <div className="flex flex-1 items-center justify-center mt-4">
                        <img src={Loading} />
                        <label className="bold-text font-16 ml-2 light-purple-text">Loading</label>
                    </div>
                    :
                    <div className="card flex-column flex-1 ml-6 carbon-retirement">
                     
                        <Routes>
                            <Route element={ <InventoryConsignements menuItems={menuItems} onMenuSelect={onMenuSelect} />} path={`/consignments`}   />
                            <Route element={ <InventoryRetirements menuItems={menuItems} onMenuSelect={onMenuSelect}  />} path={`/retirements`} />
                            <Route element={ <InventoryDetailsComp inventory={inventory}  menuItems={menuItems} onMenuSelect={onMenuSelect} />} path={`/details`} />
                        </Routes>
                       
                    
                    </div>
                    }
                </div>
            </div>
            {edit && <AddCarbonPopup setIsCarbonDialog={() => {setEdit(false); }} carbon={inventory}/>}
    </div>
    )
}

export default InventoryDetails;
