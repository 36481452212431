import { createSlice } from "@reduxjs/toolkit";
import api from "../../store/services";
import { uploadMedia } from "../services/media";
import { addToast } from "./toastSlice";
import uuid from "react-uuid";

export const templateSlice = createSlice({
  name: "template",
  initialState: {
    templates: {},
    template: {},
    templateAttributes: [],
    loading: false,
    mediaGroups: [],
    media: [],
    templateCollections: {},
  },
  reducers: {
    setTemplates: (state, { payload }) => {
      state.templates = payload;
    },
    setMergeTemplates: (state, { payload }) => {
      state.templates = {...payload, content: [...state.templates.content, ...payload.content]};
    },
    setTemplate: (state, { payload }) => {
      state.template = payload;
    },
    setTemplateAttributes: (state, { payload }) => {
      state.templateAttributes = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    addMediaGroup: (state, { payload }) => {
      state.mediaGroups = [...state.mediaGroups, payload];
    },
    setMediagroups: (state, { payload }) => {
      state.mediaGroups = payload;
    },
    setMedia: (state, { payload }) => {
      state.media = payload;
    },
    removeMedia: (state, { payload }) => {
      state.media = state.media.filter(m => m.media_external_id !== payload);
    },
    addMedia: (state, { payload }) => {
      state.media = [...state.media, payload];
    },
    updateMedia: (state, { payload }) => {
      state.media = state.media.map(m => m.id === payload.id ? payload : m);
    },
    setTemplateCollections: (state, { payload }) => {
      state.templateCollections = payload;
    },
    setCreateTemplateStatus: (state, { payload}) => {
      state.createTemplateStatus = payload;
    },
  },
});

export const getTemplates = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await api.get(
    `/v3/api/backoffice/templates?page=${payload.page}&size=${
      payload.size || 8
    }&search=${payload.search}`
  );
  if (payload.merge) {
    dispatch(setMergeTemplates(data));
  } else {
    dispatch(setTemplates(data));
  }
  dispatch(setLoading(false));
};

export const getTemplate = (payload) => async (dispatch) => {
  const { data } = await api.get(`/v3/api/backoffice/templates/${payload}`);
  dispatch(setTemplate(data));
};

export const updateTemplate = (payload) => async (dispatch) => {
  try {
    const { data } = await api.put(
      `/v3/api/backoffice/templates/${payload.id}`,
      payload.request
    );
    dispatch(setTemplate(data));
    dispatch(
      addToast({
        error: false,
        title: "Template updated",
        text: `${payload.request.name} has been updated`,
        id: uuid(),
      })
    );
  } catch (e) {
    dispatch(
      addToast({
        error: true,
        text: "Error while updating template",
        id: uuid(),
      })
    );
  }
};

export const saveNewTemplate = (payload) => async (dispatch) => {
  try {
    dispatch(setCreateTemplateStatus('LOADING'));
    const { data } = await api.post(
      `/v3/api/backoffice/templates`,
      payload.request
    );
    dispatch(setTemplate(data));
    dispatch(
      addToast({
        error: false,
        title: "Template created",
        text: `${payload.request.name} has been created`,
        id: uuid(),
      })
    );
    dispatch(setCreateTemplateStatus('CREATED'));
  } catch (e) {
    dispatch(
      addToast({
        error: true,
        text: "Error while creating template",
        id: uuid(),
      })
    );
    dispatch(setCreateTemplateStatus('FAILED'));
  }
};

export const getTemplateAttributes = (payload) => async (dispatch) => {
  const { data } = await api.get(
    `/v3/api/backoffice/templates/${payload}/attributes`
  );
  dispatch(setTemplateAttributes(data));
};

export const updateTemplateAttributes = (payload) => async (dispatch) => {
  try {
    const { data } = await api.put(
      `/v3/api/backoffice/templates/${payload.id}/attributes`,
      payload.attributes
    );
    dispatch(setTemplateAttributes(data));
    dispatch(
      addToast({
        error: false,
        title: "Attributes updated",
        text: `Template attributes has been created`,
        id: uuid(),
      })
    );
  } catch (e) {
    dispatch(
      addToast({
        error: true,
        text: "Error while updating attributes",
        id: uuid(),
      })
    );
  }
};

export const getTemplateCollections = (payload) => async (dispatch) => {
  const { data } = await api.get(
    `/v3/api/backoffice/collections?template_id=${payload}`
  );
  dispatch(setTemplateCollections(data));
};

export const getMediaGroups = (payload) => async (dispatch) => {
  const { data } = await api.get(
    `/v3/api/backoffice/templates/${payload}/mediagroups`
  );
  dispatch(setMediagroups(data.map((mg, i) => ({ ...mg, selected: i === 0 }))));
};

export const getMedia = (payload) => async (dispatch) => {
  const { data } = await api.get(`/v3/api/backoffice/templates/${payload}/media`);
  dispatch(setMedia(data));
};

export const deleteMedia = (payload) => async (dispatch) => {
  await api.delete(`/v3/api/backoffice/templates/${payload.templateId}/media/${payload.mediaId}`);
  dispatch(removeMedia(payload.mediaId));
};

export const createMedia = (payload) => async (dispatch) => {
  const media = await uploadMedia(payload.file, payload.selectedMediaGroup, media => dispatch(addMedia(media)), async ({e, id}) => {
  dispatch(updateMedia({id, uploading: true, progress: e.progress}))
    
    if(e.progress === 1) {
      var counter = 0;
      dispatch(updateMedia({id, uploading: true, uploaded: true}));

      var timer = setInterval(async () => {
        const { data } = await api.get(`/v3/api/asset/medias/${media.media_external_id }`)

          if(data.length > 0) {
            dispatch(updateMedia({...data[0], id: media.media_external_id}));
          }

          if (counter >= 100 || data.length > 0) {
              clearInterval(timer);
          }

          counter++;

      }, 3000);

    } else {
    }
  });
  await api.post(`/v3/api/backoffice/templates/${payload.templateId}/media`, { id: media.media_external_id });
};

export const saveTemplate = (payload) => async (dispatch) => {
  try {
    const { data } = await api.put(`/v3/api/backoffice/templates/${payload.id}`, payload);
    dispatch(
      addToast({
        error: false,
        title: "Template updated",
        text: `${payload.name} has been updated`,
        id: uuid(),
      })
    );
    dispatch(setTemplate(data))
  } catch (e) {
    dispatch(
      addToast({
        error: true,
        text: "Error while updating template",
        id: uuid(),
      })
    );
  }
};

export const uploadToIPFS = (payload) => async (dispatch) => {
  try {
    const { data } = await api.post(`/v3/api/asset/${payload}/reupload/IPFS`);
    return data;
  } catch (e) {
   
  }
};

export const {
  setTemplates,
  setTemplate,
  setTemplateAttributes,
  setLoading,
  addMediaGroup,
  setMediagroups,
  setMedia,
  addMedia,
  updateMedia,
  setTemplateCollections,
  setCreateTemplateStatus,
  removeMedia,
  setMergeTemplates
} = templateSlice.actions;
export default templateSlice.reducer;
