import React from "react";
import NFTCard from "../../NftCard";
import Header from "../../header";
import Filter from "../../filter";
import NftGrid from "../../NftGrid";

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Loading from '../../../assets/images/loading.svg';

import './nfts.scss';

const NftList = ({nfts, loading, currentPage, setCurrentPage, totalPages, search, setSearch, lifecycles, setLifecycles, isAddBtn, headerSize, menuItems, onMenuItemClick, filter, setFilter, filterObjects, addPermission, type = 'card'}) => {
    return (
        <div className='flex-column flex-1'>
            <Header title={"NFTs"}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    search={search}
                    setSearch={setSearch}
                    totalPages={totalPages}
                    isFilter={true}
                    isAddBtn={isAddBtn}
                    size={headerSize}
                    filterChildren={<Filter objects={filterObjects} setFilter={setFilter}  values={filter?.filters}/>}
                    filter={filter?.value}
                    menuItems={menuItems}
                    onMenuItemClick={onMenuItemClick}
                    addPermission={addPermission}
                    children={
                    <div className="flex items-center mr-6">
                            {lifecycles?.map(lifecycle => 
                            <div 
                                key={lifecycle.id}
                                className="flex items-center justify-center lifecycle-selector ml-2 cursor" 
                                onClick={() => setLifecycles(lifecycles.map(l => l.id === lifecycle.id ? ({...l, selected: true}) : ({...l, selected: false})))}
                                style={{background: lifecycle.selected ? lifecycle.color : 'rgb(170, 186, 183, 0.1)'}}
                                >
                                <label className="bold-text font-12" style={{color: lifecycle.selected ? '#FFFFFF' : '#020819'}}>{lifecycle.name}</label>
                            </div>)}
                    </div>}
                    />

            {loading ? 
                <div className="flex flex-1 items-center justify-center mt-4">
                    <img src={Loading} />
                    <label className="bold-text font-16 ml-2 light-purple-text">Loading</label>
                </div>
                :
            <div className={`nft-cards ${type === 'grid' && 'card mx-6 mt-2'}`}>
                <TransitionGroup>
                    {nfts?.map((nft, i) => 
                    <CSSTransition key={i} appear classNames="fade" timeout={500}>
                        {type === 'card' ? <NFTCard key={i} nft={nft} /> : <NftGrid key={i} nft={nft} /> }
                    </CSSTransition>
                    )}
                </TransitionGroup>
            </div>
        }
        </div>
    )
}

export default NftList;
