import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { OrganisationContext } from '../../context/organisationContext'

import MenuItem from './menu-item'
import OrganisationMenu from "./organisation-menu";

import Logo from '../../assets/images/menu/menu-logo.svg'

import ArrowDown from '../../assets/images/menu/arrow-down.svg'

import LogoutIcon from '../../assets/images/menu/logout-icon.svg'

import MyAccountIcon from '../../assets/images/my-account.svg'
import MyBusinessIcon from '../../assets/images/my-business.svg'

import SelectedIcon from '../../assets/images/selected.svg'
import Masquerade from '../../assets/images/masquerade-menu.svg';
import ExitMasquerade from '../../assets/images/exit-masquerade.svg';

import '../../assets/css/components/menu/sidebar.scss'

const Sidebar = ({ menuItems, onItemClick, user, setUser}) => {
    const navigate = useNavigate();
    const location = useLocation()

    const { merchants, selectedMerchant, setSelectedMerchant, selectedOrganisation, selectedAccount, setSelectedAccount,
         setSelectedOrganisation, venues, setVenues, selectedVenue, setSelectedVenue,  } = useContext(OrganisationContext)


    const [showUserMenu, setShowUserMenu] = useState(false)
    const [showOrganisationMenu, setShowOrganisationMenu] = useState(false)
    const [showVenues, setShowVenues] = useState(false)

    const logout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("selected-merchant");
        localStorage.removeItem("selected-organisation");
        localStorage.removeItem("selected-automation");
        navigate("/login", { replace: true });
    }

    const exitMasquerade = () => {
        localStorage.setItem("user", JSON.stringify(user.primary));
        setUser(user.primary)
        navigate('/network/accounts', { replace: true })
    }

    return (
        <div className='sidebar'>
            <div className='sidebar-top' tabIndex='0' onBlur={() => setShowVenues(false)}>
                <div className="flex items-center justify-between pxy-6 mb-4 cursor user-info no-select relative" tabIndex={0} onBlur={() => setShowUserMenu(false)} onClick={() => setShowUserMenu(!showUserMenu)}>
                    <div className="flex items-center">
                        <img src={Logo} className='menu-logo' alt="logo" />
                        <div className="flex-column ml-4 one-line">
                            <label className="semibold-text white-text font-16">{user.forename} {user.surname}</label>
                            <label className="light-text font-12 light-green-text mt-1 one-line" style={{maxWidth: '110px'}}>{user.organisations[0].roles[0].name}</label>
                        </div>
                    </div>
                    <img src={ArrowDown} alt="arrow" className="justify-end" />
                    {showUserMenu &&
                        <div className="flex-column user-menu card">
                            <div className="flex-column pxy-2">
                                <div className="flex items-center justify-between pxy-2 um-item" onClick={() => navigate('/business')}>
                                    <div className="flex items-center">
                                        <img src={MyBusinessIcon} alt="my business" />
                                        <label className="semibold-text ml-2">My business</label>
                                    </div>
                                    {location.pathname === '/business' && <img src={SelectedIcon} alt="selected" />}
                                </div>
                                <div className="flex items-center justify-between pxy-2 um-item" onClick={logout}>
                                    <div className="flex items-center">
                                        <img src={LogoutIcon} alt="my business" />
                                        <label className="semibold-text ml-2">Logout</label>
                                    </div>
                                </div>
                            </div>
                        {user.masqueraded && <div className="flex-column pxy-2 border-top">
                            <div className="flex items-center justify-between pxy-2 um-item">
                                    <div className="flex items-center">
                                        <img src={Masquerade} alt="mask" />
                                        <label className="semibold-text ml-2">{user.organisations[0].name}</label>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between pxy-2 um-item" onClick={exitMasquerade}>
                                    <div className="flex items-center">
                                        <img src={ExitMasquerade} alt="exit" />
                                        <label className="semibold-text ml-2">Exit masquerade</label>
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                    }
                </div>
                {/* <div
                    className="flex items-center mb-8 px-6 py-3 justify-between cursor organisation-info no-select relative"
                    onClick={() => setShowOrganisationMenu(!showOrganisationMenu)}
                    tabIndex={0}
                    onBlur={() => setShowOrganisationMenu(false)}
                >
                    <div className="flex-column">
                        <label className="bold-text font-10 light-green-text">{selectedOrganisation.org_type}</label>
                        <label className="regular-text white-text mt-1">{selectedOrganisation.name}</label>
                    </div>
                    <img src={ArrowDown} alt="arrow" className="justify-end mr-4" />
                    {showOrganisationMenu &&
                        <OrganisationMenu
                            merchants={merchants}
                            selectedMerchant={selectedMerchant}
                            setSelectedMerchant={setSelectedMerchant}
                            setSelectedOrganisation={setSelectedOrganisation}
                            selectedOrganisation={selectedOrganisation}
                            setSelectedAccount={setSelectedAccount}
                            selectedAccount={selectedAccount}
                            venues={venues}
                            setVenues={setVenues}
                            selectedVenue={selectedVenue}
                            setSelectedVenue={setSelectedVenue}
                            user={user}
                        />}
                </div> */}

                <div className='menu-items'>
                    {
                        menuItems.map((item, i) =>
                            <MenuItem  key={i} item={item} onClick={onItemClick}  />
                        )
                    }
                </div>
            </div>
            {/* <div className="flex items-center pxy-4 cursor no-select logout" onClick={logout}>
                <img src={LogoutIcon} alt="logout" className="ml-4" />
                <label className="regular-text font-16 light-green-text ml-6">Logout</label>
            </div> */}
        </div>
    )
}

export default Sidebar;