import React from "react";

import CampaignForm from "../CampaignForm";

import "./style.scss";

const AddCampaignPopup = ({ cancel}) => {
    return (
        <div className='add-campaign-popup'>
            <div className='add-campaign-popup-content'>
                <label className='popup-title mb-6'>Add campaign</label>
               <CampaignForm c={{}} cancel={cancel} />
            </div>
        </div>
    )
}

export default AddCampaignPopup;