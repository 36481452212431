import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaign, getCampaignTiers } from "../../store/features/campaignSlice";
import Header from "../../components/header";
import { useParams, useNavigate } from "react-router-dom";

import DotsIcon from '../../assets/images/dots.svg'

import './style.scss';

const Campaigns = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();

    const { campaign, tiers } = useSelector(data => data.campaign);
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatch(getCampaign(id));
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(getCampaignTiers({campaignId: id, page: currentPage}));
    }, [id, currentPage, dispatch]);
    
    return (
        <div className="flex-column mb-6 mt-2 flex-1">
            <Header
                nav={{names: [{text: "Campaigns", path: "/commerce/campaigns"}, {text: campaign?.name, path: `/commerce/campaigns/${campaign.id}`}, {text: "Tiers"}], edit: false}  }
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                search={search}
                setSearch={setSearch}
                isAddBtn={true}
                totalPages={tiers.total_pages}
            />

            <div className="tiers-grid mx-3">
                {tiers.content?.map(tier => 
                <div key={id} className="flex-column card pxy-4 mx-3 my-3 no-select cursor" onClick={() => navigate(`/commerce/campaigns/${campaign.id}/tiers/${tier.id}`)}>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <div className="tier-color" style={{background: tier.color_code}} />
                            <label className="bold-text font-20 ml-4">{tier.name}</label>
                        </div>
                        <img src={DotsIcon} alt="menu btn" className="cursor" />
                    </div>
                    <label className="regular-text o05 mt-2">{tier.description}</label>
                </div>
            )}
            </div>
        </div>
    )
}

export default Campaigns;
