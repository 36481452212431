import React, { useState } from "react";

import axios from "axios";
import StripeWrapper from "./stripePayment";

import './payment.scss';


const Payment = () => {

    const [forename, setForename] = useState();
    const [surname, setSurname] = useState();
    const [email, setEmail] = useState();
    const [subscription, setSubscription] = useState()
    const [error, setError] = useState()
    const [clientSecret, setClientSecret] = useState()

    const onRegister = async () => {
        if(!subscription) { 
            setError("Select a subscription")
            return;
        }
        if(!forename) { 
            setError("Forename is empty")
            return;
        }
        if(!surname) { 
            setError("Surname is empty")
            return;
        }
        if(!email) { 
            setError("Email is empty")
            return;
        }

        const { data } = await axios.post(`https://v3/api.dev.zenterprize.cloud/v1/contacts?api_key=pub_KXuHzwWJP6ZLEvougQRXo9Kfw9g5pbbgy2ZnHkopNyk`
        , {
            title: "MR",
            forename: forename,
            surname: surname,
            emails: [{
                value: email,
                is_primary: true,
                email_type: "PERSONAL"
            }],
            phones: [{
                contact_type: "MOBILE",
                contact_value: "12345",
                is_primary: true,
                phone_type: "PERSONAL"
            }],
            addresses: [{
                formatted_address: "test",
                is_primary: true,
                line1: "test",
                city: "test",
                postcode: "test",
                country_code: "US",
                type: "BILLING"
            }],
            marketing_preferences: [],
            call_time: []
        })


        const { data : subs } = await axios.post(`https://v3/api.dev.zenterprize.cloud/v1/contacts/${data.id}/subscriptions?api_key=pub_KXuHzwWJP6ZLEvougQRXo9Kfw9g5pbbgy2ZnHkopNyk`,
        {
            id: subscription
        })
        setClientSecret(subs.client_secret)
    };
    return (
    <div className="flex mt-6 ml-6">
        {clientSecret ? 
        <div>
            <StripeWrapper clientSecret={clientSecret} />
        </div>
            :
        <div className="flex-column ">
            <label className="font-20 mb-10">Enter user</label>
            <div className="flex-column mb-10">
                <label>Name</label>
                <div>
                    <input className="input mt-2" placeholder="Forename" value={forename} onChange={e => setForename(e.target.value)} />
                    <input className="input mt-2 ml-4" placeholder="Surname"  value={surname} onChange={e => setSurname(e.target.value)}/>
                </div>
                <label className="mt-4">Email</label>
                <input  className="input mt-2" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />

                <label className="mt-10 mb-4">Products</label>
                <div className="flex-column" onChange={e => setSubscription(e.target.value === 'MONTHLY' ? 'price_1N3KEsC1HK51BkXN1DZ9tR5i' : 'price_1N3KkQC1HK51BkXNlqD7BO5B'
                    )}>
                    <div className="flex items-center">
                        <input type="radio" value="MONTHLY" name="subsc" /> 
                        <div className="flex-column ml-2">
                            <label>Monthly Susbcription</label>
                            <label>$15.60</label>
                        </div>
                    </div>
                    <div className="flex mt-4">
                        <input type="radio" value="ANNUAL" name="subsc" />      
                        <div className="flex-column ml-2">
                            <label>Annual Susbcription</label>
                            <label>$156.00</label>
                        </div>                   
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    <button className="mt-4 popup-btn" onClick={onRegister}>Register</button>
                </div>

            </div>
        
            <label className="mt-4" style={{color: 'red'}}>{error}</label>
        </div>
        }
    </div>)
}


export default Payment;