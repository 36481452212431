import React, { useEffect, useMemo, useState } from "react";

import { Navigate, Route, Routes, useNavigate, useLocation, } from "react-router-dom";

import api from '../store/services'
import history from '../routes/history'
import { OrganisationContext } from '../context/organisationContext'
import { setUser } from "../store/features/userSlice";

import Sidebar from '../components/menu/sidebar'
import Dashboard from './dashboard'
import Business from './business'
import Settings from './settings'
import Accounts from "./accounts";
import Projects from "./projects";
import ProjectDetails from "./projectDetails";
import Collections from "./collections";
import CollectionDetails from "./collectionDetails";
import CampaignDetails from "./campaignDetails";
import CampaignTiers from "./campaignTiers";
import TierDetails from "./tierDetails";
import Schemas from "./schemas";
import SchemaDetails from "./schemaDetails";
import Templates from "./templates";
import TemplateDetails from "./templateDetails";
import RetirementDetails from "./retirementDetails";
import InventoryDetails from "./inventoryDetails";
import Reports from "./reports";

import InviteUser from '../components/popup/invite-user-popup'

import DashboardIcon from "../assets/images/menu/dashboard.svg"
import ReportsIcon from "../assets/images/menu/reports.svg"

import DashboardSelectedIcon from "../assets/images/menu/dashboard-selected.svg"
import AccountsIcon from "../assets/images/menu/account.svg"
import AccountsSelectedIcon from "../assets/images/menu/account-selected.svg"
import CustomersIcon from "../assets/images/menu/customers.svg"
import ProductsIcon from "../assets/images/menu/products.svg"
import CustomersSelectedIcon from "../assets/images/menu/customers-selected.svg"
import ProjectsIcon from "../assets/images/menu/projects.svg"
import ProjectsSelectedIcon from "../assets/images/menu/projects-selected.svg"
import PlatformIcon from "../assets/images/menu/platform.svg"
import PlatformSelectedIcon from "../assets/images/menu/platform-selected.svg"
import SettingsIcon from "../assets/images/menu/settings.svg"
import SettingsSelectedIcon from "../assets/images/menu/settings-selected.svg"
import CarbonIcon from "../assets/images/menu/carbons.svg"
import MetaIcon from "../assets/images/menu/meta.svg"

import "../assets/css/pages/main.scss";
import NFT from "./nfts";
import NftMinting from "./nftsMinting"
import NFTDetails from "./nftDetails";
import Products from "./products";
import Retirements from "./retirements";
import ProductDetails from "./productDetails";
import Leaderboards from "./leaderboard";
import Campaigns from "./campaigns";
import { useDispatch, useSelector } from "react-redux";
import { addToast} from '../store/features/toastSlice'
import Integration from "./integration/integration";
import Automations from "./automations/automations";
import AutomationLogs from './automation_logs/automation_logs';
import AutomationLogView from './automation_logs/automation-log-view';
import Modal from "../popup";
import Inventory from "./inventory";
import Transactions from "./transactions";
import CampaignNft from "./campaignNft";
import ReportDetails from "./reportDetails";

const Main = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { toasts } = useSelector(state => state.toast);
  const { user } = useSelector(state => state.user);

  const [venues, setVenues] = useState([])
  const [selectedAccount, setSelectedAccount] = useState(localStorage.getItem('selected-account') ? JSON.parse(localStorage.getItem('selected-account')) : null)
  const [merchants, setMerchants] = useState([])
  const [selectedMerchant, setSelectedMerchant] = useState(JSON.parse(localStorage.getItem('selected-merchant')))
  const [selectedVenue, setSelectedVenue] = useState(JSON.parse(localStorage.getItem('selected-venue')))
  const [selectedOrganisation, setSelectedOrganisation] = useState(JSON.parse(localStorage.getItem('selected-organisation')) || user.organisations[0])
  const [modal, setModal] = useState({
    type: null,
    content: {},
  });

  const permissions = user
      .organisations.map(o => o.roles)
      .flat()
      .map(r => r.permissions)
      .flat();

  const hasPermission = permission => {
    return permissions.includes(permission);
  };

  const [selectedPath, setSelectedPath] = useState(location.pathname)

  const [showInviteUser, setShowInviteUser] = useState(false)

  const checkPermission = (action, permission) => {
    if (!permission || permissions.includes(permission)) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
      dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: 'You do not have permission to perform this action',
          }),
      );
  };

  const items =  useMemo(() => [
    {
      id: 0,
      icon: DashboardIcon,
      iconSelected: DashboardSelectedIcon,
      text: "Dashboard",
      redirect: "/dashboard",
      selected: location.pathname === "/dashboard",
      subitems: [],
    }, {
      id: 5,
      icon: PlatformIcon,
      iconSelected: PlatformSelectedIcon,
      text: "Platform",
      redirect: "/platform",
      selected: location.pathname === "/platform",
      subitems: [
        {
          id: 0,
          text: "Integrations",
          redirect: "/platform/integrations",
          selected: location.pathname.startsWith("/platform/integrations"),
        }, {
          id: 1,
          text: "Automations",
          redirect: "/platform/automations",
          selected: location.pathname.startsWith("/platform/automations"),
        },
      ]
    }, {
      id: 6,
      icon: SettingsIcon,
      iconSelected: SettingsSelectedIcon,
      text: "Settings",
      redirect: "/settings",
      selected: location.pathname === "/settings",
      subitems: []
    }
  ], [location.pathname])

  const adminItems = useMemo(() => [
    {
      id: 0,
      icon: DashboardIcon,
      iconSelected: DashboardSelectedIcon,
      text: "Dashboard",
      redirect: "/dashboard",
      selected: location.pathname === "/dashboard",
      subitems: [],
      permission: ['DASHBOARD_VIEW', 'DASHBOARD_VIEW'],
    }, {
      id: 1,
      icon: AccountsIcon,
      iconSelected: AccountsSelectedIcon,
      text: "Network",
      redirect: "/network",
      open: location.pathname.startsWith("/network"),
      subitems: [
        {
          id: 0,
          text: "Accounts",
          redirect: "/network/accounts",
          selected: location.pathname.startsWith("/network/accounts"),
          permission: ['ORGANIZATIONS_VIEW', 'ORGANIZATIONS_MANAGE'],
        }, 
        // {
        //   id: 1,
        //   text: "Affiliates",
        //   redirect: "/network/affiliates",
        //   selected: location.pathname.startsWith("/network/affiliates"),
        //   permission: ['ORGANIZATIONS_VIEW', 'ORGANIZATIONS_MANAGE'],
        // },
      ]    },
    {
      id: 3,
      icon: ProjectsIcon,
      iconSelected: ProjectsSelectedIcon,
      text: "Projects",
      redirect: "/projects",
      selected: location.pathname === "/projects",
      subitems: [],
      permission: ['PROJECTS_VIEW', 'PROJECTS_MANAGE'],
    },
    {
      id: 4,
      icon: CarbonIcon,
      iconSelected: CarbonIcon,
      text: "Carbon",
      redirect: "/carbon",
      selected: location.pathname === "/carbon",
      subitems: [
        {
          id: 0,
          text: "Inventory",
          redirect: "/carbon/inventory",
          selected: location.pathname.startsWith("/carbon/inventory"),
          permission: ['INVENTORY_VIEW', 'INVENTORY_MANAGE'],
        }, {
          id: 1,
          text: "Retirements",
          redirect: "/carbon/retirements",
          selected: location.pathname.startsWith("/carbon/retirements"),
          permission: ['RETIREMENTS_VIEW', 'RETIREMENTS_MANAGE'],
        }, {
          id: 2,
          text: "Reports",
          redirect: "/carbon/reports",
          selected: location.pathname.startsWith("/carbon/reports"),
          permission: ['INVENTORY_VIEW'],
        },
      ]
    },
    {
      id: 123,
      icon: MetaIcon,
      iconSelected: MetaIcon,
      text: "Commerce",
      redirect: "/commerce",
      selected: location.pathname === "/commerce",
      subitems: [
        {
          id: 1230,
          text: "Customers",
          redirect: "/commerce/contactd",
          selected: location.pathname.startsWith("/commerce/contactd"),
          permission: ['DISABLED'],
        },
         {
          id: 1231,
          text: "Transactions",
          redirect: "/commerce/transactions",
          selected: location.pathname.startsWith("/commerce/transactions"),
          permission: ['CAMPAIGNS_VIEW', 'CAMPAIGNS_MANAGE'],
        },       
        {
          id: 1233,
          text: "Campaigns",
          redirect: "/commerce/campaigns",
          selected: location.pathname.startsWith("/commerce/campaigns"),
          permission: ['CAMPAIGNS_VIEW', 'CAMPAIGNS_MANAGE'],
        },
         {
          id: 1232,
          text: "Leaderboards",
          redirect: "/commerce/leaderboards",
          selected: location.pathname.startsWith("/commerce/leaderboards"),
          permission: ['LEADERBOARDS_VIEW'],
        },
         
      ]
    },
    {
      id: 5,
      icon: MetaIcon,
      iconSelected: MetaIcon,
      text: "Meta",
      redirect: "/meta",
      selected: location.pathname === "/meta",
      subitems: [
        {
          id: 0,
          text: "Collections",
          redirect: "/meta/collections",
          selected: location.pathname.startsWith("/meta/collections"),
          permission: ['COLLECTIONS_VIEW', 'COLLECTIONS_MANAGE'],
        }, {
          id: 1,
          text: "NFTs",
          redirect: "/meta/nft",
          selected: location.pathname.startsWith("/meta/nft"),
          permission: ['NFT_VIEW', 'NFT_MANAGE'],
        }, {
          id: 2,
          text: "Templates",
          redirect: "/meta/templates",
          selected: location.pathname.startsWith("/meta/templates"),
          permission: ['NFT_VIEW', 'NFT_MANAGE'],
        }, {
          id: 3,
          text: "Schemas",
          redirect: "/meta/schemas",
          selected: location.pathname.startsWith("/meta/schemas"),
          permission: ['NFT_VIEW', 'NFT_MANAGE'],
        },
        // {
        //   id: 5,
        //   icon: ProductsIcon,
        //   iconSelected: ProductsIcon,
        //   text: "Products",
        //   redirect: "/meta/products",
        //   open: location.pathname.startsWith("/meta/products"),
        //   subitems: [],
        //   permission: ['PRODUCTS_VIEW', 'PRODUCTS_MANAGE'],
        // },
        // {
        //   id: 52,
        //   text: "NFT Minting",
        //   redirect: "/meta/nft/minting",
        //   selected: location.pathname.startsWith("/meta/nft/minting"),
        // }
      ]
    },
    // {
    //   id: 55,
    //   icon: ReportsIcon,
    //   iconSelected: ReportsIcon,
    //   text: "Reports",
    //   redirect: "/reports",
    //   selected: location.pathname === "/reports",
    //   subitems: []
    // },

    // {
    //   id: 6,
    //   icon: MetaIcon,
    //   iconSelected: MetaIcon,
    //   text: "Marketing",
    //   redirect: "/marketing",
    //   selected: location.pathname === "/marketing",
    //   subitems: [
    //     {
    //       id: 0,
    //       text: "Leaderboards",
    //       redirect: "/marketing/leaderboards",
    //       selected: location.pathname.startsWith("/marketing/leaderboards"),
    //       permission: ['LEADERBOARDS_VIEW'],
    //     },
    //      {
    //       id: 1,
    //       text: "Campaigns",
    //       redirect: "/marketing/campaigns",
    //       selected: location.pathname.startsWith("/marketing/campaigns"),
    //       permission: ['CAMPAIGNS_VIEW', 'CAMPAIGNS_MANAGE'],
    //     }
    //   ]
    // },
    // {
    //   id: 7,
    //   icon: CustomersIcon,
    //   iconSelected: CustomersSelectedIcon,
    //   text: "Customers",
    //   redirect: "/customers",
    //   open: location.pathname.startsWith("/customers"),
    //   subitems: []
    // },
    {
      id: 8,
      icon: PlatformIcon,
      iconSelected: PlatformSelectedIcon,
      text: "Platform",
      redirect: "/platform",
      selected: location.pathname === "/platform",
      subitems: [
        {
          id: 0,
          text: "Integrations",
          redirect: "/platform/integrations",
          selected: location.pathname.startsWith("/platform/integrations"),
          permission: ['INTEGRATIONS_VIEW', 'INTEGRATIONS_MANAGE'],
        }, {
          id: 1,
          text: "Automations",
          redirect: "/platform/automations",
          selected: location.pathname.startsWith("/platform/automations"),
          permission: ['AUTOMATIONS_VIEW', 'AUTOMATIONS_MANAGE'],
        },{
          id: 2,
          text: "Automation log",
          redirect: "/platform/automation/log",
          selected: location.pathname.startsWith("/platform/automation/log"),
          permission: ['AUTOMATION_LOGS_VIEW', 'AUTOMATION_LOGS_MANAGE'],
        },
      ]
    }, {
      id: 9,
      icon: SettingsIcon,
      iconSelected: SettingsSelectedIcon,
      text: "Settings",
      redirect: "/settings",
      selected: location.pathname === "/settings",
      subitems: []
    }
  ], [location.pathname])

  const [menuItems, setMenuItems] = useState(selectedAccount ? items : adminItems);

  useEffect(() => {
    setMenuItems(selectedAccount ? items : adminItems)
  }, [selectedAccount, selectedMerchant, items, adminItems])

  const onItemClick = (itemId, subitemId) => {
    const item = menuItems.find(item => item.id === itemId);
    if (item) {
      if (item.subitems.length > 0) {
        if (subitemId !== 0 && !subitemId)
          setMenuItems(
            menuItems.map(menuItem => (menuItem.id === itemId ? { ...menuItem, open: !menuItem.open } : menuItem)),
          );
        else {
            navigate(item.subitems.find(subitem => subitem.id === subitemId).redirect);
        }
      } else {
        navigate(item.redirect);
      }
    }
  };

  useEffect(() => {
    localStorage.setItem('selected-merchant', JSON.stringify(selectedMerchant))
    localStorage.setItem('selected-organisation', JSON.stringify(selectedOrganisation))
    localStorage.setItem('selected-account', JSON.stringify(selectedAccount))
    localStorage.setItem('selected-venue', JSON.stringify(selectedVenue))

  }, [selectedMerchant, selectedOrganisation, selectedAccount, selectedVenue])

  useEffect(() => {
    if (selectedPath)
      setMenuItems(menuItems.map(menuItem => selectedPath.startsWith(menuItem.redirect) ? ({ ...menuItem, open: menuItem.subitems.length > 0, selected: true, subitems: menuItem.subitems.map(subitem => ({ ...subitem, selected: subitem.redirect.startsWith(selectedPath) })) }) : ({ ...menuItem, selected: false, open: false })));
  }, [selectedPath]);

  useEffect(() => {
      if (history.action === 'POP' || history.action === 'REPLACE' || history.action === 'PUSH') {
        setSelectedPath(location.pathname);
      }
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount) {
        const { data } = await api.get(`/v1/organizations/${selectedAccount.external_id}/network?relationship=MERCHANT&page=0&size=20&name=`)
        setMerchants(data.content);
        const { data: venues } = await api.get(`/v1/organizations/${selectedAccount.external_id}/network?relationship=VENUE&page=0&size=20&name=`)
        setVenues(venues.content);
      } else {
        setMerchants([])
        setVenues([])
      }
    };
    fetchData();
  }, [selectedAccount]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedMerchant) {
        const { data } = await api.get(`/v1/organizations/${selectedMerchant.external_id}/network?relationship=VENUE&page=0&size=20&name=`)
        setVenues(data.content);
      }
    };
    fetchData();
  }, [selectedMerchant]);

  // useEffect(() => {
  //   if (selectedMerchant)
  //     setSelectedOrganisation(selectedMerchant)
  //   else if (selectedAccount)
  //     setSelectedOrganisation(selectedAccount)
  // }, [selectedMerchant, selectedAccount])

  const changeSelectedAccount = account => {
    setMerchants([])
    setVenues([])
    setSelectedAccount(account)
    setSelectedOrganisation(account)
    navigate("/dashboard")
    setMenuItems(account ? items : adminItems)
  }

  useEffect(() => {
    navigator.serviceWorker.getRegistrations().then(regs => regs.forEach(reg => reg.update()));
  }, [location]);

  const paths = [
    {
      id: 0,
      path: '/dashboard',
      Component: Dashboard,
      permission: ['SALES_APPOINTMENTS_VIEW', 'SALES_APPOINTMENTS_MANAGE'],
    }, {
      id: 1,
      path: '/business',
      Component: Business,
      permission: ['ORGANIZATIONS_VIEW', 'ORGANIZATIONS_MANAGE'],
    }, {
      id: 2,
      path: '/network/accounts',
      Component: Accounts,
      params: { type: 'ACCOUNT', title: 'Accounts' },
      permission: ['ORGANIZATIONS_VIEW', 'ORGANIZATIONS_MANAGE'],
    }, {
      id: 3,
      path: '/network/affiliates',
      Component: Accounts,
      params: { type: 'PARTNER', title: 'Affiliates' },
      permission: ['ORGANIZATIONS_VIEW', 'ORGANIZATIONS_MANAGE'],
    }, {
      id: 4,
      path: '/meta/nft/minting',
      Component: NftMinting,
      permission: ['NFT_VIEW', 'NFT_MANAGE'],
    }, {
      id: 5,
      path: '/meta/nft/:id',
      Component: NFTDetails,
      permission: ['NFT_VIEW', 'NFT_MANAGE'],
    }, {
      id: 6,
      path: '/meta/nft',
      Component: NFT,
      permission: ['NFT_VIEW', 'NFT_MANAGE'],
    }, {
      id: 63,
      path: '/meta/schemas/:id/*',
      Component: SchemaDetails,
      permission: ['NFT_VIEW', 'NFT_MANAGE'],
    }, {
      id: 61,
      path: '/meta/schemas',
      Component: Schemas,
      permission: ['NFT_VIEW', 'NFT_MANAGE'],
    }, {
      id: 62,
      path: '/meta/templates/:id/*',
      Component: TemplateDetails,
      permission: ['NFT_VIEW', 'NFT_MANAGE'],
    }, {
      id: 62,
      path: '/meta/templates',
      Component: Templates,
      permission: ['NFT_VIEW', 'NFT_MANAGE'],
    },{
      id: 7,
      path: '/meta/collections/:id/*',
      Component: CollectionDetails,
      permission: ['COLLECTIONS_VIEW', 'COLLECTIONS_MANAGE'],
    }, {
      id: 8,
      path: '/meta/collections',
      Component: Collections,
      permission: ['COLLECTIONS_VIEW', 'COLLECTIONS_MANAGE'],
    }, {
      id: 9,
      path: '/commerce/leaderboards',
      Component: Leaderboards,
      permission: ['LEADERBOARDS_VIEW'],
    }, {
      id: 10,
      path: '/commerce/campaigns/:campaignId/tiers/:tierId',
      Component: TierDetails,
      permission: ['CAMPAIGNS_VIEW', 'CAMPAIGNS_MANAGE'],
    }, {
      id: 11,
      path: '/commerce/campaigns/:id/tiers',
      Component: CampaignTiers,
      permission: ['CAMPAIGNS_VIEW', 'CAMPAIGNS_MANAGE'],
    }, {
      id: 13,
      path: '/commerce/campaigns/:id/*',
      Component: CampaignDetails,
      permission: ['CAMPAIGNS_VIEW', 'CAMPAIGNS_MANAGE'],
    }, {
      id: 14,
      path: '/commerce/campaigns',
      Component: Campaigns,
      permission: ['CAMPAIGNS_VIEW', 'CAMPAIGNS_MANAGE'],
    }, {
      id: 15,
      path: '/carbon/retirements',
      Component: Retirements,
      permission: ['RETIREMENTS_VIEW', 'RETIREMENTS_MANAGE'],
    }, {
      id: 1311,
      path: '/carbon/retirements/:id/*',
      Component: RetirementDetails,
      permission: ['RETIREMENTS_VIEW', 'RETIREMENTS_MANAGE'],
    }, {
      id: 17,
      path: '/carbon/inventory',
      Component: Inventory,
      permission: ['INVENTORY_VIEW', 'INVENTORY_MANAGE'],
    },{
      id: 172,
      path: '/commerce/transactions',
      Component: Transactions,
      permission: ['INVENTORY_VIEW', 'INVENTORY_MANAGE'],
    }, {
      id: 1313,
      path: '/carbon/inventories/:id/*',
      Component: InventoryDetails,
      permission: ['INVENTORY_VIEW', 'INVENTORY_MANAGE'],
    }, {
      id: 18,
      path: '/projects/:id',
      Component: ProjectDetails,
      permission: ['PROJECTS_VIEW', 'PROJECTS_MANAGE'],
    }, {
      id: 19,
      path: '/projects',
      Component: Projects,
      permission: ['PROJECTS_VIEW', 'PROJECTS_MANAGE'],
    }, {
      id: 20,
      path: '/meta/products/:id',
      Component: ProductDetails,
      permission: ['PRODUCTS_VIEW', 'PRODUCTS_MANAGE'],
    }, {
      id: 21,
      path: '/meta/products',
      Component: Products,
      permission: ['PRODUCTS_VIEW', 'PRODUCTS_MANAGE'],
    }, {
      id: 22,
      path: '/platform/automation/log',
      Component: AutomationLogs,
      permission: ['AUTOMATION_LOGS_VIEW', 'AUTOMATION_LOGS_MANAGE'],
    }, {
      id: 23,
      path: '/platform/automation/log/:id',
      Component: AutomationLogView,
      permission: ['AUTOMATION_LOGS_VIEW', 'AUTOMATION_LOGS_MANAGE'],
    }, {
      id: 24,
      path: '/platform/automations',
      Component: Automations,
      permission: ['AUTOMATIONS_VIEW', 'AUTOMATIONS_MANAGE'],
    }, {
      id: 25,
      path: '/platform/integrations',
      Component: Integration,
      permission: ['INTEGRATIONS_VIEW', 'INTEGRATIONS_MANAGE'],
    }, {
      id: 26,
      path: '/settings/*',
      Component: Settings,
      actions: {inviteUser: () => setShowInviteUser(true)}
    }, {
      id: 27,
      path: '/carbon/reports',
      Component: Reports,
      permission: ['SALES_APPOINTMENTS_VIEW', 'SALES_APPOINTMENTS_MANAGE'],
    }, {
      id: 28,
      path: '/carbon/reports/*',
      Component: ReportDetails,
      permission: ['INTEGRATIONS_VIEW'],
    },
  ]

  const hasMultiplePermission = permissionList => {
    return (
      !permissionList ||
      (permissionList.length > 0 && permissionList.find(permission => permissions.includes(permission)))
    );
  };

  const checkMultiplePermission = (action, permissionList) => {
    if (
      !permissionList ||
      (permissionList.length > 0 && permissionList.find(permission => permissions.includes(permission)))
    ) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: 'You do not have permission to perform this action',
          }),
        );
  };

  return (
    <div className={`main-page`}>
      <OrganisationContext.Provider value={{ venues, setVenues, merchants, selectedMerchant, setSelectedMerchant, selectedVenue, setSelectedVenue, selectedOrganisation, setSelectedOrganisation, setSelectedAccount, selectedAccount, changeSelectedAccount, modal, setModal, checkPermission, hasPermission, hasMultiplePermission, checkMultiplePermission }} >
        <Sidebar
          menuItems={menuItems}
          onItemClick={onItemClick}
          inviteUser={() => setShowInviteUser(true)}
          selectedPath={selectedPath}
          user={user}
          setUser={u => dispatch(setUser(u))}
        />
        <div className="main">
          <div className="content">
            <Routes>
              {paths
                .filter(path => hasMultiplePermission(path.permission))
                .map(path => (
                  <Route
                    key={path.id}
                    element={<path.Component {...path.params} path={path.path} {...path.actions} />}
                    path={path.path}
                  />
                ))}
                <Route
                  element={
                    <Navigate
                      replace
                      to={
                        paths.find(path => hasMultiplePermission(path.permission))
                          ? paths.find(path => hasMultiplePermission(path.permission)).path
                          : '/profile'
                      }
                    />
                  }
                  path="*"
                />
            </Routes>
          </div>
          {showInviteUser && <InviteUser venues={venues} close={() => setShowInviteUser(false)} merchant={selectedMerchant || user.organisations[0]} />}
        </div>
        <Modal />
      </OrganisationContext.Provider>
    </div >
  );
};

export default Main;

