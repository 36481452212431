import React, { useEffect, useState, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getCollection,
  getCollectionIntegrations,
  saveCollection,
  setCollection,
} from "../../store/features/collectionSlice";

import NavTop from "../../components/common/NavTop";

import CollectionMainDetails from "../../components/collections/CollectionMainDetails";
import CollectionTemplates from "../../components/collections/CollectionTemplates";
import CollectionNfts from "../../components/collections/collectionNfts";
import CollectionSchema from "../../components/collections/CollectionSchema";
import CollectionMedia from "../../components/collections/CollectionMedia";
import TemplateMenu from "../../components/DefaultMenu";

import Image from "../../components/image";
import EditDeleteMenu from "../../components/Elements/EditDeleteMenu";
import Button from "../../components/Elements/button/button";
import placeholder from "../../assets/images/project-placeholder.svg";

import {
  Route,
  Routes,
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";

import { OrganisationContext } from "../../context/organisationContext";

import "./style.scss";
import DropDown from "../../components/McDropdown";

const CollectionDetails = () => {
  const menuItems = [
    { id: 0, name: "Details", path: "" },
    { id: 1, name: "Template", path: "/templates" },
    { id: 2, name: "Schemas", path: "/schemas" },
    { id: 3, name: "NFT", path: "/nfts" },
    { id: 4, name: "Media", path: "/media" },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const template = location.state?.template;
  const collectionDetailsFallback = useRef(null);

  const { collection, integrations } = useSelector((data) => data.collection);
  const { integration } = collection || {};
  const { setModal, checkPermission, hasPermission } =
    useContext(OrganisationContext);

  const { id } = useParams();
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    dispatch(getCollection({ id }));
  }, [id]);

  const onMenuSelect = (item) => {
    navigate(`/meta/collections/${id}${item.path}`);
  };

  useEffect(() => {
    if (edit) dispatch(getCollectionIntegrations());
  }, [edit]);

  const getMediaId = (media) => {
    return media?.media_external_id
      ? {
          id: media?.media_external_id,
        }
      : null;
  };

  const onSave = () => {
    dispatch(
      saveCollection({
        payload: {
          ...collection,
          image: getMediaId(collection.image),
          background_image: getMediaId(collection.backgroundd_image),
          video: getMediaId(collection.video),
          background_video: getMediaId(collection.background_video),
        },
        fallbackPayload: collectionDetailsFallback.current,
      })
    );
    setEdit(false);
  };

  return (
    <div className="flex-column flex-1 collection-details">
      <div className="mb-6 mt-8 ml-6">
        {template ? (
          <NavTop
            done={true}
            onDone={() => navigate(`/meta/collections`, { replace: true })}
            edit={false}
            names={[
              { text: "Templates", path: "/meta/templates" },
              {
                text: template?.name,
                path: `/meta/templates/${template.id}/collections`,
              },
              { text: "Collections", path: "/meta/collections" },
              { text: collection?.name },
            ]}
          />
        ) : (
          <NavTop
            done={true}
            onDone={() =>navigate(`/meta/collections`, { replace: true })}
            edit={false}
            names={[
              { text: "Collections", path: "/meta/collections" },
              { text: collection?.name },
            ]}
          />
        )}
      </div>

      <div className="flex ml-6">
        <div className="card pxy-6 h-content flex-column collection-info">
          <div className="flex items-center justify-between mb-6">
            <label className="bold-text font-20">Collection details</label>
            <EditDeleteMenu
              onEdit={() => {
                collectionDetailsFallback.current = collection;
                setEdit(!edit);
              }}
              onDelete={() =>
                setModal({
                  type: "delete-project",
                  content: { project: collection, top: true },
                })
              }
            />
          </div>
          {edit ? (
            <div className="flex-column">
              <div className="flex justify-center mt-4 mb-6">
                <Button
                  className="secondary-hover"
                  label="Cancel"
                  borderRadius="12px"
                  onClick={() => {
                    dispatch(setCollection(collectionDetailsFallback.current));
                    setEdit(false);
                  }}
                  secondary={true}
                  size="average"
                  style={{ flex: 1 }}
                  bgColor="#FFFFFF"
                  borderColor="#24CBB1"
                  color="#24CBB1"
                  borderWidth="1px"
                />
                <Button
                  label="Save"
                  onClick={() =>
                    checkPermission(() => onSave(), "PROJECTS_MANAGE")
                  }
                  size="average"
                  style={{ flex: 1, marginLeft: 24 }}
                  bgColor="#24CBB1"
                  borderColor="#24CBB1"
                  color="#FFFFFF"
                  borderRadius="12px"
                  borderWidth="1px"
                  disabled={!hasPermission("PROJECTS_MANAGE")}
                />
              </div>
              <div className="flex-column collection-info-edit">
                <Image
                  className={"collection-image"}
                  size={224}
                  media={collection?.image?.media}
                  uploadable={false}
                  editable={false}
                />

                <label className="input-label mt-6">Name</label>
                <input
                  className="input"
                  value={collection?.name || ""}
                  placeholder="Name"
                  onChange={(e) =>
                    dispatch(
                      setCollection({
                        ...collection,
                        name: e.target.value,
                      })
                    )
                  }
                />
                <label className="input-label mt-6">Display name</label>
                <input
                  className="input"
                  placeholder="Display name"
                  value={collection?.display_name}
                  onChange={(e) =>
                    dispatch(
                      setCollection({
                        ...collection,
                        display_name: e.target.value,
                      })
                    )
                  }
                />
                <label className="regular-text light-purple-text mt-6">
                  Blockchain
                </label>
                <DropDown
                  size="large"
                  placeholder="Select blockchain"
                  selected={integration}
                  setSelected={(c) =>
                    dispatch(
                      setCollection({
                        ...collection,
                        integration: {
                          ...c,
                          icon: c.connector?.icon
                            ? { ...c.connector?.icon }
                            : {},
                        },
                      })
                    )
                  }
                  options={integrations}
                />
                <label className="input-label mt-6">Market Fee (0%-6%):</label>
                <input
                  className="input"
                  value={collection?.market_fee || ""}
                  placeholder="Market Fee (0%-6%)"
                  onChange={(e) =>
                    dispatch(
                      setCollection({
                        ...collection,
                        market_fee: e.target.value,
                      })
                    )
                  }
                />
              </div>
            </div>
          ) : (
            <div className="flex-column collection-info-details">
              <Image
                className={"collection-image"}
                size={224}
                media={collection?.image?.media}
                placeholder={placeholder}
              />
              <div className="flex items-center justify-between mt-6">
                <label className="regular-text light-purple-text">Name:</label>

                <label className="semibold-text">{collection?.name}</label>
              </div>
              <div className="flex items-center justify-between mt-4">
                <label className="regular-text light-purple-text">
                  Display name:
                </label>

                <label className="semibold-text">
                  {collection?.display_name || "-"}
                </label>
              </div>
              <div className="flex items-center justify-between mt-4">
                <label className="regular-text light-purple-text">
                  Blockchain:
                </label>
                <div className="flex items-center">
                  {integration ?
                  <img
                    className="integration-icon mr-2"
                    src={integration?.icon?.active}
                    alt="icon"
                  />
                  :
                  <label></label>
                  }
                  <label className="regular-text">{integration?.name || '-'}</label>
                </div>
              </div>
              <div className="flex items-center justify-between mt-4">
                <label className="regular-text light-purple-text">
                  Market Fee (0%-6%):
                </label>

                <label className="semibold-text">
                  {collection?.market_fee || "-"}
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="flex card mx-6 flex-1 fit-content">
          <Routes>
            <Route
              element={
                <CollectionMainDetails
                  collection={collection}
                  menuItems={menuItems}
                  onMenuItemClick={onMenuSelect}
                  onSaveDetails={onSave}
                />
              }
              path={`/`}
            />
            <Route
              element={
                <CollectionNfts
                  menuItems={menuItems}
                  onMenuItemClick={onMenuSelect}
                />
              }
              path={`/nfts`}
            />
            <Route
              element={
                <CollectionTemplates
                  menuItems={menuItems}
                  onMenuItemClick={onMenuSelect}
                  collection={collection}
                />
              }
              path={`/templates`}
            />
            <Route
              element={
                <CollectionSchema
                  menuItems={menuItems}
                  onMenuItemClick={onMenuSelect}
                />
              }
              path={`/schemas`}
            />
            <Route
              element={
                <CollectionMedia
                  collection={collection}
                  menu={
                    <TemplateMenu
                      menuItems={menuItems}
                      onMenuItemClick={onMenuSelect}
                    />
                  }
                />
              }
              path={`/media`}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default CollectionDetails;
