import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getInventories, exportInventories } from '../../store/features/inventorySlice';
import Header from "../../components/header";
import InventoryCard from "../../components/inventory/InventoryCard";
import Filter from "../../components/filter";
import AddCarbonPopup from "../../components/projectDetails/AddCarbonPopup";
import Loading from "../../assets/images/loading.svg";
import './styles.scss'

const Inventory = () => {
    const dispatch = useDispatch();
    const { inventories, loading } = useSelector(data => data.inventory);

    const savedFilter = JSON.parse(localStorage.getItem("inventory-filter"))

    const [currentPage, setCurrentPage] = useState(0)
    const [filter, setFilter] = useState(savedFilter ? savedFilter : {value: ''});
    const [editedCarbon, setEditedCarbon] = useState(false);
    const [addCarbon, setAddCarbon] = useState()
    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch(getInventories({
                page: currentPage, 
                filter: filter?.value,
                search: search,
            size: 25}));
        localStorage.setItem("inventory-filter", JSON.stringify(filter))
    }, [currentPage, filter, search, dispatch]);

    const onExport = () => {
        dispatch(exportInventories({
            filter: filter?.value,
            search: search,
        }));
    }

    return (
        <div className="flex-column mb-6 mt-2 flex-1">
            <Header
                title={"Inventory"}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={inventories.total_pages}
                isFilter={true}
                isSearch={true}
                search={search}
                setSearch={setSearch}
                filterChildren={<Filter objects={["PROJECT", "PURCHASE_DATES", "OWNER"]} setFilter={setFilter}  values={filter?.filters}/>}
                filter={filter}
                isAddBtn={true}
                onAdd={() => setAddCarbon(true)}
                addPermission="INVENTORY_MANAGE"
                onExport={onExport}
            />
            <div className="flex-column card mx-6 mt-2">
                <div className="ci-grid ci-header border-top">
                    <label className="font-12 semibold-text px-6 py-4">NAME</label>
                    <label className="font-12 semibold-text px-6 py-4">PROJECT</label>
                    <label className="font-12 semibold-text px-6 py-4">COUNTRY</label>
                    <label className="font-12 semibold-text px-6 py-4">REGISTRY</label>
                    <label className="font-12 semibold-text px-6 py-4">PRIMARY ACCOUNT</label>
                    <label className="font-12 semibold-text px-6 py-4">VINTAGE</label>
                    <label className="font-12 semibold-text px-6 py-4">OWNER</label>
                    {/* <label className="font-12 semibold-text px-6 py-4">OPEN</label> */}
                    {/* <label className="font-12 semibold-text px-6 py-4">OPEN BALANCE</label> */}
                    <label className="font-12 semibold-text px-6 py-4">CONSIGNED</label>
                    <label className="font-12 semibold-text px-6 py-4">AVAILABLE</label>
                </div>
                {loading ?
                    <div className="flex flex-1 items-center justify-center mt-4 pxy-6">
                        <img src={Loading} alt="loading" />
                        <label className="bold-text font-16 ml-2 light-purple-text">
                            Loading
                        </label>
                    </div>
                    :
                    <div className="ci-list">
                        {inventories.content?.map(inventory => <InventoryCard key={inventory.id} inventory={inventory} editCarbon={setEditedCarbon}/>)}
                    </div>
                }
            </div>
            {(editedCarbon || addCarbon) && <AddCarbonPopup setIsCarbonDialog={() => {setEditedCarbon(false); setAddCarbon(false); }} carbon={editedCarbon}/>}
        </div>
    )
}

export default Inventory;
