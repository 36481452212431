import { ReactComponent as InviteIcon } from '../../../assets/images/invite-icon.svg';
import api from '../../../store/services'
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SearchIcon } from '../../../assets/images/search-icon.svg';
import { OrganisationContext } from '../../../context/organisationContext';
import Pagination from '../../pagination/pagination';
import TeamItem from './teamItem';
import Loading from '../../../assets/images/loading.svg';
import useDebounce from '../../../helpers/useDebounceHook';
import Profile from '../../../pages/profile/profile';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-image.svg';
import { PaginationContainerWrapper, TeamsWrapper } from './teams.styled';
import { UsersContainerWrapper } from '../users/users.styled';
import TeamDetails from './teamDetails';
import { setTeams, setSelectedTeam, setSelectedUser } from '../../../store/features/settingsSlice';

const Teams = () => {
  const { user } = useSelector(state => state.user);

  const organisationId = user?.organisations[0].external_id;
  const accountOrganisationId = user?.organisations.find(o => o.org_type === 'ACCOUNT')?.external_id || '';
  const { setModal, modal, hasPermission, checkPermission } = useContext(OrganisationContext);
  const { teams, selectedTeam, selectedUser } = useSelector(state => state.settings);
  const dispatch = useDispatch();
  const [selectedTeamPage, setSelectedTeamPage] = useState(
    Number(localStorage.getItem('initTeam-page')) || Number(localStorage.getItem('teams-page')) || 0,
  );
  const [totalTeamPage, setTotalTeamPages] = useState(0);
  const [searchTeamText, setSearchTeamText] = useState('');
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);

  const debouncedSearchTeamText = useDebounce(searchTeamText, 500);
  const previousSearchTeamTextRef = useRef('');
  const [initTeamPage, setInitTeamPage] = useState(selectedTeamPage);

  const fetchData = async loading => {
    setLoading(loading);
    const { data } = await api.get(
      `/v1/organizations/${organisationId}/teams?page=${selectedTeamPage}&size=19&search=${searchTeamText}`,
    );
    dispatch(setTeams(data.content));
    setTotalTeamPages(data.total_pages);
    setLoading(false);
  };

  useEffect(() => {
    if (totalTeamPage && selectedTeamPage > totalTeamPage - 1) {
      setSelectedTeamPage(totalTeamPage ? totalTeamPage - 1 : totalTeamPage);
    }
  }, [totalTeamPage]);

  useEffect(() => {
    const call = async() => {
      if (previousSearchTeamTextRef.current !== debouncedSearchTeamText) {
        const currentSelectedPage = selectedTeamPage;
        if (currentSelectedPage === 0 && debouncedSearchTeamText !== '') {
          await fetchData(true);
        }
        if (initTeamPage === 0 && debouncedSearchTeamText === '') {
          await fetchData(true);
        }
        if (debouncedSearchTeamText === '') {
          setSelectedTeamPage(initTeamPage);
          localStorage.setItem('teams-page', initTeamPage);
        } else {
          setSelectedTeamPage(0);
        }
        previousSearchTeamTextRef.current = debouncedSearchTeamText;
      } else {
        if (previousSearchTeamTextRef.current === '' && debouncedSearchTeamText === '') {
          setInitTeamPage(selectedTeamPage);
          localStorage.setItem('initTeam-page', selectedTeamPage);
        }
        fetchData(true);
      }
    }
 
    call()
  }, [organisationId, selectedTeamPage, debouncedSearchTeamText]);

  const selectTeam = team => {
    localStorage.setItem('selected-team', JSON.stringify(team));
    dispatch(setSelectedTeam(team));
  };

  useEffect(() => {
    const selectedTeam = JSON.parse(localStorage.getItem('selected-team'));
    const selectedUser = JSON.parse(localStorage.getItem('selected-user'));
    selectUser(selectedUser);
    selectTeam(selectedTeam);
    return () => {
      dispatch(setSelectedTeam({}));
      dispatch(setTeams([]));
      selectUser({});
    };
  }, []);

  useEffect(() => {
    if (
      modal.type === 'invite-user-success' ||
      modal.type === 'add-team-success' ||
      modal.type === 'delete-team-success' ||
      modal.type === 're-invite-user-success'
    ) {
      fetchData(false);
    }
  }, [modal.type]);

  const deleteUserFromTeam = user => {
    setModal({
      type: 'delete-user',
      content: {
        user: user,
        isFromTeam: true,
        organisationId: selectedTeam.id,
      },
    });
  };

  const onEditUser = user => {
    setModal({
      type: 'invite-user',
      content: {
        venues: [],
        merchant: user.organisations?.[0],
        user: user,
        organisationId: selectedTeam.id,
      },
    });
  };

  const onInviteUser = user => {
    setModal({
      type: 're-invite-user',
      content: {
        user: user,
        organisationId: selectedTeam.id,
        top: true,
      },
    });
  };

  const selectUser = user => {
    localStorage.setItem('selected-user', JSON.stringify(user));
    dispatch(setSelectedUser(user));
  };

  const updateUserData = updatedSelectedUser => {
    selectUser(updatedSelectedUser);
  };

  const handleBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFocused(false);
    }
  };

  const isSameTenant = team => {
    return team?.tenant?.id === accountOrganisationId;
  };

  return (
    <>
      {selectedUser?.id ? (
        <div className="mt-4">
          <Profile
            fromTeamDetails={true}
            isSameTenant={() => isSameTenant(selectedTeam)}
            loadData={true}
            profileClassName="selected-user-profile"
            selectedUser={selectedUser}
            updateUserData={updateUserData}
          />
        </div>
      ) : (
        <>
          {selectedTeam.id ? (
            <UsersContainerWrapper>
              {loading ? (
                <div className="flex items-center justify-center content-loader">
                  <img alt="loading" height="64px" src={Loading} />
                </div>
              ) : (
                <TeamDetails
                  accountOrganisationId={accountOrganisationId}
                  changeDeleteUser={deleteUserFromTeam}
                  fetchData={fetchData}
                  onEditUser={onEditUser}
                  onInviteUser={onInviteUser}
                  selectedTeam={selectedTeam}
                  selectTeam={selectTeam}
                  setSelectedUser={selectUser}
                />
              )}
            </UsersContainerWrapper>
          ) : (
            <>
              <PaginationContainerWrapper>
                <div
                  className="add-team mr-6 mt-1"
                  onClick={() =>
                    checkPermission(
                      () =>
                        setModal({
                          type: 'add-team',
                        }),
                      'TEAMS_MANAGE',
                    )
                  }>
                  <InviteIcon />
                  <p className="medium-text font-14 ml-2">Add team</p>
                </div>
                <Pagination
                  className="user-pagination"
                  selectedPage={selectedTeamPage}
                  setSelectedPage={page => {
                    setSelectedTeamPage(page);
                    localStorage.setItem('teams-page', page);
                  }}
                  totalPages={totalTeamPage}
                />
                <div
                  className={`properties-search ${focused ? 'search-focused' : ''}`}
                  onBlur={e => handleBlur(e)}
                  onFocus={() => setFocused(true)}>
                  <SearchIcon className="search-icon" />
                  <input
                    className="regular-text"
                    onChange={e => setSearchTeamText(e.target.value)}
                    placeholder="Search"
                    value={searchTeamText || ''}
                  />
                  {focused && (
                    <div
                      className="close"
                      onClick={e => {
                        setSearchTeamText('');
                      }}
                      tabIndex="0">
                      <CloseIcon className="close-icon" />
                    </div>
                  )}
                </div>
              </PaginationContainerWrapper>
              {loading ? (
                <UsersContainerWrapper>
                  <div className="flex items-center justify-center content-loader">
                    <img alt="loading" height="64px" src={Loading} />
                  </div>
                </UsersContainerWrapper>
              ) : (
                <TeamsWrapper className="scroller">
                  {teams?.map((team, key) => (
                    <TeamItem
                      accountOrganisationId={accountOrganisationId}
                      checkPermission={checkPermission}
                      hasPermission={hasPermission}
                      organisationId={organisationId}
                      setSelectedTeam={selectTeam}
                      team={team}
                      teamKey={key}
                      key={key}
                    />
                  ))}
                </TeamsWrapper>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Teams;
