import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import Button from '../components/Elements/button/button';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { addToast } from '../store/features/toastSlice';
import { CreateNftWrapper } from './modal.styled.js';
import { CSSTransition } from 'react-transition-group';
import api from '../store/services'

const MintNft = () => {
  const { modal, setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {campaign, onSuccess} = modal.content;

  const onMint = async () => {
    if (loading) return;
    setLoading(true);
    try {
    await api.post(`/v3/api/backoffice/campaigns/${campaign.id}/mint`)
      dispatch(
        addToast({
          error: false,
          title: 'nft-minted',
          text: `NFTs have been minted`,
          id: uuid(),
        }),
      );
      // onSuccess();
    } catch (error) {
      dispatch(
        addToast({
          error: true,
          text: `Failed to mint NFTs`,
          id: uuid(),
        }),
      );
    }
    // onSuccess();
  
    setLoading(false);
    setModal(initModal);
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <CreateNftWrapper>
        <p className="bold-text font-24 text-center">
          Are you sure you want to mint this campaigns NFT's?
        </p>
        <p className='regular-text text-center light-purple-text mt-2'>Once minted this action cannot be un-done</p>
        <div className="flex justify-center mt-12">
          <Button
            className="secondary-hover"
            label="Cancel"
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            style={{ flex: 1, borderRadius: '16px' }}
            bgColor="#FFFFFF"
            borderColor='#24CBB1'
            color='#24CBB1'
          />
          <Button
            label='Mint'
            onClick={onMint}
            size="large"
            style={{ flex: 1, marginLeft: 24, borderRadius: '16px' }}
            bgColor="#24CBB1"
            borderColor='#24CBB1'
            color='#FFFFFF'
          />
        </div>
      </CreateNftWrapper>
    </CSSTransition>
  );
};

export default MintNft;
