import React from 'react'

const SupporterCard = ({isProject = false, bgColor, supporter}) => {

    return (
        <div className={`${!bgColor && 'card'} retirement-card flex justify-between cursor mx-6 mb-4`}
             style={{backgroundColor: bgColor, borderRadius: 16}}>
            <div className={"py-4 px-6 border-right"}>
                <div className={"bold-text font-16 text-center"}>{supporter.rank}</div>
                <div className={"regular-text font-12 o05 mt-2 text-center"}>Rank</div>
            </div>

            <div className={"card-box bor   der-right flex-1"}>
                <div className='regular-text'><span className="regular-text o05 mr-1">Contact:</span> {supporter.contact.forename}</div>
                <div className={"mt-2 regular-text"}><span className="regular-text o05 mr-1">Alias:</span> {supporter.contact.alias}</div>
            </div>

            <div className={"card-box border-right flex-2"}>
                <div className='regular-text'><span className="regular-text o05 mr-1">Wallet:</span> {supporter.wallet.wallet_address}
                </div>
                <div className={"mt-2 regular-text"}><span className="regular-text o05 mr-1">Alias:</span> {supporter.wallet.alias}</div>
            </div>

            {isProject && <div className={"card-box flex-1"}>
                {supporter.project.name ? <div className='regular-text'><span className="regular-text o05 mr-1">Project:</span> {supporter.project.name}</div>:
                 <div className='regular-text o05'><span className="regular-text o05 mr-1">Project:</span>no project</div>}
            </div>}

            <div className={"py-4 px-6 border-left"} style={{width: '100px'}}>
                <div className={"bold-text font-16 text-center"}>{supporter.co2_kg_units || 0}</div>
                <div className={"regular-text font-12 o05 mt-2 text-center"}>kilos</div>
            </div>
        </div>
    )
}

export default SupporterCard;
