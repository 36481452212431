import Selected from '../assets/images/selected.svg';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { ReactComponent as SearchIcon } from '../assets/images/search-icon.svg';
import Button from '../components/Elements/button/button';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import api from "../store/services";
import { addToast } from '../store/features/toastSlice';
import { AddTeamMemberWrapper } from './modal.styled.js';
import { CSSTransition } from 'react-transition-group';
import { AsyncPaginate } from 'react-select-async-paginate';

const MultiValueOption = props => {
  const onSelect = () => {
    const currentSelected = props.getValue();
    const newSelected = props.isSelected
      ? currentSelected.filter(opt => opt.value !== props.value)
      : [...currentSelected, { ...props.data }];
    props.setValue(newSelected);
  };
  return (
    <div className={`tpus-user ${props.isSelected && 'tpus-user-selected'}`} onClick={() => onSelect()}>
      <label className={`regular-text ${props.isSelected && 'bold-text'}`}>{props.label}</label>
      {props.isSelected && <img alt="icon" src={Selected} />}
    </div>
  );
};

const AddTeamMember = () => {
  const { modal, setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const [inputState, setInputState] = useState('');
  const [selectedValue, setSelectedValue] = useState([]);
  const [optionsList, setOptions] = useState([]);
  const [preLoadOptions, setPreLoadOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [allTeamMembers, setAllTeamMembers] = useState('');
  const isInitialMount = useRef(true);
  const [page, setpage] = useState(0);
  const [error, setError] = useState(false);
  const { user } = useSelector(state => state.user);

  const selectedTeam = modal.content?.selectedTeam;

  const onAddMembers = async () => {
    try {
      if (!(selectedValue.length > 0) && !selectedRole) {
        setError(true);
        dispatch(addToast({ error: true, text: 'Please select user and role' }));
        return;
      } else if (!(selectedValue.length > 0)) {
        dispatch(addToast({ error: true, text: 'Please select user' }));
        return;
      } else if (!selectedRole) {
        dispatch(addToast({ error: true, text: 'Please select role' }));
        return;
      }
      const request = selectedValue.map(team => ({
        adminuser_id: team.value,
        level: selectedRole,
      }));
      const { data } = await api.post(`/v1/organisations/${selectedTeam.id}/adminusers`, request);

      dispatch(
        addToast({
          error: false,
          text: 'New members in team added successfully',
          id: uuid(),
        }),
      );
      setModal({ type: 'add-team-member-success', content: {} });
    } catch (e) {
      dispatch(
        addToast({
          error: true,
          text: 'Error while adding members in team',
          id: uuid(),
        }),
      );
      setModal(initModal);
    }
  };

  const getOptions = async inputValue => {
    try {
      const { data } = await api.get(`/v1/users?size=20&page=${page}&name=${inputValue}`);

      const filterUser = !allTeamMembers || allTeamMembers.length === 0 ? data.content :
                         data?.content?.filter(u => !allTeamMembers.find(existingUser => existingUser.id === u.id));
      return {
        options: filterUser.map(user => {
          return { label: user.name, value: user.id };
        }),
        hasMore: !data.last,
      };
    } catch (e) {
      console.log(e)
      return [];
    }
  };

  const loadOptions = async (inputValue, prevOptions) => {
    let filteroptions = await getOptions(inputValue);
    console.log(filteroptions)
    setpage(page + 1);
    setOptions([...optionsList, ...filteroptions.options]);
    return { options: [...filteroptions.options], hasMore: filteroptions.hasMore };
  };

  const handleInputChange = newValue => {
    setpage(0);
    setInputState(newValue);
    return newValue;
  };

  const handleChange = value => {
    setSelectedValue(value);
    setError(false);
  };


  useEffect(() => {
    const fetchAllTeamMembers = async () => {
      const { data } = await api.get(
        `/v1/organizations/${user.organisations[0].external_id}/teams/${selectedTeam.id}/users?size=${
          selectedTeam?.adminuser_count ? selectedTeam?.adminuser_count : 20
        }`,
      );
      setAllTeamMembers(data);
    };
    fetchAllTeamMembers();
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      const fetchOptions = async () => {
        const filterOptions = await loadOptions('', []);
        setPreLoadOptions(filterOptions.options);
      };
      fetchOptions();
    }
  }, [allTeamMembers]);

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AddTeamMemberWrapper>
        <p className="bold-text font-24 text-center mb-2">Add new member</p>
        <div className="w-full flex-column mb-6 mt-6 user-search-container relative">
          {!(selectedValue.length > 0) && <SearchIcon className="search-icon" />}
          <AsyncPaginate
            className={`options-select-container
              ${!(selectedValue.length > 0) && 'search-icon-placeholder'}
              ${error && 'error-border'}`}
            classNamePrefix="select"
            closeMenuOnSelect={false}
            defaultOptions={optionsList}
            inputValue={inputState}
            isMulti
            loadOptions={loadOptions}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onMenuClose={() => setOptions([])}
            onMenuOpen={() => setOptions(preLoadOptions)}
            placeholder="Search users by names, surnames"
            value={selectedValue}
          />
        </div>
        <div className="w-full flex mb-4 user-roles-container">
          <div
            className={`user-role w-full cursor ${selectedRole === 'LEADER' && 'selected-user-role'}`}
            onClick={() => setSelectedRole('LEADER')}>
            <label className="medium-text font-12">TEAM LEAD</label>
          </div>
          <div
            className={`user-role w-full cursor ${selectedRole === 'MEMBER' && 'selected-user-role'}`}
            onClick={() => setSelectedRole('MEMBER')}>
            <label className="medium-text font-12">TEAM MEMBER</label>
          </div>
        </div>
        <div className="flex justify-center mt-8 w-full">
          <Button
            className="secondary-hover"
            label={'Cancel'}
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            style={{ flex: 1 }}
            width="170px"
          />
          <Button
            className="primary-hover"
            label="Add"
            onClick={onAddMembers}
            primary={true}
            size="large"
            style={{ flex: 1, marginLeft: 24 }}
            width="170px"
          />
        </div>
      </AddTeamMemberWrapper>
    </CSSTransition>
  );
};

export default AddTeamMember;
