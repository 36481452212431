import React, { useState, useEffect } from 'react'

import CloseIcon from '../../assets/images/close.svg'

import "../../pages/projectDetails/details.scss";

const AddMediagroupPopup = ({ close, add }) => {

    const [name, setName] = useState('');
    const [active, setActive] = useState(false);

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    useEffect(() => {
        setActive(name.length > 2);
    }, [name]);

    return (
        <div className='add-media-group-popup' onClick={onClick}>
            <div className='add-media-group-content'>
                <div className="flex items-center justify-between">
                    <label className='dup-title'>New media group</label>
                    <div className="flex items-center justify-center mr-12 close-btn cursor" onClick={close}>
                        <img className='' src={CloseIcon} alt="close" />
                    </div>
                </div>
                <label className="input-label mt-6">Name</label>
                <input autoFocus className="input" value={name} onChange={e => setName(e.target.value)} placeholder="Enter media group" />
                <div className='dup-actions'>
                    <button onClick={close}>Cancel</button>
                    <button className='add-action ml-6' style={{background: active ? '#24CBB1' : 'rgba(181, 185, 193, 0.3)'}} onClick={() => active ? add(name) : {}}>Add</button>
                </div>
            </div>
        </div>
    )
}

export default AddMediagroupPopup;