import styled from 'styled-components';

export const PropertyContentWrapper = styled.div`
  max-height: calc(100vh - 165px);
  display: flex;
  flex-direction: column;

  .content-padding {
    padding: 0 30px 0 30px;
  }

  hr {
    margin: 0;
  }

  .icon-container {
    padding: 4px;
    background: #f4f7f9;
    border-radius: 4px;
    max-width: 26px;
  }

  .edit-action-icon {
    width: 32px;
  }

  .no-value-label {
    opacity: 0.3;
  }

  .property-map {
    margin: 24px;
  }

  .epc-arrow-container {
    .mi-arrow {
      transition-duration: 300ms;
      transition-property: transform;
    }

    .mi-arrow-down {
      transform: rotate(180deg);
    }
  }
`;

export const CustomerContentWrapper = styled.div`
  padding: 0 30px 24px 30px;
  max-height: calc(100vh - 165px);
  overflow: scroll;

  @media (max-width: 1280px) {
    height: calc(100vh - 450px);
  }

  .no_data {
    width: 300px;
    margin-top: 20px;
  }
  .text-center {
    margin: auto;
  }
  .icon-container {
    min-height: 24px;
    min-width: 24px;
    background: #f4f7f9;
    border-radius: 4px;
  }
  .dwp-status {
    background: #7bd77a;
    border-radius: 4px;
    height: 18px;
    padding: 4px;
  }
  .add-contact {
    margin-left: 8px;
    border: 2px solid #d2e5ed;
    border-radius: 10px;
    padding: 4px;

    &:hover {
      background: #e5ebef;
    }

    .add-contact-icon {
      width: 18px;
      height: 18px;
    }

    .medium-text {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
`;

export const ContactWrapper = styled.div`
  margin-bottom: 24px;
  .request-dm-btn {
    height: 32px;
    width: 168px;
    background: #40f1de;
    border-radius: 8px;
    border: none;
    outline: none;

    font-family: Roboto Bold;
    font-size: 14px;
    color: #ffffff;
  }

  .benefit-infos {
    height: 48px;
    background: #f4f9fb;
    border-radius: 8px;

    .benefit-info {
      border-right: 1px solid #e3eef3;
    }

    .benefit-info:last-child {
      border: 0;
    }
  }

  .edit-actions {
    width: 34px;
  }

  .no-value-label {
    opacity: 0.3;
  }

  .preferences {
    display: flex;
    width: 50%;
    flex-wrap: wrap;
  }

  .label-bg {
    padding: 8px;
    margin-right: 8px;
    margin-top: 8px;
    background: #f4f7f9;
    border-radius: 8px;
  }
`;

export const MarkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;

  .line {
    height: 100%;
    min-width: 2px;
    background: #d2e5ed;
    transition: height 400ms linear;
  }
  .top {
    min-height: 20px;
    height: 20px;
    max-height: 20px;
  }

  .point {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    min-height: 24px;
    border: 2px solid #d2e5ed;
    border-radius: 4px;

    .inner-point {
      min-width: 10px;
      min-height: 10px;
      background: #ff5858;
      border-radius: 2px;
    }
  }
`;

export const AutomationContentWrapper = styled.div`
  .automation-logs {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(70vh - 150px);
    justify-content: center;

    label {
      color: #d2e5ed;
      font-size: 18px;
    }

    @media (max-width: 1280px) {
      height: calc(100vh - 450px);
    }
  }
`;

export const PhotoContentWrapper = styled.div`
  max-height: calc(100vh - 150px);
  overflow: scroll;

  @media (max-width: 1280px) {
    max-height: calc(100vh - 450px);
  }

  .photo-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 30px;
    column-gap: 25px;
    margin-top: 25px;
    width: 100%;

    @media only screen and (max-width: 1630px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (max-width: 1450px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 1270px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (max-width: 1100px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .sub-photos {
    background: rgba(244, 247, 249, 0.5);
    border-radius: 8px;
    margin-top: 24px;
    padding: 24px;
  }

  .no-photo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 150px);
    justify-content: center;

    label {
      color: #d2e5ed;
      font-size: 18px;
    }

    @media (max-width: 1280px) {
      height: calc(100vh - 450px);
    }
  }

  .loading {
    width: 100%;
    top: 50px;
    z-index: 3;
    height: 150px;
  }
`;
export const PhotoItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  position: relative;

  .item {
    cursor: pointer;
    min-width: 164px;
    width: 164px;
    min-height: 104px;
    height: 104px;
    border-radius: 8px;
  }

  .placeholder-img {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafcfd;
    border: 2px dashed #d9e3ea;
    border-radius: 8px;

    img {
      object-fit: contain;
      height: 24px;
      width: 24px;
    }
  }
  .uploaded-photo {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      object-fit: contain;
    }
  }
  .uploaded-photo:hover .image-actions {
    display: flex;
  }
  .image-actions {
    position: absolute;
    display: none;
    top: 0px;
    background: rgba(5, 49, 73, 0.8);
    height: 104px;

    img:hover {
      opacity: 0.8;
    }
    img {
      height: 16px;
      width: 16px;
    }
  }

  .upload-image {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .image-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 164px;
  }

  .image-name:hover {
    max-width: 100%;
  }
`;
export const DocumentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 30px;
  column-gap: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  padding: 0 30px 0 30px;
  max-height: calc(100vh - 195px);
  overflow: scroll;

  @media (max-width: 1550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 1280px) {
    max-height: calc(100vh - 495px);
  }

  .item {
    border-radius: 8px;
    cursor: pointer;
    height: 110px;

    .menu-btn {
      width: 16px;
    }
  }
`;
export const RequiredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  border-radius: 4px;
  user-select: none;

  label {
    color: #ffffff;
    padding: 4px;
  }
`;

export const DocumentItemWrapper = styled.div`
  height: 110px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;

  .item-top {
    background-color: white;
    width: 100%;
    border-radius: 8px 8px 0 0;
    border-top: 1px solid #d9e3ea;
    border-left: 1px solid #d9e3ea;
    border-right: 1px solid #d9e3ea;

    .relative > .absolute {
      top: -10px;
      right: 40px;
    }
  }

  .item-automation {
    width: 100%;
    border-radius: 0 0 8px 8px;
    border-bottom: 1px solid #d9e3ea;
    border-left: 1px solid #d9e3ea;
    border-right: 1px solid #d9e3ea;

    button {
      width: 100%;
      height: 32px;
      margin: 0 16px;
      border-radius: 8px;
      border: none;
      outline: none;
      background: rgba(64, 241, 222, 0.3);
      cursor: pointer;

      font-family: Roboto Medium;
      font-size: 14px;
      color: #053149;
    }
  }

  .item-bottom {
    background-color: #f4f7f9;
    width: 100%;
    border-radius: 0 0 8px 8px;
    user-select: none;

    .dd-text {
      color: #73868c;
    }

    span {
      color: #32e5c5;
      cursor: pointer;
    }

    .progress-line {
      height: 2px;
      background: #d2e5ed;
      border-radius: 2px;

      .progress {
        height: 2px;
        background: #5470ff;
        border-radius: 2px;
      }
    }

    .up-action {
      background: #ffffff;
      border: 1px solid rgba(210, 229, 237, 0.5);
      border-radius: 4px;
      width: 24px;
      height: 24px;
    }
  }

  .menu {
    padding: 8px;
    z-index: 1;
    .menu-item:hover {
      background: #d2e5ed4c;
    }
    .menu-item {
      flex-direction: row;
      cursor: pointer;
      border-radius: 4px;
      height: 40px;
      padding-right: 10px;
      white-space: nowrap;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
`;

export const NotesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    width: 100%;
    height: calc(100vh - 160px);
    border-top: 1px solid #E5E5E5;

    .menu-btn {
      width: 16px;
    }

    .menu {
      padding: 8px;
      z-index: 1;
      right: 40px;
      top: -20px;

      .menu-item:hover {
        background: #D2E5ED4C;
      }
      .menu-item {
        flex-direction: row;
        cursor: pointer;
        border-radius: 4px;
        height: 40px;
        padding-right: 10px;
        white-space: nowrap;
        img {
          width: 16px;
          height: 16px
        }
      }
    }

    .edit-note {
      textarea {
        font-family: Roboto Regular;
        font-size: 16px;
        line-height: 19px;
        color: #053149;
        outline: 0;
        resize: none;
        border: 1px solid #D2E5ED;
        border-radius: 8px;
        padding: 15px;
      }
    }

    .group-date {
      font-size: 12px;
      color: #73868C;
    }

    .notes-placeholder {
      .bold-text {
        font-size: 20px;
        color: #73868C;
        opacity: 0.5;
      }
    }

    .notes-container {
      position: relative;

      .top-shadow {
        position: absolute;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        transform: rotate(180deg);
        height: 74px;
        width: 100%;
        z-index: 5;
        display: flex;
        align-items: flex-end;
        margin-top: -16px;
        justify-content: center;
        padding-right: 17px;

        .date-group {
          transform: rotate(180deg);
          background: #FFFFFF;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
          width: 100px;
          padding: 5px;
          border-radius: 0 0 10px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .line {
      width: 1px;
      background: #D2E5ED;
    }

    .line-top {
      min-height: 10px;
    }

    .notes {
      height: calc(100vh - 280px);
      overflow-y: scroll;
    }

    .add-note {
      border: 1px solid #D2E5ED;
      border-radius: 8px;

      &:hover {
          border: 1px solid #31ddca;
        }

      textarea {
        font-family: Roboto Regular;
        font-size: 16px;
        line-height: 19px;
        color: #053149;
        border: none;
        outline: 0;
        resize: none;
        height: 28px;
      }

      textarea::placeholder {
        font-family: Roboto Regular;
        font-size: 16px;
        line-height: 19px;
        color: #053149;
        opacity: 0.3;
      }

      .an-action {
        width: 32px;
        height: 32px;
        background: #F4F7F9;
        border-radius: 4px;
      }
    }

    .an-btn {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background: rgba(115, 134, 140, 0.2);
    }

    .note-date {
      font-size: 12px;
      color: #73868C;
      overflow: hidden;
      white-space: nowrap;
      min-height: 14px;
    }

    .note-user {
      font-size: 16px;
    }

    .note-org {
      color: #73868C;
      opacity: 0.5;
    }

    .note-text {
      color: #73868C;
      white-space: pre-wrap;
      background: #F4F7F9;
      border-radius: 8px;
      padding: 16px 12px;
    }

  }

  .add-note-container {
    padding: 0 24px;
    margin: 24px 0;
  }
`;

export const EditContactWrapper = styled.div`
  margin-bottom: 24px;

  .error-info {
    border-color: red;

    .selector,
    .select__control,
    .select__control--is-focused {
      border-color: red !important;
    }
  }

  .input-name-width {
    width: 200px;
  }

  .input-width {
    width: 280px;
  }

  .request-dm-btn {
    height: 32px;
    width: 168px;
    background: #40f1de;
    border-radius: 8px;
    border: none;
    outline: none;

    font-family: Roboto Bold;
    font-size: 14px;
    color: #ffffff;
  }

  .benefit-infos {
    height: 48px;
    background: #f4f9fb;
    border-radius: 8px;

    .benefit-info {
      border-right: 1px solid #e3eef3;
    }

    .benefit-info:last-child {
      border: 0;
    }
  }

  .popup-input {
    margin-bottom: 0px;
    flex: 1;
    border-radius: 8px;
    padding: 4px 10px;

    &:focus {
      border: 1px solid #31ddca;
    }
  }

  .checkbox-class {
    margin: 0;

    min-width: 15px;
    max-width: 15px;
    min-height: 15px;
    max-height: 15px;

    img {
      width: 8px;
      height: 8px;
    }

    input {
      width: 8px;
      height: 8px;
    }
  }

  .selector {
    min-height: 30px !important;
    border-radius: 8px;
    padding: 0 10px;
  }
  .options {
    top: 34px;
  }

  .birth-date-selector,
  .birth-date-selector:focus-visible {
    margin: 0;
    width: 100%;
    padding: 6px;
    border: 1px solid #d2e5ed;
    border-radius: 4px;
    outline: unset;

    &::-webkit-input-placeholder {
      color: #053149;
      opacity: 0.5;
    }
  }

  .title-selector {
    .selector {
      padding: 0;
      width: 70px;
      margin: 0;
      padding: 0 8px;

      .items-center {
        padding: 0 8px 0 0;
      }
    }
  }

  .dob-error {
    font-size: 12px;
    position: absolute;
    top: 34px;
    left: 0;
    color: red;
  }

  .error-info {
    color: red;
    font-size: 12px;
  }

  .preference-container {
    display: flex;

    .w-50 {
      width: 50%;
    }
  }

  .options-select-container {
    min-width: 290px;

    .select__placeholder {
      color: #053149;
      opacity: 0.5;
      font-family: Roboto Regular;
      font-size: 16px;
    }

    .select__value-container {
      padding: 2px 0px 2px 6px;
    }

    .select__control {
      border-radius: 10px;
      border: 1px solid #d2e5ed;
      flex-wrap: nowrap;
      min-height: 30px;

      &:hover {
        background: #f4f7f9;
      }
    }

    .select__control--is-focused {
      border: 1px solid #31ddca;
      box-shadow: unset;
      flex-wrap: nowrap;
    }

    .select__single-value {
      color: #053149;
      font-size: 16px;
      font-family: Roboto Regular;
    }

    .select__clear-indicator,
    .select__indicator-separator {
      display: none;
    }

    .select__indicators {
      .select__indicator {
        padding: 4px 2px 4px 0px;

        svg {
          height: 18px;
          width: 18px;
        }
      }
    }

    .select__menu {
      top: unset;
      bottom: 100%;

      .select__menu-list {
        max-height: 210px;
      }
    }
  }

  .error-border {
    .select__control {
      border-color: red;
    }
  }
`;

export const EditPropertyContentWrapper = styled.div`
  max-height: calc(100vh - 165px);
  display: flex;
  flex-direction: column;

  .edit-dropdown-hover:hover {
    background: #f4f7f9;
    border-radius: 10px;
  }

  .content-padding {
    padding: 0 30px 0 30px;
  }

  hr {
    margin: 0;
  }

  .icon-container {
    padding: 4px;
    background: #f4f7f9;
    border-radius: 4px;
    max-width: 26px;
  }

  .edit-property-input {
    max-width: 60px;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #d2e5ed;

    &:focus {
      border: 1px solid #31ddca;
    }

    ::-webkit-inner-spin-button {
      opacity: 1;
    }

    :focus-visible {
      outline: none;
    }
  }

  .selector {
    min-height: 30px !important;
    border-radius: 4px;
    width: 225px;

    &:hover {
      background: #f4f7f9;
    }
  }

  .mini-gas-container {
    .mini-gas,
    .selector {
      width: 80px;
    }
  }

  .construction-dropdown {
    .selector {
      & > div {
        white-space: nowrap;
        overflow: hidden;
        margin-right: 6px;

        & > label {
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
  }

  .height-30 {
    height: 30px;
  }

  .epc-arrow-container {
    .mi-arrow {
      transition-duration: 300ms;
      transition-property: transform;
    }

    .mi-arrow-down {
      transform: rotate(180deg);
    }
  }
`;

export const UploadFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 100%;
  padding: 0 30px 0 30px;
  max-height: calc(100vh - 195px);

  @media (max-width: 1280px) {
    max-height: calc(100vh - 495px);
  }

  .loader {
    height: 150px;
    background: #ffffff;
    border-radius: 0px;

    img {
      height: 48px;
    }
  }

  .file-select-input {
    display: none;
  }

  .uploaded-files {
    max-height: calc(100vh - 350px);
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }

  .upload-done-btn {
    position: absolute;
    right: 50px;
    top: -65px;
    padding: 6px 28px;
    border-radius: 6px;
    height: unset;
  }

  .error-icon {
    path {
      fill: red;
    }
  }

  .upload-files-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 32px 0px;
    background: #f4f7f9;
    border: 2px dashed #d2e5ed;
    border-radius: 8px;
    cursor: pointer;

    label {
      font-family: Roboto Medium;
      font-size: 14px;
      color: #73868c;
      margin-top: 22px;
    }
  }

  .files-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
    column-gap: 25px;
    margin: 16px 0px 24px 0px;
  }

  .all-file-done {
    margin: 0;
    border-radius: 50%;
    pointer-events: none;
    min-width: 20px;
    min-height: 20px;

    img {
      width: 10px;
      height: 10px;
    }
  }

  .options-select-container {
    width: 100%;

    .select__placeholder,
    .select__menu-notice--no-options {
      color: #053149;
      opacity: 0.3;
    }

    .select__value-container {
      padding-left: 16px;
      padding-top: 6px;
      padding-bottom: 6px;
    }

    .select__control {
      border-radius: 10px;
      border: 1px solid #d2e5ed;

      &:hover {
        background: #f4f7f9;
      }
    }

    .select__control--is-focused {
      border: 1px solid #31ddca;
      box-shadow: unset;
    }

    .select__control--menu-is-open {
      .select__single-value {
        display: none;
      }
    }

    .select__menu-list {
      max-height: 200px;
    }

    .select__multi-value {
      background: #d2e5ed;
      border-radius: 4px;
    }

    .select__multi-value__label {
      color: #053149;
      font-size: 16px;
    }

    .select__clear-indicator,
    .select__indicator-separator,
    .select__indicators {
      display: none;
    }

    .select__menu {
      margin-top: 4px;
    }
  }
`;

export const UploadFileItemWrapper = styled.div`
  display: flex;
  width: 100%;
  background: #f4f7f9;
  border-radius: 8px;
  padding: 16px 16px 12px 16px;

  .display-block {
    display: block;
  }

  .color-gray {
    color: #73868c;
  }

  .upload-icon {
    height: 20px;
    width: 20px;
  }

  .progress-line {
    height: 2px;
    background: #d2e5ed;
    border-radius: 2px;

    .progress {
      height: 2px;
      background: #5470ff;
      border-radius: 2px;
    }
  }

  .view-file-btn {
    padding: 6px 12px;
    border-radius: 4px;
  }

  .file-type-dropdown {
    .selector {
      background: #ffffff;
    }
  }

  .file-type-btn {
    padding: 4px 16px;
  }

  .upload-failed-status {
    color: #ff5b5b;
  }
`;

export const UnmatchedFileItemWrapper = styled.div`
  display: flex;
  width: 100%;
  background: #f4f7f9;
  border-radius: 8px;
  padding: 16px 16px 12px 16px;

  .upload-icon {
    height: 20px;
    width: 20px;
  }

  .file-type-dropdown {
    .selector {
      background: #ffffff;
    }
  }

  .file-type-btn {
    padding: 4px 16px;
  }

  .view-file-btn {
    padding: 6px 12px;
    border-radius: 4px;
  }
`;
