import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
      user: JSON.parse(localStorage.getItem('user')),
      organisations: JSON.parse(localStorage.getItem('user'))?.organisations,
      organisation: JSON.parse(localStorage.getItem('user'))?.organisations[0]?.external_id
    },
    reducers: {
      setUser: (state, { payload }) => {
          state.user = payload;
          state.organisations = payload.organisations;
          state.organisation = payload.organisations[0]?.external_id
      },
    }
  });


export const { setUser } = userSlice.actions;
export default userSlice.reducer;