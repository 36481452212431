import React from "react";

import EditDeleteMenu from "../../Elements/EditDeleteMenu";

import './style.scss';

const Schema = ({schema}) => {

    return (
    <div className="schema-grid border-bottom">
        <label className="regular-text px-6 py-4 my-2 border-right">{schema.name}</label>
        <label className="regular-text light-purple-text px-6 py-4 my-2 border-right">{schema.description}</label>
        <div className="flex items-center justify-between px-6 py-4 my-2">
            <label className="regular-text">{schema.attributes?.length} attributes</label>
            <EditDeleteMenu />
        </div>
    </div>
    )
}

export default Schema;
