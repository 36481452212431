import React, { useContext } from 'react'
import { OrganisationContext } from '../../context/organisationContext'

import { ReactComponent as ArrowRight } from '../../assets/images/menu/arrow-right.svg'
import { ReactComponent as Curve } from '../../assets/images/menu/curve.svg'


import '../../assets/css/components/menu/menu-item.scss'

const MenuItem = ({ item, onClick  }) => {
  const { checkMultiplePermission, hasMultiplePermission } = useContext(OrganisationContext);
    return (
        <div className='menu-item'>
            <div className='menu-item-container' style={{
                opacity: hasMultiplePermission(item.permission) ? 1 : 0.5,
            }}>
                <div className={`mi-item ${item.selected && 'mi-item-selected'} `} onClick={() => checkMultiplePermission(() => onClick(item.id), item.permission)}>
                    <div className='mii-left'>
                        <img src={item.selected ? item.iconSelected : item.icon} className='mi-icon' />
                        <label className='semibold-text font-16 light-green-text ml-6 mi-name'>{item.text}</label>
                    </div>
                    {item.subitems.length > 0 && <ArrowRight className={`mi-arrow ${item.open && 'mi-arrow-down'}`} />}
                </div>
            </div>
            {item.subitems.length > 0 &&
                <div className={`menu-subitems ${!item.open && 'menu-subitems-hide'} mt-2`} style={{ height: item.open ? `${50 * item.subitems.length}px` : 0 }}>
                    {item.subitems.map((subitem, i) =>
                        <div key={i} className={`subitem-container ${subitem.selected && 'subitem-selected'} ${i === item.subitems.length - 1 && 'last-subitem'}`} onClick={() => checkMultiplePermission(() => onClick(item.id, subitem.id), subitem.permission)} style={{
                            opacity: hasMultiplePermission(subitem.permission) ? 1 : 0.5,
                          }}>
                            <div key={subitem.id} className='subitem relative'>
                                <div className="vertical-line" />
                                <Curve />
                                <div className="flex items-center subitem-name ml-1 mt-2 w-full">
                                    <label className='semibold-text font-16 light-green-text px-5'>{subitem.text}</label>
                                </div>
                            </div>
                        </div>

                    )}
                </div>
            }
        </div>
    )
}

export default MenuItem;
