import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  .mc-options {
    top: 45px;
    width: 100%;
    overflow-y: scroll;
    max-height: 200px;
    transition: height 200ms linear 0s;
    border-radius: 12px;
    z-index: 5;
    box-sizing: border-box;

    .mc-option-item:hover {
      background: ${({ theme }) => theme.lightGreyBackground};
      border-radius: 8px;
    }

    .mc-option-item {
      height: 40px;
      min-height: 40px;
      overflow-x: hidden;

      label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .mc-select {
    border-radius: 100px;

    .organisations-border {
      height: 48px;
      padding: 8px 16px;
      border: 1px solid ${({ theme }) => theme.lightGrey};
      border-radius: 100px;
    }

    .dropdown-focus {
      border-color: ${({ theme }) => theme.primaryBlue};
    }
  }

  .selected-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
