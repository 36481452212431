import { createSlice } from "@reduxjs/toolkit";
import api from '../../store/services'
import { addToast } from "./toastSlice";
import uuid from "react-uuid";

export const schemaSlice = createSlice({
    name: "schema",
    initialState: {
      schemas: {},
      schema: {},
      schemaAttributes: [],
      loading: false,
      schemaCollections: {},
    },
    reducers: {
      setSchemas: (state, { payload }) => {
          state.schemas = payload;
      },
      addSchemas: (state, { payload }) => {
        state.schemas = {...payload, content: [...state.schemas.content, ...payload.content]}
      },
      setSchema: (state, { payload }) => {
        state.schema = payload;
      },  
      setSchemaAttributes: (state, { payload}) => {
        state.schemaAttributes = payload;
      },
      setLoading: (state, { payload}) => {
        state.loading = payload;
      },
      setCreateSchemaStatus: (state, { payload}) => {
        state.createSchemaStatus = payload;
      },
      setSchemaCollections: (state, { payload}) => {
        state.schemaCollections = payload;
      },
    }
  });

export const getSchemas = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await api.get(`/v3/api/backoffice/schemas?page=${payload.page}&size=${payload.size || 8}&search=${payload.search}`)
  if(payload.merge)
    dispatch(addSchemas(data));
  else
    dispatch(setSchemas(data));
  dispatch(setLoading(false));
};

export const getSchema = (payload) => async (dispatch) => {
  const { data } = await api.get(`/v3/api/backoffice/schemas/${payload}`)
  dispatch(setSchema(data));
};

export const getSchemaCollections = (payload) => async (dispatch) => {
  const { data } = await api.get(`/v3/api/backoffice/collections?schema_id=${payload}`)
  dispatch(setSchemaCollections(data));
};

export const updateSchema = (payload) => async (dispatch) => {
  try {

  const { data } = await api.put(`/v3/api/backoffice/schemas/${payload.id}`, payload.request)
  dispatch(setSchema(data));
  dispatch(
    addToast({
      error: false,
      title: 'Schema updated',
      text: `${payload.request.name} has been updated`,
      id: uuid(),
    }),
  );
} catch (e) {
  dispatch(
    addToast({
      error: true,
      text: 'Error while updating schema',
      id: uuid(),
    }),
  );
}
};

export const createSchema = (payload) => async (dispatch) => {
  try {
    dispatch(setCreateSchemaStatus('LOADING'));
  const { data } = await api.post(`/v3/api/backoffice/schemas`, payload.request)
  dispatch(setSchema(data));
  dispatch(
    addToast({
      error: false,
      title: 'Schema created',
      text: `${payload.request.name} has been created`,
      id: uuid(),
    }),
  );
  dispatch(setCreateSchemaStatus('CREATED'));
} catch (e) {
  dispatch(
    addToast({
      error: true,
      text: 'Error while creating schema',
      id: uuid(),
    }),
  );
  dispatch(setCreateSchemaStatus('FAILED'));
}
};

export const getSchemaAttributes = (payload) => async (dispatch) => {
  dispatch(setLoading(true));
  const { data } = await api.get(`/v3/api/backoffice/schemas/${payload}/attributes`)
  dispatch(setSchemaAttributes(data));
  dispatch(setLoading(false));
};

export const updateSchemaAttributes = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data } = await api.put(`/v3/api/backoffice/schemas/${payload.id}/attributes`, payload.attributes)
    dispatch(setSchemaAttributes(data));
    dispatch(
      addToast({
        error: false,
        title: 'Attributes updated',
        text: `Schema attributes has been created`,
        id: uuid(),
      }),
    );
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(
      addToast({
        error: true,
        text: 'Error while updating attributes',
        id: uuid(),
      }),
    );
    dispatch(setLoading(false));
  }
};

export const { setSchemas, setSchema, setSchemaAttributes, setLoading, setCreateSchemaStatus, setSchemaCollections, addSchemas } = schemaSlice.actions;
export default schemaSlice.reducer;