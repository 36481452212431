import React, { useState, useEffect, useCallback, useContext } from 'react'

import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getAccounts, masquerade } from '../../store/features/accountSlice';
import { setUser } from "../../store/features/userSlice";

import { addToast } from "../../store/features/toastSlice";

import Header from "../../components/header";
import Filter from "../../components/filter";
import { OrganisationContext } from "../../context/organisationContext";

import Loading from '../../assets/images/loading.svg';
import { ReactComponent as Masquerade } from '../../assets/images/masquerade.svg';
import './style.scss'
import uuid from 'react-uuid';

const Accounts = ({title, type}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accounts } = useSelector(data => data.account);
    const { user, organisationId } = useSelector(data => data.user);
    const { setModal } = useContext(OrganisationContext);

    const [search, setSearch] = useState('')
    const [addedData, setAddedData] = useState(null);
    const [filter, setFilter] = useState({value: ''});

    const loadAccounts = useCallback(async (page, merge)  => {
        dispatch(getAccounts({organisationId: user.organisations[0].external_id, page, search: search, merge, filter: filter?.value,}));
    }, [dispatch, user.organisations, search, filter?.value]);

    useEffect(() => {
        loadAccounts(0, false);
    }, [loadAccounts, addedData]);

    const fetchMoreAccounts = () => {
        loadAccounts(accounts?.number + 1, true);
    };

    const getContactInfo = (organisation, type) => {
        if (!organisation.contact_details) return null;
        const info = organisation.contact_details.find(contactInfo => contactInfo.contact_type === type) || {};
        return info.contact_value;
    };

    const onAddClick = () => {
        setModal({
          type: 'add-account',
          content: {
            category_tag: 'ACCOUNT',
            onSuccess: setAddedData,
            organizationId: user.organisations[0].external_id
          },
        });
      };

      const onMasquerade = account => {
        dispatch(masquerade({ organisationId: account.id }))
        .then(data => {
            const primary = JSON.parse(localStorage.getItem('user'));
            const user = {...primary,
                access_token: data.api_tokens.access_token,
                refresh_token: data.api_tokens.refresh_token,
                exp:  data.api_tokens.exp,
                organisations: data.organizations.map(org => 
                  ({
                      external_id: org.id,
                      name:  org.name, 
                      org_type: org.organization_type,
                      roles: [
                          {
                              id: org.role.id,
                              name: org.role.name,
                              permissions: data.api_tokens.permissions
                          }
                      ]
                  }
                )),
                primary: primary,
                masqueraded: true
              }
              dispatch(addToast({ text: 'Account has been accessed', id: uuid() }));
              localStorage.setItem("user", JSON.stringify(user));
              dispatch(setUser(user));
              navigate('/dashboard', { replace: true })
        })
        .catch(error => {
            console.log(error)
          dispatch(
            addToast({ text: "", error: true, id: uuid() }),
          );
        })
        // .finally(() => setLoading(false));
      }

      return (
        <div className="flex-column mb-6 mt-2 flex-1">
            <Header title={title}
                    search={search}
                    setSearch={setSearch}
                    addPermission={"ORGANISATIONS_MANAGE"}
                    isAddBtn={true}
                    isPaged={false}
                    onAdd={onAddClick}
                    filterChildren={<Filter objects={["ORGANISATION_CATEGORY"]} setFilter={setFilter}  values={filter?.filters}/>}
                    filter={filter}
                    isFilter={true}
                    />

            <div className='card mx-6 mt-2'>
                <div className="account-grid account-header border-top border-bottom">
                    <label></label>
                    <label className="font-12 semibold-text py-4">NAME</label>
                    <label className="font-12 semibold-text px-6 py-4">CATEGORY</label>
                    <label className="font-12 semibold-text px-6 py-4">ADDRESS</label>
                    <label className="font-12 semibold-text px-6 py-4">PHONE</label>
                    <label className="font-12 semibold-text px-6 py-4">EMAIL</label>
                    <label className="font-12 semibold-text px-6 py-4 one-line">PRIMARY CONTACT</label>
                </div>
                <div className="flex-column account-list-camp flex-1">
                {             
                    accounts.content?.length > 0 ?
                        <div className="account-g--list" id="scrollableDiv">
                            <InfiniteScroll
                                dataLength={accounts?.content?.length}
                                hasMore={!accounts?.last}
                                loader={
                                <div className="item flex items-center justify-center my-2">
                                    <img alt="loading" height="24px" src={Loading} />
                                </div>
                                }
                                next={fetchMoreAccounts}
                                scrollableTarget="scrollableDiv"
                                style={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                overflow: "unset",
                                }}
                            >
                        {accounts.content?.map(account => 
                            <div className={"account-grid camp-card cursor border-bottom cursor account-item"}  key={account.id}>
                                <div className='flex ites-cemnter justify-center pxy-6 masquerade-btn' onClick={() => onMasquerade(account)}>
                                    <Masquerade className='masquerade' />
                                </div>
                                <label className="medium-text py-6 one-line">{account.name}</label>
                                <label className="medium-text pxy-6 one-line">{account.category?.name || ''}</label>
                                <label className="medium-text pxy-6 one-line">{account.primary_location?.formatted_address || ''}</label>
                                <label className="medium-text pxy-6 one-line">{getContactInfo(account, 'MOBILE') || ''}</label>
                                <label className="medium-text pxy-6 one-line">{getContactInfo(account, 'EMAIL') || ''}</label>
                                <label className="medium-text pxy-6 one-line">{account?.owners?.[0]?.name || ''}</label>
                            </div>
                            )}
                            </InfiniteScroll>   
                        </div>
                    :
                    <div className="flex items-center justify-center flex-1">
                        <label className="bold-text font-16 light-purple-text">There are no accounts</label>
                    </div>
                }
                </div>
            </div>
        </div>
    )
}

export default Accounts;
