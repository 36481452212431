import React from 'react';
import { MenuWrapper } from './automations.styled';

const ActionMenu = props => {
  const { clickItem } = props;
  return (
    <MenuWrapper className="menu action-menu">
      <label className="regular-text" onClick={() => clickItem('DELETE')}>
        Delete
      </label>
    </MenuWrapper>
  );
};

export default ActionMenu;
