import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getNft, setNft, setRetirement } from "../../store/features/nftSlice";

import NavTop from "../../components/common/NavTop";
import EditDeleteMenu from "../../components/Elements/EditDeleteMenu";
import CampaignNftDetails from "../../components/campaignNft/CampaignNftDetails";
import EditCampaignNftDetails from "../../components/campaignNft/EditCampaignNftDetails";
import CampaignNftRetirement from "../../components/campaignNft/CampaignNftRetirement";
import { OrganisationContext } from '../../context/organisationContext';

import './style.scss';

const CampaignNft = ({campaign, campaignProject, nftId, close}) => {
    const [edit, setEdit] = useState(false)
    const dispatch = useDispatch();
    const { setModal } = useContext(OrganisationContext);

    const { nft, loading } = useSelector(data => data.nft)

    useEffect(() => {
        dispatch(getNft(nftId));
    }, [nftId, dispatch]);

    const onMint = () => {
        setModal({ type: 'mint-retirement-nft', content: { nft: nft, top: false } });
    }

    return (
        <div className="flex-column campaign-nft flex-1">
            <div className="mb-6 mt-8 ml-6">
                <NavTop names={[{text: "Campaigns", path: "/commerce/campaigns"}, {text: campaign?.name, onClick: () => close()}, {text: nft?.name, onClick: () => close()}, {text: 'NFT details'}]} edit={false} />
            </div>
            <div className="flex mx-6">
                <div className="campaign-nft-l card fit-content">
                    <div className="flex items-center justify-between px-6 border-bottom cn-header">
                        <label className="bold-text font-20">NFT details</label>
                        <div className="flex items-center">
                            {nft.lifecycle !== 'MINTED' && <div className="flex items-center justify-center cursor mr-2 no-select mint-btn" onClick={onMint}>
                                <label className="semibold-text font-12">Mint</label>
                            </div>}
                            <EditDeleteMenu onEdit={() => {}} onDelete={() => {} } />
                        </div>
                    </div>
                    {edit ?
                    <EditCampaignNftDetails nft={nft} cancel={() => setEdit(false)} />
                    :
                    <CampaignNftDetails nft={nft} loading={loading} />
                    }
                </div>
                <CampaignNftRetirement campaign={campaign} campaignProject={campaignProject} nft={nft} />
            </div>
        </div>
    )
}

export default CampaignNft;
