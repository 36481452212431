import React, { useContext, useState } from 'react'

import { OrganisationContext } from '../context/organisationContext'

import Venue from '../components/business/venue'
// import Account from '../components/business/account'
import MerchantMenu from '../components/business/merchant-menu'

import PhoneIcon from '../assets/images/phone.svg'
import EmailIcon from '../assets/images/email.svg'
import LocationIcon from '../assets/images/location.svg'
import ProfileIcon from '../assets/images/menu/profile.svg'

import DotsIcon from '../assets/images/dots.svg'

import '../assets/css/pages/business.scss'

const Business = () => {
    const { selectedOrganisation, venues, merchants, selectedMerchant, selectedVenue } = useContext(OrganisationContext)

    const [showMerchantMenu, setShowMerchantMenu] = useState(false)

    const getContactInfo = (organisation, type) => {
        if (!organisation.organisation_contact_infos)
            return ''
        const info = organisation.organisation_contact_infos.find(contactInfo => contactInfo.contact_type === type) || {}
        return info.contact_value
    }

    const phone = getContactInfo(selectedOrganisation, 'LANDLINE')
    const email = getContactInfo(selectedOrganisation, 'EMAIL')

    return (
        <div className='flex-column mt-10 mr-6 ml-3 flex-1 no-select'>
            <label className='bold-text font-24'>My Business</label>
            <div className="flex-column card pxy-6 mt-6 mb-2">
                <div className="flex items-center justify-between">
                    <label className="bold-text font-12 grey-text">{selectedOrganisation.org_type}</label>
                    <div className='flex items-center justify-center menu-btn relative'
                        onClick={() => setShowMerchantMenu(!showMerchantMenu)}
                        tabIndex={0}
                        onBlur={() => setShowMerchantMenu(false)}
                    >
                        <img src={DotsIcon} alt="menu btn" className="cursor" />
                        {selectedOrganisation.org_type === 'MERCHANT' && showMerchantMenu && <MerchantMenu />}
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    <div className="flex-column flex-1">
                        <label className="bold-text font-20 mb-2">{selectedOrganisation.name}</label>
                        <label className="regular-text o05">Vat number: <span className="regular-text o1">{selectedOrganisation.vat_number || 'Not provided'}</span></label>
                    </div>
                    <div className="flex-column flex-1">
                        <div className="flex items-center mb-2">
                            <div className="flex items-center justify-center contact-image">
                                <img src={PhoneIcon} alt="phone" />
                            </div>
                            {phone ? <label className="regular-text font-16 ml-2">{phone}</label> : <label className="regular-text font-16 o03 ml-2">Not provided</label>}
                        </div>
                        <div className="flex items-center">
                            <div className="flex items-center justify-center contact-image">
                                <img src={EmailIcon} alt="phone" />
                            </div>
                            {email ? <label className="regular-text font-16 ml-2">{email}</label> : <label className="regular-text font-16 o03 ml-2">Not provided</label>}
                        </div>
                    </div>
                    <div className="flex-column flex-2">
                        <div className="flex mr-10">
                            <div className="flex items-center justify-center contact-image">
                                <img src={LocationIcon} alt="phone" />
                            </div>
                            {selectedOrganisation?.location?.full_address ? <label className="regular-text font-16 ml-2">{selectedOrganisation?.location?.full_address}</label> : <label className="regular-text font-16 o03 ml-2">Not provided</label>}
                        </div>
                    </div>
                </div>
            </div>
            {merchants.length > 0 && !selectedMerchant &&
                <div className='flex-column'>
                    <div className="flex items-center justify-between mt-10">
                        <label className="bold-text font-24">{selectedOrganisation.name} merchants</label>
                    </div>
                    <div className="mt-6 organisation-grid">
                        {/* {merchants.map(merchant =>
                            <Account key={merchant.external_id} account={merchant} />
                        )} */}
                    </div>
                </div>
            }
            {venues.length > 0 && !selectedVenue &&
                <div className='flex-column'>
                    <div className="flex items-center justify-between mt-10">
                        <label className="bold-text font-24">{selectedOrganisation.name} locations</label>
                    </div>
                    <div className="mt-6 venue-grid">
                        {/* {venues.map(venue =>
                            <Account key={venue.external_id} account={venue} showImage={true} type="LOCATION" />
                        )} */}
                    </div>
                </div>
            }
        </div>
    )
}

export default Business;