import { createSlice } from "@reduxjs/toolkit";
import api from '../services'
import { addToast } from "./toastSlice";
import uuid from "react-uuid";

export const contactSlice = createSlice({
    name: "contact",
    initialState: {
      contacts: {},
      loading: false,
    },
    reducers: {
      setContacts: (state, { payload }) => {
        state.contacts = payload;
      },
      setMergeContacts: (state, { payload }) => {
        state.contacts = {...payload, content: [...state.contacts.content, ...payload.content]};
      },
      setLoading: (state, { payload }) => {
        state.loading = payload;
      },
    }
  });

  export const getContacts = (payload) => async (dispatch) => {
    dispatch(setLoading(true))
    let url = `/v3/api/backoffice/carbon/contacts?page=${payload.page}&size=${payload.size || 8}&search=${payload.search}`;
    url += `&${payload.filter}`
    const { data } = await api.get(url);
    if (payload.merge) {
      dispatch(setMergeContacts(data));
    } else {
      dispatch(setContacts(data));
    }
    dispatch(setLoading(false))
  };


export const { setContacts, setLoading, setMergeContacts } = contactSlice.actions;
export default contactSlice.reducer;