import FilterSelectedIcon from '../../../assets/images/lead-filter-selected.svg';
import FilterIcon from '../../../assets/images/lead-filter.svg';
import React, { useContext, useRef, useState } from 'react';
import AutomationFilterMenu from './automation-filter-menu';
import { AutomationFilterWrapper } from './automation-filter.styled';

const AutomationFilter = ({ automationMenuFilters, setAutomationMenuFilters, className = '', iconSize = '24px' }) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef();
  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpen(false);
    }
  };

  const clearFilter = () => {
    setAutomationMenuFilters({});
  };

  return (
    <AutomationFilterWrapper className={className}>
      <div ref={menuRef} className="pagination-content" onBlur={onBlur} tabIndex={0}>
        <div className="relative cursor">
          <div
            className={`nav-btn ${open && 'selected'}`}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(!open);
            }}
            style={{ opacity: 1 }}>
            <img alt="icon" height={iconSize} src={open ? FilterSelectedIcon : FilterIcon} width={iconSize} />
          </div>
          {open && (
            <div className="menu menu-container">
              <div className="mxy-6">
                <div className="lead-filter-menu-container">
                  <AutomationFilterMenu
                    clearFilter={clearFilter}
                    filter={automationMenuFilters}
                    setAutomationMenuFilters={setAutomationMenuFilters}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </AutomationFilterWrapper>
  );
};

export default AutomationFilter;
