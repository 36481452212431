import React, { useEffect, useState, useCallback, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { getRetirementTransactions } from "../../../store/features/retirementSlice";
import moment from "moment";
import Loading from "../../../assets/images/loading.svg";

const RetirementTransactions = ({ retirement }) => {
  const ref = useRef();
  const { retirementTransactions, loadingTransactions } = useSelector(
    (data) => data.retirement
  );

  const dispatch = useDispatch();

  const loadTransactions = useCallback(
    async (page, merge) => {
      dispatch(
        getRetirementTransactions({
          id: retirement.id,
          page: page,
          search: "",
          size: 30,
          merge: merge,
        })
      );
    },
    [retirement, dispatch]
  );

  useEffect(() => {
    if (retirement && retirement.id) loadTransactions(0, false);
  }, [retirement, loadTransactions]);

  const fetchMoreTransactions = () => {
    loadTransactions(retirementTransactions?.number + 1, true);
  };

  return (
    <div className="flex-column campaign-list-tx flex-1">
      <div className="tx-campaign-grid tx-campaign-header border-bottom ">
        <label className="font-12 semibold-text px-6 py-3 ml-12 o05">
          Date & Time
        </label>
        <label className="font-12 semibold-text px-6 py-3 o05">
          Amount of CO2
        </label>
        <label className="font-12 semibold-text px-6 py-3 o05">Wallet</label>
        <label className="font-12 semibold-text px-6 py-3 o05">Contact</label>
        <label className="font-12 semibold-text px-6 py-3 o05">Reference</label>
      </div>
      {retirementTransactions.content?.length > 0 ? (
        <div className="tx-campaign-list" id="scrollableDiv">
          <InfiniteScroll
            dataLength={retirementTransactions?.content?.length}
            hasMore={!retirementTransactions?.last}
            loader={
              <div className="item flex items-center justify-center my-2">
                <img alt="loading" height="24px" src={Loading} />
              </div>
            }
            next={fetchMoreTransactions}
            scrollableTarget="scrollableDiv"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "unset",
            }}
          >
            {retirementTransactions.content?.map((t) => (
              <div
                className={
                  "tx-campaign-grid tx-card cursor border-bottom cursor"
                }
                key={t.id}
              >
                <div className="flex items-center ml-6">
                  <label className="medium-text px-6 py-3">
                    {moment.unix(t.transaction_date).format("DD/MM/YYYY HH:mm")}
                  </label>
                </div>
                <label className="medium-text px-6 py-3">
                  {t.co2_kg_units} kilos
                </label>
                <label className="medium-text px-6 py-3">
                  {t.wallet?.alias || t.wallet?.wallet_address}
                </label>
                <label className="medium-text px-6 py-3">
                  {t.contact
                    ? t.contact?.contact_type === "BUSINESS"
                      ? t.contact?.business_name
                      : t.contact?.forename + " " + t.contact?.surname
                    : "-"}
                </label>
                <label className="medium-text px-6 py-3">{t.reference}</label>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      ) : loadingTransactions ? (
        <div className="flex flex-1 items-center justify-center my-6">
          <img alt="loading" height="24px" src={Loading} />
        </div>
      ) : (
        <div className="flex items-center justify-center flex-1">
          <label className="bold-text font-16 light-purple-text">
            There are no Transactions
          </label>
        </div>
      )}
    </div>
  );
};

export default RetirementTransactions;
