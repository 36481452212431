import React, { useState, useEffect } from 'react';
import './style.scss';

const Checkbox = ({ checked, ...props }) => {
  const [isChecked, setIsChecked] = useState(checked ? checked : false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked])
  
  return (
    <div className="checkbox-wrapper">
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          className={isChecked ? "checked" : ""}
          onChange={() => setIsChecked((prev) => !prev)}
          {...props}
        />
      </label>
    </div>
  );
};

export default Checkbox;
