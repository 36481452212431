import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setRetirementAmounts } from "../../../store/features/campaignSlice";

import AddRetirementPopup from "../../campaignNft/addRetirementPopup";

import Image from "../../image";    


import './style.scss'

const CampaignProjectRetire = ({campaign, campaignProject, setViewNft }) => {
    const { retirementAmounts } = useSelector(data => data.campaign) 
    const dispatch = useDispatch();

    const project = campaignProject?.project;
    const template = campaignProject?.template;
    const [showAdd, setShowAdd] = useState(false)


    return (
        <div className="flex-column px-6 border-bottom relative">
            <div className="campaign-project-retire-grid">
                <div className="flex items-center border-right pr-5 my-1">
                    <Image className={"campaign-project-image my-3"} size={64} media={project?.image?.media} />
                    <div className="flex-column ml-2 one-line ">
                        <label className="regular-text font-10 light-purple-text">Project info:</label>
                        <label className="bold-text one-line font-12 mt-1">{project.name}</label>
                        <label className="regular-text font-12 light-purple-text mt-05 one-line">{project.description}</label>
                    </div>
                </div>
                <div className="flex items-center ml-5">
                    <Image className={"campaign-project-image my-3"} size={64} media={template?.image?.media} />
                    <div className="flex-column ml-2 one-line">
                        <label className="regular-text font-10 light-purple-text">Template info:</label>
                        <label className="bold-text font-12 mt-1 one-line">{template?.name || 'No template'}</label>
                        <label className="regular-text font-12 light-purple-text one-line mt-05">{template?.description || 'No template'}</label>
                    </div>
                </div>
                <div className="flex items-center ml-5">
                    <div className="flex-column campaign-project-retire-item border-left pl-5">
                        <label className="regular-text font-10 light-purple-text mt-4">Offset amount:</label>
                        <label className="bold-text font-12 mt-2">{campaignProject?.total_co2_kilo_amount / 1000 || 0}t</label>
                    </div>
                </div>
                <div className="flex items-center justify-between  border-left my-1 pl-5 ml-5">
                    <div className="flex-column campaign-project-retire-item flex-1">
                        <label className="regular-text font-10 mt-4 light-purple-text">Retirement amount:</label>
                      
                        <div className="retirement-input mt-2">
                            <input type='number' min="0" step="1" pattern="[0-9]*" value={retirementAmounts[campaignProject.id]} onChange={e => e.target.validity.valid ? dispatch(setRetirementAmounts({...retirementAmounts, [campaignProject.id]: e.target.value})) : {}} />
                        </div>
                        
                    </div>
                    <div className="flex-column items-center flex-1">
                        <div className="flex items-center justify-center cursor create-view-nft-btn" style={{background: 'rgba(36, 203, 177, 0.1)'}} onClick={() => setShowAdd(true)}>
                            <label className="semibold-text font-12" style={{color: '#24CBB1'}}>Retire</label>
                        </div>
                    </div>
                </div>
            </div>
            {showAdd && <AddRetirementPopup campaignProject={campaignProject} amount={retirementAmounts[campaignProject.id] * 1000} campaign={campaign} onCancel={() => setShowAdd(false)} />}

        </div>
    )
}

export default CampaignProjectRetire;