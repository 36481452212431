import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProduct, getMediaGroups, setMediagroups, createMediaGroup, getMedia, deleteMedia, createMedia } from "../../store/features/productSlice";

import { useNavigate, useParams } from "react-router-dom";
import MediaList from "../../components/projectDetails/MediaList";
import Dropdown from "../../assets/css/components/widget/dropdown/dropdown";
import AddMediagroupPopup from "../../components/projectDetails/AddMediagroupPopup";
import DeleteMediaPopup from "../../components/projectDetails/DeleteMediaPopup";
import { OrganisationContext } from "../../context/organisationContext";

import DotsIcon from '../../assets/images/dots.svg'
import AddIcon from '../../assets/images/add.svg'

import '../projectDetails/details.scss';
import ProductInfo from "../../components/productDetails/ProductInfo";
import ProductTop from "../../components/productDetails/ProductTop";

const ProductDetails = () => {
    const dispatch = useDispatch();
    const { product, mediaGroups, media } = useSelector(data => data.product);
    const { checkPermission, hasPermission } = useContext(OrganisationContext);

    const { id } = useParams();
    const navigate = useNavigate();

    const [selected, setSelected] = useState('media');
    const [menuOpen, setMenuOpen] = useState(false);
    const [showAddMediaGroup, setShowAddMediaGroup] = useState(false);
    const [deletedMedia, setDeletedMedia] = useState();

    useEffect(() => {
        dispatch(getProduct(id));
    }, [id]);


    useEffect(() => {
        if(selected === 'media')
            dispatch(getMediaGroups(id));
    }, [id, selected]);

    const changeSelected = s => {
        setSelected(s);
        setMenuOpen(false);
    }

    const goBack = () => {
        navigate(-1)
    }

    const onAdd = () => {
        if(selected === 'media')
            setShowAddMediaGroup(true)
    }

    const addMediaGroup = name => {
        dispatch(createMediaGroup({productId: id, name: name}));
        setShowAddMediaGroup(false);
    };

    useEffect(() => {
        const selectedMediaGroup = mediaGroups.find(mg => mg.selected);
        if (selectedMediaGroup) {
            dispatch(getMedia(selectedMediaGroup));
        }
    }, [product, mediaGroups])


    const uploadMedia = async file => {
        if(file) {
            const selectedMediaGroup = mediaGroups.find(mg => mg.selected);
            dispatch(createMedia({file: file, selectedMediaGroup: selectedMediaGroup}))
        }
    }

    const getSelectedLabel = () => {
        if (selected === 'media') {
            return 'Media list';
        }
    }

    const removeMedia = async() => {
        dispatch(deleteMedia({mediaGroupId: deletedMedia.media_group_external_id, mediaId: deletedMedia.media_external_id}))
        setDeletedMedia(null);
    }

    return (
        <div className="flex-column flex-1">
            <ProductTop name={product?.name} goBack={goBack}/>
            <div className="flex ml-6">
                <ProductInfo product={product} />
                <div className="card flex-column card project-details mx-6 pxy-6">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <label className="bold-text font-24">{getSelectedLabel(selected)}</label>
                        </div>
                        <div className="flex items-center relative" onBlur={() => setMenuOpen(false)} tabIndex={1}>
                            {selected === 'media' && <Dropdown options={mediaGroups} setOptions={options => dispatch(setMediagroups(options))} /> }
                            <div className="flex items-center justify-center add-new-btn cursor mr-8" onClick={() => checkPermission(() => onAdd(), 'PRODUCTS_MANAGE')} style={{opacity: hasPermission('PRODUCTS_MANAGE') ? 1 : 0.5}}>
                                <img src={AddIcon} alt="add" />
                                <label className="medium-text ml-3 mid-green-text">Add new</label>
                            </div>
                            <img className="cursor" src={DotsIcon} onClick={() => setMenuOpen(!menuOpen)} />
                            {menuOpen &&
                            <div className="card flex-column pxy-4-2  project-details-menu">
                                <div className="flex pxy-3-4 mt-1 project-details-menu-item cursor" onClick={() => changeSelected('media')}>
                                    <label className="regular-text">Media</label>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div>
                        {selected === 'media' && <MediaList media={media} setFile={uploadMedia} deleteMedia={setDeletedMedia} addPermission="PRODUCTS_MANAGE" hasPermission={hasPermission} checkPermission={checkPermission} /> }
                    </div>
                </div>
            </div>
            {showAddMediaGroup && <AddMediagroupPopup close={() => setShowAddMediaGroup(false)} add={addMediaGroup} /> }
            {deletedMedia && <DeleteMediaPopup close={() => setDeletedMedia(null)} deleteMedia={removeMedia} /> }
        </div>
    )
}

export default ProductDetails;
