import SearchIcon from '../../assets/images/data-search-icon.svg';
import Loading from '../../assets/images/loading.gif';
import ArrowRightIcon from '../../assets/images/menu/arrow-right-black.svg';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Pagination from '../../components/pagination/pagination';
import useDebounce from '../../helpers/useDebounceHook';
import AutomationLogItem from './automation-log-item';
import AutomationLogsWrapper from './automation-logs.styled';
import api from '../../store/services'
import { OrganisationContext } from '../../context/organisationContext';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';

const AutomationLogs = () => {
  const header = ['ID', 'AUTOMATION NAME', 'DATE', 'STATUS'];
  const [searchText, setSearchText] = useState('');
  const [selectedPage, setSelectedPage] = useState(Number(localStorage.getItem('automationLog-page')) || 0);
  const [automationLogs, setAutomationLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [focusedInput, setFocusedInput] = useState(false);
  const [selectedAutomationLog, setSelectedAutomationLog] = useState({});
  const [initPage, setInitPage] = useState(selectedPage);

  const [totalPages, setTotalPages] = useState(0);

  const debouncedSearchText = useDebounce(searchText, 500);
  const currentSearchText = useRef(debouncedSearchText);
  const { modal } = useContext(OrganisationContext);

  let ls = Number(localStorage.getItem('automationLog-page'));

  const fetchAutomationLogs = async (page = null) => {
    setLoading(true);
    const { data } = await api.get(
      `/v3/api/automation_logs?page=${page === 0 ? 0 : selectedPage}&size=12${
        searchText ? `&automation_log_id=${searchText}` : ''
      }`,
    );
    setAutomationLogs(data.content);
    setTotalPages(data.total_pages);
    setLoading(false);
  };

  useEffect(() => {
    if (currentSearchText.current !== debouncedSearchText) {
      const currentSelectedPage = selectedPage;
      if (currentSelectedPage === 0 && debouncedSearchText !== '') {
        fetchAutomationLogs(0);
      }
      if (initPage === 0 && debouncedSearchText === '') {
        fetchAutomationLogs(0);
      }
      if (debouncedSearchText === '') {
        setSelectedPage(initPage);
      } else {
        setSelectedPage(0);
      }
      currentSearchText.current = debouncedSearchText;
    } else {
      if (currentSearchText.current === '' && debouncedSearchText === '') {
        setInitPage(selectedPage);
      }
      fetchAutomationLogs();
    }
  }, [selectedPage, debouncedSearchText]);

  useEffect(() => {
    if (modal.type === 'automation-log-success') {
      if (modal.content.isCancel) {
        setAutomationLogs([
          ...automationLogs.map(item =>
            item.id === modal.content.automationLog.id ? { ...item, status: 'CANCELLED' } : item,
          ),
        ]);
      } else {
        fetchAutomationLogs();
      }
    }
  }, [modal.type]);

  const handleBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFocusedInput(false);
    }
  };

  return (
    <AutomationLogsWrapper className="flex flex-1 flex-column mx-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center" style={{ height: '72px' }}>
          <label className="regular-text grey-text hover-underline">Platform</label>
          <img className="mx-2" src={ArrowRightIcon} />
          <label
            className={selectedAutomationLog.id ? 'regular-text grey-text hover-underline cursor' : 'bold-text'}
            onClick={() => setSelectedAutomationLog({})}>
            Automation Log
          </label>
          {selectedAutomationLog.id && (
            <>
              <img className="mx-2" src={ArrowRightIcon} />
              <label className="bold-text">{selectedAutomationLog.id}</label>
            </>
          )}
        </div>
        <div className={`flex items-center`}>
          <Pagination
            selectedPage={selectedPage}
            setSelectedPage={page => {
              localStorage.setItem('automationLog-page', page);
              setSelectedPage(page);
            }}
            totalPages={totalPages}
          />
          <div
            className={`flex items-center ml-6 search-border search ${focusedInput ? 'search-focused' : ''}`}
            onBlur={e => handleBlur(e)}
            onFocus={() => setFocusedInput(true)}>
            <img src={SearchIcon} />
            <input
              className="ml-2"
              onChange={e => setSearchText(e.target.value)}
              placeholder="Search"
              value={searchText || ''}
            />
            <div
              className={`close ${focusedInput ? 'close-focused' : ''}`}
              onClick={() => {
                setSearchText('');
              }}
              tabIndex="0">
              {focusedInput && <CloseIcon className="close-icon" />}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center flex-1 mb-20 content-loader">
          <img alt="loading" height="64px" src={Loading} width="64px" />
        </div>
      ) : (
        <>
          <div className="mb-2">
            <label className="bold-text font-24">Automation log</label>
          </div>
          <div className="data-match-row mt-3 mx-1">
            {header.map((text, i) => (
              <div key={i} className="flex data-match-item items-center justify-center">
                <label className="medium-text grey-text font-12">{text}</label>
              </div>
            ))}
          </div>
          <div className="flex flex-column flex-1 data-matches scroller">
            {automationLogs?.map((automationLog, i) => (
              <AutomationLogItem key={automationLog.id} automationLog={automationLog} />
            ))}
          </div>
        </>
      )}
    </AutomationLogsWrapper>
  );
};

export default AutomationLogs;
