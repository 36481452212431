import { createSlice } from "@reduxjs/toolkit";
import api from '../../store/services'
import { addToast } from "./toastSlice";
import uuid from "react-uuid";
import moment from "moment";

export const inventorySlice = createSlice({
    name: "inventory",
    initialState: {
      inventories: {},
      inventory: {},
      consignments: {},
      retirements: {},
      loading: false,
      consignmendtLoading: false,
      retirementsLoading: false,
      registryAccounts: [],
      subRegistryAccounts: []
    },
    reducers: {
      setInventories: (state, { payload }) => {
        state.inventories = payload;
      },
      setInventory: (state, { payload }) => {
        state.inventory = payload;
      },
      removeCurrentBalance: (state, { payload }) => {
        state.inventory = {...state.inventory, carbon: {...state.inventory.carbon, current_balance: state.inventory.carbon.current_balance - payload}};
      },
      setConsigments: (state, { payload }) => {
        state.consignments = payload;
      },
      setRetirements: (state, { payload }) => {
        state.retirements = payload;
      },
      setLoading: (state, { payload }) => {
        state.loading = payload;
      },
      setConsignmentsLoading: (state, { payload }) => {
        state.consignmendtLoading = payload;
      },
      setRetirementsLoading: (state, { payload }) => {
        state.retirementsLoading = payload;
      },
      changeInventory: (state, { payload }) => {
        if(state.inventories.content)
          state.inventories.content = state.inventories.content.map(i => i.id === payload.id ? payload : i);
      },
      addInventory: (state, { payload}) => {
        state.inventories = {...state.inventories, content: [...state.inventories.content, payload]};
      },
      addConsignment: (state, { payload}) => {
        state.consignments = {...state.consignments, content: [...state.consignments.content, payload]};
      },
      setRegistryAccounts: (state, { payload }) => {
        state.registryAccounts = payload;
      },
      setSubRegistryAccounts: (state, { payload }) => {
        state.subRegistryAccounts = payload;
      },
    }
  });

  export const getInventories = (payload) => async (dispatch) => {
    dispatch(setLoading(true))
    let url = `/v3/api/backoffice/carbon_inventory?page=${payload.page}&size=${payload.size || 8}&search=${payload.search || ''}`;
    url += `&${payload.filter}`
    if(payload.projectId)
      url += `&project_id=${payload.projectId}`
    const { data } = await api.get(url);
    dispatch(setInventories(data));
    dispatch(setLoading(false))
  };

  export const getInventory = (payload) => async (dispatch) => {
    dispatch(setLoading(true))
    let url = `/v3/api/backoffice/carbon_inventory/${payload}`;
    const { data } = await api.get(url);
    dispatch(setInventory(data));
    dispatch(setLoading(false))
  };

  export const getConsignements = (payload) => async (dispatch) => {
    dispatch(setConsignmentsLoading(true))
    let url = `/v3/api/backoffice/carbon_inventory/${payload.id}/consignments?page=${payload.page}&size=20`;
    const { data } = await api.get(url);
    dispatch(setConsigments(data));
    dispatch(setConsignmentsLoading(false))
  };

  export const getRetirements = (payload) => async (dispatch) => {
    dispatch(setRetirementsLoading(true))
    let url = `/v3/api/backoffice/carbon_inventory/${payload.id}/retirements?page=${payload.page}&size=${payload.size || 10}`;
    const { data } = await api.get(url);
    dispatch(setRetirements(data));
    dispatch(setRetirementsLoading(false))
  };

export const getRegistryAccounts = (payload) => async (dispatch) => {
  let url = `/v3/api/backoffice/registry_accounts?is_primary=true`;
  const { data } = await api.get(url);
  dispatch(setRegistryAccounts(data));
};

export const getSubRegistryAccounts = (payload) => async (dispatch) => {
  let url = `/v3/api/backoffice/registry_accounts?is_primary=false`;
  const { data } = await api.get(url);
  dispatch(setSubRegistryAccounts(data));
};

export const updateInventory = payload => async dispatch => {
  try {
    const { data } = await api.put(`/v3/api/backoffice/carbon_inventory/${payload.id}`, payload)
    dispatch(changeInventory(data))
    dispatch(setInventory(data))
    dispatch(
      addToast({
        error: false,
        title: 'Inventory updated',
        text: `Inventory has been updated`,
        id: uuid(),
      }),
    );
  } catch (e) {
    console.log(e)
    dispatch(
      addToast({
        error: true,
        text: 'Error while updating inventory',
        id: uuid(),
      }),
    );
  }
};

export const updateOneInventory = payload => async dispatch => {
  try {
    const { data } = await api.put(`/v3/api/backoffice/carbon_inventory/${payload.id}`, payload)
    dispatch(setInventory(data))
    dispatch(
      addToast({
        error: false,
        title: 'Inventory updated',
        text: `Inventory has been updated`,
        id: uuid(),
      }),
    );
  } catch (e) {
    console.log(e)
    dispatch(
      addToast({
        error: true,
        text: 'Error while updating inventory',
        id: uuid(),
      }),
    );
  }
};

export const createInventory = payload => async dispatch => {
  try {
  const { data } = await api.post(`/v3/api/backoffice/carbon_inventory`, payload)
  dispatch(addInventory(data))
  dispatch(
    addToast({
      error: false,
      title: 'Inventory created',
      text: `Inventory has been created`,
      id: uuid(),
    }),
  );
} catch (e) {
  console.log(e)
  dispatch(
    addToast({
      error: true,
      text: 'Error while created inventory',
      id: uuid(),
    }),
  );
}
};

export const addInventoryConsignment = payload => async dispatch => {
  try {
  const { data } = await api.post(`/v3/api/backoffice/carbon_inventory/${payload.id}/consignments`, payload.request)
  dispatch(addConsignment(data))
  dispatch(removeCurrentBalance(payload.request.amount))
  dispatch(
    addToast({
      error: false,
      title: 'Consignment created',
      text: `Consignment has been created`,
      id: uuid(),
    }),
  );
} catch (e) {
  console.log(e)
  dispatch(
    addToast({
      error: true,
      text: 'Error while created consignment',
      id: uuid(),
    }),
  );
}
};

export const exportInventories = (payload) => async (dispatch) => {
  let url = `/v3/api/backoffice/carbon_inventory/export?search=${payload.search || ''}`;
  url += `&${payload.filter}`
  if(payload.projectId)
    url += `&project_id=${payload.projectId}`
  try {
    const { data } = await api.post(url)

    const linkUrl = window.URL.createObjectURL(new Blob([data])) 
    const link = document.createElement('a')
    link.href = linkUrl
    const fileName = `Inventories ${moment(new Date()).format("DD MMM YY")}.csv`;
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  
    dispatch(
      addToast({
        error: false,
        title: "Inventories downloaded",
        text: `Inventories has been downloaded`,
        id: uuid(),
      })
    );
  } catch (e) {
    dispatch(
      addToast({
        error: true,
        title: "Inventories download failed",
        text: `Failed to download inventories`,
        id: uuid(),
      })
    );
  }


};

export const { setInventories, changeInventory, setConsigments, setLoading, setConsignmentsLoading, addInventory, setInventory, setRetirements, setRetirementsLoading, addConsignment,removeCurrentBalance, setRegistryAccounts, setSubRegistryAccounts } = inventorySlice.actions;
export default inventorySlice.reducer;