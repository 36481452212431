import React, { useEffect, useState, useCallback, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";

    
import './style.scss'

const CampaignDashboard = ({campaignId }) => {
    const { campaignProjects } = useSelector(data => data.campaign);
    
    const dispatch = useDispatch();

    return(
        <div className="flex-column campaign-list-tx">
           Dashboard
        </div>
    )
}

export default CampaignDashboard;