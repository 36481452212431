import React from 'react'
import PlaceholderImage from '../../assets/images/nft-placeholder.svg'
import { useNavigate } from "react-router-dom";
import Image from "../image";
import '../../assets/css/pages/account.scss'

const CollectionCard = ({collection }) => {
    const navigate = useNavigate();

    return (
        <div className="collection no-select cursor" onClick={() => navigate(`/meta/collections/${collection.id}${collection.integration ? `?integration=${collection.integration?.id}` : ''}`)}>
        <div className="card collection-detail">
            <div className={"relative"}>
                <Image className={"collection-image"} size={160} media={collection?.image?.media} />
                { collection.contracts  && <div className={"platform"}>{collection.contracts ? collection.contracts[0]?.platform : ''}</div> }
            </div>

            <div className='flex-column content-box flex-1'>
                <div className="flex items-start">
                    <div className="flex-column flex-1">
                        <h3 className="mt-1 mb-3 font-16">{collection.name}</h3>
                        <div className='flex org-description'>
                            <span className="regular-text mt-1 o05 font-12 collection-description">{collection.description}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default CollectionCard;
