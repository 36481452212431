import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  getSchemaAttributes,
  updateSchemaAttributes,
} from "../../../store/features/schemaSlice";
import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";
import { capitalize, formatText } from "../../../helpers/utils";
import "./style.scss";
import SchemaAttributeEdit from "./SchemaAttributeEdit";
import Button from "../../Elements/button/button";

const SchemaAttributes = ({ schema, isNew, menu }) => {
  const dispatch = useDispatch();
  const { schemaAttributes } = useSelector((data) => data.schema);
  const [edit, setEdit] = useState(false);
  const [schemaAttributesState, setSchemaAttributesState] = useState([]);

  useEffect(() => {
    if (!isNew) {
      dispatch(getSchemaAttributes(schema.id));
    }
  }, [schema?.id, isNew]);

  useEffect(() => {
    setSchemaAttributesState(schemaAttributes);
  }, [schemaAttributes]);

  const onSave = () => {
    const request = schemaAttributesState.map((att, index) =>
      att.isNew
        ? {
            id: null,
            attribute_name: att.attribute_name,
            lexicon: att.lexicon,
            attribute_type: att.attribute_type,
            priority: index + 1,
          }
        : {
            ...att,
            priority: index + 1,
          }
    );
    dispatch(updateSchemaAttributes({ id: schema.id, attributes: request }));
    setEdit(false);
  };

  return (
    <div className="flex-column card">
      <div className="flex items-center justify-between px-6 py-5 border-bottom">
        <label className="bold-text font-20">Schema attributes</label>
        <div className="flex items-center">
          {edit ? (
            <div className="flex justify-center mr-4">
              <Button
                className="secondary-hover"
                label="Cancel"
                borderRadius="12px"
                onClick={() => setEdit(false)}
                secondary={true}
                size="medium"
                style={{ flex: 1 }}
                bgColor="#FFFFFF"
                borderColor="#24CBB1"
                color="#24CBB1"
                borderWidth="1px"
                width="110px"
              />
              <Button
                label="Save"
                onClick={() => onSave()}
                size="medium"
                style={{ flex: 1, marginLeft: 24 }}
                bgColor="#24CBB1"
                borderColor="#24CBB1"
                color="#FFFFFF"
                borderRadius="12px"
                borderWidth="1px"
                width="110px"
              />
            </div>
          ) : (
            <div
              className="flex items-center justify-center cursor mr-6 no-select schema-edit-btn"
              onClick={() => {
                setSchemaAttributesState(schemaAttributes);
                setEdit(true);
              }}
            >
              <EditIcon />
              <label className="semibold-text font-12 mid-green-text ml-1">
                Edit
              </label>
            </div>
          )}
          {menu}
        </div>
      </div>
      <div className="flex-column schema-attributes">
        {!isNew &&
          (edit ? (
            <SchemaAttributeEdit
              schemaAttributes={schemaAttributesState}
              setSchemaAttributes={setSchemaAttributesState}
            />
          ) : (
            schemaAttributes.map((sa) => (
              <div
                key={sa.id}
                className="flex items-center border-bottom schema-attributes-grid"
              >
                <label className="regular-text border-right px-6 py-3 my-1">
                  {sa.attribute_name}
                </label>
                <label className="regular-text light-purple-text border-right px-6 py-3 my-1">
                  Lexicon:{" "}
                  <span className="regular-text ml-2">{sa.lexicon}</span>
                </label>
                <div className="flex items-center justify-between px-6 py-3 my-1">
                  <label className="regular-text light-purple-text">
                    Data type:{" "}
                    <span className="regular-text ml-2">
                      {capitalize(formatText(sa.attribute_type))}
                    </span>
                  </label>
                </div>
              </div>
            ))
          ))}
      </div>
    </div>
  );
};

export default SchemaAttributes;
