import React, { useState, useEffect } from 'react'

import Axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { login } from '../store/features/userSlice';

import BackGroundLogo from '../assets/images/background-logo.svg'
import Logo from '../assets/images/logo.svg'
import RefreshIcon from '../assets/images/refresh-icon.svg'
import ErrorIcon from '../assets/images/error.svg'
import ShowPassword from '../assets/images/show-password.svg'
import HidePassword from '../assets/images/hide-password.svg'
import OtpInput from './otp-input';

import "../assets/css/pages/forgot-password.scss";


const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [email, setEmail] = useState();
    const [active, setActive] = useState();
    const [passwordActive, setPasswordActive] = useState();
    const [currentStep, setCurrentStep] = useState('EMAIL');
    const [isOtpValid, setIsOtpValid] = useState(true);
    const [otpValue, setOtpValue] = useState('');
    const [password, setPassword] = useState();
    const [confimPassword, setConfirmPassword] = useState();
    const [passwordError, setPasswordError] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [authenticationToken, setAuthenticationToken] = useState('');

    useEffect(() => {
        setActive(email && email.length > 2)
    }, [email])

    useEffect(() => {
        setPasswordActive(password && confimPassword && password.length > 2 && confimPassword.length > 2)
    }, [password, confimPassword])

    const resetPassword = async () => {
        if (active) {
            await Axios.post(`${process.env.REACT_APP_API_URL}/v1/password/otp`, { destination: email, destination_type: "EMAIL" })
            setCurrentStep('OTP');
        }
    }

    const sendOtp = async () => {
        if (otpValue && otpValue.length === 6) {
            Axios.post(`${process.env.REACT_APP_API_URL}/v1/password/otp/validate`, { destination: email, code: otpValue, destination_type: 'EMAIL' })
                .then(({ data }) => {
                    setAuthenticationToken(data.access_token);
                    setCurrentStep('PASSWORD');
                })
                .catch(error => {
                    const errorData = error?.response?.data;
                    if (errorData?.error_code === 'BAD_REQUEST') {
                        setIsOtpValid(false);
                    }
                });
        }
    }

    const resendEmail = async () => {
        await Axios.post(`${process.env.REACT_APP_API_URL}/v1/passwords/otp`, { email: email })
    }

    const login = async (email, password) => {
        if (active)
            navigate("/login")
    };

    const activate = async () => {
        if (passwordActive) {
            if (password !== confimPassword) setPasswordError('Passwords do not match');
            else {
                Axios.put(
                    `${process.env.REACT_APP_API_URL}/v1/users/password`,
                    {
                        password: password,
                    },
                    { headers: { authorization: `Bearer ${authenticationToken}` } },
                )
                    .then(() => {
                        login(email, password)
                    })
                    .catch(() => {
                        setPasswordError('Something happened');
                    });
            }
        }
    };

    useEffect(() => {
        setIsOtpValid(true);
    }, [otpValue]);

    return (
        <div className="forgot-password-page flex-column items-center w-full" >
            <div className="flex-column items-center fp-content">
                <img className="logo mt-20" src={Logo} />
                {currentStep === 'EMAIL' ?
                    <>
                        <label className='bold-text font-24 white-text mt-12'>Forgotten your password?</label>
                        <label className='subtitle regular-text font-16 white-text mt-4'>Enter your email below and we will send you a password re-set email</label>
                        <div className="flex-column mt-12">
                            <label className='regular-text white-text font-12 mb-2'>Email</label>
                            <div className='input-container'>
                                <input
                                    placeholder="Email address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <button style={{ backgroundColor: active ? '#10F3CF' : '#217E70', color: active ? '#ffffff' : 'rgba(255, 255, 255, 0.3)' }} onClick={resetPassword}>Send email</button>
                    </>
                    :
                    currentStep === 'OTP' ?
                        <>
                            <label className='bold-text font-24 white-text mt-12'>We sent you an email</label>
                            <label className='subtitle regular-text font-16 white-text mt-4'>Check your email and enter 6-digit code here</label>
                            <OtpInput
                                className={'otp-input'}
                                containerClass={'otp-content'}
                                errorStyle={'error-style'}
                                hasErrored={!isOtpValid}
                                inputStyle={'input-style'}
                                isInputNum={true}
                                onChange={setOtpValue}
                                placeholder="000000"
                                value={otpValue}
                            />
                            {!isOtpValid &&
                                <div className="flex otp-error pxy-1 mt-4">
                                    <img src={ErrorIcon} alt="error" />
                                    <label className="medium-text white-text ml-2">Incorrect code - please check your code is correct and try again</label>
                                </div>
                            }
                            <button style={{ backgroundColor: active ? '#32E88B' : '#6845BB', color: active ? '#ffffff' : 'rgba(255, 255, 255, 0.3)' }} onClick={sendOtp}>Submit</button>
                            <div className="flex items-center cursor re-send">
                                <img src={RefreshIcon} alt="refresh" />
                                <label className="medium-text font-16 ml-2 green-text" onClick={resendEmail}>Re-send email</label>
                            </div>
                        </>
                        :
                        <>
                            <label className='bold-text font-24 white-text mt-12 mb-10'>Re-set your password</label>
                            {passwordError &&
                                <div className="flex password-error pxy-1 mb-6">
                                    <img src={ErrorIcon} alt="error" />
                                    <label className="medium-text white-text ml-2">{passwordError}</label>
                                </div>
                            }
                            <div className="flex-column relative">
                                <label className='regular-text white-text font-12 mb-2'>New password</label>
                                <div className='input-container'>
                                    <input
                                        placeholder="Enter new password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type={showPassword ? 'text' : 'password'}
                                    />
                                    <img className="show-password" src={showPassword ? HidePassword : ShowPassword} alt="show password" onClick={() => setShowPassword(!showPassword)} />
                                </div>
                            </div>
                            <div className="flex-column mt-6 relative">
                                <label className='regular-text white-text font-12 mb-2'>Confirm new password</label>
                                <div className='input-container'>
                                    <input
                                        placeholder="Confirm new password"
                                        value={confimPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        type={showPassword ? 'text' : 'password'}
                                    />
                                    <img className="show-password" src={showPassword ? HidePassword : ShowPassword} alt="show password" onClick={() => setShowPassword(!showPassword)} />
                                </div>
                            </div>
                            <button style={{ backgroundColor: passwordActive ? '#10F3CF' : '#217E70', color: passwordActive ? '#ffffff' : 'rgba(255, 255, 255, 0.3)' }} onClick={activate}>Set new password</button>
                        </>
                }
            </div>
        </div>
    )
}

export default ForgotPassword;