import styled from 'styled-components';

const IntegrationWrapper = styled.div`
  width: 100%;
  margin: 14px;

  .switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 48px;
    height: 24px;
    background: #d2e5ed;
    box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);
    border-radius: 20px;

    &:hover {
      background: #e5ebef;
    }

    .selector {
      min-width: 20px;
      min-height: 20px;
      background: #ffffff;
      box-shadow: 1px 0px 5px rgba(5, 49, 73, 0.2);
      border-radius: 50%;
      margin: 0 2px;
      transition: transform 400ms;
    }
  }

  .switch-selected {
    background: #40f1de;
    box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);

    &:hover {
      background: #40f1de;
    }

    .selector {
      transform: translate(24px);
    }
  }

  .content-loader {
    position: absolute;
    inset: 0;
    margin: 0;
    opacity: 0.8;
    z-index: 10;
  }

  .description-input {
    min-height: 100px;
    resize: none;
    outline: none;
    max-height: 200px;
  }

  .integration-container {
    display: flex;
    justify-content: space-between;
    margin: 8px 14px;
    padding: 0 5px;

    .user-action {
      display: flex;
      align-items: center;
      height: 40px;
      background: #31ddca4d;
      border-radius: 10px;
      cursor: pointer;
      padding-left: 8px;
      padding-right: 16px;

      &:hover {
        background: #2fedd84d;
      }
    }
    .user-action-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      path {
        fill: #053149;
      }
    }
    .integration-pagination {
      height: unset;
    }

    .integration-search {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      width: 230px;
      border: 1px solid #e3eef3;
      border-radius: 12px;
      background: #ffffff;
      padding: 0 16px;
      padding-right: 6px;
      user-select: none;
      margin-left: 20px;
      border: 1px solid #d2e5ed;

      &:hover {
        background: #f4f7f9;

        input {
          background: #f4f7f9;
        }

        .close {
          background: #f4f7f9;
        }
      }

      &:focus {
        border: 1px solid #40f1de;
      }

      input {
        width: 100%;
        border: none;
        outline: none;
        padding-left: 8px;

        &::-webkit-input-placeholder {
          opacity: 0.5;
        }
      }

      .search-icon {
        height: 16px;
        width: 16px;
        opacity: 0.5;
      }

      .close {
        background: #fff;
        border-radius: 100px;
        width: 30px;
        height: 24px;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
      }
      .close-focused {
        background: #f4f7f9;
      }

      .close-icon {
        width: 14px;
        height: 14px;
        margin-left: 5px;
      }
    }
    .search-focused {
      border: 1px solid #40f1de;
    }
  }

  .integrations {
    row-gap: 32px;
    column-gap: 24px;
    margin-top: 10px;
    overflow-y: auto;
    height: calc(100vh - 90px);
    overflow: auto;
    padding: 20px 20px 20px 20px;

    .h-9 {
      height: 80%;
    }

    .integration-item {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 5px 30px rgba(9, 19, 53, 0.06);
      border-radius: 8px;
      background: #ffffff;
      min-height: 64px;
      display: grid;
      padding: 0px 10px 0px 20px;
      grid-template-columns: minmax(200px, 1fr) 2px 2fr 2px 1.15fr 2px 1fr 2px;
      flex: 170;

      .second-width {
        min-width: 200px;
      }

      .double-line {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .no-description {
        color: rgba(115, 134, 140, 0.5);
      }

      .flex-wrap {
        flex-wrap: wrap;
      }

      .integration-item-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        outline: none;
        padding-left: 20px;
        min-width: 275px;
        padding-right: 20px;

        .flex-ratio {
          flex: 1 1;
        }

        .default-main-logo {
          height: 12px;
          margin-right: 8px;
        }
      }

      .integration-item-bottom {
        display: flex;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 12px 16px;
        height: 100%;
        justify-content: space-between;
        align-items: center;

        .icons {
          height: 32px;
          width: 32px;
        }

        .integration-type {
          span {
            font-size: 20px;
          }
        }

        .integration-status {
        }

        .enable-color {
          color: #40f1de;
        }

        .disable-color {
          color: #d2e5ed;
        }

        .switch {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 48px;
          height: 24px;
          background: #d2e5ed;
          box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);
          border-radius: 20px;

          &:hover {
            background: #e5ebef;
          }

          .selector {
            min-width: 20px;
            min-height: 20px;
            background: #ffffff;
            box-shadow: 1px 0px 5px rgba(5, 49, 73, 0.2);
            border-radius: 50%;
            margin: 0 2px;
            transition: transform 400ms;
          }
        }

        .switch-selected {
          background: #40f1de;
          box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);

          &:hover {
            background: #40f1de;
          }

          .selector {
            transform: translate(24px);
          }
        }
      }

      .integration-item-last {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: 40px;

        .last-width {
          min-width: 100px;
        }
      }
    }
    .menu-wrapper {
      position: absolute;
      top: -5px;
      width: 200px;
      right: 25px;
      padding: 4px;
      z-index: 1;

      .regular-text {
        padding: 8px;
        display: flex;
        align-items: center;
        color: #053149;
        white-space: nowrap;
      }

      .regular-text:hover {
        background: #f4f9f8;
      }

      .border-bottom {
        border-bottom: 1px solid #d2e5ed;
      }
    }
  }

  .selected-integration-container {
    display: flex;
    justify-content: space-between;
    margin: 8px 14px;

    .selected-integration-action {
      padding: 8px 40px;
      border-radius: 8px;
    }
  }

  .selected-integration {
    display: flex;
    margin: 20px 16px;

    .field-label {
      color: #73868c;
      font-size: 14px;
    }

    .popup-input {
      margin-bottom: unset;
      padding: 12px 15px;

      &:focus {
        border: 1px solid #31ddca;
      }
    }

    .error-info {
      border-color: red;

      .selector,
      .select__control,
      .select__control--is-focused {
        border-color: red !important;
      }
    }

    .selected-integration-left {
      background: #ffffff;
      padding: 40px;
      min-width: 440px;
      max-width: 440px;
      box-shadow: 2px 2px 15px rgb(5 49 73 / 5%);
      border-radius: 8px;
      margin-right: 24px;
    }

    .selected-integration-right {
      background: #ffffff;
      box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
      border-radius: 8px;
      height: fit-content;

      .api-key-input {
        font-size: 16px;
      }

      .integration-input {
        display: flex;
        width: 100%;
        position: relative;

        .api-key-input {
          width: 100%;
          padding: 12px 30px 12px 15px;
        }

        .input-show-img {
          position: absolute;
          right: 0;
          height: 100%;
          display: flex;
          align-items: center;
          margin-right: 8px;
          cursor: pointer;
        }
      }
    }

    .password-mask {
      text-security: disc; /* IE/Safari */
      -moz-text-security: disc; /* FireFox */
      -webkit-text-security: disc; /* Chrome/Safari  */
      letter-spacing: 1px;
    }
  }
`;

export default IntegrationWrapper;
