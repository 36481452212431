import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getCollectionSchemas } from '../../../store/features/collectionSlice';
import { useParams } from 'react-router-dom';

import Header from "../../header";
import Schema from "../../schemas/Schema";

import './style.scss';

const CollectionSchema = ({ menuItems, onMenuItemClick}) => {
    const { collectionSchemas  } = useSelector(data => data.collection);

    const dispatch = useDispatch();

    const {id} = useParams();

    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatch(getCollectionSchemas({collectionId: id, search: search }));
    }, [id, search, dispatch]);


    return (
        <div className="flex-column flex-1">
            <Header title={"Schemas"}
                    search={search}
                    setSearch={setSearch}
                    isFilter={false}
                    isPaged={false}
                    size='small'
                    menuItems={menuItems}
                    onMenuItemClick={onMenuItemClick}
                    isAddBtn={false}
                    />

            <div className="schemas mt-4 mb-4 border-top">
                {collectionSchemas.map(schema => <Schema key={schema.id} schema={schema} />)}
            </div>
        </div>
    )
}

export default CollectionSchema;
