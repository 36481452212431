import React, { useContext } from 'react';
import Button from '../components/Elements/button/button.js';
import { initModal } from '../constant/InitialData.js';
import { OrganisationContext } from '../context/organisationContext.js';
import { DeletePopupWrapper } from './modal.styled.js';
import { CSSTransition } from 'react-transition-group';

const DeletePopup = () => {
  const { modal, setModal } = useContext(OrganisationContext);
  const data = modal.content.data;
  const onDelete = modal.content.onDelete;

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <DeletePopupWrapper>
        <p className="bold-text font-24 text-center">
          Are you sure you want to delete {data.name}?
        </p>
        <div className="flex justify-center mt-12">
          <Button
            className="secondary-hover"
            label="Cancel"
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            style={{ flex: 1, borderRadius: '16px' }}
            bgColor="#FFFFFF"
            borderColor='#24CBB1'
            color='#24CBB1'
          />
          <Button
            label='Delete'
            onClick={() => { onDelete(data); setModal(initModal);}}
            size="large"
            style={{ flex: 1, marginLeft: 24, borderRadius: '16px' }}
            bgColor="#FB3535"
            borderColor='#FB3535'
            color='#FFFFFF'
          />
        </div>
      </DeletePopupWrapper>
    </CSSTransition>
  );
};

export default DeletePopup;
