import styled from 'styled-components';

export const PaginationContainerWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 0;

  .user-pagination {
    height: unset;
  }

  .properties-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 230px;
    border: 1px solid #e3eef3;
    border-radius: 12px;
    background: #ffffff;
    padding: 0 16px;
    padding-right: 6px;
    user-select: none;
    margin-left: 20px;
    border: 1px solid #d2e5ed;
    margin-right: 24px;

    &:hover {
      background: #f4f7f9;

      input {
        background: #f4f7f9;
      }
    }

    input {
      width: 100%;
      border: none;
      outline: none;
      padding-left: 8px;

      &::-webkit-input-placeholder {
        opacity: 0.5;
      }
    }

    .search-icon {
      height: 16px;
      width: 16px;
      opacity: 0.5;
    }

    .close {
      background: #f4f7f9;
      border-radius: 100px;
      width: 30px;
      height: 24px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    .close-icon {
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }
  }

  .search-focused {
    border: 1px solid #40f1de;
  }

  .add-team {
    display: flex;
    align-items: center;
    height: 32px;
    width: 104px;
    background: #31ddca4d;
    border-radius: 10px;
    cursor: pointer;
    padding-left: 9px;

    &:hover {
      background: #2fedd84d;
    }
  }
`;

export const TeamsWrapper = styled.div`
  @media only screen and (max-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }

  row-gap: 20px;
  column-gap: 20px;
  margin-top: 50px;
  overflow-y: auto;
  height: calc(100vh - 90px);
  overflow: auto;
  padding: 12px 10px;

  .user-invite-btn {
    min-height: 135px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #f4f7f9;
    border: 2px dashed #d2e5ed;
    border-radius: 8px;

    label {
      font-size: 16px;
    }
  }

  .data-match-row {
    display: grid;
    grid-template-columns: 1.5fr 0.1fr 2.5fr 0.1fr 2.5fr;
    background: #ffffff;
    box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
    border-radius: 16px;
    background: white;
  }

  .color-text {
    color: #053149;
  }

  .regular-text {
    padding: 0px;
    display: flex;
  }

  .tu-organisations {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    label {
      background: #053149;
      border: 1px solid rgba(9, 44, 64, 0.06);
      box-sizing: border-box;
      border-radius: 4px;
      font-family: Roboto Medium;
      font-size: 12px;
      color: white;
      padding: 4px 10px;
      margin-right: 8px;
      margin-bottom: 4px;
      margin-top: 4px;
    }
  }

  .organisation-empty {
    color: rgba(115, 134, 140, 0.5);
    margin: 4px 0px;
    display: block;
    padding: 4px 10px;
    margin-right: 8px;
    margin-bottom: 4px;
    margin-top: 4px;
  }

  .team-name,
  .team-description {
    margin: auto 0px auto 24px;
    min-width: 180px;
  }

  .empty-menu-space {
    min-width: 19px;
  }

  .services-width {
    min-width: 290px;
  }

  .dots-icon {
    margin: auto;
  }

  .border-right {
    border-right: 1px solid #d2e5ed;
    min-height: 64px;
  }

  .menu-wrapper {
    position: absolute;
    top: -5px;
    width: 200px;
    right: 25px;
    padding: 4px;
    z-index: 1;

    .regular-text {
      padding: 8px;
      display: flex;
      align-items: center;
      color: #053149;
      white-space: nowrap;
    }

    .regular-text:hover {
      background: #f4f9f8;
    }

    .border-bottom {
      border-bottom: 1px solid #d2e5ed;
    }
  }
`;
