import styled from 'styled-components';

export const InviteUserPopupWrapper = styled.div`
  .iu-popup {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid rgba(240, 246, 249, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 6px 40px rgba(108, 129, 140, 0.1);
    border-radius: 16px;
    outline: 0;
    width: 450px;
    padding: 20px 50px;

    .input-label {
      font-size: 12px;
      opacity: unset;

      .optional-label {
        opacity: 0.5;
      }
    }

    .error-input {
      border: 1px solid red;
    }

    .error-border {
      border-color: red;

      .selector,
      .select__control,
      .select__control--is-focused {
        border-color: red !important;
      }
    }

    .popup-title {
      font-family: Roboto Bold;
      font-size: 24px;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    .popup-btn {
      margin: 10px 0px;
      align-self: center;
    }

    .iu-dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      border: 1px solid #d2e5ed;
      border-radius: 8px;
      margin-bottom: 20px;
      cursor: pointer;
      outline: none;

      label {
        width: 100%;
        min-height: 35px;
        font-family: Roboto Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #053149;
        padding: 10px 15px;
        cursor: pointer;
      }

      .arrow {
        margin-right: 15px;
        transition-duration: 500ms;
        transition-property: transform;
        cursor: pointer;
      }

      .arrow-up {
        transform: rotate(180deg);
      }

      .iu-options {
        position: absolute;
        display: flex;
        flex-direction: column;
        background: #f8f8f8;
        border-radius: 4px;
        z-index: 3;
        top: 40px;
        width: 100%;
        overflow-y: scroll;
        transition: height 200ms linear 0s;

        label {
          font-family: Roboto Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #092c40;
          padding: 10px 15px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        label:hover {
          background: rgba(9, 44, 64, 0.04);
        }
      }
    }

    .iu-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 24px 0px;

      button {
        flex: 1;
      }

      .cancel-btn {
        margin-right: 14px;
        background: #ffffff;
        border: 2px solid #32e5c5;
        color: #053149;
      }
    }

    .popup-input {
      margin-bottom: unset;
      padding: 14px 16px;

      &::-webkit-input-placeholder {
        color: #053149;
        opacity: 0.3;
      }

      &:focus {
        border: 1px solid #31ddca;
      }
    }

    .options-select-container {
      max-width: 420px;

      .select__placeholder {
        color: #053149;
        opacity: 0.3;
      }

      .select__value-container {
        padding-left: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .select__control {
        border-radius: 10px;
        border: 1px solid #d2e5ed;

        &:hover {
          background: #f4f7f9;
        }
      }

      .select__control--is-focused {
        border: 1px solid #31ddca;
        box-shadow: unset;
      }

      .select__menu-list {
        max-height: 200px;
      }

      .select__multi-value {
        background: #d2e5ed;
        border-radius: 4px;
      }

      .select__multi-value__label {
        color: #053149;
        font-size: 16px;
      }

      .select__clear-indicator,
      .select__indicator-separator {
        display: none;
      }
    }

    .tpus-user {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      margin: 0 8px;
      cursor: pointer;

      label {
        width: 100%;
        font-size: 16px;
        cursor: pointer;
      }
    }

    .tpus-user:hover {
      background: rgba(210, 229, 237, 0.3);
      border-radius: 4px;
      cursor: pointer;
    }

    .switch {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 48px;
      height: 24px;
      background: #d2e5ed;
      box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);
      border-radius: 20px;
      cursor: pointer;

      &:hover {
        background: #e5ebef;
      }

      .selector {
        min-width: 20px;
        min-height: 20px;
        background: #ffffff;
        box-shadow: 1px 0px 5px rgba(5, 49, 73, 0.2);
        border-radius: 50%;
        margin: 0 5px;
        cursor: pointer;
        transition: transform 400ms;
      }
    }

    .switch-selected {
      background: #40f1de;
      box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);

      &:hover {
        background: #40f1de;
      }

      .selector {
        transform: translate(22px);
      }
    }
  }

  .invite-role-dd {
    .selector {
      border-radius: 8px;
      height: 48px;
    }
  }
`;
