import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 32, 48, 0.8);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .modal-content {
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
    overflow-y: visible !important;
    background: #ffffff;
    border: 1px solid rgba(240, 246, 249, 0.5);
    box-shadow: 2px 2px 50px rgba(5, 49, 73, 0.2);
    border-radius: 16px;
    outline: 0;
    /* padding: 40px; */
    position: relative;
  }

  .popup-in-appear {
    opacity: 0;
    transform: translate(0px, 15px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-active {
    opacity: 0;
    transform: translate(0px, 15px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-done {
    opacity: 1;
    transform: unset;
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear {
    opacity: 0;
    transform: translate(0px, 6px);
    transition: transform 100ms ease-in, opacity 50ms ease-in;
  }

  .popup-fade-appear-active {
    opacity: 0;
    transform: translate(0px, 6px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear-done {
    opacity: 1;
    transform: unset;
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }
  .popup-dashboard-fullscreen {
    height: 100vh;
    width: 100vw;
    padding: 20px;
  }
`;

export const ImageModalWrapper = styled.div`
  width: 420px;
  padding: 40px;
  .image {
    width: 100%;
    height: 225px;
    max-width: 368px;
    border-radius: 8px;
    object-fit: contain;
    margin-top: 20px;
  }

  .image-actions {
    width: 100%;
    height: 225px;
    background: linear-gradient(0deg, rgba(55, 77, 89, 0.78), rgba(55, 77, 89, 0.78));
    border-radius: 8px;
    position: absolute;
    top: 20px;

    .remove * {
      cursor: pointer;
    }

    .remove {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 2px solid #ffffff;
      border-radius: 8px;
      width: 100px;
      height: 40px;
      cursor: pointer;

      label {
        color: #ffffff;
        margin-left: 8px;
      }
    }
  }
  .add-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 224px;
    margin-top: 20px;
    background: linear-gradient(0deg, #f4f7f9, #f4f7f9);
    border-radius: 8px;
    .regular-text {
      font-size: 16px;
      color: #73868c;
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }
  .file-select-input {
    display: none;
  }
`;

export const ImagePreviewModalWrapper = styled.div`
  padding: 40px 0px;
  width: 900px;
  position: relative;
  outline: none;
  .arrow-btn {
    width: 110px;
  }
  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .view-image {
    width: 700px;
    max-height: 500px;
    border-radius: 8px;
    object-fit: contain;
  }
  .preview-center {
    width: 800px;
    overflow: hidden;
    .title {
      font-size: 24px;
    }
  }
  .pages-scroll {
    overflow: scroll;
  }
  .small-image-selected {
    img {
      border: 2px solid #f6bb23;
    }
  }
  .small-image {
    margin: 0px 15px;
    img {
      height: 65px;
      border-radius: 8px;
      margin-top: 15px;
    }
  }
`;

export const TrasnferModalWapper = styled.div`
  padding: 40px;
  height: 550px;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 24px;
    margin-bottom: 32px;
    text-align: center;
  }
  .error-border {
    .select__control {
      border-color: red;
    }
  }
`;

export const EpcImportModalWapper = styled.div`
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 24px;
    margin-bottom: 32px;
    text-align: center;
  }

  .selector {
    margin-right: 0;
  }
`;

export const RaiseIssuesWrapper = styled.div`
  padding: 40px;
  width: 450px;
`;

export const PerformActionWrapper = styled.div`
  padding: 32px 40px;
  width: 40px;
  width: 504px;
  .sub-left {
    width: 100px;
  }
  .file-upload {
    border-radius: 8px;
    padding: 32px 60px;
  }
  .choose-file {
    width: 112px;
  }
  .upload-status {
    background-color: #f4f7f9;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    user-select: none;

    .dd-text {
      color: #73868c;
    }

    span {
      color: #32e5c5;
      cursor: pointer;
    }

    .progress-line {
      height: 2px;
      background: #d2e5ed;
      border-radius: 2px;

      .progress {
        height: 2px;
        background: #5470ff;
        border-radius: 2px;
      }
    }

    .up-action {
      background: #ffffff;
      border: 1px solid rgba(210, 229, 237, 0.5);
      border-radius: 4px;
      width: 24px;
      height: 24px;
    }
  }
`;

export const ViewFileModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 32, 48, 0.8);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .close-btn {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
  }

  .pdf-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    label {
      font-family: Roboto bold;
      font-size: 16px;
      color: #ffffff;
      margin-top: 24px;
    }
  }

  .view-file-content {
    height: 92vh;
    width: 92vw;
    background-color: #ffffff;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 48px;
    border-radius: 16px;

    .pdf-view-container {
      overflow-y: scroll;
      border-radius: 8px;
      column-gap: 24px;
      width: 100%;
    }

    .pdf-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      right: 16px;
      top: 24px;
      z-index: 2;
    }

    .pdf-left-container {
      overflow-y: scroll;
      border-radius: 8px;
      background: #f4f7f9;
      height: 100%;
      min-width: 185px;

      .pdf-document-left {
        background: #f4f7f9;
        padding: 16px;

        .document-left-page {
          margin-bottom: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .pdf-page {
          height: 220px;
          width: 151px;
          margin-bottom: 8px;

          canvas {
            height: 220px !important;
            width: 151px !important;
            border: 1px solid #d2e5ed;
            border-radius: 4px;
          }
          .react-pdf__Page__textContent {
            height: 220px !important;
            width: 151px !important;
          }
        }

        .selected-page {
          canvas {
            border: 2px solid #40f1de;
            border-radius: 4px;
          }
        }
      }
    }

    .pdf-container-main {
      flex: 1 1;
      overflow-y: scroll;
      border: 1px solid #d2e5ed;
      border-radius: 8px;

      .pdf-document-main {
        max-width: calc(92vw - 96px - 185px - 28px);
        overflow: scroll;

        .pdf-page {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .pdf-action-container {
      background: #f4f7f9;
      border-radius: 8px;
      justify-content: space-between;
      padding: 4px 12px;
      margin-bottom: 16px;

      .pdf-scale-container {
        align-items: center;
        justify-content: center;

        .pdf-scale {
          border-radius: 100px;
          width: 110px;
          height: 32px;

          label {
            font-size: 16px;
          }
        }
      }

      .pdf-action {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background: rgba(5, 49, 73, 0.3);
        border-radius: 6px;
      }
    }
  }

  .loading-document {
  }
`;

export const NewLeadWrapper = styled.div`
  width: 864px;
  padding: 32px 24px 48px 24px;
  .divider {
    height: 1px;
    background: #d2e5ed7f;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .section-title {
    color: #73868c;
    font-family: Roboto bold;
    font-size: 12px;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 32px;
    opacity: 0.5;
    text-transform: uppercase;
  }
  .section-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 24px;
  }
`;

export const NewLeadV2Wrapper = styled.div`
  min-height: 620px;
  width: 864px;
  padding: 32px 24px 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .commecial-type-select {
    min-width: 168px;
  }

  .w-half {
    width: 50%;
  }

  .selector {
    &:hover {
      background: #f4f7f9;
    }
  }

  .divider {
    height: 1px;
    background: #d2e5ed7f;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .label {
    color: #73868c;
    font-family: Roboto Regular;
  }

  .section-title {
    color: #73868c;
    font-family: Roboto bold;
    font-size: 12px;
    margin-left: 24px;
    margin-right: 24px;
    opacity: 0.5;
  }

  .active-step {
    color: #053149;
    opacity: 1;
  }
  .section-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 24px;
  }

  .contact-title-container {
    width: 100px;
  }

  .column-gap-24 {
    column-gap: 24px;
  }

  .preference-container {
    display: flex;

    .w-50 {
      width: 50%;
    }
  }

  .contact-checkbox {
    .checkbox-class {
      min-width: 18px;
      max-width: 18px;
      min-height: 18px;
      max-height: 18px;

      img {
        width: 10px;
        height: 10px;
      }

      input {
        width: 18px;
        height: 18px;
      }
    }
  }

  .improvement-list {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 100%;
      background: #f4f7f9;
      border-radius: 12px;
      margin-left: 16px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d2e5ed;
      border-radius: 12px;
    }
  }
  .improvement-item {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 12px 8px;
    background: #f4f7f9;
    border-radius: 8px;
    margin: 0px 8px 8px 0px;
  }
  .selected-improvement {
    background: #053149;
    color: #ffffff;
  }

  .no-survey-content {
    background: #f4f7f9;
    border-radius: 8px;
    padding: 18px 0px;

    label {
      color: #73868c;
      opacity: 0.5;
      font-size: 16px;
    }
  }

  .checkbox-container {
    margin-top: 40px;
  }
  .checkbox-class {
    margin-left: 0px;
    margin-right: 0px;
  }
  .property-input-text {
    border-radius: 4px;
    border: 1px solid #d2e5ed;
    width: 100%;
    min-height: 40px;
    padding: 5px 10px;
    border-radius: 8px;

    ::-webkit-textfield-decoration-container {
      min-height: 25px;
    }

    ::-webkit-inner-spin-button {
      opacity: 1;
    }

    :focus-visible {
      outline: none;
    }

    ::-webkit-input-placeholder {
      opacity: 0.3;
      color: #053149;
    }
  }
  .construction-dropdown {
    .selector {
      & > div {
        white-space: nowrap;
        overflow: hidden;
        margin-right: 6px;

        & > label {
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
  }

  .lead-address {
    .selector {
      padding: 8px 16px;
    }

    .options {
      top: unset;
      margin-top: 8px;

      .disabled-option {
        opacity: 0.3;
      }
    }
  }

  .options-select-container {
    max-width: 420px;

    .select__placeholder {
      color: #053149;
      opacity: 0.3;
    }

    .select__value-container {
      padding-left: 16px;
      padding-top: 6px;
      padding-bottom: 6px;
    }

    .select__control {
      border-radius: 10px;
      border: 1px solid #d2e5ed;

      &:hover {
        background: #f4f7f9;
      }
    }

    .select__control--is-focused {
      border: 1px solid #31ddca;
      box-shadow: unset;
    }

    .select__menu-list {
      max-height: 200px;
    }

    .select__multi-value {
      background: #d2e5ed;
      border-radius: 4px;
    }

    .select__multi-value__label {
      color: #053149;
      font-size: 16px;
    }

    .select__clear-indicator,
    .select__indicator-separator {
      display: none;
    }

    .select__dropdown-indicator {
      svg {
        fill: black;
      }
    }
  }

  .error-text {
    font-size: 12px;
    margin-top: 6px;
    color: red;
  }

  .error-info {
    border-color: red;

    .selector,
    .select__control,
    .select__control--is-focused {
      border-color: red !important;
    }
  }

  .addresslist-wrapper {
    height: 236px;
    margin-top: 24px;
  }

  .empty-pincode-box {
    height: 100%;
    background: #f4f7f9;
    border-radius: 8px;
  }

  .addresslist-box {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 100%;
      background: #f4f7f9;
      border-radius: 12px;
      margin-left: 16px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d2e5ed;
      border-radius: 12px;
    }

    .disable {
      color: #d2e5ed;
      cursor: not-allowed !important;

      input {
        cursor: not-allowed !important;
      }
    }
  }
  .rounded-checkbox-container {
    .address-select-checkbox {
      margin: 0;
      border-radius: 50%;
    }

    .address-selected {
      box-shadow: inset 0 0 0 5px #fff;
    }

    .disable-checkbox {
      border: 1px solid #f4f7f9;
    }
  }
  .improvment-container {
    height: 297px;

    .empty-improvement-box {
      height: 100%;
      background: #f4f7f9;
      border-radius: 8px;
    }
  }
`;

export const NewLeadSuccessWrapper = styled.div`
  width: 456px;
  padding: 32px 48px 48px 48px;
  .item {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
  .surveys {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 18px;
  }
  .survey-item {
    display: flex;
    height: 32px;
    align-items: center;
    background: #f4f7f9;
    border-radius: 4px;
    justify-content: center;
    margin-right: 8px;
    margin-top: 8px;
    padding-left: 4px;
    padding-right: 8px;
  }
  .survey-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

export const NewLeadSuccessV2Wrapper = styled.div`
  width: 700px;
  padding: 32px 48px 48px 48px;

  .menu-container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    z-index: 11;
    cursor: pointer;
    width: 250px;

    &::before {
      content: '';
      background: white;
      width: 10px;
      height: 10px;
      transform: translate(-50%, -50%) rotate(45deg);
      position: absolute;
      top: 100%;
      left: 50%;
      right: -8px;
    }

    .service-label {
      display: flex;
      align-items: center;
      padding: 8px;
    }
    .service-label: hover {
      background-color: #f4f7f9;
      font-family: Roboto Medium;
    }
  }

  .lead-summary-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 700px;
  }
  .section-title {
    color: #73868c;
    font-family: Roboto bold;
    font-size: 12px;
    opacity: 0.5;
  }
  .divider {
    height: 1px;
    background: #d2e5ed7f;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 6px;
    margin-right: 8px;
    background: #f4f7f9;
    border-radius: 4px;
  }
  .item {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
  .surveys {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .survey-item {
    display: flex;
    align-items: center;
    background: #f4f7f9;
    border-radius: 4px;
    justify-content: center;
    margin-right: 8px;
    margin-top: 8px;
    padding: 4px 8px;
  }
  .survey-icon {
    height: 18px;
    width: 18px;
    margin-right: 8px;
  }

  .benefit-recipient {
    margin: 0;
    border-radius: 50%;
    pointer-events: none;
  }

  .property-details-container {
    .content-padding {
      padding: 0;
    }
  }

  .no-value-label {
    opacity: 0.3;
  }
`;

export const DeleteLeadWrapper = styled.div`
  width: 456px;
  padding: 32px 48px 48px 48px;
  .delete-lead-address {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .lead-description-container {
    width: 100%;
    heigth: 100%;
    display: flex;
    flex-direction: column;
    background: #f4f7f9;
    padding: 16px;
    margin: 24px 0px 0px 0px;
  }
`;

export const CancelLeadWrapper = styled.div`
  width: 456px;
  padding: 40px 40px 48px 40px;
  .cancel-lead-address {
    font-size: 16px;
    margin-top: 26px;
    margin-bottom: 24px;
    background: #f4f7f9;
    border-radius: 8px;
    padding: 16px;
  }
  .options-select-container {
    width: 100%;

    .select__placeholder,
    .select__menu-notice--no-options {
      color: #053149;
      opacity: 0.3;
    }

    .select__value-container {
      padding-left: 16px;
      padding-top: 6px;
      padding-bottom: 6px;
      min-height: 46px;
    }

    .select__control {
      border-radius: 10px;
      border: 1px solid #d2e5ed;

      &:hover {
        background: #f4f7f9;
      }
    }

    .select__control--is-focused {
      border: 1px solid #31ddca;
      box-shadow: unset;
    }

    .select__control--menu-is-open {
      .select__single-value {
        display: none;
      }
    }

    .select__menu-list {
      max-height: 200px;
    }
  }
  .opened {
    .select__control {
      border-radius: 8px;
      border: 1px solid #31ddca;
    }
    .select__value-container {
      .select__input-container {
        display: flex;

        input {
          height: 26px;
        }
      }
    }
    .hide-single-value {
      display: none;
    }
  }
`;

export const NewAppointmentWrapper = styled.div`
  width: 464px;
  padding: 32px 48px 48px 48px;
  .item {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 24px;
  }
  .select-modal {
    padding: 16px 0px;
  }
  .date-item {
    display: flex;
    flex-direction: column;
    height: 64px;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    margin-left: 4px;
    margin-right: 4px;
    min-width: 48px;
    max-width: 48px;
  }
  .time-length {
    width: 80px;
    height: 32px;
    margin-left: 24px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .time-unit {
    position: absolute;
    display: flex;
    right: 172px;
  }
  .select-time {
    display: flex;
    flex-wrap: wrap;
  }
  .time-item {
    display: flex;
    width: 76px;
    height: 24px;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    margin-right: 8px;
    margin-top: 8px;
  }
  .icon-red path {
    fill: #ff5b5b;
  }
`;

export const DeleteAppointmentWrapper = styled.div`
  width: 504px;
  padding: 40px 40px 40px 40px;
`;

export const CreateAppointmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 92vw;
  max-height: 92vh;
  padding: 8px 48px 48px 48px;
  justify-content: space-between;

  .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .length-btn {
    width: 20px;
    height: 12px;
    background: #f4f7f9;
    border-radius: 2px;
  }

  .time-selector {
    height: 40px;
  }

  textarea {
    outline: none;
    resize: none;
  }

  .create-appointment-grid {
    max-height: calc(100% - 70px);

    .create-appointment-position {
      max-height: inherit;
    }
  }

  .map-height {
    height: 92vh;
  }

  .region-dropdown {
    min-width: 184px;
    margin-right: 16px;
    .options {
      z-index: 10;
    }
  }
  .placeholder-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    opacity: 0.5;
  }
`;

export const ConfirmAppointmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 32px;
  justify-content: space-between;

  .duration {
    height: 18px;
    background: #7bd77a7f;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .icon-white path {
    width: 14px;
    height: 14px;
    fill: white;
    stroke: white;
  }

  .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .length-btn {
    width: 20px;
    height: 12px;
    background: #f4f7f9;
    border-radius: 2px;
  }

  .time-selector {
    height: 40px;
  }

  textarea {
    outline: none;
    resize: none;
  }

  .surveys {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .survey-item {
    display: flex;
    height: 30px;
    font-size: 14px;
    align-items: center;
    background: #f4f7f9;
    border-radius: 4px;
    justify-content: center;
    margin-right: 8px;
    margin-top: 6px;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const DeleteNotesWrapper = styled.div`
  width: 504px;
  padding: 40px 40px 40px 40px;
  .delete-note-content {
    margin-top: 35px;
    border: 1px solid rgba(210, 229, 237, 1);
    border-radius: 8px;
    padding: 24px;
  }
  .delete-note-actions {
    margin-top: 35px;
  }
`;

export const UpdateAppointmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 88vw;
  max-height: 92vh;
  padding: 36px 48px;

  .ua-top {
    background: rgb(249, 251, 252);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .ua-title {
      height: 48px;
      border-bottom: 1px solid rgb(231, 239, 242);
      width: 72px;
    }
  }

  .left-border {
    border-left: 1px solid rgb(231, 239, 242);
  }

  .hour-row {
    height: 80px;
    min-height: 80px;
    background: rgb(249, 251, 252);
  }

  .hour-item {
    width: 72px;
  }

  .ua-appointments {
    max-height: calc(100% - 100px);
    overflow-y: scroll;
  }

  .appointment {
    border-left: none;
    transition: none;
  }

  .ua-date-picker {
    position: absolute;
    width: 200px;
    z-index: 2;

    textarea {
      font-family: Roboto Regular;
      font-size: 14px;
      color: #053149;
      outline: 0;
      resize: none;
      border: 1px solid #d2e5ed;
      border-radius: 8px;
      padding: 15px;
    }

    .time-selector {
      min-height: 32px;
    }

    .triangle-left {
      position: absolute;
      left: -14px;
      top: 105px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 20px solid #ffffff;
      border-bottom: 10px solid transparent;
    }

    .triangle-right {
      position: absolute;
      right: -34px;
      top: 105px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-left: 20px solid #ffffff;
      border-bottom: 10px solid transparent;
    }

    .ua-date-picker-content {
      background: #ffffff;
      box-shadow: 2px 8px 30px rgba(5, 49, 73, 0.1);
      border-radius: 8px;
    }
  }

  .dotted-line {
    opacity: 0.5;
    border: 1px dashed #d2e5ed;
    background-color: transparent;
  }
`;

export const DeleteContactWrapper = styled.div`
  width: 504px;
  padding: 40px 40px 40px 40px;
  .delete-contact-content {
    margin-top: 35px;
    padding: 24px;
    background: #f4f7f9;
    border-radius: 8px;

    .icon-container {
      background: #ffffff;
      padding: 2px;
      border-radius: 4px;
    }

    .icon-class {
      width: 16px;
      height: 16px;
    }
  }
  .delete-contact-actions {
    margin-top: 35px;
  }

  .no-value-label {
    opacity: 0.3;
  }
`;

export const SurveyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .survey-item {
    border-radius: 8px;
    background: #f4f7f9;
    cursor: pointer;
    width: 100%;

    .survey-icon {
      top: 4px;
      left: 4px;
      width: 32px;
      height: 32px;
      background: white;
      border-radius: 50%;
      img {
        margin: auto;
        width: 16px;
        height: 16px;
      }
    }
  }
`;

export const BookInstallWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .book-date-selector {
    min-height: 40px;
  }

  .date-selector {
    right: 45px;
    top: 260px;
  }
`;

export const InstallStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .isp-survey {
    background: #f4f9fb;
    border-radius: 8px;
    width: 100%;
  }

  .book-date-selector {
    min-height: 40px;
  }

  .date-selector {
    right: 45px;
    top: 280px;
  }
`;

export const CancelSurveyAppointmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .isp-survey {
    background: #f4f9fb;
    border-radius: 8px;
    width: 100%;
  }
`;

export const AssignRetrofitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .book-date-selector {
    min-height: 40px;
  }

  .date-selector {
    right: 45px;
    top: 260px;
  }
`;

export const ArchivePropertyWrapper = styled.div`
  width: 504px;
  padding: 40px 40px 48px 40px;
  .delete-lead-address {
    font-size: 16px;
    margin-top: 26px;
    margin-bottom: 48px;
  }
`;

export const AppointmentPutcomesWrapper = styled.div`
  min-height: 300px;
  max-height: calc(100vh - 110px);
  position: relative;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  width: 456px;
  padding: 10px 10px 10px 10px;

  .error-border {
    border-color: red !important;

    .selector,
    .select__control,
    .select__control--is-focused {
      border-color: red !important;
    }
  }

  .loader {
    z-index: 3;
    background: transparent;
  }

  .isp-survey {
    background: #f4f9fb;
    border-radius: 8px;
    width: 100%;
  }

  .book-date-selector {
    min-height: 40px;
  }

  .date-selector {
    right: 45px;
    top: 280px;
  }

  .content-item {
    display: flex;
  }

  .org-name {
    color: #73868c;
  }

  .abc {
    background: #f4f7f9;
    border-radius: 8px;
    padding: 8px;
  }

  .note-input {
    textarea {
      width: 100%;
      border: 1px solid #d2e5ed;
      border-radius: 8px;
      padding: 10px 15px;
      resize: none;
      outline: none;
      font-family: Roboto regular;
      font-size: 16px;
      color: #053149;
      min-height: 70px;
      max-height: 70px;

      &::-webkit-input-placeholder {
        opacity: 0.5;
      }
    }
  }

  .status-selector {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    background: #f4f7f9;
    border: 1px solid #d2e5ed;
    box-sizing: border-box;
    border-radius: 100px;

    .status-selector-selected {
      width: 10px;
      height: 10px;
      min-width: 10px;
      min-height: 10px;
      background: #7bd77a;
      border-radius: 100px;
    }
  }

  .appointment-action {
    display: flex;
    align-items: center;
    height: 30px;
    background: #32e5c54c;
    border-radius: 8px;
    cursor: pointer;
    padding-left: 8px;
    padding-right: 8px;
    width: 340px;
    margin-top: 4px;
  }
  .appointment-action-icon {
    width: 14px;
    height: 14px;
    margin-right: 6px;
    path {
      fill: #053149;
    }
  }
  .create-new-action {
    height: 30px;
    background: rgba(64, 241, 222, 0.3);
    border-radius: 8px;
  }

  .survey-rag-status {
    width: 408px;
    height: 24px;
    background: #f4f7f9;
    border-radius: 4px;
  }

  .remove-survey-icon {
    left: 370px;
  }

  .book-new-btn {
    height: 48px;
    background: rgba(64, 241, 222, 0.3);
    border-radius: 8px;
  }

  .cancel-reason-dropdown {
    margin-top: 4px;
  }

  .add-survey-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 4px;
    cursor: pointer;
  }

  .minus-icon {
    border: 1px solid #40f1de;
    border-radius: 8px;
    padding: 4px;
  }

  .checked-icon {
    background: #40f1de;
    border-radius: 8px;
    padding: 8px 6px 8px 6px;
  }
  .route-container {
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
    align-items: center;

    .routes {
      max-height: 500px;
      overflow: scroll;

      .route-details {
        background: #f4f7f9 !important;
        padding: 16px;
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: 16px;

        label {
          cursor: pointer;
        }

        .double-line {
          height: 28px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .route-selector {
      .route-select-checkbox {
        margin: 0;
        border-radius: 50%;
      }

      .route-selected {
        box-shadow: inset 0 0 0 5px #fff;
      }
    }
  }
`;

export const CancelAppointmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .isp-survey {
    background: #f4f9fb;
    border-radius: 8px;
    width: 100%;
  }

  .book-date-selector {
    min-height: 40px;
  }

  .date-selector {
    right: 45px;
    top: 280px;
  }

  .content-item {
    display: flex;
  }

  .org-name {
    color: #73868c;
  }

  .note-input {
    textarea {
      width: 100%;
      border: 1px solid #d2e5ed;
      border-radius: 8px;
      padding: 10px 15px;
      resize: none;
      outline: none;
      font-family: Roboto regular;
      font-size: 16px;
      color: #053149;
      min-height: 100px;

      &::-webkit-input-placeholder {
        opacity: 0.5;
      }
    }
  }

  .cancel-reason-dropdown {
    .selector {
      border: 1px solid #d2e5ed !important;
    }
  }
`;

export const AppointmentsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .appointment-container {
    padding: 12px 8px;
    border-radius: 8px;
  }

  .duration {
    height: 18px;
    background: #7bd77a7f;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .icon-white path {
    width: 14px;
    height: 14px;
    fill: white;
    stroke: white;
  }
  .appointment-action {
    background: white;
    border: 2px solid #d2e5ed;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 24px;
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .appointment-action-icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
    path {
      fill: #053149;
    }
  }

  .note {
    height: 18px;
    background: white;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    width: fit-content;
  }
`;

export const AddTeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .isp-survey {
    background: #f4f9fb;
    border-radius: 8px;
    width: 100%;
  }

  .book-date-selector {
    min-height: 40px;
  }

  .date-selector {
    right: 45px;
    top: 280px;
  }

  .content-item {
    display: flex;
  }

  .org-name {
    color: #73868c;
  }

  .note-input {
    textarea {
      width: 100%;
      border: 1px solid #d2e5ed;
      border-radius: 8px;
      padding: 10px 15px;
      resize: none;
      outline: none;
      font-family: Roboto regular;
      font-size: 16px;
      color: #053149;
      min-height: 100px;

      &::-webkit-input-placeholder {
        opacity: 0.5;
        color: #053149;
      }
    }
  }

  .options-select-container {
    max-width: 420px;

    .select__placeholder {
      color: #053149;
      opacity: 0.5;
      font-family: Roboto Regular;
    }

    .select__value-container {
      padding-left: 15px;
    }

    .select__control {
      border-radius: 10px;
      border: 1px solid #d2e5ed;

      &:hover {
        background: #f4f7f9;
      }
    }

    .select__control--is-focused {
      border: 1px solid #31ddca;
      box-shadow: unset;
    }

    .select__multi-value {
      background: #d2e5ed;
      border-radius: 4px;
    }
    .select__multi-value__label {
      color: #053149;
      font-size: 16px;
      font-family: Roboto Regular;
    }

    .select__clear-indicator,
    .select__indicator-separator {
      display: none;
    }
  }

  .opened {
    .select__control {
      border-radius: 8px;
      border: 1px solid #31ddca;
    }
    .select__value-container {
      .select__input-container {
        display: flex;

        input {
          height: 26px;
        }
      }
    }
    .hide-single-value {
      display: none;
    }
  }

  .tpus-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin: 0 8px;
    cursor: pointer;

    label {
      width: 100%;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .tpus-user:hover {
    background: rgba(210, 229, 237, 0.3);
    border-radius: 4px;
    cursor: pointer;
  }

  .field-label {
    color: #73868c;
    font-size: 14px;
  }

  .popup-input {
    margin-bottom: unset;
    padding: 10px 15px;

    &:focus {
      border: 1px solid #31ddca;
    }
  }

  .error-input {
    border-color: red;
  }

  .error-border {
    .select__control {
      border-color: red;
    }
  }
`;

export const DeleteTeamWrapper = styled.div`
  width: 450px;
  padding: 40px 40px 40px 40px;

  .team-organization-container {
    margin-top: 24px;
  }

  .team-details-container {
    background: #f4f7f9;
    border-radius: 8px;
    padding: 16px 20px;
  }

  .team-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .team-name {
    font-family: Roboto Bold;
    font-size: 20px;
    color: #092c40;
  }

  .team-description {
    height: 100%;
    color: rgba(115, 134, 140, 0.5);
    font-size: 14px;
    padding-top: 4px;
  }

  .team-organisations {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    label {
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 6px;
      font-family: Roboto Medium;
      font-size: 12px;
      padding: 5px 10px;
      margin-right: 8px;
      color: white;
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }

  .services-empty {
    color: rgba(115, 134, 140, 0.5);
    margin: 4px 0px;
    display: block;
    padding: 4px 0px;
    margin-right: 8px;
    margin-bottom: 4px;
    margin-top: 4px;
  }
`;

export const DeleteCampaignProjectWrapper = styled.div`
  width: 424px;
  padding: 40px 40px 40px 40px;

  .rcp-image {
    width: 100%;
    border-radius: 12px;
  }

  .rcp-country {
    background: #FFFFFF;
    border-radius: 6px;
    height: 18px;
    top: 16px;
    left: 16px;
  }

  .user-details-container {
    background: #F6F6F6;
    padding: 8px;
    border-radius: 16px;
  }
`;


export const DeleteMediaWrapper = styled.div`
  width: 424px;
  padding: 40px 40px 40px 40px;

  img {
    border-radius: 12px;
  }

  .rcp-country {
    background: #FFFFFF;
    border-radius: 6px;
    height: 18px;
    top: 16px;
    left: 16px;
  }

  .user-details-container {
    background: #F6F6F6;
    padding: 8px;
    border-radius: 16px;
  }
`;

export const MintNftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 424px;
  padding: 48px;
  overflow-y: scroll;
  max-height: calc(100vh - 40px);

  .mn-details-container {
    background: #F6F6F6;
    border-radius: 16px;
  }

  .mn-image {
    width: 313px;
    border-radius: 12px;
  }

`;

export const DeleteCampaignTierWrapper = styled.div`
  width: 424px;
  padding: 40px 40px 40px 40px;

  .delete-tier-color {
    width: 20px;
    height: 20px;
    background: #D2C7C7;
    border-radius: 6px;
  }

  .rcp-image {
    width: 100%;
    border-radius: 12px;
  }

  .rcp-country {
    background: #FFFFFF;
    border-radius: 6px;
    height: 18px;
    top: 16px;
    left: 16px;
  }

  .user-details-container {
    background: #F6F6F6;
    padding: 16px;
    border-radius: 16px;
  }
`;

export const DeletePopupWrapper = styled.div`
  width: 424px;
  padding: 40px 40px 40px 40px;

  .delete-tier-color {
    width: 20px;
    height: 20px;
    background: #D2C7C7;
    border-radius: 6px;
  }

  .rcp-image {
    width: 100%;
    border-radius: 12px;
  }

  .rcp-country {
    background: #FFFFFF;
    border-radius: 6px;
    height: 18px;
    top: 16px;
    left: 16px;
  }

  .user-details-container {
    background: #F6F6F6;
    padding: 16px;
    border-radius: 16px;
  }
`;

export const DeleteUserWrapper = styled.div`
  width: 450px;
  padding: 40px 40px 40px 40px;

  .user-details-container {
    background: #f4f7f9;
    border-radius: 8px;
    padding: 16px;
    border-radius: 8px;
  }

  .not-provided-label {
    color: rgba(5, 49, 73, 0.3);
  }

  .user-name {
    font-size: 20px;
  }

  .user-roles {
    color: #73868c;
  }

  .icon-container {
    padding: 3px;
    background: white;
    border-radius: 4px;
  }
`;

export const AddTeamMemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .user-search-container {
    .search-icon {
      position: absolute;
      z-index: 5;
      height: 100%;
      margin: 0px 12px;
      width: 18px;

      path {
        fill: #d2e5ed;
      }
    }
  }

  .options-select-container {
    max-width: 420px;

    .select__placeholder,
    .select__menu-notice--no-options {
      color: #053149;
      opacity: 0.3;
    }

    .select__value-container {
      padding-left: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .select__control {
      border-radius: 10px;
      border: 1px solid #d2e5ed;

      &:hover {
        background: #f4f7f9;
      }
    }

    .select__control--is-focused {
      border: 1px solid #31ddca;
      box-shadow: unset;
    }

    .select__menu-list {
      max-height: 350px;
    }

    .select__multi-value {
      background: #d2e5ed;
      border-radius: 4px;
    }

    .select__multi-value__label {
      color: #053149;
      font-size: 16px;
    }

    .select__clear-indicator,
    .select__indicator-separator,
    .select__indicators {
      display: none;
    }

    .select__menu {
      margin-top: 4px;
    }
  }

  .search-icon-placeholder {
    .select__value-container {
      padding-left: 34px;
    }
  }

  .user-roles-container {
    column-gap: 16px;
    .user-role {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      background: #f4f7f9;
      border-radius: 8px;
    }

    .selected-user-role {
      background: #053149;
      border-radius: 8px;

      label {
        color: #ffffff;
      }
    }
  }

  .error-border {
    .select__control {
      border-color: red;
    }
  }
`;

export const AddProjectWrapper = styled.div`
  width: 450px;
  padding: 40px 40px 40px 40px;

  .label {
    color: #73868c;
    font-family: Roboto Regular;
  }

  .surveys {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .survey-item {
    display: flex;
    height: 32px;
    align-items: center;
    background: #f4f7f9;
    border-radius: 4px;
    justify-content: center;
    margin-right: 8px;
    margin-top: 12px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .selected-survey {
    background: #053149;
    color: #ffffff;
  }

  .no-survey-content {
    background: #f4f7f9;
    border-radius: 8px;
    padding: 18px 0px;

    label {
      color: #73868c;
      opacity: 0.5;
      font-size: 16px;
    }
  }
`;
export const LeadsDashboardWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100vw;
height: 100vh;

.leads-shadow {
position: absolute;
bottom: 26px;
right: 24px;
width: 30%;
height: 55px;
background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}

.nav-btn {
display: flex;
align-items: center;
justify-content: center;
width: 32px;
height: 32px;
user-select: none;
border-radius: 8px;
background: #f4f7f9;
box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
margin-left: 16px;
}

.dashboard-lead {
height: unset;

.pagination-content {
margin: 0;

.nav-btn {
background: #f4f7f9;
height: 32px;
width: 32px;
}

.selected {
background: #053149;
}

.menu-container {
right: -20px;

&:before {
left: unset;
right: 28px;
}
}
}
`;

export const SalesDashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  .nav-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    user-select: none;
    border-radius: 8px;
    background: #f4f7f9;
    box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
    margin-left: 16px;
  }
`;

export const AddIntegrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .isp-survey {
    background: #f4f9fb;
    border-radius: 8px;
    width: 100%;
  }
  .description-input {
    min-height: 100px;
    resize: none;
    outline: none;
    max-height: 200px;
  }

  .book-date-selector {
    min-height: 40px;
  }

  .date-selector {
    right: 45px;
    top: 280px;
  }

  .field-label {
    color: #73868c;
    font-size: 14px;
  }

  .popup-input {
    margin-bottom: unset;
    padding: 12px 15px;

    &:focus {
      border: 1px solid #31ddac;
    }
  }

  .error-border {
    border-color: red;
  }

  .provider-selector {
    .options {
      top: 48px;
    }
    .selector {
      padding: 12px 16px;
    }
  }

  .sub-provider {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    column-gap: 10px;
    row-gap: 10px;
  }
  .sub-provider-item {
    display: flex;
    background: #ffffff;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    box-shadow: 0px 5px 30px rgba(9, 19, 53, 0.06);
    border-radius: 8px;
    padding: 10px 16px;
    border: 1px solid #ffffff;
  }
  .selected-sub-provider {
    border: 1px solid #32e5c5;
  }

  .error-border {
    border-color: red;

    .selector,
    .select__control,
    .select__control--is-focused {
      border-color: red !important;
    }
  }
`;

export const DeleteIntegrationWrapper = styled.div`
  width: 450px;
  padding: 40px 40px 40px 40px;

  .integration-details-container {
    background: #f4f7f9;
    border-radius: 8px;
    padding: 16px;
  }

  hr {
    margin: 0px;
  }

  .integration-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .integration-name {
    font-family: Roboto Bold;
    font-size: 16px;
    color: #092c40;
  }

  .integration-type-container {
    margin-top: 8px;
    .integration-icon {
      display: flex;
      padding: 1px;
      background: white;
      border-radius: 4px;
    }

    .font-20 {
      font-size: 20px;
    }
  }

  .automation-detail {
    max-height: min(calc(70vh - 350px), 350px);
    overflow: scroll;
    margin-top: 16px;
  }

  .automation-detail-container {
    padding: 16px;
    background: #f4f7f9;
    border-radius: 8px;
    margin-bottom: 16px;

    .integration-item-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .integration-item-left {
        display: flex;
        flex-direction: column;
        margin-right: 4px;

        .integration-item-name {
          font-family: Roboto Medium;
          font-size: 16px;
          color: #092c40;
        }

        .hide-name {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            display: block;
          }
        }
      }

      .integration-item-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        outline: none;

        .owner-logo {
          margin-right: 12px;
          padding: 8px;
          background: #e6eeff;
          border-radius: 50%;

          label {
            color: #73868c;
          }
        }
      }
    }
  }
`;

export const BookInstallV2Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 92vw;
  max-height: 92vh;
  padding: 0px 0px 48px 0px;
  justify-content: space-between;

  .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .length-btn {
    width: 20px;
    height: 12px;
    background: #f4f7f9;
    border-radius: 2px;
  }

  .time-selector {
    height: 40px;
  }

  textarea {
    outline: none;
    resize: none;
  }

  .create-appointment-grid {
    max-height: calc(100% - 70px);

    .installer-container {
      padding: 24px 48px 0px 48px;
    }

    .create-appointment-position {
      max-height: calc(92vh - 300px);
    }

    .appointment-notes {
      min-height: 80px;
    }
  }

  .map-height {
    height: 92vh;

    .map-container {
      height: calc(100% - 120px);
    }
  }

  .service-container {
    display: flex;
    background: #f4f7f9;
    border-radius: 16px 16px 0px 0px;
    padding: 16px 48px 0px 48px;
    align-items: end;

    .service-item {
      padding: 8px 16px;

      &:hover {
        background: rgba(210, 229, 237, 0.24);
        border-radius: 8px 8px 0px 0px;
      }
    }

    .service-selected {
      background: #ffffff;
      border-radius: 8px 8px 0px 0px;

      &:hover {
        background: #ffffff;
      }
    }
  }
`;

export const BookInstallV3Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 92vw;
  max-height: 92vh;
  padding: 0px 0px 48px 0px;
  justify-content: space-between;

  .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .length-btn {
    width: 20px;
    height: 12px;
    background: #f4f7f9;
    border-radius: 2px;
  }

  .time-selector {
    height: 40px;
  }

  textarea {
    outline: none;
    resize: none;
  }

  .create-appointment-grid {
    .installer-container {
      padding: 24px 48px 0px 48px;
    }

    .create-appointment-position {
      max-height: calc(92vh - 300px);
    }

    .appointment-notes {
      min-height: 80px;
    }
  }

  .title-container {
    padding: 24px 48px;

    .cancel-button {
      border-radius: 8px;
      padding: 12px 8px;
    }
  }

  .map-height {
    height: 92vh;

    .map-container {
      height: calc(100% - 120px);
    }
  }

  .service-container-new {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    background: #f4f7f9;
    margin: 24px 48px 0px 48px;
    border-radius: 8px;
    padding: 4px;

    .service-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 12px 0px;

      &:hover {
        background: rgba(210, 229, 237, 0.24);
        border-radius: 8px 8px 0px 0px;
      }
    }

    .service-selected {
      background: #ffffff;
      border-radius: 8px;

      &:hover {
        background: #ffffff;
      }
    }

    .recent-appointment-text {
      margin-top: 4px;
      font-size: 10px;
      color: #73868c;
    }
  }

  .create-appointment-position {
    .search-container {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;

      .search-icon {
        position: absolute;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 4px;

        svg {
          height: 14px;

          path {
            fill: #73868c;
          }
        }
      }
      .search-input {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: 1px solid #fff;
        padding-left: 28px;
      }
    }
  }

  // .ua-title {
  // height: 45px;
  // padding: 4px 8px;
  // }

  // .appointments-top {
  // max-height: 50px;
  // }

  .filter-dropdown-wrapper {
    display: flex;
    align-items: center;
    min-width: 220px;
    max-width: 220px;
  }

  .filter-dropdown {
    min-width: 184px;
    margin-right: 16px;
    .selector {
      width: 205px;
    }
    .options {
      z-index: 10;
    }
  }
`;

export const ProjectActionWrapper = styled.div`
  width: 450px;
  padding: 40px 40px 48px 40px;

  .label {
    color: #73868c;
    font-family: Roboto Regular;
  }

  .project-details {
    border-radius: 8px;
    background: #f4f7f9;
  }

  .status-container {
    font-size: 12px;
    padding: 2px 4px;
    color: #ffffff;
    border-radius: 5px;
  }

  .project-date-container {
    margin-top: 8px;
  }
`;

export const ServiceRemoveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .service-item {
    border-radius: 8px;
    background: #f4f7f9;
    cursor: pointer;
    width: 100%;
    margin-top: 32px;

    .info-label {
      color: #73868c;
    }
  }
`;

export const SelectWorkitemRouteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 40px 40px 48px 40px;
  max-height: calc(100vh - 110px);
  overflow: scroll;

  .routes {
    max-height: 500px;
    overflow: scroll;

    .route-details {
      background: #f4f7f9;
      padding: 16px;
      border-radius: 8px;
      cursor: pointer;
      margin-bottom: 16px;

      label {
        cursor: pointer;
      }

      .double-line {
        height: 28px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .route-selector {
    .route-select-checkbox {
      margin: 0;
      border-radius: 50%;
    }

    .route-selected {
      box-shadow: inset 0 0 0 5px #fff;
    }
  }
`;

export const SelectWorkitemActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 40px 40px 48px 40px;

  .actions {
    .action-details {
      background: #f4f7f9;
      padding: 16px;
      border-radius: 8px;
      cursor: pointer;
      margin-bottom: 16px;

      label {
        cursor: pointer;
      }

      .double-line {
        height: 28px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .action-selector {
    .action-select-checkbox {
      margin: 0;
      border-radius: 50%;
    }

    .action-selected {
      box-shadow: inset 0 0 0 5px #fff;
    }
  }
`;

export const AddAutomationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .field-label {
    color: #73868c;
    font-size: 14px;
  }

  .popup-input {
    margin-bottom: unset;
    padding: 12px 15px;

    &:focus {
      border: 1px solid #31ddca;
    }
  }

  .description-input {
    min-height: 100px;
    resize: none;
    outline: none;
    max-height: 200px;
  }

  .options-select-container {
    width: 100%;

    .value-logo {
      margin-right: 8px;
      padding: 8px;
      background: #d2e5ed;
      border-radius: 50%;

      label {
        color: #73868c;
      }
    }

    .select__placeholder,
    .select__menu-notice--no-options {
      color: #053149;
      opacity: 0.3;
    }

    .select__value-container {
      padding-left: 16px;
      padding-top: 6px;
      padding-bottom: 6px;
      min-height: 46px;

      .select__input-container {
        display: none;
      }
    }

    .select__control {
      border-radius: 10px;
      border: 1px solid #d2e5ed;

      &:hover {
        background: #f4f7f9;
      }
    }

    .select__control--is-focused {
      border: 1px solid #31ddca;
      box-shadow: unset;
    }

    .select__control--menu-is-open {
      .select__single-value {
        display: none;
      }
    }

    .select__menu-list {
      max-height: 200px;
    }

    .select__multi-value {
      background: #d2e5ed;
      border-radius: 4px;
    }

    .select__multi-value__label {
      color: #053149;
      font-size: 16px;
    }

    .select__clear-indicator,
    .select__indicator-separator {
      display: none;
    }

    .select__menu {
      margin-top: 4px;
    }
  }

  .opened {
    .select__control {
      border-radius: 8px;
      border: 1px solid #31ddca;
    }
    .select__value-container {
      .select__input-container {
        display: flex;
      }
    }
    .hide-single-value {
      display: none;
    }
  }

  .error-border {
    border-color: red;
  }
`;

export const AddDashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .loader {
    z-index: 3;
    background: transparent;
  }

  .field-label {
    color: #73868c;
    font-size: 14px;
  }

  .popup-input {
    margin-bottom: unset;
    padding: 12px 15px;

    &:focus {
      border: 1px solid #31ddca;
    }
  }

  .description-input {
    min-height: 100px;
    resize: none;
    outline: none;
    max-height: 200px;
  }

  .opened {
    .select__value-container {
      .select__input-container {
        display: flex;
      }
    }
    .hide-single-value {
      display: none;
    }
  }
  .error-border {
    border-color: red;
  }
`;

export const AutomationActionWrapper = styled.div`
  width: 450px;
  padding: 40px 40px 40px 40px;

  .integration-details-container {
    background: #f4f7f9;
    border-radius: 8px;
    padding: 16px 20px 22px 20px;

    .owner-logo {
      padding: 8px;
      background: #e6eeff;
      border-radius: 50%;

      label {
        color: #73868c;
      }
    }
  }

  .integration-name {
    font-family: Roboto Bold;
    font-size: 16px;
    color: #092c40;
  }
`;

export const DashboardActionWrapper = styled.div`
  width: 450px;
  padding: 40px 40px 40px 40px;

  .integration-details-container {
    background: #f4f7f9;
    border-radius: 8px;
    padding: 16px 20px 22px 20px;

    .owner-logo {
      padding: 8px;
      background: #e6eeff;
      border-radius: 50%;

      label {
        color: #73868c;
      }
    }
  }

  .integration-name {
    font-family: Roboto Bold;
    font-size: 16px;
    color: #092c40;
  }
`;

export const CancelAutomationLogWrapper = styled.div`
  width: 450px;
  padding: 40px 48px 40px 48px;

  .integration-details-container {
    background: #f4f7f9;
    border-radius: 8px;
    // padding: 16px;

    hr {
      margin: 0;
    }

    .action-name {
      padding: 16px;
    }

    .content-padding {
      padding: 16px;
    }

    .owner-logo {
      padding: 8px;
      background: #e6eeff;
      border-radius: 50%;

      label {
        color: #73868c;
      }
    }
  }

  .integration-name {
    font-family: Roboto Bold;
    font-size: 16px;
    color: #092c40;
  }

  .border {
    position: absolute;
    width: 370px;
    height: 0px;
    left: 40px;
    border: 1px solid #e5e5e5;
  }
  .border-in {
    position: relative;
    width: 330px;
    left: 0px;
    border: 1px solid #e5e5e5;
  }
`;

export const BookDatesActionWrapper = styled.div`
  width: 450px;
  padding: 40px 40px 40px 40px;

  .book-date-action-btns {
    width: 100%;
  }

  .integration-details-container {
    background: #f4f7f9;
    border-radius: 8px;
    padding: 16px 20px 22px 20px;

    .owner-logo {
      padding: 8px;
      background: #e6eeff;
      border-radius: 50%;

      label {
        color: #73868c;
      }
    }
  }

  .integration-name {
    font-family: Roboto Bold;
    font-size: 16px;
    color: #092c40;
  }
`;

export const CancelWorkFlowWrapper = styled.div`
  width: 450px;
  padding: 40px 40px 40px 40px;

  .error-border {
    border-color: red;

    .selector,
    .select__control,
    .select__control--is-focused {
      border-color: red !important;
    }
  }
`;

export const DocumentUploadWrapper = styled.div`
  width: 420px;
  padding: 40px;

  .add-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 224px;
    margin-top: 20px;
    background: linear-gradient(0deg, #f4f7f9, #f4f7f9);
    border-radius: 8px;
    .regular-text {
      font-size: 16px;
      color: #73868c;
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }
  .file-select-input {
    display: none;
  }
  .view-file-container {
    display: flex;
    justify-content: space-between;
    background: #d2e5ed;
    border-radius: 12px;
    padding: 8px;

    .name-container {
      display: flex;
      .image-container {
        height: 50px;
        width: 50px;
        background: #ffff;
        padding: 10px;
        border-radius: 50%;
      }
      .file-name {
        margin-left: 8px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: 'Roboto Bold';
      }
    }
    .file-img-container {
      border-radius: 50%;
      background: #ffff;
      padding: 5px;
      display: flex;
    }
    .remove-icon {
      height: 20px;
      width: 20px;
      margin: auto 4px;
      cursor: pointer;
    }
  }
`;

export const UserAvailibilityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 92vw;
  max-height: 92vh;
  padding: 32px 48px 48px 48px;
  position: relative;

  .date-range {
    width: 136px;
  }

  .today-btn {
    width: 94px;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-item: center;

    label {
      display: flex;
      align-items: center;
    }
  }
  .rbc-time-header-gutter {
    color: #73868c;
    font-size: 14px;
    padding-top: 12px;
    padding-left: 16px;
    max-width: 150px !important;
  }

  .filter-container {
    display: flex;
    align-item: center;
  }

  .map-height {
    height: 92vh;
  }

  .date-selector {
    left: 20px;
    top: 120px;
  }
`;

export const PendingChecklistWrapper = styled.div`
  width: 456px;
  padding: 32px 48px 48px 48px;
  display: flex;
  flex-direction column;
  max-height: calc(100vh - 110px);
  overflow: scroll;

  .delete-lead-address {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .lead-description-container {
    width: 100%;
    heigth: 100%;
    display: flex;
    flex-direction: column;
    background: #f4f7f9;
    padding: 16px;
    margin: 24px 0px 0px 0px;
  }
  .routes {
    max-height: 500px;
    overflow: scroll;
    margin: 40px 0px;

    .route-details {
      background: #f4f7f9;
      padding: 12px;
      border-radius: 8px;
      cursor: pointer;
      margin-bottom: 8px;

      & > div {
        margin: 0px 8px 0px 0px;
        min-height:16px;
        min-width:16px;
      }

      .double-line {
        height: 28px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .route-selector {
    .route-select-checkbox {
      margin: 0;
      border-radius: 50%;
    }

    .route-selected {
      box-shadow: inset 0 0 0 5px #fff;
    }
  }
`;
export const AddDashboardOrganizationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .loader {
    z-index: 3;
    background: transparent;
  }

  .input-text {
    padding: 16px;
    border: 1px solid #d2e5ed;
    border-radius: 8px;
    cursor: pointer;
    height: 48px;
  }

  .error-border {
    border-color: red;
  }

  .menu-container {
    display: flex;
    flex-direction: column;

    position: absolute;
    right: 44px;
    top: 180px;
    background: #ffffff;
    z-index: 11;
    cursor: pointer;
    width: calc(100% - 85px);
    max-height: 250px;
    overflow: auto;

    .service-label {
      display: flex;
      align-items: center;
      padding: 8px;
    }
    .service-label: hover {
      background-color: #f4f7f9;
      font-family: Roboto Medium;
    }
  }

  .checkbox-container {
    display: flex;
    justify-content: center;
    & > div {
      margin: 0px 4px;
      min-height: 16px;
      min-width: 16px;
    }
    & > div > img {
      height: 8px;
    }
  }

  .checkbox-select {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    & > div {
      border-radius: 50%;
      margin-right: 16px;
      min-height: 16px;
      min-width: 16px;
      background: #a1d9a0;
    }
    & > div > img {
      height: 8px;
    }
  }

  .remove-icon {
    height: 20px;
    width: 20px;
    margin: auto 4px;
    cursor: pointer;
  }
`;

export const SelectRouteWrapper = styled.div`
  min-height: 300px;
  max-height: calc(100vh - 110px);
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  width: 475px;
  padding: 10px 10px 10px 10px;

  .loader {
    z-index: 3;
    background: transparent;
  }

  .route-container {
    display: flex;
    flex-direction: column;
    padding: 0px 32px;
    align-items: center;

    .routes {
      max-height: 500px;
      overflow: scroll;

      .route-details {
        background: #f4f7f9 !important;
        padding: 16px;
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: 16px;

        label {
          cursor: pointer;
        }

        .double-line {
          height: 28px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .route-selector {
      .route-select-checkbox {
        margin: 0;
        border-radius: 50%;
      }

      .route-selected {
        box-shadow: inset 0 0 0 5px #fff;
      }
    }
  }
`;
export const InstallAppointmentPutcomesWrapper = styled.div`
  min-height: 300px;
  max-height: calc(100vh - 110px);
  position: relative;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  width: 456px;
  padding: 10px 10px 10px 10px;

  .error-border {
    border-color: red;

    .selector,
    .select__control,
    .select__control--is-focused {
      border-color: red !important;
    }
  }

  .switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 48px;
    height: 24px;
    background: #d2e5ed;
    box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);
    border-radius: 20px;

    &:hover {
      background: #e5ebef;
    }

    .selector {
      min-width: 20px;
      min-height: 20px;
      background: #ffffff;
      box-shadow: 1px 0px 5px rgba(5, 49, 73, 0.2);
      border-radius: 50%;
      margin: 0 2px;
      transition: transform 400ms;
    }
  }

  .switch-selected {
    background: #40f1de;
    box-shadow: inset 0px 0px 8px rgba(5, 49, 73, 0.1);

    &:hover {
      background: #40f1de;
    }

    .selector {
      transform: translate(24px);
    }
  }

  .install-date-info {
    position: absolute;
    width: 261px;
    height: 50px;
    bottom: 153px;
    right: 10px;
    z-index: 2;
    filter: drop-shadow(2px 2px 15px rgba(5, 49, 73, 0.1));

    label {
      overflow: unset;
      text-overflow: unset;
      white-space: unset;
    }

    .triangle-bottom {
      bottom: -10px;
      left: 118px;
    }
  }

  .loader {
    z-index: 3;
    background: transparent;
  }

  .isp-survey {
    background: #f4f9fb;
    border-radius: 8px;
    width: 100%;
  }

  .book-date-selector {
    min-height: 40px;
  }

  .date-selector {
    right: 45px;
    top: 280px;
  }

  .content-item {
    display: flex;
  }

  .org-name {
    color: #73868c;
  }

  .abc {
    background: #f4f7f9;
    border-radius: 8px;
    padding: 8px;
  }

  .note-input {
    textarea {
      width: 100%;
      border: 1px solid #d2e5ed;
      border-radius: 8px;
      padding: 10px 15px;
      resize: none;
      outline: none;
      font-family: Roboto regular;
      font-size: 16px;
      color: #053149;
      min-height: 70px;
      max-height: 70px;

      &::-webkit-input-placeholder {
        opacity: 0.5;
      }
    }
  }

  .status-selector {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    background: #f4f7f9;
    border: 1px solid #d2e5ed;
    box-sizing: border-box;
    border-radius: 100px;

    .status-selector-selected {
      width: 10px;
      height: 10px;
      min-width: 10px;
      min-height: 10px;
      background: #7bd77a;
      border-radius: 100px;
    }
  }

  .appointment-action {
    display: flex;
    align-items: center;
    height: 30px;
    background: #32e5c54c;
    border-radius: 8px;
    cursor: pointer;
    padding-left: 8px;
    padding-right: 8px;
    width: 340px;
    margin-top: 4px;
  }
  .appointment-action-icon {
    width: 14px;
    height: 14px;
    margin-right: 6px;
    path {
      fill: #053149;
    }
  }
  .create-new-action {
    height: 30px;
    background: rgba(64, 241, 222, 0.3);
    border-radius: 8px;
  }

  .survey-rag-status {
    width: 408px;
    height: 24px;
    background: #f4f7f9;
    border-radius: 4px;
  }

  .remove-survey-icon {
    left: 370px;
  }

  .book-new-btn {
    height: 32px;
    background: rgba(64, 241, 222, 0.3);
    border-radius: 8px;
  }

  .cancel-reason-dropdown {
    margin-top: 4px;
  }

  .add-survey-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 4px;
    cursor: pointer;
  }

  .minus-icon {
    border: 1px solid #40f1de;
    border-radius: 8px;
    padding: 4px;
  }

  .checked-icon {
    background: #40f1de;
    border-radius: 8px;
    padding: 8px 6px 8px 6px;
  }
  .route-container {
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
    align-items: center;

    .routes {
      max-height: 500px;
      overflow: scroll;

      .route-details {
        background: #f4f7f9 !important;
        padding: 16px;
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: 16px;

        label {
          cursor: pointer;
        }

        .double-line {
          height: 28px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .route-selector {
      .route-select-checkbox {
        margin: 0;
        border-radius: 50%;
      }

      .route-selected {
        box-shadow: inset 0 0 0 5px #fff;
      }
    }
  }
`;

export const AddPaymentTermWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .routes {
    .route-details {
      background: #f4f7f9;
      padding: 16px;
      border-radius: 8px;
      cursor: pointer;
      margin-bottom: 16px;

      label {
        cursor: pointer;
      }
    }
  }

  .route-selector {
    .route-select-checkbox {
      margin: 0;
      border-radius: 50%;
    }

    .route-selected {
      box-shadow: inset 0 0 0 5px #fff;
    }
  }
`;
export const AddPaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 40px 40px 48px 40px;
  align-items: center;

  .error-text {
    font-size: 12px;
    margin-top: 6px;
    color: red;
  }

  .field-label {
    color: #73868c;
    font-size: 14px;
  }

  .popup-input {
    margin-bottom: unset;
    padding: 12px 15px;

    .error-info {
      border-color: red;
    }
  }

  .description-input {
    min-height: 100px;
    resize: none;
    outline: none;
    max-height: 200px;
  }

  .error-input {
    border: 1px solid red !important;
  }

  .opened {
    .select__value-container {
      .select__input-container {
        display: flex;
      }
    }
    .hide-single-value {
      display: none;
    }
  }
`;
export const SnoozeWorkitemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 48px 48px 48px;
  width: 456px;

  .workitem-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 14px 16px 16px 16px;
    background: #f4f9fb;
    border-radius: 8px;
  }

  .snooze-container {
    background: #ffff;
    padding: 4px;
  }
`;
export const RefundPaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 40px 48px 48px 48px;
  align-items: center;

  .details-container {
    display: flex;
    flex-direction: column;
    background: #f4f7f9;
    width: 100%;
    .hl-line {
      margin: 0px;
      color: #d2e5ed;
      width: 100%;
      height: 1px;
    }
  }
`;

export const DialogWrapper = styled.div`
  width: 450px;
  padding: 32px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn-border-radius {
    border-radius: 16px;
  }
`;

export const ReInstateLeadWrapper = styled.div`
  .lead-address {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .lead-description-container {
    width: 100%;
    heigth: 100%;
    display: flex;
    flex-direction: column;
    background: #f4f7f9;
    padding: 16px;
    margin: 24px 0px 0px 0px;
    border-radius: 12px;
  }
`;
export const ChangesAmountWrapper = styled.div`
  padding: 40px 48px 48px 48px;
  width: 456px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .payment-item-container {
    width: 100%;
    display: flex;
    background: #f4f7f9;
    padding: 8px 16px;
    border-radius: 8px;
  }

  .label-text {
    width: 80px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .provider-selector {
    width: 150px;
    background: #fff;
    border-radius: 8px;
    .options {
      top: 48px;
    }
    .selector {
      padding: 12px 16px;
      border-color: rgb(210, 229, 237);
      border-radius: 10px;
      height: 32px;
      min-height: 32px !important;
    }
    .input {
      padding: 12px 16px;
      height: 32px;
      min-height: 32px !important;
    }
  }

  .disabled-dropdown {
    cursor: not-allowed !important;
    background: transparent;
    pointer-events: none;
  }

  .options-select-container {
    min-width: 150px;

    .select__placeholder {
      color: #053149;
      opacity: 0.5;
      font-family: Roboto Regular;
      font-size: 16px;
    }

    .select__value-container {
      padding: 2px 0px 2px 6px;
    }

    .select__control {
      border-radius: 10px;
      border: 1px solid #d2e5ed;
      flex-wrap: nowrap;
      min-height: 30px;
      cursor: pointer;

      &:hover {
        background: #f4f7f9;
      }
    }

    .select__control--is-disabled {
      background: rgba(115, 134, 140, 0.05);
      cursor: not-allowed;
      pointer-events: auto;
    }

    .select__control--is-focused {
      border: 1px solid #31ddca;
      box-shadow: unset;
      flex-wrap: nowrap;
    }

    .select__single-value {
      color: #053149;
      font-size: 16px;
      font-family: Roboto Regular;
      margin: 0px 8px;
    }

    .select__clear-indicator,
    .select__indicator-separator {
      display: none;
    }

    .select__indicators {
      .select__indicator {
        padding: 4px;
        color: #053149;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    .select__menu {
      .select__menu-list {
        max-height: 150px;
      }
    }
  }
`;

export const CreateNftWrapper = styled.div`
  width: 424px;
  padding: 40px 40px 40px 40px;
`;

export const RemoveSchemaAttributeWrapper = styled.div`
  width: 424px;
  padding: 40px 40px 40px 40px;

  .name-wrapper {
    background: #F6F6F6;
    border-radius: 16px;
  }
`;

