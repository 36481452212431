import React, {useState} from 'react';
import HttpsRedirect from 'react-https-redirect';
import './App.scss';
import { Router } from './routes/router'
import { useDispatch, useSelector, useStore } from "react-redux";
import { init } from './store/features/reloadSlice'
import ReloadIcon from './assets/images/reload.svg';
import setUpInterceptor from './store/services/interceptor';
import { CommonStyleWrapper } from './styles/Common.styled';
import { ThemeProvider } from 'styled-components';
import { ThemePreferenceContextProvider, initialThemeState } from './context/ThemePreferenceContext';
import { dark, light } from './styles/theme/index';
import { useTranslation } from 'react-i18next';
import { getLanguage } from './helpers/i18n';
import { LanguageProvider } from './context/LanguageContext';

const themesMap = {
  light,
  dark,
};

const App = () => {
  const store = useStore()
  setUpInterceptor(store)
  const dispatch = useDispatch();
  const state = useSelector(state => state.reload);
  const [theme, setTheme] = useState(initialThemeState.theme);
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(getLanguage());

  const onLanguageChange = lang => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  return (
    <div className="App">
          <LanguageProvider value={{ onLanguageChange, language }}>
          <ThemePreferenceContextProvider value={{ theme, setTheme }}>

            <ThemeProvider theme={themesMap[theme]}>
            <CommonStyleWrapper />

            <HttpsRedirect>
              <Router />
              {state.updated && (
                    <div className="fixed new-version-popup flex justify-center pxy-6">
                        <div className="flex flex-column items-center card mt-20 pxy-12 nw-refresh-view">
                            <label className="bold-text font-24">New version available!</label>
                            <label className="regular-text font-16 mt-2">
                                Please refresh the page to get access to new platform updates
                            </label>
                            <img className="mt-10" src={ReloadIcon} />
                            <div
                                className="flex items-center justify-center mt-12 cursor nv-refresh-btn"
                                onClick={() => {
                                    state.serviceWorkerRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });
                                    dispatch(init());
                                    window.location.reload();
                                }}>
                                <label className="medium-text font-16 white-text">Refresh</label>
                            </div>
                        </div>
                    </div>
                )}
            </HttpsRedirect>
        </ThemeProvider>
        </ThemePreferenceContextProvider>
      </LanguageProvider>
    </div>
  );
}

export default App;