import React, { useEffect, useState, useCallback, useRef, useContext } from "react";

import { useSelector, useDispatch } from "react-redux";
import uuid from "react-uuid";
import { getTemplates } from "../../../store/features/templateSlice";
import { createAchievement } from "../../../store/features/campaignSlice";
import { addToast } from "../../../store/features/toastSlice";

import Button from "../../Elements/button/button";
import DropDown from "../../../components/McDropdown";
import DateView from "../../../components/Elements/DateView";

import "./style.scss";

const AddAchievementPopup = ({campaignId, cancel}) => {
    const ref = useRef();

    const dispatch = useDispatch();
    const { templates, loading } = useSelector(data => data.template);

    const types = [
        {
            id: 0,
            name: 'Rank',
            type: 'RANK'
        },
        {
            id: 1,
            name: 'Offset',
            type: 'OFFSET'
        },
        {
            id: 2,
            name: 'Pledge',
            type: 'PLEDGE'
        },
        {
            id: 3,
            name: 'Measure',
            type: 'MEASURE'
        }
    ]

    const periods = [
        {
            id: 0,
            name: 'Single TX',
            type: 'SINGLE'
        },
        {
            id: 1,
            name: 'CampaignDates',
            type: 'CAMPAIGN'
        },
        {
            id: 2,
            name: 'All Time',
            type: 'ALLTIME'
        }
    ]

    const [achievement, setAchievement] = useState({period: periods[0], type: types[0]})

    const save = async () => {
        if(!achievement.template) {
            dispatch(
                addToast({
                  error: true,
                  text: `Please select template`,
                  id: uuid(),
                }),
              );
        } else {
            const payload = {...achievement, template: {id: achievement.template.id}, achievement_type: achievement.type.type, use_history: achievement.period.type}
            const data = await dispatch(createAchievement({campaignId, achievement: payload}))
            cancel(data);
        }
    }

    const fetchTemplates  = useCallback((page, merge)  => {
        ref.current = page;
        dispatch(getTemplates({merge, page, search: '', size: 20}));
    }, [dispatch]);

    useEffect(() => {
        fetchTemplates(0, false);
    }, [fetchTemplates]);


    return (
        <div className='add-achievement-popup'>
            <div className='add-achievement-popup-content'>
                <label className='popup-title mb-6'>Add achievement</label>
            <div className="flex">
            <div className="flex-column flex-1">
                <label className="input-label">Name</label>
                <input className="input" value={achievement?.name || ''} placeholder="Enter name" onChange={e => setAchievement({...achievement, name: e.target.value})} />
                <div className="flex mt-6">
                    <div className="flex-column flex-1">
                        <label className="input-label">Start</label>
                        <DateView size="medium" value={achievement.effective?.start} setValue={o =>  setAchievement({...achievement, effective: {...achievement.effective, start: o}})}/>
                    </div>
                    <div className="flex-column flex-1 ml-6 end-date-selector">
                        <label className="input-label">End</label>
                        <DateView size="medium" value={achievement.effective?.end} setValue={o =>  setAchievement({...achievement, effective: {...achievement.effective, end: o}})}/>
                    </div>
                </div>
                <label className="input-label mt-6">Type</label>
                <DropDown 
                    size="large" 
                    placeholder="Select" 
                    selected={achievement.type}
                    setSelected={c => setAchievement({...achievement, type:c })}
                    options={types}
                />  
                {/* <label className="input-label mt-6">Period</label>
                <div className="period-items">
                    {periods.map(period => 
                        <div key={period.id} className={`flex items-center justify-center cursor period-item ${achievement.period?.id === period?.id && 'period-item-selected'}`} onClick={() => setAchievement({...achievement, period: period})}>
                            <label className="regular-text font-12">{period.name}</label>
                        </div>
                    )}
                </div> */}
                <div className="flex mt-6">
                    <div className="flex-column flex-1">
                        <label className="input-label">Minimum</label>
                        <input className="input w-full" value={achievement.min_amount} onChange={e => setAchievement({...achievement, min_amount: e.target.value})} />
                    </div>
                    <div className="flex-column flex-1 ml-6">
                        <label className="input-label">Maximum</label>
                        <input className="input w-full" value={achievement.max_amount} onChange={e => setAchievement({...achievement, max_amount: e.target.value})} />
                    </div>
                </div>
                <label className="input-label mt-6">Template</label>
                <DropDown 
                    size="large" 
                    placeholder="Select" 
                    selected={achievement.template}
                    setSelected={c => setAchievement({...achievement, template: c})}
                    options={templates.content}
                    isPaged={true}
                    hasMore={!templates.last}    
                    fetchData={() => fetchTemplates(ref.current + 1, true)}  
                />                        
            </div>
        </div>
        <div className="flex items-center justify-center mt-10">
            <div className="flex">
                <Button
                    className="secondary-hover"
                    label="Cancel"
                    borderRadius="16px"
                    onClick={cancel}
                    secondary={true}
                    size="large"
                    style={{ flex: 1 }}
                    bgColor='#FFFFFF'
                    borderColor="#24CBB1"
                    color="#24CBB1"
                    borderWidth="1px"
                    width="152px"
                />
                <Button
                    label='Add'
                    onClick={save}
                    size="large"
                    style={{ flex: 1, marginLeft: 24 }}
                    bgColor='#24CBB1'
                    borderColor="#24CBB1"
                    color='#FFFFFF'
                    borderRadius="16px"
                    borderWidth="1px"
                    disabled={false}
                    width="152px"
                />
            </div>
        </div>
            </div>
        </div>
    )
}

export default AddAchievementPopup;