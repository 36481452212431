import React, { useState, useEffect } from 'react'

import CloseIcon from '../../assets/images/close.svg'

import "../../pages/projectDetails/details.scss";

const DeleteMediaPopup = ({ close, deleteMedia }) => {

    const [name, setName] = useState('');
    const [active, setActive] = useState(false);

    const onClick = e => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            close()
        }
    }

    useEffect(() => {
        setActive(name.length > 2);
    }, [name]);

    return (
        <div className='add-media-group-popup' onClick={onClick}>
            <div className='add-media-group-content delete-media'>
                <div className="flex items-center justify-between">
                    <label className='dup-title'>Delete media</label>
                    <div className="flex items-center justify-center mr-12 close-btn cursor" onClick={close}>
                        <img className='' src={CloseIcon} alt="close" />
                    </div>
                </div>
                <label className="regular-text font-18 mt-6">Are you sure you want to delete this media?</label>
                <div className='dup-actions'>
                    <button onClick={close}>Cancel</button>
                    <button className='add-action ml-6' style={{background: '#FF8888'}} onClick={deleteMedia}>Delete</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteMediaPopup;