import ComingSoonIcon from '../../assets/images/coming-soon.svg';
import Edit from '../../assets/images/edit-image.svg';
import Plus from '../../assets/images/plus.svg';
import EmailIcon from '../../assets/images/email.svg';
import MobileIcon from '../../assets/images/mobile.svg';
import PhoneIcon from '../../assets/images/phone.svg';
import api from '../../store/services'
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import Button from '../../components/Elements/button/button';
import { addToast } from '../../store/features/toastSlice';
import { setSelectedTeam } from '../../store/features/settingsSlice';
import { ReactComponent as DotsIcon } from '../../assets/images/menu/dots.svg';

import ProfileWrapper from './profile.styled';
import ServiceSelectorMenu from './service-selector-menu';
import { OrganisationContext } from '../../context/organisationContext';
import Loading from '../../assets/images/loading.svg';
import { UsersContainerWrapper } from '../../components/settings/users/users.styled';
import Select from 'react-select';
import Selected from '../../assets/images/selected.svg';

const Profile = ({
  selectedUser,
  profileClassName = '',
  updateUserData,
  loadData = false,
  isSameTenant = () => {},
  fromTeamDetails = false,
}) => {
  const { user: loggedInUser } = useSelector(state => state.user);
  const user = selectedUser?.id ? { ...selectedUser, external_id: selectedUser.id } : loggedInUser;
  const isSelectedUser = selectedUser?.id;
  const [userData, setUserData] = useState(selectedUser);
  const [editInfo, setEditInfo] = useState({});
  const [editContactInfo, setEditContactInfo] = useState({});
  const [showAddService, setShowAddService] = useState(false);
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [userServices, setUserServices] = useState([]);
  const [serviceToAdd, setServiceToAdd] = useState({});
  const [loading, setLoading] = useState(loadData);
  const [apiLoading, setApiLoading] = useState(false);
  const [userServicesLoading, setUserServicesLoading] = useState(false);
  const [showServiceMenu, setShowServiceMenu] = useState('');
  const [showEditService, setShowEditService] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [error, setError] = useState({ service: false, daily_capacity: false });

  const contactRegex = /^[0-9\b]+$/;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modal, setModal, hasPermission } = useContext(OrganisationContext);

  useEffect(() => {
    const fetchUserRolesData = async () => {
        const { data } = await api.get(`/v1/organizations/${loggedInUser.organisations[0].external_id}/roles`);
        const userRolesOptions = data.map(userRole => {
          return { ...userRole, label: userRole.name, value: userRole.id };
        });
        setUserRoles(userRolesOptions);
    };

    fetchUserRolesData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get(`/v1/organizations/${user.organisations[0].external_id}/services`);
      const servicesOptions = data.map(service => {
        return { ...service, label: service.name, value: service.id };
      });
      setServices(servicesOptions);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setUserServicesLoading(true);
      const { data } = await api.get(`/v3/api/adminusers/${userData.id}/services`);
      const userServicesOptions = data.map(service => {
        return { ...service, label: service.name, value: service.id };
      });
      setUserServices(userServicesOptions);
      setUserServicesLoading(false);
    };
    if (userData?.id) {
      fetchData();
    }
  }, [userData?.id]);

  useEffect(() => {
    setFilteredServices(services.filter(service => !userServices.find(us => us.id === service.id)));
  }, [services, userServices]);

  const saveServices = async () => {
    try {
      if (!serviceToAdd?.service && (!serviceToAdd.daily_capacity || !(serviceToAdd.daily_capacity > 0))) {
        setError({ service: true, daily_capacity: true });
        dispatch(addToast({ error: true, text: 'Please fill service and daily capacity' }));
        return;
      } else if (!serviceToAdd?.service) {
        setError({ ...error, service: true });
        dispatch(addToast({ error: true, text: 'Please select service' }));
        return;
      } else if (!serviceToAdd.daily_capacity || !(serviceToAdd.daily_capacity > 0)) {
        setError({ ...error, daily_capacity: true });
        dispatch(addToast({ error: true, text: 'Please enter valid daily capacity' }));
        return;
      }
      setApiLoading(true);
      const previousServices = userServices.map(service => ({
        service: { id: service.id },
        daily_capacity: service.daily_capacity,
      }));
      const request = serviceToAdd.isEdit
        ? previousServices.map(s => ({
            ...s,
            daily_capacity: s.service.id === serviceToAdd.service.id ? serviceToAdd.daily_capacity : s.daily_capacity,
          }))
        : [
            ...previousServices,
            { service: { id: serviceToAdd.service.id }, daily_capacity: serviceToAdd.daily_capacity },
          ];
      await api.put(`/v3/api/adminusers/${userData.id}/services`, request);
      const updatedUserServices = serviceToAdd.isEdit
        ? userServices.map(us => ({
            ...us,
            daily_capacity: us.id === serviceToAdd.service.id ? serviceToAdd.daily_capacity : us.daily_capacity,
          }))
        : [...userServices, { ...serviceToAdd.service, daily_capacity: serviceToAdd.daily_capacity }];
      setUserServices([...updatedUserServices]);
      setShowAddService(false);
      setShowEditService(false);
      setServiceToAdd({});
      setApiLoading(false);
      dispatch(
        addToast({
          error: false,
          text: serviceToAdd.isEdit ? 'Service updated successfully' : 'Service added successfully',
          id: uuid(),
        }),
      );
    } catch (error) {
      dispatch(
        addToast({
          error: true,
          text: serviceToAdd.isEdit ? 'Failed to update servie' : 'Failed to added servie',
          id: uuid(),
        }),
      );
      setApiLoading(false);
    }
  };

  const getContactInfo = (userData, type) => {
    if (!userData.contact_info) return '';
    const info = userData.contact_info.find(contactInfo => contactInfo.contact_type === type) || {};
    return info;
  };

  const getTeams = () => {
    return userData.organisations?.filter(org => org.organisation_type === 'TEAM') || [];
  };

  const onProfileSave = async () => {
    const request = {};
    const names = editInfo.userName.split(' ');
    request.forename = names[0];
    request.surname = names[1];
    request.is_active = userData.is_active;
    try {
      if (request.forename && request.surname) {
        await api.put(`/v1/users/${userData.id}`, request);
        if (hasPermission('USERS_MANAGE')) {
          await api.put(`/v1/users/${userData.id}/roles/${editInfo.userRole.id}`);
        }
        setUserData({ ...userData, name: editInfo.userName, userRole: editInfo.userRole });
        setEditInfo({ hoverProfile: true });
        dispatch(
          addToast({
            error: false,
            text: 'Profile updated successully',
            id: uuid(),
          }),
        );
        if (isSelectedUser) updateUserData({ ...selectedUser, name: editInfo.userName, userRole: editInfo.userRole });
      }
    } catch (e) {
      dispatch(
        addToast({
          error: true,
          text: 'Error while updating user profile',
          id: uuid(),
        }),
      );
    }
  };

  const onContactSave = async () => {
    const newContact = [];
    let userContactInfos = [...userData.contact_info];
    if (editContactInfo.landlineValue?.length > 0 && editContactInfo?.landlineValue?.length !== 11) {
      dispatch(
        addToast({
          error: true,
          text: 'Please enter valid landline number',
          id: uuid(),
        }),
      );
      return;
    }
    if (editContactInfo.mobileValue?.length > 0 && editContactInfo.mobileValue?.length !== 11) {
      dispatch(
        addToast({
          error: true,
          text: 'Please enter valid mobile number',
          id: uuid(),
        }),
      );
      return;
    }
    if (editContactInfo.landline?.id) {
      await api.put(`/v3/api/adminusers/${userData.id}/contact_infos/${editContactInfo.landline.id}`, {
        contact_type: 'LANDLINE',
        contact_value: editContactInfo.landlineValue,
      });
      userContactInfos = userContactInfos.map(contact =>
        contact.contact_type === 'LANDLINE' ? { ...contact, contact_value: editContactInfo.landlineValue } : contact,
      );
    } else {
      newContact.push({
        contact_type: 'LANDLINE',
        contact_value: editContactInfo.landlineValue || '',
      });
    }

    if (editContactInfo.mobile?.id) {
      await api.put(`/v3/api/adminusers/${userData.id}/contact_infos/${editContactInfo.mobile.id}`, {
        contact_type: 'MOBILE',
        contact_value: editContactInfo.mobileValue,
      });
      userContactInfos = userContactInfos.map(contact =>
        contact.contact_type === 'MOBILE' ? { ...contact, contact_value: editContactInfo.mobileValue } : contact,
      );
    } else {
      newContact.push({
        contact_type: 'MOBILE',
        contact_value: editContactInfo.mobileValue || '',
      });
    }

    if (newContact.length > 0) {
      const { data } = await api.post(`/v3/api/adminusers/${userData.id}/contact_infos`, newContact);
      userContactInfos = [...userContactInfos, ...data];
    }

    setUserData({ ...userData, contact_info: [...userContactInfos] });
    setEditContactInfo({ hoverContact: true });
    dispatch(
      addToast({
        error: false,
        text: 'Profile updated successully',
        id: uuid(),
      }),
    );
    if (isSelectedUser) updateUserData({ ...selectedUser, contact_info: [...userContactInfos] });
  };

  const onTeamClick = organisation => {
    if (!fromTeamDetails) {
      dispatch(setSelectedTeam(organisation));
      localStorage.setItem('selected-team', JSON.stringify(organisation));
      navigate('/settings/security/teams');
    }
  };
  console.log(selectedUser)
  const onEditService = service => {
    setServiceToAdd({ service: { ...service }, daily_capacity: service.daily_capacity, isEdit: true });
    setShowAddService(false);
    setShowEditService(true);
    setShowServiceMenu('');
  };

  const onRemoveService = service => {
    setServiceToAdd({});
    setShowAddService(false);
    setShowEditService(false);
    setModal({ type: 'service-remove', content: { service, userServices, userData, top: true } });
    setShowServiceMenu('');
  };

  useEffect(() => {
    if (modal.type === 'service-remove-success') {
      setUserServices(userServices.filter(s => s.id !== modal.content.serviceToRemove.id));
    }
  }, [modal]);

  const renderAddService = () => {
    return (
      <div className="service-item pxy-4 add-service">
        <div className="mb-4">
          <Select
            className={`options-select-container ${error.service && 'error-border'}`}
            classNamePrefix="select"
            isDisabled={serviceToAdd.isEdit}
            onChange={option => {
              setServiceToAdd({ ...serviceToAdd, service: option });
              setError({ ...error, service: false });
            }}
            onMenuClose={() => setDropdownIsOpen(false)}
            onMenuOpen={() => setDropdownIsOpen(true)}
            options={serviceToAdd.isEdit ? [] : filteredServices}
            placeholder="Select service"
            value={serviceToAdd.service || null}
          />
        </div>
        <div className="flex items-center justify-between mb-6">
          <label className="regular-text font-14 profile-info-label">Daily capacity:</label>
          <div>
            <input
              className={`regular-text popup-input form-input capacity-input ${error.daily_capacity && 'error-input'}`}
              min="1"
              onChange={e => {
                setServiceToAdd({ ...serviceToAdd, daily_capacity: e.target.value });
                setError({ ...error, daily_capacity: false });
              }}
              placeholder="0"
              type={'number'}
              value={serviceToAdd.daily_capacity || ''}
            />
          </div>
        </div>
        <div className="flex mb-2 add-service-action">
          <Button
            className="secondary-hover w-full"
            label="Cancel"
            onClick={() => {
              setServiceToAdd({});
              setShowEditService(false);
              setShowAddService(false);
            }}
            secondary={true}
            size="medium"
          />
          <Button
            className={`w-full ${!apiLoading && 'primary-hover'}`}
            disabled={apiLoading}
            label={showEditService ? 'Update' : 'Add'}
            onClick={() => saveServices()}
            primary={true}
            size="medium"
          />
        </div>
      </div>
    );
  };

  const RoleValueOption = props => {
    const onSelect = () => {
      props.setValue({ ...props.data });
    };
    return (
      <div className={`tpus-user ${props.isSelected && 'tpus-user-selected'}`} onClick={() => onSelect()}>
        <label className={`regular-text`}>{props.label}</label>
        {props.isSelected && <img alt="icon" src={Selected} />}
      </div>
    );
  };

  return (
    <ProfileWrapper className={profileClassName}>
      {!isSelectedUser && <label className="black-text">My Profile</label>}
      <div className="flex">
        {loading ? (
          <div className="flex items-center justify-center content-loader">
            <img alt="loading" height="64px" src={Loading} />
          </div>
        ) : (
          <>
            <div className="profile-left">
              <div className="card profile-left-content" style={{ overflow: dropdownIsOpen ? 'hidden' : 'auto' }}>
                <div
                  className="user-info-top"
                  onMouseEnter={() => !editInfo.profileEdit && setEditInfo({ hoverProfile: true })}
                  onMouseLeave={() => !editInfo.profileEdit && setEditInfo({ hoverProfile: false })}>
                  <div className="mb-4 justify-between items-center" style={{ height: '16px' }}>
                    <label className="sub-label bold-text">{isSelectedUser ? 'User' : 'My'} Profile</label>
                    {((hasPermission('USERS_MANAGE') &&
                      (!fromTeamDetails || (fromTeamDetails && isSameTenant()))) ||
                      !isSelectedUser) &&
                      editInfo.hoverProfile && (
                        <div
                          className="menu-item flex items-center cursor"
                          onClick={() => {
                            setEditInfo({
                              profileEdit: true,
                              userName: userData.name,
                              userRole: {
                                ...userData.role,
                                label: userData.role.name,
                                value: userData.role.id,
                              },
                            });
                            setEditContactInfo({ hoverContact: false });
                          }}>
                          <img alt="edit" className="mx-2" src={Edit} />
                        </div>
                      )}
                    {editInfo.profileEdit && (
                      <div className="flex">
                        <Button
                          className="secondary-hover mr-4 edit-profile-action"
                          label="Cancel"
                          onClick={() => setEditInfo({ hoverProfile: true })}
                          secondary={true}
                          size="small"
                        />
                        <Button
                          className="primary-hover edit-profile-action"
                          label="Save"
                          onClick={() => onProfileSave()}
                          primary={true}
                          size="small"
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    {editInfo.profileEdit ? (
                      <input
                        className="popup-input form-input profile-input"
                        onChange={e =>
                          setEditInfo({
                            ...editInfo,
                            userName: e.target.value,
                          })
                        }
                        placeholder="Name"
                        size="10"
                        value={editInfo?.userName || ''}
                      />
                    ) : (
                      <label className="bold-text org-name font-16">{userData.name}</label>
                    )}
                  </div>
                  {/* <p className="my-2 regular-text flex">
                    <label className="profile-info-label">Organisation: </label>
                    <label className="ml-1">{userData.organisationsNames}</label>
                  </p> */}
                  <p className="regular-text flex">
                    <label className="profile-info-label flex items-center">Role: </label>
                    <div className="w-full">
                      {editInfo.profileEdit && hasPermission('USERS_MANAGE') ? (
                        <Select
                          className="options-select-container ml-2"
                          classNamePrefix="select"
                          components={{ Option: RoleValueOption }}
                          onChange={option =>
                            setEditInfo({
                              ...editInfo,
                              userRole: option,
                            })
                          }
                          onMenuClose={() => setDropdownIsOpen(false)}
                          onMenuOpen={() => setDropdownIsOpen(true)}
                          options={userRoles}
                          placeholder="Select Role"
                          value={editInfo.userRole || null}
                        />
                      ) : (
                        <label className="ml-1">{selectedUser.role?.name || ''}</label>
                      )}
                    </div>
                  </p>
                </div>
                <div
                  className="user-infos"
                  onMouseEnter={() => !editContactInfo.contactEdit && setEditContactInfo({ hoverContact: true })}
                  onMouseLeave={() => !editContactInfo.contactEdit && setEditContactInfo({ hoverContact: false })}>
                  <div className="mb-4 justify-between items-center" style={{ height: '16px' }}>
                    <label className="sub-label bold-text">Contact info</label>
                    {((hasPermission('USERS_MANAGE') &&
                      (!fromTeamDetails || (fromTeamDetails && isSameTenant()))) ||
                      !isSelectedUser) &&
                      editContactInfo.hoverContact && (
                        <div
                          className="menu-item flex items-center cursor"
                          onClick={() => {
                            setEditContactInfo({
                              contactEdit: true,
                              landline: getContactInfo(userData, 'LANDLINE'),
                              landlineValue: getContactInfo(userData, 'LANDLINE').contact_value,
                              mobile: getContactInfo(userData, 'MOBILE'),
                              mobileValue: getContactInfo(userData, 'MOBILE').contact_value,
                            });
                            setEditInfo({ hoverProfile: false });
                          }}>
                          <img alt="edit" className="mx-2" src={Edit} />
                        </div>
                      )}
                    {editContactInfo.contactEdit && (
                      <div className="flex">
                        <Button
                          className="secondary-hover mr-4 edit-profile-action"
                          label="Cancel"
                          onClick={() => setEditContactInfo({ hoverContact: true })}
                          secondary={true}
                          size="small"
                        />
                        <Button
                          className="primary-hover edit-profile-action"
                          label="Save"
                          onClick={() => onContactSave()}
                          primary={true}
                          size="small"
                        />
                      </div>
                    )}
                  </div>
                  <div className="user-info">
                    <div className="icon-container">
                      <img alt="icon" src={PhoneIcon} />
                    </div>
                    <div className="w-full ml-2">
                      {editContactInfo.contactEdit ? (
                        <input
                          className="popup-input form-input profile-input"
                          onChange={e => {
                            if (e.target.value === '' || contactRegex.test(e.target.value.replace(/\s+/g, ''))) {
                              setEditContactInfo({
                                ...editContactInfo,
                                landlineValue: e.target.value.replace(/\s+/g, ''),
                              });
                            }
                          }}
                          placeholder="Landline number"
                          value={editContactInfo?.landlineValue || ''}
                        />
                      ) : (
                        <label
                          className={`regular-text ${
                            !getContactInfo(userData, 'LANDLINE').contact_value && 'not-provided-label'
                          }`}>
                          {getContactInfo(userData, 'LANDLINE').contact_value || 'Not provided'}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="user-info">
                    <div className="icon-container">
                      <img alt="icon" src={MobileIcon} />
                    </div>
                    <div className="w-full ml-2">
                      {editContactInfo.contactEdit ? (
                        <input
                          className="popup-input form-input profile-input"
                          onChange={e => {
                            if (e.target.value === '' || contactRegex.test(e.target.value.replace(/\s+/g, ''))) {
                              setEditContactInfo({
                                ...editContactInfo,
                                mobileValue: e.target.value.replace(/\s+/g, ''),
                              });
                            }
                          }}
                          placeholder="Mobile number"
                          value={editContactInfo?.mobileValue || ''}
                        />
                      ) : (
                        <label
                          className={`regular-text ${
                            !getContactInfo(userData, 'MOBILE').contact_value && 'not-provided-label'
                          }`}>
                          {getContactInfo(userData, 'MOBILE').contact_value || 'Not provided'}
                        </label>
                      )}
                    </div>
                  </div>
                  {/* <div className="user-info">
                    <div className="icon-container">
                      <img alt="icon" src={EmailIcon} />
                    </div>
                    <label
                      className={`ml-2 w-full regular-text ${
                        selectedUser.email && 'not-provided-label'
                      }`}>
                      {selectedUser.email || 'Not provided'}
                    </label>
                  </div> */}
                </div>
                <div className={`flex-column profile-service-container ${!getTeams().length && 'bottom-border-unset'}`}>
                  <div className="flex mb-4 items-center justify-between" style={{ height: '16px' }}>
                    <label className="sub-label bold-text">SERVICES</label>
                    {((hasPermission('USERS_MANAGE') &&
                      (!fromTeamDetails || (fromTeamDetails && isSameTenant()))) ||
                      !isSelectedUser) &&
                      !userServicesLoading &&
                      filteredServices?.length > 0 &&
                      !serviceToAdd?.isEdit && (
                        <div
                          className="menu-item flex items-center cursor add-service-icon"
                          onClick={() => setShowAddService(true)}>
                          <img alt="plus" src={Plus} />
                        </div>
                      )}
                  </div>
                  {showAddService && renderAddService()}
                  {userServices.length === 0 ? (
                    <div className="flex items-center justify-between service-item pxy-4">
                      <div className="flex-column">
                        <label className="medium-text font-16 profile-info-label">No service</label>
                      </div>
                    </div>
                  ) : (
                    userServices.map(service => (
                      <>
                        {showEditService && service.id === serviceToAdd?.service?.id ? (
                          renderAddService()
                        ) : (
                          <div className="flex items-center justify-between service-item pxy-4">
                            <div className="flex-column">
                              <label className="medium-text font-16">{service.name}</label>
                              <div className="flex mt-1">
                                <label className="regular-text profile-info-label mr-1">{`Daily capacity:`}</label>
                                <label className="regular-text profile-info-label">
                                  {service.daily_capacity || 'Not available'}
                                </label>
                              </div>
                            </div>
                            {((hasPermission('USERS_MANAGE') &&
                              (!fromTeamDetails || (fromTeamDetails && isSameTenant()))) ||
                              !isSelectedUser) && (
                              <div
                                className="relative cursor mr-1 menu-hover-reverse py-1 px-2"
                                onBlur={() => setShowServiceMenu(null)}
                                tabIndex={0}>
                                <DotsIcon
                                  className="survey-menu-icon"
                                  onClick={e => {
                                    e.stopPropagation();
                                    setShowServiceMenu(showServiceMenu ? null : service.id);
                                  }}
                                />
                                {showServiceMenu === service.id && (
                                  <div className="relative">
                                    <ServiceSelectorMenu
                                      onEdit={onEditService}
                                      onRemove={onRemoveService}
                                      service={service}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ))
                  )}
                </div>
                {getTeams().length > 0 && (
                  <>
                    <div className="user-infos bottom-border-unset">
                      <label className="sub-label mb-4 bold-text">Teams</label>
                      <div className="user-teams">
                        {getTeams().map(organisation => (
                          <div className="flex-column team-container">
                            <div className="flex-column team-data cursor" onClick={() => onTeamClick(organisation)}>
                              <label className="bold-text team-name">{organisation.name}</label>
                              <hr />
                              <label className="regular-text mt-4">
                                Role: <span className="bold-text">{organisation.roles[0].name}</span>
                              </label>
                              <label className="regular-text profile-info-label mt-2">
                                Joined: {moment.unix(organisation.join_date).format('DD/MM/YY')}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="profile-right">
              <label className="black-text">{isSelectedUser ? 'User' : 'My'} details</label>
              <div className="flex-column flex-1 items-center justify-center">
                <img alt="icon" src={ComingSoonIcon} />
                <label className="regular-text coming-soon-label mt-4">Coming soon</label>
              </div>
            </div>
          </>
        )}
      </div>
    </ProfileWrapper>
  );
};

export default Profile;
