import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getInventories } from '../../store/features/inventorySlice';
import CarbonListItem from "./CarbonListItem";
import Loading from "../../assets/images/loading.svg";

import "../../pages/projectDetails/details.scss";

const CarbonList = ({project, setEdit}) => {
    const dispatch = useDispatch();
    const { inventories, loading } = useSelector(data => data.inventory);

    useEffect(() => {
        if(project)
            dispatch(getInventories({
                    page: 0, 
                    projectId: project.id}));
    }, [project]);

    return (
        <div className="pb-6">
        {loading ?
            <div className="flex flex-1 items-center justify-center mt-4">
                <img src={Loading} alt="loading" />
                <label className="bold-text font-16 ml-2 light-purple-text">
                    Loading
                </label>
            </div>
        :

        <div className="flex-column carbon-items border-top cursor">
            {inventories.content?.map(carbon => <CarbonListItem key={carbon.id} carbon={carbon} setEdit={setEdit}/> )}
        </div>
        }
    </div>
    )
}

export default CarbonList;
