import InviteIcon from '../../assets/images/invite-icon.svg';
import ArrowRightIcon from '../../assets/images/menu/arrow-right-black.svg';
import React, { useContext, useRef, useState, useEffect } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/images/search-icon.svg';
import { OrganisationContext } from '../../context/organisationContext';
import Pagination from '../../components/pagination/pagination';
import IntegrationWrapper from './integration.styled';
import IntegrationItem from './integrationItem';
import api from '../../store/services'
import SelectedIntegration from './selected-integration';
import Loading from '../../assets/images/loading.svg';
import useDebounce from '../../helpers/useDebounceHook';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import { getItemFromLocalStorage, setItemInLocalStorage } from '../../helpers/localstorage';

const Integration = () => {
  const { setModal, modal, hasPermission, checkPermission } = useContext(OrganisationContext);

  const [selectedPage, setSelectedPage] = useState(getItemFromLocalStorage('integration-page') || 0);
  const [totalPage, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [integrations, setIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState(getItemFromLocalStorage('selected-integration') || {});
  const [loading, setLoading] = useState(false);
  const [focusedInput, setFocusedInput] = useState(false);

  const debouncedSearchText = useDebounce(searchText, 500);
  const previousSearchTextRef = useRef('');
  const [initPage, setInitPage] = useState(selectedPage);

  const fetchData = async () => {
    setLoading(true);
    const { data } = await api.get(`/v3/api/integrations?page=${selectedPage}&size=11&search=${searchText}`);
    setIntegrations(data);
    setTotalPages(data.total_pages);
    setLoading(false);
  };

  useEffect(() => {
    if (previousSearchTextRef.current !== debouncedSearchText) {
      if (debouncedSearchText === '') {
        setSelectedPage(initPage);
      } else {
        setSelectedPage(0);
      }
      previousSearchTextRef.current = debouncedSearchText;
    } else {
      if (previousSearchTextRef.current === '' && debouncedSearchText === '') {
        setInitPage(selectedPage);
      }
    }
    fetchData();
  }, [selectedPage, debouncedSearchText]);

  useEffect(() => {
    if (modal.type === 'integration-success') {
      if (modal.content.isDelete) {
        if (integrations.length === 1 && selectedPage > 0) {
          setItemInLocalStorage('integration-page', selectedPage - 1);
          setSelectedPage(selectedPage - 1);
        } else {
          fetchData();
        }
      } else if (modal.content.isDisable) {
        setIntegrations([
          ...integrations.map(item =>
            item.id === modal.content.integration_id ? { ...item, is_enabled: false } : item,
          ),
        ]);
      } else {
        onIntegrationSelect(modal.content.integration);
        fetchData();
      }
    }
  }, [modal.type]);

  const updateIntegration = updatedIntegration => {
    setIntegrations([...integrations.map(intg => (intg.id === updatedIntegration.id ? updatedIntegration : intg))]);
  };

  const handleBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFocusedInput(false);
    }
  };

  const onIntegrationSelect = integration => {
    setItemInLocalStorage('selected-integration', integration);
    setSelectedIntegration(integration);
  };

  return (
    <IntegrationWrapper>
      {selectedIntegration.id ? (
        <SelectedIntegration
          hasPermission={hasPermission}
          selectedIntegration={selectedIntegration}
          setSelectedIntegration={onIntegrationSelect}
          updateIntegration={updateIntegration}
        />
      ) : (
        <>
          <div className="integration-container">
            <div className="flex items-center">
              <label className="regular-text grey-text hover-underline">Platform</label>
              <img className="mx-2" src={ArrowRightIcon} />
              <label className="bold-text">Integrations</label>
            </div>
            <div className="flex">
              <div
                className="user-action"
                onClick={() =>
                  checkPermission(
                    () =>
                      setModal({
                        type: 'add-integration',
                      }),
                    'INTEGRATIONS_MANAGE',
                  )
                }
                style={{ opacity: hasPermission('INTEGRATIONS_MANAGE') ? 1 : 0.5 }}>
                <img alt="icon" className="user-action-icon" src={InviteIcon} />
                <p className="medium-text">Add new</p>
              </div>

              <div
                className={`integration-search mr-7 ${focusedInput ? 'search-focused' : ''}`}
                onBlur={e => handleBlur(e)}
                onFocus={() => setFocusedInput(true)}>
                <SearchIcon className="search-icon" />
                <input
                  className="regular-text"
                  onChange={e => setSearchText(e.target.value)}
                  placeholder="Search"
                  value={searchText || ''}
                />
                <div
                  className={`close ${focusedInput ? 'close-focused' : ''}`}
                  onClick={e => {
                    setSearchText('');
                  }}
                  tabIndex="0">
                  {focusedInput && <CloseIcon className="close-icon" />}
                </div>
              </div>
              {/* <Pagination
                className="integration-pagination"
                selectedPage={selectedPage}
                setSelectedPage={page => {
                  setSelectedPage(page);
                  setItemInLocalStorage('integration-page', page);
                }}
                totalPages={totalPage}
              /> */}
            </div>
          </div>
          {loading ? (
            <div className="flex items-center justify-center content-loader">
              <img alt="loading" height="64px" src={Loading} />
            </div>
          ) : (
            <div className="integrations scroller">
              {integrations?.map((integration, key) => (
                <IntegrationItem
                  checkPermission={checkPermission}
                  hasPermission={hasPermission}
                  integration={integration}
                  setSelectedIntegration={onIntegrationSelect}
                  updateIntegration={updateIntegration}
                />
              ))}
            </div>
          )}
        </>
      )}
    </IntegrationWrapper>
  );
};

export default Integration;
