import React from "react";

import Image from "../../image";
import EditDeleteMenu from "../../Elements/EditDeleteMenu";
import { useNavigate } from 'react-router-dom';

import './style.scss';

const Template = ({collection, template}) => {
    const navigate = useNavigate();
    return (
        <div className="template border-bottom" onClick={() => navigate(`/meta/templates/${template.id}/attributes`, {state: {collection: collection}})}>
            <div className="flex items-center px-6 py-1 my-1 border-right one-line">
                <Image className={"collection-template-image"} size={58} media={template?.image?.media} />
                <label className="regular-text ml-2 one-line">{template.name}</label>
            </div>
            <div className="flex border-right px-6 py-5 my-1 ">
                <label className="regular-text light-purple-text two-lines my-1">{template.description}</label>
            </div>
            <div className="flex items-center justify-between px-6 py-5 my-1">
                <div className="flex-column">
                    <label className="regular-text font-12 light-purple-text">Schema:</label>
                    <label className="regular-text mt-2">{template.schema?.name}</label>
                </div>
                <EditDeleteMenu />
            </div>
        </div>
    )
}

export default Template;
