import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  getTemplateAttributes,
  updateTemplateAttributes,
} from "../../../store/features/templateSlice";
import "./style.scss";
import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";

import "./style.scss";
import Button from "../../Elements/button/button";
import TemplateAttributesEdit from "./TemplateAttributeEdit";
import { addToast } from "../../../store/features/toastSlice";

const TemplateAttributes = ({ template, menu }) => {
  const dispatch = useDispatch();
  const { templateAttributes } = useSelector((data) => data.template);
  const [edit, setEdit] = useState(false);
  const [templateAttributesState, setTemplateAttributesState] = useState([]);

  useEffect(() => {
    dispatch(getTemplateAttributes(template.id));
  }, [template]);

  useEffect(() => {
    setTemplateAttributesState(templateAttributes);
  }, [templateAttributes]);

  const onSave = () => {
    const isErrorOfSchema = templateAttributesState.filter(ta => ta.isNew && !ta.schema_attribute?.id).length
    if (isErrorOfSchema) {
        dispatch(
            addToast({
              error: true,
              title: 'New Attribute Error',
              text: `Please select schema in new attributes`,
              id: 'attribute-error',
            }),
          );
        return;
    }
    const request = templateAttributesState.map((att, index) => ({
      id: att.isNew ? null : att.id,
      default_value: att.default_value,
      schema_attribute: att.schema_attribute?.id ? { id: att.schema_attribute?.id } : null,
    }));
    dispatch(
      updateTemplateAttributes({ id: template.id, attributes: request })
    );
    setEdit(false);
  };

  return (
    <div className="flex-column card">
      <div className="flex items-center justify-between px-6 py-5 border-bottom">
        <label className="bold-text font-20">Attributes</label>

        <div className="flex">
          {edit ? (
            <div className="flex justify-center mr-4">
              <Button
                className="secondary-hover"
                label="Cancel"
                borderRadius="12px"
                onClick={() => setEdit(false)}
                secondary={true}
                size="medium"
                style={{ flex: 1 }}
                bgColor="#FFFFFF"
                borderColor="#24CBB1"
                color="#24CBB1"
                borderWidth="1px"
                width="110px"
              />
              <Button
                label="Save"
                onClick={() => onSave()}
                size="medium"
                style={{ flex: 1, marginLeft: 24 }}
                bgColor="#24CBB1"
                borderColor="#24CBB1"
                color="#FFFFFF"
                borderRadius="12px"
                borderWidth="1px"
                width="110px"
              />
            </div>
          ) : (
            <div
              className="flex items-center justify-center cursor mr-6 no-select schema-edit-btn"
              onClick={() => {
                setTemplateAttributesState(templateAttributes);
                setEdit(true);
              }}
            >
              <EditIcon />
              <label className="semibold-text font-12 mid-green-text ml-1">
                Edit
              </label>
            </div>
          )}
          {menu}
        </div>
      </div>
      <div className="flex-column template-attributes">
        {edit ? (
          <TemplateAttributesEdit
            templateAttributes={templateAttributesState}
            setTemplateAttributes={setTemplateAttributesState}
            template={template}
          />
        ) : (
          templateAttributes.map((ta) => (
            <div key={ta.id} className="flex-column border-bottom px-6 py-4">
              <label className="regular-text font-12 light-purple-text">
                {ta.schema_attribute?.attribute_name}
              </label>
              <label className="regular-text mt-2">{ta.default_value}</label>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default TemplateAttributes;
