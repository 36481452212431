import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getAttributes } from '../../store/features/nftSlice';
import Loading from "../../assets/images/loading.svg";


const NFTAttributes = ({nft}) => {
    const dispatch = useDispatch();
    const { attributes, loading } = useSelector(data => data.nft);

    useEffect(() => {
        dispatch(getAttributes(nft.id));
    }, [nft]);

    return (
        <div className="flex-column">
               {loading ? 
                <div className="flex flex-1 items-center justify-center mt-4">
                    <img src={Loading} />
                    <label className="bold-text font-16 ml-2 light-purple-text">
                        Loading
                    </label>
                </div>
                :
                <div className='mt-6 border-top'>
                    {attributes.map(attribute => 
                    <div className="flex  border-bottom" key={attribute.id}> 
                        <div className='flex-column border-right' style={{width: "200px"}}>
                            <div className='pl-6 py-3'>
                                <label className='regular-text font-16 light-purple-text'>{attribute.name}</label>
                            </div>
                        
                        </div>
                        <div className='flex-column flex-1'>
                            <div className='pl-6 py-3'>
                                <label className='regular-text font-16'>{attribute.attribute_value || '-'}</label>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            }
        </div>
    )
}

export default NFTAttributes;
