import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import Image from "../../image";
import Button from "../../Elements/button/button";
import DateView from "../../Elements/DateView";
import DropDown from "../../McDropdown";
import { getNftLifecycleColor } from "../../../helpers/utils";

import CopyIcon from '../../../assets/images/copy-green.svg'

import './style.scss'

const EditCampaignNftDetails = ({nft, cancel, save}) => {

    const headlineRef = useRef(null);

    // useEffect(() => {
    //     if(headlineRef.current) {
    //         headlineRef.current.style.height = '0px';
    //         const scrollHeightHeadline = headlineRef.current.scrollHeight;
    //         headlineRef.current.style.height = scrollHeightHeadline + 5 + 'px';
    //     }
    // }, [campaign?.description, edit]);

    return(
        <div className="flex-column pxy-6">
        <label className="input-label">Name</label>
        {/* <input className="input" value={nft?.name} onChange={e => dispatch(setCampaign({...campaign, name: e.target.value}))} />
        <label className="input-label mt-6">Headline</label>
        <textarea className="textarea" value={nft?.description} ref={headlineRef} onChange={e => dispatch(setCampaign({...campaign, description: e.target.value}))} />
        <div className="flex mt-6">
            <div className="flex-column flex-1">
                <label className="input-label">Start</label>
                <DateView size="medium" value={campaign.effective?.start} setValue={o =>  dispatch(setCampaign({...campaign, effective: {...campaign.effective, start: o}}))}/>
            </div>
            <div className="flex-column flex-1 ml-6">
                <label className="input-label">End</label>
                <DateView size="medium" value={campaign.effective?.end} setValue={o =>  dispatch(setCampaign({...campaign, effective: {...campaign.effective, end: o}}))}/>
            </div>
        </div>
        <label className="input-label mt-6">Blockchain</label>
        <DropDown 
            size="large" 
            placeholder="Select" 
            selected={campaign.integration}
            setSelected={c => dispatch(setCampaign({...campaign, integration: c, collection: null}))}
            options={integrations}
        />                        
        <label className="input-label mt-6">Blockchain receiver</label>
        <input className="input" placeholder="Enter" value={campaign?.account} onChange={e => dispatch(setCampaign({...campaign, account: e.target.value}))} /> */}
        <div className="flex justify-center mt-10">
            <Button
                className="secondary-hover"
                label="Cancel"
                borderRadius="12px"
                onClick={cancel}
                secondary={true}
                size="average"
                style={{ flex: 1 }}
                bgColor='#FFFFFF'
                borderColor="#24CBB1"
                color="#24CBB1"
                borderWidth="1px"
            />
            <Button
                label='Done'
                onClick={save}
                size="average"
                style={{ flex: 1, marginLeft: 24 }}
                bgColor='#24CBB1'
                borderColor="#24CBB1"
                color='#FFFFFF'
                borderRadius="12px"
                borderWidth="1px"
            />
        </div>
    </div>
    )
}

export default EditCampaignNftDetails