import styled from 'styled-components';

const AutomationLogViewWrapper = styled.div`
  .automation-log-view {
    display: flex;
    // column-gap: 20px;
    overflow-y: none;
    height: calc(100vh - 88px);

    .left-panel {
      overflow-y: auto;
      height: calc(100vh - 88px);
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      max-width: 450px;
      min-width: 400px;
      padding-right: 16px;
      padding-top: 10px;

      hr {
        margin: 0;
      }

      .log-details,
      .event-details {
        background: #ffffff;
        box-shadow: 0px 5px 30px rgba(9, 19, 53, 0.05);
        // box-shadow: 0px 5px 30px rgb(9 19 53 / 5%);
        border-radius: 8px;

        .header-text {
          padding: 12px 16px 12px 16px;
        }

        .details-text {
          padding: 20px 16px 12px 16px;

          .info {
            display: grid;
            grid-template-columns: 0.2fr 1fr;
            margin-bottom: 16px;

            .status {
              font-size: 12px;
              padding: 2px 4px;
              color: #ffffff;
              border-radius: 4px;
            }
          }
        }
      }
    }

    .right-panel {
      max-height: calc(100vh - 88px);
      overflow-y: auto;
      padding: 10px 16px 0 16px;

      .no-action-container {
        background: #ffffff;
        box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
        border-radius: 8px;
        height: 100%;
        width: 100%;

        .font-20 {
          font-size: 20px;
        }
      }

      hr {
        margin: 0;
      }
      .action-container {
        background: #ffffff;
        box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
        border-radius: 8px;
        position: relative;

        .header-text {
          padding: 12px 24px 12px 24px;
          display: flex;
          justify-content: space-between;
        }

        .action-details {
          padding: 20px 24px 20px 24px;

          .status {
            font-size: 12px;
            padding: 4px;
            color: #ffffff;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .status_message {
            margin: 16px 0px 0px 0px;
            border-radius: 4px;
            background: #f4f7f9;
            padding: 2px;

            .message-icon {
              height: 12px;
              width: 12px;
              margin: auto 10px;
            }

            .message-text {
              align-items: center;
              margin: 8px 0px;
            }
          }
        }

        .integration-details {
          padding: 24px 24px 16px 24px;

          .integration-text {
            display: flex;
            align-items: center;
          }
        }
        .menu-container {
          position: relative;
          outline: none;
          user-select: none;
          cursor: pointer;
        }
      }

      .show-line {
        margin-top: 24px;

        .line {
          height: 46px;
          position: absolute;
          width: 1px;
          background: #d2e5ed;
          top: -40px;
          left: 32px;
          z-index: -1;
        }
      }
    }
  }

  .view-more-automations {
    margin-top: 10px;
    .left-panel {
      padding-left: 14px;
    }
  }
`;

export default AutomationLogViewWrapper;
