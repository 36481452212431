import React, { useState, useEffect } from "react";

import { useDispatch, useSelector} from "react-redux";

import { createRetirement, getNftRetirement, setRetirementLoading } from "../../../store/features/nftSlice";
import { setRetirementAmounts } from "../../../store/features/campaignSlice";

import CampaignNftRetirementDetails from "../CampaignNftRetirementDetails";
import EditCampaignNftRetirementDetails from "../EditCampaignNftRetirementDetails";
import Button from "../../Elements/button/button";
import Loading from "../../../assets/images/loading.svg";

import './style.scss'
import moment from "moment";

const CampaignNftRetirement = ({campaignProject, nft, campaign }) => {
    const dispatch = useDispatch();
    const { retirement, retirementLoading, loading } = useSelector(data => data.nft)
    const { retirementAmounts } = useSelector(data => data.campaign) 
    const { organisation } = useSelector(data => data.user) 
    const [newRetirement, setNewRetirement] = useState({co2_offset_amount: retirementAmounts[campaignProject.id]})
    
    const saveRetirement = () => {
        dispatch(createRetirement({nftId: nft.id, retirement: {
            retirement_date: moment().unix(),
            serial_number: newRetirement.serial_number,
            carbon_inventory: {id: newRetirement.carbon_inventory?.id},
            project: {id: campaignProject.project.id},
            account: {id: organisation},
            beneficiary: {id: campaign?.beneficiary?.id},
            reason: {id: newRetirement.reason?.id},
            registry: {id: newRetirement.registry?.id},
            units: newRetirement.co2_offset_amount * 1000,
            offset_url: newRetirement.offset_url,
            unit_type: newRetirement.unit_type?.name
        }}))
        dispatch(setRetirementAmounts({...retirementAmounts, [campaignProject.id]: 0}))
    }

    useEffect(() => {
        if(!loading && nft && nft.id) {
            if(nft.retirement)
                dispatch(getNftRetirement(nft.id));
            else
                dispatch(setRetirementLoading(false));
        }
    }, [nft, dispatch]);

    return(
        <div className="card flex-column flex-1 ml-6 campaign-nft-content">
            <div className="flex items-center justify-between px-6 border-bottom nft-retirement-header">
                <label className="bold-text font-20">Retirement details</label>
                    <div className="flex items-center">
                        {retirement ?
                    <></>
                        :
                        <div className="flex justify-center">
                            <Button
                                className="secondary-hover"
                                label="Cancel"
                                borderRadius="10px"
                                onClick={() => {}}
                                secondary={true}
                                size="medium"
                                style={{ flex: 1 }}
                                bgColor='#FFFFFF'
                                borderColor="#24CBB1"
                                color="#24CBB1"
                                borderWidth="1px"
                                width="112px"
                            />
                            <Button
                                label='Save'
                                onClick={saveRetirement}
                                size="medium"
                                style={{ flex: 1, marginLeft: 24 }}
                                bgColor='#24CBB1'
                                borderColor="#24CBB1"
                                color='#FFFFFF'
                                borderRadius="10px"
                                borderWidth="1px"
                                width="112px"
                            />
                        </div>
                        }
                        {/* <EditDeleteMenu onEdit={() => setEdit(!edit)} onDelete={() => {} } /> */}
                    </div>
                </div>
                {(loading || retirementLoading) ? 
                    <div className="flex flex-1 items-center justify-center mt-4">
                        <img src={Loading} alt="loading" />
                        <label className="bold-text font-16 ml-2 light-purple-text">
                            Loading
                        </label>
                    </div>
                    :
                <div>
                {retirement ? 
                    <CampaignNftRetirementDetails retirement={retirement} />   
                    :
                    <EditCampaignNftRetirementDetails campaignProject={campaignProject} retirement={newRetirement} setRetirement={setNewRetirement} />
                    } 
            </div>
            }
        </div>
    )
}

export default CampaignNftRetirement