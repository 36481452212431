import React, { useEffect, useState } from 'react';
import Button from '../../components/Elements/button/button';
import ConditionComponent from './condition-component';
import ScheduleComponent from './schedule-component';
import Loading from '../../assets/images/loading.gif';

function ScheduleAndConditionData({
  automationObj,
  actionResponse,
  actionObj,
  triggerData,
  integration,
  setActionResponse,
}) {
  const [showScheduleComponent, setShowScheduleComponent] = useState(
    actionResponse?.schedule?.field || actionResponse?.schedule?.offset !== null || actionResponse?.schedule?.unit
      ? true
      : false,
  );
  const [showConditionComponent, setShowConditionComponent] = useState(
    actionResponse?.conditions?.length > 0 ? true : false,
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (actionResponse.id) {
      setShowScheduleComponent(
        actionResponse?.schedule &&
          (actionResponse?.schedule?.field ||
            actionResponse?.schedule?.offset !== null ||
            actionResponse?.schedule?.unit)
          ? true
          : false,
      );
      setShowConditionComponent(actionResponse?.conditions?.length > 0 ? true : false);
    }
  }, [actionResponse]);

  return (
    <>
      <div>
        {loading && (
          <div className="flex items-center justify-center content-loader">
            <img alt="loading" height="64px" src={Loading} />
          </div>
        )}
        <div className={`mt-6  ${!showScheduleComponent && !showConditionComponent && 'for-button-flex'}`}>
          {!showScheduleComponent ? (
            <div className={`${!showScheduleComponent && !showConditionComponent ? '' : 'footer-button'}`}>
              <Button
                additional={true}
                className={`for-button mr-6 additional-hover`}
                label="Add schedule"
                onClick={() => {
                  setShowScheduleComponent(true);
                }}
                size="medium"
              />
            </div>
          ) : (
            <>
              <ScheduleComponent
                actionObj={actionObj}
                actionResponse={actionResponse}
                automationObj={automationObj}
                integration={integration}
                setActionResponse={setActionResponse}
                setLoading={setLoading}
                setShowScheduleComponent={setShowScheduleComponent}
                showScheduleComponent={showScheduleComponent}
                triggerData={triggerData}
              />
              {!showConditionComponent && <hr />}
            </>
          )}
          {!showConditionComponent ? (
            <div className={`${!showScheduleComponent && !showConditionComponent ? '' : 'footer-button'}`}>
              <Button
                additional={true}
                className={`for-button additional-hover`}
                label="Add condition"
                onClick={() => {
                  setShowConditionComponent(true);
                }}
                size="medium"
              />
            </div>
          ) : (
            <ConditionComponent
              actionObj={actionObj}
              actionResponse={actionResponse}
              automationObj={automationObj}
              integration={integration}
              setActionResponse={setActionResponse}
              setLoading={setLoading}
              setShowConditionComponent={setShowConditionComponent}
              triggerData={triggerData}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ScheduleAndConditionData;
