const USER = 'user';

export function setUser(user) {
  localStorage.setItem(USER, JSON.stringify(user));
}
export function getUser() {
  return JSON.parse(localStorage.getItem(USER));
}

export function setItemInLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function getItemFromLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function removeItemFromLocalStorage(key) {
  localStorage.removeItem(key);
}
