import React from "react";
import "./style.scss";

const CampaignLeaderboardItem = ({ campaignNftLeaderItem }) => {
  return (
    <div className="campaign-nft">
      <div className="border-bottom campaign-leaderboard">
        <div
          key={campaignNftLeaderItem.id}
          className="leaderboard-item"
        >
          <div className="flex items-center border-right py-3 px-6">
            <div
              className="flex leaderboard-rank mr-2"
              style={{
                background: "rgba(181, 185, 193, 0.3)",
                borderRadius: "6px",
              }}
            >
              <span className="bold-text">{campaignNftLeaderItem.ranking}</span>
            </div>
            <label className="regular-text light-purple-text flex-1 one-line">
              Rank
            </label>
          </div>
          <div className="flex-column border-right px-6 py-3">
            <label className="regular-text light-purple-text flex-1 one-line">
              Wallet:{" "}
              <span className="medium-text">
                {campaignNftLeaderItem.wallet_address || "-"}
              </span>
            </label>
            <label className="regular-text light-purple-text flex-1 mt-2 one-line">
              Contact:{" "}
              <span className="medium-text">
                {campaignNftLeaderItem.contact?.name || "-"}
              </span>
            </label>
          </div>
          <div className="flex items-center justify-between px-6 py-3 border-right relative">
            <label className="regular-text light-purple-text flex-1 one-line">
              Project:{" "}
              <span className="medium-text">
                {campaignNftLeaderItem.project_name || "-"}
              </span>
            </label>
          </div>
          <div className="flex items-center justify-between px-6 py-3 relative">
            <label className="medium-text flex-1 one-line">
              {campaignNftLeaderItem.co2_kg_units || "-"}
              <span className="regular-text light-purple-text"> kilos</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignLeaderboardItem;
