import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { ReactComponent as EmailIcon } from '../assets/images/email.svg';
import { ReactComponent as MobileIcon } from '../assets/images/mobile.svg';
import Button from '../components/Elements/button/button';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import api from "../store/services";
import { addToast } from '../store/features/toastSlice';
import { DeleteUserWrapper } from './modal.styled.js';
import { CSSTransition } from 'react-transition-group';

const DeleteUser = () => {
  const { modal, setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const user = modal.content.user;
  const isFromTeam = modal.content.isFromTeam;
  const organisationId = modal.content.organisationId;
  const roles =
    (user &&
      user.organisations
        ?.reduce((acc, curr) => {
          curr.roles && acc.push(...curr.roles);
          return acc;
        }, [])
        .map(role => role.name)) ||
    [];
  const userEmail = (user && user.contact_info?.find(contact => contact.contact_type === 'EMAIL')?.contact_value) || '';
  const userMobile =
    (user && user?.contact_info?.find(contact => contact.contact_type === 'MOBILE')?.contact_value) || '';

  const onDelete = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = isFromTeam
        ? await api.delete(`/v1/organisations/${organisationId}/users/${user.id}`)
        : await api.delete(`/v1/users/${user.id}`);
      dispatch(
        addToast({
          error: false,
          text: `User has been ${isFromTeam ? 'removed from team' : 'deleted'} successfully`,
          id: uuid(),
        }),
      );
      setModal({ type: 'delete-user-success', content: {} });
      return;
    } catch (error) {
      console.log(error);
    }
    dispatch(
      addToast({
        error: true,
        text: `Failed to ${isFromTeam ? 'remove from team' : 'delete'} user`,
        id: uuid(),
      }),
    );
    setLoading(false);
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <DeleteUserWrapper>
        <p className="bold-text font-24 text-center">
          Are you sure you want to {isFromTeam ? 'remove' : 'delete'} this user{isFromTeam && ' from this team'}?
        </p>
        <div className="flex-column user-details-container" style={{ marginTop: 32 }}>
          <div className="flex-column">
            <label className="bold-text user-name">{user.name}</label>
            <label className="regular-text user-roles mt-1">{roles.join(', ')}</label>
          </div>
          <div className="flex items-center mt-4">
            <div className="icon-container">
              <EmailIcon />
            </div>
            <label className="regular-text ml-2">{userEmail}</label>
          </div>
          <div className="flex items-center mt-4">
            <div className="icon-container">
              <MobileIcon />
            </div>
            <label className={`regular-text ml-2 ${!userMobile && 'not-provided-label'}`}>
              {userMobile || 'Not provided'}
            </label>
          </div>
        </div>
        <div className="flex justify-center mt-12">
          <Button
            className="secondary-hover"
            label="Cancel"
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            style={{ flex: 1 }}
          />
          <Button
            label={isFromTeam ? 'Remove' : 'Delete'}
            onClick={onDelete}
            red={true}
            size="large"
            style={{ flex: 1, marginLeft: 24 }}
          />
        </div>
      </DeleteUserWrapper>
    </CSSTransition>
  );
};

export default DeleteUser;
