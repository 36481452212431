const themeHelper = {
  down: breakpoint => `@media screen and (max-width: ${breakpoint})`,
  up: breakpoint => `@media screen and (min-width: ${breakpoint})`,
  breakpoints: {
    lg: '1024px',
    xl: '1920px',
  },
};

export const light = {
  ...themeHelper,
  colors: {
    primary: '#08B2E4',
    primary_background_light: 'rgba(8, 178, 228, 0.1)',

    additional: '#E9FAFF',
    white: '#FFFFFF',
    backgroundColor2: '#F0F3F6',
    backgroundColor: '#F9FBFD',
    sidebarBackground: '#023544',
    sidebar_item_hover: 'rgba(222, 229, 243, 0.04)',
    sidebar_menu_icon_selected: 'rgba(8, 178, 228, 0.08)',

    sidebar_bottom_background: '#063E4F',

    shadowColor: 'rgba(5, 49, 73, 0.08)',
    modalBackground: 'rgba(2, 53, 68, 0.6)',
    disabled: '#CFE3E9',
    border: 'rgba(181, 185, 193, 0.5)',
    menu_item_hover: 'rgba(222, 229, 243, 0.3)',

    main_border: '#DEE5F3',

    black_text: '#023544',

    main_grey: '#AABAB7',
    grey: '#F4F7F9',
    light_grey_background: 'rgba(170, 186, 183, 0.08)',

    light_blue: '#9CDBEE',

    light_purple: '#AABAB7',

    main_orange: '#FD9F2D',
    main_orange_O_10: 'rgba(253, 159, 45, 0.1)',

    additional_red: '#FF6262',

    lighter_text: '#6B7280',

    users_teams_background: '#F0F5FA',

    green: '#7ADEAE',
    light_grey: '#F6F6F6',
    yellow: '#EED067',

    light_green: '#CAFFE5',
    green_text: '#25763C',
  },
};

export const dark = {
  ...themeHelper,
  colors: {
    primary: '#08B2E4',
    primary_background_light: 'rgba(8, 178, 228, 0.1)',

    additional: '#E9FAFF',
    white: '#FFFFFF',
    backgroundColor2: '#F0F3F6',
    backgroundColor: '#F9FBFD',
    sidebarBackground: '#023544',
    sidebar_item_hover: 'rgba(222, 229, 243, 0.04)',
    sidebar_menu_icon_selected: 'rgba(8, 178, 228, 0.08)',

    sidebar_bottom_background: '#063E4F',

    shadowColor: 'rgba(5, 49, 73, 0.08)',
    modalBackground: 'rgba(2, 53, 68, 0.6)',
    disabled: '#CFE3E9',
    border: 'rgba(181, 185, 193, 0.5)',
    menu_item_hover: 'rgba(222, 229, 243, 0.3)',

    main_border: '#DEE5F3',

    black_text: '#023544',

    main_grey: '#AABAB7',
    grey: '#F4F7F9',
    light_grey_background: 'rgba(170, 186, 183, 0.08)',

    light_blue: '#9CDBEE',

    light_purple: '#AABAB7',

    main_orange: '#FD9F2D',

    additional_red: '#FF6262',

    lighter_text: '#6B7280',

    users_teams_background: '#F0F5FA',

    green: '#7ADEAE',
    light_grey: '#F6F6F6',
    yellow: '#EED067',

    light_green: '#CAFFE5',
    green_text: '#25763C',
  },
};
