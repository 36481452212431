import { ReactComponent as AddUserIcon } from '../../../assets/images/add-user.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/edit-image.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/remove-upload.svg';
import React from 'react';

const Menu = props => {
  const { clickItem, hasPermission, checkPermission, isTeamDetails } = props;
  return (
    <div className="card menu-wrapper">
      <div style={{ padding: '8px' }}>
        {!isTeamDetails && (
          <label
            className="regular-text cursor"
            onClick={e => {
              e.stopPropagation();
              checkPermission(() => clickItem('ADD_USER'), 'TEAMS_MANAGE');
            }}
            style={{ opacity: hasPermission('TEAMS_MANAGE') ? 1 : 0.5 }}>
            <AddUserIcon />
            <span className="ml-3">Add users</span>
          </label>
        )}
        <label
          className="regular-text cursor"
          onClick={e => {
            e.stopPropagation();
            checkPermission(() => clickItem('EDIT'), 'TEAMS_MANAGE');
          }}
          style={{ opacity: hasPermission('TEAMS_MANAGE') ? 1 : 0.5 }}>
          <EditIcon />
          <span className="ml-3">Edit</span>
        </label>
        <label
          className="regular-text cursor"
          onClick={e => {
            e.stopPropagation();
            checkPermission(() => clickItem('DELETE'), 'TEAMS_MANAGE');
          }}
          style={{ opacity: hasPermission('TEAMS_MANAGE') ? 1 : 0.5 }}>
          <DeleteIcon />
          <span className="ml-3">Delete</span>
        </label>
      </div>
    </div>
  );
};

export default Menu;
