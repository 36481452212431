import React, { useEffect, useState, useCallback, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  getRetirementTransactions,
  setShowRetirementTransactions,
  setRetirementTransactions,
} from "../../../store/features/retirementSlice";
import McCheckbox from "../../McCheckbox";
import { getNftLifecycleColor } from "../../../helpers/utils";
import { ReactComponent as ArrowDownIcon } from "../../../assets/images/menu/arrow-down-black.svg";
import ArrowDownGreenIcon from "../../../assets/images/arrow-down-green.svg";
import { ReactComponent as DotsIcon } from "../../../assets/images/menu/dots.svg";
import OpenIcon from "../../../assets/images/open-green.svg";

import "./style.scss";

const CampaignRetirement = ({
  ret,
  onSelectRetirement,
  selectAll,
  menuItems,
  onMenuItemClick,
}) => {
  const ref = useRef();
  const {
    retirementTransactions,
    loadingTransactions,
    showRetirementTransactions,
  } = useSelector((data) => data.retirement);
  const [showMenu, setShowMenu] = useState();

  const dispatch = useDispatch();

  const loadRetirementTransactions = useCallback(
    async (page, merge) => {
      dispatch(getRetirementTransactions({ id: ret.id, merge, size: 20 }));
    },
    [ret, dispatch]
  );

  useEffect(() => {
    if (
      showRetirementTransactions &&
      showRetirementTransactions === ret.id &&
      ret.fractional.count > 0
    ) {
      dispatch(setRetirementTransactions({}));
      loadRetirementTransactions(0, false);
    }
  }, [loadRetirementTransactions, showRetirementTransactions, ret]);
  return (
    <div
      className="flex-column relative"
      onClick={() =>
        dispatch(
          setShowRetirementTransactions(
            showRetirementTransactions === ret.id ? "" : ret.id
          )
        )
      }
    >
      <div className="flex-column camp-ret-item">
        <div
          className={"camp-ret-grid camp-card cursor border-bottom cursor"}
          key={ret.id}
        >
          <div className="flex items-center ml-6 one-line">
            <McCheckbox
              checked={ret.selected || selectAll}
              onClick={(e) => {
                e.stopPropagation();
                onSelectRetirement(ret);
              }}
            />
            <label className="medium-text px-6 py-4 one-line">
              {ret.project?.name || "-"}
            </label>
          </div>
          <label className="medium-text px-6 py-4">
            {moment.unix(ret.retirement_date).format("DD/MM/YYYY")}
          </label>
          <label className="medium-text px-6 py-4 one-line">
            {ret.registry?.name || "-"}
          </label>
          <label className="medium-text px-6 py-4 one-line">
            {ret.beneficiary_account?.name || ""}
          </label>
          <label className="medium-text px-6 py-4">
            {ret.fractional?.count}
          </label>
          <label className="medium-text px-6 py-4">
            {ret.blocks?.units / 1000}
          </label>
          <div className="flex items-center px-6 py-4">
            <div
              className="flex items-center justify-center retirement-state px-2"
              style={{ background: ret.is_pending ? "#FBBF66" : "#5CCFF3" }}
            >
              <label className="medium-text white-text font-10">
                {ret.is_pending ? "PENDING" : "RETIRED"}
              </label>
            </div>
          </div>
          <div className="flex items-center justify-between pl-4 pr-4 py-4 mr-2">
            <div
              className="nft-lifecycle flex items-center justify-center"
              style={{ background: getNftLifecycleColor(ret.nft.lifecycle) }}
            >
              <label className="medium-text font-10 white-text">
                {ret.nft.lifecycle}
              </label>
            </div>
            {ret?.nft.blockchain && (
              <div className="open-icon ml-2">
                <img
                  src={OpenIcon}
                  alt="copy"
                  className="cursor mr-2"
                  onClick={() => {
                    window.open(
                      `${
                        ret?.nft.blockchain?.token?.token_type === "BEP20"
                          ? `https://${
                            JSON.stringify(process.env.REACT_APP_ENVIRONMENT) === JSON.stringify("dev")
                                ? "test."
                                : ""
                            }bithomp.com/en/nft/`
                          : "https://wax.atomichub.io/explorer/asset/wax-mainnet/"
                      }${ret?.nft.blockchain?.token?.hash}`,
                      "_blank"
                    );
                  }}
                />
              </div>
            )}
            <div className="flex items-center justify-center fit-content">
              <ArrowDownIcon
                className={`arrow-down mx-4 ${
                  showRetirementTransactions === ret.id &&
                  ret.fractional.count > 0 &&
                  "arrow-up"
                }`}
              />
            </div>
          </div>
        </div>
        <div
          className="flex-column retirement-tx no-select"
          style={{
            maxHeight:
              showRetirementTransactions === ret.id && ret.fractional.count > 0
                ? "352px"
                : 0,
          }}
        >
          <div className="ret-tx-grid camp-ret-header border-bottom">
            <label className="font-12 semibold-text py-2 tx-date-time one-line">
              DATE & TIME
            </label>
            <label className="font-12 semibold-text px-6 py-2 one-line">
              AMOUNT
            </label>
            <label className="font-12 semibold-text px-6 py-2 one-line">
              WALLET
            </label>
            <label className="font-12 semibold-text px-6 py-2 one-line">
              CONTACT
            </label>
            <label className="font-12 semibold-text px-6 py-2 one-line">
              REFERENCE
            </label>
          </div>
          <div className="flex-column ret-tx">
            {retirementTransactions.content?.map((tx) => (
              <div className="ret-tx-grid border-bottom">
                <label className="regular-text font-12 py-2 tx-date-time one-line">
                  {moment.unix(tx.transaction_date).format("DD/MM/YYY HH:mm")}
                </label>
                <label className="regular-text font-12 px-6 py-2">
                  {tx.co2_kg_units / 1000}
                </label>
                <label className="regular-text font-12 px-6 py-2">
                  {tx.wallet?.alias || tx?.wallet?.wallet_address || "-"}
                </label>
                <label className="regular-text font-12 px-6 py-2 one-line">
                  {tx.contact?.name || "-"}
                </label>
                <label className="regular-text font-12 px-6 py-2 one-line">
                  {tx.reference || "-"}
                </label>
              </div>
            ))}
            {!retirementTransactions.last && (
              <div
                className="flex border-bottom py-2 cursor tx-date-time"
                onClick={(e) => {
                  e.stopPropagation();
                  loadRetirementTransactions(
                    retirementTransactions?.number + 1,
                    true
                  );
                }}
              >
                <label className="semi-bold-text font-12 color-green mr-1">
                  Show more
                </label>
                <img src={ArrowDownGreenIcon} alt="down" />
              </div>
            )}
            {/* {loadingTransactions &&
                        <div className="item flex items-center justify-center py-4 border-bottom">
                            <img alt="loading" height="24px" src={Loading} />
                        </div>
                        } */}
          </div>
        </div>
      </div>
      <div className="flex-column absolute camp-ret-menu ">
        <div
          className="flex items-center justify-center px-3 relative cursor"
          onClick={(e) => {
            e.stopPropagation();
            setShowMenu(!showMenu);
          }}
          tabIndex={0}
          onBlur={() => setShowMenu(false)}
        >
          <DotsIcon className="cursor" height={12} />
          {showMenu && (
            <div className="header-menu-items flex-column card pxy-2 absolute">
              {menuItems.map((item, i) => (
                <div
                  key={i}
                  className="header-menu-item flex items-center pxy-2 cursor"
                  onClick={(e) => onMenuItemClick(item, ret)}
                >
                  <label className="regular-text">{item.name}</label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignRetirement;
