import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import Image from '../components/image';
import Button from '../components/Elements/button/button';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { addToast } from '../store/features/toastSlice';
import { DeleteCampaignProjectWrapper } from './modal.styled.js';
import { CSSTransition } from 'react-transition-group';
import { deleteCampaignProjects } from '../store/features/campaignSlice';

const DeleteCampaignProject = () => {
  const { modal, setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const campaignProject = modal.content.campaignProject;
  const project = campaignProject.project;
  const campaignId = modal.content.campaignId;

  const onDelete = async () => {
    if (loading) return;
    setLoading(true);
    try {
    dispatch(deleteCampaignProjects({campaignId: campaignId, campaignProject: campaignProject}))
      dispatch(
        addToast({
          error: false,
          title: 'Project removed',
          text: `${project.name} has been removed`,
          id: uuid(),
        }),
      );
    } catch (error) {
      console.log(error);
      dispatch(
        addToast({
          error: true,
          text: `Failed to delete project`,
          id: uuid(),
        }),
      );
    }
  
    setLoading(false);
    setModal(initModal);
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <DeleteCampaignProjectWrapper>
        <p className="bold-text font-24 text-center">
          Are you sure you want to remove this project?
        </p>
        <div className="flex-column user-details-container relative" style={{ marginTop: 32 }}>
          <Image className={"rcp-image"} size='313' media={project?.image?.media} />
          <div className='flex items-center justify-center absolute rcp-country px-2'>
            <label className='bold-text font-12'>{project.location?.country}</label>
          </div>
          <label className='bold-text font-16 mt-4 px-2'>{project.name}</label>
          <label className='regular-text font-12 o05 mt-2 px-2 pb-2'>{project.description}</label>
        </div>
        <div className="flex justify-center mt-12">
          <Button
            className="secondary-hover"
            label="Cancel"
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            style={{ flex: 1, borderRadius: '16px' }}
            bgColor="#FFFFFF"
            borderColor='#24CBB1'
            color='#24CBB1'
          />
          <Button
            label='Remove'
            onClick={onDelete}
            size="large"
            style={{ flex: 1, marginLeft: 24, borderRadius: '16px' }}
            bgColor="#FB3535"
            borderColor='#FB3535'
            color='#FFFFFF'
          />
        </div>
      </DeleteCampaignProjectWrapper>
    </CSSTransition>
  );
};

export default DeleteCampaignProject;
