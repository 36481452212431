import React, { useState} from "react";


import { ReactComponent as DotsIcon } from '../../../assets/images/menu/dots.svg';

import './style.scss'
import moment from "moment";

const CampaignAchievement = ({ach, menuItems, onMenuItemClick}) => {
    const [showMenu, setShowMenu] = useState();

    return(
        <div className={"camp-ach-grid camp-card cursor border-bottom relative cursor"}  key={ach.id} >
        <label className="medium-text px-6 py-3">{ach.name}</label>
        <label className="medium-text px-6 py-3">{ach.achievement_type || '-'}</label>
        <label className="medium-text px-6 py-3">{ach.min_amount || ''}</label>
        <label className="medium-text px-6 py-3">{ach.max_amount}</label>
        <label className="medium-text px-6 py-3">{ach.effective.start ? moment.unix(ach.effective.start).format('DD/MM/yyyy') : ''} - {ach.effective.end ? moment.unix(ach.effective.end).format('DD/MM/yyyy') : ''}</label>
        <label className="medium-text px-6 py-3">{ach.use_history}</label>
        <label className="medium-text px-6 py-3">{ach.template?.name}</label>
        <div className="flex-column absolute achievement-menu ml-8">
            <div className="flex items-center justify-center header-menu relative cursor" style={{background: showMenu  ? '#F6F6F6' : '', border: showMenu ? '1px solid rgba(181, 185, 193, 0.5)' : ''}}  onClick={() => setShowMenu(!showMenu)} tabIndex={0} onBlur={() => setShowMenu(false)}>
                    <DotsIcon className="cursor" height={12} /> 
                    {showMenu && <div className="header-menu-items flex-column card pxy-2 absolute">
                        {menuItems.map((item, i) => 
                            <div key={i} className="header-menu-item flex items-center pxy-2 cursor" onClick={() => onMenuItemClick(item, ach)}>
                                <label className="regular-text">{item.name}</label>
                            </div>
                        )}
                    </div>
                    }
                </div>
        </div>
     </div>
    )
}

export default CampaignAchievement;



