import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProducts } from "../../store/features/productSlice";
import ProductCard from "../../components/products/ProductCard";
import Header from "../../components/header";
import Filter from "../../components/filter";

import './products.scss';

const Products = () => {
    const dispatch = useDispatch();
    const { products } = useSelector(data => data.product);
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState({value: ''});

    useEffect(() => {
        dispatch(getProducts({page: currentPage, search: search, filter: filter?.value }))
    }, [currentPage, filter?.value, search, dispatch]);

    return (
        <div className="flex-column mt-2 mb-6 flex-1">
            <Header
                title={"Products"}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                search={search}
                setSearch={setSearch}
                totalPages={products.total_pages}
                isFilter={true}
                filterChildren={<Filter objects={["BRAND", "FAMILY"]} setFilter={setFilter} values={filter?.filters} />}
                filter={filter}
                isAddBtn={false}
                />

            <div className="product-cards card mx-6 mt-4">
                {products.content?.map(product => <ProductCard product={product} key={product.id}/>)}
            </div>
        </div>
    )
}

export default Products;
