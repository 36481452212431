export const TEXT_COLOR = '#053149';
export const ACCENT_COLOR = '#40F1DE';
export const BACKGROUND_COLOR = '#FAFCFD';
export const SELECTED_MENU_ITEM_COLOR = '#053149';
export const SUB_MENU_ITEM_COLOR = '#F4F9FB';
export const USER_ICON_COLOR = '#40F1DE';
export const SIDE_BAR_BACKGROUND = '#FFFFFF';
export const BORDER_COLOR = '#E3EEF3';
export const HOVER_COLOR = '#E9FAF7';

export const ADDITIONAL_GREEN = '#7BD77A';
export const ADDITIONAL_RED = '#FF5B5B';
export const ADDITIONAL_YELLOW = '#F6BB23';

const colors = {
  ADDITIONAL_GREEN,
  ADDITIONAL_RED,
  ADDITIONAL_YELLOW,
};

export default colors;
