import React, { useState, useRef, useEffect } from "react";

import Button from "../Elements/button/button";
import Switch from "../common/switch";

import ArrowDownIcon from '../../assets/images/menu/arrow-down-black.svg';
import Loading from '../../assets/images/loading.svg';
import AddCustomer from '../../assets/images/add-customer.svg';
import SearchGrey from '../../assets/images/search-grey.svg';

import './style.scss';

const DropDown = ({className, isAddSearchable, options, selected, setSelected, placeholder, isSearchable, search, setSearch, isPaged, hasMore, fetchData, size='medium', description, icon = false, selectedIcon=false, getIcon = () => {}, hideOptions = false, onAdd }) => {
    const ref = useRef();
    const loader = useRef(null);

    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState(false);
    const [add, setAdd] = useState(false);
    const [isBusiness, setIsBusiness] = useState(false);
    const [contact, setContact] = useState({})

    const itemHeight = description ? '56' : '32'

    const onBlur = e => {
        e.preventDefault();
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setShow(false);  
          setEdit(false);
          setAdd(false)
        }
    };

    const onClick = (edit, show) => {
        setEdit(edit);
        setShow(show);
    }; 

    const handleObserver = entities => {
        const target = entities[0];
        if (target.isIntersecting) {
          if (hasMore) fetchData();
        }
      };

      useEffect(() => {
        if (!isPaged) return;
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0.5,
        };
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
          observer.observe(loader.current);
        }
        return () => {
          if (loader.current) {
            observer.unobserve(loader.current);
          }
        };
      }, [loader.current, isPaged]);

      const select = s => {
        setEdit(false); 
        setShow(false);
        setSelected(s);
        if(isSearchable)
            setSearch('');
    }

    const save = () => {
      onAdd({...contact, forename: contact.name.split(' ')[0], surname: contact.name.split(' ')[1], contact_type: isBusiness ? 'BUSINESS' : 'PERSON'})
      setShow(false);  
      setEdit(false);
      setAdd(false)
      setContact({})
    }

    return(
        <div className={`flex mc-select relative no-select mc-select fit-content ${className}`} id="of-id" onBlur={onBlur} tabIndex={0} style={{}}>
            <div className="flex items-center justify-between organisations-border mc-border flex-1 cursor pl-4 one-line" 
                    ref={ref} 
                    style={{height: size === 'large' ? '48px' : '32px', borderColor: show ? '#96F1E3' : 'rgba(181, 185, 193, 0.3)', borderWidth: size === 'large' ? '1.5px' : '1px', borderRadius: size === 'large' ? '16px' : '12px'}} 
                    onClick={() => isSearchable ? onClick(true, true) : setShow(!show)} >
                { isSearchable && edit ?
                    <div className="flex items-center py-2" onClick={() => setShow(true)}>
                        <input className="org-name-input" autoFocus value={search} onChange={e => setSearch(e.target.value)} />
                    </div>
                :
                selected ? <label className={`regular-text selected-name flex-1 flex ${size === 'large' && 'font-16'}`}>{(icon || selectedIcon) && <span className="flex items-center"><img className="selected-icon mr-2" height="20px" src={getIcon(selected)} alt="icon" /></span>}{selected.name}</label> : <label className={`regular-text o03  ${size === 'large' && 'font-16'}`}>{placeholder}</label> }
                <div className="input-arrow"  onClick={() => onClick(false, !show)}>
                    <img alt="icon" className={`dropdown-icon ${show && 'open'} cursor`} src={ArrowDownIcon} />
                </div>            
            </div>
            {add ? 
                <div className="absolute flex-column card pxy-4 mc-add">
                    <div className="flex-column">
                          <label className="regular-text font-12 light-purple-text mb-2">Name</label>
                          <input className="mc-add-input"  value={contact.name || ''} onChange={e => setContact({...contact, name: e.target.value})} />
                      </div>
                      <div className="flex items-center justify-between my-6">
                        <label className="regular-text">Business</label>
                        <Switch
                            variant="large"
                            enabled={isBusiness}
                            onClick={() => setIsBusiness(!isBusiness)}
                            />
                      </div>
                      {isBusiness &&
                        <div className="flex-column mb-6">
                            <label className="regular-text font-12 light-purple-text mb-2">Business name</label>
                            <input className="mc-add-input"  value={contact.business_name || ''} onChange={e => setContact({...contact, business_name: e.target.value})} />
                        </div>
                      }
                      <div className="flex-column">
                          <label className="regular-text font-12 light-purple-text mb-2">Email</label>
                          <input className="mc-add-input"  value={contact.email || ''} onChange={e => setContact({...contact, email: e.target.value})} />
                      </div>
                      <div className="flex-column mt-6">
                          <label className="regular-text font-12 light-purple-text mb-2">Phone</label>
                          <input className="mc-add-input"  value={contact.phone || ''} onChange={e => setContact({...contact, phone: e.target.value})} />
                      </div>
                      <div className="flex items-center mt-8">
                        <Button
                            className="secondary-hover"
                            label="Cancel"
                            borderRadius="12px"
                            onClick={() => setAdd(false)}
                            secondary={true}
                            size="medium"
                            style={{ flex: 1 }}
                            bgColor='#FFFFFF'
                            borderColor="#24CBB1"
                            color="#24CBB1"
                            borderWidth="1px"
                            width="152px"
                        />
                        <Button
                            label='Add'
                            onClick={save}
                            size="medium"
                            style={{ flex: 1, marginLeft: 16 }}
                            bgColor='#24CBB1'
                            borderColor="#24CBB1"
                            color='#FFFFFF'
                            borderRadius="12px"
                            borderWidth="1px"
                            disabled={!contact.name || !contact.email}
                            width="152px"
                        />
                    </div>
                </div>
                :
            <div className="mc-options absolute flex-column card px-2" style={{height: show ? (options?.length * itemHeight + (isAddSearchable ? 57 : 0)) : 0 , top: size === 'large' ? '54px' : '38px', display: hideOptions ? show ? 'flex' : 'none' : 'flex'}}>
              {isAddSearchable && 
                <div className="flex items-center flex-1 mt-4 mb-2">
                  <div className="flex items-center flex-1 dd-search-cont mr-4 px-2 py-1">
                    <img src={SearchGrey}  width="24px" height="24px" alt="search" />
                    <input className="ml-2" placeholder="Search" value={search} onChange={e => setSearch(e.target.value)}  />
                  </div>
                  <div className="cursor" onClick={() => setAdd(true)}>
                    <img src={AddCustomer} alt="add" className="flex-1" />

                  </div>
                </div>
                }
              
                <div className="mc-options-list flex-column py-2">  
                    {options?.map((option, i) =>  
                        <div 
                            key={option.id} 
                            className="flex items-center px-2 cursor mc-option-item" 
                            onClick={() => select(option)}
                            style={{height: `${itemHeight}px`}}
                            >
                              <div className="flex-column">
                                <label className="regular-text flex">{icon && <span className="flex items-center"><img className="option-icon mr-2" src={getIcon(option)} alt="icon" height="20px" /></span>}{option.name}</label>
                                {description && <label className="regular-text font-12 light-purple-text" style={{width: `${ref.current?.offsetWidth - 30}px`}}>{option.description}</label> }
                              </div>
                        </div>
                    )}
                    {isPaged && hasMore && (
                        <div ref={loader} className="item flex items-center justify-center my-2">
                            <img src={Loading}/>
                        </div>
                    )}
                </div>
                
            </div>
          }
        </div>
    )
}

export default DropDown;