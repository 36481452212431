import React, { useEffect, useCallback, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getCampaignFractionalNfts, setFractionalNftsLoading } from "../../../store/features/campaignSlice";
import CampaignNft from "../CampaignNft";
import Loading from '../../../assets/images/loading.svg';
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";

import './style.scss'

const CampaignFractionalNfts = ({campaignId, isNewCampaign}) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const queued = location.state?.queued;

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const { fractionalNfts, fractionalNftsLoading } = useSelector(data => data.campaign);
    const [currentTier, setCurrentTier] = useState()

    const getNfts  = useCallback(async (page, merge)  => {
        if(queued) {
            for (let i = 0; i < 10; i++) {
                const data = await dispatch(getCampaignFractionalNfts({campaignId: campaignId, merge, page}));
                if (data.content?.length > 0 || !queued) break; 
                await sleep(3000);
              }
            dispatch(setFractionalNftsLoading(false))
        } else {
            dispatch(getCampaignFractionalNfts({campaignId: campaignId, merge, page}));
            dispatch(setFractionalNftsLoading(false))
        }
      }, [campaignId, dispatch, queued]);

    useEffect(() => {
        if (campaignId)
            getNfts(0, false);
    }, [campaignId, getNfts]);

    const fetchMoreTiers = () => {
        getNfts(fractionalNfts?.number + 1, true);
    };
    useEffect(() => {
        setCurrentTier(fractionalNfts.content?.[0]?.tier)
    }, [fractionalNfts])

    const onScroll = () => {
        const messageBox = document.getElementById('scrollableDiv');
        const labels = document.querySelectorAll('.campaign-nft-nft');
        const campaignNfts = document.querySelectorAll('.campaign-nft');
        let currentLabel = null;
        let totalHeight = 0 - messageBox.scrollTop;
        campaignNfts.forEach((nft, index) => {
          totalHeight += nft?.offsetHeight ;
          if (totalHeight > 0 && !currentLabel) {
            currentLabel = labels[index];
          }
        });
        if(currentLabel)
            setCurrentTier(JSON.parse(currentLabel.id));
      };

      return(
        <div className="flex-column campaign-list-nfts-merchandise flex-1">
            {             
                fractionalNfts.content?.length > 0 && !isNewCampaign ?
                <div>
                    {currentTier && <div className="flex items-center justify-between px-6 py-3 campaign-nft-top cursor no-select">
                        <div className="flex items-center">
                            <div className="campaign-nft-color" style={{background: currentTier?.color_code}} />
                            <label className="bold-text font-12 ml-2">{currentTier?.name?.toUpperCase()}</label>
                        </div>
                    </div> }
                    <div className="merchandise-nfts-list" id="scrollableDiv">
                        <InfiniteScroll
                            onScroll={onScroll}    
                            dataLength={fractionalNfts?.content?.length}
                            hasMore={!fractionalNfts?.last}
                            loader={
                            <div className="item flex items-center justify-center">
                                <img alt="loading" height="24px" src={Loading} />
                            </div>
                            }
                            next={fetchMoreTiers}
                            scrollableTarget="scrollableDiv"
                            style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            overflow: "unset",
                            }}
                        >
                        {fractionalNfts.content?.map((nft, i) => 
                            <div className="flex-column campaign-nft" id="nft" key={nft.id}>
                                <CampaignNft nft={nft} /> 
                            </div>
                        )}
                        </InfiniteScroll>   
                    </div>

                </div> 
                :
            fractionalNftsLoading ? 
                <div className="flex flex-1 items-center justify-center my-6">
                    <img alt="loading" height="24px" src={Loading} />
                </div>
                :          
                <div className="flex items-center justify-center flex-1 my-6">
                    <label className="bold-text font-16 light-purple-text">There are no NFTs</label>
                </div>
            }
        </div>
    )
}

export default CampaignFractionalNfts;