import React, { useEffect, useRef, useState } from 'react';
import SelectedIntegrationAutomationWrapper from './selected-integration-automation.styled';
import api from '../../store/services'
import ScheduleAndConditionData from './schedule-and-condition-data';
import Dropdown from '../../components/Elements/dropdown/dropdown';
import Button from '../../components/Elements/button/button';
import Loading from '../../assets/images/loading.gif';
import { useDispatch, useSelector } from 'react-redux';
import { addToast} from '../../store/features/toastSlice'
import { ReactComponent as EditIcon } from '../../assets/images/edit-image.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/trash.svg';

import HidePassword from '../../assets/images/hide-password.svg'
import ShowPassword from '../../assets/images/show-password.svg';
import { capitalize, formatText } from '../../helpers/utils';
import Selected from '../../assets/images/selected.svg';
import AsyncSelect from 'react-select/async';

const MultiValueOption = props => {
  const onSelect = () => {
    const currentSelected = props.getValue();
    const newSelected = props.isSelected
      ? currentSelected.filter(opt => opt.value !== props.value)
      : [...currentSelected, { ...props.data }];
    props.setValue(newSelected);
  };
  return (
    <div className={`tpus-user ${props.isSelected && 'tpus-user-selected'}`} onClick={() => onSelect()}>
      <label className={`regular-text ${props.isSelected && 'bold-text'}`}>{props.label}</label>
      {props.isSelected && <img alt="icon" src={Selected} />}
    </div>
  );
};

function SelectedIntegrationAutomation({
  selectedIntegrationObj,
  actionObj,
  automationObj,
  setSelectedIntegration,
  triggerData,
  updateAction,
  removeIntegrationFromAction,
}) {
  const [integrationConfigData, setIntegrationConfigData] = useState(
    actionObj?.integration?.id ? actionObj?.params : [],
  );
  const [showButtons, setShowButtons] = useState(actionObj?.integration?.id ? true : false);
  const [errorFields, setErrorFields] = useState({});
  const [isLoadingRight, setIsLoadingRight] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState([]);
  const [showIntegrationAction, setShowIntegrationAction] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const dispatch = useDispatch();
  const inputRefs = useRef({});

  const setIntegrationParams = integration_params => {
    let newParams = {};
    integration_params?.forEach(p => {
      newParams[p.param_type] = p.param_value;
    });
    return newParams;
  };
  const [integration, setIntegration] = useState(
    actionObj?.integration?.id ? setIntegrationParams(actionObj?.params) : {},
  );
  const [listOptions, setListOptions] = useState({});
  const [actionResponse, setActionResponse] = useState(actionObj?.integration?.id ? actionObj : null);
  const [showPassword, setShowPassword] = useState({});
  const [attachmentsOptions, setAttachmentsOptions] = useState({});
  const [inputState, setInputState] = useState({});
  const [selectedValue, setSelectedValue] = useState({});
  const [disable, setDisable] = useState(false);

  const { automationsData } = useSelector(state => state.automation);

  const check = async () => {
    if (!actionObj?.integration?.id) {
      if (selectedIntegrationObj?.id) {
        setIntegrationConfigData([]);
        setActionResponse(null);
        setIntegration({});
        fetchIntegrationConfigData();
        setIsEdit(false);
      }
    } else {
      setShowButtons(true);
      setIntegrationConfigData(actionObj?.params);
      setIntegration(setIntegrationParams(actionObj?.params));
      setActionResponse(actionObj);
      setIsEdit(false);
    }
  }

  useEffect(() => {
    check()
  }, [actionObj]);

  const checkErrors = () => {
    let errors = {};
    integrationConfigData.forEach(config => {
      if (config.optional) {
        return;
      }
      if (config.conditional) {
        const isAvailable = integration[config.conditional.param_name] === config.conditional.param_value;
        if (isAvailable) {
          const value = integration[config.id];
          errors = { ...errors, [config.id]: !value };
        }
      } else if (config.required_params?.length > 0 && config.show) {
        const value = integration[config.id];
        errors = { ...errors, [config.id]: !value };
      } else if (config.param_type === 'ATTACHMENTS') {
        const value = integration[config.id]?.length > 0;
        errors = { ...errors, [config.id]: !value };
      } else {
        const value = integration[config.id];
        errors = { ...errors, [config.id]: !value };
      }
    });
    setErrorFields(errors);
    return Object.values(errors).some(error => error);
  };

  const getSelectedConfigs = () => {
    const selectedConfigs = integrationConfigData.reduce((acc, curr) => {
      if (curr.conditional) {
        const isAvailable = integration[curr.conditional.param_name] === curr.conditional.param_value;
        if (isAvailable) {
          acc.push(curr);
        }
      } else if (curr.required_params?.length > 0 && curr.show) {
        acc.push(curr);
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
    return selectedConfigs;
  };

  const getAttachmentrequest = value => {
    let requestParamValue = '';
    value.forEach(v => {
      if (requestParamValue) {
        requestParamValue += ',';
      }
      if (v.id === 'import_result.duplicates_file' || v.id === 'appointment.icalendar') {
        requestParamValue += `${v.shortcode}`;
      } else {
        requestParamValue += `evidence.${v.shortcode}.file`;
      }
    });
    return requestParamValue;
  };

  const handleIntegrationData = async () => {
    if (checkErrors()) {
      dispatch(
        addToast({
          error: true,
          text: 'Please enter all required fields before enabling this integration',
          id: 'error-required-field',
        }),
      );
      return;
    }
    try {
      const selectedConfigs = getSelectedConfigs();
      const actionData =
        actionObj.editIntegration || isEdit
          ? { schedule: actionResponse?.schedule, conditions: actionResponse?.conditions }
          : {};
      if (
        actionData?.schedule &&
        (!actionData?.schedule?.field || actionData?.schedule?.offset === null || !actionData?.schedule?.unit)
      ) {
        delete actionData.schedule;
      }
      const request = {
        ...actionData,
        step_number: 0,
        action_type: actionObj.action_type,
        integration: {
          id: selectedIntegrationObj?.id,
        },
        params:
          selectedConfigs
            .filter(p => integration[p.id])
            .map(param => ({
              param_type: param.id,
              param_value:
                param.param_type === 'ATTACHMENTS'
                  ? getAttachmentrequest([...integration[param.id]])
                  : param.param_type === 'LIST'
                  ? integration[param.id]?.id
                  : integration[param.id] || null,
              display_value:
                param.param_type === 'ATTACHMENTS'
                  ? getAttachmentsForDisplay([...integration[param.id]])
                  : param.param_type === 'LIST'
                  ? integration[param.id]?.name
                  : integration[param.id] || null,
            })) || [],
      };

      setIsLoading(true);
      const { data } =
        actionObj.editIntegration || isEdit
          ? await api.put(`/v3/api/automations/${automationObj.id}/actions/${actionObj.id}`, request)
          : await api.post(`/v3/api/automations/${automationObj.id}/actions`, request);
      updateActionResponseAndAction(data, actionObj);
      setShowButtons(true);
      setIsLoading(false);
      setIsEdit(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const updateActionResponseAndAction = (data, actionObj) => {
    setActionResponse(data);
    updateAction(actionObj, data);
  };

  const getAttachmentsForDisplay = value => {
    let displayValue = '';
    value.forEach(v => {
      if (displayValue) {
        displayValue += ', ';
      }
      displayValue += `${v.name}`;
    });
    return displayValue;
  };

  const fetchListOptions = async list => {
    const { data } =
      await api.get(`/v3/api/integrations/${selectedIntegrationObj?.id}/action/${actionObj.action_type}/lists/${list.id}
    `);
    return data;
  };

  const fetchAttachmentsOptions = async () => {
    const { data } = await api.get(`/v3/api/evidence_rules`);
    const hardcodedOptions = [
      {
        label: 'Appointments ics',
        name: 'Appointments ics',
        id: 'appointment.icalendar',
        value: 'appointment.icalendar',
        shortcode: 'appointment.icalendar',
      },
      {
        label: 'Lead import duplicates',
        name: 'Lead import duplicates',
        id: 'import_result.duplicates_file',
        value: 'import_result.duplicates_file',
        shortcode: 'import_result.duplicates_file',
      },
    ];
    const updatedData = data.map(o => ({ ...o, value: o.id, label: o.name }));
    return [...hardcodedOptions, ...updatedData];
  };

  const fetchIntegrationConfigData = async () => {
    try {
      setIsLoadingRight(true);
      const { data } =
        await api.get(`/v3/api/integrations/${selectedIntegrationObj?.id}/action/${actionObj.action_type}/config

    `);
      const defaultLists = data.filter(c => c.param_type === 'LIST' && !c.required_params);
      const attachmentsAvailable = data.filter(c => c.param_type === 'ATTACHMENTS');
      let integrationDataTemp = { ...integration };
      if (defaultLists.length > 0) {
        let result = {};

        const promiseResult = await Promise.all(
          defaultLists.map(async s => {
            const data = await fetchListOptions(s);
            const currentSelected = data.find(
              d => d.id === (integrationDataTemp[s.id]?.id || integrationDataTemp[s.id]),
            );
            integrationDataTemp[s.id] = currentSelected;
            return { id: s.id, data };
          }),
        );
        result = promiseResult.reduce((acc, curr) => {
          acc[curr.id] = curr.data;
          return acc;
        }, {});
        setListOptions(result);
      }
      const updatedData = data.map(d =>
        d.param_type === 'LIST' && d.required_params?.length > 0 ? { ...d, show: false } : d,
      );
      if (attachmentsAvailable.length > 0) {
        let result = {};
        const data = await fetchAttachmentsOptions();
        result = attachmentsAvailable.reduce((acc, curr) => {
          acc[curr.id] = data;
          integrationDataTemp[curr.id] = [];
          return acc;
        }, {});
        setAttachmentsOptions(result);
      }
      setIntegrationConfigData(updatedData);
      setIntegration({ ...integrationDataTemp });
      setIsLoadingRight(false);
    } catch (e) {
      dispatch(
        addToast({ error: true, text: 'Error while getting integration config', id: 'config-error' }),
      );
      setDisable(true);
      setIsLoadingRight(false);
    }
  };

  const getOptions = async inputValue => {
    const { data } = await api.get(`/v3/api/evidence_rules?search=${inputValue}`);
    return data.map(o => ({ ...o, value: o.id, label: o.name }));
  };

  const loadOptions = async inputValue => {
    const options = await getOptions(inputValue);
    return options;
  };

  const handleInputChange = (newValue, id) => {
    setInputState({ ...inputState, [id]: newValue });
    return newValue;
  };

  const handleChange = (value, id) => {
    setIntegration({ ...integration, [id]: value });
  };

  const updateIntegrationParams = async (name, value) => {
    const requiredList = integrationConfigData
      .filter(d => d.param_type === 'LIST' && d.required_params?.length > 0)
      .filter(t => t.required_params.includes(name))
      .filter(
        s => !(s.required_params.filter(rp => rp !== name && integration[rp]).length + 1 < s.required_params.length),
      );
    let newIntegration = { ...integration };
    if (requiredList.length > 0) {
      setIsLoadingRight(true);
      let result = { ...listOptions };
      const promiseResult = await Promise.all(
        requiredList.map(async s => {
          let additionalParams = `?`;
          s.required_params.forEach(fe => {
            additionalParams += `${fe}=${fe === name ? value.id : integration[fe].id}`;
          });
          const { data } =
            await api.get(`/v3/api/integrations/${selectedIntegrationObj?.id}/action/${actionObj.action_type}/lists/${s.id}${additionalParams}
        `);
          return { id: s.id, data };
        }),
      );
      result = promiseResult.reduce((acc, curr) => {
        acc[curr.id] = curr.data;
        return acc;
      }, {});
      const newConfigData = integrationConfigData.map(config =>
        requiredList.find(ls => ls.id === config.id) ? { ...config, show: true } : { ...config },
      );
      requiredList.forEach(r => {
        newIntegration = { ...newIntegration, [r.id]: null };
      });
      setListOptions({ ...listOptions, ...result });
      setIntegrationConfigData(newConfigData);
      setIsLoadingRight(false);
    }
    if (inputRefs.current?.[name]) {
      inputRefs.current[name].style.height = '0px';
      const scrollHeight = inputRefs.current[name].scrollHeight;
      inputRefs.current[name].style.height = scrollHeight + 'px';
    }
    setIntegration({
      ...newIntegration,
      [name]: value,
    });
    setErrorFields({ ...errorFields, [name]: false });
  };

  const onEditSelectedAction = () => {
    fetchIntegrationConfigData();
    setIsEdit(true);
    setShowButtons(false);
  };

  const onDeleteSelectedAction = () => {
    removeIntegrationFromAction(actionObj);
  };

  const onCancel = () => {
    if (isEdit) {
      if (actionObj?.integration?.id) {
        setShowButtons(true);
        setIntegrationConfigData(actionObj?.params);
        setIntegration(setIntegrationParams(actionObj?.params));
        setActionResponse(actionObj);
        setIsEdit(false);
      }
    } else {
      setSelectedIntegration(false);
    }
  };

  return (
    <SelectedIntegrationAutomationWrapper>
      <div>
        {!showButtons ? (
          isLoading ? (
            <div className="flex items-center justify-center h-full">
              <img alt="loading" height="40px" src={Loading} />
            </div>
          ) : (
            <>
              <div style={{ display: 'flex' }}>
                <div>
                  {/* left side */}
                  <div className="left-side-container">
                    <label className="medium-text font-16">{selectedIntegrationObj?.name}</label>
                    <div style={{ marginTop: 24 }}>
                      <img
                        alt="icon"
                        src={`${selectedIntegrationObj?.connector?.icon?.active}`}
                        style={{ width: 200, height: 200, marginRight: 8 }}
                      />
                    </div>
                  </div>
                </div>
                {/* right side */}
                <div className="right-side-data">
                  {isLoadingRight ? (
                    <div className="flex items-center justify-center h-full">
                      <img alt="loading" height="40px" src={Loading} />
                    </div>
                  ) : (
                    integrationConfigData.map(config => (
                      <>
                        {config.conditional ? (
                          <>
                            {config.conditional.param_value === integration[config.conditional.param_name] && (
                              <div className="flex-column mt-6">
                                <label className="regular-text mb-2 font-12 grey-text">{config.name}</label>
                                <div className="integration-input">
                                  <textarea
                                    ref={element => (inputRefs.current[config.id] = element)}
                                    autoComplete="off"
                                    className={`popup-input form-input api-key-input config-input textarea-input ${
                                      errorFields[config.id] ? 'error-info' : ''
                                    } ${
                                      config.param_type === 'PASSWORD' && !showPassword[config.id] && 'password-mask'
                                    }`}
                                    onChange={e => updateIntegrationParams(config.id, e.target.value)}
                                    placeholder={config.name}
                                    type="text"
                                    value={integration[config.id] || ''}
                                  />
                                  {config.param_type === 'PASSWORD' && (
                                    <div className="input-show-img">
                                      <img
                                        alt="icon"
                                        onClick={() => setShowPassword({ [config.id]: !showPassword[config.id] })}
                                        src={showPassword[config.id] ? ShowPassword : HidePassword}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        ) : config.param_type === 'LIST' ? (
                          !config.required_params ? (
                            <div className="flex-column mt-6">
                              <label className="regular-text mb-2 font-12 grey-text">{config.name}</label>
                              <Dropdown
                                className={`dropdown-hover ${errorFields[config.id] ? 'error-info' : ''}`}
                                dropdownHeight={'115px'}
                                onSelect={option => updateIntegrationParams(config.id, option)}
                                options={listOptions[config.id] || []}
                                placeholder={config.name}
                                scrollEnabled={true}
                                selected={(listOptions[config.id] || []).find(
                                  type => type.id === (integration[config.id]?.id || integration[config.id]),
                                )}
                                withIcon={false}
                              />
                            </div>
                          ) : config.show ? (
                            <div className="flex-column mt-6">
                              <label className="regular-text mb-2 font-12 grey-text">{config.name}</label>
                              <Dropdown
                                className={`dropdown-hover ${errorFields[config.id] ? 'error-info' : ''}`}
                                dropdownHeight={'115px'}
                                onSelect={option => updateIntegrationParams(config.id, option)}
                                options={listOptions[config.id] || []}
                                placeholder={config.name}
                                scrollEnabled={true}
                                selected={(listOptions[config.id] || []).find(
                                  type => type.id === (integration[config.id]?.id || integration[config.id]),
                                )}
                                withIcon={false}
                              />
                            </div>
                          ) : (
                            <></>
                          )
                        ) : config.param_type === 'ATTACHMENTS' ? (
                          <div className="flex-column mt-6">
                            <label className="regular-text mb-2 font-12 grey-text">{config.name}</label>
                            <AsyncSelect
                              className={`options-select-container attachment-options ${
                                errorFields[config.id] ? 'error-info' : ''
                              } ${dropdownOpened ? 'opened' : ''}`}
                              classNamePrefix="select"
                              closeMenuOnSelect={false}
                              components={{ Option: MultiValueOption }}
                              defaultOptions={attachmentsOptions[config.id] || []}
                              hideSelectedOptions={false}
                              inputValue={inputState[config.id] || ''}
                              isMulti
                              loadOptions={loadOptions}
                              onChange={newValue => handleChange(newValue, config.id)}
                              onInputChange={newValue => handleInputChange(newValue, config.id)}
                              onMenuClose={() => setDropdownOpened(false)}
                              onMenuOpen={() => setDropdownOpened(true)}
                              options={attachmentsOptions[config.id] || []}
                              placeholder="Select attachments"
                              value={integration[config.id]}
                            />
                          </div>
                        ) : (
                          <div className="flex-column mt-6">
                            <label className="regular-text mb-2 font-12 grey-text">{config.name}</label>
                            <div className="integration-input">
                              <textarea
                                ref={element => (inputRefs.current[config.id] = element)}
                                autoComplete="off"
                                className={`popup-input form-input api-key-input config-input textarea-input ${
                                  errorFields[config.id] ? 'error-info' : ''
                                } ${config.param_type === 'PASSWORD' && !showPassword[config.id] && 'password-mask'}`}
                                onChange={e => updateIntegrationParams(config.id, e.target.value)}
                                placeholder={config.name}
                                type="text"
                                value={integration[config.id] || ''}
                              />
                              {config.param_type === 'PASSWORD' && (
                                <div className="input-show-img">
                                  <img
                                    alt="icon"
                                    onClick={() => setShowPassword({ [config.id]: !showPassword[config.id] })}
                                    src={showPassword[config.id] ? ShowPassword : HidePassword}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ))
                  )}
                </div>
              </div>
              <div className="button-container">
                <Button
                  className={`secondary-hover cancel-save-button`}
                  label="Cancel"
                  onClick={() => onCancel()}
                  secondary={true}
                  size="large"
                />
                <Button
                  className={`primary-hover cancel-save-button ${(disable || isLoadingRight) && 'disabled-hover'}`}
                  disabled={disable || isLoadingRight}
                  label="Save"
                  onClick={() => {
                    handleIntegrationData();
                  }}
                  primary={true}
                  size="large"
                  style={{ marginLeft: 12 }}
                />
              </div>
            </>
          )
        ) : (
          <div
            className="mb-6"
            onMouseLeave={() => setShowIntegrationAction(false)}
            onMouseMove={() => setShowIntegrationAction(true)}>
            <div className="flex items-center">
              <div className="flex items-center flex-1">
                <div>
                  <img
                    alt="icon"
                    src={`${selectedIntegrationObj?.connector?.icon?.active}`}
                    style={{ width: 25, height: 25, marginRight: 8 }}
                  />
                </div>
                <label className="medium-text">{selectedIntegrationObj?.name}</label>
              </div>
              {showIntegrationAction && (
                <div className="flex mx-1">
                  <div className="mx-1 cursor" onClick={() => onEditSelectedAction()}>
                    <EditIcon />
                  </div>
                  <div className="mx-1 cursor" onClick={() => onDeleteSelectedAction()}>
                    <DeleteIcon />
                  </div>
                </div>
              )}
            </div>
            <div className="mt-2">
              {actionResponse.params.map(p => (
                <div className="flex items-center mb-2">
                  <label className="regular-text grey-text mr-1">{capitalize(formatText(p.param_type, ' '))}:</label>
                  <label className="regular-text">{p.display_value || p.param_value}</label>
                </div>
              ))}
            </div>
          </div>
        )}
        {showButtons && (
          <>
            <hr />
            <ScheduleAndConditionData
              actionObj={actionObj}
              actionResponse={actionResponse}
              automationObj={automationObj}
              integration={selectedIntegrationObj}
              setActionResponse={data => updateActionResponseAndAction(data, actionObj)}
              triggerData={triggerData}
            />
          </>
        )}
      </div>
    </SelectedIntegrationAutomationWrapper>
  );
}

export default SelectedIntegrationAutomation;
