import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSupporters } from "../../store/features/supporterSlice";
import moment from "moment";
import Header from "../../components/header";
import SupporterCard from "../../components/project/SupporterCard";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './style.scss'

const Leaderboards = () => {
    const dispatch = useDispatch();
    const { supporters } = useSelector(data => data.supporter);
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')
    const [selected, setSelected] = useState('month')
    const [startDate, setStartDate] = useState(moment().startOf('month').unix());
    const [endDate, setEndDate] = useState(moment().endOf('month').unix());

    useEffect(() => {
        if(selected === 'day') {
            setStartDate(moment().startOf('day').unix());
            setEndDate(moment().endOf('day').unix())
        } else if(selected === 'month') {
            setStartDate(moment().startOf('month').unix());
            setEndDate(moment().endOf('month').unix())
        } else if(selected === 'year') {
            setStartDate(moment().startOf('year').unix());
            setEndDate(moment().endOf('year').unix())
        }
    }, [selected]);

    useEffect(() => {
        dispatch(getSupporters({page: currentPage, startDate, endDate}));
    }, [currentPage, startDate, endDate, dispatch]);

    return (
        <div className="flex-column mb-6 mt-2 flex-1">
            <Header
                title={"Leaderboards"}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                search={search}
                setSearch={setSearch}
                totalPages={supporters.total_pages}
                isAddBtn={false}
                isTimeToggle={true}
                isFilter={false}
                selectedTime={selected}
                setSelectedTime={setSelected}
                />

            <div className="supporter-cards">
            <TransitionGroup>
                {supporters.content?.map((supporter, index) =>
                    <CSSTransition key={index} appear classNames="fade" timeout={500}>
                        <SupporterCard supporter={supporter} isProject={true} />
                    </CSSTransition>
                )}
                </TransitionGroup>
            </div>
        </div>
    )
}

export default Leaderboards;
