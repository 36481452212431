import React, { useEffect, useState, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProject, setProject, getMediaGroups, setMediagroups, createMediaGroup, getMedia, deleteMedia, createMedia, getSupporters, getSdgs, getCarbon, saveProject, setMedia, uploadImage, addProject } from "../../store/features/projectSlice";
import { getOrganisations } from '../../store/features/organisationSlice';

import { useParams, useNavigate, useLocation } from "react-router-dom";

import NavTop from "../../components/common/NavTop";
import CarbonList from "../../components/projectDetails/CarbonList";
import MediaList from "../../components/projectDetails/MediaList";
import ProjectLeaderboard from "../../components/projectDetails/ProjectLeaderboard";
import ProjectLocation from "../../components/projectDetails/ProjectLocation";
import AddMediagroupPopup from "../../components/projectDetails/AddMediagroupPopup";
import Pagination from "../../components/pagination-grey/pagination";
import DropDown from "../../components/McDropdown";
import Image from "../../components/image";
import EditDeleteMenu from "../../components/Elements/EditDeleteMenu";
import Button from "../../components/Elements/button/button";

import DotsIcon from '../../assets/images/dots.svg'
import AddIcon from '../../assets/images/add.svg'
import EditIcon from "../../assets/images/edit-green.svg";

import './details.scss';
import MainDetails from "../../components/projectDetails/MainDetails";
import SDGData from "../../components/projectDetails/SDGData";
import TimeToggle from "../../components/timeToggle";
import moment from "moment";
import AddCarbonPopup from "../../components/projectDetails/AddCarbonPopup";
import DeleteMediaPopup from "../../components/projectDetails/DeleteMediaPopup";
import { OrganisationContext } from "../../context/organisationContext";
import Loading from '../../assets/images/loading.svg';
import { getTemplates } from "../../store/features/templateSlice";
import placeholder from "../../assets/images/project-placeholder.svg";

const ProjectDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { project, mediaGroups, media, supporters, sdgs, loading } = useSelector(data => data.project);
    const { user, organisations, organisation } = useSelector(data => data.user);
    const { templates } = useSelector(data => data.template);

    const { setModal, modal, checkPermission, hasPermission } = useContext(OrganisationContext);
    const [currentPage, setCurrentPage] = useState(0)

    const {id} = useParams();

    const headlineRef = useRef(null);
    const pageRef = useRef(null);

    const [menuOpen, setMenuOpen] = useState(false);
    const [showAddMediaGroup, setShowAddMediaGroup] = useState(false);
    const [filterSelected, setFilterSelected] = useState('month')
    const [isCarbonDialog, setIsCarbonDialog] = useState(false);
    const [deletedMedia, setDeletedMedia] = useState();
    const [search, setSearch] = useState();
    const [edit, setEdit] = useState(id === 'new' || false);
    const [editLocation, setEditLocation] = useState(false)
    const [editCarbon, setEditCarbon] = useState();

    const owner = organisations[0].org_type === 'SAAS_OPERATOR' || project?.owner?.id === organisation

    const [selected, setSelected] = useState(owner ? 'main' : 'carbon');

    useEffect(() => {
        if(headlineRef.current) {
            headlineRef.current.style.height = '0px';
            const scrollHeightHeadline = headlineRef.current.scrollHeight;
            headlineRef.current.style.height = scrollHeightHeadline + 5 + 'px';
        }
    }, [project?.headline, edit]);

    useEffect(() => {
        if(id !== "new") {
            dispatch(setMedia([]))
            dispatch(getProject(id));
        } else {
            dispatch(setProject({}))
        }
    }, [id, dispatch]);

    const fetchOrganisations = async (page = 0, merge = false) => {
        pageRef.current = page;
        dispatch(getOrganisations({organisationId: user.organisations[0].external_id, page: page, search: search, merge: merge, size: 20, type: user.organisations[0].org_type === 'SERVICEOPERATOR' ? 'ACCOUNT' : 'PARTNER'}));
      };
  
      useEffect(() => {
        fetchOrganisations(0, false);
      }, [search])

      useEffect(() => {
        dispatch(getTemplates())
    }, []);

    useEffect(() => {
        if (selected === 'media')
            dispatch(getMediaGroups(id));
    }, [id, selected, dispatch]);


    useEffect(() => {
        if(selected === 'sdg')
            dispatch(getSdgs(id));

    }, [id, selected, dispatch]);

    useEffect(() => {
        if (selected === 'leaderboard') {
            let start;
            let end;
            if (filterSelected === 'month') {
                start = moment().startOf('month').unix();
                end = moment().endOf('month').unix()
            } else if (filterSelected === 'day') {
                start = moment().startOf('day').unix();
                end = moment().endOf('day').unix()
            } else if (filterSelected === 'year') {
                start = moment().startOf('year').unix();
                end = moment().endOf('year').unix()
            }

            dispatch(getSupporters({projectId: id, start: start, end: end, page: currentPage}));
        } 
    }, [id, selected, filterSelected, currentPage, dispatch]);

    const changeSelected = s => {
        setSelected(s);
        setMenuOpen(false);
    }

    const onAdd = () => {
        if (selected === 'media')
            setShowAddMediaGroup(true)
        else if (selected === 'carbon') {
            setIsCarbonDialog(true)
        }
    }

    const addMediaGroup = async name => {
        dispatch(createMediaGroup({projectId: id, name: name}));
        setShowAddMediaGroup(false);
    };

    useEffect(() => {
        if(selected === 'media') {
            const selectedMediaGroup = mediaGroups.find(mg => mg.selected);
            if (selectedMediaGroup) {
                dispatch(getMedia(selectedMediaGroup));
            }
        }
    }, [selected, mediaGroups, dispatch])

    const uploadMedia = async file => {
        if (file) {
            const selectedMediaGroup = mediaGroups.find(mg => mg.selected);
            dispatch(createMedia({file: file, selectedMediaGroup: selectedMediaGroup}))
        }
    }

    const getSelectedLabel = () => {
        if (selected === 'carbon') {
            return 'Carbon list';
        } else if (selected === 'media') {
            return 'Media list'
        } else if (selected === 'sdg') {
            return 'Sustainable development goals (SDG)'
        } else if (selected === 'leaderboard') {
            return 'Leaderboard'
        } else if (selected === 'location') {
            return 'Location'
        } else {
            return 'Main details';
        }
    }

    const removeMedia = async() => {
        dispatch(deleteMedia({mediaGroupId: deletedMedia.media_group_external_id, mediaId: deletedMedia.media_external_id}))
        setDeletedMedia(null);
    }

    const changeProject = project => {
        dispatch(setProject(project))
    }

    const onImageUpload = file => {
        if (file) {
            dispatch(uploadImage({file: file}))
        }
    }

    const setPrimary = image => {
        dispatch(setProject({...project, image: image}))
    }

    const onSave = async () => {
        if(id === 'new') {
            const p = await dispatch(addProject({...project, image: {id: project.image?.media_external_id}}))
            navigate(`/projects/${p.id}`, {replace: true, state: {image: project.image}})
        } else
            dispatch(saveProject({...project, image: {id: project.image?.media_external_id}}))
        setEdit(false)
        setEditLocation(false)
    }

    return (
        <div className="flex-column flex-1">
            <div className="pb-6 mt-8 ml-6">
                <NavTop
                    names={[{text: "Projects", path: "/projects"}, {text: loading ? '' : project?.name || 'New Project'}]} 
                    savePermission="PROJECTS_MANAGE"
                    done={true}
                    edit={false}
                    onDone={onSave}
                />
            </div>
            {loading ? 
            <div className="flex flex-1 items-center justify-center mt-4">
                <img src={Loading} />
                <label className="bold-text font-16 ml-2 light-purple-text">Loading</label>
            </div>
            :
            <div className="flex ml-6">
                <div className="flex-column card pxy-6 h-content project-info">
                    {edit ?
                        <div className="flex-column">
                            <Image className={"project-details-image"} size={224} media={project?.image?.media} placeholder={placeholder} uploadable={true} onUpload={onImageUpload} editable={true} onRemove={() => dispatch(setProject({...project, image: null}))} />
                            <label className="input-label mt-6">Name</label>
                            <input className="input" value={project?.name || ''} onChange={e => changeProject({...project, name: e.target.value })} placeholder="Name" />
                            <label className="input-label mt-6">Headline</label>
                            <textarea className="textarea" value={project?.headline || ''} ref={headlineRef} onChange={e => changeProject({...project, headline: e.target.value})} placeholder="Description"/>
                            {/* <label className="input-label mt-6">Template</label>
                            <DropDown size="large" placeholder="Select template" options={templates} selected={project.template} setSelected={t => changeProject({...project, template: t}) } />
                            <label className="input-label mt-6">Organisation</label>
                            <DropDown 
                                size="large" 
                                placeholder="Select organisation" 
                                options={organisations} 
                                selected={project.organisation}
                                setSelected={p => changeProject({...project, organisation: p})}
                                isSearchable={true}
                                search={search}
                                setSearch={setSearch}
                                isPaged={true}
                                hasMore={hasMore}    
                                fetchData={() => fetchOrganisations(pageRef.current + 1, true)}   
                            /> */}

                            <div className="enable-switch-container mt-6">
                                <label className="regular-text font-16">Published</label>
                                <div
                                    className={`switch cursor ml-1 ${project?.is_published && 'switch-selected'}`}
                                    onClick={e => changeProject({...project, is_published: !project.is_published })}>
                                    <div className="selector" />
                                </div>
                            </div>
                            {id !== 'new' &&
                            <div className="flex justify-center mt-10">
                                <Button
                                    className="secondary-hover"
                                    label="Cancel"
                                    borderRadius="12px"
                                    onClick={() => setEdit(false)}
                                    secondary={true}
                                    size="average"
                                    style={{ flex: 1 }}
                                    bgColor='#FFFFFF'
                                    borderColor="#24CBB1"
                                    color="#24CBB1"
                                    borderWidth="1px"
                                />
                                <Button
                                    label='Save'
                                    onClick={() => checkPermission(() => onSave(), 'PROJECTS_MANAGE')}
                                    size="average"
                                    style={{ flex: 1, marginLeft: 24 }}
                                    bgColor='#24CBB1'
                                    borderColor="#24CBB1"
                                    color='#FFFFFF'
                                    borderRadius="12px"
                                    borderWidth="1px"
                                    disabled={!hasPermission('PROJECTS_MANAGE')}
                                />
                            </div>
                            }
                        </div>
                        :
                        <div className="flex-column">
                            <div className="flex items-center justify-between mb-6">
                                <label className="bold-text font-20">Project details</label>
                                <EditDeleteMenu onEdit={() => setEdit(!edit)} onDelete={() => setModal({ type: 'delete-project', content: { project: project, top: true } }) } />
                            </div>
                            <Image className={"project-details-image"}  size={224} media={project?.image?.media} placeholder={placeholder}  />
                            <label className="semibold-text font-16 mt-6">{project?.name}</label>
                            <label className="regular-text light-purple-text mt-2">{project?.headline}</label>
                        </div>
                    }
                </div>
                <div className="card flex-column card project-details mx-6">
                    <div className="flex items-center justify-between pd-top px-6">
                        <div className="flex items-center">
                            <label className="bold-text font-20">{getSelectedLabel(selected)}</label>
                        </div>
                        <div className="flex items-center relative" onBlur={() => setMenuOpen(false)} tabIndex={1}>
                            {selected === 'media' && <div className="mr-6">
                                    <DropDown options={mediaGroups} selected={mediaGroups.find(mg => mg.selected)} setSelected={s => dispatch(setMediagroups(mediaGroups.map(o => ({ ...o, selected: o.id === s.id }))))}/>
                                </div>}
                            {selected !== 'carbon' && selected !== 'main' && selected !== 'sdg' && selected !== 'leaderboard' && selected !== 'location' &&
                            <div className="flex items-center justify-center add-new-btn cursor" style={{opacity: hasPermission('PROJECTS_MANAGE') ? 1 : 0.5}} onClick={() => checkPermission(() => onAdd(), 'PROJECTS_MANAGE')}>
                                <img src={AddIcon} alt="add"/>
                                <label className="bold-text font-14 violet-light-text ml-2">Add new</label>
                            </div>}
                            {selected === 'location' && !editLocation &&
                            <div className="flex items-center justify-center edit-btn cursor" style={{opacity: hasPermission('PROJECTS_MANAGE') ? 1 : 0.5}} onClick={() => checkPermission(() => setEditLocation(true), 'PROJECTS_MANAGE')}>
                                <img src={EditIcon} alt="edit"/>
                                <label className="bold-text font-14 violet-light-text ml-2">Edit</label>
                            </div>}
                            {editLocation &&
                            <div className="flex justify-center">
                                <Button
                                    className="secondary-hover"
                                    label="Cancel"
                                    borderRadius="12px"
                                    onClick={() => setEditLocation(false)}
                                    secondary={true}
                                    size="average"
                                    style={{ flex: 1 }}
                                    bgColor='#FFFFFF'
                                    borderColor="#24CBB1"
                                    color="#24CBB1"
                                    borderWidth="1px"
                                    width="112px"
                                    height="32px"
                                />
                                <Button
                                    label='Save'
                                    onClick={() => checkPermission(() => onSave(), 'PROJECTS_MANAGE')}
                                    size="average"
                                    style={{ flex: 1, marginLeft: 24 }}
                                    bgColor='#24CBB1'
                                    borderColor="#24CBB1"
                                    color='#FFFFFF'
                                    borderRadius="12px"
                                    borderWidth="1px"
                                    disabled={!hasPermission('PROJECTS_MANAGE')}
                                    width="112px"
                                    height="32px"
                                />
                            </div>
                            }
                            {selected === 'leaderboard' && <div className={"mr-6"}><TimeToggle selected={filterSelected} setSelected={setFilterSelected}/></div>}
                            {selected === 'leaderboard' && <Pagination selectedPage={currentPage} setSelectedPage={setCurrentPage} totalPages={supporters.total_pages} height={'32px'}/> }
                            {id !== 'new' && owner && <>
                                <img className="cursor ml-6" src={DotsIcon} onClick={() => setMenuOpen(!menuOpen)}/>
                                {menuOpen &&
                                <div className="card flex-column pxy-4-2  project-details-menu">
                                    <div className="flex pxy-3-4 project-details-menu-item cursor"
                                        onClick={() => changeSelected('main')}>
                                        <label className="regular-text">Main Details</label>
                                    </div>
                                    <div className="flex pxy-3-4 project-details-menu-item cursor"
                                        onClick={() => changeSelected('location')}>
                                        <label className="regular-text">Location</label>
                                    </div>
                                    <div className="flex pxy-3-4 project-details-menu-item cursor"
                                        onClick={() => changeSelected('carbon')}>
                                        <label className="regular-text">Carbon</label>
                                    </div>
                                    <div className="flex pxy-3-4 mt-1 project-details-menu-item cursor"
                                        onClick={() => changeSelected('media')}>
                                        <label className="regular-text">Media</label>
                                    </div>
                                    <div className="flex pxy-3-4 mt-1 project-details-menu-item cursor"
                                        onClick={() => changeSelected('sdg')}>
                                        <label className="regular-text">SDG</label>
                                    </div>
                                    <div className="flex pxy-3-4 mt-1 project-details-menu-item cursor"
                                        onClick={() => changeSelected('leaderboard')}>
                                        <label className="regular-text">Leaderboard</label>
                                    </div>
                                </div>
                                }
                            </>
                            }
                        </div>
                    </div>
                    <div className="pd-details">
                        {selected === 'carbon' && <CarbonList project={project} setEdit={item => {setIsCarbonDialog(true); setEditCarbon(item)}}/>}
                        {selected === 'media' && <MediaList primary={project?.image?.media_external_id} media={media} setFile={uploadMedia} deleteMedia={setDeletedMedia} addPermission="PROJECTS_MANAGE" hasPermission={hasPermission} checkPermission={checkPermission} setPrimary={setPrimary} />}
                        {selected === 'main' && <MainDetails project={project} changeProject={changeProject}/>}
                        {selected === 'sdg' && <SDGData sdgs={sdgs}/>}
                        {selected === 'leaderboard' && <ProjectLeaderboard supporters={supporters} /> }
                        {selected === 'location' && <ProjectLocation project={project} changeProject={changeProject} edit={editLocation} /> }
                    </div>

                    {isCarbonDialog && <AddCarbonPopup setIsCarbonDialog={() => {setIsCarbonDialog(false); setEditCarbon(null)}} carbon={editCarbon}/>}
                </div>
            </div>
            }
            {showAddMediaGroup && <AddMediagroupPopup close={() => setShowAddMediaGroup(false)} add={addMediaGroup}/>}
            {deletedMedia && <DeleteMediaPopup close={() => setDeletedMedia(null)} deleteMedia={removeMedia} /> }
        </div>
    )
}

export default ProjectDetails;
