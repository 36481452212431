import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import uuid from "react-uuid";
import { ReactComponent as AddIcon } from "../../../assets/images/add.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/images/remove-image.svg";
import { OrganisationContext } from "../../../context/organisationContext";
import DropDown from "../../McDropdown";
import api from "../../../store/services";
import { capitalize, formatText } from "../../../helpers/utils";


const TemplateAttributesEdit = ({
  templateAttributes,
  setTemplateAttributes,
  template,
}) => {
  const { setModal } = useContext(OrganisationContext);
  const [schemaAttributes, setSchemaAttributes] = useState([]);

  const getSchemaAttributes = async () => {
    const { data } = await api.get(
      `/v3/api/backoffice/schemas/${template?.schema?.id}/attributes`
    );
    setSchemaAttributes(data.map(d =>({...d, name: d.attribute_name, description: `Data type: ${capitalize(formatText(d.attribute_type || '-'))}`})));
  };

  useEffect(() => {
    if (template?.schema?.id) {
      getSchemaAttributes();
    }
  }, [template?.id]);

  const onAddAttribute = () => {
    setTemplateAttributes([{ id: uuid(), isNew: true }, ...templateAttributes]);
  };

  const updateAttribute = (attribute, name, value) => {
    setTemplateAttributes([
      ...templateAttributes.map((c) =>
        c.id === attribute.id ? { ...c, [name]: value } : c
      ),
    ]);
  };

  const removeAttribute = (attribute) => {
    setTemplateAttributes([
      ...templateAttributes.filter((c) => c.id !== attribute.id),
    ]);
  };

  const onRemoveAttribute = (attribute) => {
    setModal({
      type: "remove-template-attribute",
      content: {
        attribute: attribute,
        onSuccess: () => removeAttribute(attribute),
        top: true,
      },
    });
  };

  return (
    <div className="template-attributes-edit">
      <div
        className="add-new-attribute flex items-center justify-center mb-4 cursor"
        onClick={() => onAddAttribute()}
      >
        <AddIcon />
        <label className="regular-text font-12 ml-2">Add new attribute</label>
      </div>
      <div>
        {templateAttributes.map((ta) => (
          <div
            key={ta.id}
            className="flex-column border-bottom px-6 py-4 attribute-edit-item"
          >
            {ta.isNew ? (
              <>
                <label className="regular-text font-12 light-purple-text mb-1">
                  Schema
                </label>
                <DropDown
                  options={schemaAttributes}
                  selected={schemaAttributes.find(
                    (s) => s.id === ta?.schema_attribute?.id
                  )}
                  setSelected={(value) =>
                    updateAttribute(ta, "schema_attribute", value)
                  }
                  placeholder="Select attribute"
                  description={true}
                />
              </>
            ) : (
              <>
                <label className="regular-text font-12 light-purple-text">
                  {ta.schema_attribute?.attribute_name}
                </label>
              </>
            )}
            <div className="flex mt-2">
              <input
                className="input flex-1"
                placeholder="Enter data"
                onChange={(e) =>
                  updateAttribute(ta, "default_value", e.target.value)
                }
                value={ta.default_value}
              />
              <div
                className="flex items-center ml-2 cursor"
                onClick={() => onRemoveAttribute(ta)}
              >
                <RemoveIcon className="remove-icon" height={16} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TemplateAttributesEdit;
