import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getCampaignProjects } from "../../../store/features/campaignSlice";
import { useNavigate, useParams } from "react-router-dom";

import CampaignProjectRetire from "../CampaignProjectRetire";

import Loading from '../../../assets/images/loading.svg';
import Header from "../../header";

import './style.scss'

const CampaignRetire = ({campaign, isNewCampaign, menuItems, onMenuItemClick, totalOffsetAmount, totalRetireAmount, setViewNft}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id} = useParams();

    const { campaignProjects, loading } = useSelector(data => data.campaign);
    const { retirementAmounts } = useSelector(data => data.campaign) 

    useEffect(() => {
        if(id)
            dispatch(getCampaignProjects({campaignId: id}))
    }, [id])

    return(
        <div className="flex-column campaign-retire-comp">
            <div className="border-bottom">
                    <Header title='Retire'
                        isSearch={false}
                        isPaged={false}
                        size='small'
                        menuItems={menuItems}
                        onMenuItemClick={onMenuItemClick}
                        isAddBtn={false}
                        isBack={true}
                        back={() => navigate(-1)}
                        totalOffsetAmount={totalOffsetAmount}
                        totalRetirementAmount={(campaignProjects.map(cp => (retirementAmounts[cp.id] || 0)).map(a => parseInt(a)).reduce((s, a) => s + a, 0) * 1000)}
                        tabMenu={true}
                    />
            </div>
            {loading ? 
                <div className="flex flex-1 items-center justify-center mt-4">
                    <img src={Loading} alt="loading" />
                    <label className="bold-text font-16 ml-2 light-purple-text">Loading</label>
                </div>
                :
                campaignProjects.length > 0 && !isNewCampaign ?
                <div className="campaign-retire">
                    {campaignProjects.map(cp =>
                        <CampaignProjectRetire key={cp.id} campaign={campaign} campaignProject={cp} setViewNft={setViewNft} />
                    )}                
                </div>
                :
                <div className="flex items-center justify-center flex-1">
                    <label className="bold-text font-16 light-purple-text">There are no projects</label>
                </div>
            }
        </div>
    )
}

export default CampaignRetire;