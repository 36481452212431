import React, { useState } from "react";


import { ReactComponent as DotsIcon } from '../../../assets/images/dots.svg'

import './style.scss'

const EditDeleteMenu = ({onEdit, onDelete}) => {
    const [showMenu, setShowMenu] = useState();
    const menuItems = [{id: 0, name: 'Edit'}, {id: 1, name: 'Delete'}];

    const onMenuItemClick = item => {
        if(item.name === 'Delete') {
            onDelete();
        } else if(item.name === 'Edit') {
            onEdit();
        }
    }

    return (
        <div className="flex items-center justify-center edit-delete-menu relative cursor" style={{background: showMenu  ? '#F6F6F6' : '', borderRadius: showMenu ? '8px' : ''}} onClick={e => {e.stopPropagation(); setShowMenu(!showMenu)}} tabIndex={0} onBlur={() => setShowMenu(false)}>
        <DotsIcon className="cursor" width={20} /> 
        {showMenu && menuItems.length > 0 && <div className="edit-delete-menu-items flex-column card pxy-2 absolute">
            {menuItems.map((item, i) => 
                <div key={i} className="header-menu-item flex items-center pxy-2 cursor" onClick={() => onMenuItemClick(item)}>
                    <label className="regular-text">{item.name}</label>
                </div>
                )}
        </div>
        }
    </div>
    )
}

export default EditDeleteMenu;