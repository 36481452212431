
import React from "react";

import { Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement} from '@stripe/react-stripe-js';
import { useElements, useStripe } from "@stripe/react-stripe-js";

const StripePayment = ({clientSecret}) => {

    const elements = useElements();
    const stripe = useStripe();

    const onSubmit = async () => {
        const {error: submitError} = await elements.submit();
        if (submitError) {
          return;
        }

        const {error} = await stripe.confirmPayment({
            elements,
            clientSecret: clientSecret,
            confirmParams: {
              return_url: 'https://backoffice.dev2.meta-carbon.cloud/#/success',
            },
          });
          console.log(error)    
    }

    return (
        <div>
            <label className="font-20">Enter payment details</label>
            <form className="mt-6 justify-center items-center">
                <PaymentElement />
                <button className="popup-btn ml-8 mt-6" onClick={onSubmit}>Submit</button>
            </form>
        </div>
           
)
}

const StripeWrapper = ({clientSecret}) => {
    const stripePromise = loadStripe("pk_test_ZrWh52u51krqe0bWrtMRJqJe00VebZgLYi");

    return (
    <Elements stripe={stripePromise} options={{clientSecret: clientSecret}}>
        <StripePayment clientSecret={clientSecret} />
    </Elements>
    )

}

export default StripeWrapper;