import { ReactComponent as DotsIcon } from '../../../assets/images/menu/dots.svg';
import { ReactComponent as UsersIcon } from '../../../assets/images/users.svg';
import React, { useContext, useEffect, useState } from 'react';
import { OrganisationContext } from '../../../context/organisationContext';
import Menu from './menu';

const TeamItem = ({
  team,
  teamKey,
  setSelectedTeam,
  organisationId,
  hasPermission,
  checkPermission,
  accountOrganisationId,
}) => {
  const { setModal } = useContext(OrganisationContext);

  const [showMenu, setShowMenu] = useState(false);

  const { services, tenant } = team;

  const getBackgroundForService = key => {
    switch (key) {
      case 'Electrician':
        return '#8AB2FF';
      case 'Scaffolding':
        return '#F6BB23';
      case 'LEAD_GENERATOR':
      case 'Roofer':
        return '#44A9E2';
      default:
        return '#44A9E2';
    }
  };

  const menuClick = action => {
    setShowMenu(false);
    switch (action) {
      case 'ADD_USER':
        setModal({
          type: 'add-team-member',
          content: { selectedTeam: team, organisationId: organisationId, top: true },
        });
        break;
      case 'EDIT':
        setModal({ type: 'add-team', content: { team: team, isEdit: true } });
        break;
      case 'DELETE':
        setModal({ type: 'delete-team', content: { team: team, top: true } });
        break;
      default:
        break;
    }
  };

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowMenu(false);
    }
  };

  const isSameTenant = () => {
    return true;
  };

  return (
    <div className="data-match-row mb-4 cursor" onClick={() => setSelectedTeam(team)}>
      <div className="team-name flex-column">
        <label className="medium-text font-16 color-text">{team.name}</label>
        {/* {!isSameTenant() && <label className="regular-text grey-text font-12">Shared by {tenant.name}</label>} */}
      </div>
      <div className="border-right" />
      <div className="team-description pt-4 pb-3">
        {team.description ? (
          <label className="regular-text grey-text font-14">{team.description}</label>
        ) : (
          <label className="regular-text organisation-empty">No description</label>
        )}
      </div>
      <div className="border-right" />
      <div className="flex justify-between services-width">
        <div className="flex ml-6 mt-4 mb-4">
          <div className="tu-organisations">
            {services?.length > 0 ? (
              <>
                {services.map((service, index) => (
                  <label key={`${service.id}`} style={{ background: getBackgroundForService(service.name) }}>
                    {service.name}
                  </label>
                ))}
              </>
            ) : (
              <div>
                <span className="regular-text organisation-empty">No service available</span>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex">
            <UsersIcon />
            <label className="regular-text font-14 ml-1">{team.adminuser_count}</label>
          </div>
          {isSameTenant() ? (
            <div className="relative ml-6 mr-6" onBlur={onBlur} tabIndex={0}>
              <div
                className="menu-btn cursor flex items-center relative px-2 cursor menu-hover pxy-2"
                onClick={e => {
                  e.stopPropagation();
                  setShowMenu(!showMenu);
                }}>
                <DotsIcon className="dots-icon" />
              </div>
              {showMenu && (
                <div>
                  <Menu
                    checkPermission={checkPermission}
                    clickItem={action => menuClick(action)}
                    hasPermission={hasPermission}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="relative ml-6 mr-6 empty-menu-space" />
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamItem;
