import React from 'react';

import "../../pages/projectDetails/details.scss";

const ProjectLeaderboard = ({ supporters }) => {
    return (
        <div className="mt-2 mb-6 border-top">
           {supporters.content?.map((supporter, i) => 
            <div key={i} className="project-leaderboard border-bottom items-center px-6">
                <div className='flex items-center project-leaderboard-item border-right'>
                    <div className='flex items-center'>
                        <div className='flex items-center justify-center pl-rank'>
                            <label className='bold-text font-16'>{supporter.rank}</label>
                        </div>
                        <label className='regular-text font-16 ml-2 o05'>Rank</label>
                    </div>
                </div>
                <div className='flex items-center project-leaderboard-item border-right px-6'>
                    <div className='flex-column'>
                        <div  className='flex items-center'>
                            <label className='regular-text font-12 light-purple-text'>Contact:</label>
                            <label className='medium-text font-12 ml-2'>{supporter.contact?.forename} {supporter.contact?.surnamre}</label>
                        </div>
                        <div  className='flex items-center mt-2'>
                            <label className='regular-text font-12 light-purple-text'>Alias:</label>
                            <label className='medium-text font-12 ml-2'>{supporter.contact?.alias}</label>
                        </div>
                    </div>
                </div>
                <div className='flex items-center project-leaderboard-item border-right px-6'>
                    <div className='flex-column'>
                        <div  className='flex items-center'>
                            <label className='regular-text font-12 light-purple-text'>Wallet:</label>
                            <label className='medium-text font-12 ml-2'>{supporter.wallet?.wallet_address}</label>
                        </div>
                        <div  className='flex items-center mt-2'>
                            <label className='regular-text font-12 light-purple-text'>Alias:</label>
                            <label className='medium-text font-12 ml-2'>{supporter.wallet?.alias}</label>
                        </div>
                    </div>
                </div>
                <div className='flex items-center project-leaderboard-item pl-6'>
                    <div className='flex items-center'>
                        <label className='bold-text font-16'>{supporter.co2_kg_units}</label>
                        <label className='regular-text light-purple-text ml-1'>kilos</label>
                    </div>
                </div>
            </div>  
            )}
        </div>
    );
};

export default ProjectLeaderboard;