import React from 'react';
import { useNavigate } from "react-router-dom";


import ArrowRight from '../../assets/images/menu/arrow-right-next.svg';

const ProductTop = ({name, goBack}) => {
    const navigate = useNavigate();
    return (
        <div className="flex items-center justify-between mb-6 mt-8 ml-6">
            <div className="flex items-center">
                <label className="regular-text grey-text cursor" onClick={() => goBack()}>Products</label>
                <img src={ArrowRight} className="mx-2 nav-arrow" />
                <label className="bold-text">{name}</label>
            </div>

            <div className='nft-actions'>
                <button onClick={() => navigate(-1)}>Cancel</button>
                <button className='save-action'>Save</button>
            </div>
        </div>
    )
}

export default ProductTop;
