import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRetirements } from "../../store/features/retirementSlice";
import RetirementCard from "../../components/project/RetirementCard";
import AddRetirementPopup from "../../components/campaignNft/addRetirementPopup";

import Header from "../../components/header";
import Filter from "../../components/filter";

import './style.scss';

const Retirements = () => {
    const dispatch = useDispatch();
    const { retirements } = useSelector(data => data.retirement);
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')
    const [lifecycles, setLifecycles] = useState([{ id: 1, name: 'PENDING', color: '#FBBF66', selected: false}])
    const [filter, setFilter] = useState({value: ''});
    const [showRetirement, setShowRetirement] = useState();

    useEffect(() => {
        dispatch(getRetirements({
            page: currentPage, size: 15,
             pending: lifecycles.find(l => l.selected && l.id === 1)?.selected || false,
             filter: filter?.value}));
    }, [currentPage, lifecycles, dispatch, filter?.value]);

    return (
        <div className="flex-column mb-6 mt-2 flex-1">
            <Header title={"Retirements"}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    search={search}
                    setSearch={setSearch}
                    isFilter={true}
                    filterChildren={<Filter objects={["PROJECT", "OWNER"]} setFilter={setFilter}  values={filter?.filters}/>}
                    totalPages={retirements.total_pages}
                    addPermission="RETIREMENTS_MANAGE"
                    children={
                        <div className="flex items-center no-select mr-6">
                                {lifecycles?.map(lifecycle => 
                                <div 
                                    key={lifecycle.id}
                                    className="flex items-center justify-center lifecycle-selector ml-2 cursor" 
                                    onClick={() => setLifecycles(lifecycles.map(l => l.id === lifecycle.id ? ({...l, selected: !l.selected}) : l))}
                                    style={{background: lifecycle.selected ? lifecycle.color : 'rgb(170, 186, 183, 0.1)'}}
                                    >
                                    <label className="bold-text font-12" style={{color: lifecycle.selected ? '#FFFFFF' : '#020819'}}>{lifecycle.name}</label>
                                </div>)}
                        </div>}
                    />

            <div className="flex-column card mx-6 mt-2">
                <div className="ret-grid ret-header border-top border-right border-left">
                    <label className="font-12 semibold-text px-6 py-4">DATE</label>
                    <label className="font-12 semibold-text px-6 py-4">AMOUNT</label>
                    <label className="font-12 semibold-text px-6 py-4">PROJECT</label>
                    <label className="font-12 semibold-text px-6 py-4">REGISTRY</label>
                    <label className="font-12 semibold-text px-6 py-4">BENEFICIARY</label>
                    <label className="font-12 semibold-text px-6 py-4">STATUS</label>
                </div>
                <div className="ret-list">
                    {retirements.content?.map(retirement => <RetirementCard key={retirement.id} retirement={retirement} />)}
                </div>
            </div>
            {/* {showRetirement && <AddRetirementPopup campaignProject={campaignProject} amount={0} campaign={campaign} onCancel={() => setShowRetirement(null)} />} */}
        </div>
    )
}

export default Retirements;
