import { createSlice } from "@reduxjs/toolkit";
import api from '../../store/services'
import { setCampaign } from "./campaignSlice";
import axios from "axios";

export const accountSlice = createSlice({
    name: "account",
    initialState: {
      accounts: {
        content: []
      },
      categoryTags: [],
    },
    reducers: {
      setAccounts: (state, { payload }) => {
          state.accounts = payload;
      },
      addAccounts: (state, { payload }) => {
        state.accounts = {...payload, content: [...state.accounts.content, ...payload.content]}
      },
      setCategoryTags: (state, { payload }) => {
        state.categoryTags = payload;
      },
    }
  });

export const getAccounts = (payload) => async (dispatch) => {
  let url = `/v1/organizations/${payload.organisationId}/network?page=${payload.page}&size=20`
  if(payload.type ) url += `&category_tag=${payload.type}`
  if(payload.search) url += `&search=${payload.search}`
  if(payload.filter)
    url += `&${payload.filter}`
  const { data } = await api.get(url)
  if(payload.merge)
    dispatch(addAccounts(data));
  else
    dispatch(setAccounts(data));
};

export const getCategoryTags = payload => async dispatch => {
  const { data } = await api.get(`/v1/category_tags`)
  dispatch(setCategoryTags(data));
};

export const getCalculators =
  ({ params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get('/v1/calculators', { params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const addFormOrganization = (payload) => async (dispatch) => {
      const { data } = await api.post(`/v1/forms/${payload.formId}/organizations`, {organization: {id: payload.organizationId}});
  };

export const createAccount =
  ({ request }) =>
  async () => {
    try {
      const { data } = await api.post(`/v1/organizations`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const masquerade = payload => async () => {
    try {
      const { data } = await api.post(`/v1/organizations/${payload.organisationId}/masquerade`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const getAccountCategoryTagInfo = payload => async () => {
    try {
      const { organizationId, params } = payload;
      const { data } = await api.get(`/v1/organizations/${organizationId}/network`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const getOrganizationCategories =
  ({ params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/v1/organization_categories`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getFileData = (chunk, length, folder, resize, chunk_number, session_id) => {
    const url = chunk_number === 0 ? '/files' : `/files/${session_id}`;
    const formData = new FormData();
  
    if (chunk_number === 0) {
      formData.append('file', chunk);
      formData.append('folder', folder);
      formData.append('backend', '');
      formData.append('resize', resize);
      formData.append('length', length);
    } else {
      formData.append('file', chunk);
      formData.append('offset', chunk_number);
    }
  
    return { formData, url };
  };

  export const uploadFile =
  ({ file, folder = 'files', resize = '', onSuccess, onError, setProgress }) =>
  async dispatch => {
    let data = null;
    const chunkSize = 256 * 1024; // (adjust based on your requirements)
    const totalChunks = Math.floor(file.size / chunkSize) + 1;
    const chunkProgress = 100 / totalChunks;
    let chunkNumber = 0;
    let start = 0;
    let end = chunkSize;

    const uploadNextChunk = async () => {
      if (chunkNumber < totalChunks) {
        try {
          let chunk = file.slice(start, end);
          chunk = new File([chunk], file.name, { type: file.type, lastModified: file.lastModified });

          const { formData, url } = getFileData(chunk, file.size, folder, resize, start, data?.session_id);

          const response = await axios.post(`https://api.zenterprize.co.uk/v3/api${url}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4ZWUxYTNlMy1jYzUzLTQ1NjYtOTc1NC05N2RjMTc0MzA4NWYiLCJ0eXBlIjoiYWNjZXNzIiwidGVuYW50T3JnYW5pc2F0aW9uIjoiY2RlMmYzMTUtYzMxNi0xMWVkLTgwNjYtNDIwMGZlOWEwMDA2Iiwib3JnYW5pemF0aW9uQ2F0ZWdvcnkiOiIyOTg2NGU4Ni05NzM5LTExZWUtODQ5Yi00MjAxMGE5YTAwMGYiLCJzZXJ2aWNlUHJvdmlkZXIiOiJjZGUyZjMxNS1jMzE2LTExZWQtODA2Ni00MjAwZmU5YTAwMDYiLCJyb2xlIjoiMTM0NmE5YjgtYzMxOS0xMWVkLTgwNjYtNDIwMTBhOWEwMDA2IiwidGVhbXMiOnt9LCJsaWNlbmNlcyI6IltdIiwiZXhwIjoxNzE3NjYxODU3LCJpYXQiOjE3MTc1NzU0NTd9.VM2vvYhkTRGKbxooytWmIvYqMnoIN2haFIWqvCSUZhfTsY58Mp2lfWKh5xafx8BoL-3E9CbYl6EOpyvW9eurPw' },
            timeout: 50000,
          });
          data = response.data;
          chunkNumber++;
          start = end;
          end = start + chunkSize;
          const progress = Number((chunkNumber + 1) * chunkProgress);
          setProgress(progress > 100 ? 100 : progress);
          uploadNextChunk();
        } catch (error) {
          onError(error);
        }
      } else {
        onSuccess(data);
      }
    };

    uploadNextChunk();
  };

export const { setAccounts, addAccounts, setCategoryTags } = accountSlice.actions;
export default accountSlice.reducer;