import React, { useContext, useState } from 'react';
import uuid from 'react-uuid';
import Button from '../components/Elements/button/button';
import { initModal } from '../constant/InitialData';
import { addToast} from '../store/features/toastSlice'
import { AutomationActionWrapper } from './modal.styled.js';
import { CSSTransition } from 'react-transition-group';
import { useDispatch } from 'react-redux';
import { OrganisationContext } from "../context/organisationContext";
import api from "../store/services";

const AutomationAction = () => {
  const { modal, setModal } = useContext(OrganisationContext);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { automation, isDelete, isEnable, isDisable, ownerSymbolColor = '#e6eeff' } = modal.content;

  const onPerformAction = async () => {
    if (loading) return;
    setLoading(true);
    try {
      isDelete
        ? await api.delete(`/v3/api/automations/${automation.id}`)
        : await api.put(`/v3/api/automations/${automation.id}/state`, {
            enabled: isEnable ? true : isDisable ? false : null,
          });
      dispatch(
        addToast({
          error: false,
          text: `Automation has been ${
            isDelete ? 'deleted' : isEnable ? 'enabled' : isDisable ? 'disabled' : ''
          } successfully`,
          id: uuid(),
        }),
      );
      setModal({
        type: 'automation-success',
        content: {
          isDelete,
          updateAutomationStatus: isDelete ? false : true,
          automation: { ...automation, is_enabled: isEnable ? true : false },
        },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text:
            !isDelete && error?.response?.data?.error_code === 'BAD_REQUEST'
              ? error.response.data.error_description
              : `Failed to ${isDelete ? 'deleted' : isEnable ? 'enable' : isDisable ? 'disable' : ''} Automation`,
          id: uuid(),
        }),
      );
    }
  };

  const getOwnerLogo = () => {
    const { owner } = automation;
    if (!owner.name) return '';
    const nameArray = owner.name?.split(' ');
    return (
      <div className="owner-logo" style={{ backgroundColor: ownerSymbolColor }}>
        <label className="bold-text">
          {nameArray?.[0]?.charAt(0)?.toUpperCase()}
          {nameArray?.[1]?.charAt(0)?.toUpperCase()}
        </label>
      </div>
    );
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AutomationActionWrapper>
        <p className="bold-text font-24 text-center">
          {isDelete
            ? 'Are you sure you want to delete this automation?'
            : isEnable
            ? 'Would you like to enable this automation?'
            : isDisable
            ? 'Would you like to disable this automation?'
            : ''}
        </p>
        <div className="mt-8 integration-details-container">
          <div className="flex justify-between items-center">
            <div className="flex integration-name-container">
              <label className="medium-text font-16">{automation.name}</label>
            </div>
            {getOwnerLogo()}
          </div>
          <div className="flex items-center mt-1">
            <label className="regular-text grey-text">{automation.description}</label>
          </div>
        </div>
        <div className="flex justify-center mt-12">
          <Button
            bgColor="white"
            borderColor="#32E5C5"
            label="Cancel"
            onClick={() => setModal(initModal)}
            size="large"
            style={{ flex: 1 }}
          />
          <Button
            bgColor={isDelete ? '#FF5B5B' : '#40F1DE'}
            borderColor={isDelete ? '#FF5B5B' : '#40F1DE'}
            color="white"
            disabled={loading}
            label={isDelete ? 'Delete' : isEnable ? 'Enable' : isDisable ? 'Disable' : ''}
            onClick={onPerformAction}
            size="large"
            style={{ flex: 1, marginLeft: 24 }}
          />
        </div>
      </AutomationActionWrapper>
    </CSSTransition>
  );
};

export default AutomationAction;
