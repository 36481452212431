import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrganisationContext } from '../../context/organisationContext';
import { MenuWrapper } from './menu.styled';

const Menu = ({ automationLog }) => {
  const { setModal } = useContext(OrganisationContext);
  const navigate = useNavigate();

  return (
    <MenuWrapper className="menu">
      <label className="regular-text" onClick={() => navigate(`/platform/automation/log/${automationLog?.id}`)}>
        View more
      </label>
    </MenuWrapper>
  );
};

export default Menu;
