import { createSlice } from "@reduxjs/toolkit";
import api from '../../store/services'

export const organisationSlice = createSlice({
    name: "organisation",
    initialState: {
      organisations: [],
      hasMore: false,
    },
    reducers: {
      setOrganisations: (state, { payload }) => {
          state.organisations = payload.content;
          state.hasMore = !payload.last;
      },
      addOrganisations: (state, { payload }) => {
        state.organisations = [...state.organisations, ...payload.content];
        state.hasMore = !payload.last;
    },
    }
  });

export const getOrganisations = (payload) => async (dispatch) => {
  let url = `/v1/organizations/${payload.organisationId}/network?page=${payload.page}&size=${payload.size || 10}&sort=name`
  if(payload.type) url += `&relationship=${payload.type}`
  if(payload.search) url += `&search=${payload.search}`
  if(payload.category) url += `&organization_category_id=${payload.category}`
  const { data } = await api.get(url)
  if(payload.merge)
    dispatch(addOrganisations(data));
  else
    dispatch(setOrganisations(data));
};


export const { setOrganisations, addOrganisations } = organisationSlice.actions;
export default organisationSlice.reducer;