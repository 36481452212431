import React, { useState } from "react";

import Image from "../../image";

import moment from "moment";

import ArrowDownIcon from "../../../assets/images/menu/arrow-down-black.svg";
import CheckedIcon from "../../../assets/images/check-checked.svg";
import Loading from "../../../assets/images/loading.svg";

import "./style.scss";
import { capitalize, formatText } from "../../../helpers/utils";

const CampaignMintingProgressItem = ({ campaignId, campaignNft }) => {
  return (
    <div className="border-bottom campaign-nft">
      <div className="campaign-mint-progress">
        <div
          key={campaignNft.id}
          className="campaign-mint-progress-item"
        >
          <div className="flex border-right px-6 py-2 my-1">
            <img
              alt="mint-progress-img"
              className="mint-progress-image"
              height={32}
              width={32}
              src={campaignNft?.image?.thumbnail_url}
            />
            <div className="flex-column ml-4 justify-between mint-progress-name">
              <label className="bold-text font-12 one-line">{campaignNft.name}</label>
              <label className="bold-text font-12 one-line">
                {campaignNft.series?.issue || "-"}/
                {campaignNft.series?.total || "-"}
              </label>
            </div>
          </div>
          <div className="flex-column justify-between px-6 py-2 my-1 relative row-gap-1">
            <label className="regular-text font-12 light-purple-text one-line">
              Owner:{" "}
              <span className="regular-text font-12">
                {campaignNft.owner?.contact?.name}
              </span>
            </label>
            <label className="regular-text font-12 light-purple-text one-line">
              Wallet:{" "}
              <span className="regular-text font-12">
                {campaignNft.owner?.wallet?.wallet_address}
              </span>
            </label>
          </div>
          <div className="flex items-center justify-center">
            {campaignNft.lifecycle === "MINTED" ? (
              <div className="flex items-center">
                <img
                  alt="checked-img"
                  className="checked-icon"
                  src={CheckedIcon}
                />
                <label className="medium-text ml-2 color-green">{capitalize(formatText(campaignNft.lifecycle))}</label>
              </div>
            ) : (
              <div className="item flex items-center justify-center">
                <img alt="loading" height="16px" src={Loading} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignMintingProgressItem;
