import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

export const DndContainer = ({ children }) => {
    return (
        <DndProvider backend={HTML5Backend}>
            {children}
      </DndProvider>
    )
}
