import styled from 'styled-components';

const SettingsWrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  flex: 1;
  user-select: none; */
  display: flex;
  width: 100%;
  .settings {
    display: flex;
    flex-direction: column;
    margin: 14px;
    flex: 1;
    user-select: none;

    .st-content {
      display: flex;
      flex-direction: row;

      .st-icon {
        min-width: 24px;
        min-height: 24px;
        align-self: flex-start;
      }

      .st-details {
        display: flex;
        flex-direction: column;
        margin-left: 20px;

        .std-name {
          font-family: Roboto Bold;
          font-size: 20px;
          color: #092c40;
        }

        .std-description {
          font-family: Roboto Medium;
          font-size: 14px;
          color: rgba(9, 44, 64, 0.5);
          margin-top: 5px;
        }
      }
    }

    .st-arrow {
      min-width: 8px;
      min-height: 8px;
      width: 8px;
      justify-self: flex-end;
    }

    .settings-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: absolute;
      height: 40px;

      .settings-navigation {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 24px;
        label {
          font-family: Roboto Bold;
          font-size: 14px;
          color: #092c40;
          cursor: pointer;
        }

        img {
          height: 8px;
          margin: 0 7px;
        }
      }

      .settings-invite-btn * {
        cursor: pointer;
      }

      .settings-invite-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 40px;
        border: 2px solid #d2e5ed;
        border-radius: 8px;
        overflow: hidden;
        transition: opacity 400ms;
        cursor: pointer;

        label {
          font-family: Roboto Bold;
          font-size: 14px;
          line-height: 16px;
          color: #053149;
          margin-left: 10px;
        }
      }
    }

    .capture-survey-menu {
      display: flex;
      flex-direction: row;
      align-items: center;

      .dropdown-selected {
        margin-right: 0;
      }

      button {
        width: 110px;
        height: 32px;
        background: #32e5c5;
        border: 2px solid #32e5c5;
        border-radius: 8px;
        margin-left: 16px;
        color: #ffffff;
        font-size: 14px;
        font-family: Roboto Medium;
        cursor: pointer;
      }

      .cancel-btn {
        background: #ffffff;
        color: #053149;
      }
    }

    .settings-invite-btn * {
      cursor: pointer;
    }

    .settings-type * {
      cursor: pointer;
    }

    .settings-types {
      display: flex;
      flex-wrap: wrap;
      margin-top: 48px;
    }

    .settings-type {
      width: 50%;
      padding-left: 10px;
      padding-right: 10px;

      .settings-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #ffffff;
        box-shadow: 0px 5px 30px rgba(9, 19, 53, 0.06);
        border-radius: 8px;
        padding: 20px;
        cursor: pointer;
        margin-bottom: 20px;
        min-width: fit-content;
      }
    }
  }

  .security-settings {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 5px 30px rgba(9, 19, 53, 0.06);
    border-radius: 8px;
    margin-top: 50px;
    padding: 20px;

    .st-content {
      flex: 1;
    }

    .ss-types {
      flex: 1;
      display: flex;
      flex-direction: column;

      .ss-type * {
        cursor: pointer;
      }

      .ss-type {
        display: flex;
        flex-direction: row;
        background: #f4f7f9;
        border-radius: 8px;
        padding: 20px;
        margin: 10px 0;
        cursor: pointer;

        .st-details {
          margin-left: 0;
        }
      }
    }
  }
`;

export default SettingsWrapper;
