import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";

import SmallCheckbox from '../small-checkbox';

import DeleteIcon from '../../../assets/images/trash-chart.svg';
import SearchIcon from "../../../assets/images/search.svg";
import Loading from "../../../assets/images/loading.svg";

import './style.scss';

const TxFilter = ({ options, name, search, setSearch, fetchMore, selected, setSelected, children }) => {
  const ref = useRef();

  const [show, setShow] = useState(false)

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (ref.current) ref.current.focus();
  }, [ref]);

  return (
    <div ref={ref} className="flex flex-column relative tx-filter" onBlur={onBlur} tabIndex={0}>
        <div className='flex items-center cursor' onClick={() => setShow(!show)}>
          <SmallCheckbox selected={show || selected.length > 0} />
          <label className='medium-text ml-2'>{name}</label>
        </div>
        {show &&
          <div ref={ref} className="flex flex-column card absolute tx-filter-options" onBlur={onBlur} tabIndex={0}>
            <div className='flex pxy-4 border-bottom'>
              <div className="flex items-center search-input txs-search-input px-2" >
                  <img src={SearchIcon} alt="search" className="ml-1"/>
                  <input placeholder="Search" className="ml-1 font-16" value={search}
                      onChange={e => setSearch(e.target.value)}/>
              </div>
            </div>
            {children}
            <div className='pxy-4'>
              {options?.content?.length > 0 ?
                <div className="tx-filter-list mb-2" id="scrollableDiv">
                        <InfiniteScroll
                            dataLength={options?.content?.length}
                            hasMore={!options?.last}
                            loader={
                            <div className="item flex items-center justify-center my-2">
                                <img alt="loading" height="24px" src={Loading} />
                            </div>
                            }
                            next={fetchMore}
                            scrollableTarget="scrollableDiv"
                            style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            overflow: "unset",
                            }}
                        >
                        <div className="flex items-center py-2 cursor" onClick={() => setSelected([])}>
                          <SmallCheckbox selected={selected.length === 0} />
                          <label className='ml-2 regular-text'>All</label>
                        </div>
                        {options.content?.map(o => 
                        <div className="flex items-center py-2 cursor" key={o.id} onClick={() => {
                            if(selected.includes(o.id)) 
                              setSelected(selected.filter(s => s !== o.id))
                            else 
                              setSelected([...selected, o.id])}}>
                          <SmallCheckbox selected={selected.includes(o.id)} />
                          <label className='regular-text ml-2'>{o.name}</label>
                        </div>
                        )}
                        </InfiniteScroll>   
                    </div>    
                      :
                    <div className="flex items-center justify-center flex-1">
                        <label className="bold-text font-16 light-purple-text">There are no items</label>
                    </div>
                    }
            </div>
      
          </div>
     }
    </div>
  );
};

export default TxFilter;
