import ArrowRightIcon from '../../assets/images/menu/arrow-right-black.svg';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { capitalize, formatText, getIntegrationTypeIcon } from '../../helpers/utils';
import api from '../../store/services'
import { addToast} from '../../store/features/toastSlice'
import Button from '../../components/Elements/button/button';
import InviteIcon from '../../assets/images/invite-icon.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/search-icon.svg';
import SelectedAutomationBox from './selected-automation-box';
import { ReactComponent as DotsIcon } from '../../assets/images/menu/dots.svg';
import Menu from './details-menu';
import ActionMenu from './action-menu';
import SelectedAutomationLogs from './selected-automation-logs';
import SelectedAtomationDetails from './selected-automation-details';
import SelectedIntegration from './selected-integration';
import { setViewMore, setAutomationData } from '../../store/features/automationSlice';
import Loading from '../../assets/images/loading.gif';
import { ReactComponent as TimeGreyIcon } from '../../assets/images/time-grey.svg';
import { ReactComponent as ConditionIcon } from '../../assets/images/condition.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/trash.svg';
import { ReactComponent as AlertWarningIcon } from '../../assets/images/alert-warning.svg';

import { OrganisationContext } from '../../context/organisationContext';
import ViewMoreCompoent from './view-more/view-more-compoent';

const SelectedAutomation = ({
  selectedAutomation,
  setSelectedAutomation,
  updateAutomation,
  hasPermission,
  checkPermission,
}) => {
  const { setModal } = useContext(OrganisationContext);
  const [automation, setAutomation] = useState({ ...selectedAutomation });
  const [showTrigger, setShowTrigger] = useState(true);
  const [showAction, setShowAction] = useState(false);
  const [showTiggerData, setShowTriggerData] = useState();
  const [showMenu, setShowMenu] = useState(false);
  const [showDetailsMenu, setShowDetailsMenu] = useState(false);
  const [showLogs, setShowLogs] = useState(selectedAutomation.is_enabled);
  const [showDetails, setShowDetails] = useState('LOGS');
  const [tiggerData, setTriggetData] = useState(false);
  const [actionData, setActionData] = useState([]);
  const [selected, setSelected] = useState({ newAction: true, selectedActionIndex: 0 });
  const [actionList, setActionList] = useState([{ newAction: true }]);
  const [selectedActionLeft, setSelectedActionLeft] = useState({ newAction: true, selectedActionIndex: 0 });
  const [loading, setLoading] = useState(true);
  const [loadingActions, setLoadingActions] = useState(false);
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showSelectedActionOptions, setShowSelectedActionOptions] = useState(false);
  const [focusedInput, setFocusedInput] = useState(false);

  const dispatch = useDispatch();
  const { viewMore } = useSelector(state => state.settings);

  const menuClick = action => {
    setShowDetailsMenu(!showDetailsMenu);
    setShowDetails(action);
    setShowLogs(true);
  };
  const fetchData = async () => {
    setLoading(true);
    const { data } = await api.get(`/v3/api/automations/${selectedAutomation.id}`);
    setAutomation(data);
    if (data.is_enabled) {
      const newAction = data.actions?.[0] || {};
      setShowTriggerData(data.event_type);
      setActionList(data.actions);
      setSelected({
        ...newAction,
        selectedActionIndex: 0,
      });
      setSelectedActionLeft({ ...newAction, selectedActionIndex: 0 });

      setShowTrigger(false);
      setShowAction(true);
    } else {
      if (data.event_type?.id) {
        setShowTriggerData(data.event_type);
        setShowTrigger(false);
      }
      if (data.actions?.length > 0) {
        const newAction = data.actions?.[0] || {};
        setActionList(data.actions);
        setShowAction(true);
        setSelected({
          ...newAction,
          selectedActionIndex: 0,
        });
        setSelectedActionLeft({
          ...newAction,
          selectedActionIndex: 0,
        });
      }
    }
    dispatch(setAutomationData(data));
    setLoading(false);
  };

  const updateAutomationEnableStatus = async () => {
    await api.put(`/v3/api/automations/${selectedAutomation.id}/state`, {
      enabled: false,
    });
    setSelectedAutomation({ ...selectedAutomation, is_enabled: false });
    setShowAction(false);
    updateAutomation({ ...selectedAutomation, is_enabled: false }, false);
  };

  const onDeleteAction = action => {
    const newActionList = actionList.filter(a => a.id !== action.id);
    if (newActionList.length > 0) {
      if (selectedActionLeft.id === action.id) {
        setSelectedActionLeft({ ...newActionList[0], selectedActionIndex: 0 });
        setSelected({ ...newActionList[0], selectedActionIndex: 0 });
        if (newActionList[0]?.newAction) {
          setShowAction(false);
        } else {
          setShowAction(true);
        }
      } else {
        const newActionIndex = newActionList.findIndex(a => a.id === selectedActionLeft.id);
        setSelectedActionLeft({ ...selectedActionLeft, selectedActionIndex: newActionIndex > 0 ? newActionIndex : 0 });
        setSelected({ ...selectedActionLeft, selectedActionIndex: newActionIndex > 0 ? newActionIndex : 0 });
        if (selectedActionLeft.newAction) {
          setShowAction(false);
        } else {
          setShowAction(true);
        }
      }
      setActionList(newActionList);
    } else {
      setSelectedActionLeft({});
      setActionList([{ newAction: true }]);
      setSelected();
      setShowAction(false);
      updateAutomationEnableStatus();
    }
  };

  const actionMenuClick = (actionType, action, index) => {
    let action_type = {};
    actionData?.some(a => {
      const matchedAction = a.action_types.find(at => at.action_type === action.action_type);
      if (matchedAction) {
        action_type = matchedAction;
        return true;
      }
    });
    if (actionType === 'DELETE') {
      setModal({
        type: 'delete-automation-action',
        content: {
          action: { ...action, action_type },
          index: index + 1,
          automation: selectedAutomation,
          onSuccess: () => onDeleteAction(action),
          top: true,
        },
      });
    }
  };

  const updateAction = (action, data) => {
    setActionList(actionList.map(a => (a.id === action.id ? { ...data } : { ...a })));
    setSelected({ ...data, selectedActionIndex: selectedActionLeft.selectedActionIndex });
    setSelectedActionLeft({ ...data, selectedActionIndex: selectedActionLeft.selectedActionIndex });
  };

  const removeIntegrationFromAction = action => {
    setActionList(
      actionList.map((a, idx) =>
        a.id === action.id && idx === selectedActionLeft.selectedActionIndex
          ? { ...a, editIntegration: true, integration: null }
          : { ...a },
      ),
    );
    setSelected({ ...selected, integration: null, editIntegration: true });
    setSelectedActionLeft({
      ...selectedActionLeft,
      integration: null,
      editIntegration: true,
      selectedActionIndex: selectedActionLeft.selectedActionIndex,
    });
  };

  const setNewSelectedAction = (action, index) => {
    let newAction = {};
    actionData?.some(a => {
      const matchedAction = a.action_types.find(at => at.action_type === action.action_type);
      if (matchedAction) {
        newAction = matchedAction;
        return true;
      }
    });
    if (action.newAction) {
      setShowAction(action.id ? true : false);
      setSelected({ ...action });
      setSelectedActionLeft({ ...action, selectedActionIndex: index });
    } else {
      setShowAction(true);
      setSelected({
        ...action,
        params: action.params,
        name: newAction.name,
        icon: newAction.icon,
        description: newAction.description,
      });
    }
    setSelectedActionLeft({ ...action, selectedActionIndex: index });
    setShowLogs(false);
  };

  const onDeleteActionFromInside = action => {
    const newActionList = actionList.filter(a => a.id !== action.id);
    if (newActionList.length > 0) {
      const newActionAvailable = actionList.findIndex(a => a.newAction && !a.id);
      if (selectedActionLeft.id === action.id) {
        setSelectedActionLeft(
          newActionAvailable >= 0
            ? { ...actionList[newActionAvailable], selectedActionIndex: newActionAvailable }
            : { newAction: true, selectedActionIndex: selectedActionLeft.selectedActionIndex },
        );
        setSelected(
          newActionAvailable >= 0
            ? { ...actionList[newActionAvailable], selectedActionIndex: newActionAvailable }
            : { newAction: true, selectedActionIndex: selected.selectedActionIndex },
        );
        setShowAction(false);
      }
      setActionList(
        newActionAvailable >= 0
          ? actionList.filter(a => a.id !== action.id)
          : actionList.map(a => (a.id === action.id ? { newAction: true } : { ...a })),
      );
    } else {
      setSelectedActionLeft({});
      setActionList([{ newAction: true }]);
      setSelected();
      setShowAction(false);
      updateAutomationEnableStatus();
    }
  };

  const onDeleteSelectedAction = async action => {
    onDeleteActionFromInside(action);
    await api.delete(`/v3/api/automations/${selectedAutomation.id}/actions/${action.id}`);
  };

  const renderSelectedActionData = selected => {
    let newAction = { ...selected };
    if (!newAction.name) {
      actionData?.some(a => {
        const matchedAction = a.action_types.find(at => at.action_type === selected.action_type);
        if (matchedAction) {
          newAction = {
            ...newAction,
            name: matchedAction.name,
            icon: matchedAction.icon,
            description: matchedAction.description,
          };
          return true;
        }
      });
    }
    return (
      <div>
        <div
          className="flex items-center pxy-6"
          onMouseLeave={() => setShowSelectedActionOptions(false)}
          onMouseMove={() => setShowSelectedActionOptions(true)}>
          <div className="leads-icon">
            {newAction?.icon && (
              <img src={`${newAction?.icon?.active}`} style={{ width: 24, height: 24, marginRight: 8 }} />
            )}
          </div>
          <div className="flex-1">
            <div className="medium-text">{newAction?.name}</div>
            <div className="regular-text grey-text mt-1 font-12">{newAction?.description}</div>
          </div>
          {showSelectedActionOptions && (
            <div className="flex mx-1">
              <div className="mx-1 cursor" onClick={() => onDeleteSelectedAction(newAction)}>
                <DeleteIcon />
              </div>
            </div>
          )}
        </div>
        <hr className="mb-4 " style={{ marginTop: '0px', width: 'calc(100% - 42px)' }} />
      </div>
    );
  };

  const fetchEventTypesData = async () => {
    let { data } = await api.get(`/v3/api/event_types`);
    setTriggetData(data);
  };
  const fetchActionData = async () => {
    setLoadingActions(true);
    let { data } = await api.get(`/v3/api/actions`);
    setActionData(data);
    setLoadingActions(false);
  };
  // put trigger Data

  const putTrigeerData = async triggerData => {
    let data = {
      name: selectedAutomation?.name,
      description: selectedAutomation?.description,
      owner: { id: selectedAutomation?.owner?.id },
      event_type: triggerData?.event_type,
    };
    try {
      await api.put(`/v3/api/automations/${selectedAutomation.id}`, data);
    } catch (error) {
      dispatch(
        addToast({
          error: true,
          text: 'Error while updating status',
          id: uuid(),
        }),
      );
    }
  };
  const selectedActionFunction = color => {
    return (
      showAction &&
      selected && (
        <div
          className={`${color !== 'none' ? 'left-boxes' : ''}`}
          style={{
            margin: '10px 0px 20px 33px',
            padding: '10px',
            width: '90%',
          }}>
          <div className="flex">
            <div className="leads-icon">
              {selected?.icon && (
                <img
                  // alt="icon"
                  src={`${selected?.icon?.active}`}
                  style={{ width: 24, height: 24, marginRight: 8 }}
                />
              )}
            </div>
            <div>
              <div className="medium-text">{selected?.name}</div>
              <div className="regular-text grey-text mt-1 font-12">{selected?.description}</div>
            </div>
          </div>
        </div>
      )
    );
  };

  const renderAction = action => {
    let newAction = {};
    actionData.some(a => {
      const matchedAction = a.action_types.find(at => at.action_type === action.action_type.action_type);
      if (matchedAction) {
        newAction = matchedAction;
        return true;
      }
    });

    return (
      <div
        className={`left-boxes`}
        style={{
          margin: '10px 0px 10px 33px',
          padding: '10px',
          width: '90%',
        }}>
        <div className="flex">
          <div className="leads-icon"></div>
          {newAction?.icon && (
            <img
              alt="icon"
              src={`${newAction?.icon?.active}`}
              style={{ width: 24, height: 24, marginRight: 8 }}
            />
          )}
          <div>
            <div className="medium-text">{newAction?.name}</div>
            <div className="regular-text grey-text mt-1 font-12">{newAction?.description}</div>
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // call on false
    if (!selectedAutomation.is_enabled && !selectedAutomation.event_type && showTrigger) {
      fetchEventTypesData();
    }
    // show Action
    if (!showTrigger) fetchActionData();
  }, [selectedAutomation.id, showTrigger]);

  const onSave = async () => {
    try {
      setLoading(true);
      const request = {
        name: selectedAutomation.name,
        description: selectedAutomation.description,
        owner: {
          id: selectedAutomation.owner.id,
        },
      };
      const { data } = await api.put(`/v3/api/automations/${selectedAutomation.id}`, request);

      dispatch(
        addToast({
          error: false,
          text: 'automation updated successfully',
          id: uuid(),
        }),
      );
      setLoading(false);
      updateAutomation(data);
    } catch (e) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: 'Error while updating automation',
          id: uuid(),
        }),
      );
    }
  };

  const enableAutomationAction = async () => {
    setModal({
      type: 'automation-save-action',
      content: {
        automation: selectedAutomation,
        top: true,
        onSave: onSave,
      },
    });
  };

  const onSaveAction = () => {
    const isActionAvailable = actionList.filter(a => !a.newAction)?.length > 0;
    if (!selectedAutomation.is_enabled && isActionAvailable) {
      enableAutomationAction();
    } else {
      onSave();
    }
  };

  const onSelectTrigger = value => {
    setShowTriggerData(value);
    putTrigeerData(value);
  };

  const onSelectAction = value => {
    if (selected?.newAction) {
      setActionList(
        actionList.map((a, idx) =>
          a.newAction && selectedActionLeft.selectedActionIndex === idx ? { ...value, newAction: true } : { ...a },
        ),
      );
      setSelectedActionLeft({
        ...value,
        selectedActionIndex: selectedActionLeft.selectedActionIndex,
        newAction: true,
      });
      setSelected({ ...value, newAction: true });
    } else {
      setActionList(
        actionList.map((a, inx) => (inx === actionList.length - 1 ? { ...value, newAction: true } : { ...a })),
      );
      setSelectedActionLeft({
        ...value,
        selectedActionIndex: selectedActionLeft.selectedActionIndex,
        newAction: true,
      });
      setSelected({ ...value, newAction: true });
    }
  };

  return (
    <>
      <div className="selected-automation-container">
        <div className="flex items-center">
          <label className="regular-text grey-text hover-underline">Platform</label>
          <img className="mx-2" src={ArrowRightIcon} />
          <label className="regular-text grey-text hover-underline cursor" onClick={() => setSelectedAutomation({})}>
            Automation
          </label>
          {!viewMore?.status && (
            <>
              <img className="mx-2" src={ArrowRightIcon} />
              <label className="bold-text">{capitalize(formatText(automation?.name))}</label>
            </>
          )}
          {viewMore?.status && (
            <>
              <img className="mx-2" src={ArrowRightIcon} />
              <label
                className="regular-text grey-text hover-underline cursor"
                onClick={async () => {
                  setShowDetails('LOGS');
                  dispatch(setViewMore());
                }}>
                {capitalize(formatText(automation?.name))}
              </label>
              {viewMore?.status && (
                <>
                  <img className="mx-2" src={ArrowRightIcon} />
                  <label className="bold-text">{formatText(viewMore?.data?.id)}</label>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {/* calls when you call 'view more'  from automation logs*/}
      {loading ? (
        <div className={`flex items-center justify-center loading-automation`}>
          <img alt="loading" height="64px" src={Loading} width="64px" />
        </div>
      ) : viewMore?.status ? (
        <ViewMoreCompoent viewMoreData={viewMore?.data} />
      ) : (
        <>
          <div className="selected-automation-container selected-automation-name-container">
            <div className="">
              <label className="bold-text automation-selected-name">{capitalize(formatText(automation?.name))}</label>
            </div>
            <div className="flex">
              <Button
                className={`secondary-hover mr-4 selected-automation-action`}
                label="Cancel"
                onClick={() => setSelectedAutomation({})}
                secondary={true}
                size="medium"
              />
              <Button
                className={`selected-automation-action ${hasPermission('AUTOMATIONS_MANAGE') && 'primary-hover'}`}
                disabled={hasPermission('AUTOMATIONS_MANAGE') ? false : true}
                label="Save"
                onClick={e => {
                  e.stopPropagation();
                  onSaveAction();
                }}
                primary={true}
                size="medium"
              />
            </div>
          </div>
          <div className="selected-automation">
            {/* left side portion */}
            <div className="left-scroll">
              <div
                className={`selected-automation-left ${showTrigger && 'current-selected-left cursor'}`}
                onClick={() => {
                  showTrigger && showLogs && setShowLogs(false);
                }}>
                <div className="flex items-center">
                  <div className="dot flex-row" style={{ background: showTrigger ? 'rgb(64, 241, 222)' : '#D2E5ED' }} />

                  <label className="medium-text">{'TRIGGER'}</label>
                </div>
                {!showTrigger && showTiggerData && (
                  <div
                    className="left-boxes"
                    style={{
                      margin: '10px 0px',
                      padding: '10px',
                      width: '90%',
                      marginLeft: '33px',
                    }}>
                    <div className="flex items-center">
                      <div className="leads-icon">
                        {showTiggerData?.icon && (
                          <img
                            // alt="icon"
                            src={`${showTiggerData?.icon?.active}`}
                            style={{ width: 24, height: 24, marginRight: 8 }}
                          />
                        )}
                      </div>
                      <div>
                        <div className="medium-text">{showTiggerData?.name}</div>
                        <div className="regular-text grey-text mt-1 font-12">{showTiggerData?.description}</div>
                      </div>
                    </div>
                  </div>
                )}
                {/* <br /> */}
              </div>
              {actionList.map((action, index) => (
                <>
                  <div className="line" />

                  <div
                    className={`selected-automation-left  ${
                      !showTrigger && action.id === selectedActionLeft.id && 'current-selected-left'
                    }`}
                    onClick={() => {
                      !showTrigger && setNewSelectedAction(action, index);
                    }}
                    style={{ borderLeft: ' 4px solid #7BD77A', cursor: 'pointer' }}>
                    <div className="flex items-center">
                      <div
                        className="dot flex-row"
                        style={{
                          background:
                            !showTrigger && action.id === selectedActionLeft.id ? 'rgb(64, 241, 222)' : '#D2E5ED',
                        }}
                      />

                      <label className="medium-text flex-1" style={{ cursor: 'pointer' }}>{`ACTION ${
                        index + 1
                      }`}</label>
                      <div className="flex action-icons px-2">
                        {action.schedule?.field && action.schedule?.unit && action.schedule?.offset !== null && (
                          <div className="flex items-center px-1">
                            <TimeGreyIcon className="grey-icon" />
                            <div className="medium-text">{action?.name}</div>

                          </div>
                        )}
                        {action.conditions?.length > 0 && (
                          <div className="flex items-center px-1">
                            <ConditionIcon className="grey-icon" />
                          
                          </div>
                        )}
                        {action.id && (
                          <div
                            className="flex cursor relative items-center py-1 px-2 menu-hover"
                            onBlur={() => setShowActionMenu(false)}
                            onClick={e => {
                              e.stopPropagation();
                              setShowActionMenu(action.id);
                            }}
                            tabIndex={0}>
                            <DotsIcon />
                            {showActionMenu === action.id && (
                              <ActionMenu clickItem={actionType => actionMenuClick(actionType, action, index)} />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {action.id && renderAction(action.id === selected?.id && selected?.edit ? selected : action)}
                  </div>
                </>
              ))}

              <div className="line" />

              <div
                className="add-new-action-button mr-4"
                onClick={() => {
                  if (!actionList.find(a => a.newAction)) {
                    setActionList([...actionList, { newAction: true }]);
                    setSelectedActionLeft({ newAction: true, selectedActionIndex: actionList.length });
                    setSelected({ newAction: true, selectedActionIndex: actionList.length });
                    setShowAction(false);
                    setShowLogs(false);
                  }
                }}
                style={{}}>
                <img alt="icon" src={InviteIcon} />

                <label className="regular-text ml-2">Add new action</label>
              </div>
            </div>
            {/* right side portion */}

            {!showLogs ? (
              <div className="selected-integration-right w-full">
                <div className="trigger-container">
                  <label className="bold-text">
                    {showTrigger
                      ? 'TRIGGER'
                      : `ACTION ${
                          selectedActionLeft?.selectedActionIndex >= 0
                            ? `${selectedActionLeft?.selectedActionIndex + 1}`
                            : ''
                        }`}
                  </label>
                  <div
                    className="flex flex-row cursor relative detail-log-menu menu-hover py-1 px-2"
                    onBlur={() => setShowDetailsMenu(false)}
                    onClick={() => setShowDetailsMenu(!showDetailsMenu)}
                    tabIndex={0}>
                    <DotsIcon />
                    {showDetailsMenu && (
                      <Menu
                        checkPermission={checkPermission}
                        clickItem={action => menuClick(action)}
                        hasPermission={hasPermission}
                      />
                    )}
                  </div>
                </div>
                <hr style={{ margin: '0px ' }} />

                {!selected?.id || selected?.edit ? (
                  <>
                    <div className="search-container">
                      <div
                        className={`integration-search ${focusedInput ? 'search-focused' : ''}`}
                        onFocus={() => setFocusedInput(true)}>
                        <SearchIcon className="search-icon" />
                        <input className="regular-text" placeholder="Search" />
                      </div>
                      {showTrigger && (
                        <div className="flex items-center">
                          <AlertWarningIcon />
                          <label className="regular-text grey-text font-12 ml-1">
                            Once the trigger has been selected it cannot be changed
                          </label>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>{renderSelectedActionData(selected)}</>
                )}

                <div className="main-container">
                  {loadingActions ? (
                    <div className={`flex items-center justify-center`}>
                      <img alt="loading" height="40px" src={Loading} width="40px" />
                    </div>
                  ) : (
                    <>
                      {showTrigger ? (
                        <>
                          {/* Trigget portion */}
                          {tiggerData &&
                            tiggerData.map((data, idx) => {
                              return (
                                <div key={idx}>
                                  <SelectedAutomationBox
                                    data={data.event_types}
                                    mainTitle={data.group}
                                    setSelected={onSelectTrigger}
                                    setShowTrigger={setShowTrigger}
                                  />
                                </div>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {/* Action portion */}
                          {!showAction &&
                            actionData &&
                            actionData.map((data, idx) => {
                              return (
                                <div key={idx}>
                                  <SelectedAutomationBox
                                    data={data.action_types}
                                    mainTitle={data.group}
                                    setSelected={value => {
                                      onSelectAction(value);
                                    }}
                                    setShowAction={setShowAction}
                                    type={'action'}
                                  />
                                </div>
                              );
                            })}
                          {/* Integration Portion */}
                          {showAction && (
                            <>
                              <SelectedIntegration
                                actionObj={selected}
                                automationObj={automation}
                                removeIntegrationFromAction={removeIntegrationFromAction}
                                selectedIngrationObj={{
                                  ...selected.integration,
                                  integration_params: selected.integration_params,
                                }}
                                triggerData={showTiggerData}
                                updateAction={updateAction}
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                {/* LOGs and Details portion */}
                <div className="selected-integration-right w-full">
                  <div className="flex header-item items-center justify-center ">
                    <div className="flex justify-center trigger-container flex-1">
                      <label className="bold-text">{showDetails === 'LOGS' ? 'LOGS' : 'DETAILS'}</label>
                    </div>
                    <div
                      className="flex flex-row pr-4t cursor menu-btn-header relative menu-hover py-1 px-2"
                      onBlur={() => setShowDetailsMenu(false)}
                      onClick={() => setShowDetailsMenu(!showDetailsMenu)}
                      tabIndex={0}>
                      <DotsIcon />
                      {showDetailsMenu && (
                        <Menu
                          checkPermission={checkPermission}
                          clickItem={action => menuClick(action)}
                          hasPermission={hasPermission}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <hr style={{ margin: 0 }} />
                  </div>

                  {showDetails === 'LOGS' ? (
                    <SelectedAutomationLogs
                      checkPermission={checkPermission}
                      clickItem={action => menuClick(action)}
                      hasPermission={hasPermission}
                      selectedAutomation={selectedAutomation}
                      setShowDetails={setShowDetails}
                      setShowMenu={setShowMenu}
                      showMenu={showMenu}
                    />
                  ) : (
                    <SelectedAtomationDetails
                      selectedAutomation={selectedAutomation}
                      setSelectedAutomation={setSelectedAutomation}
                      // updateIntegration={updateIntegration}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SelectedAutomation;
