import api from '../../../store/services'
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '../../../assets/images/trash.svg';
import { automationFilterMenuOptions } from '../../../constant/optionData';
import Dropdown from '../../../components/Elements/dropdown/dropdown';
import { MenuWrapper } from './automation-filter.styled';
import AsyncSelect from 'react-select/async';
import Selected from '../../../assets/images/selected.svg';

const MultiValueOption = props => {
  const onSelect = () => {
    props.setValue({ ...props.data });
  };
  return (
    <div className={`tpus-user ${props.isSelected && 'tpus-user-selected'}`} onClick={() => onSelect()}>
      <label className={`regular-text`}>{props.label}</label>
      {props.isSelected && <img alt="icon" src={Selected} />}
    </div>
  );
};

const AutomationFilterMenu = ({ setAutomationMenuFilters, filter, clearFilter }) => {
  const { user } = useSelector(state => state.user);

  const [objectType, setObjectType] = useState({});
  const [triggerTypeOptions, setTriggerTypeOptions] = useState([]);
  const [selectedAutomationFilter, setSelectedAutomationFilter] = useState({});
  const [inputState, setInputState] = useState('');
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  const organisationId = user?.organisations[0]?.external_id;
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem('automationMenuFilters', JSON.stringify(filter));
  }, [filter]);

  useEffect(() => {
    const fetchEventTypeOptions = async () => {
      const { data } = await api.get(`/v3/api/event_types`);

      let tmp = [];
      if (data) {
        data.map(event => {
          event.event_types.map(e => {
            tmp.push({ value: e.event_type, label: e.name });
          });
        });
      }
      setTriggerTypeOptions(tmp);
    };

    const fetchOwnerOptions = async () => {
      setLoadingOptions(true);
      const defaultOptions = await getOptions('');
      setOptions(defaultOptions);
      setLoadingOptions(false);
    };

    if (objectType.value === 'trigger' && triggerTypeOptions.length === 0) {
      fetchEventTypeOptions();
    }
    if (objectType.value === 'owner' && options.length === 0) {
      fetchOwnerOptions();
    }
  }, [objectType.value]);

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      clearStates();
    } else {
      setObjectType(automationFilterMenuOptions.find(option => option.value === filter.objectType));
      setSelectedAutomationFilter({ ...filter });
    }
  }, [filter]);

  useEffect(() => {
    if (Object.keys(filter).length > 0) {
      setObjectType(automationFilterMenuOptions.find(option => option.value === filter.objectType));
      setSelectedAutomationFilter({ ...filter });
    }
  }, []);

  const updateMainFilter = updatedFilter => {
    setAutomationMenuFilters(updatedFilter);
  };

  const clearStates = () => {
    setObjectType({});
    setSelectedAutomationFilter({});
  };

  const getOptions = async inputValue => {
    const { data } = await api.get(`/v1/users?organisation_id=${organisationId}&name=${inputValue}&size=100`);
    return data.content.map(o => ({ ...o, value: o.id, label: o.name }));
  };

  const loadOptions = async inputValue => {
    const options = await getOptions(inputValue);
    return options;
  };

  const handleChange = value => {
    const newFilter = { ...selectedAutomationFilter, owner: value };
    setSelectedAutomationFilter(newFilter);
    updateMainFilter(newFilter);
  };

  const handleChangeTrigger = value => {
    const newFilter = { ...selectedAutomationFilter, trigger: value };
    setSelectedAutomationFilter(newFilter);
    updateMainFilter(newFilter);
  };

  const handleInputChange = newValue => {
    setInputState(newValue);
    return newValue;
  };

  const renderFilterMenuOptions = () => {
    if (!objectType.value) return <div />;
    if (objectType.value === 'owner') {
      return (
        <div className="flex filter-dropdowns w-full" tabIndex={0}>
          <AsyncSelect
            className={`options-select-container`}
            classNamePrefix="select"
            components={{
              Option: MultiValueOption,
            }}
            defaultOptions={options}
            inputValue={inputState}
            isLoading={loadingOptions}
            loadOptions={loadOptions}
            onChange={handleChange}
            onInputChange={handleInputChange}
            placeholder="Select owner"
            value={selectedAutomationFilter.owner ? selectedAutomationFilter.owner : null}
          />
        </div>
      );
    } else if (objectType.value === 'trigger') {
      return (
        <div className="flex-column filter-dropdowns w-full">
          <div>
            <AsyncSelect
              className={`options-select-container`}
              classNamePrefix="select"
              // components={{
              //   Option: MultiValueOption,
              // }}
              defaultOptions={triggerTypeOptions}
              inputValue={inputState}
              // isLoading={loadingOptions}
              loadOptions={triggerTypeOptions}
              onChange={handleChangeTrigger}
              onInputChange={handleInputChange}
              placeholder="Select Trigger"
              value={selectedAutomationFilter.trigger ? selectedAutomationFilter.trigger : null}
            />
            {/* <Dropdown
              className={'dropdown-container'}
              dropdownHeight={'360px'}
              onSelect={option => {
                const newFilter = {
                  ...filter,
                  trigger: option.name || null,
                };
                setSelectedAutomationFilter({
                  ...selectedAutomationFilter,
                  trigger: option.name || null,
                });
                updateMainFilter(newFilter);
              }}
              options={triggerTypeOptions}
              placeholder="Select trigger"
              scrollEnabled
              selected={triggerTypeOptions.find(option => option.name === selectedAutomationFilter?.trigger)}
              value={selectedAutomationFilter.owner ? selectedAutomationFilter.owner : null}
              withIcon={false}
            /> */}
          </div>
        </div>
      );
    }
    return '';
  };

  return (
    <MenuWrapper>
      <div className="header-container justify-between items-center">
        <label className="bold-text">FILTER</label>
        <div className="clear-btn" onClick={() => clearFilter()}>
          <img alt="icon" src={DeleteIcon} />
        </div>
      </div>
      <div className="mb-4">
        <Dropdown
          className={'dropdown-hover'}
          dropdownHeight={'360px'}
          onSelect={option => {
            const newFilter = option.value ? { objectType: option.value } : {};
            setObjectType(option.value ? option : {});
            updateMainFilter(newFilter);
          }}
          options={automationFilterMenuOptions}
          placeholder="Select object"
          scrollEnabled
          selected={automationFilterMenuOptions.find(option => option.value === objectType.value)}
          withIcon={false}
        />
      </div>
      <>{renderFilterMenuOptions()}</>
    </MenuWrapper>
  );
};

export default AutomationFilterMenu;
