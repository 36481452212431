import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../store/features/toastSlice';
import uuid from 'react-uuid';
import { ReactComponent as EmailIcon } from '../../../assets/images/email.svg';
import { ReactComponent as DotsIcon } from '../../../assets/images/menu/dots.svg';
import { ReactComponent as MobileIcon } from '../../../assets/images/mobile.svg';
import Menu from '../users/menu';

const TeamDetailsUser = ({
  user,
  changeActive,
  testKey,
  onEditUser,
  changeDeleteUser,
  setSelectedUser,
  selectedTeam,
  checkPermission,
  hasPermission,
  changeUserRole,
  onInviteUser,
  onAvailibilityUser,
  index,
  isSameTenant,
}) => {
  const dispatch = useDispatch();

  const [showMenu, setShowMenu] = useState(false);

  const role = user.role

  const organisations =
    user.organisations?.filter(org => org.organisation_type === 'TEAM')?.filter(o => o.id === selectedTeam.id) || [];

  const menuClick = action => {
    setShowMenu(false);
    switch (action) {
      case 'DELETE':
        changeDeleteUser(user);
        break;
      case 'EDIT':
        onEditUser(user);
        break;
      case 'ACTIVE':
        changeActive(user);
        break;
      case 'CHANGE_USER_LEAD':
        changeUserRole(user, 'LEADER');
        dispatch(
          addToast({
            error: false,
            text: 'User is now a team lead',
            id: uuid(),
          }),
        );
        break;
      case 'CHANGE_USER_MEMBER':
        changeUserRole(user, 'MEMBER');
        dispatch(
          addToast({
            error: false,
            text: 'User is now a member',
            id: uuid(),
          }),
        );
        break;
      case 'INVITE_USER':
        onInviteUser(user);
        break;
      case 'AVAILIBILITY':
        onAvailibilityUser(user);
        break;
      default:
        break;
    }
  };

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowMenu(false);
    }
  };

  const getUserInviteStatus = () => {
    if (user.invitation_status === 'ACCEPTED') {
      return user.is_active
        ? { status: 'active', background: '#A1D9A0' }
        : { status: 'inactive', background: '#D2E5ED' };
    } else if (user.invitation_status === 'NOT_INVITED') {
      return { status: 'not invited', background: 'rgb(115, 134, 140, 0.3)' };
    } else if (user.invitation_status === 'INVITED') {
      return { status: 'invite sent', background: '#F6BB23' };
    } else if (user.invitation_status === 'INVITATION_EXPIRED') {
      return { status: 'invite expired', background: '#FF5B5B' };
    }
    return { status: 'not invited', background: 'rgb(115, 134, 140, 0.3)' };
  };

  return (
    <div className="data-match-row cursor" onClick={() => setSelectedUser(user)}>
      <div className="ml-6">
        <div className="flex name-column">
          <div>
            <label className="medium-text font-16">{user.name}</label>
          </div>
          <div className="urut-right mr-4 ml-2" tabIndex={0}>
            <label className="uru-state" style={{ background: getUserInviteStatus().background }}>
              {getUserInviteStatus().status}
            </label>
          </div>
        </div>
        <div className={`mt-1 flex items-center`}>
          <div className="uru-roles w-full flex flex-wrap">{role === 'LEADER' ? 'Team lead' : 'Team member'}</div>
        </div>
      </div>
      <div className="border-right" />
      <div className="justify-between contact-column">
        <div className="uru-middle">
          <div className={`urub-email mb-2 uru-organisations`}>
            <div className="uru-icons">
              <EmailIcon />
            </div>
            <label
              className={`ml-2 contact-email ${
                !user?.contact_info?.find(contact => contact.contact_type === 'EMAIL')?.contact_value &&
                'not-provided-label'
              }`}
              style={{ width: 'calc(100% - 10px)' }}>
              {user?.contact_info?.find(contact => contact.contact_type === 'EMAIL')?.contact_value || 'Not provided'}
            </label>
          </div>
          <div className="urub-email">
            <div className="uru-icons">
              <MobileIcon />
            </div>
            <label
              className={`ml-2 contact-mobile ${
                !user?.contact_info?.find(contact => contact.contact_type === 'MOBILE')?.contact_value &&
                'not-provided-label'
              }`}>
              {user?.contact_info?.find(contact => contact.contact_type === 'MOBILE')?.contact_value || 'Not provided'}
            </label>
          </div>
        </div>
        <div className="flex items-center justify-center menu-container items-center" tabIndex={0}>
          <div className="relative w-full" onBlur={onBlur} tabIndex={0}>
            {isSameTenant() && (
              <div
                className="menu-btn cursor flex items-center relative cursor mr-2 px-2 py-1 menu-hover"
                onClick={e => {
                  e.stopPropagation();
                  setShowMenu(!showMenu);
                }}>
                <DotsIcon />
              </div>
            )}
            {showMenu && (
              <div>
                <Menu
                  checkPermission={checkPermission}
                  clickItem={action => menuClick(action)}
                  hasPermission={hasPermission}
                  index={index}
                  isTeamUsers={true}
                  organisations={organisations}
                  user={user}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetailsUser;
