import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import moment from "moment";

import { getInventoryReport } from '../../../store/features/reportsSlice';
import OpenIcon from '../../../assets/images/open-green.svg'

import './style.scss'

const ReportInventory = ({organisation, selected}) => {
    const dispatch = useDispatch();

    const { inventoryReport } = useSelector(data => data.reports);

    useEffect(() => {
        dispatch(getInventoryReport({startDate: selected.startDate, endDate: selected.endDate, organizationId: organisation?.id === 'all' ? null : organisation?.id}));
    }, [dispatch, selected, organisation]);

    return (
        <div className="flex-column">
            <div className="report-details-grid-3 report-details-header border-top border-bottom">
                <label className="font-12 regular-text px-4 py-3 one-line">PROJECT</label>
                <label className="font-12 regular-text px-4 py-3 one-line">OWNER</label>
                <label className="font-12 regular-text px-4 py-3 one-line">RETIRED ON</label>
                <label className="font-12 regular-text px-4 py-3 one-line">VINTAGE</label>
                <label className="font-12 regular-text px-4 py-3 one-line">SERIAL</label>
                <label className="font-12 regular-text px-4 py-3 one-line">OPENING</label>
                <label className="font-12 regular-text px-4 py-3 one-line">RETIRED</label>
                <label className="font-12 regular-text px-4 py-3 one-line">CLOSING</label>
                <label className="font-12 regular-text px-4 py-3 one-line">OFFSET URL</label>
            </div>
            <div className="flex-column report-inventory-list">
            {inventoryReport.map((op, i) => 
                <div key={i} className='flex-column'>
                    <div className={`report-details-grid-3 ci-card cursor cursor ${i !== 0 && 'border-top'}`}>
                        <label className='regular-text px-4 py-3 one-line'>{op.project?.name}</label>
                        <label className='regular-text px-4 py-3 one-line'>{op.owner?.name}</label>
                        <label className='regular-text px-4 py-3 one-line'>{op.retired_on ? moment.unix(op.retired_on).format('DD/MM/YYYY') : ''}</label>
                        <label className='regular-text px-4 py-3 one-line'>{moment.unix(op?.vintage?.vintage_from).format('DD/MM/YYYY')}-{moment.unix(op?.vintage?.vintage_to).format('DD/MM/YYYY')}</label>
                        <label className='regular-text px-4 py-3 one-line'>{op.serial}</label>
                        <label className='regular-text px-4 py-3 one-line'>{(op.open_balance)?.toFixed(2).toLocaleString('en-US')}</label>
                        <label className='regular-text px-4 py-3 one-line'>{(op.retired)?.toFixed(2).toLocaleString('en-US')}</label>
                        <label className='regular-text px-4 py-3 one-line'>{(op.closing_balance)?.toFixed(2).toLocaleString('en-US')}</label>
                        <div className="px-4 py-3 one-line flex items-center"  onClick={() => { window.open(op.offset_url, "_blank")}}>
                            <label className='regular-text one-line'>{op.offset_url}</label>
                            {op.offset_url && <img src={OpenIcon} alt="copy" className="cursor mr-4" /> }
                        </div>
                    </div>
                    {op.id !== inventoryReport[i+1]?.id && 
                        <div className={"report-details-grid-3 ci-card cursor cursor border-top border-bottom mb-10"}>
                        <label className='bold-text px-4 py-3 one-line'></label>
                        <label className='bold-text px-4 py-3 one-line'></label>
                        <label className='bold-text px-4 py-3 one-line'></label>
                        <label className='bold-text px-4 py-3 one-line'></label>
                        <label className='bold-text px-4 py-3 one-line'></label>
                        <label className='bold-text px-4 py-3 one-line'>Total:</label>
                        <label className='bold-text px-4 py-3 one-line'>{(inventoryReport.filter(p => p.id === op.id).map(p => p.retired).reduce((sum, p) => sum + p, 0)).toFixed(2).toLocaleString('en-US')}</label>
                        <label className='bold-text px-4 py-3 one-line'></label>
                        <label className='bold-text px-4 py-3 one-line'></label>
                    </div>}
                </div>
                )}
            </div>
        </div>
    )
}

export default ReportInventory;