import moment from 'moment';
import React from 'react'
import { capitalize, formatText } from "../../../helpers/utils";

import { useNavigate } from 'react-router-dom';

import { ReactComponent as DotsIcon } from '../../../assets/images/menu/dots.svg';

import './style.scss';

const CampaignCard = ({ campaign }) => {
    const navigate = useNavigate();

    const active = moment().isBetween(moment.unix(campaign.effective.start), moment.unix(campaign.effective.end));

    return (
        <div className='campaigns-ret-grid campaigns-card cursor no-select' onClick={() => navigate(`/commerce/campaigns/${campaign.id}/details`)}>
            <label className='medium-text px-6 py-3'>{campaign.name}</label>
            <label className='medium-text px-6 py-3'>{campaign.description}</label>
            <label className='medium-text px-6 py-3'>{moment.unix(campaign.effective.start).format('DD/MM/yyyy')}</label>
            <label className='medium-text px-6 py-3'>{moment.unix(campaign.effective.end).format('DD/MM/yyyy')}</label>
            <label className='medium-text px-6 py-3'>{capitalize(formatText(campaign?.campaign_type || ''))}</label>
            <div className="column campaign-item flex justify-between items-center">
                <div className="flex items-center justify-center px-2 py-1 br-2 mr-2" style={{ background: active ? '#24CBB1' : '#FB3535' }}>
                    <label className="medium-text semibold-text font-12 white-text">{active ? 'Active' : 'Not active'}</label>
                </div>
                <DotsIcon width={20}></DotsIcon>
            </div>
        </div>
    )
}

export default CampaignCard;
