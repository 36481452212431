import React from "react";

import { CustomPicker } from "react-color";
import {
    Hue,
    Saturation
  } from "react-color/lib/components/common";

import './style.scss'

function Picker() {
    return (
      <div
        style={{
          width: 16,
          height: 16,
          borderRadius: 20,
          background: "rgba(255,255,255,0.2)",
          border: "2px solid white",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          boxSizing: "border-box"
        }}
      />
    );
  }

const McColorPicker = ({hex, hsl, hsv, onChange, setColor, close}) => {
    const defaultColors = ["#BADAE4", "#F5D45E", "#DAE6EB", "#D2C7C7"];
    
    return (
        <div className="flex-column card mc-color-picker absolute" tabIndex={0} onBlur={close}>
            <div className="flex pxy-4 ">
                <div className="cp-saturation relative">
                    <Saturation hsl={hsl} hsv={hsv} onChange={onChange} pointer={Picker} />                
                </div>
                <div className="cp-hue relative">
                    <Hue hsl={hsl} onChange={onChange} direction="vertical" pointer={Picker} />
                </div>
            </div>
            <div className="flex items-center justify-between px-4 pb-4 border-bottom">
                <label className="regular-text font-12">Custom color</label>
                <div className="flex items-center justify-center custom-color">
                    <label className="regular-text font-12">{hex}</label>
                </div>
            </div>
            <div className="flex items-center pxy-4">
                {defaultColors.map(c => <div className="default-color mr-2 cursor" style={{background: c, borderColor: hex === c.toLowerCase() ? '#1A2123' : c}} onClick={() => setColor(c)} />)}
            </div>
        </div>
    )
}

export default CustomPicker(McColorPicker);