import React from 'react'
import { useNavigate } from 'react-router-dom'

import '../../assets/css/pages/account.scss'
import PlaceholderImage from '../../assets/images/nft-placeholder.svg'
import Image from "../image";

const Project = ({project}) => {
    const navigate = useNavigate();

    return (
        <div className="flex-column card project no-select cursor" onClick={() => navigate(`/projects/${project.id}`)}>
            <div className={"relative"}>
                <Image className={"project-image"} size={160} media={project?.image?.media} />
                <div className={"location"}>{project.location?.country}</div>
                <div className="project-organisations flex items-center mb-2 ml-2">
                    {project.organisations.map(o =>
                         <div className="flex items-center project-organisation mt-1 mr-1 px-2">
                            <label className="medium-text font-10 white-text">{o.name}</label>
                        </div>
                    )}
                </div>
            </div>

            <div className='flex-column flex-1 pxy-2'>
                <h3 className="mt-1 mb-3 text-wrap font-16">{project.name}</h3>
                <label className="regular-text mt-1 o05 project-description">{project.description}</label>
            </div>
        </div>
    )
}

export default Project;
