import React from 'react';

import Button from '../components/Elements/button/button';
import { CancelAutomationLogWrapper } from './modal.styled';
import { CSSTransition } from 'react-transition-group';
import { useContext, useState } from 'react';
import { OrganisationContext } from '../context/organisationContext';
import { initModal } from '../constant/InitialData';
import { useDispatch } from 'react-redux';
import { addToast } from '../store/features/toastSlice';
import uuid from 'react-uuid';
import api from "../store/services";

const RetryLog = () => {
  const { modal, setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { content, index } = modal;
  const { action } = content;

  const onPerformAction = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await api.post(`/v3/api/action_log/${action.id}/retry`);
      dispatch(
        addToast({
          error: false,
          text: `Action has been retried`,
          id: uuid(),
        }),
      );

      setModal(initModal);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: 'Failed to retry action',
          id: uuid(),
        }),
      );
      setModal(initModal);
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <CancelAutomationLogWrapper>
        <p className="bold-text font-24 text-center">Are you sure you want to retry this action?</p>

        <div className="flex justify-center mt-12">
          <Button
            className={`${!loading && 'secondary-hover'}`}
            disabled={loading}
            label="Cancel"
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            style={{ flex: 1 }}
          />
          <Button
            className={`${!loading && 'primary-hover'}`}
            disabled={loading}
            label="Retry"
            onClick={onPerformAction}
            primary={true}
            size="large"
            style={{ flex: 1, marginLeft: 24 }}
          />
        </div>
      </CancelAutomationLogWrapper>
    </CSSTransition>
  );
};

export default RetryLog;
