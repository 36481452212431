import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from "../context/organisationContext";
import { addToast } from '../store/features/toastSlice';
import { transferTransaction } from '../store/features/transactionSlice';
import CommonPopup from './common-popup';
import uuid from 'react-uuid';

const TransferTransaction = () => {
  const { modal, setModal } = useContext(OrganisationContext);

  const { campaignProjects, transactions, onSuccess } = modal?.content || {};
  const [selectedProject, setSelectedProject] = useState()

  const dispatch = useDispatch();

  const onTransfer = async () => {
    try {
      await dispatch(transferTransaction({project: {id: selectedProject.project.id}, transactions: transactions.map(t => ({id: t.id}))}));
      dispatch(
        addToast({
          error: false,
          text: `Transfer success`,
          id: uuid(),
        }),
      );
      onSuccess()
      setModal(initModal)
    } catch(e) {
      dispatch(
        addToast({
          error: true,
          text: `Error on transfering transactions`,
          id: uuid(),
        }),
      );
    }

  }

  return (
    <TransferTransactionCommonWrapper>
      <CommonPopup
        modalClassName="common-popup"
        popupTitle='Transfer to Project'
        confirmButtonProps={{ label: 'Transfer'}}
        onCancel={() => setModal(initModal)}
        onConfirm={onTransfer}
        confirmDisabled={!selectedProject}
        >
        <TransferTransactionWrapper className="flex-column row-gap-6 w-full flex-1">
          <label className='light-purple-text regular-text text-center'>Select the project to which you want to transfer the transaction</label>
          <div className='flex-column cp-list'>
            {campaignProjects.map(cp => 
              <div key={cp.id} className='flex items-center justify-between px-4 py-3 mb-2 cursor cp-item' onClick={() => setSelectedProject(cp)}>
                <div className='flex-column'>
                  <label className='semibold-text font-16'>{cp.project.name}</label>
                  <label className='regular-text font-12 mt-1'>Current {cp.total_co2_kilo_amount ? cp.total_co2_kilo_amount.toLocaleString() : 0 } New { ((cp.total_co2_kilo_amount ? cp.total_co2_kilo_amount : 0 ) + transactions.map(t => t.co2_kg_units).reduce((a, c) => a + c, 0)).toLocaleString()}</label>
                </div>
                <div className="flex items-center justify-center add-t-radio mr-2 ml-4">
                  {cp.id === selectedProject?.id && <div className="add-t-radio-selected"/> }
                </div>             
              </div>
              )}
          </div>
        </TransferTransactionWrapper>
      </CommonPopup>
    </TransferTransactionCommonWrapper>
  );
};

const TransferTransactionCommonWrapper = styled.div`
  .common-popup {
    min-height: 680px;
  }
`;

const TransferTransactionWrapper = styled.div`
  .cp-item {
    border-radius: 16px;
    background: #F6F6F6;
  }
  .add-t-radio {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border: 1px solid rgba(181, 185, 193, 0.5);
    border-radius: 100px;
    background: #FFFFFF;

    .add-t-radio-selected {
      width: 12px;
      height: 12px;
      min-width: 12px;
      min-height: 12px;
      background: #24CBB1;
      border-radius: 100px;
    }
  }
  .cp-list {
    max-height: 420px;
    overflow-y: scroll;
  }
`;

export default TransferTransaction;
