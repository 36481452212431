import { configureStore } from "@reduxjs/toolkit";
import collectionSlide from "./features/collectionSlice";
import reloadSlice from "./features/reloadSlice";
import toastSlice from "./features/toastSlice";
import settingsSlice from "./features/settingsSlice";
import inventorySlice from "./features/inventorySlice";
import nftSlice from "./features/nftSlice";
import productSlice from "./features/productSlice";
import projectSlice from "./features/projectSlice";
import retirementSlice from "./features/retirementSlice";
import supporterSlice from "./features/supporterSlice";
import analyticsSlice from "./features/analyticsSlice";
import campaignSlice from "./features/campaignSlice";
import registrySlice from "./features/registrySlice";
import supplierSlice from "./features/supplierSlice";
import accountSlice from "./features/accountSlice";
import organisationSlice from "./features/organisationSlice";
import userSlice from "./features/userSlice";
import automationSlice from "./features/automationSlice";
import templateSlice from "./features/templateSlice";
import schemaSlice from "./features/schemaSlice";
import walletSlice from "./features/walletSlice";
import transactionSlice from "./features/transactionSlice";
import contactSlice from "./features/contactSlice";
import addressSlice from "./features/addressSlice";
import integrationsSlice from "./features/integrationsSlice";
import reportsSlice from "./features/reportsSlice";

export default configureStore({
  reducer: {
    collection: collectionSlide,
    reload: reloadSlice,
    toast: toastSlice,
    settings: settingsSlice,
    inventory: inventorySlice,
    nft: nftSlice,
    product: productSlice,
    project: projectSlice,
    retirement: retirementSlice,
    supporter: supporterSlice,
    analytics: analyticsSlice,
    campaign: campaignSlice,
    registry: registrySlice,
    supplier: supplierSlice,
    account: accountSlice,
    organisation: organisationSlice,
    user: userSlice,
    automation: automationSlice,
    template: templateSlice,
    schema: schemaSlice,
    wallet: walletSlice,
    transaction: transactionSlice,
    contact: contactSlice,
    address: addressSlice,
    integration: integrationsSlice,
    reports: reportsSlice,
  }
});
