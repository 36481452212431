import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProjects } from "../../store/features/projectSlice";
import './projects.scss';
import Project from "../../components/project/Project";
import Header from "../../components/header";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useNavigate } from "react-router-dom";

import Loading from '../../assets/images/loading.svg';

const Projects = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { projects, loading } = useSelector(data => data.project);
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')
    const [ isPublished, setIsPublished] = useState(true)

    useEffect(() => {
        dispatch(getProjects({page: currentPage, search: search, isPublished}))
    }, [currentPage, search, isPublished, dispatch]);

    return (
        <div className="flex-column mb-6 mt-2 flex-1">
            <Header title={"Projects"}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    search={search}
                    setSearch={setSearch}
                    isAddBtn={true}
                    totalPages={projects.total_pages}
                    addPermission="PROJECTS_MANAGE"
                    onAdd={() => navigate("/projects/new")}
                    children={
                        <div className="flex items-center">
                                <div 
                                    className="flex items-center justify-center lifecycle-selector ml-2 cursor" 
                                    onClick={() => setIsPublished(!isPublished)}
                                    style={{background: isPublished? '#57DBD3' : 'rgb(170, 186, 183, 0.1)'}}
                                    >
                                    <label className="bold-text font-12" style={{color: isPublished ? '#FFFFFF' : '#020819'}}>PUBLISHED</label>
                                </div>
                        </div>}
                    />

            <div>
                {loading ? 
                <div className="flex flex-1 items-center justify-center mt-4">
                    <img src={Loading} />
                    <label className="bold-text font-16 ml-2 light-purple-text">Loading</label>
                </div>
                :
                <TransitionGroup  className="projects-grid">
                    {projects.content?.map(project =>
                        <CSSTransition key={project.id} appear classNames="fade" timeout={500}>
                            <Project key={project.id} project={project}/>
                        </CSSTransition>
                    )}
                </TransitionGroup>  
                }
            </div>
        </div>
    )
}

export default Projects;
