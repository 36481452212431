import React from 'react';

import './delete-user-popup.scss';

const DeleteUserModal = ({ name, close, deleteUser }) => {
  const onClick = e => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      close();
    }
  };

  return (
    <div className="delete-user-popup" onClick={onClick}>
      <div className="delete-user-popup-content">
        <label className="dup-title">Are you sure you want to delete {name}?</label>
        <div className="dup-actions">
          <button onClick={close}>Cancel</button>
          <button className="delete-action" onClick={deleteUser}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserModal;
