import React from 'react';

import './style.scss';

const IconInput = ({
  activeIcon,
  inactiveIcon,
  value,
  onChange,
  className,
  placeholder
}) => {
  
  return (
    <div className={`flex items-center icon-input ${className}`}>
        <img src={value ? activeIcon : inactiveIcon} />
        <input className='flex-1' value={value} onChange={onChange} placeholder={placeholder} />
    </div>
  );
};

export default IconInput;
