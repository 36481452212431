import { ReactComponent as EditIcon } from '../../../assets/images/edit-image.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/remove-upload.svg';
import { ReactComponent as TeamLead } from '../../../assets/images/add-team-lead.svg';
import { ReactComponent as TeamMember } from '../../../assets/images/add-user.svg';
import React from 'react';
import Button from '../../Elements/button/button';

const Menu = props => {
  const { clickItem, user, isTeamUsers, checkPermission, hasPermission, organisations, index } = props;
  const currectUserOrganisation = organisations[0] || {};

  return (
    <div className={`card menu-wrapper ${index < 4 ? 'menu-position-top' : 'menu-position-bottom'}`}>
      <div style={{ padding: '8px' }}>
        <label
          className="regular-text cursor"
          onClick={e => {
            e.stopPropagation();
            checkPermission(() => clickItem('EDIT'), 'USERS_MANAGE');
          }}
          style={{ opacity: hasPermission('USERS_MANAGE') ? 1 : 0.5 }}>
          <EditIcon />
          <span className="ml-3">Edit</span>
        </label>

        <label
          className="regular-text cursor"
          onClick={e => {
            e.stopPropagation();
            checkPermission(() => clickItem('DELETE'), 'USERS_MANAGE');
          }}
          style={{ opacity: hasPermission('USERS_MANAGE') ? 1 : 0.5 }}>
          <DeleteIcon />
          <span className="ml-3">{isTeamUsers ? 'Remove from team' : 'Delete'}</span>
        </label>
        {isTeamUsers && (
          <label
            className="regular-text cursor"
            onClick={e => {
              e.stopPropagation();
              checkPermission(
                () =>
                  clickItem(currectUserOrganisation?.level === 'MEMBER' ? 'CHANGE_USER_LEAD' : 'CHANGE_USER_MEMBER'),
                'USERS_MANAGE',
              );
            }}
            style={{ opacity: hasPermission('USERS_MANAGE') ? 1 : 0.5 }}>
            {currectUserOrganisation?.level === 'MEMBER' ? <TeamLead /> : <TeamMember />}
            <span className="ml-3">
              {currectUserOrganisation?.level === 'MEMBER' ? 'Make team lead' : 'Make team member'}
            </span>
          </label>
        )}
        <div style={{ opacity: hasPermission('USERS_MANAGE') ? 1 : 0.5 }}>
          <hr />
          {user.status === 'ACTIVE' ? (
            <div className="regular-text cursor justify-between">
              <label>Active</label>
              <div
                className={`switch ${user.is_active && 'switch-selected'}`}
                onClick={e => {
                  e.stopPropagation();
                  checkPermission(() => clickItem('ACTIVE'), 'USERS_MANAGE');
                }}>
                <div className="selector" />
              </div>
            </div>
          ) : (
            <div className="cursor justify-between">
              <Button
                additional={true}
                className="additional-hover invite-btn"
                label={
                  user.status === 'INACTIVE'
                    ? 'Send invite'
                    : user.status === 'EXPIRED'
                    ? 'Re-send invite'
                    : user.status === 'EXPIRED'
                    ? 'Re-send invite'
                    : 'Send invite'
                }
                onClick={e => {
                  e.stopPropagation();
                  checkPermission(() => clickItem('INVITE_USER'), 'USERS_MANAGE');
                }}
                width="100%"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
