import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import Button from '../components/Elements/button/button';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { addToast } from '../store/features/toastSlice';
import { DeleteCampaignTierWrapper } from './modal.styled.js';
import { CSSTransition } from 'react-transition-group';
import { deleteCampaignTier } from '../store/features/campaignSlice';

const DeleteCampaignTier = () => {
  const { modal, setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const tier = modal.content.tier;
  const campaignId = modal.content.campaignId;

  const onDelete = async () => {
    if (loading) return;
    setLoading(true);
    try {
      dispatch(deleteCampaignTier({campaignId: campaignId, tierId: tier.id}))
      dispatch(
        addToast({
          error: false,
          title: 'Tier removed',
          text: `${tier.name} has been removed`,
          id: uuid(),
        }),
      );
    } catch (error) {
      console.log(error);
      dispatch(
        addToast({
          error: true,
          text: `Failed to delete tier`,
          id: uuid(),
        }),
      );
    }
  
    setLoading(false);
    setModal(initModal);
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <DeleteCampaignTierWrapper>
        <p className="bold-text font-24 text-center">
          Are you sure you want to delete this tier?
        </p>
        <div className="flex-column user-details-container relative" style={{ marginTop: 32 }}>
          <div className='flex items-center'>
            <div className='delete-tier-color' style={{background: tier.color_code}} />
            <label className='medium-text font-16 ml-2'>{tier.name}</label>
          </div>
          <label className='regular-text light-purple-text mt-2'>Template name: <span className='regular-text'>{tier.template?.name}</span></label>
          <label className='regular-text mt-4'>{tier.description}</label>
        </div>
        <div className="flex justify-center mt-12">
          <Button
            className="secondary-hover"
            label="Cancel"
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            style={{ flex: 1, borderRadius: '16px' }}
            bgColor="#FFFFFF"
            borderColor='#24CBB1'
            color='#24CBB1'
          />
          <Button
            label='Delete'
            onClick={onDelete}
            size="large"
            style={{ flex: 1, marginLeft: 24, borderRadius: '16px' }}
            bgColor="#FB3535"
            borderColor='#FB3535'
            color='#FFFFFF'
          />
        </div>
      </DeleteCampaignTierWrapper>
    </CSSTransition>
  );
};

export default DeleteCampaignTier;
