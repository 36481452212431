import React, { useEffect, useState } from "react";

import { getTemplates } from "../../../store/features/templateSlice";
import { cteateCollectionTemplates } from "../../../store/features/collectionSlice";

import { useDispatch, useSelector } from "react-redux";

import Pagination from "../../../components/pagination/pagination";
import Image from "../../image";

import "./style.scss";

const AddTemplatePopup = ({onCancel, collectionId}) => {
    const dispatch = useDispatch();

    const { templates } = useSelector(data => data.template);

    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(0)
    const [selected, setSelected] = useState([])    

    const addTemplate = async () => {
        dispatch(cteateCollectionTemplates({id: collectionId, templates: selected}))
        onCancel()
    }

    useEffect(() => {
        dispatch(getTemplates({page: currentPage, size: 5, search}))
    }, [currentPage, search, dispatch])

    return (
        <div className='add-template-popup'>
            <div className='add-template-popup-content'>
                <div className="flex-column px-12 pt-12 pb-6">
                    <label className='popup-title mb-6'>Import</label>
                    <div className="flex items-center">
                        <input className="edit-retirement-input edit-t-id" style={{width: "100%", height: '40px'}} placeholder="Template id"/>
                        <div className="flex items-center justify-center add-btn ml-4 cursor" style={{width: '96px'}}>
                            <label className="bold-text font-14 violet-light-text">Go</label>
                        </div>
                    </div>
                </div>
                <div className="flex-column px-12 pt-6 pb-12 border-top">
                    <label className="semibold-text font-16 ">Chose</label>
                    <div className="flex items-center mt-4 mb-4">
                        <input className="edit-retirement-input edit-t-search mr-4" placeholder="Search" style={{width: "100%", height: '32px'}} onChange={e => setSearch(e.target.value)} />
                        <Pagination fixed={true} selectedPage={currentPage} setSelectedPage={setCurrentPage} totalPages={templates.total_pages} height={ '32px'}/>
                    </div>
                    <div className="flex-column add-t-templates">
                        {templates.content?.map(template => 
                        <div className="flex items-center justify-between pxy-2 add-template-template mt-2 cursor" onClick={() => setSelected(selected.includes(template.id) ? selected.filter(s => s !== template.id) : [...selected, template.id])}>
                            <div className="flex items-center one-line">
                                <Image className={"add-template-template-image"} size={40} media={template?.image?.media} />
                                <label className="regular-text one-line ml-2">{template.name}</label>
                            </div>
                            <div className="flex items-center justify-center add-t-radio mr-2 ml-4">
                                {selected.includes(template.id) && <div className="add-t-radio-selected"/> }
                            </div>
                        </div>)}
                    </div>
                    
                    <div className="flex justify-center mt-auto mt-10">
                        <div className="flex items-center justify-center cancel-btn cursor mr-8"
                                onClick={() => onCancel(false)}>
                            <label className="medium-text color-green">Cancel</label>
                        </div>
                        <div
                            onClick={addTemplate}
                            className={`${(true) ? 'done-btn' : 'disable-btn'} flex items-center justify-center cursor`}>
                            <label className="medium-text color-white">Save</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddTemplatePopup;