import React from "react";

import Header from "../../header";
import { useSelector, useDispatch } from "react-redux";

import { getCampaignRetirementNfts, getCampaignMerchandiseNfts, getCampaignFractionalNfts } from "../../../store/features/campaignSlice";

import CampaignRetirementNfts from "../CampaignRetirementNfts"
import CampaignMerchandiseNfts from "../CampaignMerchandiseNfts";
import CampaignFractionalNfts from "../CampaignFractionalNfts";
    
import './style.scss'

const CampaignNfts = ({campaignId, selectedNftTab, nftTabOptions, onNftTabChange, isNewCampaign, menuItems, onMenuItemClick, onAdd, onCreateNft }) => {
    const { merchandiseNfts } = useSelector(data => data.campaign);
    const dispatch = useDispatch();

    return(
        <div className="flex-column">
            <div className="border-bottom">
                 <Header 
                    title="NFTs"
                    isSearch={false}
                    isPaged={false}
                    size='small'
                    addBtnText={"Mint NFTs"}
                    menuItems={menuItems}
                    onMenuItemClick={onMenuItemClick}
                    isAddBtn={true}
                    addPermission="NFT_MANAGE"
                    onAdd={onAdd}
                    onCreateNft={() => onCreateNft()}
                    tabs={nftTabOptions}
                    selectedTab={selectedNftTab.value}
                    onTabChange={onNftTabChange}
                    isLeaderboard={selectedNftTab.value === 'MERCHANDISE' && merchandiseNfts.content?.length === 0}
                    onRefresh={() => (selectedNftTab?.value === 'RETIREMENT' || selectedNftTab?.value === 'MEASUREMENT') ? 
                    dispatch(getCampaignRetirementNfts({campaignId: campaignId, merge: false, page: 0, type: selectedNftTab?.value })) : 
                    selectedNftTab?.value === 'FRACTIONAL' ?
                    dispatch(getCampaignFractionalNfts({campaignId: campaignId, merge: false, page: 0})) : 
                    dispatch(getCampaignMerchandiseNfts({campaignId: campaignId, merge: false, page: 0}))}
                    tabMenu={true}
                />
            </div>
            <div className="camp-nft-grid camp-nft-header border-bottom">
                <label className="font-12 semibold-text px-6 py-4">DETAILS</label>
                <label className="font-12 semibold-text px-6 py-4">PROJECT</label>
                <label className="font-12 semibold-text px-6 py-4">CARBON</label>
                <label className="font-12 semibold-text px-6 py-4">OWNER/WALLET</label>
                <label className="font-12 semibold-text px-6 py-4">TOKEN</label>
                <label className="font-12 semibold-text px-6 py-4">MINTED</label>
                <label className="font-12 semibold-text px-6 py-4">STATUS</label>
            </div>
           {selectedNftTab?.value === 'RETIREMENT' || selectedNftTab?.value === 'MEASUREMENT' ?
            <CampaignRetirementNfts campaignId={campaignId} isNewCampaign={isNewCampaign} type={selectedNftTab?.value} /> :
            selectedNftTab?.value === 'FRACTIONAL' ?
            <CampaignFractionalNfts campaignId={campaignId} isNewCampaign={isNewCampaign} /> :
            <CampaignMerchandiseNfts campaignId={campaignId} isNewCampaign={isNewCampaign} />}
        </div>
    )
}

export default CampaignNfts;