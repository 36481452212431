import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import uuid from 'react-uuid';
import Button from '../components/Elements/button/button';
import { initModal } from '../constant/InitialData';
import { addToast} from '../store/features/toastSlice'
import { AddAutomationWrapper } from './modal.styled.js';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import Selected from '../assets/images/selected.svg';
import { OrganisationContext } from "../context/organisationContext";
import api from "../store/services";

const MultiValueOption = props => {
  const onSelect = () => {
    props.setValue({ ...props.data });
  };
  return (
    <div className={`tpus-user ${props.isSelected && 'tpus-user-selected'}`} onClick={() => onSelect()}>
      <label className={`regular-text`}>{props.label}</label>
      {props.isSelected && <img alt="icon" src={Selected} />}
    </div>
  );
};

const SingleValue = props => {
  const { data, setDropdownOpened, dropdownOpened } = props;
  const getValueLogo = name => {
    if (!name) return '';
    const nameArray = name?.split(' ');
    return (
      <div className="value-logo">
        <label className="bold-text">
          {nameArray?.[0]?.charAt(0)?.toUpperCase()}
          {nameArray?.[1]?.charAt(0)?.toUpperCase()}
        </label>
      </div>
    );
  };
  return (
    <div
      className={`flex items-center ignore-click ${dropdownOpened && 'hide-single-value'}`}
      onClick={() => setDropdownOpened(!dropdownOpened)}>
      {getValueLogo(data?.label)}
      <label className={`regular-text font-16 w-full`}>{data?.label}</label>
    </div>
  );
};

const AddAutomation = () => {
  const { user, organisation } = useSelector(data => data.user)

  const { modal, setModal } = useContext(OrganisationContext);
  const isEdit = modal.content?.isEdit;

  const dispatch = useDispatch();
  const textareaRef = useRef(null);

  const [automation, setAutomation] = useState({});
  const [loading, setLoading] = useState(false);
  const [inputState, setInputState] = useState('');
  const [options, setOptions] = useState([]);
  const [owner, setOwner] = useState({});
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);

  const getOptions = async inputValue => {
    const { data } = await api.get(`/v1/users?organisation_id=${organisation}&name=${inputValue}&size=100`);
    return data.content.map(o => ({ ...o, value: o.id, label: o.name }));
  };

  const loadOptions = async inputValue => {
    const options = await getOptions(inputValue);
    return options;
  };

  const handleChange = value => {
    setOwner(value);
  };

  const handleInputChange = newValue => {
    setInputState(newValue);
    return newValue;
  };

  useEffect(() => {
    const fetchDefaultOptions = async () => {
      setLoadingOptions(true);
      const defaultOptions = await getOptions('');
      setOptions(defaultOptions);
      setLoadingOptions(false);
    };
    fetchDefaultOptions();
  }, []);

  useEffect(() => {
    if (modal.content?.automation) {
      const { owner } = modal.content.automation;
      if (owner.id) {
        setOwner({ ...owner, value: owner.id, label: owner.name });
      } else {
        setOwner({
          ...user,
          value: user.external_id,
          label: `${user.forename} ${user.surname}`,
        });
      }
      setAutomation({ ...modal.content.automation });
    } else {
      setOwner({
        ...user,
        value: user.external_id,
        label: `${user.forename} ${user.surname}`,
      });
      setAutomation({ event_type: null, is_enabled: false });
    }
  }, [modal.content?.automation]);

  useEffect(() => {
    textareaRef.current.style.height = '0px';
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + 5 + 'px';
  }, [automation.description]);

  const changesAvailable = () => {
    const { name, description } = automation;
    const {
      name: oldAutomationName,
      description: oldAutomationDescription,
      owner: oldAutomationOwner,
    } = modal.content?.automation || {};
    return (
      name !== oldAutomationName || description !== oldAutomationDescription || owner?.value !== oldAutomationOwner?.id
    );
  };

  const onDone = async () => {
    if ((isEdit && changesAvailable()) || !isEdit) {
      try {
        setLoading(true);
        const request = isEdit
          ? {
              name: automation.name,
              description: automation.description,
              owner: { id: owner.value },
            }
          : {
              name: automation.name,
              description: automation.description,
              owner: { id: owner.value },
              is_enabled: automation.is_enabled,
            };
        const { data } = isEdit
          ? await api.put(`/v3/api/automations/${automation.id}`, request)
          : await api.post(`/v3/api/automations`, request);

        dispatch(
          addToast({
            error: false,
            text: isEdit ? 'Automation updated successfully' : 'New Automation added successfully',
            id: uuid(),
          }),
        );
        setLoading(false);

        setModal({ type: 'automation-success', content: { isEdit, automation: data } });
      } catch (e) {
        setLoading(false);

        dispatch(
          addToast({
            error: true,
            text: isEdit ? 'Error while updating automation' : 'Error while creating automation',
            id: uuid(),
          }),
        );
      }
    } else {
      setModal(initModal);
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <AddAutomationWrapper>
        <p className="bold-text font-24 text-center mb-2">{isEdit ? 'Edit automation' : 'New automation'}</p>
        <div className="w-full flex-column mb-4 mt-6">
          <label className="regular-text field-label mb-2">Name</label>
          <input
            className="popup-input"
            onChange={e => setAutomation({ ...automation, name: e.target.value })}
            placeholder="Enter automation name"
            value={automation.name || ''}
          />
        </div>
        <div className="w-full flex-column mb-4 mt-4">
          <label className="regular-text field-label mb-2">Description</label>
          <textarea
            ref={textareaRef}
            className="popup-input description-input"
            onChange={e => setAutomation({ ...automation, description: e.target.value })}
            placeholder="Enter description here"
            rows={4}
            value={automation.description || ''}
          />
        </div>
        <div className="w-full mb-6 flex-column mb-6 mt-4">
          <label className="regular-text field-label mb-2">Owner</label>
          <AsyncSelect
            className={`options-select-container ${dropdownOpened ? 'opened' : ''}`}
            classNamePrefix="select"
            components={{
              Option: MultiValueOption,
              SingleValue: props => (
                <SingleValue {...props} dropdownOpened={dropdownOpened} setDropdownOpened={setDropdownOpened} />
              ),
              Input: props => <components.Input {...props} autoFocus={dropdownOpened} />,
            }}
            defaultOptions={options}
            inputValue={inputState}
            isLoading={loadingOptions}
            loadOptions={loadOptions}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onMenuClose={() => setDropdownOpened(false)}
            onMenuOpen={() => setDropdownOpened(true)}
            placeholder="Select owner"
            value={owner.value ? owner : null}
          />
        </div>
        <div className="flex justify-center mt-10 w-full">
          <Button
            bgColor="white"
            borderColor="#32E5C5"
            label={'Cancel'}
            onClick={() => setModal(initModal)}
            size="large"
            style={{ flex: 1 }}
            width="170px"
          />
          <Button
            bgColor="#40F1DE"
            borderColor="#40F1DE"
            color="white"
            disabled={!(automation.name?.trim() && owner.value) || loading}
            label={`${isEdit ? 'Update' : 'Done'}`}
            onClick={onDone}
            size="large"
            style={{ flex: 1, marginLeft: 24 }}
            width="170px"
          />
        </div>
      </AddAutomationWrapper>
    </CSSTransition>
  );
};

export default AddAutomation;
