import { ReactComponent as DotsIcon } from '../../assets/images/menu/dots.svg';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, formatText, getIntegrationTypeIcon } from '../../helpers/utils';
import api from '../../store/services'
import { addToast} from '../../store/features/toastSlice'
import Menu from './menu';
import Logo from '../../assets/images/logo.svg';

const IntegrationItem = ({
  integration,
  checkPermission,
  hasPermission,
  setSelectedIntegration,
  updateIntegration,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  const { setModal } = useContext(OrganisationContext);

  const menuClick = action => {
    setShowMenu(false);
    switch (action) {
      case 'EDIT':
        setSelectedIntegration(integration);
        break;
      case 'DELETE':
        setModal({ type: 'integration-actions', content: { integration: integration, top: true, isDelete: true } });
        break;
      default:
        break;
    }
  };

  const onChangeStatus = async () => {
    const { is_enabled } = integration;
    if (is_enabled) {
      setModal({ type: 'integration-actions', content: { integration: integration, top: true, isDisable: true } });
    } else {
      try {
        const request = {
          enabled: !is_enabled,
        };
        updateIntegration({ ...integration, is_enabled: !is_enabled });
        await api.put(`/v3/api/integrations/${integration.id}/state`, request);
      } catch (error) {
        const errorData = error?.response?.data;
        updateIntegration({ ...integration, is_enabled: is_enabled });
        dispatch(
          addToast({
            error: true,
            text:
              errorData?.error_code === 'BAD_REQUEST'
                ? 'Please add all required fields in integration'
                : 'Error while updating status',
            id: uuid(),
          }),
        );
      }
    }
  };

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowMenu(false);
    }
  };

  return (
    <div
      onClick={() =>
        checkPermission(
          () => setSelectedIntegration(integration),
          integration.is_default ? 'INTEGRATIONS_MANAGE' : 'INTEGRATIONS_MANAGE',
        )
      }>
      <div className="integration-item cursor flex card items-center">
        <label className="regular-text font-16">{integration.name}</label>

        <div className="border-right mh h-9" />
        <div className="flex-column cursor pr-4 pt-2 pb-2 pl-4 second-width">
          {integration?.description ? (
            <label className="double-line regular-text grey-text font-14">{integration?.description}</label>
          ) : (
            <label className="grey-text font-14 no-description">{'No description'}</label>
          )}
        </div>
        <div className="border-right mh h-9" />
        <div className="integration-item-right ">
          <div className="relative flex items-center flex-ratio" onBlur={onBlur} tabIndex={0}>
            <div className="integration-item-bottom">
              <div className="flex items-center">
                <img alt="icon" className="icons" src={`${integration.connector.icon?.active}`} />
                <span className="regular-text font-14 ml-2">{capitalize(formatText(integration.connector?.name))}</span>
              </div>
            </div>
          </div>
          <div>{integration.is_default && <img alt="icon" className="default-main-logo" src={Logo} />}</div>
        </div>
        <div className="border-right items-center mh h-9" />
        <div className="items-center justify-center cursor pl-6">
          <div className="integration-item-last">
            <span
              className={`regular-text flex items-center font-16 last-width ${
                integration.is_enabled ? 'enable-color' : 'disable-color'
              }`}>
              {integration.is_enabled ? 'Enabled' : 'Disabled'}
            </span>
            <div
              className={`switch ml-1 ${integration.is_enabled && 'switch-selected'} ${
                hasPermission(integration.is_default ? 'INTEGRATIONS_MANAGE' : 'INTEGRATIONS_MANAGE')
                  ? 'cursor'
                  : 'not-allowed'
              }`}
              onClick={e => {
                e.stopPropagation();
                checkPermission(
                  () => onChangeStatus(),
                  integration.is_default ? 'INTEGRATIONS_MANAGE' : 'INTEGRATIONS_MANAGE',
                );
              }}>
              <div className="selector" />
            </div>
            <div
              className="menu-btn cursor flex items-center relative py-1 px-2 menu-hover"
              onClick={e => {
                e.stopPropagation();
                checkPermission(
                  () => setShowMenu(!showMenu),
                  integration.is_default ? 'INTEGRATIONS_MANAGE' : 'INTEGRATIONS_MANAGE',
                );
              }}>
              <DotsIcon />
              {showMenu && (
                <div>
                  <Menu
                    checkPermission={checkPermission}
                    clickItem={action => menuClick(action)}
                    hasPermission={hasPermission}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationItem;
