import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Header from "../../components/header";
import CollectionCard from "../../components/collections/CollectionCard";
import { getCollections, getCollectionIntegrations, setIntegrations } from '../../store/features/collectionSlice';
import Filter from "../../components/filter";

import './style.scss';

const Collections = () => {
    const dispatch = useDispatch();
    const { collections } = useSelector(data => data.collection);

    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState({value: ''});

    useEffect(() => {
        dispatch(getCollections({ page: currentPage, filter: filter?.value }));
    }, [currentPage, filter?.value, dispatch]);

    useEffect(() => {
        dispatch(getCollectionIntegrations());
    }, [])

    return (
        <div className="flex-column mb-6 mt-2 flex-1">
            <Header title={"Collections"}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    search={search}
                    setSearch={setSearch}
                    totalPages={collections.total_pages}
                    isFilter={true}
                    addPermission="COLLECTIONS_MANAGE"
                    filterChildren={<Filter objects={["INTEGRATION"]} setFilter={setFilter} values={filter?.filters} />}
                    />

            <div className="pt-6 pb-6 mx-6 collections-grid">
                {collections.content?.map(c => <CollectionCard key={c.id} collection={c}  />)}
            </div>
        </div>
    )
}

export default Collections;