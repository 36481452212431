import React, { useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSchema, getSchema, setSchema, updateSchema } from "../../store/features/schemaSlice";
import SchemaDetailsComponent from "../../components/schemas/SchemaDetails";
import SchemaAttributes from "../../components/schemas/SchemaAttributes";
import SchemaCollections from "../../components/schemas/SchemaCollections";
import NavTop from "../../components/common/NavTop";
import { Route, Routes, useParams, useNavigate } from 'react-router-dom';
import TemplateMenu from "../../components/DefaultMenu";

import './style.scss';

const SchemaDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { schema } = useSelector(data => data.schema);

    const {id} = useParams();
    const isNew = id === 'new';

    const menuItems = [{id: 0, name: 'Attributes', text: 'Attributes', path: '/attributes'},
                       {id: 1, name: 'Collections', text: 'Collections', path: '/collections'}];

    const onMenuItemClick = item => {
        navigate(`/meta/schemas/${id}${item.path}`)
    }

    useEffect(() => {
        if (isNew) {
            dispatch(setSchema({}));
        } else {
            dispatch(getSchema(id));
        }
    }, [id, isNew]);

    const saveSchema = async (updatedSchema, isNew) => {
        if (isNew) {
            await dispatch(createSchema({request: {name: updatedSchema?.name, description: updatedSchema?.description}}));
        } else {
            await dispatch(updateSchema({id: id, request: {name: updatedSchema.name, description: updatedSchema.description}}));
        }
    }


    return (
        <div className="flex-column schema-details flex-1">
            <div className="mb-6 mt-8 ml-6">
                <NavTop names={[{text: "Schemas", path: "/meta/schemas"}, {text: schema?.name}]} edit={false} />
            </div>
            <div className="flex">
                <SchemaDetailsComponent schema={schema} saveSchema={saveSchema} isNew={isNew} />
                <div className="flex-column flex-1 mr-6">
                    <Routes>
                        <Route element={<SchemaAttributes schema={schema} isNew={isNew} menu={<TemplateMenu menuItems={menuItems} onMenuItemClick={onMenuItemClick}  />} />} path={`/attributes`} />
                        <Route element={<SchemaCollections schema={schema} menu={<TemplateMenu menuItems={menuItems} onMenuItemClick={onMenuItemClick}  />} />} path={`/collections`} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default SchemaDetails;
