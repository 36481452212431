import React from 'react'
import { useNavigate } from "react-router-dom";

import './style.scss'

const Product = ({product}) => {
    const navigate = useNavigate();

    return (
        <div className="flex items-center cursor product-items border-bottom" onClick={() => navigate(`/meta/products/${product.id}`)}>
            <img className="product-image pxy-2 " src={product.creatives.find(c => c.resource_type !== 'video')?.url} alt="nft-image"/>
            <div className="flex-column justify-center flex-2-5 pl-2 pr-6 border-right product-item">
                <div className="flex items-center justify-between">
                    <label className="bold-text">{product.name}</label>
                    <label className="regular-text">{product.sku}</label>
                </div>
                <label className="regular-text o05 font-12 product-desc mt-2">{product.description}</label>
            </div>
            <div className="flex-column justify-center flex-1 pl-6 border-right product-item">
                <label className="regular-text o05">Brand:</label>
                <label className="regular-text mt-2">{product.brands[0]?.name}</label>
            </div>
            <div className="flex-column justify-center flex-1 pl-6 border-right product-item ">
                <label className="regular-text o05">Product type:</label>
                <label className="regular-text mt-2">{product.product_type}</label>
            </div>
            <div className="flex-column justify-center flex-1 pl-6 border-right product-item">
                <label className="regular-text o05">Product family:</label>
                <label className="regular-text mt-2">{product.families[0]?.name}</label>
            </div>
            <div className="flex-column justify-center pl-6 h-full flex-0-8 product-item">
                <label className="regular-text o05">Price:</label>
                <label className="bold-text font-20 mt-1">${product.pricing?.[0]?.prices?.[0]?.price}</label>
            </div>
        </div>
    )
}

export default Product;
