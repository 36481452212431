import React from "react";

import "./style.scss";

const ReportSale = ({ sale }) => {
  const monthNames = [
    "January",
    "Feburary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="flex-column">
      <div className="flex-column">
        <div
          className={
            "report-details-grid-sales ci-card cursor border-top cursor"
          }
        >
          {/* <label className='regular-text px-4 py-3'>{sale.inventory?.name || '-'}</label> */}
          <label className="regular-text px-4 py-3 one-line">
            {sale.project?.name || "-"}
          </label>
          <label className="regular-text px-4 py-3 one-line">
            {sale.owner?.name || "-"}
          </label>
          <label className="regular-text px-4 py-3 one-line">
            {sale.serial || "-"}
          </label>
          <label className="regular-text px-4 py-3 one-line">
            {monthNames[sale.date - 1]}
          </label>
          <label className="regular-text px-4 py-3 one-line">
            {sale.amount
              ? `${sale.amount.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} ${sale.currency}`
              : "N/A"}
          </label>
          <label className="regular-text px-4 py-3 one-line">
            {sale.amount_usd
              ? `$${sale.amount_usd.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`
              : "N/A"}
          </label>
      
          <label className="regular-text px-4 py-3 one-line">{ `$${sale.paymentFees?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}</label>
          <label className="regular-text px-4 py-3 one-line">{ `$${sale.ownerCommission?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}</label>
          <label className="regular-text px-4 py-3 one-line">{ `$${sale.platformCommission?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}</label>
          <label className="regular-text px-4 py-3 one-line">${(sale.amount_usd - sale.paymentFees - sale.ownerCommission - sale.platformCommission).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</label>
          
          <label className="regular-text px-4 py-3 one-line">
            {(sale.sales / 1000).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </label>
        </div>
      </div>

      {/* <div
        className={
          "report-details-grid-sales ci-card cursor border-top cursor border-bottom mb-10"
        }
      >
        <label className="bold-text px-4 py-3"></label>
        <label className="bold-text px-4 py-3"></label>
        <label className="bold-text px-4 py-3"></label>
        <label className="bold-text px-4 py-3">Total:</label>
        <label className="bold-text px-4 py-3">
          {v
            .map((p) => p.amount)
            .reduce((sum, p) => sum + p, 0)
            .toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
          {k.split("::")[1]}
        </label>
        <label className="bold-text px-4 py-3">
          {v
            .map((p) => p.amount_usd)
            .reduce((sum, p) => sum + p, 0)
            .toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
          USD
        </label>
        <label className="bold-text px-4 py-3">
          {(
            v.map((p) => p.sales).reduce((sum, p) => sum + p, 0) / 1000
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </label>
      </div> */}
    </div>
  );
};

export default ReportSale;
