import React, { useEffect, useRef, useState } from 'react';
import AllIcon from '../../../assets/images/all-icon.svg';
import CavitiesIcon from '../../../assets/images/cavities.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/checked.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-image.svg';
import PropertyIcon from '../../../assets/images/house-icon.svg';
import LoftIcon from '../../../assets/images/loft.svg';
import ArrowDownIcon from '../../../assets/images/menu/arrow-down-black.svg';
import OptionalIcon from '../../../assets/images/optional-icon.svg';
import ProfileIcon from '../../../assets/images/profile.svg';
import Loading from '../../../assets/images/loading.gif';
import { getSurveyIcon } from '../../../helpers/utils';
import DropdownWrapper from './dropdown.styled';

const getIcon = name => {
  if (name === 'All') return AllIcon;
  else if (name.toLowerCase().includes('property')) return PropertyIcon;
  else if (name.toLowerCase().includes('contact')) return ProfileIcon;
  else if (name.toLowerCase().includes('cavity')) return CavitiesIcon;
  else if (name.toLowerCase().includes('loft')) return LoftIcon;
  else return OptionalIcon;
};

const Dropdown = ({
  components,
  component,
  borderColor,
  children,
  multiple,
  onSelect = () => {},
  options = [],
  placeholder,
  scrollEnabled,
  selected = null,
  type,
  withIcon = true,
  isOptional,
  dropdownHeight,
  className,
  height,
  fetchData,
  hasMore,
  infiniteSCroll,
  getSelectedId,
  showDropdownArrowForInput = false,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const loader = useRef(null);
  const [focusInput, setFocuesInput] = useState(false);
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    if (type !== 'input' && !children && !multiple) setShow(false);
  }, [options, selected]);

  useEffect(() => {
    // if (type === 'input') setShow(true);
  }, [options]);

  const onClick = option => {
    onSelect(option);
    if (!multiple && !option.disabled) setShow(false);
    setShowInput(false);
  };

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShow(false);
      setShowInput(false);
      if (props.onChange) props.onChange({ target: { value: '' } });
    }
  };
  const handleObserver = entities => {
    const target = entities[0];
    if (target.isIntersecting) {
      if (hasMore) fetchData();
    }
  };
  useEffect(() => {
    if (!infiniteSCroll) return;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [loader.current]);

  const onInputClick = () => {
    setShowInput(true);
    setFocuesInput(true);
    setShow((!!children || options.length > 0) && !show);
  };

  return (
    <DropdownWrapper className={className} onBlur={onBlur} tabIndex={0}>
      {(type === 'input' && !selected) || showInput ? (
        <div className="flex relative w-full">
          <input
            autoFocus={type === 'input' && focusInput}
            className={`input ${showDropdownArrowForInput && 'arrow-padding'}`}
            onFocus={() => setShow(true)}
            placeholder={placeholder}
            style={{ width: '100%' }}
            {...props}
            autoComplete="turnoff"
          />
          {showDropdownArrowForInput && (
            <div
              className="input-arrow"
              onClick={() => {
                if (type === 'input') {
                  setShow(false);
                  setShowInput(false);
                }
              }}>
              <img alt="icon" className={`dropdown-icon ${show && 'open'} cursor`} src={ArrowDownIcon} />
            </div>
          )}
        </div>
      ) : (
        <div
          className="selector"
          onClick={() => (type === 'input' ? onInputClick() : setShow((!!children || options.length > 0) && !show))}
          style={{ minHeight: height || 40, height: height || 40, borderColor: borderColor ? borderColor : show ? '#32e5c5' : '#d2e5ed' }}>
          <div className="flex items-center" style={{ width: '100%', flexWrap: multiple ? 'wrap' : 'nowrap' }}>
            {multiple && selected?.length > 0 ? (
              selected.map(item => (
                <div key={item.id} className="tag">
                  {withIcon && (
                    <img
                      alt="icon"
                      src={item.small_icon ? getSurveyIcon(item.small_icon) : getIcon(item.name)}
                      style={{ width: 16, height: 16, marginRight: 8 }}
                    />
                  )}
                  <label className="regular-text font-16">{item.name || item}</label>
                  <CloseIcon
                    onClick={e => {
                      onClick(selected.filter(option => option !== item));
                    }}
                    style={{ width: 16, height: 16, marginLeft: 4 }}
                  />
                </div>
              ))
            ) : (
              <>
                {withIcon && !multiple && selected && (
                  <img
                    alt="icon"
                    className="mark-icon"
                    src={selected.small_icon ? getSurveyIcon(selected.small_icon) : getIcon(selected.name)}
                  />
                )}
                <label
                  className={`flex flex-1 regular-text font-16 ${
                    isOptional && (!selected || multiple || selected.length === 0) && 'is-optional'
                  }`}
                  style={{ opacity: !multiple && selected && selected.name ? 1 : 0.3 }}>
                  {!multiple && selected && selected.name ? (
                    selected.name ? (
                      <>
                        {selected.name}
                        {selected.name2 && <span className="color-gray mx-2">{selected.name2}</span>}
                      </>
                    ) : (
                      selected.name
                    )
                  ) : (
                    placeholder
                  )}
                </label>
              </>
            )}
          </div>
          {((type !== 'input' && !children) || showDropdownArrowForInput) && (
            <img
              alt="icon"
              className={`dropdown-icon ${show && 'open'} cursor`}
              onClick={() => (type === 'input' ? onInputClick() : setShow((!!children || options.length > 0) && !show))}
              src={ArrowDownIcon}
            />
          )}
        </div>
      )}
      <div>
        {component ?
        <div>
          {component}
        </div>
        :
        <div>
          {(children || options.length > 0) && (
            <div
              className="options card"
              style={{
                maxHeight: show
                  ? scrollEnabled
                    ? dropdownHeight
                      ? dropdownHeight
                      : children
                      ? 400
                      : Math.min(400, options.length * 40)
                    : children
                    ? null
                    : options.length * 40
                  : 0,
                overflow: 'scroll',
              }}>
              {children
                ? children({ onClick })
                : 
                <div>
                {
                options.map((option, i) => {
                    const isSelected = multiple
                      ? selected?.find(item => item.id === option.id)
                      : getSelectedId
                      ? getSelectedId(selected) === getSelectedId(option)
                      : selected === option;
                    return (
                      <div
                        key={i}
                        className={`option-item ${option.disabled ? 'disabled-option' : ''}`}
                        onClick={() =>
                          onClick(
                            multiple
                              ? isSelected
                                ? selected.filter(item => item.id !== option.id)
                                : [...(selected || []), option]
                              : option,
                          )
                        }>
                        {withIcon && (
                          <img
                            alt="icon"
                            className="mark-icon"
                            src={option.small_icon ? getSurveyIcon(option.small_icon) : getIcon(option.name)}
                          />
                        )}
                        <label className="flex-1 regular-text font-16">{option.name || option}</label>
                        {isSelected && <CheckIcon className="option-item-icon" />}
                      </div>
                    );
                  })}
                  {components}
                  </div>
                }
              {hasMore && (
                <div ref={loader} className="item flex items-center justify-center">
                  <img alt="loading" height="32px" src={Loading} />
                </div>
              )}
            </div>
          )}
          </div>
        }
      </div>
    </DropdownWrapper>
  );
};

export default Dropdown;
