import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { OrganisationContext } from "../../../context/organisationContext";

import ArrowRight from '../../../assets/images/menu/arrow-right-next.svg';

import './style.scss';

const NavTop = ({names, edit = true, onSave, savePermission, done = false, onDone = () => {}}) => {
    const navigate = useNavigate();
    const { checkPermission, hasPermission } = useContext(OrganisationContext);

    return (
        <div className="flex items-center justify-between ">
            <div className="flex items-center">
            {
                names.map((name, i) => (
                <div key={i} className="flex items-center">
                    <label className={`${i < names.length - 1 ? 'regular-text grey-text' : 'bold-text'} cursor`} onClick={() => name.onClick ? name.onClick() :  navigate(name.path)}>{name.text}</label>
                    {i < names.length - 1 && <img src={ArrowRight} className="mx-2 nav-arrow" /> }
                </div>
                ))
            }
            </div>
            {edit &&
                <div className='nav-top-actions '>
                    <button onClick={() => navigate(-1)}>Cancel</button>
                    <button 
                        className='save-action' 
                        style={{opacity: hasPermission(savePermission) ? 1 : 0.5}}
                        onClick={() => checkPermission(() => onSave(), savePermission)}
                        >
                            Save
                        </button>
                </div>
            }
            {done &&
                <div className='nav-top-actions '>
                    <button className='save-action' onClick={() => onDone()}>Done</button>
                </div>
            }
        </div>
    )
}

export default NavTop;
