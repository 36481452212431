import React, { useState } from "react";
import Header from "../../header";
import IconInput from "../../widget/IconInput";

import TwitterIcon from "../../../assets/images/input/twitter-input-icon.svg";
import FbIcon from "../../../assets/images/input/fb-input-icon.svg";
import DiscordIcon from "../../../assets/images/input/discord-input-icon.svg";
import TelegramIcon from "../../../assets/images/input/telegram-input-icon.svg";
import MediumIcon from "../../../assets/images/input/medium-input-icon.svg";
import GithubIcon from "../../../assets/images/input/github-input-icon.svg";
import YoutubeIcon from "../../../assets/images/input/youtube-input-icon.svg";

import TwitterActiveIcon from "../../../assets/images/input/twitter-input-icon-green.svg";
import FbActiveIcon from "../../../assets/images/input/fb-input-icon-green.svg";
import DiscordActiveIcon from "../../../assets/images/input/discord-input-icon-green.svg";
import TelegramActiveIcon from "../../../assets/images/input/telegram-input-icon-green.svg";
import MediumActiveIcon from "../../../assets/images/input/medium-input-icon-green.svg";
import GithubActiveIcon from "../../../assets/images/input/github-input-icon-green.svg";
import YoutubeActiveIcon from "../../../assets/images/input/youtube-input-icon-green.svg";

import "./style.scss";
import { useDispatch } from "react-redux";
import { setCollection } from "../../../store/features/collectionSlice";

const CollectionMainDetails = ({ collection, menuItems, onMenuItemClick, onSaveDetails = () => {} }) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  const onSave = () => {
    onSaveDetails();
    setEdit(false);
  };

  return (
    <div className="flex-column flex-1 pb-12">
      <Header
        title={"More details"}
        isSearch={false}
        isFilter={false}
        isPaged={false}
        size="small"
        menuItems={menuItems}
        onMenuItemClick={onMenuItemClick}
        isAddBtn={false}
        isEditBtn={true}
        onSave={onSave}
        onCancelEdit={() => setEdit(false)}
        onEdit={() => setEdit(true)}
        edit={edit}
      />
      {edit ? (
        <div className="flex-column mx-6 mt-4 collection-main-details">
          <div className="flex">
            <div className="flex-column flex-1 mr-6">
              <label className="input-label">Description</label>
              <textarea
                className="textarea"
                value={collection.description}
                placeholder="Enter description"
                onChange={(e) =>
                  dispatch(
                    setCollection({
                      ...collection,
                      description: e.target.value,
                    })
                  )
                }
              />
              <label className="input-label mt-6">Long description</label>
              <textarea
                className="textarea textarea-large"
                value={collection.long_description}
                placeholder="Enter long description"
                onChange={(e) =>
                  dispatch(
                    setCollection({
                      ...collection,
                      long_description: e.target.value,
                    })
                  )
                }
              />
              <label className="input-label mt-6">Website URL</label>
              <input
                className="input"
                value={collection.website_url}
                placeholder="Enter website url"
                onChange={(e) =>
                  dispatch(
                    setCollection({
                      ...collection,
                      website_url: e.target.value,
                    })
                  )
                }
              />
              <label className="input-label mt-6">Taxon</label>
              <input
                className="input"
                value={collection.taxon}
                placeholder="Enter taxon"
                onChange={(e) =>
                  dispatch(
                    setCollection({
                      ...collection,
                      taxon: e.target.value,
                    })
                  )
                }
              />
            </div>
          </div>

          <label className="bold-text font-16 mt-12">Social media</label>
          <div className="flex mt-6">
            <div className="flex-column flex-1 mr-6">
              <label className="input-label">Twitter</label>
              <IconInput
                activeIcon={TwitterActiveIcon}
                inactiveIcon={TwitterIcon}
                value={collection?.socials?.twitter_url || ""}
                placeholder="https://twitter.com/@username"
                onChange={(e) =>
                  dispatch(
                    setCollection({
                      ...collection,
                      socials: {
                        ...collection.socials,
                        twitter_url: e.target.value,
                      },
                    })
                  )
                }
              />
              <label className="input-label mt-6">Facebook</label>
              <IconInput
                activeIcon={FbActiveIcon}
                inactiveIcon={FbIcon}
                value={collection?.socials?.facebook_url || ""}
                onChange={(e) =>
                  dispatch(
                    setCollection({
                      ...collection,
                      socials: {
                        ...collection.socials,
                        facebook_url: e.target.value,
                      },
                    })
                  )
                }
                placeholder="https://facebook.com/@username"
              />
              <label className="input-label mt-6">Discord</label>
              <IconInput
                activeIcon={DiscordActiveIcon}
                inactiveIcon={DiscordIcon}
                value={collection?.socials?.discord_url || ""}
                onChange={(e) =>
                  dispatch(
                    setCollection({
                      ...collection,
                      socials: {
                        ...collection.socials,
                        discord_url: e.target.value,
                      },
                    })
                  )
                }
                placeholder="https://discord.gg/invite/channel"
              />
              <label className="input-label mt-6">Telegram</label>
              <IconInput
                activeIcon={TelegramActiveIcon}
                inactiveIcon={TelegramIcon}
                value={collection?.socials?.telegram_url || ""}
                onChange={(e) =>
                  dispatch(
                    setCollection({
                      ...collection,
                      socials: {
                        ...collection.socials,
                        telegram_url: e.target.value,
                      },
                    })
                  )
                }
                placeholder="https://t.me/username"
              />
            </div>
            <div className="flex-column flex-1">
              <label className="input-label">Medium</label>
              <IconInput
                activeIcon={MediumActiveIcon}
                inactiveIcon={MediumIcon}
                value={collection?.socials?.medium_url || ""}
                onChange={(e) =>
                  dispatch(
                    setCollection({
                      ...collection,
                      socials: {
                        ...collection.socials,
                        medium_url: e.target.value,
                      },
                    })
                  )
                }
                placeholder="https://medium.com/@username"
              />
              <label className="input-label mt-6">GitHub</label>
              <IconInput
                activeIcon={GithubActiveIcon}
                inactiveIcon={GithubIcon}
                value={collection?.socials?.github_url || ""}
                onChange={(e) =>
                  dispatch(
                    setCollection({
                      ...collection,
                      socials: {
                        ...collection.socials,
                        github_url: e.target.value,
                      },
                    })
                  )
                }
                placeholder="https://github.com/username"
              />
              <label className="input-label mt-6">YouTube</label>
              <IconInput
                activeIcon={YoutubeActiveIcon}
                inactiveIcon={YoutubeIcon}
                value={collection?.socials?.youtube_url || ""}
                onChange={(e) =>
                  dispatch(
                    setCollection({
                      ...collection,
                      socials: {
                        ...collection.socials,
                        youtube_url: e.target.value,
                      },
                    })
                  )
                }
                placeholder="https://youtube.com/channel/channel"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-column mx-6 mt-4 collection-main-details">
            <div className="flex">
              <div className="flex-column flex-1 mr-6">
                <label className="input-label font-12">Description</label>
                <p className="regular-text font-16">{collection.description || '-'}</p>
                <label className="input-label font-12 mt-6">Long description</label>
                <p className="regular-text font-16">{collection.long_description || '-'}</p>
                <label className="input-label font-12 mt-6">Website URL</label>
                <p className="regular-text font-16">{collection.website_url || '-'}</p>
                <label className="input-label font-12 mt-6">Taxon</label>
                <p className="regular-text font-16">{collection.taxon || '-'}</p>
              </div>
            </div>

            <label className="bold-text font-16 mt-12">Social media</label>
            <div className="flex mt-6">
              <div className="flex-column flex-1 mr-6">
                <label className="input-label">Twitter</label>
                <div className="flex">
                  <img src={collection?.socials?.twitter_url ? TwitterActiveIcon : TwitterIcon} alt="icon" />
                  <p className={`regular-text ml-2 font-16 ${!collection.socials?.twitter_url && 'light-purple-text'}`}>
                    {collection.socials?.twitter_url ||
                      "https://twitter.com/@username"}
                  </p>
                </div>
                <label className="input-label mt-6">Facebook</label>
                <div className="flex">
                  <img src={collection?.socials?.facebook_url ? FbActiveIcon : FbIcon} alt="icon" />
                  <p className={`regular-text ml-2 font-16 ${!collection.socials?.facebook_url && 'light-purple-text'}`}>
                    {collection.socials?.facebook_url ||
                      "https://facebook.com/@username"}
                  </p>
                </div>
                <label className="input-label mt-6">Discord</label>
                <div className="flex">
                  <img src={collection?.socials?.discord_url ? DiscordActiveIcon : DiscordIcon} alt="icon" />
                  <p className={`regular-text ml-2 font-16 ${!collection.socials?.discord_url && 'light-purple-text'}`}>
                    {collection.socials?.discord_url ||
                      "https://discord.gg/invite/channel"}
                  </p>
                </div>
                <label className="input-label mt-6">Telegram</label>
                <div className="flex">
                  <img src={collection?.socials?.telegram_url ? TelegramActiveIcon : TelegramIcon} alt="icon" />
                  <p className={`regular-text ml-2 font-16 ${!collection.socials?.telegram_url && 'light-purple-text'}`}>
                    {collection.socials?.telegram_url ||
                      "https://t.me/username"}
                  </p>
                </div>
              </div>
              <div className="flex-column flex-1">
                <label className="input-label">Medium</label>
                <div className="flex">
                  <img src={collection?.socials?.medium_url ? MediumActiveIcon : MediumIcon} alt="icon" />
                  <p className={`regular-text ml-2 font-16 ${!collection.socials?.medium_url && 'light-purple-text'}`}>
                    {collection.socials?.medium_url ||
                      "https://medium.com/@username"}
                  </p>
                </div>
                <label className="input-label mt-6">GitHub</label>
                <div className="flex">
                  <img src={collection?.socials?.github_url ? GithubActiveIcon : GithubIcon} alt="icon" />
                  <p className={`regular-text ml-2 font-16 ${!collection.socials?.github_url && 'light-purple-text'}`}>
                    {collection.socials?.github_url ||
                      "https://github.com/username"}
                  </p>
                </div>
                <label className="input-label mt-6">YouTube</label>
                <div className="flex">
                  <img src={collection?.socials?.youtube_url ? YoutubeActiveIcon : YoutubeIcon} alt="icon" />
                  <p className={`regular-text ml-2 font-16 ${!collection.socials?.youtube_url && 'light-purple-text'}`}>
                    {collection.socials?.youtube_url ||
                      "https://youtube.com/channel/channel"}
                  </p>
                </div>
              </div>
            </div>
        </div>
      )}
    </div>
  );
};

export default CollectionMainDetails;
