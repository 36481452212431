import React, { useContext, useEffect, useRef, useState } from 'react';
import { TeamDetailsWrapper } from './teamDetails.styled';
import TeamDetailsUser from './teamDetailsUser';
import AsyncSelect from 'react-select/async';
import api from '../../../store/services'
import { ReactComponent as DotsIcon } from '../../../assets/images/horizontal-dots.svg';
import { ReactComponent as UsersIcon } from '../../../assets/images/users.svg';
import Menu from './menu';
import { OrganisationContext } from '../../../context/organisationContext';
import { ReactComponent as InviteIcon } from '../../../assets/images/invite-icon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/search-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-image.svg';
import { ReactComponent as AlertWarningIcon } from '../../../assets/images/alert-warning.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../../assets/images/loading.svg';
import useDebounce from '../../../helpers/useDebounceHook';
import Button from '../../Elements/button/button';
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../store/features/toastSlice';
import { setTeams, setSelectedTeam } from '../../../store/features/settingsSlice';
import Selected from '../../../assets/images/selected.svg';

import uuid from 'react-uuid';
import { Tooltip } from '../../tooltip/tooltip';

const MultiValueOption = props => {
  const onSelect = () => {
    const currentSelected = props.getValue();
    const newSelected = props.isSelected
      ? currentSelected.filter(opt => opt.value !== props.value)
      : [...currentSelected, { ...props.data }];
    props.setValue(newSelected);
  };
  return (
    <div className={`tpus-user ${props.isSelected && 'tpus-user-selected'}`} onClick={() => onSelect()}>
      <label className={`regular-text ${props.isSelected && 'bold-text'}`}>{props.label}</label>
      {props.isSelected && <img alt="icon" src={Selected} />}
    </div>
  );
};

const TeamDetails = ({
  onEditUser,
  changeDeleteUser,
  setSelectedUser,
  selectedTeam,
  onInviteUser,
  selectTeam,
  fetchData,
  accountOrganisationId,
}) => {
  const { setModal, modal, hasPermission, checkPermission } = useContext(OrganisationContext);
  const textareaRef = useRef(null);
  const { tenant } = selectedTeam;

  const [services, setServices] = useState([]);
  const [inputState, setInputState] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [focused, setFocused] = useState(false);
  const [selectedTeamUser, setSelectedTeamUsers] = useState([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(false);

  const debouncedSearchText = useDebounce(searchText, 500);

  const dispatch = useDispatch();
  const { teams } = useSelector(state => state.settings);
  const { user } = useSelector(state => state.user);

  const organisationId = user?.organisations[0].external_id;

  const [leftLoading, setLeftLoading] = useState(false);
  const [team, setTeam] = useState({});
  const [editTeam, setEditTeam] = useState({});
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedValueEdit, setSelectedValueEdit] = useState([]);
  const [error, setError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const isSameTenant = () => {
    return tenant?.id === accountOrganisationId;
  };

  const fetchTeamsMemberData = async (loading, page, merge) => {
    setLoading(loading);
    const { data } = await api.get(
      `/v1/organizations/${user.organisations[0].external_id}/teams/${selectedTeam.id}/users?page=${page}&size=20&name=${debouncedSearchText}`,
    );
    setSelectedPage(page);
    if (merge) {
      setSelectedTeamUsers([...selectedTeamUser, ...data]);
    } else {
      setSelectedTeamUsers([...data]);
    }
    setHasMoreData(false);

    if (!debouncedSearchText) {
      setTotalMembers(data.total_elements);
    }
    setLoading(false);
  };


  const fetchMoreData = async () => {
    await fetchTeamsMemberData(false, selectedPage + 1, true);
  };

  useEffect(() => {
    if (
      modal.type === 'add-team-member-success' ||
      modal.type === 'delete-user-success' ||
      modal.type === 'invite-user-success' ||
      modal.type === 're-invite-user-success'
    ) {
      if (selectedTeam.id) fetchTeamsMemberData(false, 0, false);
      fetchData(false);
    }
  }, [modal.type]);

  useEffect(() => {
    if (selectedTeam?.id) {
      fetchTeamsMemberData(true, 0, false);
    } else {
      setSearchText('');
    }
  }, [selectedTeam?.id, debouncedSearchText]);

  useEffect(() => {
    if (isEdit) {
      setEditTeam({ ...team });
      setSelectedValueEdit([...selectedValue]);
    }
  }, [isEdit]);

  const changeUserRole = async (user, level) => {
    const request = { level };
    await api.put(`/v1/organisations/${selectedTeam.id}/teams/users/${user.id}`, request);
    setSelectedTeamUsers(
      selectedTeamUser.map(u =>
        u.id === user.id
          ? { ...u, organisations: u.organisations.map(o => (o.id === selectedTeam.id ? { ...o, level: level } : o)) }
          : u,
      ),
    );
  };

  const changeActive = async user => {
    const names = user.name?.split(' ') || [];
    const request = { is_active: !user.is_active, forename: names[0] || '', surname: names[1] || '' };
    await api.put(`/v1/api/adminusers/${user.id}`, request);
    setSelectedTeamUsers(selectedTeamUser.map(u => (u.id === user.id ? { ...u, is_active: !user.is_active } : u)));
  };

  const menuClick = action => {
    setShowMenu(false);
    switch (action) {
      case 'EDIT':
        setIsEdit(true);
        break;
      case 'DELETE':
        setModal({ type: 'delete-team', content: { team: team, top: true } });
        selectTeam({});
        break;
      default:
        break;
    }
  };

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowMenu(false);
    }
  };

  const handleBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFocused(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await api.get(`/v1/organizations/${organisationId}/services`);
      setServices(data.map(d => ({ ...d, label: d.name, value: d.id })));
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedTeam) {
      setTeam({ ...selectedTeam });
      setSelectedValue(selectedTeam.services?.map(s => ({ ...s, label: s.name, value: s.id })) || []);
    }
  }, [selectedTeam]);

  const getOptions = async inputValue => {
    return services.filter(o => o.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const loadOptions = async inputValue => {
    const options = await getOptions(inputValue);
    return options;
  };

  const handleInputChange = newValue => {
    setInputState(newValue);
    return newValue;
  };

  const handleChange = value => {
    setSelectedValueEdit(value);
  };

  const onDone = async () => {
    try {
      if (!editTeam?.name?.trim()) {
        setError(true);
        dispatch(addToast({ error: true, text: 'Please enter team name' }));
        return;
      }
      const request = {
        name: editTeam.name,
        description: editTeam.description,
      };
      setLeftLoading(true);
      const serviceRequest = selectedValueEdit.map(s => ({ service: { id: s.value }, daily_capacity: 0, slots: 0 }));
      const { data } = await api.put(`/v1/organizations/${organisationId}/teams/${team.id}`, request);
      const { data: serviceData } = await api.put(`/v1/organizations/${team.id}/services`, serviceRequest);
      const updatedTeamServices = serviceData.map(s => ({ ...s.service }));

      const newTeamsData = teams
        ? teams.map(t => (t.id === data[0].id ? { ...data[0], services: [...updatedTeamServices] } : { ...t }))
        : [{ ...data, services: [...updatedTeamServices] }];
      dispatch(setTeams(newTeamsData));
      dispatch(setSelectedTeam(editTeam));
      localStorage.setItem('selected-team', JSON.stringify({ ...editTeam, services: [...selectedValueEdit] }));

      dispatch(
        addToast({
          error: false,
          text: 'Team saved successfully',
          id: uuid(),
        }),
      );
      setSelectedValue([...selectedValueEdit]);
      setTeam({ ...editTeam });
      setIsEdit(false);
      setLeftLoading(false);
    } catch (e) {
      console.log(e);
      dispatch(
        addToast({
          error: true,
          text: 'Error while saving team',
          id: uuid(),
        }),
      );
      setLeftLoading(false);
    }
  };

  return (
    <TeamDetailsWrapper className="flex">
      <div className="ml-1 team-details-left">
        <div className="justify-between title-m">
          <div>
            <label className="medium-text title-text">Team details</label>
          </div>
          <div className="flex ml-1 items-center" tabIndex={0}>
            <div className="flex t-users">
              <UsersIcon />
              <label className="regular-text font-14 ml-1">{totalMembers}</label>
            </div>
            {isSameTenant() && (
              <div className="relative" onBlur={onBlur} tabIndex={0}>
                <div
                  className="menu-btn cursor flex items-center relative px-2 ml-4 menu-hover py-2"
                  onClick={e => {
                    e.stopPropagation();
                    setShowMenu(!showMenu);
                  }}>
                  <DotsIcon />
                </div>
                {showMenu && (
                  <div>
                    <Menu
                      checkPermission={checkPermission}
                      clickItem={action => menuClick(action)}
                      hasPermission={hasPermission}
                      isTeamDetails={true}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <hr />
        {isEdit ? (
          <>
            <div className="flex-column input-m">
              <label className="regular-text field-label mb-2">Name</label>
              <input
                className={`popup-input form-input ${error && 'input-error'}`}
                onChange={e => {
                  setEditTeam({ ...editTeam, name: e.target.value });
                  setError(false);
                }}
                placeholder="Enter team name"
                value={editTeam.name || ''}
              />
            </div>
            <div className="note-input flex-column input-m">
              <label className="regular-text field-label mb-2">Description</label>
              <textarea
                ref={textareaRef}
                className="form-input medium-text grey-text input-w popup-input"
                onChange={e => setEditTeam({ ...editTeam, description: e.target.value })}
                placeholder="Enter team description"
                value={editTeam.description || ''}
              />
            </div>
            <div className="mb-6 flex-column input-m">
              <label className="regular-text field-label mb-2">Service</label>
              <AsyncSelect
                className={`options-select-container select-hover input-w ${dropdownOpened ? 'opened' : ''}`}
                classNamePrefix="select"
                closeMenuOnSelect={false}
                components={{ Option: MultiValueOption }}
                defaultOptions={services}
                inputValue={inputState}
                isMulti
                loadOptions={loadOptions}
                onChange={handleChange}
                onInputChange={handleInputChange}
                onMenuClose={() => setDropdownOpened(false)}
                onMenuOpen={() => setDropdownOpened(true)}
                options={services}
                placeholder="Select services"
                value={selectedValueEdit}
              />
            </div>
            <div className="flex my-8 mx-6 action-container">
              <Button
                className={`${!leftLoading && 'secondary-hover'}`}
                disabled={leftLoading}
                label={'Cancel'}
                onClick={() => {
                  setIsEdit(false);
                }}
                secondary={true}
                size="medium"
                style={{ flex: 1 }}
              />
              <Button
                className={`${!leftLoading && 'primary-hover'}`}
                disabled={leftLoading}
                label={'Save'}
                onClick={onDone}
                primary={true}
                size="medium"
                style={{ flex: 1 }}
              />
            </div>
          </>
        ) : (
          <div className="flex-column ml-6 mr-6">
            <label className="regular-text grey-text font-12">Name</label>
            <div className="flex mt-1">
              <label className="bold-text font-16 flex-1">{team?.name}</label>
              {!isSameTenant() && (
                <div className="flex items-center justify-center">
                  <label className="medium-text grey-text font-10 mr-1">SHARED</label>
                  <div className="shared-tooltip">
                    <Tooltip
                      className="shared-tooltip-details"
                      content={<span className="regular-text font-12">Shared team cannot be modified</span>}
                      placement="top">
                      <AlertWarningIcon height={16} width={16} />
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
            <label className="regular-text grey-text font-12 mt-6">Description</label>
            {team?.description ? (
              <label className="regular-text font-14 mt-1">{team?.description}</label>
            ) : (
              <label className="regular-text grey-text font-14 mt-1">No description</label>
            )}
            <label className="regular-text grey-text font-12 mt-6">Services:</label>
            <div className="flex wrap mb-6">
              {selectedValue.length > 0 ? (
                selectedValue?.map(service => (
                  <div className="mt-2 mr-2">
                    <label className="service regular-text font-14">{service.label}</label>
                  </div>
                ))
              ) : (
                <label className="regular-text grey-text font-14 mt-2">No service available</label>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="w-full ml-6">
        <div className="team-user">
          <div className="justify-between">
            <label className="bold-text font-20 ml-6 mt-5">{`Team's members`}</label>
            <div className="flex">
              {isSameTenant() && (
                <div
                  className="add-team-member mt-4 mb-2"
                  onClick={() =>
                    setModal({
                      type: 'add-team-member',
                      content: { selectedTeam: team, organisationId: team?.id, top: true },
                    })
                  }>
                  <InviteIcon />
                  <p className="medium-text font-14 ml-2">Add new</p>
                </div>
              )}
              <div
                className={`properties-search mt-4 mb-4 ${focused ? 'search-focused' : ''}`}
                onBlur={e => handleBlur(e)}
                onFocus={() => setFocused(true)}>
                <SearchIcon className="search-icon" />
                <input
                  className="regular-text"
                  onChange={e => setSearchText(e.target.value)}
                  placeholder="Search"
                  value={searchText || ''}
                />
                {focused && (
                  <div
                    className="close"
                    onClick={e => {
                      setSearchText('');
                    }}
                    tabIndex="0">
                    <CloseIcon className="close-icon" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr className="hr-line" />
          {loading ? (
            <div className={`flex items-center justify-center h-full py-6`}>
              <img alt="loading" height="48px" src={Loading} width="48px" />
            </div>
          ) : (
            <div className="scroll-wrapper" id="scrollableDiv">
              <InfiniteScroll
                dataLength={selectedTeamUser.length}
                hasMore={hasMoreData}
                loader={
                  <div className="item flex items-center justify-center">
                    <img alt="loading" height="32px" src={Loading} />
                  </div>
                }
                next={fetchMoreData}
                scrollableTarget="scrollableDiv"
                style={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'unset' }}>
                {selectedTeamUser?.map((user, index) => (
                  <>
                    <TeamDetailsUser
                      changeActive={user => changeActive(user)}
                      changeDeleteUser={changeDeleteUser}
                      changeUserRole={changeUserRole}
                      checkPermission={checkPermission}
                      hasPermission={hasPermission}
                      index={index}
                      isSameTenant={isSameTenant}
                      onEditUser={user => onEditUser(user)}
                      onInviteUser={onInviteUser}
                      selectedTeam={selectedTeam}
                      setSelectedUser={setSelectedUser}
                      user={user}
                    />
                  </>
                ))}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
    </TeamDetailsWrapper>
  );
};

export default TeamDetails;
