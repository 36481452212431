import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import Image from '../components/image';
import Button from '../components/Elements/button/button';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { addToast } from '../store/features/toastSlice';
import { DeleteMediaWrapper } from './modal.styled.js';
import { CSSTransition } from 'react-transition-group';
import {  deleteMedia } from "../store/features/templateSlice";
import { useNavigate } from 'react-router-dom';

const DeleteMedia = () => {
  const navigate = useNavigate();
  const { modal, setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const media = modal.content.media;

  const onDelete = async () => {
    if (loading) return;
    setLoading(true);
    try {
      dispatch(deleteMedia({templateId: media.templateId, mediaId: media.media_external_id}))
      dispatch(
        addToast({
          error: false,
          title: 'Project removed',
          text: `Media has been removed`,
          id: uuid(),
        }),
      );
    } catch (error) {
      console.log(error);
      dispatch(
        addToast({
          error: true,
          text: `Failed to delete media`,
          id: uuid(),
        }),
      );
    }
  
    setLoading(false);
    setModal(initModal);
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <DeleteMediaWrapper>
        <p className="bold-text font-24 text-center">
          Are you sure you want to remove this media?
        </p>
        <div className="flex-column user-details-container relative" style={{ marginTop: 32 }}>
        <img 
            alt="" 
            className="template_image"
            sizes="200px"
            srcSet={media.media?.map(m => `${m.url} ${m.width}w`).join(', ')}
        />        
        </div>
        <div className="flex justify-center mt-12">
          <Button
            className="secondary-hover"
            label="Cancel"
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            style={{ flex: 1, borderRadius: '16px' }}
            bgColor="#FFFFFF"
            borderColor='#24CBB1'
            color='#24CBB1'
          />
          <Button
            label='Remove'
            onClick={onDelete}
            size="large"
            style={{ flex: 1, marginLeft: 24, borderRadius: '16px' }}
            bgColor="#FB3535"
            borderColor='#FB3535'
            color='#FFFFFF'
          />
        </div>
      </DeleteMediaWrapper>
    </CSSTransition>
  );
};

export default DeleteMedia;
