import React, { useEffect, useRef } from 'react';

import "../../pages/projectDetails/details.scss";

const ProductInfo = ({product}) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        textareaRef.current.style.height = '0px';
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + 5 + 'px';
    }, [product?.description]);

    return (
        <div className="flex-column card pxy-6">
            <img src={product?.creatives.find(c => c.resource_type !== 'video')?.url} className="product-info-image" alt="project" />
            <label className="input-label mt-6">Name</label>
            <input className="input" value={product?.name} />
            <label className="input-label mt-6">Description</label>
            <textarea className="textarea" value={product?.description} ref={textareaRef} />
            <label className="input-label mt-6">SKU</label>
            <input className="input" value={product?.sku} />
        </div>
    )
}

export default ProductInfo;
