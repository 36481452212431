import { createSlice } from "@reduxjs/toolkit";
import api from '../services'
import moment from "moment";
import { addToast } from "./toastSlice";
import uuid from "react-uuid";

export const reportsSlice = createSlice({
    name: "reports",
    initialState: {
      ownerPerformance: [],
      retirementReport: [],
      inventoryReport: []
    },
    reducers: {
      setOwnerPerformance: (state, { payload }) => {
        state.ownerPerformance = payload;
      },
      setRetirementReport: (state, { payload }) => {
        state.retirementReport = payload;
      },
      setInventoryReport: (state, { payload }) => {
        state.inventoryReport = payload;
      },
    }
  });


export const getOwnerPerformance = (payload) => async (dispatch) => {
  let url = `/v3/api/backoffice/analytics/owner_performance`;
  if(payload?.startDate)
    url += `?start_date=${payload.startDate}`
  if(payload?.endDate)
    url += `&end_date=${payload.endDate}`
  if(payload.organizationId)
    url += `&organization_id=${payload.organizationId}`

  const { data } = await api.get(url)
  dispatch(setOwnerPerformance(data));
};

export const getRetirementReport = (payload) => async (dispatch) => {
  let url = `/v3/api/backoffice/analytics/retirement_report`;
  if(payload?.startDate)
    url += `?start_date=${payload.startDate}`
  if(payload?.endDate)  
    url += `&end_date=${payload.endDate}`
  if(payload.organizationId)
    url += `&organization_id=${payload.organizationId}`
  const { data } = await api.get(url)
  dispatch(setRetirementReport(data));
};

export const getInventoryReport = (payload) => async (dispatch) => {
  let url = `/v3/api/backoffice/analytics/inventory_report`;
  if(payload?.startDate)
    url += `?start_date=${payload.startDate}`
  if(payload?.endDate)  
    url += `&end_date=${payload.endDate}`
  if(payload.organizationId)
    url += `&organization_id=${payload.organizationId}`
  const { data } = await api.get(url)
  dispatch(setInventoryReport(data));
};

export const exportReport = (payload) => async (dispatch) => {
  let url = `/v3/api/backoffice/analytics//carbon/export?type=${payload.type}`;
  if(payload?.startDate)
    url += `&start_date=${payload.startDate}`
  if(payload?.endDate)  
    url += `&end_date=${payload.endDate}`
  if(payload.organizationId)
    url += `&organization_id=${payload.organizationId}`
  const { data } = await api.post(url)
 
  const linkUrl = window.URL.createObjectURL(new Blob([data])) 
  const link = document.createElement('a')
  link.href = linkUrl
  const fileName = `Report ${moment(new Date()).format("DD MMM YY")}.csv`;
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()

  dispatch(
    addToast({
      error: false,
      title: "Report downloaded",
      text: `Report has been downloaded`,
      id: uuid(),
    })
  );
};


export const { setOwnerPerformance, setRetirementReport, setInventoryReport } = reportsSlice.actions;
export default reportsSlice.reducer;