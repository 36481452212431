import React from 'react';
import Button from '../components/Elements/button/button';
import { CancelAutomationLogWrapper } from './modal.styled';
import { CSSTransition } from 'react-transition-group';
import TimeIcon from '../assets/images/time.svg';
import { useContext, useState } from 'react';
import { OrganisationContext } from '../context/organisationContext';
import { initModal } from '../constant/InitialData';
import { useDispatch } from 'react-redux';
import { addToast } from '../store/features/toastSlice';
import uuid from 'react-uuid';
import api from "../store/services";
import { capitalize, formatText } from "../helpers/utils";

const CancelLog = () => {
  const { modal, setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { content, index, onSuccess } = modal;
  const { action: actionContent } = content;
  const { action } = actionContent;

  const onPerformAction = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const request = {
        status: 'CANCELLED',
      };
      await api.post(`/v3/api/action_log/${actionContent.id}/cancel`, request);
      dispatch(
        addToast({
          error: false,
          text: `Automation log has been cancelled successfully`,
          id: uuid(),
        }),
      );
      onSuccess();
      setModal(initModal);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: 'Failed to cancel automation',
          id: uuid(),
        }),
      );
      setModal(initModal);
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <CancelAutomationLogWrapper>
        <p className="bold-text font-24 text-center">{'Are you sure you want to cancel this action?'}</p>
        {action ? (
          <>
            <div className="mt-8 integration-details-container">
              <div className="flex justify-between items-center action-name mb-2">
                <div className="flex integration-name-container">
                  <label className="medium-text font-16">ACTION {index}</label>
                </div>
              </div>
              <hr />
              <div className="content-padding">
                <div className="mb-4 flex">
                  <div className="flex mr-1">
                    {action?.action_type?.icon && (
                      <img
                        // alt="icon"
                        src={`${action?.action_type?.icon?.active}`}
                        style={{ width: 24, height: 24 }}
                      />
                    )}
                  </div>
                  <div className="flex-column">
                    <label className="medium-text font-12 mt-1">{action?.action_type?.name}</label>
                    <label className="flex regular-text grey-text font-12">{action?.action_type?.description}</label>
                  </div>
                </div>

                <hr />
                <div className="mb-4 mt-2">
                  <div>
                    <div className="flex items-center mb-1">
                      <img
                        className="mr-2"
                        src={`${action?.integration?.connector?.icon?.active}`}
                        style={{ width: 25, height: 25 }}
                      />
                      <div className="integration-text bold-text regular-text">
                        {action?.integration?.connector?.name}
                      </div>
                    </div>
                  </div>
                  <div>
                    {action?.params?.length > 0 &&
                      action?.params.map(data => {
                        return (
                          <div className="flex mb-2">
                            <div key={data.id} className="flex">
                              <label className="regular-text grey-text">
                                {capitalize(formatText(data.param_type, ' '))}: &nbsp;
                              </label>
                              <label className="flex regular-text" style={{ wordBreak: 'break-all' }}>
                                {data.param_value || data.param_value}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                {action?.schedule?.offset !== null && action?.schedule?.unit && action?.schedule?.field && (
                  <>
                    <hr />
                    <div className="flex mt-4 mb-2">
                      <img className="mx-2" src={TimeIcon} />
                      <label className="regular-text">{`${Math.abs(action?.schedule?.offset)}
                      ${formatText(action?.schedule?.unit, ' ')}
                      ${action?.schedule?.offset < 0 ? 'before' : 'after'}
                      ${action?.schedule?.field.toLowerCase().replaceAll('.', ' ')}`}</label>
                    </div>
                  </>
                )}
                {action?.conditions.length > 0 && (
                  <>
                    <hr />
                    {action?.conditions.map((condition, index) => {
                      return (
                        <div className="mt-2 mb-2">
                          <label className="flex regular-text">
                            {index == 0 ? 'When' : 'And'} {condition?.field} =
                          </label>
                          <label className="flex medium-text">{condition?.value}</label>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center no-action-container mt-4">
            <label className="regular-text grey-text font-20">No action available</label>
          </div>
        )}

        <div className="flex justify-center mt-12">
          <Button
            className={`${!loading && 'secondary-hover'}`}
            disabled={loading}
            label="Keep"
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            style={{ flex: 1 }}
          />
          <Button
            disabled={loading}
            label="Cancel"
            onClick={onPerformAction}
            red={true}
            size="large"
            style={{ flex: 1, marginLeft: 24 }}
          />
        </div>
      </CancelAutomationLogWrapper>
    </CSSTransition>
  );
};

export default CancelLog;
