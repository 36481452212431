import ArrowLeft from '../../assets/images/menu/arrow-left.svg';
import ArrowRight from '../../assets/images/menu/arrow-right-next.svg';
import React, { useEffect, useState } from 'react';
import PaginationWrapper from './pagination.styled';

let timeoutScroll = null;
const Pagination = ({ totalPages, selectedPage, setSelectedPage, height }) => {
  const [open, setOpen] = useState(false);
  const [opening, setOpening] = useState(false);
  const [scrolled, setScrolled] = useState(0);
  const [previousPage, setPreviousPage] = useState(selectedPage);

  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const scroll = (element, scrollWith, duration) => {
    var start = element?.scrollLeft || 0,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = Math.easeInOutQuad(currentTime, start, scrollWith - start, duration);
      element.scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const goToPreviousPage = () => {
    setPreviousPage(selectedPage);
    if (selectedPage - 1 >= 0) {
      if (selectedPage + 1 > 5 / 2) scroll(document.getElementById('pages-scroll'), (selectedPage - 3) * 50, 400);
      setSelectedPage(selectedPage - 1);
    }
  };

  const goToNextPage = () => {
    setPreviousPage(selectedPage);
    if (selectedPage + 1 < totalPages) {
      if (selectedPage + 1 > 5 / 2) scroll(document.getElementById('pages-scroll'), (selectedPage - 1) * 50, 400);
      setSelectedPage(selectedPage + 1);
    }
  };

  useEffect(() => {
    if (selectedPage === previousPage) {
    } else if (selectedPage < previousPage || selectedPage === 0) {
      setScrolled(selectedPage * 50);
    } else if (selectedPage > previousPage || selectedPage === totalPages - 1) {
      setScrolled(selectedPage * 50);
    }
    setScrolled(selectedPage * 50);
  }, [selectedPage]);

  useEffect(() => {
    setScrolled(selectedPage * 50);
  }, []);

  const changeOpening = value => {
    if (totalPages > 1) setOpening(value);
  };

  const changeOpen = value => {
    if (totalPages > 3) setOpen(value);
  };

  useEffect(() => {
    if (totalPages > 0) {
      if (timeoutScroll) clearTimeout(timeoutScroll);
      if (!open && !opening) {
        timeoutScroll = setTimeout(() => scroll(document.getElementById('pages-scroll'), scrolled, 800), 500);
      } else {
        timeoutScroll = setTimeout(() => scroll(document.getElementById('pages-scroll'), scrolled - 100, 800), 500);
      }
    }
  }, [totalPages, open, opening, scrolled]);

  return (
    <PaginationWrapper onMouseEnter={() => changeOpening(true)} onMouseLeave={() => changeOpening(false)}>
      <div
        style={{height: height}}
        className={`pagination-content`}
        onMouseEnter={() => changeOpen(true)}
        onMouseLeave={() => changeOpen(false)}>
      
        <div className="nav-btn nav-btn-left relative" onClick={goToPreviousPage} style={{height: height}} >
          <img alt="icon" src={ArrowLeft} />
          <div className='right-shadow absolute' style={{height: height}} />
        </div>
        <div
          className="pages"
          id="pages-scroll"
          style={{
            width: open
              ? `${(totalPages > 8 ? 250 : totalPages * 50) + 4}px`
              : opening
              ? `${(totalPages > 3 ? 150 : totalPages * 50) + 4}px`
              : '52px',
          }}>
          {[...Array(totalPages).keys()].map(page => (
            <div
              key={page}
              className={`page ${page === selectedPage && 'selected-page'}`}
              onClick={() => setSelectedPage(page)}
              style={{height: height}}>
              <label className="bold-text">{page + 1}</label>
            </div>
          ))}
        </div>
        <div className="nav-btn nav-btn-right relative" onClick={goToNextPage} style={{height: height}}>
          <img alt="icon" src={ArrowRight} />
          <div className='left-shadow absolute' style={{height: height}} />
        </div>
      </div>
    </PaginationWrapper>
  );
};

export default Pagination;
