export const propertyOptions = [
  { name: 'House', value: 'HOUSE' },
  { name: 'Bungalow', value: 'BUNGALOW' },
  { name: 'Flat', value: 'FLAT' },
  { name: 'Maisonette', value: 'MAISONETTE' },
  { name: 'Park home', value: 'PARK_HOME' },
];

export const subTypeOptions = [
  { name: 'Detached', value: 'DETACHED' },
  { name: 'Semi-detached', value: 'SEMI_DETACHED' },
  { name: 'End terrace', value: 'END_TERRACE' },
  { name: 'Mid terrace', value: 'MID_TERRACE' },
  { name: 'Enclosed end terrace', value: 'ENCLOSED_END_TERRACE' },
  { name: 'Enclosed mid terrace', value: 'ENCLOSED_MID_TERRACE' },
];

export const orientationOptions = [
  { name: 'Left', value: 'LEFT' },
  { name: 'Right', value: 'RIGHT' },
];

export const tenureOptions = [
  { name: 'Private tenant', value: 'PRIVATE_TENANT' },
  { name: 'Private owner', value: 'PRIVATE_OWNER' },
  { name: 'Social housing tenant', value: 'SOCIAL_HOUSING_TENANT' },
];

export const ageBandOptions = [
  'Before 1900',
  '1900 - 1929',
  '1930 - 1949',
  '1950 - 1966',
  '1967 - 1975',
  '1976 - 1982',
  '1983 - 1990',
  '1991 - 1995',
  '1996 - 2002',
  '2003 - 2006',
  '2007 - 2011',
  '2012 Onwards',
];

export const ageBandEnumOptions = [
  {
    name: 'Before 1900',
    value: 'AGE_BEFORE1900',
  },
  {
    name: '1900 - 1929',
    value: 'AGE_1900',
  },
  {
    name: '1930 - 1949',
    value: 'AGE_1930',
  },
  {
    name: '1950 - 1966',
    value: 'AGE_1950',
  },
  {
    name: '1967 - 1975',
    value: 'AGE_1967',
  },
  {
    name: '1976 - 1982',
    value: 'AGE_1976',
  },
  {
    name: '1983 - 1990',
    value: 'AGE_1983',
  },
  {
    name: '1991 - 1995',
    value: 'AGE_1991',
  },
  {
    name: '1996 - 2002',
    value: 'AGE_1996',
  },
  {
    name: '2003 - 2006',
    value: 'AGE_2003',
  },
  {
    name: '2007 - 2011',
    value: 'AGE_2007',
  },
  {
    name: '2012 Onwards',
    value: 'AGE_ONWARDS2012',
  },
];

export const primaryHeatingOptions = [
  { name: 'Gas boiler', value: 'GAS_BOILER' },
  { name: 'Electric Boiler', value: 'ELECTRIC_BOILER' },
  { name: 'Electric storage heaters', value: 'ELECTRIC_STORAGE' },
  { name: 'Gas room heaters', value: 'GAS_ROOM_HEATER' },
  { name: 'Oil boiler', value: 'OIL_BOILER' },
  { name: 'LPG boiler', value: 'LPG_BOILER' },
  { name: 'Solid fuel room heaters', value: 'SOLIDFUEL_ROOM_HEATER' },
  { name: 'Electric room heaters', value: 'ELECTRIC_ROOM_HEATER' },
  { name: 'Soild fossil fuel boiler', value: 'FOSSIL_FUEL_BOILER' },
  { name: 'Other', value: 'OTHER' },
];

export const secondaryHeatingOption = [{ name: 'None', value: 'NONE' }, ...primaryHeatingOptions];

export const miniGasOptions = [
  { name: 'Yes', value: true },
  { name: 'No', value: false },
];

export const conservatoriesOptions = [
  { name: 'Yes', value: true },
  { name: 'No', value: false },
];

export const propertyconstructionsOptions = [
  { name: 'Conventional, not high-rise, not protected - cavity wall', value: 'CONVENTIONAL_CAVITY' },
  { name: 'Traditional, not protected - solid wall', value: 'TRADITIONAL_SOLID' },
  { name: 'System-built, not high-rise, not protected - solid wall', value: 'SYSTEM_SOLID' },
  { name: 'High rise, cavity wall', value: 'HIGHRISE_CAVITY' },
  { name: 'High rise, solid wall', value: 'HIGHRISE_SOLID' },
  { name: 'Protected cavity wall', value: 'PROTECTED_CAVITY' },
  { name: 'Protected solid wall', value: 'PROTECTED_SOLID' },
];

export const flatPositionOptions = [
  { name: 'Top', value: 'TOP' },
  { name: 'Middle', value: 'MIDDLE' },
  { name: 'Ground', value: 'GROUND' },
];

export const parkHomeSizeOptions = [
  { name: 'Single', value: 'SINGLE' },
  { name: 'Double', value: 'DOUBLE' },
];

export const cancelAppointmentOptions = [
  { name: 'Surveyor cancelled', value: 'SURVEYOR_CANCELLED' },
  { name: 'Customer cancelled', value: 'CUSTOMER_CANCELLED' },
  { name: 'Other', value: 'OTHER' },
];

export const leadFilterOptions = [
  { name: 'Lead Created Date', value: 'lead_created_date' },
  // { name: 'Appointment Created Date', value: 'appointment_created_date' },
  // { name: 'Appointment Date', value: 'appointment_date' },
];

export const filterOptions = [
  { name: 'Ascending', value: 'asc' },
  { name: 'Descending', value: 'desc' },
];

export const teamRelationOptions = [
  { name: 'Surveyor', value: 'SURVEYOR' },
  { name: 'Lead Generator', value: 'SALES_PARTNER' },
  { name: 'Retrofit Co-ordinator', value: 'RETROFIT_COORDINATOR' },
  { name: 'Installer', value: 'INSTALLER' },
  { name: 'Admin', value: 'ADMIN' },
];

export const integrationProviderOptions = [
  { name: 'General', value: 'GENERAL', subProviders: [{ name: 'Webhook', value: 'WEBHOOK' }] },
  {
    name: 'Communications',
    value: 'COMMUNICATIONS',
    subProviders: [
      { name: 'Twilio', value: 'TWILIO' },
      { name: 'Sendgrid', value: 'SENDGRID' },
    ],
  },
];

export const lifecycleFilterOptions = [
  { name: 'None', value: '' },
  { name: 'Lead Generated', value: 'LEAD_GENERATED' },
  { name: 'Survey Booked', value: 'SURVEY_BOOKED' },
  { name: 'Survey Success', value: 'SURVEY_SUCCESS' },
  { name: 'Survey Failed', value: 'SURVEY_FAILED' },
  { name: 'Survey Cancelled', value: 'SURVEY_CANCELLED' },
  { name: 'Survey Postponed', value: 'SURVEY_POSTPONED' },
  { name: 'Processing', value: 'PROCESSING' },
  { name: 'Install Ready', value: 'INSTALL_READY' },
  { name: 'Install Booked', value: 'INSTALL_BOOKED' },
  { name: 'Install Complete', value: 'INSTALL_COMPLETE' },
];

export const appointmentDateFilterMenuOptions = [
  { name: 'None', value: '' },
  { name: 'Last Month', value: 'last_month' },
  { name: 'Last Week', value: 'last_week' },
  { name: 'Yesterday', value: 'yesterday' },
  { name: 'Today', value: 'today' },
  { name: 'Tomorrow', value: 'tomorrow' },
  { name: 'Next Week', value: 'next_week' },
  { name: 'Next Month', value: 'next_month' },
];

export const appointmentStatusFilterOptions = [
  { name: 'None', value: '' },
  { name: 'Success', value: 'SUCCESS' },
  { name: 'Failed', value: 'FAILED' },
  { name: 'Cancelled', value: 'CANCELLED' },
  { name: 'Postponed', value: 'POSTPONED' },
];

export const surveyTypeFilterOptions = [
  { name: 'None', value: '' },
  { name: 'Cavity', value: 'cavity' },
  { name: 'Loft', value: 'loft' },
  { name: 'Room in roof', value: 'room_in_roof' },
  { name: 'EWI', value: 'ewi' },
  { name: 'IWI', value: 'iwi' },
  { name: 'Boiler', value: 'boiler' },
  { name: 'Electrical storage heater', value: 'electrical_storage_heater' },
  { name: 'Under floor heater', value: 'under_floor_heater' },
];

export const leadFilterMenuOptions = [
  { name: 'None', value: '' },
  // { name: 'Appointment date', value: 'appointment_date' },
  // { name: 'Appointment status', value: 'appointment_status', subOptions: appointmentStatusFilterOptions },
  // { name: 'Survey type', value: 'survey_type', subOptions: surveyTypeFilterOptions },
  // { name: 'Surveyor', value: 'surveyor' },
  { name: 'Lead generator', value: 'lead_gen' },
  { name: 'Lead create date', value: 'lead_created_date' },
  { name: 'Lifecycle', value: 'lifecycle' },
  { name: 'Project', value: 'project' },
  { name: 'Status', value: 'status' },
];

export const dashboardLeadFilterMenuOptions = [
  { name: 'None', value: '' },
  // { name: 'Surveyor', value: 'surveyor' },
  { name: 'Lead generator', value: 'lead_gen' },
  { name: 'Project', value: 'project' },
];

export const dashboardSalesFilterMenuOptions = [
  { name: 'None', value: '' },
  { name: 'Lead generator', value: 'lead_gen' },
  { name: 'Appointment status', value: 'appointment_status', subOptions: appointmentStatusFilterOptions },
  { name: 'Project', value: 'project' },
];

export const automationFilterMenuOptions = [
  { name: 'Workspace Owner', value: 'owner' },
  { name: 'Trigger', value: 'trigger' },
];

export const dashboardsFiltersOptions = [
  { name: 'Scheme', value: 'SCHEME' },
  { name: 'Project', value: 'PROJECT' },
  { name: 'Lead Gen', value: 'LEAD_GEN' },
  { name: 'Workspace Owner', value: 'OWNER_USER' },
];

export const WorkItemFilterMenuOptions = [
  { name: 'Project', value: 'project' },
  { name: 'Scheme', value: 'scheme' },
];

export const leadStatusOptions = [
  { id: 1, name: 'Active', value: 'ACTIVE' },
  { id: 2, name: 'Complete', value: 'COMPLETED' },
  { id: 3, name: 'Cancelled', value: 'CANCELLED' },
];
