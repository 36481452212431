import React, { useCallback, useEffect, useRef } from 'react';

import { useGoogleMaps } from 'react-hook-google-maps';
import { GOOGLE_API_KEY } from '../../config';

import "../../pages/projectDetails/details.scss";

const ProjectLocation = ({ project, changeProject, edit }) => {
    const prevMarkerRef = useRef();

    const { ref, map, google } = useGoogleMaps(
        GOOGLE_API_KEY,
        {
            zoomControl: false,
            mapTypeControl: true,
            fullscreenControl: false,
            center: { lat: 51.5074, lng: 0.1278 },
            zoom: 6,
        },
    );

    const addMarker = useCallback ((lat, lng) => {
        if (google) {
            const marker = new google.maps.Marker({
                position: { lat: lat, lng: lng },
                map
            });
            map?.setCenter({ lat: lat, lng: lng })
            prevMarkerRef.current = marker
        }
    }, [google, map])

    useEffect(() => {
        if(project) {
            const lat = project?.location?.location?.lat;
            const lon =  project?.location?.location?.lon;
            if(lat && lon)
                addMarker(lat, lon)
        }
    }, [project, addMarker])

    return (
        <div className="mt-2 mb-6 border-top">
            {!edit ?
            <div>
                <div className='flex items-center border-bottom'>
                    <div className='flex-column flex-1 px-6 py-3 my-1 border-right'>
                        <label className='regular-text font-12 light-purple-text'>Country</label>
                        <label className='mt-2 regular-text font-16'>{project?.location?.country}</label>
                    </div>
                    <div className='flex-column flex-1 px-6 py-3 my-1'>
                        <label className='regular-text font-12 light-purple-text'>Region</label>
                        <label className='mt-2 regular-text font-16'>{project?.location?.region}</label>
                    </div>
                </div>
                <div className='flex items-center border-bottom'>
                    <div className='flex-column flex-1 px-6 py-3 my-1 border-right'>
                        <label className='regular-text font-12 light-purple-text'>Lat</label>
                        <label className='mt-2 regular-text font-16'>{project?.location?.location?.lat}</label>
                    </div>
                    <div className='flex-column flex-1 px-6 py-3 my-1'>
                        <label className='regular-text font-12 light-purple-text'>Lon</label>
                        <label className='mt-2 regular-text font-16'>{project?.location?.location?.lon}</label>
                    </div>
                </div>
            </div>
          :
        <div>
            <div className='flex items-center'>
                <div className='flex-column flex-1 px-6 py-3 my-1'>
                    <label className='regular-text font-12 light-purple-text'>Country</label>
                    <input className="input mt-1" value={project?.location?.country || ''} placeholder={'Enter country'} onChange={e => changeProject({...project, location: {...project.location, country: e.target.value}})}/>
                </div>
                <div className='flex-column flex-1 px-6 py-3 my-1'>
                    <label className='regular-text font-12 light-purple-text'>Region</label>
                    <input className="input mt-1" value={project?.location?.region || ''} placeholder={'Enter region'} onChange={e => changeProject({...project, location: {...project.location, region: e.target.value}})}/>
                </div>
            </div>
            <div className='flex items-center'>
                <div className='flex-column flex-1 px-6 py-3 my-1'>
                    <label className='regular-text font-12 light-purple-text'>Lat</label>
                    <input className="input mt-1" value={project?.location?.location?.lat || ''} placeholder={'000000'} onChange={e => changeProject({...project, location: {...project.location, location: {...project.location.location, lat: parseFloat(e.target.value)}}})}/>
                </div>
                <div className='flex-column flex-1 px-6 py-3 my-1'>
                    <label className='regular-text font-12 light-purple-text'>Lon</label>
                    <input className="input mt-1" value={project?.location?.location?.lon || ''} placeholder={'000000'}  onChange={e => changeProject({...project, location: {...project.location, location: {...project.location.location, lon: parseFloat(e.target.value)}}})}/>
                </div>
            </div>
        </div>
            }
          <div className="details-info-map" ref={ref}></div>
        </div>
    );
};

export default ProjectLocation;
