import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getConsignements } from '../../store/features/inventorySlice';
import moment from "moment";
import Menu from "../DefaultMenu";

import "../../pages/projectDetails/details.scss";

import { ReactComponent as ArrowDownIcon } from '../../assets/images/menu/arrow-down-black.svg';

const CarbonListItem = ({carbon, setEdit}) => {
    const dispatch = useDispatch();
    const { consignments } = useSelector(data => data.inventory);
    const imageMenuItems = [{id: 0, name: "Edit"}, {id: 1, name: "Consigment"}];
    
    const [show, setShow] = useState(false)

    useEffect(() => {
        if(show && carbon.consignments.consigned_count && carbon.consignments.consigned_count > 0)
            dispatch(getConsignements({id: carbon.id, page: 0}));
    }, [carbon.id, show]);

    const onMenuItemClick = item => {
        if(item.name === 'Edit')
            setEdit(carbon)
    }

    return (
        <div onClick={() => carbon.consignments.consigned_count && carbon.consignments.consigned_count > 0 ? setShow(!show) : {}}>
            <div  className="carbon-item border-bottom items-center">
                <div className="flex items-center">
                    <div className="flex-column justify-center px-6 border-right carbon-item-center w-full">
                        <label className="regular-text font-12 light-purple-text">Balance:</label>
                        <label className="bold-text mt-1">{carbon.carbon?.current_balance /1000}</label>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex-column justify-center px-6 border-right carbon-item-center w-full">
                        <div className="flex items-center">
                            <label className="regular-text font-12 light-purple-text">Vintage to:</label>
                            <label className="medium-text font-12 ml-2" style={{marginLeft: "22px"}}>{carbon.vintage.vintage_to ? moment.unix(carbon.vintage.vintage_to).format('DD/MM/YYYY') : '-'}</label>
                        </div>
                        <div className="flex items-center mt-1">
                            <label className="regular-text font-12 light-purple-text">Vintage from:</label>
                            <label className="medium-text font-12 ml-2">{carbon.vintage.vintage_from ? moment.unix(carbon.vintage.vintage_from).format('DD/MM/YYYY') : '-'}</label>
                        </div>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex-column justify-center px-6 border-right carbon-item-center w-full">
                        <div className="flex items-center">
                            <label className="regular-text font-12 light-purple-text">Owner:</label>
                            <label className="medium-text font-12 ml-2" style={{marginLeft: "39px"}}>{carbon.owner?.name || '-'}</label>
                        </div>
                        <div className="flex items-center mt-1">
                            <label className="regular-text font-12 light-purple-text">Primary account:</label>
                            <label className="medium-text font-12 ml-2">{carbon?.primary_account?.name || '-'}</label>
                        </div>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="flex-column justify-center px-6 border-right carbon-item-center w-full">
                        <div className="flex items-center">
                            <label className="regular-text font-12 light-purple-text">Purchase price:</label>
                            <label className="medium-text font-12 ml-2">{carbon.costs.purchase_price} {carbon.costs.currency}</label>
                        </div>
                        <div className="flex items-center mt-1">
                            <label className="regular-text font-12 light-purple-text">Purchased:</label>
                            <label className="medium-text font-12 ml-2"  style={{marginLeft: "30px"}}>{carbon.carbon.open_balance/1000} tCO2</label>
                        </div>
                    </div>
                </div>
                <div className='flex-column items-center mr-4 absolute' style={{right: 32}}>
                    <Menu menuItems={imageMenuItems} background={true} onMenuItemClick={onMenuItemClick} />
                    {carbon.consignments.consigned_count > 0 &&
                        <ArrowDownIcon className={`arrow-down mt-1 ${false && 'arrow-up'}`} /> }
                </div>
            </div>
            {consignments.content && consignments.content.length > 0 && 
                <div className='ci-consigneds' style={{background: '#FBFBFB', height: show ? carbon.consignments.consigned_count * 43  : 0}}>
                    {consignments.content.map(ci => 
                        <div key={ci.id} className='flex border-bottom py-1'>
                            <div className='flex items-center border-right pl-6' style={{height: '34px', width: '30%'}}>
                                <label className='regular-text'>{ci.consignments.consigned_count_to?.name}</label>
                            </div>
                            <div className="flex-column flex-1 border-right justify-center" style={{height: '34px'}}>
                                <label className="regular-text light-purple-text ml-6">Consigned: <span className="medium-text">{ci.consignments.consigned_count_to?.date && moment.unix(ci.consignments.consigned_count_to?.date).format("DD/MM/YYYY")}</span></label>
                            </div>
                            <div className="flex-column flex-1 border-right justify-center" style={{height: '34px'}}>
                                <label className="regular-text light-purple-text ml-6">Purchase price: <span className="medium-text">{ci.costs.purchase_price || '-'} {ci.costs.currency}</span></label>
                            </div>
                            <div className="flex-column flex-1 justify-center" style={{height: '34px'}}>
                                <label className="regular-text light-purple-text ml-6">Tonnes: <span className="medium-text">{ci.carbon.current_balance / 1000}</span></label>
                            </div>
                        </div>
                        )}
                </div>
                }
        </div>
            )
}

export default CarbonListItem;
