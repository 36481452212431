import styled from 'styled-components';
import { SUB_MENU_ITEM_COLOR } from '../../constant/colorTheme';

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -10px;
  right: calc(100% + 10px);
  background: #ffffff;
  padding: 6px;
  z-index: 2;
  cursor: pointer;
  * {
    cursor: pointer;
  }

  .regular-text {
    padding: 6px;
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
  }

  .regular-text:hover {
    background-color: ${SUB_MENU_ITEM_COLOR};
    border-radius: 4px;
  }
`;
