import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import Item from './item'

import AddIcon from "../../assets/images/add.svg";

import './style.scss';

const Filter = ({ objects, values, setFilter}) => {
    const [filters, setFilters] = useState(values || [{id: 0}]);

    const getFilter = f => {
        if(f.object?.view === 'DATE_RANGE') {
            let range = [];
            if(f.start)
                range = [...range, {param: f.object.startParam, value: f.start}]
            if(f.end)
                range = [...range, {param: f.object.endParam, value: f.end}]
            return range;
        }
        if(f.value)
            return [{param: f.object.param, value: f.object.paramValue ? f.value[f.object.paramValue] : f.value.id}]
        else
            return [];
    }

    useEffect(() => {
        setFilter({filters: filters, value: filters.map(getFilter).flat().reduce((filter, current, i) => filter += `${i === 0 ? '' : '&'}${current.param}=${current.value}`, '')})
    }, [filters, setFilter]);
    
    return (
        <div className="flex-column card filter-menu absolute">
            <div className="flex items-center justify-between pxy-6 border-bottom">
                <label className="bold-text">Filters</label>
                <div className="flex items-center justify-center cursor clear-all no-select" onClick={() => setFilters([{id: uuid()}])}>
                    <label className="bold-text font-12 light-purple-text px-2" style={{color: filters.length > 0 ? '#020819' : '#AABAB7'}}>Clear all</label>
                </div>
            </div>
            <div>
                {filters.map((filter, i) =>  
                <Item 
                    key={i} nr={i + 1} 
                    filter={{...filter, objects: objects}} 
                    setFilter={f => setFilters(filters.map(fi => fi.id === f.id ? f : fi))}
                    removeFilter={f => setFilters(filters.filter(fi => fi.id !== f.id))}
                /> 
              )}
            </div>
            <div className="flex items-center justify-center px-6 py-4 no-select">
                <div className="flex items-center justify-center cursor add-new-filter-btn" onClick={() => setFilters([...filters, {id: uuid()}])}>
                    <img src={AddIcon} alt="add" />
                    <label className="bold-text font-12 mid-green-text  ml-2">Add new filter</label>
                </div>
            </div>
        </div>
    )
}

export default Filter;