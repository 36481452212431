export const initModal = {
  type: null,
  content: {},
};

export const InitDashBoard = [
  {
    organisationId: '9b5f5b5a-2e02-4272-adb2-667718fb14a9',
    organisationName: 'Cavity',
    visits: [
      {
        timestamp: 1623196800,
        number: 20,
      },
      {
        timestamp: 1623283200,
        number: 4,
      },
      {
        timestamp: 1623369600,
        number: 31,
      },
      {
        timestamp: 1623456000,
        number: 39,
      },
      {
        timestamp: 1623542400,
        number: 37,
      },
      {
        timestamp: 1623628800,
        number: 11,
      },
      {
        timestamp: 1623715200,
        number: 1,
      },
      {
        timestamp: 1621123200,
        number: 10,
      },
      {
        timestamp: 1621209600,
        number: 13,
      },
      {
        timestamp: 1621296000,
        number: 26,
      },
      {
        timestamp: 1621382400,
        number: 30,
      },
      {
        timestamp: 1621468800,
        number: 84,
      },
      {
        timestamp: 1621555200,
        number: 56,
      },
      {
        timestamp: 1621728000,
        number: 2,
      },
      {
        timestamp: 1621814400,
        number: 37,
      },
      {
        timestamp: 1621900800,
        number: 31,
      },
      {
        timestamp: 1621987200,
        number: 40,
      },
      {
        timestamp: 1622073600,
        number: 18,
      },
      {
        timestamp: 1622160000,
        number: 50,
      },
      {
        timestamp: 1622246400,
        number: 46,
      },
      {
        timestamp: 1622332800,
        number: 26,
      },
      {
        timestamp: 1622419200,
        number: 31,
      },
      {
        timestamp: 1622505600,
        number: 48,
      },
      {
        timestamp: 1622592000,
        number: 41,
      },
      {
        timestamp: 1622678400,
        number: 24,
      },
      {
        timestamp: 1622764800,
        number: 28,
      },
      {
        timestamp: 1622851200,
        number: 13,
      },
      {
        timestamp: 1622937600,
        number: 17,
      },
      {
        timestamp: 1623024000,
        number: 9,
      },
      {
        timestamp: 1623110400,
        number: 17,
      },
    ],
  },
  {
    organisationId: '9b5f5b5a-2e02-4272-adb2-667718fb1411',
    organisationName: 'Loft',
    visits: [
      {
        timestamp: 1623196800,
        number: 71,
      },
      {
        timestamp: 1623283200,
        number: 86,
      },
      {
        timestamp: 1623369600,
        number: 92,
      },
      {
        timestamp: 1623456000,
        number: 87,
      },
      {
        timestamp: 1623542400,
        number: 149,
      },
      {
        timestamp: 1623628800,
        number: 205,
      },
      {
        timestamp: 1623715200,
        number: 69,
      },
      {
        timestamp: 1621209600,
        number: 23,
      },
      {
        timestamp: 1621296000,
        number: 26,
      },
      {
        timestamp: 1621382400,
        number: 19,
      },
      {
        timestamp: 1621468800,
        number: 7,
      },
      {
        timestamp: 1621814400,
        number: 1,
      },
      {
        timestamp: 1621987200,
        number: 10,
      },
      {
        timestamp: 1622073600,
        number: 5,
      },
      {
        timestamp: 1622160000,
        number: 91,
      },
      {
        timestamp: 1622246400,
        number: 148,
      },
      {
        timestamp: 1622332800,
        number: 157,
      },
      {
        timestamp: 1622419200,
        number: 153,
      },
      {
        timestamp: 1622505600,
        number: 164,
      },
      {
        timestamp: 1622592000,
        number: 168,
      },
      {
        timestamp: 1622678400,
        number: 198,
      },
      {
        timestamp: 1622764800,
        number: 178,
      },
      {
        timestamp: 1622851200,
        number: 135,
      },
      {
        timestamp: 1622937600,
        number: 85,
      },
      {
        timestamp: 1623024000,
        number: 80,
      },
      {
        timestamp: 1623110400,
        number: 86,
      },
    ],
  },
  {
    organisationId: '9b5f5b5a-2e02-4272-adb2-667718fb14a1',
    organisationName: 'Boiler',
    visits: [
      {
        timestamp: 1623196800,
        number: 37,
      },
      {
        timestamp: 1623283200,
        number: 54,
      },
      {
        timestamp: 1623369600,
        number: 29,
      },
      {
        timestamp: 1623456000,
        number: 27,
      },
      {
        timestamp: 1623542400,
        number: 27,
      },
      {
        timestamp: 1623628800,
        number: 27,
      },
      {
        timestamp: 1623715200,
        number: 3,
      },
      {
        timestamp: 1621123200,
        number: 47,
      },
      {
        timestamp: 1621209600,
        number: 72,
      },
      {
        timestamp: 1621296000,
        number: 14,
      },
      {
        timestamp: 1621382400,
        number: 21,
      },
      {
        timestamp: 1621468800,
        number: 24,
      },
      {
        timestamp: 1621555200,
        number: 43,
      },
      {
        timestamp: 1621641600,
        number: 31,
      },
      {
        timestamp: 1621814400,
        number: 95,
      },
      {
        timestamp: 1621900800,
        number: 76,
      },
      {
        timestamp: 1621987200,
        number: 107,
      },
      {
        timestamp: 1622073600,
        number: 90,
      },
      {
        timestamp: 1622160000,
        number: 22,
      },
      {
        timestamp: 1622246400,
        number: 28,
      },
      {
        timestamp: 1622332800,
        number: 95,
      },
      {
        timestamp: 1622419200,
        number: 68,
      },
      {
        timestamp: 1622505600,
        number: 69,
      },
      {
        timestamp: 1622592000,
        number: 62,
      },
      {
        timestamp: 1622678400,
        number: 51,
      },
      {
        timestamp: 1622764800,
        number: 20,
      },
      {
        timestamp: 1622851200,
        number: 10,
      },
      {
        timestamp: 1622937600,
        number: 40,
      },
      {
        timestamp: 1623024000,
        number: 86,
      },
      {
        timestamp: 1623110400,
        number: 80,
      },
    ],
  },
  {
    organisationId: '9b5f5b5a-2e02-4272-adb2-667718fb1111',
    organisationName: 'Room in roof',
    visits: [
      {
        timestamp: 1623196800,
        number: 81,
      },
      {
        timestamp: 1623283200,
        number: 29,
      },
      {
        timestamp: 1623369600,
        number: 85,
      },
      {
        timestamp: 1623456000,
        number: 63,
      },
      {
        timestamp: 1623542400,
        number: 117,
      },
      {
        timestamp: 1623628800,
        number: 109,
      },
      {
        timestamp: 1623715200,
        number: 34,
      },
      {
        timestamp: 1621123200,
        number: 28,
      },
      {
        timestamp: 1621209600,
        number: 33,
      },
      {
        timestamp: 1621296000,
        number: 23,
      },
      {
        timestamp: 1621382400,
        number: 24,
      },
      {
        timestamp: 1621468800,
        number: 43,
      },
      {
        timestamp: 1621555200,
        number: 42,
      },
      {
        timestamp: 1621641600,
        number: 17,
      },
      {
        timestamp: 1621814400,
        number: 11,
      },
      {
        timestamp: 1621900800,
        number: 36,
      },
      {
        timestamp: 1621987200,
        number: 22,
      },
      {
        timestamp: 1622073600,
        number: 19,
      },
      {
        timestamp: 1622160000,
        number: 39,
      },
      {
        timestamp: 1622246400,
        number: 42,
      },
      {
        timestamp: 1622332800,
        number: 89,
      },
      {
        timestamp: 1622419200,
        number: 36,
      },
      {
        timestamp: 1622505600,
        number: 11,
      },
      {
        timestamp: 1622592000,
        number: 16,
      },
      {
        timestamp: 1622764800,
        number: 28,
      },
      {
        timestamp: 1622851200,
        number: 19,
      },
      {
        timestamp: 1622937600,
        number: 138,
      },
      {
        timestamp: 1623024000,
        number: 40,
      },
      {
        timestamp: 1623110400,
        number: 76,
      },
    ],
  },
];

export const dashboardChartSelector = [
  {
    externalId: 0,
    name: 'Daily',
    unit: 'day',
    selected: false,
    apiUnit: 'hourly',
  },
  {
    externalId: 1,
    name: 'Weekly',
    unit: 'week',
    selected: false,
    apiUnit: 'daily',
  },
  {
    externalId: 2,
    name: 'Monthly',
    unit: 'month',
    selected: true,
    apiUnit: 'daily',
  },
];

export const integrationData = [
  {
    name: 'Sendgrid',
    enabled: true,
    type: 'SENDGRID',
  },
  {
    name: 'Twilio',
    enabled: true,
    type: 'TWILIO',
  },
  {
    name: 'Webhook',
    enabled: false,
    type: 'WEBHOOK',
  },
];
