import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNfts } from '../../store/features/nftSlice';

import NftList from "../../components/nfts/NftList";

import './nfts.scss';

const Nfts = () => {
    const dispatch = useDispatch();
    const { nfts, loading } = useSelector(data => data.nft);

    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState({value: ''});
    const [lifecycles, setLifecycles] = useState([{id: 0, name: 'MINTED', color: '#57DBD3', selected: true}, { id: 1, name: 'PENDING', color: '#FBBF66'}, { id: 2, name: 'RANKED', color: '#7976F4'}])

    useEffect(() => {
        dispatch(getNfts({page: currentPage, lifecycles: lifecycles.filter(l => l.selected).map(l => l.name), filter: filter?.value }));
    }, [currentPage, lifecycles, filter?.value, dispatch]);

    return (
        <div className="flex-column mb-6 mt-2 flex-1">
            <NftList 
                nfts={nfts.content}
                loading={loading}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={nfts.total_pages}
                search={search}
                setSearch={setSearch}
                lifecycles={lifecycles}
                setLifecycles={setLifecycles}
                filter={filter}
                setFilter={setFilter}
                filterObjects={["COLLECTION", "NFT_TYPE", 'CREATED_DATES', 'MINTED_DATES']}
                addPermission="NFT_MANAGE"
                type="grid"
            />
        </div>
    )
}

export default Nfts;
