import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { getInventoryReport, getOwnerPerformance } from '../../../store/features/reportsSlice';
import OpenIcon from '../../../assets/images/open-green.svg'
import ReportSale from "../sale";

import './style.scss'

const ReportCombined = ({organisation, selected}) => {
    const monthNames = ["January", "Feburary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    
    const dispatch = useDispatch();

    const { inventoryReport, ownerPerformance } = useSelector(data => data.reports);
    const [sales, setSales] = useState([]);

    useEffect(() => {
        dispatch(getInventoryReport({startDate: selected.startDate, endDate: selected.endDate, organizationId: organisation?.id === 'all' ? null : organisation?.id}));
    }, [dispatch, selected, organisation]);

    useEffect(() => {
        dispatch(getOwnerPerformance({startDate: selected.startDate, endDate: selected.endDate, organizationId: organisation?.id === 'all' ? null : organisation?.id}));
    }, [dispatch, selected, organisation]);

    useEffect(() => {
        setSales(
          ownerPerformance
            .filter((e) => e.project?.id)
            .map(sale => ({...sale, paymentFees: sale.amount_usd * (sale.payment_fees || 0), ownerCommission: sale.amount_usd * (sale.owner_commission || 0), platformCommission: sale.amount_usd * (sale.platform_commission || 0)}))
            .reduce((r, e) => {
              var key = (e.project?.id || "-") + "::" + e.currency;
              if (!r[key]) r[key] = [e];
              else {
                r[key].push(e);
              }
              return r;
            }, [])
        );
      }, [ownerPerformance]);

    return (
        <div className="flex-column report-combined-list">
            <div className="report-details-grid-3 report-details-header border-top border-bottom">
                <label className="font-12 regular-text px-4 py-3 one-line">PROJECT</label>
                <label className="font-12 regular-text px-4 py-3 one-line">OWNER</label>
                <label className="font-12 regular-text px-4 py-3 one-line">RETIRED ON</label>
                <label className="font-12 regular-text px-4 py-3 one-line">VINTAGE</label>
                <label className="font-12 regular-text px-4 py-3 one-line">SERIAL</label>
                <label className="font-12 regular-text px-4 py-3 one-line">OPENING</label>
                <label className="font-12 regular-text px-4 py-3 one-line">RETIRED</label>
                <label className="font-12 regular-text px-4 py-3 one-line">CLOSING</label>
                <label className="font-12 regular-text px-4 py-3 one-line">OFFSET URL</label>
            </div>
            <div className="flex-column report-inventory-lis">
                {inventoryReport.length === 0 && <div className="flex items-center justify-center py-3">
                    <label className="bold-text light-purple-text">No report</label>
                    </div>}
            {inventoryReport.map((op, i) => 
                <div key={i} className='flex-column'>
                    <div className={`report-details-grid-3 ci-card cursor cursor ${i !== 0 && 'border-top'}`}>
                        <label className='regular-text px-4 py-3 one-line'>{op.project?.name}</label>
                        <label className='regular-text px-4 py-3 one-line'>{op.owner?.name}</label>
                        <label className='regular-text px-4 py-3 one-line'>{op.retired_on ? moment.unix(op.retired_on).format('DD/MM/YYYY') : ''}</label>
                        <label className='regular-text px-4 py-3 one-line'>{moment.unix(op?.vintage?.vintage_from).format('DD/MM/YYYY')}-{moment.unix(op?.vintage?.vintage_to).format('DD/MM/YYYY')}</label>
                        <label className='regular-text px-4 py-3 one-line'>{op.serial}</label>
                        <label className='regular-text px-4 py-3 one-line'>{(op.open_balance)?.toFixed(2).toLocaleString('en-US')}</label>
                        <label className='regular-text px-4 py-3 one-line'>{(op.retired)?.toFixed(2).toLocaleString('en-US')}</label>
                        <label className='regular-text px-4 py-3 one-line'>{(op.closing_balance)?.toFixed(2).toLocaleString('en-US')}</label>
                        <div className="px-4 py-3 one-line flex items-center"  onClick={() => { window.open(op.offset_url, "_blank")}}>
                            <label className='regular-text one-line'>{op.offset_url}</label>
                            {op.offset_url && <img src={OpenIcon} alt="copy" className="cursor mr-4" /> }
                        </div>
                    </div>
                    {op.id !== inventoryReport[i+1]?.id && 
                        <div className={"report-details-grid-3 ci-card cursor cursor border-top border-bottom mb-10"}>
                        <label className='bold-text px-4 py-3 one-line'></label>
                        <label className='bold-text px-4 py-3 one-line'></label>
                        <label className='bold-text px-4 py-3 one-line'></label>
                        <label className='bold-text px-4 py-3 one-line'></label>
                        <label className='bold-text px-4 py-3 one-line'></label>
                        <label className='bold-text px-4 py-3 one-line'>Total:</label>
                        <label className='bold-text px-4 py-3 one-line'>{(inventoryReport.filter(p => p.id === op.id).map(p => p.retired).reduce((sum, p) => sum + p, 0)).toFixed(2).toLocaleString('en-US')}</label>
                        <label className='bold-text px-4 py-3 one-line'></label>
                        <label className='bold-text px-4 py-3 one-line'></label>
                    </div>}
                </div>
                )}
            </div>
            <div className="report-details-grid-sales report-details-header border-top">
        {/* <label className="font-12 regular-text px-4 py-3">INVENTORY</label> */}
        <label className="font-12 regular-text px-4 py-3 text-center">
          PROJECT
        </label>
        <label className="font-12 regular-text px-4 py-3 text-center">OWNER</label>
        <label className="font-12 regular-text px-4 py-3 text-center">
          SERIAL
        </label>
        <label className="font-12 regular-text px-4 py-3 text-center">MONTH</label>
        {/* <label className="font-12 regular-text px-4 py-3">WHOLESALE PRICE</label> */}
        <label className="font-12 regular-text px-4 py-3 text-center">
          RETAIL SALE
        </label>
        <label className="font-12 regular-text px-4 py-3 text-center">
          RETAIL SALE USD
        </label>
        <label className="font-12 regular-text px-4 py-3 text-center">
          PAYMENT PROCESSING
        </label>
        <label className="font-12 regular-text px-4 py-3 text-center">
          OWNER COMMISSION
        </label>
        <label className="font-12 regular-text px-4 py-3 text-center">
          PLATFORM COMMISSION
        </label>
        <label className="font-12 regular-text px-4 py-3 text-center">
          AMOUNT DUE SUPPLIER
        </label>
        {/* <label className="font-12 regular-text px-4 py-3">
          AMOUNT USD
        </label> */}
        <label className="font-12 regular-text px-4 py-3 text-center">SALES TONNES</label>
      </div>
      <div className="flex-column report-sales-list">
        {Object.entries(sales).map(([k, v]) => (
          <div>
            {v.map((op, i) => (
              <ReportSale sale={op} key={i}/>
            ))}
            <div className={"report-details-grid-sales ci-card cursor border-top cursor border-bottom mb-10"}>
                <label className='bold-text px-4 py-3'></label>
                <label className='bold-text px-4 py-3'></label>
                <label className='bold-text px-4 py-3'></label>
                <label className='bold-text px-4 py-3'>Total:</label>
                <label className='bold-text px-4 py-3'>{v.map(p => p.amount).reduce((sum, p) => sum + p, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} {k.split("::")[1]}</label>
                <label className='bold-text px-4 py-3'>${v.map(p => p.amount_usd).reduce((sum, p) => sum + p, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</label>
                <label className='bold-text px-4 py-3'>${v.map(p => p.paymentFees).reduce((sum, p) => sum + p, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</label>
                <label className='bold-text px-4 py-3'>${v.map(p => p.ownerCommission).reduce((sum, p) => sum + p, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</label>
                <label className='bold-text px-4 py-3'>${v.map(p => p.platformCommission).reduce((sum, p) => sum + p, 0).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</label>
                <label className='bold-text px-4 py-3'>${(v.map(p => p.amount_usd).reduce((sum, p) => sum + p, 0) - v.map(p => p.paymentFees).reduce((sum, p) => sum + p, 0) - v.map(p => p.ownerCommission).reduce((sum, p) => sum + p, 0) - v.map(p => p.platformCommission).reduce((sum, p) => sum + p, 0)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</label>
                <label className='bold-text px-4 py-3'>{((v.map(p => p.sales).reduce((sum, p) => sum + p, 0)/1000)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits:2})}</label>
            </div>
          </div>
        ))}
      </div>
        </div>
    )
}

export default ReportCombined;