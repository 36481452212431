import React, { useState } from 'react';

import moment from 'moment';

import DateSelector from '../date-selector/date-selector';

import ArrowDown from '../../../assets/images/arrow-down-2.svg';

import  './date-selector-element.scss';

const DateSelectorElement = ({
  selectedDate,
  setSelectedDate,
  placeholder,
  format = 'DD MMM YYYY',
  className = '',
  showDropdownArrow = true,
  borderRadius="8px"
}) => {
  const [showCalendar, setShowCalendar] = useState(false);

  const changeSelectedDate = date => {
    setSelectedDate(date);
    setShowCalendar(false);
  };

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowCalendar(false);
    }
  };

  return (
    <div
      className={`date-selector-element flex flex-1 items-center justify-between px-4 cursor ml-10 ${className}`}
      onBlur={onBlur}
      onClick={() => setShowCalendar(!showCalendar)}
      style={{ borderColor: showCalendar ? '#40F1DE' : '#D2E5ED', borderRadius: borderRadius }}
      tabIndex={0}>
      {selectedDate ? (
        <label className="regular-text font-16">{moment.unix(selectedDate).format(format)}</label>
      ) : (
        <label className="regular-text grey-text">{placeholder}</label>
      )}
      {showDropdownArrow && <img src={ArrowDown} />}
      {showCalendar && (
        <DateSelector
          setEndDate={() => {}}
          setStartDate={changeSelectedDate}
          startDate={moment.unix(selectedDate || moment().unix())}
          format={format}
        />
      )}
    </div>
  );
};

export default DateSelectorElement;
