import styled from 'styled-components';

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 35px;
  margin-left: 26px;

  .content-loader {
    position: absolute;
    inset: 0;
    margin: 0;
    opacity: 0.8;
    z-index: 10;
  }

  .tpus-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin: 0 8px;
    cursor: pointer;

    label {
      width: 100%;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .tpus-user:hover {
    background: rgba(210, 229, 237, 0.3);
    border-radius: 4px;
    cursor: pointer;
  }

  .options-select-container {
    max-width: 420px;

    .select__placeholder {
      color: #053149;
      opacity: 0.3;
    }

    .select__value-container {
      padding-left: 4px;
      padding-top: 0px;
      padding-bottom: 2px;
    }

    .select__dropdown-indicator {
      padding: 4px 8px;
    }

    .select__control {
      min-height: 32px;
      height: 32px;
      border-radius: 10px;
      border: 1px solid #d2e5ed;

      &:hover {
        background: #f4f7f9;
      }
    }

    .select__control--is-focused {
      border: 1px solid #31ddca;
      box-shadow: unset;
    }

    .select__menu-list {
      max-height: 200px;
    }

    .select__multi-value {
      background: rgba(210, 229, 237, 0.3);
      border-radius: 4px;
    }

    .select__multi-value__label {
      color: #053149;
      font-size: 16px;
    }

    .select__clear-indicator,
    .select__indicator-separator {
      display: none;
    }

    .select__dropdown-indicator {
      svg {
        fill: black;
      }
    }

    .select__multi-value__remove {
      background: #ffffff;
      margin: 6px;
      padding: 0px;
      cursor: pointer;
    }
  }

  .black-text {
    font-size: 22px;
    margin-bottom: 25px;
    line-height: 25px;
  }

  .sub-label {
    font-size: 12px;
    color: #73868c;
    opacity: 0.5;
    text-transform: uppercase;
  }

  .profile-info-label {
    color: #73868c;
  }

  .not-provided-label {
    color: rgba(5, 49, 73, 0.3);
  }

  .profile-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 26px;
    max-width: 360px;
    min-width: 360px;

    hr {
      margin: 0 -20px;
      width: inherit;
    }
  }

  .profile-right {
    display: flex;
    flex-direction: column;
    flex: 2;
    background: white;
    padding: 24px;
    margin-right: 26px;
    height: calc(100vh - 125px);
  }

  .profile-left-content {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    height: fit-content;
    max-height: calc(100vh - 125px);
    overflow: auto;

    .user-info-top {
      display: flex;
      flex-direction: column;
      padding: 0px 20px 20px 20px;
      border-bottom: 1px solid #d2e5ed;

      .menu-btn {
        cursor: pointer;
      }

      .add-role-dropdown {
        min-height: 30px;
        margin-left: 4px;
        border-radius: 8px;
      }
    }

    .service-item {
      background: #f4f7f9;
      border-radius: 8px;
      margin-bottom: 8px;
    }

    .user-infos {
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-bottom: 1px solid #d2e5ed;

      img {
        min-width: 16px;
        min-height: 16px;
      }

      .company-info {
        margin: 5px 0;
      }

      .user-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 5px 0;

        .icon-container {
          padding: 4px;
          background: #f4f7f9;
          border-radius: 4px;
        }
      }

      .user-teams {
        margin-top: 5px;

        .team-container {
          background: #f4f7f9;
          border-radius: 8px;
          margin-bottom: 8px;

          .team-data {
            padding: 11px 16px 16px 16px;

            .team-name {
              margin-bottom: 10px;
              font-size: 16px;
            }

            hr {
              margin: 0 -15px;
            }
          }
        }
      }
    }

    .profile-service-container {
      padding: 20px;
      border-bottom: 1px solid #d2e5ed;

      .add-service-icon {
        padding: 4px;
        background: #f4f7f9;
        border-radius: 4px;
      }
    }

    .bottom-border-unset {
      border-bottom: unset;
    }

    .add-service {
      .add-service-dropdown {
        border-radius: 8px;
        .selector {
          background: #ffffff;
        }
      }
      .capacity-input {
        width: 90px;
        font-size: 14px;
        border-radius: 8px;
        padding: 4px 12px;
        margin-bottom: 0px;

        &:focus {
          border: 1px solid #31ddca;
        }
      }
      .add-service-action {
        column-gap: 24px;
        .w-full {
          width: 100% !important;
        }
      }
    }
  }

  .coming-soon-label {
    font-size: 18px;
    color: #d2e5ed;
  }

  .edit-profile-action {
    padding: 8px 16px;
    border-radius: 4px;
  }

  .profile-input {
    width: 100%;
    font-size: 14px;
    border-radius: 4px;
    padding: 4px;
    margin-bottom: 4px;

    &:focus {
      border: 1px solid #31ddca;
    }
  }

  .error-input {
    border-color: red;
  }

  .error-border {
    .select__control {
      border-color: red;
    }
  }
`;

export default ProfileWrapper;

export const ServiceSelectorMenuWrapper = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.1);
  border-radius: 8px;
  z-index: 2;
  right: 18px;
  top: -32px;
  width: 150px;
  padding: 5px;

  .service-menu-item {
    white-space: nowrap;
    padding: 8px;
  }

  .service-menu-item:hover {
    background: #f4f7f9;
    border-radius: 4px;
  }
`;
