import styled from 'styled-components';

export const CircularProgressBarWrapper = styled.div`
  .circle-background {
    fill: none;
    stroke: none;
  }

  .circle-progress {
    fill: none;
  }
`;
