import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTemplates } from "../../store/features/templateSlice";
import Header from "../../components/header";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useNavigate } from "react-router-dom";
import { ReactComponent as DotsIcon } from '../../assets/images/menu/dots.svg';
import Image from "../../components/image";

import Loading from '../../assets/images/loading.svg';

import './style.scss'

const Templates = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { templates, loading } = useSelector(data => data.template);
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatch(getTemplates({page: currentPage, search: search}))
    }, [currentPage, search, dispatch]);

    return (
        <div className="flex-column mb-6 mt-2 flex-1">
            <Header title={"Templates"}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    search={search}
                    setSearch={setSearch}
                    isAddBtn={true}
                    addPermission="COLLECTIONS_MANAGE"
                    totalPages={templates.total_pages}
                    onAdd={() => navigate(`/meta/templates/new/media`)}
                    />

            <div>
                {loading ? 
                <div className="flex flex-1 items-center justify-center mt-4">
                    <img src={Loading} />
                    <label className="bold-text font-16 ml-2 light-purple-text">Loading</label>
                </div>
                :
                <TransitionGroup  className="templates-cards card mx-6 mt-4">
                    {templates.content?.map(template =>
                        <CSSTransition key={template.id} appear classNames="fade" timeout={500}>
                            <div className="template-grid border-bottom" onClick={() => navigate(`/meta/templates/${template.id}/attributes`)}>
                                <div className="flex items-center template-item border-right my-2 pl-2 pr-6">
                                    <Image className={"templates-template-image"} size={64} media={template?.image?.media} />
                                    <div className="flex-column ml-4">
                                        <label className="semibold-text font-16">{template.name}</label>
                                    </div>
                                </div>
                                <div className="flex items-center template-item border-right my-2 px-6">
                                    <label className="regular-text light-purple-text font-12">{template.description}</label>
                                </div>
                                <div className="flex-column justify-center template-item border-right my-2 px-6">
                                    <label className="regular-text light-purple-text font-12">Max supply:<span className="regular-text">{template.max_supply}</span></label>
                                    <label className="regular-text light-purple-text font-12 mt-4">Transferable:<span className="regular-text font-12 ml-2">{template.is_transferable ? 'Yes' : 'No'}</span></label>
                                </div>
                                <div className="flex items-center justify-between template-item my-2 px-6">
                                    <div className="flex-column justify-center template-item">
                                    <label className="regular-text light-purple-text font-12 mt-4">Burnable:<span className="regular-text font-12 ml-2">{template.is_burnable ? 'Yes' : 'No'}</span></label>
                                        <label className="regular-text light-purple-text font-12 mt-4">Schema:<span className="regular-text font-12 ml-2">{template.schema?.name}</span></label>
                                    </div>
                                    <DotsIcon className="cursor" width={25} />
                                </div>
                            </div>
                        </CSSTransition>
                    )}
                </TransitionGroup>  
                }
            </div>
        </div>
    )
}

export default Templates;
