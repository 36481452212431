import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import Button from '../components/Elements/button/button';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { addToast } from '../store/features/toastSlice';
import { CreateNftWrapper } from './modal.styled.js';
import { CSSTransition } from 'react-transition-group';
import api from '../store/services'

const CreateRetirementNft = () => {
  const { modal, setModal } = useContext(OrganisationContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {campaign, onSuccess, retirements} = modal.content;

  const onCreate = async () => {
    if (loading) return;
    setLoading(true);
    try {
    const { data } = await api.post(`/v3/api/backoffice/campaigns/${campaign}/retirement_nfts`, {retirements: retirements.map(r => ({id: r.id}))})
      dispatch(
        addToast({
          error: false,
          title: 'nft-created',
          text: `NFTs have been created`,
          id: uuid(),
        }),
      );
      onSuccess();  
    } catch (error) {
      dispatch(
        addToast({
          error: true,
          text: `Failed to create NFTs`,
          id: uuid(),
        }),
      );
    }
  
    setLoading(false);
    setModal(initModal);
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <CreateNftWrapper>
        <p className="bold-text font-24 text-center">
          Are you sure you want to create NFT's of this campaign?
        </p>
        <div className="flex justify-center mt-12">
          <Button
            className="secondary-hover"
            label="No"
            onClick={() => setModal(initModal)}
            secondary={true}
            size="large"
            style={{ flex: 1, borderRadius: '16px' }}
            bgColor="#FFFFFF"
            borderColor='#24CBB1'
            color='#24CBB1'
          />
          <Button
            label='Yes'
            onClick={() => onCreate()}
            size="large"
            style={{ flex: 1, marginLeft: 24, borderRadius: '16px' }}
            bgColor="#24CBB1"
            borderColor='#24CBB1'
            color='#FFFFFF'
          />
        </div>
      </CreateNftWrapper>
    </CSSTransition>
  );
};

export default CreateRetirementNft;
