import styled from 'styled-components';

const SelectedIntegrationAutomationWrapper = styled.div`
  // schedule and condition buttn css
  .buttons-css {
    display: flex;
    margin: 0px 16px;
  }
  .for-button {
    border-radius: 16px;
    height: 32px;
    font-size: 14px;
    width: 100%;
  }
  .schedule {
    .popup-input {
      padding: 8px 12px;
      height: 40px;
      font-size: 14px;
      border: 1px solid #d2e5ed;
      border-radius: 8px;
      ::-webkit-inner-spin-button {
        display: none;
      }

      &:focus{
        border:1px solid #31ddca;
      }
    }

    .error-info {
      border-color: red;
    }
    .schedule-hour {
      min-height: 40px;
      min-width: 135px;
      margin-right: 8px;
    }
    .last-dropdown {
      flex: 1 1;
      margin-right: 0px;
    }
  }
  .schedule-label {
    height: auto;
    padding: 3px;
  }
  .main-container {
    height: auto;
    margin-bottom: 15px;
  }
  input {
    background: #ffffff;

    border: 1px solid #d2e5ed;
    border-radius: 8px;
    width: 80px;
    height: auto;
    margin-right: 12px;
  }
  .hours-selector {
    background: #ffffff;

    border: 1px solid #d2e5ed;
    border-radius: 8px;
    width: auto;
    margin: 0px 12px;
    opacity: revet;
    color: red;
    .options {
      top: 48px;
      // background: green;
      width: auto;
    }
    .selector {
      padding: 12px 16px;
    }
  }
  .hours-selector::placeholder {
    color: red;
  }
  .cancel-save-button {
    border-radius: 8px;
    width: 50%;
    height: 32px;
  }
  .button-container {
    display: flex;
    margin: 30px 0px;
    width: 100%;
    padding: 0px 15px;
  }

  .conditions-button {
    margin: 30px 0px 12px 0px;
  }
  // right side css
  .right-side-data {
    margin: 17px 3px;
    flex: 1 1;

    .options-select-container {
      width: 100%;

      .select__placeholder {
        color: #053149;
        opacity: 0.5;
        font-family: Roboto Regular;
      }

      .select__value-container {
        // padding-left: 15px;
      }

      .select__loading-indicator {
        span {
          he
        }
      }

      .select__control {
        border-radius: 10px;
        border: 1px solid #d2e5ed;

        &:hover {
          background: #F4F7F9;
        }
      }

      .select__control--is-focused {
        border: 1px solid #31DDCA;
        box-shadow: unset;
      }

      .select__menu {
        box-shadow: 2px 2px 15px rgb(5 49 73 / 5%);
      }

      .select__menu-list{
        max-height: 180px;
      }

      .select__multi-value {
        background: #d2e5ed;
        border-radius: 4px;
      }
      .select__multi-value__label {
        color: #053149;
        font-size: 16px;
        font-family: Roboto Regular;
      }

      .select__clear-indicator,
      .select__indicator-separator {
        display: none;
      }


    }

  .opened {
        .select__control {
          border-radius: 8px;
          border: 1px solid #31ddca;
        }
        .select__value-container {
      .select__input-container {
        display: flex;

        input {
          height: 26px;
        }
      }
    }
    .hide-single-value {
      display: none;
    }
      }
      }
  .config-label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #73868c;
  }
  .config-input {
    width: 100%;
  }

  .textarea-input {
    resize: none;
    height: 40px;
  }

  .for-button-flex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
    width: 100%;
  }

  .footer-button {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    column-gap: 12px;
    width: 100%;
  }

  .single-footer-button {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 12px;
    width: 50%;
  }

  // condition component css
  .condition {
    margin-top: 16px;
    .condition-render {
      width: 100%;
      display: grid;
      grid-template-columns: 50px minmax(170px, 1fr) 10px 1.5fr 1fr;
      column-gap: 16px;

      .close-conditions {
        padding: 4px;
        background: #f4f7f9;
        border-radius: 8px;
      }
    }
  }
  .condition-container {
    // margin: 15px 0px;
  }
  .dropdown {
    background: #ffffff;
    margin: 0px 18px;

    border-radius: 8px;
    width: 136px;
  }

  .condition-boxs {
    display: flex;
    margin: 18px 0px;
  }
  .add-condition {
    cursor: pointer;
    box-sizing: border-box;

    border: 2px solid #d2e5ed;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 4px;
    }

    label {
      cursor: pointer;
    }
  }
  .for-span {
    margin-right: 4px;
  }
`;

export default SelectedIntegrationAutomationWrapper;
