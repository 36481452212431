import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getCampaignProjects, setCampaignProjects } from "../../../store/features/campaignSlice";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CampaignProject from "../CampaignProject";
import AddCampaignProject from "../AddCampaignProject";
import Header from "../../header";

import Loading from '../../../assets/images/loading.svg';

import './style.scss'

const CampaignProjects = ({campaign, add, cancelAdd, isNewCampaign, menuItems, onMenuItemClick, onAdd, setViewNft}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {id} = useParams();

    const dispatch = useDispatch();
    const { campaignProjects, loading } = useSelector(data => data.campaign);

    const [editProject, setEditProject] = useState(null)

    useEffect(() => {
        dispatch(setCampaignProjects([]))
        if(id && !isNewCampaign)
            dispatch(getCampaignProjects({campaignId: id}))
    }, [id, isNewCampaign, dispatch])

    const onEditProject = (project) => {
        setEditProject(project);
    }

    const onCancelProject = () => {
        if (editProject) {
            setEditProject(null);
        } else {
            cancelAdd();
        }
    }

    return(
        <div className="flex-column campaign-projects-comp">
            <div className="border-bottom">
               <Header title="Projects"
                        isSearch={false}
                        isPaged={false}
                        size='small'
                        menuItems={menuItems}
                        onMenuItemClick={onMenuItemClick}
                        isAddBtn={true}
                        addPermission="PROJECTS_MANAGE"
                        onAdd={onAdd}
                        // isRetire={true}
                        // onRetire={() => navigate(`${location.pathname}/retire`)}
                        tabMenu={true}
                    />
                    </div>
            {(add || editProject) && <AddCampaignProject campaign={campaign} onCancel={() => onCancelProject()} editProject={editProject} />  }
               {loading ? 
                <div className="flex flex-1 items-center justify-center mt-4">
                    <img src={Loading} alt="loading" />
                    <label className="bold-text font-16 ml-2 light-purple-text">Loading</label>
                </div>
                :
                campaignProjects.length > 0 && !isNewCampaign ?
                <div className="campaign-projects">
                    {campaignProjects.map(cp =>
                        <CampaignProject key={cp.id} campaign={campaign} campaignProject={cp} onEditProject={onEditProject} setViewNft={setViewNft}/>
                    )}                
                </div>
                :
                <div className="flex items-center justify-center flex-1">
                    <label className="bold-text font-16 light-purple-text">There are no projects</label>
                </div>
            }
         
        </div>
    )
}

export default CampaignProjects;