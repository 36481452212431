import React, { useEffect, useState, useCallback, useRef } from "react";

import "../../pages/projectDetails/details.scss";
import '../../assets/css/components/popup/add-carbon-popup.scss'
import { useParams } from "react-router-dom";
import { getRegistries, createRegistry } from "../../store/features/registrySlice";
import { getSuppliers } from "../../store/features/supplierSlice";
import { updateInventory, createInventory } from "../../store/features/inventorySlice";
import { getProjects } from "../../store/features/projectSlice";
import { getRegistryAccounts, getSubRegistryAccounts } from "../../store/features/inventorySlice";
import { getAccounts } from "../../store/features/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "../McDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import DateView from "../Elements/DateView";
import AddIcon from "../../assets/images/add.svg";
import moment from "moment";

const AddCarbonPopup = ({setIsCarbonDialog, carbon}) => {
    const dispatch = useDispatch();
    const { registries } = useSelector(data => data.registry);
    const { suppliers } = useSelector(data => data.supplier);
    const { organisation } = useSelector(data => data.user);
    const { projects } = useSelector(data => data.project);
    const { registryAccounts, subRegistryAccounts } = useSelector(data => data.inventory);
    const { accounts } = useSelector(data => data.account);
    const { user, organisations } = useSelector(data => data.user);

    const pageRef = useRef(null);

    const [showAddRegistry, setShowAddRegistry] = useState(false);
    const [search, setSearch] = useState('');

    const [formData, setFormData] = useState({
        owner: carbon.owner,
        serialNumber: carbon ? carbon.serial_number : '',
        name: carbon ? carbon.name : '',
        blocks: carbon ? carbon.blocks : '',
        supplier: carbon ? carbon.supplier?.id : '',
        project: carbon ? carbon.project : '',
        primary_account: carbon ? carbon.primary_account : '',
        sub_account: carbon ? carbon.sub_account : '',
        registry: carbon ? carbon.registry?.id : '',
        vintage_to: carbon ? carbon.vintage?.vintage_to : null,
        vintage_from: carbon ? carbon.vintage?.vintage_from : null,
        purchase_price: carbon ? carbon.costs?.purchase_price : '',
        currency: carbon ?  {name: carbon.costs?.currency} : {name: 'USD'},
        current_balance: carbon ? carbon.carbon.current_balance / 1000 : '',
        open_balance: carbon ? carbon.carbon.open_balance / 1000 : '',
        sale_amount: carbon ? carbon.sale?.amount : '',
        sale_currency: carbon ?  {name: carbon.sale?.currency} : {name: 'USD'},
        purchase_date: carbon ? carbon.purchase_date : null
    });

    const [newRegistry, setNewRegistry] = useState({});

    const {serialNumber, blocks, supplier, vintage_to, vintage_from, purchase_price, currency, registry,
        current_balance, open_balance, sale_amount, sale_currency, purchase_date, project, primary_account, sub_account, name, owner } = formData;

        const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

          
    const fetchAccounts  = useCallback((page, merge, user)  => {
        pageRef.current = page;
        dispatch(getAccounts({organisationId: user.organisations[0].external_id, page: page, merge: merge, type: 'PROJECT_OWNER', search: ''}));
    }, [dispatch]);

    useEffect(() => {
        fetchAccounts(0, false, user);
    }, [user, fetchAccounts]);

    useEffect(() => {
        dispatch(getRegistryAccounts());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getSubRegistryAccounts());
    }, [dispatch]);

    const fetchProjects = useCallback((page, merge) => {
        pageRef.current = page;
        dispatch(getProjects({page: page, size: 10, search, merge}));
    }, [dispatch, search]);


    useEffect(() => {
        fetchProjects(0, false);
    }, [search, fetchProjects])

    useEffect(() => {
        dispatch(getRegistries());
        dispatch(getSuppliers(organisation));
    }, []);
 
    const addCarbon = async () => {
        const inventory = carbon ? {
            "project": project,
            name: name,
            primary_account: primary_account,
            sub_account: sub_account,
            "vintage_to": vintage_to,
            "vintage_from": vintage_from,
            "serial_number": serialNumber,
            "purchase_price": purchase_price,
            "purchase_currency": currency.name,
            "registry": {
                "id": registry
            },
            "supplier": {
                "id": supplier
            },
            "open_co2_balance": open_balance * 1000,
            "current_co2_balance": current_balance * 1000,
            "sale": {
                amount: sale_amount,
                currency: sale_currency.name
            },
            "purchase_date": purchase_date,
            owner: {id: owner.id}
        } :  {
            "project": project,
            name: name,
            primary_account: primary_account,
            sub_account: sub_account,
            "vintage_to": vintage_to,
            "vintage_from": vintage_from,
            "blocks": blocks,
            "serial_number": serialNumber,
            "purchase_price": purchase_price,
            "purchase_currency": currency.name,
            "registry": {
                "id": registry
            },
            "supplier": {
                "id": supplier
            },
            "open_co2_balance": open_balance * 1000,
            "current_co2_balance": current_balance * 1000,
            "sale": {
                amount: sale_amount,
                currency: sale_currency.name
            },
            "purchase_date": purchase_date,
            owner: {id: owner.id}
        }
        if(serialNumber && current_balance && purchase_price && registry) {
            if(carbon)
                dispatch(updateInventory({...inventory, id: carbon.id}));
            else
                dispatch(createInventory(inventory));
            setIsCarbonDialog(false)
        }
    }

    const addRegistry = () => {
        dispatch(createRegistry(newRegistry));
        setShowAddRegistry(false);
    };

    return (
        <div className='add-carbon-popup'>
            <div className='add-carbon-popup-content'>
                <label className='popup-title'>{carbon ? 'Edit inventory' : 'New inventory'}</label>
                <div className={"px-6 mt-4 my-10"}>
                 <div className="flex mt-2">
                    <div className="flex-column flex-1 mr-6">
                        <label className="input-label font-12">Project</label>
                        <DropDown 
                            size="medium"
                            options={projects.content} 
                            selected={formData?.project} 
                            setSelected={p => setFormData({...formData, project: p })} 
                            placeholder="Select"
                            isSearchable={true}
                            search={search}
                            setSearch={setSearch}
                            isPaged={true}
                            hasMore={!projects.last}    
                            fetchData={() => fetchProjects(pageRef.current + 1, true)}   
                        />
                    </div>
                    <div className="flex-column flex-1">
                        <label className="input-label font-12">Owner</label>
                        <DropDown 
                            size="medium" 
                            placeholder="Select" 
                            selected={formData.owner}
                            setSelected={c => setFormData({...formData, owner: c})}
                            options={[...organisations.map(o => ({...o, id: o.external_id})), ...accounts.content]}
                            isPaged={true}
                            hasMore={!accounts.last}    
                            fetchData={() => fetchAccounts(pageRef.current + 1, true, user)}   
                        />
                    </div>
                 
                </div>
                <div className={"flex w-full mt-4"}>
                    <div className="flex-column flex-1 w-full">
                        <label className="input-label font-12">Name</label>
                        <input className="input medium-input " value={name} name={'name'}
                            placeholder={'Enter name'} onChange={onChange}/>
                    </div>
                    <div className="flex items-center flex-1 ml-6 ">
                        <div className={"flex-column flex-1"} >
                                <label className="input-label font-12">Vintage to</label>
                                <div className="flex flex-1">
                                    <DatePicker
                                        className="input medium-input mc-date-input"
                                        selected={moment.unix(vintage_to || moment().unix()).toDate()}
                                        onChange={(date) => onChange({target: {value: moment(date).unix(), name: 'vintage_to'}})}
                                        dropdownMode="select"
                                        showMonthDropdown
                                        showYearDropdown
                                        adjustDateOnChange
                                    />
                                </div>
                                {/* <DateView size="medium" value={vintage_to} setValue={o => onChange({target: {value: o, name: 'vintage_to'}})}/> */}
                            </div>
                            <div className={"flex-column w-full ml-6"} > 
                                <label className="input-label font-12">Vintage from</label>
                                <DatePicker
                                    className="input medium-input mc-date-input"
                                    selected={moment.unix(vintage_from || moment().unix()).toDate()}
                                    onChange={(date) => onChange({target: {value: moment(date).unix(), name: 'vintage_from'}})}
                                    dropdownMode="select"
                                    showMonthDropdown
                                    showYearDropdown
                                    adjustDateOnChange
                                />
                                {/* <DateView size="medium" value={vintage_from} setValue={o => onChange({target: {value: o, name: 'vintage_from'}})}/> */}
                            </div>
                    </div>
                </div>
                <div className={"flex items-center w-full mt-4"}>
                    <div className="flex-column flex-1 mr-3">
                        <label className="input-label font-12">Registry</label>
                        <DropDown 
                            size="medium"
                            options={registries} 
                            selected={registries.find(r => r.id === registry)} 
                            setSelected={p => setFormData({...formData, registry: p.id })} 
                            placeholder="Select"
                        />
                    </div>
                   
                    <div className="flex items-center flex-1 ml-3">
                            <div className={"flex-column flex-1 mr-6"}>
                                <label className="input-label font-12">Primary account</label>
                                <DropDown 
                                    size="medium"
                                    options={registryAccounts} 
                                    selected={formData.primary_account} 
                                    setSelected={p => setFormData({...formData, primary_account: p })} 
                                    placeholder="Select"
                                />
                            </div>
                            <div className="flex-column flex-1">
                                <label className="input-label font-12">Sub account</label>
                                <DropDown 
                                    size="medium"
                                    options={subRegistryAccounts} 
                                    selected={formData.sub_account} 
                                    setSelected={p => setFormData({...formData, sub_account: p })} 
                                    placeholder="Select"
                                />
                            </div>
                        </div>
                </div>
                <div className={"flex w-full mt-4"}>
                    <div className="flex-column flex-1">
                        <label className="input-label font-12">Serial Number</label>
                        <input className="input medium-input " value={serialNumber} name={'serialNumber'}
                            placeholder={'Enter serial number'} onChange={onChange}/>
                    </div>
                </div>
                <div className="flex flex-1 mt-4">
                    <div className="flex-column items-center flex-1 mr-6 border-1 radius-4 pxy-4">
                        <div className="flex-column flex-1 justify-between w-full">
                            <div className="flex items-center flex-1 mr-6">
                                <label className="regular-text font-16 flex-1">Opening balance</label>
                                <input className="input medium-input flex-1  w-full"value={open_balance} type={"number"}
                                            name={'current_balance'} placeholder={0} onChange={e => onChange({target: {value: e.target.value, name: 'open_balance'}})}/>
                            </div>
                            <div className="flex items-center flex-1 mr-6">
                                <label className="regular-text font-16 flex-1">Current balance</label>
                                <input className="input medium-input flex-1 w-full"value={current_balance} type={"number"}
                                            name={'current_balance'} placeholder={0} onChange={e => onChange({target: {value: e.target.value, name: 'current_balance'}})}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex-column items-center flex-1  border-1 radius-4 pxy-4">
                        <div className="flex flex-1 lr-6  w-full">
                            <div className="flex-column flex-1">
                                <label className="input-label font-12">Supplier</label>
                                <DropDown 
                                    size="medium"
                                    options={formData.owner ? [formData.owner, ...suppliers] : suppliers} 
                                    selected={(formData.owner ? [formData.owner, ...suppliers] : suppliers).find(r => r.id === supplier)} 
                                    setSelected={p => setFormData({...formData, supplier: p.id })} 
                                    placeholder="Select"
                                />
                            </div>
                        </div>
                        <div className={"flex flex-1 mt-4  w-full"}>
                            <div className={"flex-column flex-1 mr-6"}>
                                <label className="input-label font-12">Purchase price</label>
                                <div className="flex items-center justify-between input medium-input ">
                                    <input className="purchase-price-input w-full" value={purchase_price} type={"number"}
                                        name={'purchase_price'} placeholder={0} onChange={onChange}/>
                                        <label className="regular-text">USD</label>
                                </div>

                            </div>
                            <div className={"flex flex-1"}>
                                    <div className="flex-column flex-1">
                                        <label className="input-label font-12">Purchase Date</label>
                                        <DatePicker
                                            className="input medium-input mc-date-input-purchase"
                                            selected={moment.unix(purchase_date || moment().unix()).toDate()}
                                            onChange={(date) => onChange({target: {value: moment(date).unix(), name: 'purchase_date'}})}
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            adjustDateOnChange
                                        />
                                        {/* <DateView size="medium" value={purchase_date} setValue={o => onChange({target: {value: o, name: 'purchase_date'}})}/> */}
                                    </div>
                                    {/* <div className={"flex-column w-full"}>
                                        <label className="input-label">Sale price</label>
                                        <input className="input  w-full" value={sale_amount} type={"number"}
                                            name={'sale_amount'} placeholder={0} onChange={onChange}/>
                                    </div>
                                    <div className={"flex-column w-full"}>
                                        <label className="input-label">Currency</label>
                                        <Dropdown
                                            onSelect={option => setFormData({...formData, sale_currency: option})}
                                            options={[{id: 0, name: 'USD'}, { id: 1, name: 'GBP'}, {id: 2, name: 'EUR'}]}
                                            scrollEnabled
                                            selected={sale_currency}
                                            withIcon={false}
                                            borderColor='rgba(181, 185, 193, 0.3)'
                                        />
                                    </div> */}
                                </div>
                            </div>
                    </div>
                    
                  </div>
                </div>

                <div className="flex justify-center mt-auto mb-6 mt-10 ml-6">
                    <div className="flex items-center justify-center cancel-btn cursor mr-6 flex-1"
                         onClick={() => setIsCarbonDialog(false)}>
                        <label className="medium-text color-green">Cancel</label>
                    </div>

                    <div
                        onClick={addCarbon}
                        className={`${(serialNumber && current_balance && purchase_price && registry) ? 'done-btn' : 'disable-btn'} flex items-center justify-center cursor mr-6 flex-1`}>
                        <label className="medium-text color-white">Save</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCarbonPopup;





