import React, { useState, useEffect, useRef } from "react";

import api, { cloudinaryApi } from '../../store/services'

import uuid from 'react-uuid'

import ImagePlaceholder from '../../assets/images/image-placeholder.svg'

import './style.scss';


const Files = () => {
    const inputFile = useRef(null)
    const usageTypes = ['LOGO',
        'BACKGROUND',
        'AVATAR',
        'PHOTO',
        'VIDEO',
        'DOCUMENT'];

    const [selected, setSelected] = useState();
    const [file, setFile] = useState();
    const [uploaded, setUploaded] = useState();


     const uploadDocument = async () => {
        if(file) {
            let formData = new FormData();
            formData.append('file', file);
            formData.append('folder', 'techaware');
            const {data} = await api.post(`/v3/api/files`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              timeout: 50000,
            });

            console.log(data);
            setUploaded(data)
        }
     
      }
      

    return (<div className="photos">
        <div className="photos-left">
            <img src={selected?.url} />
        </div>
        <div className="photos-right">
            <div className='bv-image-placeholder'>
                <input className='file-select-input' type='file' ref={inputFile} onChange={(e) => setFile(e.target.files[0])} />
            </div>
            <div className="actions">
                <button onClick={uploadDocument}>UPLOAD</button>
            </div>
            <div className="mt-4">
                <label>{uploaded?.media_id}</label>
            </div>
        </div>
    </div>)
}


export default Files;