import React, { useEffect} from "react";

import { useSelector, useDispatch } from "react-redux";
import { getTemplateCollections } from "../../../store/features/templateSlice";
import { ReactComponent as DotsIcon } from '../../../assets/images/dots.svg'
import { useNavigate } from "react-router-dom";

import Image from "../../image";

import './style.scss';

const TemplateCollections = ({template, menu, isNew}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { templateCollections } = useSelector(data => data.template);

    useEffect(() => {
        if(template?.id)
            dispatch(getTemplateCollections(template.id));
    }, [template, dispatch]);

    return (
       <div className="flex-column card">
             <div className="flex items-center justify-between px-6 py-5 border-bottom">
                <label className="bold-text font-20">Collections</label>
                {menu}
            </div>
            <div className="flex-column template-collections">
                {!isNew && templateCollections.content?.map(tc => 
                <div key={tc.id} className="flex items-center border-bottom template-collections-grid cursor" onClick={() => navigate(`/meta/collections/${tc.id}`, {state: {template: template}})}>
                    <div className="flex items-center px-6 py-1 my-1 border-right">
                        <Image className={"tc-image"} size={58} media={tc?.image?.media} />
                        <label className="regular-text ml-2">{tc.name}</label>
                    </div>
                    <label className="regular-text light-purple-text border-right px-6 py-5 my-1">{tc.description}</label>
                    <div className="flex items-center justify-between px-6 py-5 my-1">
                        <div className="flex items-center">
                            <img src={tc.integration?.icon?.active} alt="" className="tc-integration-img" />
                            <label className="regular-text ml-2">{tc.integration?.name}</label>
                        </div>
                        <DotsIcon className="cursor" width={20} /> 
                    </div>
                </div>)}
            </div>
        </div>
    )
}

export default TemplateCollections;
