import Selected from '../assets/images/selected.svg';
import api from "../store/services";
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { CSSTransition } from 'react-transition-group';
import Button from '../components/Elements/button/button';
import Dropdown from '../components/Elements/dropdown/dropdown';
import PhoneInput from '../components/Elements/phone-input';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { addToast } from '../store/features/toastSlice';
import { InviteUserPopupWrapper } from './invite-user-popup.styled';
import 'react-phone-input-2/lib/style.css';

const MultiValueOption = props => {
  const onSelect = () => {
    const currentSelected = props.getValue();
    const newSelected = props.isSelected
      ? currentSelected.filter(opt => opt.value !== props.value)
      : [...currentSelected, { ...props.data }];
    props.setValue(newSelected);
  };
  return (
    <div className={`tpus-user ${props.isSelected && 'tpus-user-selected'}`} onClick={() => onSelect()}>
      <label className={`regular-text ${props.isSelected && 'bold-text'}`}>{props.label}</label>
      {props.isSelected && <img alt="icon" src={Selected} />}
    </div>
  );
};

const InviteUserModal = ({ organisationId, user }) => {
  const { user: loggedInUser } = useSelector(state => state.user);

  const accountOrganisationId = loggedInUser?.organisations?.length === 1 ? loggedInUser?.organisations[0]?.external_id : loggedInUser?.organisations.find(o => o.org_type === 'ACCOUNT')?.external_id;
  const [complete, setComplete] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sendInvite, setSendInvite] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const emailRegex = /^[A-za-z0-9]([-A-Z-a-z0-9.!#$%&'*+-=?^_`{|}~\/]?)+@([-A-Z-a-z0-9]+\.)+[A-Za-z]{2,5}$/;
  const userEmail = (user && user.contact_info?.find(contact => contact.contact_type === 'EMAIL')?.contact_value) || '';
  const userMobile =
    (user && user?.contact_info?.find(contact => contact.contact_type === 'MOBILE')?.contact_value) || '';

  const [invitedUser, setInvitedUser] = useState({
    name: user ? user.name : '',
    email: userEmail,
    phone: userMobile,
    country_code: '+44',
    is_active: user ? user.is_active : false,
  });
  const [selectedRole, setSelectedRole] = useState();

  const { setModal } = useContext(OrganisationContext);
  const { toasts } = useSelector(state => state.toast);

  useEffect(() => {
    setComplete(
      invitedUser.name &&
        invitedUser.name.length > 2 &&
        invitedUser.name.split(' ').length > 1 &&
        (user || (invitedUser.email && emailRegex.test(invitedUser.email))) &&
        (user ? selectedRole : !sendInvite || selectedRole),
    );
    setError(false);
  }, [invitedUser, selectedRole, sendInvite]);

  const handleChange = (name, value) => {
    setInvitedUser({
      ...invitedUser,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const rolesResponse = await api.get(`/v1/organizations/${accountOrganisationId}/roles`);
      setRoles(rolesResponse.data);
      if (user) {
        const userRole = user.role;
        setSelectedRole(rolesResponse.data.find(role => role.id === userRole?.id));
      }
    };
    fetchData();
  }, [organisationId]);

  const save = async () => {
    try {
      if (complete) {
        const request = {};
        const names = invitedUser.name.split(' ');
        request.forename = names[0];
        request.surname = names[1];
        setLoading(true);
        if (user) {
          request.is_active = invitedUser.is_active;

          await api.put(`/v1/users/${user.id}`, request);
          if (selectedRole?.id) {
            await api.put(`/v1/users/${user.id}/roles/${selectedRole?.id}`);
          }
          dispatch(
            addToast({
              error: false,
              text: 'User updated successfully',
            }),
          );
          setLoading(false);
          setModal({ type: 'invite-user-success', content: {} });
        } else {
          const { data } = await api.post(`/v1/organizations/${accountOrganisationId}/users`, {
            forename: names[0],
            surname: names[1],
            email: invitedUser.email,
            language: "",
            title: "",
            timezone: "",
            role: {
              id: selectedRole.id
            },
            phone: `${invitedUser.phone?.replaceAll('-', '')?.replaceAll(' ', '') || ''}`,
            country_code: invitedUser.country_code
          });

          // const contacts = [{ contact_type: 'EMAIL', contact_value: invitedUser.email }];
          // if (invitedUser.phone) contacts.push({ contact_type: 'MOBILE', contact_value: invitedUser.phone });
          // await api.post(`/v1/users/${data.id}/contact_infos`, contacts);

          if (sendInvite) 
            await api.post(`/v1/organizations/${accountOrganisationId}/users/${data.id}/invite`);
          dispatch(
            addToast({
              error: false,
              text: 'User added successfully',
            }),
          );
          setLoading(false);
          setModal({ type: 'invite-user-success', content: {} });
        }
      } else {
        setError(true);
        const toastMessage = !(
          invitedUser.name &&
          invitedUser.name.length > 2 &&
          invitedUser.name.split(' ').length > 1
        )
          ? { message: 'Please enter the users full name', id: 'name' }
          : !invitedUser.email
          ? { message: 'Please enter the users email', id: 'email' }
          : !emailRegex.test(invitedUser.email)
          ? { message: 'Please enter valid email', id: 'valid-email' }
          : !selectedRole
          ? { message: 'Please select the users role', id: 'role' }
          : '';
        !toasts.find(t => t.id === toastMessage.id) &&
          dispatch(
            addToast({
              error: true,
              text: toastMessage.message,
              id: toastMessage.id,
            }),
          );
      }
    } catch (error) {
      setLoading(false);
      const err_message = error.response?.data?.error_description
        ? error.response?.data?.error_description
        : 'Something went wrong';
      dispatch(
        addToast({
          error: true,
          text: err_message,
        }),
      );
    }
  };

  return (
    <CSSTransition appear classNames="popup-fade" in timeout={300}>
      <InviteUserPopupWrapper>
        <div className="iu-popup">
          <label className="popup-title">{user ? "Edit user's info" : 'Add new user'}</label>
          <div className="flex-column mb-6">
            <label className="input-label">Name</label>
            <input
              autoComplete="turnoff"
              className={`popup-input form-input ${error && invitedUser.name.split(' ').length < 2 && 'error-input'}`}
              onChange={e => handleChange('name', e.target.value)}
              placeholder="Full user's name"
              value={invitedUser.name}
            />
          </div>
          {!user && (
            <>
              <div className="flex-column mb-6">
                <label className="input-label">Email</label>
                <input
                  autoComplete="turnoff"
                  className={`popup-input form-input ${error && !emailRegex.test(invitedUser.email) && 'error-input'}`}
                  onChange={e => handleChange('email', e.target.value)}
                  placeholder="Type user's email"
                  value={invitedUser.email}
                />
              </div>
              <div className="flex-column mb-6">
                <label className="input-label">
                  Phone <span className="optional-label ml-1">(optional)</span>
                </label>
                <PhoneInput
                  selectedCountry={invitedUser.country_code}
                  setSelectedCountry={country => setInvitedUser({ ...invitedUser, country_code: country, phone: '' })}
                  phone={invitedUser.phone}
                  setPhone={phone => setInvitedUser({ ...invitedUser, phone: phone })}
                  error={error?.phone}
                />
              </div>
            </>
          )}
          {!user && (
            <div className="flex-column mb-6 mt-3">
              <div className="regular-text cursor justify-between">
                <label className="font-16">Send invite</label>
                <div className={`switch ${sendInvite && 'switch-selected'}`} onClick={() => setSendInvite(!sendInvite)}>
                  <div className="selector" />
                </div>
              </div>
            </div>
          )}
          {(user || sendInvite) && (
            <div className="flex-column mb-6">
              <label className="input-label">Role</label>
              <Dropdown
                className={`dropdown-hover invite-role-dd options-select-container ${error && !selectedRole && 'error-border'}`}
                classNamePrefix="select"
                closeMenuOnSelect={false}
                dropdownHeight={'200px'}
                onSelect={options => {
                  setSelectedRole(options);
                }}
                options={roles}
                placeholder="Select role"
                scrollEnabled
                selected={selectedRole}
                withIcon={false}
              />
            </div>
          )}
          {user && (
            <div className="flex-column mb-6 mt-3">
              <div className="regular-text cursor justify-between">
                <label className="font-16">Active</label>
                <div
                  className={`switch ${invitedUser.is_active && 'switch-selected'}`}
                  onClick={() => setInvitedUser({ ...invitedUser, is_active: !invitedUser.is_active })}>
                  <div className="selector" />
                </div>
              </div>
            </div>
          )}
          <div className="iu-actions">
            <Button
              className={`${!loading && 'secondary-hover'}`}
              disabled={loading}
              label="Cancel"
              onClick={() => setModal(initModal)}
              secondary={true}
              size="large"
              width="168px"
            />
            <Button
              className={`${!loading && 'primary-hover'}`}
              disabled={loading}
              label={user ? 'Save' : 'Add user'}
              onClick={save}
              primary={true}
              size="large"
              style={{ marginLeft: 24 }}
              width="168px"
            />
          </div>
        </div>
      </InviteUserPopupWrapper>
    </CSSTransition>
  );
};

export default InviteUserModal;
